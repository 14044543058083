import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/index.css';
import App from './components/App';
import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from './components/AlertTemplate';

const alertOptions = {
  position: positions.BOTTOM_RIGHT,
  timeout: 5000,
  offset: '20px',
  transition: transitions.SCALE,
};

ReactDOM.render(
    <RecoilRoot>
      <AlertProvider template={AlertTemplate} {...alertOptions}>
        <RecoilNexus/>
        <App/>
      </AlertProvider>
    </RecoilRoot>,
  document.getElementById('root'),
);
