import { useState } from 'react';
import { Button, Form, Spinner, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { getCarryoverParts } from '../helpers/api';
import {
  accessTokenAtom,
  locationLovAtom,
  userIdAtom,
  loggedInUserAtom,
} from '../state';
import { useRecoilValue } from 'recoil';
import { round } from '../helpers/misc';
import Logger from '../helpers/Logger';
import COLORS from '../styles/colors';

const logger = new Logger();

const CarryoverPartsSearchModal = props => {
  const { isOpen, hide, onSubmit } = props;
  const [partNumberSearchValue, setPartNumberSearchValue] = useState('');
  const [partDescriptionSearchValue, setPartDescriptionSearchValue] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);
  const [locationSelect, setLocationSelect] = useState('');
  const accessToken = useRecoilValue(accessTokenAtom);
  const locationLov = useRecoilValue(locationLovAtom);
  const userId = useRecoilValue(userIdAtom);
  const loggedInUser = useRecoilValue(loggedInUserAtom);
  
  const searchParts = async () => {
    try {
      setIsSearching(true);
      const parts = await getCarryoverParts(accessToken, partNumberSearchValue, partDescriptionSearchValue, locationSelect);
      setSearchResults(parts);
      setHasSearched(true);
    }
    catch(e) {
      console.error('CarryoverPartsSearchModal.searchParts error ->', e);
      logger.log(accessToken, `CarryoverPartsSearchModal.searchParts error -> ${JSON.stringify(e)}`, userId, loggedInUser);
      setSearchResults([]);
      setHasSearched(true);
    }
    finally {
      setIsSearching(false);
    }
  };
  
  return (
    <div
      style={{
        ...styles.modal,
        display: isOpen ? 'block' : 'none',
      }}
    >
      <div style={styles.partsModalHeaderRow}>
        <h4 style={{ margin: 0 }}>Search Carryover Parts</h4>
        <FontAwesomeIcon
          icon={faTimes}
          size="lg"
          onClick={() => {
            setPartNumberSearchValue('');
            setPartDescriptionSearchValue('');
            setLocationSelect('');
            setSearchResults([]);
            setHasSearched(false);
            hide();
          }}
          style={styles.closeTimes}
        />
      </div>
      <div style={styles.partsModalHeaderRow}>
        <div style={styles.partsModalSearchGroup}>
          <Form.Select
            as="select"
            style={{ marginRight: 10 }}
            value={locationSelect}
            onChange={e => setLocationSelect(e.target.value)}
          >
            <option value="">Any Location</option>
            {locationLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
          </Form.Select>
          <Form.Control
            type="text"
            placeholder="Number"
            value={partNumberSearchValue}
            onChange={e => setPartNumberSearchValue(e.target.value)}
            style={{ marginRight: 10 }}
          />
          <Form.Control
            type="text"
            placeholder="Description"
            value={partDescriptionSearchValue}
            onChange={e => setPartDescriptionSearchValue(e.target.value)}
            style={{ marginRight: 10 }}
          />
          <Button
            type="button"
            variant="primary"
            onClick={searchParts}
            disabled={isSearching ||
              (!partNumberSearchValue.trim().length && !partDescriptionSearchValue.trim().length && !locationSelect.trim().length)}
          >
              <span style={styles.partsModalSearchButton}>
                <FontAwesomeIcon icon={faSearch} style={{ marginRight: 4 }}/>
                Search
              </span>
          </Button>
        </div>
        {
          hasSearched ?
            <h5 style={styles.headingResultsText}>
              {searchResults.length === 100 ?
                'Found over 100 matching parts' :
                `Found ${searchResults.length} matching parts`}
            </h5> :
            null
        }
      </div>
      <div style={styles.partsModalContent}>
        {
          isSearching ?
            <div style={styles.spinnerContainer}>
              <Spinner animation="border"/>
              <p style={styles.spinnerText}>Searching...</p>
            </div> :
            searchResults.length ?
              <Table striped>
                <thead>
                <tr>
                  <th>Org</th>
                  <th>Number</th>
                  <th>Description</th>
                  <th>Cost</th>
                </tr>
                </thead>
                <tbody>
                {searchResults.map((row, index) => (
                  <tr
                    key={index}
                    style={styles.clickableRow}
                    onClick={() => {
                      onSubmit(row);
                      setPartNumberSearchValue('');
                      setPartDescriptionSearchValue('');
                      setLocationSelect('');
                      setSearchResults([]);
                      setHasSearched(false);
                      hide();
                    }}
                  >
                    <td>{row.orgCode}</td>
                    <td>{row.partNumber}</td>
                    <td>{row.partDescription}</td>
                    <td>{row.partCost != null ? round(row.partCost, 5) : 'N/A'}</td>
                  </tr>
                ))}
                </tbody>
              </Table> :
              null
        }
      </div>
    </div>
  );
};

const styles = {
  modal: {
    position: 'fixed',
    top: 25,
    bottom: 25,
    left: 25,
    right: 25,
    borderRadius: 5,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    backgroundColor: COLORS.white,
    zIndex: 9999999999,
    border: `solid 1px ${COLORS.lightGray}`,
  },
  partsModalContent: {
    flex: 1,
    overflowY: 'auto',
  },
  partsModalHeaderRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: 10,
    borderBottom: `solid 1px ${COLORS.lightGray}`,
  },
  partsModalSearchGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  partsModalSearchButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  closeTimes: {
    cursor: 'pointer',
  },
  spinnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 30,
  },
  spinnerText: {
    margin: 0,
    marginTop: 10,
    fontSize: 20,
  },
  headingResultsText: {
    margin: 0,
    alignSelf: 'center',
  },
  clickableRow: {
    cursor: 'pointer',
  },
};

export default CarryoverPartsSearchModal;