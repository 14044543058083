import { Badge } from 'react-bootstrap';
import { cloneQuote, getLateCutoffs, getStatusBadgeType } from '../helpers/misc';
import {
  selectedDashboardQuoteAtom,
  currentViewSelector,
  quoteDataAtom,
  quoteStatusLovAtom,
  quoteProcessorAtom,
  showPrintPageAtom,
  appRolesAtom,
  userIdAtom,
  loggedInUserAtom,
  initValuesAtom,
} from '../state';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import COLORS from '../styles/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faExclamationTriangle, faUserEdit } from '@fortawesome/free-solid-svg-icons';
import * as CONFIG from '../config';

const DashboardQuote = ({ quote }) => {
  const [selectedDashboardQuote, setSelectedDashboardQuote] = useRecoilState(selectedDashboardQuoteAtom);
  const setCurrentView = useSetRecoilState(currentViewSelector);
  const setQuoteData = useSetRecoilState(quoteDataAtom);
  const setInitValues = useSetRecoilState(initValuesAtom);
  const quoteStatusLov = useRecoilValue(quoteStatusLovAtom);
  const quoteProcessor = useRecoilValue(quoteProcessorAtom);
  const appRoles = useRecoilValue(appRolesAtom);
  const userId = useRecoilValue(userIdAtom);
  const loggedInUser = useRecoilValue(loggedInUserAtom);
  const setShowPrintPage = useSetRecoilState(showPrintPageAtom);
  const quoteDisplayOption = quoteStatusLov && quoteStatusLov.length ? quoteStatusLov.find(o => o.code === quote.status) : null;
  
  const lateCutoffs = getLateCutoffs();
  const quoteAlmostLate = quote.dueDate < lateCutoffs.almostLateCutOff && quote.dueDate >= lateCutoffs.lateCutOff;
  const quoteLate = quote.dueDate < lateCutoffs.lateCutOff;
  
  return (
    <div
      style={{
        ...styles.dashboardQuoteContainer,
        borderColor: selectedDashboardQuote && selectedDashboardQuote.quoteId === quote.quoteId ?
          COLORS.blue :
          COLORS.midGray,
        backgroundColor: quote.status === 'SANDBOX' ?
          COLORS.paleYellow :
          COLORS.white,
      }}
      onClick={() => setSelectedDashboardQuote(prev => !prev || prev.quoteId !== quote.quoteId ? quote : null)}
      onDoubleClick={() => {
        quoteProcessor.clearAllData();
        setSelectedDashboardQuote(null);
        setShowPrintPage(false);
        setInitValues(true);
        setQuoteData(quote);
        setCurrentView('EDIT_QUOTE');
      }}
    >
      <div style={styles.topRow}>
        <p style={styles.dashboardQuoteText}>{quote.customerPartNumber}</p>
        <div>
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            size="lg"
            style={{
              visibility: quote.status !== 'SANDBOX' && (quoteLate || quoteAlmostLate) ? 'visible' : 'hidden',
              color: quoteLate ? COLORS.red : quoteAlmostLate ? COLORS.yellow : null,
            }}
          />
          {appRoles.includes(CONFIG.ROLE_MAPPINGS.CREATE_QUOTE) ?
            <FontAwesomeIcon
              icon={faCopy}
              size="lg"
              onClick={e => {
                e.stopPropagation();
                const clone = cloneQuote(quote, userId, loggedInUser);
                quoteProcessor.clearAllData();
                setShowPrintPage(false);
                setInitValues(true);
                setQuoteData(clone);
                setCurrentView('EDIT_QUOTE');
              }}
              style={styles.cloneButton}
              className="toolbar-button"
            /> :
            null
          }
        </div>
      </div>
      <p style={styles.dashboardQuoteText}>{quote.partDescription}</p>
      <p style={styles.dashboardQuoteText}>{quote.customerName}</p>
      <p style={styles.dashboardQuoteText}>Quote #: {quote.quoteNumber}</p>
      {quote.holdUsername ?
        <div style={styles.editRow}>
          <FontAwesomeIcon
            icon={faUserEdit}
            style={{
              color: COLORS.yellow,
              marginRight: 5,
            }}
          />
          <p style={styles.dashboardQuoteText}>{quote.holdUsername}</p>
        </div> :
        null
      }
      <h3 style={styles.badgeContainer}>
        <Badge bg={getStatusBadgeType(quote.status)}>
          {quoteDisplayOption ? quoteDisplayOption.name : quote.status}
        </Badge>
      </h3>
    </div>
  );
};

const styles = {
  topRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  editRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  dashboardQuoteContainer: {
    padding: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
    borderStyle: 'solid',
    borderWidth: 1,
    marginBottom: 4,
    cursor: 'pointer',
    borderRadius: 5,
  },
  dashboardQuoteText: {
    margin: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  badgeContainer: {
    margin: 0,
    marginTop: 10,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  cloneButton: {
    cursor: 'pointer',
    color: COLORS.midGray,
    marginLeft: 10,
  },
};

export default DashboardQuote;