import {
  updateQuote,
  assignQuote,
  getQuoteById,
  getQuoteAssignments,
  createQuoteComment,
  createQuoteActivityLog,
  updateSandboxQuote,
  getSandboxQuoteById,
  getQuoteByIdOnly,
  deleteSandboxQuote,
} from './api';
import cloneDeep from 'lodash.clonedeep';
import { diff, mergeUserAssignments, returnQuoteToDraft, formatChanges, graphHasFloaters } from './misc';
import * as CONFIG from '../config';
import { DateTime } from 'luxon';

class QuoteProcessor {
  #globalData;
  #generalData;
  #moldedData;
  #assemblyData;
  #purchasingData;
  #packagingData;
  #auxData;
  #capitalCostData;
  #testingData;
  #feasibilityData;
  #bomData;
  #assignees;
  #reassignees;
  #productType;
  #userRoles;
  #cancellationData;
  #rejectPreFeasibilityComments;
  #rejectFeasibilityComments;
  #feasibilityComments;
  #rejectSalesCheckComments;
  #completeData;
  #lostReasonComments;
  #takeBackData;
  #actions = {
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    SUBMIT: 'SUBMIT',
    APPROVE_PRE_FEASIBILITY: 'APPROVE_PRE_FEASIBILITY',
    REJECT_PRE_FEASIBILITY: 'REJECT_PRE_FEASIBILITY',
    SUBMIT_ESTIMATION: 'SUBMIT_ESTIMATION',
    TAKE_BACK_ESTIMATION: 'TAKE_BACK_ESTIMATION',
    DELETE: 'DELETE',
    CANCEL: 'CANCEL',
    CREATE_REVISION: 'CREATE_REVISION',
    CREATE_SANDBOX: 'CREATE_SANDBOX',
    SEND_BACK_FEASIBILITY: 'SEND_BACK_FEASIBILITY',
    SIGN_OFF_FEASIBILITY: 'SIGN_OFF_FEASIBILITY',
    REJECT_FEASIBILITY: 'REJECT_FEASIBILITY',
    SEND_BACK_SALES_APPROVAL: 'SEND_BACK_SALES_APPROVAL',
    SALES_APPROVAL: 'SALES_APPROVAL',
    SAVE_COMPLETE: 'SAVE_COMPLETE',
    REASSIGN: 'REASSIGN',
    UPDATE_SANDBOX: 'UPDATE_SANDBOX',
    PROMOTE_SANDBOX: 'PROMOTE_SANDBOX',
    DELETE_SANDBOX: 'DELETE_SANDBOX',
  };
  
  get ACTIONS() {
    return this.#actions;
  }
  
  setGeneralData(data) {
    this.#generalData = data;
  }
  
  setGlobalData(data) {
    this.#globalData = data;
  }
  
  setBomData(data) {
    this.#bomData = data;
  }
  
  setMoldedData(data) {
    this.#moldedData = data;
  }
  
  setAssignees(assignees) {
    this.#assignees = assignees;
  }
  
  setReassignees(assignees) {
    this.#reassignees = assignees;
  }
  
  setProductType(type) {
    this.#productType = type;
  }
  
  setUserRoles(roles) {
    this.#userRoles = roles;
  }
  
  setCancellationData(data) {
    this.#cancellationData = data;
  }
  
  setRejectPreFeasibilityComments(data) {
    this.#rejectPreFeasibilityComments = data;
  }
  
  setRejectFeasibilityComments(data) {
    this.#rejectFeasibilityComments = data;
  }
  
  setRejectSalesCheckComments(data) {
    this.#rejectSalesCheckComments = data;
  }
  
  setAssemblyData(data) {
    this.#assemblyData = data;
  }
  
  setPurchasingData(data) {
    this.#purchasingData = data;
  }
  
  setPackagingData(data) {
    this.#packagingData = data;
  }
  
  setAuxData(data) {
    this.#auxData = data;
  }
  
  setCapitalCostData(data) {
    this.#capitalCostData = data;
  }
  
  setTestingData(data) {
    this.#testingData = data;
  }
  
  setFeasibilityData(data) {
    this.#feasibilityData = data;
  }
  
  setFeasibilityComments(data) {
    this.#feasibilityComments = data;
  }
  
  setCompleteData(data) {
    this.#completeData = data;
  }
  
  setLostReasonComments(data) {
    this.#lostReasonComments = data;
  }
  
  setTakeBackData(data) {
    this.#takeBackData = data;
  }
  
  clearAllData() {
    this.#globalData = null;
    this.#generalData = null;
    this.#bomData = null;
    this.#moldedData = null;
    this.#assemblyData = null;
    this.#purchasingData = null;
    this.#packagingData = null;
    this.#auxData = null;
    this.#capitalCostData = null;
    this.#testingData = null;
    this.#feasibilityData = null;
    this.#completeData = null;
    this.#feasibilityComments = null;
    this.#cancellationData = null;
    this.#rejectPreFeasibilityComments = null;
    this.#rejectFeasibilityComments = null;
    this.#rejectSalesCheckComments = null;
    this.#lostReasonComments = null;
    this.#assignees = null;
    this.#reassignees = null;
    this.#productType = null;
    this.#userRoles = null;
    this.#takeBackData = null;
  }
  
  async processQuote(accessToken, userId, loggedInUser, quoteData, action, delay = 1000) {
    await new Promise(res => setTimeout(() => res(), delay));
    let quoteDataCopy = quoteData.status === 'SANDBOX' ?
      await getSandboxQuoteById(accessToken, quoteData.quoteId, quoteData.revision, userId) :
      await getQuoteById(accessToken, quoteData.quoteId, quoteData.revision);
    if(!quoteDataCopy) quoteDataCopy = cloneDeep(quoteData);
    if(!['DRAFT', 'SANDBOX'].includes(quoteDataCopy.status) && quoteDataCopy.holdUserId !== userId) throw new Error('Cannot updated quote -> user does not have an edit hold');
    const assignments = (await getQuoteAssignments(accessToken, quoteData.quoteId, quoteData.revision))
      .map(o => ({ userId: o.userId, username: o.username, types: o.assignmentTypes }));
    const quoteId = quoteDataCopy.quoteId;
    const revision = quoteDataCopy.revision;
    delete quoteDataCopy.quoteId;
    delete quoteDataCopy.revision;
    delete quoteDataCopy.assignments;
    
    const generateChangeFuncs = (quoteToPut, quoteData, activityLogFuncs) => {
      const changes = formatChanges(diff(quoteToPut, quoteData));
      if(changes.length) changes.forEach(change => activityLogFuncs.push(() => createQuoteActivityLog(
        accessToken,
        quoteId,
        revision,
        change.activityType,
        change.message,
        loggedInUser,
      )));
    };
    
    if(action === this.#actions.CREATE) {
      // check required data exists
      if(!this.#globalData) throw new Error('Cannot complete CREATE action -> no global data available');
      if(!this.#generalData) throw new Error('Cannot complete CREATE action -> no general data available');
      if(!this.#userRoles) throw new Error('Cannot complete CREATE action -> no userRoles available');
      if(!this.#userRoles.includes(CONFIG.ROLE_MAPPINGS.CREATE_QUOTE))
        throw new Error('Cannot complete CREATE action -> user does not have permission');
      
      const updatedQuote = await updateQuote(accessToken, quoteId, revision, {
        ...quoteDataCopy,
        ...this.#globalData,
        ...this.#generalData,
        ...(this.#bomData ? this.#bomData : []),
      }, userId, loggedInUser);
      
      const userAssignments = [{
        userId,
        username: loggedInUser,
        types: ['OWNER'],
      }];
      
      await assignQuote(accessToken, quoteId, revision, userAssignments);
      await Promise.all([
        createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'GENERAL_CHANGE',
          `Quote created`,
          loggedInUser,
        ),
        createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'ASSIGNMENT',
          `Quote assigned to ${loggedInUser} on creation`,
          loggedInUser,
        ),
        createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'STATUS_CHANGE',
          `Quote status changed to DRAFT`,
          loggedInUser,
        ),
        createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'QUOTE_REVISION',
          `Quote revision changed to ${revision}`,
          loggedInUser,
        ),
      ]);
      
      return {
        ...updatedQuote,
        assignments: userAssignments,
      };
    }
    else if(action === this.#actions.UPDATE) {
      // check required data exists
      if(!this.#globalData) throw new Error('Cannot complete UPDATE action -> no global data available');
      if(!this.#generalData) throw new Error('Cannot complete UPDATE action -> no general data available');
      if(!this.#moldedData) throw new Error('Cannot complete UPDATE action -> no molded data available');
      if(!this.#assemblyData) throw new Error('Cannot complete UPDATE action -> no assembly data available');
      if(!this.#purchasingData) throw new Error('Cannot complete UPDATE action -> no purchasing data available');
      if(!this.#packagingData) throw new Error('Cannot complete UPDATE action -> no packaging data available');
      if(!this.#auxData) throw new Error('Cannot complete UPDATE action -> no aux data available');
      if(!this.#capitalCostData) throw new Error('Cannot complete UPDATE action -> no capital cost data available');
      if(!this.#testingData) throw new Error('Cannot complete UPDATE action -> no testing data available');
      if(!this.#feasibilityData) throw new Error('Cannot complete UPDATE action -> no feasibility data available');
      if(!this.#completeData) throw new Error('Cannot complete UPDATE action -> no complete data available');
      
      let completeData = cloneDeep(this.#completeData);
      delete completeData.quoteStatus;
      
      const quoteToPut = {
        ...quoteDataCopy,
        ...this.#globalData,
        ...this.#generalData,
        ...this.#assemblyData,
        ...this.#purchasingData,
        ...this.#packagingData,
        ...this.#auxData,
        ...this.#capitalCostData,
        ...this.#testingData,
        ...this.#feasibilityData,
        ...(this.#bomData ? this.#bomData : []),
        ...completeData,
        moldedParts: this.#moldedData,
      };
      
      const activityLogFuncs = [];
      generateChangeFuncs(quoteToPut, quoteData, activityLogFuncs);
      
      const updatedQuote = await updateQuote(accessToken, quoteId, revision, quoteToPut, userId, loggedInUser);
      
      await Promise.all(activityLogFuncs.map(f => f()));
      
      return {
        ...updatedQuote,
        assignments: quoteData.assignments,
      };
    }
    else if(action === this.#actions.UPDATE_SANDBOX) {
      // check required data exists
      if(!this.#globalData) throw new Error('Cannot complete UPDATE_SANDBOX action -> no global data available');
      if(!this.#generalData) throw new Error('Cannot complete UPDATE_SANDBOX action -> no general data available');
      if(!this.#moldedData) throw new Error('Cannot complete UPDATE_SANDBOX action -> no molded data available');
      if(!this.#assemblyData) throw new Error('Cannot complete UPDATE_SANDBOX action -> no assembly data available');
      if(!this.#purchasingData) throw new Error('Cannot complete UPDATE_SANDBOX action -> no purchasing data available');
      if(!this.#packagingData) throw new Error('Cannot complete UPDATE_SANDBOX action -> no packaging data available');
      if(!this.#auxData) throw new Error('Cannot complete UPDATE_SANDBOX action -> no aux data available');
      if(!this.#capitalCostData) throw new Error('Cannot complete UPDATE_SANDBOX action -> no capital cost data available');
      if(!this.#testingData) throw new Error('Cannot complete UPDATE_SANDBOX action -> no testing data available');
      if(!this.#feasibilityData) throw new Error('Cannot complete UPDATE_SANDBOX action -> no feasibility data available');
      if(!this.#completeData) throw new Error('Cannot complete UPDATE_SANDBOX action -> no complete data available');
      
      let completeData = cloneDeep(this.#completeData);
      delete completeData.quoteStatus;
      
      const quoteToPut = {
        ...quoteDataCopy,
        ...this.#globalData,
        ...this.#generalData,
        ...this.#assemblyData,
        ...this.#purchasingData,
        ...this.#packagingData,
        ...this.#auxData,
        ...this.#capitalCostData,
        ...this.#testingData,
        ...this.#feasibilityData,
        ...(this.#bomData ? this.#bomData : []),
        ...completeData,
        moldedParts: this.#moldedData,
      };
      
      const updatedQuote = await updateSandboxQuote(accessToken, quoteId, revision, quoteToPut, userId, loggedInUser);
      
      return {
        ...updatedQuote,
        assignments: [{
          userId,
          username: loggedInUser,
          types: ['OWNER'],
        }],
      };
    }
    else if(action === this.#actions.SUBMIT) {
      // check required data exists
      if(!this.#globalData) throw new Error('Cannot complete SUBMIT action -> no global data available');
      if(!this.#generalData) throw new Error('Cannot complete SUBMIT action -> no general data available');
      if(!this.#userRoles) throw new Error('Cannot complete SUBMIT action -> no userRoles available');
      if(!this.#assignees) throw new Error('Cannot complete SUBMIT action -> no assignees available');
      if(!this.#userRoles.includes(CONFIG.ROLE_MAPPINGS.CREATE_QUOTE))
        throw new Error('Cannot complete SUBMIT action -> user does not have permission');
      
      // check required attributes exist
      let missingAttributes = [];
      // assignee data
      if(!this.#assignees.feasibilityAssignee) missingAttributes.push('GLOBAL|feasibilityAssignee');
      // global data
      if(!this.#globalData.fgLocation) missingAttributes.push('GLOBAL|fgLocation');
      if(!this.#globalData.dueDate) missingAttributes.push('GLOBAL|dueDate');
      // general data
      if(!this.#generalData.productType) missingAttributes.push('GENERAL|productType');
      if(!this.#generalData.customerName) missingAttributes.push('GENERAL|customerName');
      if(!this.#generalData.productTypeIhs) missingAttributes.push('GENERAL|productTypeIhs');
      if(!this.#generalData.programs || (!this.#generalData.programs.length && this.#generalData?.platformId !== 'TBD'))
        missingAttributes.push('GENERAL|program');
      if(!this.#generalData.platform) missingAttributes.push('GENERAL|platform');
      if(!this.#generalData.platformId) missingAttributes.push('GENERAL|platform');
      if(!this.#generalData.estimatedPpv) missingAttributes.push('GENERAL|estimatedPpv');
      if(!this.#generalData.partType) missingAttributes.push('GENERAL|partType');
      if(!this.#generalData.customerPartNumber) missingAttributes.push('GENERAL|customerPartNumber');
      if(!this.#generalData.partDescription) missingAttributes.push('GENERAL|partDescription');
      if(!this.#generalData.toolType) missingAttributes.push('GENERAL|toolType');
      if(!this.#generalData.materialType) missingAttributes.push('GENERAL|materialType');
      if(!this.#generalData.generalColor) missingAttributes.push('GENERAL|generalColor');
      if(!this.#generalData.monthlyVolume) missingAttributes.push('GENERAL|monthlyVolume');
      if(!this.#generalData.monthlyCarVolume) missingAttributes.push('GENERAL|monthlyCarVolume');
      if(!this.#generalData.designResponsibility) missingAttributes.push('GENERAL|designResponsibility');
      if(!this.#generalData.businessType) missingAttributes.push('GENERAL|businessType');
      if(this.#generalData.businessType &&
        ['EXISTING_BUSINESS_CARRY_OVER', 'EXISTING_BUSINESS_REPLACEMENT'].includes(this.#generalData.businessType) &&
        !this.#generalData.previousPartNumber) missingAttributes.push('GENERAL|previousPartNumber');
      if(!this.#generalData.salesExpectation) missingAttributes.push('GENERAL|salesExpectation');
      if(missingAttributes.length) return { missingAttributes };
      
      // build user assignments array
      const userAssignments = [{
        userId: this.#assignees.feasibilityAssignee.code,
        username: this.#assignees.feasibilityAssignee.name,
        types: ['FEASIBILITY'],
      }];
      
      const quoteToPut = {
        ...quoteDataCopy,
        ...this.#globalData,
        ...this.#generalData,
        status: 'PRE_FEASIBILITY',
        dateTimeSubmitted: Date.now(),
      };
      
      const activityLogFuncs = [
        () => createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'ASSIGNMENT',
          `Quote assigned to ${this.#assignees.feasibilityAssignee.name} on for pre-feasibility`,
          loggedInUser,
        ),
        () => createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'STATUS_CHANGE',
          `Quote status changed to PRE-FEASIBILITY`,
          loggedInUser,
        ),
      ];
      
      generateChangeFuncs(quoteToPut, quoteData, activityLogFuncs);
      
      // update quote
      const updatedQuote = await updateQuote(accessToken, quoteId, revision, quoteToPut, userId, loggedInUser);
      await assignQuote(accessToken, quoteId, revision, userAssignments);
      await Promise.all(activityLogFuncs.map(f => f()));
      
      return {
        ...updatedQuote,
        assignments: userAssignments,
      };
    }
    else if(action === this.#actions.APPROVE_PRE_FEASIBILITY) {
      // check required data exists
      if(!this.#globalData) throw new Error('Cannot complete APPROVE_PRE_FEASIBILITY action -> no global data available');
      if(!this.#generalData) throw new Error('Cannot complete APPROVE_PRE_FEASIBILITY action -> no general data available');
      if(!this.#productType) throw new Error('Cannot complete APPROVE_PRE_FEASIBILITY action -> no productType available');
      if(!this.#userRoles) throw new Error('Cannot complete APPROVE_PRE_FEASIBILITY action -> no userRoles available');
      if(!this.#assignees) throw new Error('Cannot complete APPROVE_PRE_FEASIBILITY action -> no assignees available');
      if(!this.#userRoles.some(roleId => CONFIG.ROLE_MAPPINGS.FEASIBILITY === roleId))
        throw new Error('Cannot complete APPROVE_PRE_FEASIBILITY action -> user does not have permission');
      
      const assignmentForUser = assignments.find(o => o.userId === userId);
      if(!assignmentForUser) throw new Error('Cannot complete APPROVE_PRE_FEASIBILITY action -> no assignments for current user');
      
      // check required attributes exist
      let missingAttributes = [];
      // assignee data
      if(!this.#assignees.designEstimatorAssignee) missingAttributes.push('GLOBAL|designEstimatorAssignee');
      if(!this.#assignees.toolingEstimatorAssignee) missingAttributes.push('GLOBAL|toolingEstimatorAssignee');
      if(this.#productType.assembled && !this.#assignees.manufacturingEstimatorAssignee)
        missingAttributes.push('GLOBAL|manufacturingEstimatorAssignee');
      if(this.#productType.purchased && !this.#assignees.purchasingEstimatorAssignee)
        missingAttributes.push('GLOBAL|purchasingEstimatorAssignee');
      // global data
      if(!this.#globalData.fgLocation) missingAttributes.push('GLOBAL|fgLocation');
      if(!this.#globalData.dueDate) missingAttributes.push('GLOBAL|dueDate');
      // general data
      if(!this.#generalData.productType) missingAttributes.push('GENERAL|productType');
      if(!this.#generalData.customerName) missingAttributes.push('GENERAL|customerName');
      if(!this.#generalData.productTypeIhs) missingAttributes.push('GENERAL|productTypeIhs');
      if(!this.#generalData.programs || !this.#generalData.programs.length) missingAttributes.push('GENERAL|program');
      if(!this.#generalData.platform) missingAttributes.push('GENERAL|platform');
      if(!this.#generalData.platformId) missingAttributes.push('GENERAL|platform');
      if(!this.#generalData.estimatedPpv) missingAttributes.push('GENERAL|estimatedPpv');
      if(!this.#generalData.partType) missingAttributes.push('GENERAL|partType');
      if(!this.#generalData.customerPartNumber) missingAttributes.push('GENERAL|customerPartNumber');
      if(!this.#generalData.partDescription) missingAttributes.push('GENERAL|partDescription');
      if(!this.#generalData.toolType) missingAttributes.push('GENERAL|toolType');
      if(!this.#generalData.materialType) missingAttributes.push('GENERAL|materialType');
      if(!this.#generalData.generalColor) missingAttributes.push('GENERAL|generalColor');
      if(!this.#generalData.monthlyVolume) missingAttributes.push('GENERAL|monthlyVolume');
      if(!this.#generalData.monthlyCarVolume) missingAttributes.push('GENERAL|monthlyCarVolume');
      if(!this.#generalData.designResponsibility) missingAttributes.push('GENERAL|designResponsibility');
      if(!this.#generalData.businessType) missingAttributes.push('GENERAL|businessType');
      if(this.#generalData.businessType &&
        ['EXISTING_BUSINESS_CARRY_OVER', 'EXISTING_BUSINESS_REPLACEMENT'].includes(this.#generalData.businessType) &&
        !this.#generalData.previousPartNumber) missingAttributes.push('GENERAL|previousPartNumber');
      if(!this.#generalData.salesExpectation) missingAttributes.push('GENERAL|salesExpectation');
      if(missingAttributes.length) return { missingAttributes };
      
      const now = Date.now();
      const quoteToPut = {
        ...quoteDataCopy,
        ...this.#globalData,
        ...this.#generalData,
        status: 'IN_PROGRESS',
        dateTimePreFeasibilityApproved: now,
        preFeasibilityApprovedUserId: userId,
        preFeasibilityApprovedUsername: loggedInUser,
      };
      
      // check if quote needs to change state
      const activityLogFuncs = [
        () => createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'STATUS_CHANGE',
          `Quote status changed to IN PROGRESS`,
          loggedInUser,
        ),
        () => createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'ASSIGNMENT',
          `Quote assigned to ${this.#assignees.designEstimatorAssignee.name} for design estimation`,
          loggedInUser,
        ),
        () => createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'ASSIGNMENT',
          `Quote assigned to ${this.#assignees.toolingEstimatorAssignee.name} for tooling estimation`,
          loggedInUser,
        ),
      ];
      let userAssignments = [
        {
          userId: this.#assignees.designEstimatorAssignee.code,
          username: this.#assignees.designEstimatorAssignee.name,
          types: ['DESIGN_ESTIMATOR'],
        },
        {
          userId: this.#assignees.toolingEstimatorAssignee.code,
          username: this.#assignees.toolingEstimatorAssignee.name,
          types: ['TOOLING_ESTIMATOR'],
        },
      ];
      if(this.#assignees.manufacturingEstimatorAssignee) {
        userAssignments.push({
          userId: this.#assignees.manufacturingEstimatorAssignee.code,
          username: this.#assignees.manufacturingEstimatorAssignee.name,
          types: ['MANUFACTURING_ESTIMATOR'],
        });
        activityLogFuncs.push(() => createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'ASSIGNMENT',
          `Quote assigned to ${this.#assignees.manufacturingEstimatorAssignee.name} for manufacturing estimation`,
          loggedInUser,
        ));
      }
      if(this.#assignees.purchasingEstimatorAssignee) {
        userAssignments.push({
          userId: this.#assignees.purchasingEstimatorAssignee.code,
          username: this.#assignees.purchasingEstimatorAssignee.name,
          types: ['PURCHASING_ESTIMATOR'],
        });
        activityLogFuncs.push(() => createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'ASSIGNMENT',
          `Quote assigned to ${this.#assignees.purchasingEstimatorAssignee.name} for purchasing estimation`,
          loggedInUser,
        ));
      }
      
      // merge same users into one record
      userAssignments = mergeUserAssignments(userAssignments);
      
      generateChangeFuncs(quoteToPut, quoteData, activityLogFuncs);
      
      // update quote
      const updatedQuote = await updateQuote(accessToken, quoteId, revision, quoteToPut, userId, loggedInUser);
      
      await assignQuote(accessToken, quoteId, revision, userAssignments);
      await Promise.all(activityLogFuncs.map(f => f()));
      
      return {
        ...updatedQuote,
        assignments: userAssignments,
      };
    }
    else if(action === this.#actions.REJECT_PRE_FEASIBILITY) {
      // check required data exists
      if(!this.#globalData) throw new Error('Cannot complete REJECT_PRE_FEASIBILITY action -> no global data available');
      if(!this.#generalData) throw new Error('Cannot complete REJECT_PRE_FEASIBILITY action -> no general data available');
      if(!this.#userRoles) throw new Error('Cannot complete REJECT_PRE_FEASIBILITY action -> no userRoles available');
      if(!this.#userRoles.some(roleId => CONFIG.ROLE_MAPPINGS.FEASIBILITY === roleId))
        throw new Error('Cannot complete REJECT_PRE_FEASIBILITY action -> user does not have permission');
      
      const assignmentForUser = assignments.find(o => o.userId === userId);
      if(!assignmentForUser) throw new Error('Cannot complete REJECT_PRE_FEASIBILITY action -> no assignments for current user');
      
      // check required attributes exist
      let missingAttributes = [];
      // global data
      if(!this.#globalData.fgLocation) missingAttributes.push('GLOBAL|fgLocation');
      if(!this.#globalData.dueDate) missingAttributes.push('GLOBAL|dueDate');
      if(!this.#rejectPreFeasibilityComments) missingAttributes.push('GLOBAL|rejectionPreFeasibilityComment');
      // general data
      if(!this.#generalData.productTypeIhs) missingAttributes.push('GENERAL|productTypeIhs');
      if(!this.#generalData.programs || !this.#generalData.programs.length) missingAttributes.push('GENERAL|program');
      if(!this.#generalData.platform) missingAttributes.push('GENERAL|platform');
      if(!this.#generalData.platformId) missingAttributes.push('GENERAL|platform');
      if(!this.#generalData.estimatedPpv) missingAttributes.push('GENERAL|estimatedPpv');
      if(missingAttributes.length) return { missingAttributes };
      
      const now = Date.now();
      const quoteToPut = {
        ...returnQuoteToDraft(quoteDataCopy),
        ...this.#globalData,
        ...this.#generalData,
        status: 'NOT_FEASIBLE',
        dateTimePreFeasibilityRejected: now,
        preFeasibilityRejectedUserId: userId,
        preFeasibilityRejectedUsername: loggedInUser,
      };
      
      const userAssignments = [{
        userId: quoteData.createdBy,
        username: quoteData.createdByUsername,
        types: ['OWNER'],
      }];
      
      const activityLogFuncs = [
        () => createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'STATUS_CHANGE',
          `Quote status changed to NOT FEASIBLE`,
          loggedInUser,
        ),
        () => createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'ASSIGNMENT',
          `Quote assigned to ${quoteData.createdByUsername} because it failed pre-feasibility`,
          loggedInUser,
        ),
      ];
      
      generateChangeFuncs(quoteToPut, quoteData, activityLogFuncs);
      
      // update quote
      const updatedQuote = await updateQuote(accessToken, quoteId, revision, quoteToPut, userId, loggedInUser);
      await assignQuote(accessToken, quoteId, revision, userAssignments);
      await createQuoteComment(
        accessToken,
        quoteId,
        revision,
        'FEASIBILITY',
        `Quote pre-feasibility rejected with comment: ${this.#rejectPreFeasibilityComments}`,
        loggedInUser,
      );
      await Promise.all(activityLogFuncs.map(f => f()));
      
      return {
        ...updatedQuote,
        assignments: userAssignments,
      };
    }
    else if(action === this.#actions.SUBMIT_ESTIMATION) {
      // check required data exists
      if(!this.#globalData) throw new Error('Cannot complete SUBMIT_ESTIMATION action -> no global data available');
      if(!this.#generalData) throw new Error('Cannot complete SUBMIT_ESTIMATION action -> no general data available');
      if(!this.#moldedData) throw new Error('Cannot complete SUBMIT_ESTIMATION action -> no molded data available');
      if(!this.#assemblyData) throw new Error('Cannot complete SUBMIT_ESTIMATION action -> no assembly data available');
      if(!this.#purchasingData) throw new Error('Cannot complete SUBMIT_ESTIMATION action -> no purchasing data available');
      if(!this.#packagingData) throw new Error('Cannot complete SUBMIT_ESTIMATION action -> no packaging data available');
      if(!this.#auxData) throw new Error('Cannot complete SUBMIT_ESTIMATION action -> no aux data available');
      if(!this.#capitalCostData) throw new Error('Cannot complete SUBMIT_ESTIMATION action -> no capital cost data available');
      if(!this.#testingData) throw new Error('Cannot complete SUBMIT_ESTIMATION action -> no testing data available');
      if(!this.#productType) throw new Error('Cannot complete SUBMIT_ESTIMATION action -> no productType available');
      if(!this.#userRoles) throw new Error('Cannot complete SUBMIT_ESTIMATION action -> no userRoles available');
      if(!this.#userRoles.some(roleId => [
        CONFIG.ROLE_MAPPINGS.DESIGN_ESTIMATOR,
        CONFIG.ROLE_MAPPINGS.TOOLING_ESTIMATOR,
        CONFIG.ROLE_MAPPINGS.MANUFACTURING_ESTIMATOR,
        CONFIG.ROLE_MAPPINGS.PURCHASING_ESTIMATOR,
      ].includes(roleId))) throw new Error('Cannot complete SUBMIT_ESTIMATION action -> user does not have permission');
      
      const assignmentForUser = assignments.find(o => o.userId === userId);
      if(!assignmentForUser) throw new Error('Cannot complete SUBMIT_ESTIMATION action -> no assignments for current user');
      const assignmentTypes = assignmentForUser.types;
      
      // check required attributes exist
      let missingAttributes = [];
      // global data
      if(!this.#globalData.fgLocation) missingAttributes.push('GLOBAL|fgLocation');
      if(!this.#globalData.dueDate) missingAttributes.push('GLOBAL|dueDate');
      
      // molded role specific data
      if(assignmentTypes.includes('DESIGN_ESTIMATOR')) {
        // general data
        if(!this.#generalData.productTypeIhs) missingAttributes.push('GENERAL|productTypeIhs');
        if(!this.#generalData.programs || !this.#generalData.programs.length) missingAttributes.push('GENERAL|program');
        if(!this.#generalData.platform) missingAttributes.push('GENERAL|platform');
        if(!this.#generalData.platformId) missingAttributes.push('GENERAL|platform');
        if(!this.#generalData.estimatedPpv) missingAttributes.push('GENERAL|estimatedPpv');
        
        // molded data
        if(!this.#moldedData.length && !['ASSEMBLY_ALL_PURCHASE_PARTS', 'PURCHASE_PART_ONLY'].includes(this.#generalData.productType)) {
          missingAttributes.push('MOLDED|moldedParts');
          return { missingAttributes };
        }
        this.#moldedData.forEach((partObj, index) => {
          if(!partObj.partDescription) missingAttributes.push(`MOLDED|${index}|partDescription`);
          if(!partObj.partWeightDerived) missingAttributes.push(`MOLDED|${index}|partWeightDerived`);
          if(!partObj.partWeight) missingAttributes.push(`MOLDED|${index}|partWeight`);
          if(!partObj.annealing) missingAttributes.push(`MOLDED|${index}|annealing`);
          if(!partObj.inspection) missingAttributes.push(`MOLDED|${index}|inspection`);
          if(!partObj.packaging) missingAttributes.push(`MOLDED|${index}|packaging`);
          if(!partObj.materials || partObj.materials.reduce((total, cur) => total + cur.blendPercentage, 0) !== 100)
            missingAttributes.push(`MOLDED|${index}|materials`);
          if(partObj.isOutsourced && partObj.outsourcedCost == null) missingAttributes.push(`MOLDED|${index}|outsourcedCost`);
        });
        // bom data
        if(!this.#bomData?.bom || graphHasFloaters(this.#bomData.bom)) missingAttributes.push('BOM|bomData');
      }
      if(assignmentTypes.includes('TOOLING_ESTIMATOR')) {
        // molded data
        if(!this.#moldedData.length && !['ASSEMBLY_ALL_PURCHASE_PARTS', 'PURCHASE_PART_ONLY'].includes(this.#generalData.productType)) {
          missingAttributes.push('MOLDED|moldedParts');
          return { missingAttributes };
        }
        this.#moldedData.forEach((partObj, index) => {
          if(!partObj.cavitiesPerMoldA) missingAttributes.push(`MOLDED|${index}|cavitiesPerMoldA`);
          if(!partObj.cavitiesPerPartA) missingAttributes.push(`MOLDED|${index}|cavitiesPerPartA`);
          if(!partObj.numOfDiffPartsA) missingAttributes.push(`MOLDED|${index}|numOfDiffPartsA`);
          if(!partObj.gateStyleA) missingAttributes.push(`MOLDED|${index}|gateStyleA`);
          if(!partObj.gateCuttingA) missingAttributes.push(`MOLDED|${index}|gateCuttingA`);
          if(partObj.toolCostA == null) missingAttributes.push(`MOLDED|${index}|toolCostA`);
          if(partObj.leadTimeWeeksA == null) missingAttributes.push(`MOLDED|${index}|leadTimeWeeksA`);
          if(!partObj.toolDescA) missingAttributes.push(`MOLDED|${index}|toolDescA`);
          if(!partObj.toolLocationA) missingAttributes.push(`MOLDED|${index}|toolLocationA`);
          if(!partObj.shotsPerHourA) missingAttributes.push(`MOLDED|${index}|shotsPerHourA`);
          if(!partObj.pressSizeA) missingAttributes.push(`MOLDED|${index}|pressSizeA`);
          if(partObj.hasOptionB) {
            if(!partObj.cavitiesPerMoldB) missingAttributes.push(`MOLDED|${index}|cavitiesPerMoldB`);
            if(!partObj.cavitiesPerPartB) missingAttributes.push(`MOLDED|${index}|cavitiesPerPartB`);
            if(!partObj.numOfDiffPartsB) missingAttributes.push(`MOLDED|${index}|numOfDiffPartsB`);
            if(!partObj.gateStyleB) missingAttributes.push(`MOLDED|${index}|gateStyleB`);
            if(!partObj.gateCuttingB) missingAttributes.push(`MOLDED|${index}|gateCuttingB`);
            if(partObj.toolCostB == null) missingAttributes.push(`MOLDED|${index}|toolCostB`);
            if(partObj.leadTimeWeeksB == null) missingAttributes.push(`MOLDED|${index}|leadTimeWeeksB`);
            if(!partObj.toolDescB) missingAttributes.push(`MOLDED|${index}|toolDescB`);
            if(!partObj.toolLocationB) missingAttributes.push(`MOLDED|${index}|toolLocationB`);
            if(!partObj.shotsPerHourB) missingAttributes.push(`MOLDED|${index}|shotsPerHourB`);
            if(!partObj.pressSizeB) missingAttributes.push(`MOLDED|${index}|pressSizeB`);
          }
        });
        // bom data
        if(!this.#bomData?.bom || graphHasFloaters(this.#bomData.bom)) missingAttributes.push('BOM|bomData');
      }
      if(missingAttributes.length) return { missingAttributes };
      
      const now = Date.now();
      const quoteToPut = {
        ...quoteDataCopy,
        ...this.#globalData,
        ...this.#generalData,
        ...this.#assemblyData,
        ...this.#purchasingData,
        ...this.#packagingData,
        ...this.#auxData,
        ...this.#capitalCostData,
        ...this.#testingData,
        ...(this.#bomData ? this.#bomData : []),
        moldedParts: this.#moldedData,
        dateTimeFeasibilitySentBack: null,
        feasibilitySentBackUserId: null,
        feasibilitySentBackUsername: null,
      };
      if(assignmentTypes.includes('DESIGN_ESTIMATOR')) {
        quoteToPut.dateTimeDesignEstimationSubmitted = now;
        quoteToPut.designEstimationSubmittedUserId = userId;
        quoteToPut.designEstimationSubmittedUsername = loggedInUser;
      }
      if(assignmentTypes.includes('TOOLING_ESTIMATOR')) {
        quoteToPut.dateTimeToolingEstimationSubmitted = now;
        quoteToPut.toolingEstimationSubmittedUserId = userId;
        quoteToPut.toolingEstimationSubmittedUsername = loggedInUser;
      }
      if(assignmentTypes.includes('MANUFACTURING_ESTIMATOR')) {
        quoteToPut.dateTimeManufacturingEstimationSubmitted = now;
        quoteToPut.manufacturingEstimationSubmittedUserId = userId;
        quoteToPut.manufacturingEstimationSubmittedUsername = loggedInUser;
      }
      if(assignmentTypes.includes('PURCHASING_ESTIMATOR')) {
        quoteToPut.dateTimePurchasingEstimationSubmitted = now;
        quoteToPut.purchasingEstimationSubmittedUserId = userId;
        quoteToPut.purchasingEstimationSubmittedUsername = loggedInUser;
      }
      
      // check if quote needs to change state
      let userAssignments;
      const activityLogFuncs = [];
      if(quoteToPut.designEstimationSubmittedUserId && quoteToPut.toolingEstimationSubmittedUserId &&
        (!this.#productType.assembled || quoteToPut.manufacturingEstimationSubmittedUserId) &&
        (!this.#productType.purchased || quoteToPut.purchasingEstimationSubmittedUserId)) {
        quoteToPut.status = 'FEASIBILITY';
        quoteToPut.dateTimeEstimationsComplete = now;
        userAssignments = [{
          userId: quoteToPut.preFeasibilityApprovedUserId,
          username: quoteToPut.preFeasibilityApprovedUsername,
          types: ['FEASIBILITY'],
        }];
        activityLogFuncs.push(() => createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'STATUS_CHANGE',
          `Quote status changed to FEASIBILITY`,
          loggedInUser,
        ));
        activityLogFuncs.push(() => createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'ASSIGNMENT',
          `Quote assigned to ${quoteToPut.preFeasibilityApprovedUsername} for feasibility`,
          loggedInUser,
        ));
      }
      else userAssignments = assignments.filter(o => o.userId !== userId);
      
      generateChangeFuncs(quoteToPut, quoteData, activityLogFuncs);
      
      // update quote
      const updatedQuote = await updateQuote(accessToken, quoteId, revision, quoteToPut, userId, loggedInUser);
      await assignQuote(accessToken, quoteId, revision, userAssignments);
      await Promise.all(activityLogFuncs.map(f => f()));
      
      return {
        ...updatedQuote,
        assignments: userAssignments,
      };
    }
    else if(action === this.#actions.TAKE_BACK_ESTIMATION) {
      if(!this.#takeBackData) throw new Error('Cannot complete TAKE_BACK_ESTIMATION action -> no take back data available');
      if(!this.#takeBackData.isTakingBackDesignEstimation && !this.#takeBackData.isTakingBackToolingEstimation &&
        !this.#takeBackData.isTakingBackManufacturingEstimation && !this.#takeBackData.isTakingBackPurchasingEstimation)
        throw new Error('Cannot complete TAKE_BACK_ESTIMATION action -> no estimations to take back');
      if(this.#takeBackData.isTakingBackDesignEstimation &&
        (!quoteDataCopy.designEstimationSubmittedUserId || quoteDataCopy.designEstimationSubmittedUserId !== userId))
        throw new Error('Cannot complete TAKE_BACK_ESTIMATION action -> user is did not submit the design estimation');
      if(this.#takeBackData.isTakingBackToolingEstimation &&
        (!quoteDataCopy.toolingEstimationSubmittedUserId || quoteDataCopy.toolingEstimationSubmittedUserId !== userId))
        throw new Error('Cannot complete TAKE_BACK_ESTIMATION action -> user is did not submit the tooling estimation');
      if(this.#takeBackData.isTakingBackManufacturingEstimation &&
        (!quoteDataCopy.manufacturingEstimationSubmittedUserId || quoteDataCopy.manufacturingEstimationSubmittedUserId !== userId))
        throw new Error('Cannot complete TAKE_BACK_ESTIMATION action -> user is did not submit the manufacturing estimation');
      if(this.#takeBackData.isTakingBackPurchasingEstimation &&
        (!quoteDataCopy.purchasingEstimationSubmittedUserId || quoteDataCopy.purchasingEstimationSubmittedUserId !== userId))
        throw new Error('Cannot complete TAKE_BACK_ESTIMATION action -> user is did not submit the purchasing estimation');
      if(quoteDataCopy.status !== 'IN_PROGRESS')
        throw new Error('Cannot complete TAKE_BACK_ESTIMATION action -> quote is not in state IN_PROGRESS');
      
      let userAssignments = cloneDeep(assignments);
      const activityLogFuncs = [];
      if(this.#takeBackData.isTakingBackDesignEstimation) {
        quoteDataCopy.dateTimeDesignEstimationSubmitted = null;
        quoteDataCopy.designEstimationSubmittedUserId = null;
        quoteDataCopy.designEstimationSubmittedUsername = null;
        userAssignments.push({
          userId: userId,
          username: loggedInUser,
          types: ['DESIGN_ESTIMATOR'],
        });
        activityLogFuncs.push(() => createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'ASSIGNMENT',
          `Estimation took back by ${loggedInUser} for design estimation`,
          loggedInUser,
        ));
      }
      if(this.#takeBackData.isTakingBackToolingEstimation) {
        quoteDataCopy.dateTimeToolingEstimationSubmitted = null;
        quoteDataCopy.toolingEstimationSubmittedUserId = null;
        quoteDataCopy.toolingEstimationSubmittedUsername = null;
        userAssignments.push({
          userId: userId,
          username: loggedInUser,
          types: ['TOOLING_ESTIMATOR'],
        });
        activityLogFuncs.push(() => createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'ASSIGNMENT',
          `Estimation took back by ${loggedInUser} for tooling estimation`,
          loggedInUser,
        ));
      }
      if(this.#takeBackData.isTakingBackManufacturingEstimation) {
        quoteDataCopy.dateTimeManufacturingEstimationSubmitted = null;
        quoteDataCopy.manufacturingEstimationSubmittedUserId = null;
        quoteDataCopy.manufacturingEstimationSubmittedUsername = null;
        userAssignments.push({
          userId: userId,
          username: loggedInUser,
          types: ['MANUFACTURING_ESTIMATOR'],
        });
        activityLogFuncs.push(() => createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'ASSIGNMENT',
          `Estimation took back by ${loggedInUser} for manufacturing estimation`,
          loggedInUser,
        ));
      }
      if(this.#takeBackData.isTakingBackPurchasingEstimation) {
        quoteDataCopy.dateTimePurchasingEstimationSubmitted = null;
        quoteDataCopy.purchasingEstimationSubmittedUserId = null;
        quoteDataCopy.purchasingEstimationSubmittedUsername = null;
        userAssignments.push({
          userId: userId,
          username: loggedInUser,
          types: ['PURCHASING_ESTIMATOR'],
        });
        activityLogFuncs.push(() => createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'ASSIGNMENT',
          `Estimation took back by ${loggedInUser} for purchasing estimation`,
          loggedInUser,
        ));
      }
  
      // merge same users into one record
      userAssignments = mergeUserAssignments(userAssignments);
  
      // update quote
      const updatedQuote = await updateQuote(accessToken, quoteId, revision, quoteDataCopy, userId, loggedInUser);
      await assignQuote(accessToken, quoteId, revision, userAssignments);
      await Promise.all(activityLogFuncs.map(f => f()));
  
      return {
        ...updatedQuote,
        assignments: userAssignments,
      };
    }
    else if(action === this.#actions.SEND_BACK_FEASIBILITY) {
      // check required data exists
      if(!this.#globalData) throw new Error('Cannot complete SEND_BACK_FEASIBILITY action -> no global data available');
      if(!this.#generalData) throw new Error('Cannot complete SEND_BACK_FEASIBILITY action -> no general data available');
      if(!this.#moldedData) throw new Error('Cannot complete SEND_BACK_FEASIBILITY action -> no molded data available');
      if(!this.#assemblyData) throw new Error('Cannot complete SEND_BACK_FEASIBILITY action -> no assembly data available');
      if(!this.#purchasingData) throw new Error('Cannot complete SEND_BACK_FEASIBILITY action -> no purchasing data available');
      if(!this.#packagingData) throw new Error('Cannot complete SEND_BACK_FEASIBILITY action -> no packaging data available');
      if(!this.#auxData) throw new Error('Cannot complete SEND_BACK_FEASIBILITY action -> no aux data available');
      if(!this.#capitalCostData) throw new Error('Cannot complete SEND_BACK_FEASIBILITY action -> no capital cost data available');
      if(!this.#testingData) throw new Error('Cannot complete SEND_BACK_FEASIBILITY action -> no testing data available');
      if(!this.#feasibilityData) throw new Error('Cannot complete SEND_BACK_FEASIBILITY action -> no feasibility data available');
      if(!this.#productType) throw new Error('Cannot complete SEND_BACK_FEASIBILITY action -> no productType available');
      if(!this.#userRoles) throw new Error('Cannot complete SEND_BACK_FEASIBILITY action -> no userRoles available');
      if(!this.#userRoles.some(roleId => CONFIG.ROLE_MAPPINGS.FEASIBILITY === roleId))
        throw new Error('Cannot complete SEND_BACK_FEASIBILITY action -> user does not have permission');
      
      const assignmentForUser = assignments.find(o => o.userId === userId);
      if(!assignmentForUser) throw new Error('Cannot complete SEND_BACK_FEASIBILITY action -> no assignments for current user');
      
      // check required attributes exist
      let missingAttributes = [];
      // global data
      if(!this.#globalData.fgLocation) missingAttributes.push('GLOBAL|fgLocation');
      if(!this.#globalData.dueDate) missingAttributes.push('GLOBAL|dueDate');
      if(!this.#rejectFeasibilityComments) missingAttributes.push('GLOBAL|sendBackComment');
      if(!this.#assignees.sendBackDesignEstimatorAssignee && !this.#assignees.sendBackToolingEstimatorAssignee &&
        !this.#assignees.sendBackManufacturingEstimatorAssignee && !this.#assignees.sendBackPurchasingEstimatorAssignee)
        missingAttributes.push('GLOBAL|sendBackAssignees');
      if(missingAttributes.length) return { missingAttributes };
      
      const now = Date.now();
      const quoteToPut = {
        ...quoteDataCopy,
        ...this.#globalData,
        ...this.#generalData,
        ...this.#assemblyData,
        ...this.#purchasingData,
        ...this.#packagingData,
        ...this.#auxData,
        ...this.#capitalCostData,
        ...this.#testingData,
        ...this.#feasibilityData,
        ...(this.#bomData ? this.#bomData : []),
        moldedParts: this.#moldedData,
        status: 'IN_PROGRESS',
        dateTimeFeasibilitySentBack: now,
        feasibilitySentBackUserId: userId,
        feasibilitySentBackUsername: loggedInUser,
        dateTimeEstimationsComplete: null,
      };
      
      let userAssignments = [];
      const activityLogFuncs = [
        () => createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'STATUS_CHANGE',
          `Quote status changed to IN PROGRESS`,
          loggedInUser,
        ),
      ];
      
      if(this.#assignees.sendBackDesignEstimatorAssignee) {
        quoteToPut.dateTimeDesignEstimationSubmitted = null;
        quoteToPut.designEstimationSubmittedUserId = null;
        quoteToPut.designEstimationSubmittedUsername = null;
        userAssignments.push({
          userId: this.#assignees.sendBackDesignEstimatorAssignee.code,
          username: this.#assignees.sendBackDesignEstimatorAssignee.name,
          types: ['DESIGN_ESTIMATOR'],
        });
        activityLogFuncs.push(() => createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'ASSIGNMENT',
          `Quote assigned to ${this.#assignees.sendBackDesignEstimatorAssignee.name} because it needs design estimation changes`,
          loggedInUser,
        ));
      }
      if(this.#assignees.sendBackToolingEstimatorAssignee) {
        quoteToPut.dateTimeToolingEstimationSubmitted = null;
        quoteToPut.toolingEstimationSubmittedUserId = null;
        quoteToPut.toolingEstimationSubmittedUsername = null;
        userAssignments.push({
          userId: this.#assignees.sendBackToolingEstimatorAssignee.code,
          username: this.#assignees.sendBackToolingEstimatorAssignee.name,
          types: ['TOOLING_ESTIMATOR'],
        });
        activityLogFuncs.push(() => createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'ASSIGNMENT',
          `Quote assigned to ${this.#assignees.sendBackToolingEstimatorAssignee.name} because it needs tooling estimation changes`,
          loggedInUser,
        ));
      }
      if(this.#assignees.sendBackManufacturingEstimatorAssignee) {
        quoteToPut.dateTimeManufacturingEstimationSubmitted = null;
        quoteToPut.manufacturingEstimationSubmittedUserId = null;
        quoteToPut.manufacturingEstimationSubmittedUsername = null;
        userAssignments.push({
          userId: this.#assignees.sendBackManufacturingEstimatorAssignee.code,
          username: this.#assignees.sendBackManufacturingEstimatorAssignee.name,
          types: ['MANUFACTURING_ESTIMATOR'],
        });
        activityLogFuncs.push(() => createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'ASSIGNMENT',
          `Quote assigned to ${this.#assignees.sendBackManufacturingEstimatorAssignee.name} because it needs manufacturing estimation changes`,
          loggedInUser,
        ));
      }
      if(this.#assignees.sendBackPurchasingEstimatorAssignee) {
        quoteToPut.dateTimePurchasingEstimationSubmitted = null;
        quoteToPut.purchasingEstimationSubmittedUserId = null;
        quoteToPut.purchasingEstimationSubmittedUsername = null;
        userAssignments.push({
          userId: this.#assignees.sendBackPurchasingEstimatorAssignee.code,
          username: this.#assignees.sendBackPurchasingEstimatorAssignee.name,
          types: ['PURCHASING_ESTIMATOR'],
        });
        activityLogFuncs.push(() => createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'ASSIGNMENT',
          `Quote assigned to ${this.#assignees.sendBackPurchasingEstimatorAssignee.name} because it needs purchasing estimation changes`,
          loggedInUser,
        ));
      }
      
      // merge same users into one record
      userAssignments = mergeUserAssignments(userAssignments);
      
      generateChangeFuncs(quoteToPut, quoteData, activityLogFuncs);
      
      // update quote
      const updatedQuote = await updateQuote(accessToken, quoteId, revision, quoteToPut, userId, loggedInUser);
      await assignQuote(accessToken, quoteId, revision, userAssignments);
      await createQuoteComment(
        accessToken,
        quoteId,
        revision,
        'REQUEST_FOR_CHANGES',
        `Quote sent back with comment: ${this.#rejectFeasibilityComments}`,
        loggedInUser,
      );
      await Promise.all(activityLogFuncs.map(f => f()));
      
      return {
        ...updatedQuote,
        assignments: userAssignments,
      };
    }
    else if(action === this.#actions.REJECT_FEASIBILITY) {
      // check required data exists
      if(!this.#globalData) throw new Error('Cannot complete REJECT_FEASIBILITY action -> no global data available');
      if(!this.#generalData) throw new Error('Cannot complete REJECT_FEASIBILITY action -> no general data available');
      if(!this.#moldedData) throw new Error('Cannot complete REJECT_FEASIBILITY action -> no molded data available');
      if(!this.#assemblyData) throw new Error('Cannot complete REJECT_FEASIBILITY action -> no assembly data available');
      if(!this.#purchasingData) throw new Error('Cannot complete REJECT_FEASIBILITY action -> no purchasing data available');
      if(!this.#packagingData) throw new Error('Cannot complete REJECT_FEASIBILITY action -> no packaging data available');
      if(!this.#auxData) throw new Error('Cannot complete REJECT_FEASIBILITY action -> no aux data available');
      if(!this.#capitalCostData) throw new Error('Cannot complete REJECT_FEASIBILITY action -> no capital cost data available');
      if(!this.#testingData) throw new Error('Cannot complete REJECT_FEASIBILITY action -> no testing data available');
      if(!this.#feasibilityData) throw new Error('Cannot complete REJECT_FEASIBILITY action -> no feasibility data available');
      if(!this.#productType) throw new Error('Cannot complete REJECT_FEASIBILITY action -> no productType available');
      if(!this.#userRoles) throw new Error('Cannot complete REJECT_FEASIBILITY action -> no userRoles available');
      if(!this.#userRoles.some(roleId => CONFIG.ROLE_MAPPINGS.FEASIBILITY === roleId))
        throw new Error('Cannot complete REJECT_FEASIBILITY action -> user does not have permission');
      
      const assignmentForUser = assignments.find(o => o.userId === userId);
      if(!assignmentForUser) throw new Error('Cannot complete REJECT_FEASIBILITY action -> no assignments for current user');
      
      // check required attributes exist
      let missingAttributes = [];
      // global data
      if(!this.#globalData.fgLocation) missingAttributes.push('GLOBAL|fgLocation');
      if(!this.#globalData.dueDate) missingAttributes.push('GLOBAL|dueDate');
      if(!this.#generalData.productTypeIhs) missingAttributes.push('GENERAL|productTypeIhs');
      if(!this.#generalData.programs || !this.#generalData.programs.length) missingAttributes.push('GENERAL|program');
      if(!this.#generalData.platform) missingAttributes.push('GENERAL|platform');
      if(!this.#generalData.platformId) missingAttributes.push('GENERAL|platform');
      if(!this.#generalData.estimatedPpv) missingAttributes.push('GENERAL|estimatedPpv');
      // molded data
      this.#moldedData.forEach((partObj, index) => {
        if(!partObj.partDescription) missingAttributes.push(`MOLDED|${index}|partDescription`);
        if(!partObj.partWeightDerived) missingAttributes.push(`MOLDED|${index}|partWeightDerived`);
        if(!partObj.partWeight) missingAttributes.push(`MOLDED|${index}|partWeight`);
        if(!partObj.annealing) missingAttributes.push(`MOLDED|${index}|annealing`);
        if(!partObj.inspection) missingAttributes.push(`MOLDED|${index}|inspection`);
        if(!partObj.packaging) missingAttributes.push(`MOLDED|${index}|packaging`);
        if(!partObj.materials || partObj.materials.reduce((total, cur) => total + cur.blendPercentage, 0) !== 100)
          missingAttributes.push(`MOLDED|${index}|materials`);
        if(partObj.isOutsourced && partObj.outsourcedCost == null) missingAttributes.push(`MOLDED|${index}|outsourcedCost`);
      });
      // molded part data
      this.#moldedData.forEach((partObj, index) => {
        if(!partObj.cavitiesPerMoldA) missingAttributes.push(`MOLDED|${index}|cavitiesPerMoldA`);
        if(!partObj.cavitiesPerPartA) missingAttributes.push(`MOLDED|${index}|cavitiesPerPartA`);
        if(!partObj.numOfDiffPartsA) missingAttributes.push(`MOLDED|${index}|numOfDiffPartsA`);
        if(!partObj.gateStyleA) missingAttributes.push(`MOLDED|${index}|gateStyleA`);
        if(!partObj.gateCuttingA) missingAttributes.push(`MOLDED|${index}|gateCuttingA`);
        if(!partObj.toolCostA) missingAttributes.push(`MOLDED|${index}|toolCostA`);
        if(!partObj.leadTimeWeeksA) missingAttributes.push(`MOLDED|${index}|leadTimeWeeksA`);
        if(!partObj.toolDescA) missingAttributes.push(`MOLDED|${index}|toolDescA`);
        if(!partObj.toolLocationA) missingAttributes.push(`MOLDED|${index}|toolLocationA`);
        if(!partObj.shotsPerHourA) missingAttributes.push(`MOLDED|${index}|shotsPerHourA`);
        if(!partObj.pressSizeA) missingAttributes.push(`MOLDED|${index}|pressSizeA`);
        if(partObj.hasOptionB) {
          if(!partObj.cavitiesPerMoldB) missingAttributes.push(`MOLDED|${index}|cavitiesPerMoldB`);
          if(!partObj.cavitiesPerPartB) missingAttributes.push(`MOLDED|${index}|cavitiesPerPartB`);
          if(!partObj.numOfDiffPartsB) missingAttributes.push(`MOLDED|${index}|numOfDiffPartsB`);
          if(!partObj.gateStyleB) missingAttributes.push(`MOLDED|${index}|gateStyleB`);
          if(!partObj.gateCuttingB) missingAttributes.push(`MOLDED|${index}|gateCuttingB`);
          if(!partObj.toolCostB) missingAttributes.push(`MOLDED|${index}|toolCostB`);
          if(!partObj.leadTimeWeeksB) missingAttributes.push(`MOLDED|${index}|leadTimeWeeksB`);
          if(!partObj.toolDescB) missingAttributes.push(`MOLDED|${index}|toolDescB`);
          if(!partObj.toolLocationB) missingAttributes.push(`MOLDED|${index}|toolLocationB`);
          if(!partObj.shotsPerHourB) missingAttributes.push(`MOLDED|${index}|shotsPerHourB`);
          if(!partObj.pressSizeB) missingAttributes.push(`MOLDED|${index}|pressSizeB`);
        }
      });
      // feasibility data
      if(!this.#feasibilityComments) missingAttributes.push('FEASIBILITY|feasibilityComments');
      if(!this.#feasibilityData.feasibility) missingAttributes.push('FEASIBILITY|feasibility');
      if(!this.#feasibilityData.feasibilityAdequatelyDefined && !this.#feasibilityData.feasibilityAdequatelyDefinedComment)
        missingAttributes.push('FEASIBILITY|feasibilityAdequatelyDefinedComment');
      if(!this.#feasibilityData.feasibilityEngineeringRequirementsMet && !this.#feasibilityData.feasibilityEngineeringRequirementsMetComment)
        missingAttributes.push('FEASIBILITY|feasibilityEngineeringRequirementsMetComment');
      if(!this.#feasibilityData.feasibilityManufacturedToTolerances && !this.#feasibilityData.feasibilityManufacturedToTolerancesComment)
        missingAttributes.push('FEASIBILITY|feasibilityManufacturedToTolerancesComment');
      if(!this.#feasibilityData.feasibilityAdequateCapacity && !this.#feasibilityData.feasibilityAdequateCapacityComment)
        missingAttributes.push('FEASIBILITY|feasibilityAdequateCapacityComment');
      if(!this.#feasibilityData.feasibilityEfficientMaterialHandling && !this.#feasibilityData.feasibilityEfficientMaterialHandlingComment)
        missingAttributes.push('FEASIBILITY|feasibilityEfficientMaterialHandlingComment');
      if(!this.#feasibilityData.feasibilityNoUnusualCapitalCosts && !this.#feasibilityData.feasibilityNoUnusualCapitalCostsComment)
        missingAttributes.push('FEASIBILITY|feasibilityNoUnusualCapitalCostsComment');
      if(!this.#feasibilityData.feasibilityNoUnusualToolingCosts && !this.#feasibilityData.feasibilityNoUnusualToolingCostsComment)
        missingAttributes.push('FEASIBILITY|feasibilityNoUnusualToolingCostsComment');
      if(!this.#feasibilityData.feasibilityNoAlternateMethods && !this.#feasibilityData.feasibilityNoAlternateMethodsComment)
        missingAttributes.push('FEASIBILITY|feasibilityNoAlternateMethodsComment');
      if(missingAttributes.length) return { missingAttributes };
      
      const now = Date.now();
      const quoteToPut = {
        ...returnQuoteToDraft(quoteDataCopy),
        ...this.#globalData,
        ...this.#generalData,
        ...this.#assemblyData,
        ...this.#purchasingData,
        ...this.#packagingData,
        ...this.#auxData,
        ...this.#capitalCostData,
        ...this.#testingData,
        ...this.#feasibilityData,
        ...(this.#bomData ? this.#bomData : []),
        moldedParts: this.#moldedData,
        status: 'NOT_FEASIBLE',
        dateTimeFeasibilityRejected: now,
        feasibilityRejectedUserId: userId,
        feasibilityRejectedUsername: loggedInUser,
      };
      
      const userAssignments = [{
        userId: quoteData.createdBy,
        username: quoteData.createdByUsername,
        types: ['OWNER'],
      }];
      
      const activityLogFuncs = [
        () => createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'STATUS_CHANGE',
          `Quote status changed to NOT FEASIBLE`,
          loggedInUser,
        ),
        () => createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'ASSIGNMENT',
          `Quote assigned to ${quoteData.createdByUsername} because feasibility was rejected`,
          loggedInUser,
        ),
      ];
      
      generateChangeFuncs(quoteToPut, quoteData, activityLogFuncs);
      
      // update quote
      const updatedQuote = await updateQuote(accessToken, quoteId, revision, quoteToPut, userId, loggedInUser);
      await assignQuote(accessToken, quoteId, revision, userAssignments);
      await createQuoteComment(
        accessToken,
        quoteId,
        revision,
        'FEASIBILITY',
        `Quote feasibility rejected with comment: ${this.#feasibilityComments}`,
        loggedInUser,
      );
      await Promise.all(activityLogFuncs.map(f => f()));
      
      return {
        ...updatedQuote,
        assignments: userAssignments,
      };
    }
    else if(action === this.#actions.SIGN_OFF_FEASIBILITY) {
      // check required data exists
      if(!this.#globalData) throw new Error('Cannot complete SIGN_OFF_FEASIBILITY action -> no global data available');
      if(!this.#generalData) throw new Error('Cannot complete SIGN_OFF_FEASIBILITY action -> no general data available');
      if(!this.#moldedData) throw new Error('Cannot complete SIGN_OFF_FEASIBILITY action -> no molded data available');
      if(!this.#assemblyData) throw new Error('Cannot complete SIGN_OFF_FEASIBILITY action -> no assembly data available');
      if(!this.#purchasingData) throw new Error('Cannot complete SIGN_OFF_FEASIBILITY action -> no purchasing data available');
      if(!this.#packagingData) throw new Error('Cannot complete SIGN_OFF_FEASIBILITY action -> no packaging data available');
      if(!this.#auxData) throw new Error('Cannot complete SIGN_OFF_FEASIBILITY action -> no aux data available');
      if(!this.#capitalCostData) throw new Error('Cannot complete SIGN_OFF_FEASIBILITY action -> no capital cost data available');
      if(!this.#testingData) throw new Error('Cannot complete SIGN_OFF_FEASIBILITY action -> no testing data available');
      if(!this.#feasibilityData) throw new Error('Cannot complete SIGN_OFF_FEASIBILITY action -> no feasibility data available');
      if(!this.#productType) throw new Error('Cannot complete SIGN_OFF_FEASIBILITY action -> no productType available');
      if(!this.#userRoles) throw new Error('Cannot complete SIGN_OFF_FEASIBILITY action -> no userRoles available');
      if(!this.#userRoles.some(roleId => CONFIG.ROLE_MAPPINGS.FEASIBILITY === roleId))
        throw new Error('Cannot complete SIGN_OFF_FEASIBILITY action -> user does not have permission');
      
      const assignmentForUser = assignments.find(o => o.userId === userId);
      if(!assignmentForUser) throw new Error('Cannot complete SIGN_OFF_FEASIBILITY action -> no assignments for current user');
      
      // check required attributes exist
      let missingAttributes = [];
      // global data
      if(!this.#globalData.fgLocation) missingAttributes.push('GLOBAL|fgLocation');
      if(!this.#globalData.dueDate) missingAttributes.push('GLOBAL|dueDate');
      if(this.#globalData.dueDate && this.#globalData.dueDate < DateTime.now().set({ hour: 0, minute:0, second:0, millisecond: 0 }).toMillis()) {
        if(!this.#globalData.lateReason) missingAttributes.push('GLOBAL|lateReason');
        if(!this.#globalData.lateComment) missingAttributes.push('GLOBAL|lateComment');
      }
      // general data
      if(!this.#generalData.complexity && this.#productType.code !== 'PURCHASE_PART_ONLY') missingAttributes.push('GENERAL|complexity');
      if(!this.#generalData.partCategory) missingAttributes.push('GENERAL|partCategory');
      if(!this.#generalData.ppapTiming) missingAttributes.push('GENERAL|ppapTiming');
      if(!this.#generalData.ppapTimingType) missingAttributes.push('GENERAL|ppapTimingType');
      if(!this.#generalData.productTypeIhs) missingAttributes.push('GENERAL|productTypeIhs');
      if(!this.#generalData.programs || !this.#generalData.programs.length) missingAttributes.push('GENERAL|program');
      if(!this.#generalData.platform) missingAttributes.push('GENERAL|platform');
      if(!this.#generalData.platformId) missingAttributes.push('GENERAL|platform');
      if(!this.#generalData.estimatedPpv) missingAttributes.push('GENERAL|estimatedPpv');
      this.#moldedData.forEach((partObj, index) => {
        if(!partObj.partDescription) missingAttributes.push(`MOLDED|${index}|partDescription`);
        if(!partObj.partWeightDerived) missingAttributes.push(`MOLDED|${index}|partWeightDerived`);
        if(!partObj.partWeight) missingAttributes.push(`MOLDED|${index}|partWeight`);
        if(!partObj.annealing) missingAttributes.push(`MOLDED|${index}|annealing`);
        if(!partObj.inspection) missingAttributes.push(`MOLDED|${index}|inspection`);
        if(!partObj.packaging) missingAttributes.push(`MOLDED|${index}|packaging`);
        if(!partObj.materials || partObj.materials.reduce((total, cur) => total + cur.blendPercentage, 0) !== 100)
          missingAttributes.push(`MOLDED|${index}|materials`);
        if(partObj.isOutsourced && partObj.outsourcedCost == null) missingAttributes.push(`MOLDED|${index}|outsourcedCost`);
      });
      // molded part data
      this.#moldedData.forEach((partObj, index) => {
        if(!partObj.cavitiesPerMoldA) missingAttributes.push(`MOLDED|${index}|cavitiesPerMoldA`);
        if(!partObj.cavitiesPerPartA) missingAttributes.push(`MOLDED|${index}|cavitiesPerPartA`);
        if(!partObj.numOfDiffPartsA) missingAttributes.push(`MOLDED|${index}|numOfDiffPartsA`);
        if(!partObj.gateStyleA) missingAttributes.push(`MOLDED|${index}|gateStyleA`);
        if(!partObj.gateCuttingA) missingAttributes.push(`MOLDED|${index}|gateCuttingA`);
        if(!partObj.toolCostA) missingAttributes.push(`MOLDED|${index}|toolCostA`);
        if(!partObj.leadTimeWeeksA) missingAttributes.push(`MOLDED|${index}|leadTimeWeeksA`);
        if(!partObj.toolDescA) missingAttributes.push(`MOLDED|${index}|toolDescA`);
        if(!partObj.toolLocationA) missingAttributes.push(`MOLDED|${index}|toolLocationA`);
        if(!partObj.shotsPerHourA) missingAttributes.push(`MOLDED|${index}|shotsPerHourA`);
        if(!partObj.pressSizeA) missingAttributes.push(`MOLDED|${index}|pressSizeA`);
        if(partObj.hasOptionB) {
          if(!partObj.cavitiesPerMoldB) missingAttributes.push(`MOLDED|${index}|cavitiesPerMoldB`);
          if(!partObj.cavitiesPerPartB) missingAttributes.push(`MOLDED|${index}|cavitiesPerPartB`);
          if(!partObj.numOfDiffPartsB) missingAttributes.push(`MOLDED|${index}|numOfDiffPartsB`);
          if(!partObj.gateStyleB) missingAttributes.push(`MOLDED|${index}|gateStyleB`);
          if(!partObj.gateCuttingB) missingAttributes.push(`MOLDED|${index}|gateCuttingB`);
          if(!partObj.toolCostB) missingAttributes.push(`MOLDED|${index}|toolCostB`);
          if(!partObj.leadTimeWeeksB) missingAttributes.push(`MOLDED|${index}|leadTimeWeeksB`);
          if(!partObj.toolDescB) missingAttributes.push(`MOLDED|${index}|toolDescB`);
          if(!partObj.toolLocationB) missingAttributes.push(`MOLDED|${index}|toolLocationB`);
          if(!partObj.shotsPerHourB) missingAttributes.push(`MOLDED|${index}|shotsPerHourB`);
          if(!partObj.pressSizeB) missingAttributes.push(`MOLDED|${index}|pressSizeB`);
        }
      });
      // feasibility data
      if(this.#feasibilityData.feasibility === 'FEASIBLE_CHANGES' && !this.#feasibilityComments)
        missingAttributes.push('FEASIBILITY|feasibilityComments');
      if(!this.#feasibilityData.feasibility) missingAttributes.push('FEASIBILITY|feasibility');
      if(!this.#feasibilityData.feasibilityAdequatelyDefined || !this.#feasibilityData.feasibilityEngineeringRequirementsMet ||
        !this.#feasibilityData.feasibilityManufacturedToTolerances || !this.#feasibilityData.feasibilityAdequateCapacity ||
        !this.#feasibilityData.feasibilityEfficientMaterialHandling || !this.#feasibilityData.feasibilityNoUnusualCapitalCosts ||
        !this.#feasibilityData.feasibilityNoUnusualToolingCosts || !this.#feasibilityData.feasibilityNoAlternateMethods)
        missingAttributes.push('FEASIBILITY|feasibility');
      if(!this.#feasibilityData.feasibilityAdequatelyDefined && !this.#feasibilityData.feasibilityAdequatelyDefinedComment)
        missingAttributes.push('FEASIBILITY|feasibilityAdequatelyDefinedComment');
      if(!this.#feasibilityData.feasibilityEngineeringRequirementsMet && !this.#feasibilityData.feasibilityEngineeringRequirementsMetComment)
        missingAttributes.push('FEASIBILITY|feasibilityEngineeringRequirementsMetComment');
      if(!this.#feasibilityData.feasibilityManufacturedToTolerances && !this.#feasibilityData.feasibilityManufacturedToTolerancesComment)
        missingAttributes.push('FEASIBILITY|feasibilityManufacturedToTolerancesComment');
      if(!this.#feasibilityData.feasibilityAdequateCapacity && !this.#feasibilityData.feasibilityAdequateCapacityComment)
        missingAttributes.push('FEASIBILITY|feasibilityAdequateCapacityComment');
      if(!this.#feasibilityData.feasibilityEfficientMaterialHandling && !this.#feasibilityData.feasibilityEfficientMaterialHandlingComment)
        missingAttributes.push('FEASIBILITY|feasibilityEfficientMaterialHandlingComment');
      if(!this.#feasibilityData.feasibilityNoUnusualCapitalCosts && !this.#feasibilityData.feasibilityNoUnusualCapitalCostsComment)
        missingAttributes.push('FEASIBILITY|feasibilityNoUnusualCapitalCostsComment');
      if(!this.#feasibilityData.feasibilityNoUnusualToolingCosts && !this.#feasibilityData.feasibilityNoUnusualToolingCostsComment)
        missingAttributes.push('FEASIBILITY|feasibilityNoUnusualToolingCostsComment');
      if(!this.#feasibilityData.feasibilityNoAlternateMethods && !this.#feasibilityData.feasibilityNoAlternateMethodsComment)
        missingAttributes.push('FEASIBILITY|feasibilityNoAlternateMethodsComment');
      if(missingAttributes.length) return { missingAttributes };
      
      const now = Date.now();
      const quoteToPut = {
        ...quoteDataCopy,
        ...this.#globalData,
        ...this.#generalData,
        ...this.#assemblyData,
        ...this.#purchasingData,
        ...this.#packagingData,
        ...this.#auxData,
        ...this.#capitalCostData,
        ...this.#testingData,
        ...this.#feasibilityData,
        ...(this.#bomData ? this.#bomData : []),
        moldedParts: this.#moldedData,
        status: 'SALES_APPROVAL',
        dateTimeFeasibilityApproved: now,
        feasibilityApprovedUserId: userId,
        feasibilityApprovedUsername: loggedInUser,
        dateTimeSalesApprovalRejected: null,
        salesApprovalRejectedUserId: null,
        salesApprovalRejectedUsername: null,
      };
      
      const userAssignments = [{
        userId: quoteData.createdBy,
        username: quoteData.createdByUsername,
        types: ['OWNER'],
      }];
      
      const activityLogFuncs = [
        () => createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'STATUS_CHANGE',
          `Quote status changed to SALES APPROVAL`,
          loggedInUser,
        ),
        () => createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'ASSIGNMENT',
          `Quote assigned to ${quoteData.createdByUsername} because it passed feasibility`,
          loggedInUser,
        ),
      ];
      
      generateChangeFuncs(quoteToPut, quoteData, activityLogFuncs);
      
      // update quote
      const updatedQuote = await updateQuote(accessToken, quoteId, revision, quoteToPut, userId, loggedInUser);
      await assignQuote(accessToken, quoteId, revision, userAssignments);
      if(this.#feasibilityComments) await createQuoteComment(
        accessToken,
        quoteId,
        revision,
        'FEASIBILITY',
        `Quote feasibility approved with comment: ${this.#feasibilityComments}`,
        loggedInUser,
      );
      await Promise.all(activityLogFuncs.map(f => f()));
      
      return {
        ...updatedQuote,
        assignments: userAssignments,
      };
    }
    else if(action === this.#actions.SEND_BACK_SALES_APPROVAL) {
      // check required data exists
      if(!this.#globalData) throw new Error('Cannot complete SEND_BACK_SALES_APPROVAL action -> no global data available');
      if(!this.#userRoles) throw new Error('Cannot complete SEND_BACK_SALES_APPROVAL action -> no userRoles available');
      if(!this.#userRoles.some(roleId => CONFIG.ROLE_MAPPINGS.CREATE_QUOTE === roleId))
        throw new Error('Cannot complete SEND_BACK_SALES_APPROVAL action -> user does not have permission');
      
      const assignmentForUser = assignments.find(o => o.userId === userId);
      if(!assignmentForUser) throw new Error('Cannot complete SEND_BACK_SALES_APPROVAL action -> no assignments for current user');
      
      // check required attributes exist
      let missingAttributes = [];
      // global data
      if(!this.#globalData.dueDate) missingAttributes.push('GLOBAL|dueDate');
      if(!this.#rejectSalesCheckComments) missingAttributes.push('GLOBAL|sendBackFeasibilityComment');
      if(missingAttributes.length) return { missingAttributes };
      
      const now = Date.now();
      const quoteToPut = {
        ...quoteDataCopy,
        ...this.#globalData,
        status: 'FEASIBILITY',
        dateTimeSalesApprovalRejected: now,
        salesApprovalRejectedUserId: userId,
        salesApprovalRejectedUsername: loggedInUser,
        feasibilityApprovedUserId: null,
        feasibilityApprovedUsername: null,
        dateTimeFeasibilityApproved: null,
      };
      
      const userAssignments = [{
        userId: quoteData.feasibilityApprovedUserId,
        username: quoteData.feasibilityApprovedUsername,
        types: ['FEASIBILITY'],
      }];
      
      const activityLogFuncs = [
        () => createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'STATUS_CHANGE',
          `Quote status changed to FEASIBILITY`,
          loggedInUser,
        ),
        () => createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'ASSIGNMENT',
          `Quote assigned to ${quoteData.feasibilityApprovedUsername} because is failed the sales approval`,
          loggedInUser,
        ),
      ];
      
      generateChangeFuncs(quoteToPut, quoteData, activityLogFuncs);
      
      // update quote
      const updatedQuote = await updateQuote(accessToken, quoteId, revision, quoteToPut, userId, loggedInUser);
      await assignQuote(accessToken, quoteId, revision, userAssignments);
      await createQuoteComment(
        accessToken,
        quoteId,
        revision,
        'REQUEST_FOR_CHANGES',
        `Quote sales approval sent back with comment: ${this.#rejectSalesCheckComments}`,
        loggedInUser,
      );
      await Promise.all(activityLogFuncs.map(f => f()));
      
      return {
        ...updatedQuote,
        assignments: userAssignments,
      };
    }
    else if(action === this.#actions.SALES_APPROVAL) {
      // check required data exists
      if(!this.#globalData) throw new Error('Cannot complete SALES_APPROVAL action -> no global data available');
      if(!this.#userRoles) throw new Error('Cannot complete SALES_APPROVAL action -> no userRoles available');
      if(!this.#userRoles.some(roleId => CONFIG.ROLE_MAPPINGS.CREATE_QUOTE === roleId))
        throw new Error('Cannot complete SALES_APPROVAL action -> user does not have permission');
      
      const assignmentForUser = assignments.find(o => o.userId === userId);
      if(!assignmentForUser) throw new Error('Cannot complete SALES_APPROVAL action -> no assignments for current user');
      
      // check required attributes exist
      let missingAttributes = [];
      // global data
      if(!this.#globalData.dueDate) missingAttributes.push('GLOBAL|dueDate');
      if(missingAttributes.length) return { missingAttributes };
      
      const now = Date.now();
      const quoteToPut = {
        ...quoteDataCopy,
        ...this.#globalData,
        status: 'COMPLETED',
        dateTimeSalesApprovalComplete: now,
        salesApprovalUserId: userId,
        salesApprovalUsername: loggedInUser,
      };
      
      // build user assignments array
      const userAssignments = [{
        userId: quoteData.createdBy,
        username: quoteData.createdByUsername,
        types: ['OWNER'],
      }];
      
      const activityLogFuncs = [
        () => createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'STATUS_CHANGE',
          `Quote status changed to COMPLETED`,
          loggedInUser,
        ),
        () => createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'ASSIGNMENT',
          `Quote assigned to ${quoteData.createdByUsername} because it passed the sales approval`,
          loggedInUser,
        ),
      ];
      
      generateChangeFuncs(quoteToPut, quoteData, activityLogFuncs);
      
      // update quote
      const updatedQuote = await updateQuote(accessToken, quoteId, revision, quoteToPut, userId, loggedInUser);
      await assignQuote(accessToken, quoteId, revision, userAssignments);
      await Promise.all(activityLogFuncs.map(f => f()));
      
      return {
        ...updatedQuote,
        assignments: userAssignments,
      };
    }
    else if(action === this.#actions.SAVE_COMPLETE) {
      if(!this.#completeData) throw new Error('Cannot complete SAVE_COMPLETE action -> no cancellation data available');
      if(!this.#completeData.quoteStatus) throw new Error('Cannot complete SAVE_COMPLETE action -> no new quote status data available');
      if(!this.#userRoles) throw new Error('Cannot complete SAVE_COMPLETE action -> no userRoles available');
      if(!this.#userRoles.includes(CONFIG.ROLE_MAPPINGS.CREATE_QUOTE))
        throw new Error('Cannot complete CANCEL action -> user does not have permission');
      
      const assignmentForUser = assignments.find(o => o.userId === userId);
      if(!assignmentForUser) throw new Error('Cannot complete SAVE_COMPLETE action -> no assignments for current user');
      
      // check required attributes exist
      let missingAttributes = [];
      if(!this.#completeData.awardProbability) missingAttributes.push('GLOBAL|awardProbability');
      if(!this.#completeData.fgPrice) missingAttributes.push('GLOBAL|fgPrice');
      if(!this.#completeData.royaltyPercentage) missingAttributes.push('GLOBAL|royaltyPercentage');
      if(this.#completeData.quoteStatus === 'LOST' && !this.#completeData.lostReason) missingAttributes.push('GLOBAL|lostReason');
      if(this.#completeData.quoteStatus === 'LOST' && !this.#lostReasonComments) missingAttributes.push('GLOBAL|lostReasonComment');
      if(missingAttributes.length) return { missingAttributes };
      
      let completeData = cloneDeep(this.#completeData);
      delete completeData.quoteStatus;
      
      const quoteToPut = {
        ...quoteDataCopy,
        ...completeData,
        status: this.#completeData.quoteStatus,
      };
      
      if(this.#completeData.quoteStatus !== 'LOST') quoteToPut.lostReason = null;
      
      const activityLogFuncs = [
        () => createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'STATUS_CHANGE',
          `Quote status changed to ${this.#completeData.quoteStatus}`,
          loggedInUser,
        ),
      ];
      
      generateChangeFuncs(quoteToPut, quoteData, activityLogFuncs);
      
      // update quote
      const updatedQuote = await updateQuote(accessToken, quoteId, revision, quoteToPut, userId, loggedInUser);
      if(this.#lostReasonComments) await createQuoteComment(
        accessToken,
        quoteId,
        revision,
        'GENERAL',
        `Quote status was changed to lost with comment: ${this.#lostReasonComments}`,
        loggedInUser,
      );
      await Promise.all(activityLogFuncs.map(f => f()));
      
      return {
        ...updatedQuote,
        assignments: assignments,
      };
    }
    else if(action === this.#actions.CREATE_REVISION) {
      // check required data exists
      if(!this.#globalData) throw new Error('Cannot complete CREATE_REVISION action -> no global data available');
      if(!this.#generalData) throw new Error('Cannot complete CREATE_REVISION action -> no general data available');
      if(!this.#userRoles) throw new Error('Cannot complete CREATE_REVISION action -> no userRoles available');
      if(!this.#userRoles.includes(CONFIG.ROLE_MAPPINGS.CREATE_QUOTE))
        throw new Error('Cannot complete CREATE_REVISION action -> user does not have permission');
      
      quoteDataCopy = returnQuoteToDraft(quoteDataCopy);
      
      const newRevision = quoteData.revision + 1;
      
      const quoteToPut = {
        ...quoteDataCopy,
        ...this.#globalData,
        ...this.#generalData,
        status: 'DRAFT',
      };
      
      const activityLogFuncs = [
        () => createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'QUOTE_REVISION',
          `Quote revision changed to ${newRevision}`,
          loggedInUser,
        ),
        () => createQuoteActivityLog(
          accessToken,
          quoteId,
          newRevision,
          'STATUS_CHANGE',
          `Quote status changed to DRAFT`,
          loggedInUser,
        ),
      ];
      
      generateChangeFuncs(quoteToPut, quoteData, activityLogFuncs);
      
      const updatedQuote = await updateQuote(accessToken, quoteId, newRevision, quoteToPut, userId, loggedInUser);
      
      const userAssignments = [{
        userId,
        username: loggedInUser,
        types: ['OWNER'],
      }];
      
      await assignQuote(accessToken, quoteId, newRevision, userAssignments);
      await Promise.all(activityLogFuncs.map(f => f()));
      
      return {
        ...updatedQuote,
        assignments: userAssignments,
      };
    }
    else if(action === this.#actions.PROMOTE_SANDBOX) {
      // check required data exists
      if(!this.#globalData) throw new Error('Cannot complete PROMOTE_SANDBOX action -> no global data available');
      if(!this.#generalData) throw new Error('Cannot complete PROMOTE_SANDBOX action -> no general data available');
      if(!this.#moldedData) throw new Error('Cannot complete PROMOTE_SANDBOX action -> no molded data available');
      if(!this.#assemblyData) throw new Error('Cannot complete PROMOTE_SANDBOX action -> no assembly data available');
      if(!this.#purchasingData) throw new Error('Cannot complete PROMOTE_SANDBOX action -> no purchasing data available');
      if(!this.#packagingData) throw new Error('Cannot complete PROMOTE_SANDBOX action -> no packaging data available');
      if(!this.#auxData) throw new Error('Cannot complete PROMOTE_SANDBOX action -> no aux data available');
      if(!this.#capitalCostData) throw new Error('Cannot complete PROMOTE_SANDBOX action -> no capital cost data available');
      if(!this.#testingData) throw new Error('Cannot complete PROMOTE_SANDBOX action -> no testing data available');
      if(!this.#feasibilityData) throw new Error('Cannot complete PROMOTE_SANDBOX action -> no feasibility data available');
      if(!this.#completeData) throw new Error('Cannot complete PROMOTE_SANDBOX action -> no complete data available');
      
      let completeData = cloneDeep(this.#completeData);
      delete completeData.quoteStatus;
      
      const quoteToPut = {
        ...quoteDataCopy,
        ...this.#globalData,
        ...this.#generalData,
        ...this.#assemblyData,
        ...this.#purchasingData,
        ...this.#packagingData,
        ...this.#auxData,
        ...this.#capitalCostData,
        ...this.#testingData,
        ...this.#feasibilityData,
        ...(this.#bomData ? this.#bomData : []),
        ...completeData,
        moldedParts: this.#moldedData,
        status: 'DRAFT',
      };
      
      // get quote with latest revision
      const latestQuote = await getQuoteByIdOnly(accessToken, quoteId);
      if(!latestQuote) throw new Error('Cannot complete PROMOTE_SANDBOX action -> could not find latest quote');
      if(latestQuote.holdUserId) throw new Error('QUOTE_ON_HOLD');
      const newRevision = latestQuote.revision + 1;
      
      // update quote
      const activityLogFuncs = [
        () => createQuoteActivityLog(
          accessToken,
          quoteId,
          latestQuote.revision,
          'QUOTE_REVISION',
          `Quote revision changed to ${newRevision}`,
          loggedInUser,
        ),
        () => createQuoteActivityLog(
          accessToken,
          quoteId,
          newRevision,
          'STATUS_CHANGE',
          `Quote status changed to DRAFT`,
          loggedInUser,
        ),
      ];
      
      generateChangeFuncs(quoteToPut, latestQuote, activityLogFuncs);
      
      const updatedQuote = await updateQuote(accessToken, quoteId, newRevision, quoteToPut, userId, loggedInUser);
      
      const userAssignments = [{
        userId,
        username: loggedInUser,
        types: ['OWNER'],
      }];
      
      await assignQuote(accessToken, quoteId, newRevision, userAssignments);
      await Promise.all(activityLogFuncs.map(f => f()));
      
      // delete sandbox quote
      await deleteSandboxQuote(accessToken, quoteId, revision, userId);
      
      return {
        ...updatedQuote,
        assignments: userAssignments,
      };
    }
    else if(action === this.#actions.CREATE_SANDBOX) {
      // check required data exists
      if(!this.#globalData) throw new Error('Cannot complete CREATE_SANDBOX action -> no global data available');
      if(!this.#generalData) throw new Error('Cannot complete CREATE_SANDBOX action -> no general data available');
      if(!this.#userRoles) throw new Error('Cannot complete CREATE_SANDBOX action -> no userRoles available');
      if(!this.#userRoles.includes(CONFIG.ROLE_MAPPINGS.CREATE_QUOTE))
        throw new Error('Cannot complete CREATE_SANDBOX action -> user does not have permission');
      
      const originalQuote = cloneDeep(quoteDataCopy);
      quoteDataCopy = returnQuoteToDraft(quoteDataCopy);
      
      const quoteToPut = {
        ...quoteDataCopy,
        ...this.#globalData,
        ...this.#generalData,
        status: 'SANDBOX',
        createdBy: userId,
        createdByUsername: loggedInUser,
      };
      
      const updatedQuote = await updateSandboxQuote(accessToken, quoteId, revision, quoteToPut, userId, loggedInUser);
      await updateQuote(accessToken, quoteId, revision, originalQuote, userId, loggedInUser);
      
      return {
        ...updatedQuote,
        assignments: [{
          userId,
          username: loggedInUser,
          types: ['OWNER'],
        }],
      };
    }
    else if(action === this.#actions.CANCEL) {
      if(!this.#cancellationData) throw new Error('Cannot complete CANCEL action -> no cancellation data available');
      if(!this.#userRoles) throw new Error('Cannot complete CANCEL action -> no userRoles available');
      if(!this.#userRoles.includes(CONFIG.ROLE_MAPPINGS.CREATE_QUOTE))
        throw new Error('Cannot complete CANCEL action -> user does not have permission');
      
      quoteDataCopy = returnQuoteToDraft(quoteDataCopy);
      
      const quoteToPut = { ...quoteDataCopy, status: 'DRAFT' };
      
      const activityLogFuncs = [
        () => createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'STATUS_CHANGE',
          `Quote status changed to DRAFT`,
          loggedInUser,
        ),
        () => createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'ASSIGNMENT',
          `Quote assigned to ${quoteData.createdByUsername} because it was cancelled`,
          loggedInUser,
        ),
      ];
      
      generateChangeFuncs(quoteToPut, quoteData, activityLogFuncs);
      
      const userAssignments = [{
        userId: quoteData.createdBy,
        username: quoteData.createdByUsername,
        types: ['OWNER'],
      }];
      
      const updatedQuote = await updateQuote(accessToken, quoteId, revision, quoteToPut, userId, loggedInUser);
      await assignQuote(accessToken, quoteId, revision, userAssignments);
      await createQuoteComment(
        accessToken,
        quoteId,
        revision,
        'CANCELLATION',
        `Quote cancelled with reason ${this.#cancellationData.reason?.name}: ${this.#cancellationData.comment}`,
        loggedInUser,
      );
      await Promise.all(activityLogFuncs.map(f => f()));
      
      return {
        ...updatedQuote,
        assignments: userAssignments,
      };
    }
    else if(action === this.#actions.DELETE) {
      if(!this.#userRoles) throw new Error('Cannot complete DELETE action -> no userRoles available');
      if(!this.#userRoles.includes(CONFIG.ROLE_MAPPINGS.CREATE_QUOTE))
        throw new Error('Cannot complete DELETE action -> user does not have permission');
      
      await updateQuote(accessToken, quoteId, revision, { ...quoteDataCopy, isDeleted: 'X' }, userId, loggedInUser);
      await assignQuote(accessToken, quoteId, revision, []);
    }
    else if(action === this.#actions.DELETE_SANDBOX) {
      if(!this.#userRoles) throw new Error('Cannot complete DELETE_SANDBOX action -> no userRoles available');
      if(!this.#userRoles.includes(CONFIG.ROLE_MAPPINGS.CREATE_QUOTE))
        throw new Error('Cannot complete DELETE_SANDBOX action -> user does not have permission');
      
      // delete sandbox quote
      await deleteSandboxQuote(accessToken, quoteId, revision, userId);
    }
    else if(action === this.#actions.REASSIGN) {
      if(!this.#userRoles) throw new Error('Cannot complete REASSIGN action -> no userRoles available');
      if(!this.#userRoles.includes(CONFIG.ROLE_MAPPINGS.REASSIGN_QUOTE))
        throw new Error('Cannot complete REASSIGN action -> user does not have permission');
      if(!this.#reassignees) throw new Error('Cannot complete REASSIGN action -> no reassignees available');
      
      // check required attributes exist
      let missingAttributes = [];
      // reassignee data
      if(!Object.values(this.#reassignees).some(o => o != null)) {
        missingAttributes.push('GLOBAL|reassignOwnerAssignee');
        missingAttributes.push('GLOBAL|reassignFeasibilityAssignee');
        missingAttributes.push('GLOBAL|reassignDesignEstimatorAssignee');
        missingAttributes.push('GLOBAL|reassignToolingEstimatorAssignee');
        missingAttributes.push('GLOBAL|reassignManufacturingEstimatorAssignee');
        missingAttributes.push('GLOBAL|reassignPurchasingEstimatorAssignee');
      }
      if(missingAttributes.length) return { missingAttributes };
      
      // get current assignees
      const currentOwnerAssigneeObj = assignments.find(o => o.types.includes('OWNER'));
      const currentFeasibilityAssigneeObj = assignments.find(o => o.types.includes('FEASIBILITY'));
      const currentDesignAssigneeObj = assignments.find(o => o.types.includes('DESIGN_ESTIMATOR'));
      const currentToolingAssigneeObj = assignments.find(o => o.types.includes('TOOLING_ESTIMATOR'));
      const currentManufacturingAssigneeObj = assignments.find(o => o.types.includes('MANUFACTURING_ESTIMATOR'));
      const currentPurchasingAssigneeObj = assignments.find(o => o.types.includes('PURCHASING_ESTIMATOR'));
      
      // build user assignments
      let userAssignments = [];
      const activityLogFuncs = [];
      let quoteToPut;
      if(this.#reassignees.ownerAssignee || currentOwnerAssigneeObj) {
        const userId = this.#reassignees.ownerAssignee ?
          this.#reassignees.ownerAssignee.code :
          currentOwnerAssigneeObj.userId;
        const username = this.#reassignees.ownerAssignee ?
          this.#reassignees.ownerAssignee.name :
          currentOwnerAssigneeObj.username;
        quoteToPut = {
          ...quoteDataCopy,
          createdBy: userId,
          createdByUsername: username,
        };
        userAssignments.push({
          userId,
          username,
          types: ['OWNER'],
        });
        activityLogFuncs.push(() => createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'ASSIGNMENT',
          `Quote reassigned to ${username} for ownership`,
          loggedInUser,
        ));
      }
      if(this.#reassignees.feasibilityAssignee || currentFeasibilityAssigneeObj) {
        const userId = this.#reassignees.feasibilityAssignee ?
          this.#reassignees.feasibilityAssignee.code :
          currentFeasibilityAssigneeObj.userId;
        const username = this.#reassignees.feasibilityAssignee ?
          this.#reassignees.feasibilityAssignee.name :
          currentFeasibilityAssigneeObj.username;
        userAssignments.push({
          userId,
          username,
          types: ['FEASIBILITY'],
        });
        activityLogFuncs.push(() => createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'ASSIGNMENT',
          `Quote reassigned to ${username} for feasibility`,
          loggedInUser,
        ));
      }
      if(this.#reassignees.designEstimatorAssignee || currentDesignAssigneeObj) {
        const userId = this.#reassignees.designEstimatorAssignee ?
          this.#reassignees.designEstimatorAssignee.code :
          currentDesignAssigneeObj.userId;
        const username = this.#reassignees.designEstimatorAssignee ?
          this.#reassignees.designEstimatorAssignee.name :
          currentDesignAssigneeObj.username;
        userAssignments.push({
          userId,
          username,
          types: ['DESIGN_ESTIMATOR'],
        });
        activityLogFuncs.push(() => createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'ASSIGNMENT',
          `Quote reassigned to ${username} for design estimation`,
          loggedInUser,
        ));
      }
      if(this.#reassignees.toolingEstimatorAssignee || currentToolingAssigneeObj) {
        const userId = this.#reassignees.toolingEstimatorAssignee ?
          this.#reassignees.toolingEstimatorAssignee.code :
          currentToolingAssigneeObj.userId;
        const username = this.#reassignees.toolingEstimatorAssignee ?
          this.#reassignees.toolingEstimatorAssignee.name :
          currentToolingAssigneeObj.username;
        userAssignments.push({
          userId,
          username,
          types: ['TOOLING_ESTIMATOR'],
        });
        activityLogFuncs.push(() => createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'ASSIGNMENT',
          `Quote reassigned to ${username} for tooling estimation`,
          loggedInUser,
        ));
      }
      if(this.#reassignees.manufacturingEstimatorAssignee || currentManufacturingAssigneeObj) {
        const userId = this.#reassignees.manufacturingEstimatorAssignee ?
          this.#reassignees.manufacturingEstimatorAssignee.code :
          currentManufacturingAssigneeObj.userId;
        const username = this.#reassignees.manufacturingEstimatorAssignee ?
          this.#reassignees.manufacturingEstimatorAssignee.name :
          currentManufacturingAssigneeObj.username;
        userAssignments.push({
          userId,
          username,
          types: ['MANUFACTURING_ESTIMATOR'],
        });
        activityLogFuncs.push(() => createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'ASSIGNMENT',
          `Quote reassigned to ${username} for manufacturing estimation`,
          loggedInUser,
        ));
      }
      if(this.#reassignees.purchasingEstimatorAssignee || currentPurchasingAssigneeObj) {
        const userId = this.#reassignees.purchasingEstimatorAssignee ?
          this.#reassignees.purchasingEstimatorAssignee.code :
          currentPurchasingAssigneeObj.userId;
        const username = this.#reassignees.purchasingEstimatorAssignee ?
          this.#reassignees.purchasingEstimatorAssignee.name :
          currentPurchasingAssigneeObj.username;
        userAssignments.push({
          userId,
          username,
          types: ['PURCHASING_ESTIMATOR'],
        });
        activityLogFuncs.push(() => createQuoteActivityLog(
          accessToken,
          quoteId,
          revision,
          'ASSIGNMENT',
          `Quote reassigned to ${username} for purchasing estimation`,
          loggedInUser,
        ));
      }
      
      // merge same users into one record
      userAssignments = mergeUserAssignments(userAssignments);
      
      // complete reassignment update
      const updatedQuote = await updateQuote(accessToken, quoteId, revision, quoteToPut ? quoteToPut : quoteDataCopy, userId, loggedInUser);
      await assignQuote(accessToken, quoteId, revision, userAssignments);
      await Promise.all(activityLogFuncs.map(f => f()));
      
      return {
        ...updatedQuote,
        assignments: userAssignments,
      };
    }
  }
}

export default QuoteProcessor;