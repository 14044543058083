import { Button, Form, InputGroup, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faDownload, faTrashAlt, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import COLORS from '../styles/colors';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import {
  productTypeLovAtom,
  partTypeLovAtom,
  partGlobalCategoryLovAtom,
  ppapTimingLovAtom,
  toolTypeLovAtom,
  designResponsibilityLovAtom,
  businessTypeLovAtom,
  salesExpectationLovAtom,
  quoteDataAtom,
  saveQuoteTriggerAtom,
  quoteProcessorAtom,
  customersLovAtom,
  quoteMissingAttributesAtom,
  generalProductTypeSelectAtom,
  appRolesAtom,
  userIdAtom,
  loadingMessageSelector,
  accessTokenAtom,
  loggedInUserAtom,
  quoteAttachmentsAtom,
  quoteActivitiesAtom,
  programsLovAtom,
  currentViewSelector,
  partTypeIhsLovAtom,
  complexityIhsLovAtom,
  initValuesAtom,
  bomDataAtom,
  moldedPartsValueAtom,
  purchasedPartCostsAtom,
  carryoverPartCostsAtom,
  packagingCostsAtom,
} from '../state';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import GLOBAL_STYLES from '../styles';
import * as CONFIG from '../config';
import cloneDeep from 'lodash.clonedeep';
import {
  getUploadUrl,
  uploadAttachedDocument,
  createAttachmentRecord,
  deleteAttachment,
  getDownloadUrl,
  createQuoteActivityLog,
} from '../helpers/api';
import { readFileData, getProgramsGrouped, getPlatformsGrouped, cmp } from '../helpers/misc';
import { useAlert } from 'react-alert';
import { DateTime } from 'luxon';
import Logger from '../helpers/Logger';
import CarryoverPartsSearchModal from './CarryoverPartsSearchModal';

const logger = new Logger();

const QuoteTabGeneral = () => {
  const [documentsVisible, setDocumentsVisible] = useState(false);
  const [filePickerValue, setFilePickerValue] = useState('');
  const [filePickerFiles, setFilePickerFiles] = useState(null);
  const [fileDescriptions, setFileDescriptions] = useState(null);
  const [generalProductTypeSelect, setGeneralProductTypeSelect] = useRecoilState(generalProductTypeSelectAtom);
  const [partTypeSelect, setPartTypeSelect] = useState('NULL');
  const [partGlobalCategorySelect, setPartGlobalCategorySelect] = useState('NULL');
  const [complexitySelect, setComplexitySelect] = useState('NULL');
  const [ppapTimingSelect, setPpapTimingSelect] = useState('NULL');
  const [toolTypeSelect, setToolTypeSelect] = useState('NULL');
  const [designResponsibilitySelect, setDesignResponsibilitySelect] = useState('NULL');
  const [businessTypeSelect, setBusinessTypeSelect] = useState('NULL');
  const [salesExpectationSelect, setSalesExpectationSelect] = useState('NULL');
  const [customerNameValue, setCustomerNameValue] = useState(null);
  const [customerNameInputValue, setCustomerNameInputValue] = useState('');
  const [programValue, setProgramValue] = useState([]);
  const [programInputValue, setProgramInputValue] = useState('');
  const [programChangeValue, setProgramChangeValue] = useState(null);
  const [programChangeInputValue, setProgramChangeInputValue] = useState('');
  const [platformProgramTbd, setPlatformProgramTbd] = useState(false);
  const [platformValue, setPlatformValue] = useState(null);
  const [platformValueInput, setPlatformValueInput] = useState('');
  const [productTypeValue, setProductTypeValue] = useState(null);
  const [productTypeInputValue, setProductTypeInputValue] = useState('');
  const [materialTypeValue, setMaterialTypeValue] = useState('');
  const [generalColorValue, setGeneralColorValue] = useState('');
  const [specificColorValue, setSpecificColorValue] = useState('');
  const [ppapTimingValue, setPpapTimingValue] = useState('');
  const [drawingRevValue, setDrawingRevValue] = useState('');
  const [programLifeValue, setProgramLifeValue] = useState('');
  const [specialInstructionsValue, setSpecialInstructionsValue] = useState('');
  const [customerPartNumberValue, setCustomerPartNumberValue] = useState('');
  const [partDescriptionValue, setPartDescriptionValue] = useState('');
  const [estimatedPpvValue, setEstimatedPpvValue] = useState('');
  const [previousPartNumberValue, setPreviousPartNumberValue] = useState('');
  const [targetPriceValue, setTargetPriceValue] = useState('');
  const [monthlyVolumeValue, setMonthlyVolumeValue] = useState('');
  const [monthlyCarVolumeValue, setMonthlyCarVolumeValue] = useState('');
  const [carryoverPartSearchModalIsOpen, setCarryoverPartSearchModalIsOpen] = useState(false);
  const partTypeIhsLov = useRecoilValue(partTypeIhsLovAtom);
  const productTypeLov = useRecoilValue(productTypeLovAtom);
  const partTypeLov = useRecoilValue(partTypeLovAtom);
  const partGlobalCategoryLov = useRecoilValue(partGlobalCategoryLovAtom);
  const complexityIhsLov = useRecoilValue(complexityIhsLovAtom);
  const ppapTimingLov = useRecoilValue(ppapTimingLovAtom);
  const toolTypeLov = useRecoilValue(toolTypeLovAtom);
  const designResponsibilityLov = useRecoilValue(designResponsibilityLovAtom);
  const businessTypeLov = useRecoilValue(businessTypeLovAtom);
  const salesExpectationLov = useRecoilValue(salesExpectationLovAtom);
  const quoteData = useRecoilValue(quoteDataAtom);
  const programsLov = useRecoilValue(programsLovAtom);
  const customersLov = useRecoilValue(customersLovAtom);
  const saveQuoteTrigger = useRecoilValue(saveQuoteTriggerAtom);
  const quoteProcessor = useRecoilValue(quoteProcessorAtom);
  const [initValues, setInitValues] = useRecoilState(initValuesAtom);
  const quoteMissingAttributes = useRecoilValue(quoteMissingAttributesAtom);
  const appRoles = useRecoilValue(appRolesAtom);
  const userId = useRecoilValue(userIdAtom);
  const accessToken = useRecoilValue(accessTokenAtom);
  const loggedInUser = useRecoilValue(loggedInUserAtom);
  const setLoadingMessage = useSetRecoilState(loadingMessageSelector);
  const setQuoteActivities = useSetRecoilState(quoteActivitiesAtom);
  const [quoteAttachments, setQuoteAttachments] = useRecoilState(quoteAttachmentsAtom);
  const currentView = useRecoilValue(currentViewSelector);
  const alert = useAlert();
  const setBomData = useSetRecoilState(bomDataAtom);
  const setMoldedPartsValue = useSetRecoilState(moldedPartsValueAtom);
  const setPurchasedPartCosts = useSetRecoilState(purchasedPartCostsAtom);
  const setCarryoverPartCosts = useSetRecoilState(carryoverPartCostsAtom);
  const setPackagingCosts = useSetRecoilState(packagingCostsAtom);
  
  useEffect(() => {
    if(saveQuoteTrigger) {
      quoteProcessor.setGeneralData({
        productType: generalProductTypeSelect !== 'NULL' ? generalProductTypeSelect : null,
        customerName: customerNameValue && customerNameValue.trim().length ? customerNameValue.trim() : null,
        productTypeIhs: productTypeValue ? productTypeValue.code : null,
        programs: programValue.map(o => o.plantPrograms).flat(),
        platform: platformProgramTbd ? 'TBD' : platformValue ? platformValue.platform : null,
        platformId: platformProgramTbd ? 'TBD' : platformValue ? platformValue.platformId : null,
        programChangeDate: programChangeValue ? programChangeValue.changeDate : null,
        programChangeType: programChangeValue ? programChangeValue.changeType : null,
        partType: partTypeSelect !== 'NULL' ? partTypeSelect : null,
        partCategory: partGlobalCategorySelect !== 'NULL' ? partGlobalCategorySelect : null,
        complexity: complexitySelect !== 'NULL' ? complexitySelect : null,
        ppapTiming: ppapTimingValue && !isNaN(parseInt(ppapTimingValue)) ? parseInt(ppapTimingValue) : null,
        ppapTimingType: ppapTimingSelect !== 'NULL' ? ppapTimingSelect : null,
        toolType: toolTypeSelect !== 'NULL' ? toolTypeSelect : null,
        drawingRevLevel: drawingRevValue.trim().length ? drawingRevValue.trim() : null,
        materialType: materialTypeValue.trim().length ? materialTypeValue.trim() : null,
        generalColor: generalColorValue.trim().length ? generalColorValue.trim() : null,
        specificColor: specificColorValue.trim().length ? specificColorValue.trim() : null,
        programLife: programLifeValue && !isNaN(parseInt(programLifeValue)) ? parseInt(programLifeValue) : null,
        designResponsibility: designResponsibilitySelect !== 'NULL' ? designResponsibilitySelect : null,
        businessType: businessTypeSelect !== 'NULL' ? businessTypeSelect : null,
        salesExpectation: salesExpectationSelect !== 'NULL' ? salesExpectationSelect : null,
        specialInstructions: specialInstructionsValue.trim().length ? specialInstructionsValue.trim() : null,
        customerPartNumber: customerPartNumberValue.trim().length ? customerPartNumberValue.trim() : null,
        partDescription: partDescriptionValue.trim().length ? partDescriptionValue.trim() : null,
        previousPartNumber: previousPartNumberValue.trim().length ? previousPartNumberValue.trim() : null,
        estimatedPpv: estimatedPpvValue && !isNaN(parseFloat(estimatedPpvValue)) ? parseFloat(estimatedPpvValue) : null,
        targetPrice: targetPriceValue && !isNaN(parseFloat(targetPriceValue)) ? parseFloat(targetPriceValue) : null,
        monthlyVolume: monthlyVolumeValue && !isNaN(parseInt(monthlyVolumeValue)) ? parseInt(monthlyVolumeValue) : null,
        monthlyCarVolume: monthlyCarVolumeValue && !isNaN(parseInt(monthlyCarVolumeValue)) ? parseInt(monthlyCarVolumeValue) : null,
      });
    }
  }, [
    saveQuoteTrigger,
    quoteProcessor,
    generalProductTypeSelect,
    customerNameValue,
    partTypeSelect,
    partGlobalCategorySelect,
    complexitySelect,
    ppapTimingSelect,
    toolTypeSelect,
    designResponsibilitySelect,
    businessTypeSelect,
    salesExpectationSelect,
    materialTypeValue,
    generalColorValue,
    specificColorValue,
    ppapTimingValue,
    drawingRevValue,
    programLifeValue,
    specialInstructionsValue,
    customerPartNumberValue,
    partDescriptionValue,
    estimatedPpvValue,
    targetPriceValue,
    monthlyVolumeValue,
    monthlyCarVolumeValue,
    programsLov,
    programValue,
    platformValue,
    productTypeValue,
    previousPartNumberValue,
    platformProgramTbd,
    programChangeValue,
  ]);
  
  useEffect(() => {
    if(initValues && quoteData) {
      const selectedProductTypeObj = quoteData.productTypeIhs ?
        partTypeIhsLov.find(o => o.code === quoteData.productTypeIhs) :
        null;
      const programsGrouped = getProgramsGrouped(programsLov);
      const platformsGrouped = getPlatformsGrouped(programsLov);
      const tempProgramValue = quoteData.programs ? programsGrouped.filter(o => quoteData.programs.some(p => p.programId === o.programId)) : [];
      const programChangeOptions = tempProgramValue?.length ?
        [...tempProgramValue.map(p => p.plantPrograms.map(o => o.changeList)).flat(2).reduce((map, cur) => {
          map.set(`${cur.changeDate}|${cur.changeType}`, { ...cur, name: `${cur.changeDate} - ${cur.changeType}` });
          return map;
        }, new Map()).values()].sort((a, b) => cmp(a.changeDate, b.changeDate)) :
        [];
      setGeneralProductTypeSelect(quoteData.productType ? quoteData.productType : 'NULL');
      setCustomerNameValue(quoteData.customerName ? quoteData.customerName : null);
      setProductTypeValue(selectedProductTypeObj ? selectedProductTypeObj : null);
      setProgramValue(tempProgramValue);
      setPlatformValue(quoteData.platformId && quoteData.platformId !== 'TBD' ? platformsGrouped.find(o => o.platformId === quoteData.platformId) : null);
      setProgramChangeValue(quoteData.programChangeDate && quoteData.programChangeType && programChangeOptions?.length ? programChangeOptions.find(o => o.changeDate === quoteData.programChangeDate && o.changeType === quoteData.programChangeType) : null);
      setPlatformProgramTbd(quoteData.platformId === 'TBD');
      setPartTypeSelect(quoteData.partType ? quoteData.partType : 'NULL');
      setPartGlobalCategorySelect(quoteData.partCategory ? quoteData.partCategory : 'NULL');
      setComplexitySelect(quoteData.complexity ? quoteData.complexity : 'NULL');
      setPpapTimingValue(quoteData.ppapTiming != null ? `${quoteData.ppapTiming}` : '');
      setPpapTimingSelect(quoteData.ppapTimingType ? quoteData.ppapTimingType : 'NULL');
      setToolTypeSelect(quoteData.toolType ? quoteData.toolType : 'NULL');
      setDrawingRevValue(quoteData.drawingRevLevel ? quoteData.drawingRevLevel : '');
      setMaterialTypeValue(quoteData.materialType ? quoteData.materialType : '');
      setGeneralColorValue(quoteData.generalColor ? quoteData.generalColor : '');
      setSpecificColorValue(quoteData.specificColor ? quoteData.specificColor : '');
      setProgramLifeValue(quoteData.programLife != null ? quoteData.programLife : '');
      setDesignResponsibilitySelect(quoteData.designResponsibility ? quoteData.designResponsibility : 'NULL');
      setBusinessTypeSelect(quoteData.businessType ? quoteData.businessType : 'NULL');
      setSalesExpectationSelect(quoteData.salesExpectation ? quoteData.salesExpectation : 'NULL');
      setSpecialInstructionsValue(quoteData.specialInstructions ? quoteData.specialInstructions : '');
      setCustomerPartNumberValue(quoteData.customerPartNumber ? quoteData.customerPartNumber : '');
      setPartDescriptionValue(quoteData.partDescription ? quoteData.partDescription : '');
      setEstimatedPpvValue(quoteData.estimatedPpv != null ? quoteData.estimatedPpv : '');
      setPreviousPartNumberValue(quoteData.previousPartNumber ? quoteData.previousPartNumber : '');
      setTargetPriceValue(quoteData.targetPrice != null ? quoteData.targetPrice : '');
      setMonthlyVolumeValue(quoteData.monthlyVolume != null ? quoteData.monthlyVolume : '');
      setMonthlyCarVolumeValue(quoteData.monthlyCarVolume != null ? quoteData.monthlyCarVolume : '');
      setInitValues(false);
    }
  }, [
    initValues,
    quoteData,
    setGeneralProductTypeSelect,
    programsLov,
    partTypeIhsLov,
    setInitValues,
  ]);
  
  const onPickerChange = async e => {
    try {
      if(!e.target.files || !e.target.files.length) return;
      setFilePickerValue(e.target.value);
      const files = [];
      const fileDescs = [];
      for(let i = 0; i < e.target.files.length; i++) {
        files.push(e.target.files[i]);
        fileDescs.push('');
      }
      setFilePickerFiles(files);
      setFileDescriptions(fileDescs);
    }
    catch(e) {
      console.error('QuoteTabGeneral.onPickerChange error ->', e);
      logger.log(accessToken, `QuoteTabGeneral.onPickerChange error -> ${JSON.stringify(e)}`, userId, loggedInUser);
    }
  };
  
  const designAssignment = quoteData.assignments.find(o => o.types.includes('DESIGN_ESTIMATOR'));
  const designAssignedUserId = designAssignment ? designAssignment.userId : null;
  const feasibilityAssignment = quoteData.assignments.find(o => o.types.includes('FEASIBILITY'));
  const feasibilityAssignmentUserId = feasibilityAssignment ? feasibilityAssignment.userId : null;
  
  const disableAllInputs = quoteData.status !== 'SANDBOX' && ((quoteData.holdUserId !== userId && currentView !== 'CREATE_QUOTE') || quoteData.isNotActive ||
    quoteData.status === 'NOT_FEASIBLE' ||
    (!(appRoles.includes(CONFIG.ROLE_MAPPINGS.DESIGN_ESTIMATOR) && userId === designAssignedUserId) &&
      !(appRoles.includes(CONFIG.ROLE_MAPPINGS.FEASIBILITY) && userId === feasibilityAssignmentUserId) &&
      !(appRoles.includes(CONFIG.ROLE_MAPPINGS.CREATE_QUOTE) && userId === quoteData.createdBy)) ||
    ![
      designAssignedUserId,
      feasibilityAssignmentUserId,
      quoteData.createdBy,
    ].includes(userId));
  
  const selectedProductTypeObj = productTypeLov.find(o => o.code === generalProductTypeSelect);
  const programsGrouped = getProgramsGrouped(programsLov);
  const platformsGrouped = getPlatformsGrouped(programsLov);
  
  const uploadFiles = async () => {
    try {
      setLoadingMessage('Uploading documents');
      await Promise.all(filePickerFiles.map(async (file, index) => {
        const fileName = file.name.replace(' ', '_');
        try {
          const fileData = await readFileData(file);
          const url = await getUploadUrl(accessToken, quoteData.quoteId, quoteData.revision, fileName, file.type);
          await uploadAttachedDocument(url, fileData, file.type);
          const attachmentRecord = await createAttachmentRecord(
            accessToken,
            quoteData.quoteId,
            quoteData.revision,
            fileName,
            file.type,
            fileDescriptions[index],
            userId,
            loggedInUser,
          );
          const activityLog = await createQuoteActivityLog(
            accessToken,
            quoteData.quoteId,
            quoteData.revision,
            'ATTACHMENT',
            `Attachment created -> ${fileName}`,
            loggedInUser,
          );
          setQuoteAttachments(prevState => {
            const newQuoteAttachments = cloneDeep(prevState);
            newQuoteAttachments.push(attachmentRecord);
            return newQuoteAttachments;
          });
          setQuoteActivities(prevState => {
            const newQuoteActivities = cloneDeep(prevState);
            newQuoteActivities.unshift(activityLog);
            return newQuoteActivities;
          });
          alert.success(`${fileName} uploaded`);
        }
        catch(e) {
          console.error('QuoteTabGeneral.uploadFiles.loop error ->', e);
          logger.log(accessToken, `QuoteTabGeneral.uploadFiles.loop error -> ${JSON.stringify(e)}`, userId, loggedInUser);
          alert.error(`Upload error for ${fileName}`);
        }
      }));
      setFilePickerValue('');
      setFilePickerFiles(null);
      setFileDescriptions(null);
    }
    catch(e) {
      console.error('QuoteTabGeneral.uploadFiles error ->', e);
      logger.log(accessToken, `QuoteTabGeneral.uploadFiles error -> ${JSON.stringify(e)}`, userId, loggedInUser);
      alert.error('File upload error');
    }
    finally {
      setLoadingMessage(null);
    }
  };
  
  const confirmDeleteAttachments = async () => {
    if(window.confirm(`Are you sure you want to delete the selected attachments?`)) {
      try {
        setLoadingMessage('Deleting attachments');
        const deleted = await Promise.all(quoteAttachments.map(async o => {
          if(o.selected) {
            let deleted = o.filename;
            try {
              await deleteAttachment(accessToken, o.quoteId, o.revision, o.filename);
              const activityLog = await createQuoteActivityLog(
                accessToken,
                o.quoteId,
                o.revision,
                'ATTACHMENT',
                `Attachment deleted -> ${o.filename}`,
                loggedInUser,
              );
              setQuoteActivities(prevState => {
                const newQuoteActivities = cloneDeep(prevState);
                newQuoteActivities.unshift(activityLog);
                return newQuoteActivities;
              });
              alert.success(`Deleted ${o.filename}`);
            }
            catch(e) {
              console.error('QuoteTabGeneral.attachment delete error ->', e);
              logger.log(accessToken, `QuoteTabGeneral.attachment delete error -> ${JSON.stringify(e)}`, userId, loggedInUser);
              alert.error(`Could not delete ${o.filename}`);
              deleted = null;
            }
            return deleted;
          }
          else return null;
        }));
        setQuoteAttachments(prevState => cloneDeep(prevState).filter(o => !deleted.includes(o.filename)));
      }
      catch(e) {
        console.error('QuoteTabGeneral.confirmDeleteAttachments error ->', e);
        logger.log(accessToken, `QuoteTabGeneral.confirmDeleteAttachments error -> ${JSON.stringify(e)}`, userId, loggedInUser);
      }
      finally {
        setLoadingMessage(null);
      }
    }
  };
  
  const downloadFiles = async () => {
    try {
      setLoadingMessage('Requesting attachments');
      await Promise.all(quoteAttachments.filter(o => o.selected).map(async file => {
        const url = await getDownloadUrl(
          accessToken,
          file.quoteId,
          file.parentRevision != null ? file.parentRevision : file.revision,
          file.filename,
        );
        window.open(url, '_blank');
      }));
      setQuoteAttachments(prevState => cloneDeep(prevState).map(o => ({ ...o, selected: false })));
    }
    catch(e) {
      console.error('QuoteTabGeneral.downloadFiles error ->', e);
      logger.log(accessToken, `QuoteTabGeneral.downloadFiles error -> ${JSON.stringify(e)}`, userId, loggedInUser);
    }
    finally {
      setLoadingMessage(null);
    }
  };
  
  const confirmProductTypeChange = newValue => {
    if(window.confirm('Changing the product type will remove any existing BOM and BOM-related data, do you want to continue?')) {
      // clear bom and other data on change
      setBomData(null);
      setMoldedPartsValue([]);
      setPurchasedPartCosts([]);
      setCarryoverPartCosts([]);
      setPackagingCosts([]);
      setGeneralProductTypeSelect(newValue);
    }
  };
  
  const programChangeOptions = programValue?.length ?
    [...programValue.map(p => p.plantPrograms.map(o => o.changeList)).flat(2).reduce((map, cur) => {
      map.set(`${cur.changeDate}|${cur.changeType}`, { ...cur, name: `${cur.changeDate} - ${cur.changeType}` });
      return map;
    }, new Map()).values()].sort((a, b) => cmp(a.changeDate, b.changeDate)) :
    [];
  
  if(quoteData == null) return null;
  return (
    <>
      {
        ['DRAFT', 'SANDBOX', 'PRE_FEASIBILITY'].includes(quoteData.status) ?
          <>
            <div style={styles.tabContainerRow}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Manufacture Type</p>
              </div>
              <Form.Select
                as="select"
                style={{
                  ...(quoteMissingAttributes.includes('GENERAL|productType') ? GLOBAL_STYLES.errorInput : {}),
                  marginRight: 10,
                  flexBasis: '26%',
                }}
                value={generalProductTypeSelect}
                onChange={e => confirmProductTypeChange(e.target.value)}
                disabled={disableAllInputs}
              >
                <option value="NULL">-- Select Manufacture Type --</option>
                {productTypeLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
              </Form.Select>
              <p
                style={{
                  ...styles.noMarginText,
                  flexBasis: '24%',
                  marginRight: 10,
                  textAlign: 'center',
                }}
              >
                Purchase Component – <span
                style={{ fontWeight: 'bold' }}>{selectedProductTypeObj && selectedProductTypeObj.purchased ? 'Yes' : 'No'}</span>
              </p>
              <p
                style={{
                  ...styles.noMarginText,
                  flexBasis: '26%',
                  textAlign: 'center',
                }}
              >
                Requires Assembly – <span
                style={{ fontWeight: 'bold' }}>{selectedProductTypeObj && selectedProductTypeObj.assembled ? 'Yes' : 'No'}</span>
              </p>
            </div>
            <div style={styles.tabContainerRow}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Customer</p>
              </div>
              <Autocomplete
                size="small"
                autoHighlight
                disablePortal
                disabled={disableAllInputs}
                value={customerNameValue}
                onChange={(e, newValue) => setCustomerNameValue(newValue)}
                inputValue={customerNameInputValue}
                onInputChange={(e, newValue) => setCustomerNameInputValue(newValue)}
                options={customersLov.map(o => o.name)}
                sx={{
                  ...(quoteMissingAttributes.includes('GENERAL|customerName') ? GLOBAL_STYLES.errorInput : {}),
                  flexBasis: '78%',
                }}
                renderInput={(params) => <TextField {...params} label="Customer Name"/>}
              />
            </div>
          </> :
          null
      }
      <div style={styles.tabContainerRow}>
        <div style={styles.tabContainerLabel}>
          <p style={styles.noMarginText}>Product Type</p>
        </div>
        <Autocomplete
          size="small"
          autoHighlight
          disablePortal
          disabled={['SALES_APPROVAL', 'COMPLETED', 'AT_CUSTOMER', 'AWARDED', 'LOST'].includes(quoteData.status) || disableAllInputs}
          value={productTypeValue}
          onChange={(e, newValue) => setProductTypeValue(newValue)}
          inputValue={productTypeInputValue}
          onInputChange={(e, newValue) => setProductTypeInputValue(newValue)}
          options={partTypeIhsLov}
          filterOptions={(options, state) => options.filter(o => o.name.toLowerCase().includes(state.inputValue.toLowerCase()))}
          getOptionLabel={option => option.name}
          isOptionEqualToValue={(option, value) => option.code === value?.code}
          sx={{
            ...(quoteMissingAttributes.includes('GENERAL|productTypeIhs') ? GLOBAL_STYLES.errorInput : {}),
            flexBasis: '78%',
          }}
          renderInput={(params) => <TextField {...params} label="Product Type"/>}
        />
      </div>
      <div style={styles.tabContainerRow}>
        <div style={styles.tabContainerLabel}/>
        <Form.Check
          type="checkbox"
          label="Platform/Program TBD"
          checked={platformProgramTbd}
          onChange={e => {
            setProgramValue([]);
            setPlatformValue(null);
            setProgramChangeValue(null);
            setProgramInputValue('');
            setPlatformValueInput('');
            setProgramChangeInputValue('');
            setPlatformProgramTbd(e.target.checked);
          }}
          disabled={['SALES_APPROVAL', 'COMPLETED', 'AT_CUSTOMER', 'AWARDED', 'LOST'].includes(quoteData.status) || disableAllInputs}
          style={{ flexBasis: '39%' }}
        />
      </div>
      <div style={styles.tabContainerRow}>
        <div style={styles.tabContainerLabel}>
          <p style={styles.noMarginText}>Platform</p>
        </div>
        <Autocomplete
          size="small"
          autoHighlight
          disablePortal
          disabled={['SALES_APPROVAL', 'COMPLETED', 'AT_CUSTOMER', 'AWARDED', 'LOST'].includes(quoteData.status) || disableAllInputs || platformProgramTbd}
          value={platformValue}
          onChange={(e, newValue) => {
            setPlatformValue(newValue);
            if(!programValue?.length && newValue) {
              const programs = programsGrouped.filter(o => o.platformId === newValue.platformId);
              setProgramValue(programs);
            }
          }}
          inputValue={platformValueInput}
          onInputChange={(e, newValue) => setPlatformValueInput(newValue)}
          options={platformsGrouped.filter(o => !programValue?.length || o.platformId === programValue[0].platformId)}
          filterOptions={(options, state) => options.filter(o => o.platform.toLowerCase().includes(state.inputValue.toLowerCase()))}
          getOptionLabel={option => option.platform}
          isOptionEqualToValue={(option, value) => option.platformId === value?.platformId}
          sx={{
            ...(quoteMissingAttributes.includes('GENERAL|platform') ? GLOBAL_STYLES.errorInput : {}),
            flexBasis: '78%',
          }}
          renderInput={(params) => <TextField {...params} label="Platform"/>}
        />
      </div>
      <div style={styles.tabContainerRow}>
        <div style={styles.tabContainerLabel}>
          <p style={styles.noMarginText}>Programs</p>
        </div>
        <Autocomplete
          size="small"
          multiple
          autoHighlight
          disablePortal
          disabled={['SALES_APPROVAL', 'COMPLETED', 'AT_CUSTOMER', 'AWARDED', 'LOST'].includes(quoteData.status) || disableAllInputs || platformProgramTbd}
          value={programValue}
          onChange={(e, newValue) => {
            setProgramValue(newValue);
            setProgramChangeValue(null);
            if(!platformValue && newValue?.length) {
              const platform = platformsGrouped.find(o => o.platformId === newValue[0].platformId);
              if(platform) setPlatformValue(platform);
            }
          }}
          inputValue={programInputValue}
          onInputChange={(e, newValue) => setProgramInputValue(newValue)}
          options={programsGrouped.filter(o => (!programValue?.length && !platformValue) || (programValue?.length && o.platformId === programValue[0].platformId) || (platformValue && o.platformId === platformValue.platformId))}
          filterOptions={(options, state) => options.filter(o => o.name.toLowerCase().includes(state.inputValue.toLowerCase()))}
          getOptionLabel={option => option.name}
          isOptionEqualToValue={(option, value) => option.programId === value?.programId}
          sx={{
            ...(quoteMissingAttributes.includes('GENERAL|program') ? GLOBAL_STYLES.errorInput : {}),
            flexBasis: '78%',
          }}
          renderInput={(params) => <TextField {...params} label="Program Descriptions"/>}
        />
      </div>
      {programChangeOptions?.length ?
        <div style={styles.tabContainerRow}>
          <div style={styles.tabContainerLabel}>
            <p style={styles.noMarginText}>Program Change</p>
          </div>
          <Autocomplete
            size="small"
            autoHighlight
            disablePortal
            disabled={['SALES_APPROVAL', 'COMPLETED', 'AT_CUSTOMER', 'AWARDED', 'LOST'].includes(quoteData.status) || disableAllInputs || platformProgramTbd}
            value={programChangeValue}
            onChange={(e, newValue) => {
              setProgramChangeValue(newValue);
            }}
            inputValue={programChangeInputValue}
            onInputChange={(e, newValue) => setProgramChangeInputValue(newValue)}
            options={programChangeOptions}
            filterOptions={(options, state) => options.filter(o => o.name.toLowerCase().includes(state.inputValue.toLowerCase()))}
            getOptionLabel={option => option.name}
            isOptionEqualToValue={(option, value) => option.name === value?.name}
            sx={{ flexBasis: '78%' }}
            renderInput={(params) => <TextField {...params} label="Program Change"/>}
          />
        </div> :
        null
      }
      <div style={styles.tabContainerRow}>
        <div style={styles.tabContainerLabel}>
          <p style={styles.noMarginText}>Estimated PPV</p>
        </div>
        <Form.Control
          type="number"
          step="0.1"
          min="0"
          style={{
            ...(quoteMissingAttributes.includes('GENERAL|estimatedPpv') ? GLOBAL_STYLES.errorInput : {}),
            flexBasis: '26%',
          }}
          value={estimatedPpvValue}
          onChange={e => setEstimatedPpvValue(e.target.value)}
          disabled={['SALES_APPROVAL', 'COMPLETED', 'AT_CUSTOMER', 'AWARDED', 'LOST'].includes(quoteData.status) || disableAllInputs}
        />
      </div>
      <div style={styles.tabContainerRow}>
        <div style={styles.tabContainerLabel}>
          <p style={styles.noMarginText}>Part Type</p>
        </div>
        <Form.Select
          as="select"
          style={{
            ...(quoteMissingAttributes.includes('GENERAL|partType') ? GLOBAL_STYLES.errorInput : {}),
            flexBasis: '26%',
            marginRight: 10,
          }}
          value={partTypeSelect}
          onChange={e => setPartTypeSelect(e.target.value)}
          disabled={!['DRAFT', 'SANDBOX', 'PRE_FEASIBILITY'].includes(quoteData.status) || disableAllInputs}
        >
          <option value="NULL">-- Select a Part Type --</option>
          {partTypeLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
        </Form.Select>
        {
          !['DRAFT', 'SANDBOX'].includes(quoteData.status) ?
            <>
              <div style={{ ...styles.tabContainerLabel, flexGrow: 1 }}>
                <p style={styles.noMarginText}>Category</p>
              </div>
              <Form.Select
                as="select"
                style={{
                  ...(quoteMissingAttributes.includes('GENERAL|partCategory') ? GLOBAL_STYLES.errorInput : {}),
                  flexBasis: '26%',
                }}
                value={partGlobalCategorySelect}
                onChange={e => setPartGlobalCategorySelect(e.target.value)}
                disabled={['SALES_APPROVAL', 'COMPLETED', 'AT_CUSTOMER', 'AWARDED', 'LOST'].includes(quoteData.status) || disableAllInputs}
              >
                <option value="NULL">-- Select a Category --</option>
                {partGlobalCategoryLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
              </Form.Select>
            </> :
            null
        }
      </div>
      {
        !['DRAFT', 'SANDBOX'].includes(quoteData.status) ?
          <div style={styles.tabContainerRow}>
            <div style={styles.tabContainerLabel}>
              <p style={styles.noMarginText}>Complexity</p>
            </div>
            <Form.Select
              as="select"
              style={{
                ...(quoteMissingAttributes.includes('GENERAL|complexity') ? GLOBAL_STYLES.errorInput : {}),
                flexBasis: '26%',
                marginRight: 10,
              }}
              value={complexitySelect}
              onChange={e => setComplexitySelect(e.target.value)}
              disabled={['SALES_APPROVAL', 'COMPLETED', 'AT_CUSTOMER', 'AWARDED', 'LOST'].includes(quoteData.status) || disableAllInputs}
            >
              <option value="NULL">-- Select a Complexity --</option>
              {complexityIhsLov.filter(o => (o.type === 'ASSEMBLY' && selectedProductTypeObj?.assembled) ||
                (o.type === 'MOLDING' && !selectedProductTypeObj?.assembled && selectedProductTypeObj?.code !== 'PURCHASE_PART_ONLY'))
                .map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
            </Form.Select>
            <div style={{ ...styles.tabContainerLabel, flexGrow: 1 }}>
              <p style={styles.noMarginText}>Est. PPAP Timing (weeks)</p>
            </div>
            <Form.Control
              type="number"
              step="1"
              style={{
                ...(quoteMissingAttributes.includes('GENERAL|ppapTiming') ? GLOBAL_STYLES.errorInput : {}),
                flexBasis: '6%',
                marginRight: 10,
              }}
              value={ppapTimingValue}
              onChange={e => setPpapTimingValue(e.target.value)}
              disabled={['SALES_APPROVAL', 'COMPLETED', 'AT_CUSTOMER', 'AWARDED', 'LOST'].includes(quoteData.status) || disableAllInputs}
            />
            <Form.Select
              as="select"
              style={{
                ...(quoteMissingAttributes.includes('GENERAL|ppapTimingType') ? GLOBAL_STYLES.errorInput : {}),
                flexBasis: '19%',
              }}
              value={ppapTimingSelect}
              onChange={e => setPpapTimingSelect(e.target.value)}
              disabled={['SALES_APPROVAL', 'COMPLETED', 'AT_CUSTOMER', 'AWARDED', 'LOST'].includes(quoteData.status) || disableAllInputs}
            >
              <option value="NULL">-- Select a Timing --</option>
              {ppapTimingLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
            </Form.Select>
          </div> :
          null
      }
      <div style={styles.tabContainerRow}>
        <div style={styles.tabContainerLabel}>
          <p style={styles.noMarginText}>Customer Part #</p>
        </div>
        <Form.Control
          type="text"
          style={{
            ...(quoteMissingAttributes.includes('GENERAL|customerPartNumber') ? GLOBAL_STYLES.errorInput : {}),
            flexBasis: '26%',
            marginRight: 10,
          }}
          value={customerPartNumberValue}
          onChange={e => setCustomerPartNumberValue(e.target.value)}
          disabled={disableAllInputs}
        />
        <div style={{ ...styles.tabContainerLabel, flexGrow: 1 }}>
          <p style={styles.noMarginText}>Part Description</p>
        </div>
        <Form.Control
          type="text"
          style={{
            ...(quoteMissingAttributes.includes('GENERAL|partDescription') ? GLOBAL_STYLES.errorInput : {}),
            flexBasis: '26%',
          }}
          value={partDescriptionValue}
          onChange={e => setPartDescriptionValue(e.target.value)}
          disabled={disableAllInputs}
        />
      </div>
      <div style={styles.tabContainerRow}>
        <div style={styles.tabContainerLabel}>
          <p style={styles.noMarginText}>Tool Type</p>
        </div>
        <Form.Select
          as="select"
          style={{
            ...(quoteMissingAttributes.includes('GENERAL|toolType') ? GLOBAL_STYLES.errorInput : {}),
            flexBasis: '26%',
            marginRight: 10,
          }}
          value={toolTypeSelect}
          onChange={e => setToolTypeSelect(e.target.value)}
          disabled={!['DRAFT', 'SANDBOX', 'PRE_FEASIBILITY'].includes(quoteData.status) || disableAllInputs}
        >
          <option value="NULL">-- Select Tool Type --</option>
          {toolTypeLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
        </Form.Select>
        <div style={{ ...styles.tabContainerLabel, flexGrow: 1 }}>
          <p style={styles.noMarginText}>Drawing Rev. Level</p>
        </div>
        <Form.Control
          type="text"
          style={{ flexBasis: '26%' }}
          value={drawingRevValue}
          onChange={e => setDrawingRevValue(e.target.value)}
          disabled={!['DRAFT', 'SANDBOX', 'PRE_FEASIBILITY'].includes(quoteData.status) || disableAllInputs}
        />
      </div>
      <div style={styles.tabContainerRow}>
        <div style={styles.tabContainerLabel}>
          <p style={styles.noMarginText}>Material Type</p>
        </div>
        <Form.Control
          type="text"
          style={{
            ...(quoteMissingAttributes.includes('GENERAL|materialType') ? GLOBAL_STYLES.errorInput : {}),
            flexBasis: '26%',
          }}
          value={materialTypeValue}
          onChange={e => setMaterialTypeValue(e.target.value)}
          disabled={!['DRAFT', 'SANDBOX', 'PRE_FEASIBILITY'].includes(quoteData.status) || disableAllInputs}
        />
      </div>
      <div style={styles.tabContainerRow}>
        <div style={styles.tabContainerLabel}>
          <p style={styles.noMarginText}>General Color</p>
        </div>
        <Form.Control
          type="text"
          style={{
            ...(quoteMissingAttributes.includes('GENERAL|generalColor') ? GLOBAL_STYLES.errorInput : {}),
            flexBasis: '26%',
            marginRight: 10,
          }}
          value={generalColorValue}
          onChange={e => setGeneralColorValue(e.target.value)}
          disabled={!['DRAFT', 'SANDBOX', 'PRE_FEASIBILITY'].includes(quoteData.status) || disableAllInputs}
        />
        <div style={{ ...styles.tabContainerLabel, flexGrow: 1 }}>
          <p style={styles.noMarginText}>Specific Color</p>
        </div>
        <Form.Control
          type="text"
          style={{ flexBasis: '26%' }}
          value={specificColorValue}
          onChange={e => setSpecificColorValue(e.target.value)}
          disabled={!['DRAFT', 'SANDBOX', 'PRE_FEASIBILITY'].includes(quoteData.status) || disableAllInputs}
        />
      </div>
      {
        ['DRAFT', 'SANDBOX', 'PRE_FEASIBILITY'].includes(quoteData.status) ?
          <>
            <div style={styles.tabContainerRow}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Target Price</p>
              </div>
              <InputGroup style={{ flexBasis: '26%', marginRight: 10 }}>
                <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                <Form.Control
                  type="number"
                  value={targetPriceValue}
                  onChange={e => setTargetPriceValue(e.target.value)}
                  disabled={disableAllInputs}
                />
              </InputGroup>
              <div style={{ ...styles.tabContainerLabel, flexGrow: 1 }}>
                <p style={styles.noMarginText}>Monthly Volume</p>
              </div>
              <Form.Control
                type="number"
                style={{
                  ...(quoteMissingAttributes.includes('GENERAL|monthlyVolume') ? GLOBAL_STYLES.errorInput : {}),
                  flexBasis: '26%',
                }}
                value={monthlyVolumeValue}
                onChange={e => setMonthlyVolumeValue(e.target.value)}
                disabled={disableAllInputs}
              />
            </div>
            <div style={styles.tabContainerRow}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Monthly Car Volume</p>
              </div>
              <Form.Control
                type="number"
                style={{
                  ...(quoteMissingAttributes.includes('GENERAL|monthlyCarVolume') ? GLOBAL_STYLES.errorInput : {}),
                  flexBasis: '26%',
                }}
                value={monthlyCarVolumeValue}
                onChange={e => setMonthlyCarVolumeValue(e.target.value)}
                disabled={disableAllInputs}
              />
            </div>
          </>
          :
          null
      }
      <div style={styles.tabContainerRow}>
        <div style={styles.tabContainerLabel}>
          <p style={styles.noMarginText}>Prog. Life (months)</p>
        </div>
        <Form.Control
          type="number"
          step="1"
          style={{ flexBasis: '26%', marginRight: 10 }}
          value={programLifeValue}
          onChange={e => setProgramLifeValue(e.target.value)}
          disabled={!['DRAFT', 'SANDBOX', 'PRE_FEASIBILITY'].includes(quoteData.status) || disableAllInputs}
        />
        <div style={{ ...styles.tabContainerLabel, flexGrow: 1 }}>
          <p style={styles.noMarginText}>Design Responsibility</p>
        </div>
        <Form.Select
          as="select"
          style={{
            ...(quoteMissingAttributes.includes('GENERAL|designResponsibility') ? GLOBAL_STYLES.errorInput : {}),
            flexBasis: '26%',
          }}
          value={designResponsibilitySelect}
          onChange={e => setDesignResponsibilitySelect(e.target.value)}
          disabled={!['DRAFT', 'SANDBOX', 'PRE_FEASIBILITY'].includes(quoteData.status) || disableAllInputs}
        >
          <option value="NULL">-- Select --</option>
          {designResponsibilityLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
        </Form.Select>
      </div>
      <div style={styles.tabContainerRow}>
        <div style={styles.tabContainerLabel}>
          <p style={styles.noMarginText}>Business Type</p>
        </div>
        <Form.Select
          as="select"
          style={{
            ...(quoteMissingAttributes.includes('GENERAL|businessType') ? GLOBAL_STYLES.errorInput : {}),
            flexBasis: '26%',
          }}
          value={businessTypeSelect}
          onChange={e => setBusinessTypeSelect(e.target.value)}
          disabled={!['DRAFT', 'SANDBOX', 'PRE_FEASIBILITY'].includes(quoteData.status) || disableAllInputs}
        >
          <option value="NULL">-- Select --</option>
          {businessTypeLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
        </Form.Select>
        <div style={{ ...styles.tabContainerLabel, flexGrow: 1 }}>
          <p style={styles.noMarginText}>Sales Expectation</p>
        </div>
        <Form.Select
          as="select"
          style={{
            ...(quoteMissingAttributes.includes('GENERAL|salesExpectation') ? GLOBAL_STYLES.errorInput : {}),
            flexBasis: '26%',
          }}
          value={salesExpectationSelect}
          onChange={e => setSalesExpectationSelect(e.target.value)}
          disabled={!['DRAFT', 'SANDBOX', 'PRE_FEASIBILITY'].includes(quoteData.status) || disableAllInputs}
        >
          <option value="NULL">-- Select --</option>
          {salesExpectationLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
        </Form.Select>
      </div>
      {
        ['EXISTING_BUSINESS_CARRY_OVER', 'EXISTING_BUSINESS_REPLACEMENT'].includes(businessTypeSelect) ?
          <div style={styles.tabContainerRow}>
            <div style={styles.tabContainerLabel}>
              <p style={styles.noMarginText}>Previous Part Number</p>
            </div>
            <Form.Control
              type="text"
              style={{
                ...(quoteMissingAttributes.includes('GENERAL|previousPartNumber') ? GLOBAL_STYLES.errorInput : {}),
                flexBasis: '26%',
                marginRight: 10,
              }}
              value={previousPartNumberValue}
              disabled
            />
            <Button
              type="button"
              variant="secondary"
              onClick={() => setPreviousPartNumberValue('')}
              disabled={!['DRAFT', 'SANDBOX', 'PRE_FEASIBILITY'].includes(quoteData.status) || disableAllInputs}
              style={{ marginRight: 10 }}
            >
              <FontAwesomeIcon icon={faTimes} style={{ marginRight: 4 }}/>
              Clear
            </Button>
            <Button
              type="button"
              variant="secondary"
              onClick={() => setCarryoverPartSearchModalIsOpen(true)}
              disabled={!['DRAFT', 'SANDBOX', 'PRE_FEASIBILITY'].includes(quoteData.status) || disableAllInputs}
            >
              <FontAwesomeIcon icon={faSearch} style={{ marginRight: 4 }}/>
              Search
            </Button>
          </div> :
          null
      }
      <div style={styles.tabContainerRow}>
        <div style={styles.tabContainerLabel}>
          <p style={styles.noMarginText}>Special Instructions</p>
        </div>
        <Form.Control
          as="textarea"
          rows={3}
          style={{ flexBasis: '78%' }}
          value={specialInstructionsValue}
          onChange={e => setSpecialInstructionsValue(e.target.value)}
          disabled={disableAllInputs}
        />
      </div>
      <div style={styles.closingRow}>
        <div
          style={styles.closingRowHeader}
          onClick={() => setDocumentsVisible(prev => !prev)}
        >
          <h5 style={styles.noMarginText}>Attached Documents</h5>
        </div>
        {
          documentsVisible ?
            <div style={styles.closingRowContent}>
              {
                quoteAttachments && quoteAttachments.length ?
                  <>
                    <Table size="sm" bordered style={{ marginBottom: 8 }}>
                      <thead>
                      <tr>
                        <th>
                          <Form.Check
                            type="checkbox"
                            label=""
                            checked={quoteAttachments.every(o => o.selected)}
                            onChange={e => setQuoteAttachments(prevState => cloneDeep(prevState).map(o => ({
                              ...o,
                              selected: e.target.checked,
                            })))}
                          />
                        </th>
                        <th>Filename</th>
                        <th>Time</th>
                        <th>Description</th>
                        <th>User</th>
                      </tr>
                      </thead>
                      <tbody>
                      {quoteAttachments.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <Form.Check
                              type="checkbox"
                              label=""
                              checked={item.selected}
                              onChange={e => setQuoteAttachments(prevState => {
                                const newQuoteAttachment = cloneDeep(prevState);
                                newQuoteAttachment[index].selected = e.target.checked;
                                return newQuoteAttachment;
                              })}
                            />
                          </td>
                          <td>{item.filename}</td>
                          <td>{DateTime.fromMillis(item.createdTime).toFormat('ccc LLL d yyyy, h:mm a')}</td>
                          <td>{item.description}</td>
                          <td>{item.username}</td>
                        </tr>
                      ))}
                      </tbody>
                    </Table>
                    <div style={styles.fileButtons}>
                      <Button
                        type="button"
                        variant="primary"
                        size="sm"
                        style={{ marginRight: 8 }}
                        onClick={downloadFiles}
                        disabled={!quoteAttachments.filter(o => o.selected).length}
                      >
                        <FontAwesomeIcon icon={faDownload} style={{ marginRight: 4 }}/>
                        Download Files
                      </Button>
                      <Button
                        type="button"
                        variant="danger"
                        size="sm"
                        style={{ marginRight: 8 }}
                        onClick={confirmDeleteAttachments}
                        disabled={!quoteAttachments.filter(o => o.selected).length || quoteData.status === 'SANDBOX'}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} style={{ marginRight: 4 }}/>
                        Delete Files
                      </Button>
                      <Button
                        type="button"
                        variant="warning"
                        size="sm"
                        onClick={() => setQuoteAttachments(prevState => cloneDeep(prevState)
                          .map(o => ({ ...o, selected: false })))}
                        disabled={!quoteAttachments.filter(o => o.selected).length}
                      >
                        Clear Selection
                      </Button>
                    </div>
                  </> :
                  null
              }
              <p style={{ ...styles.noMarginText, fontWeight: 'bold', margin: 5 }}>
                Add documents
              </p>
              {
                !filePickerFiles || !filePickerFiles.length ?
                  <input
                    type="file"
                    multiple
                    disabled={disableAllInputs || quoteData.status === 'SANDBOX'}
                    onChange={onPickerChange}
                    value={filePickerValue}
                  /> :
                  <>
                    <Table size="sm" bordered style={{ marginBottom: 8 }}>
                      <thead>
                      <tr>
                        <th>Filename</th>
                        <th>Description</th>
                      </tr>
                      </thead>
                      <tbody>
                      {filePickerFiles.map((file, index) => <tr key={index}>
                        <td
                          style={{
                            verticalAlign: 'middle',
                            color: quoteAttachments.map(o => o.filename).includes(file.name.replace(' ', '_')) ? COLORS.red : null,
                          }}
                        >
                          {file.name.replace(' ', '_')}
                        </td>
                        <td>
                          <Form.Control
                            type="text"
                            size="sm"
                            value={fileDescriptions[index]}
                            onChange={e => setFileDescriptions(prevState => {
                              const newFileDescriptions = cloneDeep(prevState);
                              newFileDescriptions[index] = e.target.value;
                              return newFileDescriptions;
                            })}
                          />
                        </td>
                      </tr>)}
                      </tbody>
                    </Table>
                    <div style={styles.fileButtons}>
                      <Button
                        type="button"
                        variant="primary"
                        size="sm"
                        style={{ marginRight: 8 }}
                        onClick={uploadFiles}
                        disabled={disableAllInputs || quoteData.status === 'SANDBOX' ||
                          filePickerFiles.some(file => quoteAttachments.map(o => o.filename).includes(file.name.replace(' ', '_')))}
                      >
                        <FontAwesomeIcon icon={faUpload} style={{ marginRight: 4 }}/>
                        {`Upload ${filePickerFiles.length} Files`}
                      </Button>
                      <Button
                        type="button"
                        variant="danger"
                        size="sm"
                        onClick={() => {
                          setFilePickerValue('');
                          setFilePickerFiles(null);
                          setFileDescriptions(null);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </>
              }
            </div> :
            null
        }
      </div>
      
      <CarryoverPartsSearchModal
        isOpen={carryoverPartSearchModalIsOpen}
        hide={() => setCarryoverPartSearchModalIsOpen(false)}
        onSubmit={partObj => setPreviousPartNumberValue(partObj.partNumber)}
      />
    </>
  );
};

const styles = {
  tabContainerRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 7,
  },
  noMarginText: {
    margin: 0,
  },
  tabContainerLabel: {
    flexBasis: '22%',
    textAlign: 'right',
    paddingRight: 10,
  },
  closingRow: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    borderRadius: 3,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: COLORS.midGray,
    marginBottom: 7,
  },
  closingRowHeader: {
    backgroundColor: COLORS.midGray,
    color: COLORS.darkGray,
    cursor: 'pointer',
    padding: 7,
  },
  closingRowContent: {
    padding: 7,
    display: 'flex',
    flexDirection: 'column',
  },
  fileButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
};

export default QuoteTabGeneral;