import { useState } from 'react';
import {
  windowWidthAtom,
  quoteStatusLovAtom,
  salesManagerUsersLovAtom,
  designEstimatorUsersLovAtom,
  toolingEstimatorUsersLovAtom,
  manufacturingEstimatorUsersLovAtom,
  purchasingEstimatorUsersLovAtom,
  customersLovAtom,
  accessTokenAtom,
  productTypeLovAtom,
  quoteDataAtom,
  currentViewSelector,
  quoteProcessorAtom,
  userIdAtom,
  loggedInUserAtom,
  showPrintPageAtom,
  appRolesAtom,
  initValuesAtom,
} from '../state';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Tabs, Tab, Form, Button, Spinner, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faCopy, faUndoAlt } from '@fortawesome/free-solid-svg-icons';
import COLORS from '../styles/colors';
import { getNameFromLovCode, cloneQuote } from '../helpers/misc';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useAlert } from 'react-alert';
import {
  getQuotesByAccountManager,
  getQuotesByCostEstimator,
  getAllOpenQuotes,
  getQuotesByQuoteNumber,
  getQuotesByQuoteNumberRevision,
  getQuotesByStatus,
  getQuotesByDepartment,
  getQuotesByCustomerPart,
  getQuotesByCustomerName,
  getQuotesByCustomerNamePart,
  getQuoteAssignments,
  getIsQuoteCurrentRevision,
  getQuotesByPartDescription,
} from '../helpers/api';
import { DateTime } from 'luxon';
import Logger from '../helpers/Logger';
import * as CONFIG from '../config';
import { DateTime as DateTimeBusiness } from 'luxon-business-days';

const logger = new Logger();

const Search = () => {
  const [selectedSalesManager, setSelectedSalesManager] = useState('NULL');
  const [selectedSalesManagerSearchType, setSelectedSalesManagerSearchType] = useState('NULL');
  const [selectedCostEstimator, setSelectedCostEstimator] = useState('NULL');
  const [selectedCostEstimatorSearchType, setSelectedCostEstimatorSearchType] = useState('NULL');
  const [quoteSearchType, setQuoteSearchType] = useState('NULL');
  const [quoteNumber, setQuoteNumber] = useState('');
  const [quoteRevision, setQuoteRevision] = useState('');
  const [quoteDepartment, setQuoteDepartment] = useState('NULL');
  const [customerSearchType, setCustomerSearchType] = useState('NULL');
  const [customerName, setCustomerName] = useState(null);
  const [customerNameInput, setCustomerNameInput] = useState('');
  const [customerPartNumber, setCustomerPartNumber] = useState('');
  const [partDescription, setPartDescription] = useState('');
  const [quoteStatusSelect, setQuoteStatusSelect] = useState('NULL');
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const windowWidth = useRecoilValue(windowWidthAtom);
  const quoteStatusLov = useRecoilValue(quoteStatusLovAtom);
  const salesManagerUsersLov = useRecoilValue(salesManagerUsersLovAtom);
  const designEstimatorUsersLov = useRecoilValue(designEstimatorUsersLovAtom);
  const toolingEstimatorUsersLov = useRecoilValue(toolingEstimatorUsersLovAtom);
  const manufacturingEstimatorUsersLov = useRecoilValue(manufacturingEstimatorUsersLovAtom);
  const purchasingEstimatorUsersLov = useRecoilValue(purchasingEstimatorUsersLovAtom);
  const customersLov = useRecoilValue(customersLovAtom);
  const accessToken = useRecoilValue(accessTokenAtom);
  const productTypeLov = useRecoilValue(productTypeLovAtom);
  const appRoles = useRecoilValue(appRolesAtom);
  const quoteProcessor = useRecoilValue(quoteProcessorAtom);
  const userId = useRecoilValue(userIdAtom);
  const loggedInUser = useRecoilValue(loggedInUserAtom);
  const setCurrentView = useSetRecoilState(currentViewSelector);
  const setQuoteData = useSetRecoilState(quoteDataAtom);
  const setShowPrintPage = useSetRecoilState(showPrintPageAtom);
  const setInitValues = useSetRecoilState(initValuesAtom);
  const alert = useAlert();
  
  const estimatorUsers = [
    ...designEstimatorUsersLov,
    ...toolingEstimatorUsersLov,
    ...manufacturingEstimatorUsersLov,
    ...purchasingEstimatorUsersLov,
  ];
  const estimatorUsersLov = [];
  estimatorUsers.forEach(userObj => {
    if(!estimatorUsersLov.some(obj => obj.code === userObj.code)) estimatorUsersLov.push(userObj);
  });
  
  const addAdditionalQuoteData = quotes => Promise.all(quotes.map(async quote => {
    const assignments = await getQuoteAssignments(accessToken, quote.quoteId, quote.revision);
    const isCurrent = await getIsQuoteCurrentRevision(accessToken, quote.quoteId, quote.revision);
    return {
      ...quote,
      assignments: assignments.map(o => ({ userId: o.userId, username: o.username, types: o.assignmentTypes })),
      isNotActive: !isCurrent,
    };
  }));
  
  const searchByAccountManager = async (selectedSalesManager, selectedSalesManagerSearchType) => {
    try {
      setIsSearching(true);
      let quotes = await getQuotesByAccountManager(accessToken, selectedSalesManager, selectedSalesManagerSearchType);
      quotes = await addAdditionalQuoteData(quotes);
      setSearchResults(quotes);
    }
    catch(e) {
      console.error('Search.searchByAccountManager error ->', e);
      logger.log(accessToken, `Search.searchByAccountManager error -> ${JSON.stringify(e)}`, userId, loggedInUser);
      alert.error('Search error');
      setSearchResults([]);
    }
    finally {
      setIsSearching(false);
    }
  };
  
  const searchByCostEstimator = async (selectedCostEstimator, selectedCostEstimatorSearchType) => {
    try {
      setIsSearching(true);
      let quotes = await getQuotesByCostEstimator(accessToken, selectedCostEstimator, selectedCostEstimatorSearchType);
      quotes = await addAdditionalQuoteData(quotes);
      setSearchResults(quotes);
    }
    catch(e) {
      console.error('Search.searchByCostEstimator error ->', e);
      logger.log(accessToken, `Search.searchByCostEstimator error -> ${JSON.stringify(e)}`, userId, loggedInUser);
      alert.error('Search error');
      setSearchResults([]);
    }
    finally {
      setIsSearching(false);
    }
  };
  
  const searchByQuoteData = async (quoteSearchType, quoteNumber, quoteRevision, quoteStatusSelect, quoteDepartment) => {
    try {
      setIsSearching(true);
      let quotes = [];
      if(quoteSearchType === 'QUOTE_OPEN') {
        quotes = await getAllOpenQuotes(accessToken);
      }
      else if(quoteSearchType === 'QUOTE_NUMBER') {
        quotes = quoteRevision.trim().length ?
          await getQuotesByQuoteNumberRevision(accessToken, quoteNumber, quoteRevision) :
          await getQuotesByQuoteNumber(accessToken, quoteNumber);
      }
      else if(quoteSearchType === 'QUOTE_STATUS') {
        quotes = await getQuotesByStatus(accessToken, quoteStatusSelect);
      }
      else if(quoteSearchType === 'QUOTE_OUTSTANDING') {
        quotes = await getQuotesByDepartment(accessToken, quoteDepartment);
      }
      quotes = await addAdditionalQuoteData(quotes);
      setSearchResults(quotes);
    }
    catch(e) {
      console.error('Search.searchByQuoteData error ->', e);
      logger.log(accessToken, `Search.searchByQuoteData error -> ${JSON.stringify(e)}`, userId, loggedInUser);
      alert.error('Search error');
      setSearchResults([]);
    }
    finally {
      setIsSearching(false);
    }
  };
  
  const searchByCustomerData = async () => {
    try {
      setIsSearching(true);
      let quotes = [];
      if(customerSearchType === 'CUSTOMER') {
        if(customerPartNumber && customerPartNumber.trim().length) {
          quotes = await getQuotesByCustomerNamePart(accessToken, customerName, customerPartNumber.trim());
        }
        else {
          quotes = await getQuotesByCustomerName(accessToken, customerName);
        }
      }
      else if(customerSearchType === 'PART_NUMBER') {
        quotes = await getQuotesByCustomerPart(accessToken, customerPartNumber.trim());
      }
      quotes = await addAdditionalQuoteData(quotes);
      setSearchResults(quotes);
    }
    catch(e) {
      console.error('Search.searchByCustomerData error ->', e);
      logger.log(accessToken, `Search.searchByCustomerData error -> ${JSON.stringify(e)}`, userId, loggedInUser);
      alert.error('Search error');
      setSearchResults([]);
    }
    finally {
      setIsSearching(false);
    }
  };
  
  const searchByPartDescription = async () => {
    try {
      setIsSearching(true);
      let quotes = await getQuotesByPartDescription(accessToken, partDescription.trim());
      quotes = await addAdditionalQuoteData(quotes);
      setSearchResults(quotes);
    }
    catch(e) {
      console.error('Search.searchByPartDescription error ->', e);
      logger.log(accessToken, `Search.searchByPartDescription error -> ${JSON.stringify(e)}`, userId, loggedInUser);
      alert.error('Search error');
      setSearchResults([]);
    }
    finally {
      setIsSearching(false);
    }
  };
  
  return (
    <div style={styles.mainSearchContainer}>
      <Tabs defaultActiveKey="ACCOUNT_MANAGER">
        <Tab eventKey="ACCOUNT_MANAGER" title="Account Manager">
          <div style={styles.tabContainer}>
            <div style={styles.inputContainer}>
              <Form style={{ marginRight: 10 }}>
                <Form.Select
                  as="select"
                  value={selectedSalesManager}
                  onChange={e => {
                    setSelectedSalesManagerSearchType('NULL');
                    setSelectedSalesManager(e.target.value);
                  }}
                >
                  <option value="NULL">-- Select Sales Manager --</option>
                  {salesManagerUsersLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
                </Form.Select>
              </Form>
              {
                selectedSalesManager && selectedSalesManager !== 'NULL' ?
                  <Form style={{ marginRight: 10 }}>
                    <Form.Select
                      as="select"
                      value={selectedSalesManagerSearchType}
                      onChange={e => {
                        setSelectedSalesManagerSearchType(e.target.value);
                        if(e.target.value && e.target.value !== 'NULL') searchByAccountManager(selectedSalesManager, e.target.value);
                      }}
                    >
                      <option value="NULL">-- Select Search Type --</option>
                      <option value="OPEN">All Open Quotes</option>
                      <option value="COMPLETE">All Completed/Approved Quotes</option>
                    </Form.Select>
                  </Form> :
                  null
              }
            </div>
            <div style={styles.buttonGroup}>
              <Button
                type="button"
                variant="warning"
                style={{ marginRight: 4 }}
                disabled={isSearching || selectedSalesManager === 'NULL'}
                onClick={() => {
                  setSelectedSalesManagerSearchType('NULL');
                  setSelectedSalesManager('NULL');
                }}
              >
                <FontAwesomeIcon icon={faUndoAlt} style={{ marginRight: 4 }}/>
                {windowWidth < 992 ? '' : 'RESET'}
              </Button>
              <Button
                type="button"
                variant="primary"
                disabled={isSearching || selectedSalesManager === 'NULL' || selectedSalesManagerSearchType === 'NULL'}
                onClick={() => searchByAccountManager(selectedSalesManager, selectedSalesManagerSearchType)}
              >
                <FontAwesomeIcon icon={faSearch} style={{ marginRight: 4 }}/>
                {windowWidth < 992 ? '' : 'SEARCH'}
              </Button>
            </div>
          </div>
        </Tab>
        <Tab eventKey="COST_ESTIMATOR" title="Cost Estimator">
          <div style={styles.tabContainer}>
            <div style={styles.inputContainer}>
              <Form style={{ marginRight: 10 }}>
                <Form.Select
                  as="select"
                  value={selectedCostEstimator}
                  onChange={e => {
                    setSelectedCostEstimatorSearchType('NULL');
                    setSelectedCostEstimator(e.target.value);
                  }}
                >
                  <option value="NULL">-- Select Cost Estimator --</option>
                  {estimatorUsersLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
                </Form.Select>
              </Form>
              {
                selectedCostEstimator && selectedCostEstimator !== 'NULL' ?
                  <Form style={{ marginRight: 10 }}>
                    <Form.Select
                      as="select"
                      value={selectedCostEstimatorSearchType}
                      onChange={e => {
                        setSelectedCostEstimatorSearchType(e.target.value);
                        if(e.target.value && e.target.value !== 'NULL') searchByCostEstimator(selectedCostEstimator, e.target.value);
                      }}
                    >
                      <option value="NULL">-- Select Search Type --</option>
                      <option value="OPEN">All Open Quotes</option>
                      <option value="COMPLETE">All Completed/Approved Quotes</option>
                    </Form.Select>
                  </Form> :
                  null
              }
            </div>
            <div style={styles.buttonGroup}>
              <Button
                type="button"
                variant="warning"
                style={{ marginRight: 4 }}
                disabled={isSearching || selectedCostEstimator === 'NULL'}
                onClick={() => {
                  setSelectedCostEstimatorSearchType('NULL');
                  setSelectedCostEstimator('NULL');
                }}
              >
                <FontAwesomeIcon icon={faUndoAlt} style={{ marginRight: 4 }}/>
                {windowWidth < 992 ? '' : 'RESET'}
              </Button>
              <Button
                type="button"
                variant="primary"
                disabled={isSearching || selectedCostEstimator === 'NULL' || selectedCostEstimatorSearchType === 'NULL'}
                onClick={() => searchByCostEstimator(selectedCostEstimator, selectedCostEstimatorSearchType)}
              >
                <FontAwesomeIcon icon={faSearch} style={{ marginRight: 4 }}/>
                {windowWidth < 992 ? '' : 'SEARCH'}
              </Button>
            </div>
          </div>
        </Tab>
        <Tab eventKey="QUOTE" title="Quote">
          <div style={styles.tabContainer}>
            <div style={styles.inputContainer}>
              <Form style={{ marginRight: 10 }}>
                <Form.Select
                  as="select"
                  value={quoteSearchType}
                  onChange={e => {
                    setQuoteNumber('');
                    setQuoteRevision('');
                    setQuoteStatusSelect('NULL');
                    setQuoteDepartment('NULL');
                    setQuoteSearchType(e.target.value);
                    if(e.target.value === 'QUOTE_OPEN') searchByQuoteData(e.target.value);
                  }}
                >
                  <option value="NULL">-- Select Search Type --</option>
                  <option value="QUOTE_OPEN">All Open Quotes</option>
                  <option value="QUOTE_NUMBER">By Quote Number</option>
                  <option value="QUOTE_STATUS">By Quote Status</option>
                  <option value="QUOTE_OUTSTANDING">Outstanding Quotes By Department</option>
                </Form.Select>
              </Form>
              {
                quoteSearchType === 'QUOTE_NUMBER' ?
                  <>
                    <div style={{ marginRight: 10 }}>
                      <Form.Control
                        type="number"
                        step="1"
                        placeholder="Quote #"
                        onKeyDown={e => {
                          if(e.keyCode === 13 && !(isSearching || !(quoteSearchType === 'QUOTE_OPEN' ||
                            (quoteSearchType === 'QUOTE_NUMBER' && quoteNumber.trim().length) ||
                            (quoteSearchType === 'QUOTE_STATUS' && quoteStatusSelect !== 'NULL') ||
                            (quoteSearchType === 'QUOTE_OUTSTANDING' && quoteDepartment !== 'NULL'))))
                            searchByQuoteData(quoteSearchType, quoteNumber, quoteRevision, quoteStatusSelect, quoteDepartment)
                        }}
                        value={quoteNumber}
                        onChange={e => setQuoteNumber(e.target.value)}
                      />
                    </div>
                    <div style={{ marginRight: 10 }}>
                      <Form.Control
                        type="number"
                        step="1"
                        placeholder="Rev #"
                        onKeyDown={e => {
                          if(e.keyCode === 13 && !(isSearching || !(quoteSearchType === 'QUOTE_OPEN' ||
                            (quoteSearchType === 'QUOTE_NUMBER' && quoteNumber.trim().length) ||
                            (quoteSearchType === 'QUOTE_STATUS' && quoteStatusSelect !== 'NULL') ||
                            (quoteSearchType === 'QUOTE_OUTSTANDING' && quoteDepartment !== 'NULL'))))
                            searchByQuoteData(quoteSearchType, quoteNumber, quoteRevision, quoteStatusSelect, quoteDepartment)
                        }}
                        value={quoteRevision}
                        onChange={e => setQuoteRevision(e.target.value)}
                      />
                    </div>
                  </> :
                  null
              }
              {
                quoteSearchType === 'QUOTE_STATUS' ?
                  <Form style={{ marginRight: 10 }}>
                    <Form.Select
                      as="select"
                      value={quoteStatusSelect}
                      onChange={e => {
                        setQuoteStatusSelect(e.target.value);
                        if(e.target.value && e.target.value !== 'NULL')
                          searchByQuoteData(quoteSearchType, undefined, undefined, e.target.value);
                      }}
                    >
                      <option value="NULL">-- Select Quote Status --</option>
                      {quoteStatusLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
                    </Form.Select>
                  </Form> :
                  null
              }
              {
                quoteSearchType === 'QUOTE_OUTSTANDING' ?
                  <Form style={{ marginRight: 10 }}>
                    <Form.Select
                      as="select"
                      value={quoteDepartment}
                      onChange={e => {
                        setQuoteDepartment(e.target.value);
                        if(e.target.value && e.target.value !== 'NULL')
                          searchByQuoteData(quoteSearchType, undefined, undefined, undefined, e.target.value);
                      }}
                    >
                      <option value="NULL">-- Select Department --</option>
                      <option value="ALL">All Departments</option>
                      <option value="DESIGN">Design</option>
                      <option value="TOOLING">Tooling</option>
                      <option value="PURCHASING">Purchasing</option>
                      <option value="MANUFACTURING">Manufacturing</option>
                    </Form.Select>
                  </Form> :
                  null
              }
            </div>
            <div style={styles.buttonGroup}>
              <Button
                type="button"
                variant="warning"
                style={{ marginRight: 4 }}
                disabled={isSearching || quoteSearchType === 'NULL'}
                onClick={() => {
                  setQuoteSearchType('NULL');
                  setQuoteNumber('');
                  setQuoteRevision('');
                  setQuoteStatusSelect('NULL');
                  setQuoteDepartment('NULL');
                }}
              >
                <FontAwesomeIcon icon={faUndoAlt} style={{ marginRight: 4 }}/>
                {windowWidth < 992 ? '' : 'RESET'}
              </Button>
              <Button
                type="button"
                variant="primary"
                disabled={isSearching || !(quoteSearchType === 'QUOTE_OPEN' ||
                  (quoteSearchType === 'QUOTE_NUMBER' && quoteNumber.trim().length) ||
                  (quoteSearchType === 'QUOTE_STATUS' && quoteStatusSelect !== 'NULL') ||
                  (quoteSearchType === 'QUOTE_OUTSTANDING' && quoteDepartment !== 'NULL'))}
                onClick={() => searchByQuoteData(quoteSearchType, quoteNumber, quoteRevision, quoteStatusSelect, quoteDepartment)}
              >
                <FontAwesomeIcon icon={faSearch} style={{ marginRight: 4 }}/>
                {windowWidth < 992 ? '' : 'SEARCH'}
              </Button>
            </div>
          </div>
        </Tab>
        <Tab eventKey="CUSTOMER" title="Customer">
          <div style={styles.tabContainer}>
            <div style={styles.inputContainer}>
              <Form style={{ marginRight: 10 }}>
                <Form.Select
                  as="select"
                  value={customerSearchType}
                  onChange={e => setCustomerSearchType(e.target.value)}
                >
                  <option value="NULL">-- Select Search Type --</option>
                  <option value="CUSTOMER">By Customer</option>
                  <option value="PART_NUMBER">By Part Number</option>
                </Form.Select>
              </Form>
              {
                customerSearchType === 'CUSTOMER' ?
                  <Form style={{ marginRight: 10 }}>
                    <Autocomplete
                      size="small"
                      autoHighlight
                      disablePortal
                      value={customerName}
                      onChange={(e, newValue) => setCustomerName(newValue)}
                      inputValue={customerNameInput}
                      onInputChange={(e, newValue) => setCustomerNameInput(newValue)}
                      options={customersLov.map(o => o.name)}
                      sx={{ width: 350 }}
                      renderInput={(params) => <TextField {...params} label="Customer Name"/>}
                    />
                  </Form> :
                  null
              }
              {
                customerSearchType === 'CUSTOMER' || customerSearchType === 'PART_NUMBER' ?
                  <div style={{ marginRight: 10 }}>
                    <Form.Control
                      type="text"
                      placeholder="Part #"
                      onKeyDown={e => {
                        if(e.keyCode === 13 && !(isSearching || !((customerSearchType === 'CUSTOMER' && customerName && customerName.length) ||
                          (customerSearchType === 'PART_NUMBER' && customerPartNumber.length)))) searchByCustomerData();
                      }}
                      value={customerPartNumber}
                      onChange={e => setCustomerPartNumber(e.target.value)}
                    />
                  </div> :
                  null
              }
            </div>
            <div style={styles.buttonGroup}>
              <Button
                type="button"
                variant="warning"
                style={{ marginRight: 4 }}
                disabled={isSearching || customerSearchType === 'NULL'}
                onClick={() => {
                  setCustomerSearchType('NULL');
                  setCustomerName(null);
                  setCustomerPartNumber('');
                  setCustomerNameInput('');
                }}
              >
                <FontAwesomeIcon icon={faUndoAlt} style={{ marginRight: 4 }}/>
                {windowWidth < 992 ? '' : 'RESET'}
              </Button>
              <Button
                type="button"
                variant="primary"
                disabled={isSearching || !((customerSearchType === 'CUSTOMER' && customerName && customerName.length) ||
                  (customerSearchType === 'PART_NUMBER' && customerPartNumber.length))}
                onClick={searchByCustomerData}
              >
                <FontAwesomeIcon icon={faSearch} style={{ marginRight: 4 }}/>
                {windowWidth < 992 ? '' : 'SEARCH'}
              </Button>
            </div>
          </div>
        </Tab>
        <Tab eventKey="PART" title="Part">
          <div style={styles.tabContainer}>
            <div style={styles.inputContainer}>
              <div style={{ marginRight: 10 }}>
                <Form.Control
                  type="text"
                  placeholder="Part description"
                  onKeyDown={e => {
                    if(e.keyCode === 13 && !(isSearching || partDescription.trim().length < 3))
                      searchByPartDescription();
                  }}
                  value={partDescription}
                  onChange={e => setPartDescription(e.target.value)}
                />
              </div>
            </div>
            <div style={styles.buttonGroup}>
              <Button
                type="button"
                variant="warning"
                style={{ marginRight: 4 }}
                disabled={isSearching || !partDescription?.length}
                onClick={() => setPartDescription('')}
              >
                <FontAwesomeIcon icon={faUndoAlt} style={{ marginRight: 4 }}/>
                {windowWidth < 992 ? '' : 'RESET'}
              </Button>
              <Button
                type="button"
                variant="primary"
                disabled={isSearching || partDescription.trim().length < 3}
                onClick={searchByPartDescription}
              >
                <FontAwesomeIcon icon={faSearch} style={{ marginRight: 4 }}/>
                {windowWidth < 992 ? '' : 'SEARCH'}
              </Button>
            </div>
          </div>
        </Tab>
      </Tabs>
      <div style={styles.resultsContainer}>
        {
          isSearching ?
            <div style={styles.spinnerContainer}>
              <Spinner animation="border"/>
              <p style={styles.spinnerText}>Searching...</p>
            </div> :
            searchResults.length ?
              <Table
                style={{ margin: 0 }}
                striped
              >
                <thead>
                <tr>
                  <th>Quote #</th>
                  <th>Rev #</th>
                  <th>Prod. Type</th>
                  <th>Status</th>
                  <th>Acct. Manager</th>
                  <th>Editing</th>
                  <th>Customer Name</th>
                  <th>Customer Part #</th>
                  <th>Description</th>
                  <th>Program</th>
                  <th>Entry Date</th>
                  <th>Estimation Due</th>
                  <th>Due</th>
                  <th>Monthly Volume</th>
                  {appRoles.includes(CONFIG.ROLE_MAPPINGS.CREATE_QUOTE) ?
                    <th>Clone</th> :
                    null
                  }
                </tr>
                </thead>
                <tbody>
                {searchResults.map((row, index) => {
                  return (
                    <tr
                      key={index}
                      style={styles.clickableRow}
                      onDoubleClick={() => {
                        quoteProcessor.clearAllData();
                        setShowPrintPage(false);
                        setInitValues(true);
                        setQuoteData(row);
                        setCurrentView('EDIT_QUOTE');
                      }}
                    >
                      <td>{row.quoteNumber}</td>
                      <td>{row.revision}</td>
                      <td>{row.productType ? getNameFromLovCode(productTypeLov, row.productType) : ''}</td>
                      <td>{row.status ? getNameFromLovCode(quoteStatusLov, row.status) : ''}</td>
                      <td>{row.createdByUsername ? row.createdByUsername : ''}</td>
                      <td>{row.holdUsername ? row.holdUsername : ''}</td>
                      <td>{row.customerName ? row.customerName : ''}</td>
                      <td>{row.customerPartNumber ? row.customerPartNumber : ''}</td>
                      <td>{row.partDescription ? row.partDescription : ''}</td>
                      <td>{row.programs?.length ? row.programs[0].programCode : ''}</td>
                      <td>{row.dateTimeSubmitted ? DateTime.fromMillis(row.dateTimeSubmitted).toFormat('LL/dd/y HH:mm:ss') : DateTime.fromMillis(row.createdTime).toFormat('LL/dd/y HH:mm:ss')}</td>
                      <td>{row.dueDate ? DateTimeBusiness.fromMillis(row.dueDate).minusBusiness({ days: 1 }).toFormat('LL/dd/y') : ''}</td>
                      <td>{row.dueDate ? DateTime.fromMillis(row.dueDate).toFormat('LL/dd/y') : ''}</td>
                      <td>{row.monthlyVolume ? row.monthlyVolume.toLocaleString() : ''}</td>
                      {appRoles.includes(CONFIG.ROLE_MAPPINGS.CREATE_QUOTE) ?
                        <td>
                          <FontAwesomeIcon
                            icon={faCopy}
                            size="lg"
                            onClick={e => {
                              e.stopPropagation();
                              const clone = cloneQuote(row, userId, loggedInUser);
                              quoteProcessor.clearAllData();
                              setShowPrintPage(false);
                              setInitValues(true);
                              setQuoteData(clone);
                              setCurrentView('EDIT_QUOTE');
                            }}
                            style={styles.cloneButton}
                            className="toolbar-button"
                          />
                        </td> :
                        null
                      }
                    </tr>
                  );
                })}
                </tbody>
              </Table> :
              <h5 style={styles.noResultsText}>No quotes found</h5>
        }
      </div>
    </div>
  );
};

const styles = {
  mainSearchContainer: {
    padding: 10,
  },
  tabContainer: {
    padding: 10,
    backgroundColor: COLORS.white,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: COLORS.whiteGray,
    borderStyle: 'solid',
    borderTopWidth: 0,
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  spinnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 20,
  },
  spinnerText: {
    margin: 0,
    marginTop: 10,
    fontSize: 20,
  },
  resultsContainer: {
    borderRadius: 4,
    backgroundColor: COLORS.white,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: COLORS.whiteGray,
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
  },
  noResultsText: {
    margin: 0,
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  clickableRow: {
    cursor: 'pointer',
  },
  cloneButton: {
    cursor: 'pointer',
    color: COLORS.midGray,
  },
};

export default Search;