import { DateTime } from 'luxon';
import {
  getFinishedGoodCalculations,
  getInvestmentCalculations,
} from '../helpers/calculations';
import { getRateOrgCodeFromLocator, round } from '../helpers/misc';
import { useRecoilValue } from 'recoil';
import {
  auxCostsAtom,
  capitalCostsAtom,
  currentRatesAtom,
  testingCostsAtom,
  paymentTypeLovAtom,
  depreciationMonthsLovAtom,
} from '../state';
import { Form, InputGroup, Table } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import COLORS from '../styles/colors';
import * as CONSTANTS from '../constants';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
  BarChart,
  Bar,
} from 'recharts';
import cloneDeep from 'lodash.clonedeep';

const InvestPage = ({ quoteData, style }) => {
  const currentRates = useRecoilValue(currentRatesAtom);
  const capitalCosts = useRecoilValue(capitalCostsAtom);
  const paymentTypeLov = useRecoilValue(paymentTypeLovAtom);
  const depreciationMonthsLov = useRecoilValue(depreciationMonthsLovAtom);
  const [volumePercentageValue, setVolumePercentageValue] = useState('100');
  const [annualSalesPriceReductionValue, setAnnualSalesPriceReductionValue] = useState('');
  const [investmentYearValue, setInvestmentYearValue] = useState(`${(new Date()).getFullYear()}`);
  const auxCosts = useRecoilValue(auxCostsAtom);
  const testingCosts = useRecoilValue(testingCostsAtom);
  const [costLines, setCostLines] = useState([]);
  const [editCostLines, setEditCostLines] = useState(false);
  const [fgPriceValue, setFgPriceValue] = useState('');
  const [sgaValue, setSgaValue] = useState('11');
  const [costVarianceValue, setCostVarianceValue] = useState('6');
  
  useEffect(() => {
    setCostLines([
      ...(auxCosts.length ? cloneDeep(auxCosts).map(o => ({
        ...o,
        costType: 'AUX',
      })) : []),
      ...(testingCosts?.length ? cloneDeep(testingCosts).map(o => ({
        ...o,
        costType: 'TEST',
      })) : []),
      ...(capitalCosts?.length ? cloneDeep(capitalCosts).filter(o => o.enabled).map(o => ({
        ...o,
        quantity: '1',
        costType: 'CAPITAL',
      })) : []),
    ]);
  }, [auxCosts, testingCosts, capitalCosts, editCostLines]);
  
  useEffect(() => {
    if(quoteData?.fgPrice) setFgPriceValue(`${quoteData.fgPrice}`);
  }, [quoteData]);
  
  const finishedGoodCalculations = getFinishedGoodCalculations({
    rates: currentRates,
    moldedParts: quoteData?.moldedParts ? quoteData.moldedParts : [],
    monthlyVolume: quoteData.monthlyVolume,
    partType: quoteData.partType,
    purchasedPartCosts: quoteData?.newPurchasedParts ? quoteData.newPurchasedParts : [],
    carryoverPartCosts: quoteData?.carryoverPurchasedParts ? quoteData.carryoverPurchasedParts.map(o => ({
      quantity: o.quantity,
      partCost: o.partCost,
    })) : [],
    assemblyCosts: quoteData?.assemblyCosts ? quoteData.assemblyCosts.map(line => ({
      location: line.location,
      description: line.assemblyType,
      pphPerPerson: line.pphPerPerson,
    })) : [],
    fgLocation: quoteData.fgLocation !== 'NULL' ? getRateOrgCodeFromLocator(quoteData.fgLocation) : null,
    inspectionType: quoteData.assemblyInspection,
    packagingType: quoteData.assemblyPackaging,
    packagingCosts: quoteData?.packagingCosts ? quoteData.packagingCosts.map(o => ({
      associatedPart: o.associatedPart,
      quantity: `${o.quantity}`,
      unitCost: o.unitCost,
    })) : [],
    auxCosts: quoteData?.auxCosts ? quoteData.auxCosts.map(o => ({
      quantity: `${o.quantity}`,
      unitCost: `${o.unitCost}`,
      paymentType: o.paymentType,
      depreciationMonths: o.depreciationMonths,
    })) : [],
    rMexFreightCost: quoteData.rMexFreightCost ? quoteData.rMexFreightCost : 0,
    testingCosts: quoteData?.testingCosts ? quoteData.testingCosts.map(o => ({
      quantity: `${o.quantity}`,
      unitCost: `${o.unitCost}`,
      paymentType: o.paymentType,
    })) : [],
    bom: quoteData.bom,
    productType: quoteData.productType,
    fgPrice: fgPriceValue?.length && !isNaN(parseFloat(fgPriceValue)) ? parseFloat(fgPriceValue) : null,
    royaltyPercentage: quoteData.royaltyPercentage,
  });
  
  const selectedToolingOption = quoteData.selectedToolingOption ? quoteData.selectedToolingOption : 'OPTION_A';
  const volumePercentageDecimal = volumePercentageValue && !isNaN(parseFloat(volumePercentageValue)) ? parseFloat(volumePercentageValue) / 100 : null;
  
  const investmentCalculations = getInvestmentCalculations({
    capitalCosts: costLines.filter(o => o.costType === 'CAPITAL'),
    auxCosts: costLines.filter(o => o.costType === 'AUX'),
    testingCosts: costLines.filter(o => o.costType === 'TEST'),
    monthlyVolume: volumePercentageDecimal != null ? volumePercentageDecimal * quoteData.monthlyVolume : quoteData.monthlyVolume,
    startOfProdYear: quoteData?.prodStartDate ? DateTime.fromMillis(quoteData.prodStartDate).year : null,
    startOfProdMonth: quoteData?.prodStartDate ? DateTime.fromMillis(quoteData.prodStartDate).month : null,
    programLife: quoteData?.programLife ? quoteData.programLife : null,
    annualPriceReductionPct: !isNaN(parseFloat(annualSalesPriceReductionValue)) ? parseFloat(annualSalesPriceReductionValue) / 100 : 0,
    fgPrice: fgPriceValue?.length && !isNaN(parseFloat(fgPriceValue)) ? parseFloat(fgPriceValue) : null,
    fgCost: selectedToolingOption === 'OPTION_A' ? finishedGoodCalculations?.standardCostA : finishedGoodCalculations?.standardCostB,
    corporateTaxRate: CONSTANTS.CORPORATE_TAX_RATE,
    royaltyPercentage: quoteData.royaltyPercentage,
    costVariance: costVarianceValue?.length && !isNaN(parseFloat(costVarianceValue)) ? parseFloat(costVarianceValue) / 100 : 0,
    sga: sgaValue?.length && !isNaN(parseFloat(sgaValue)) ? parseFloat(sgaValue) / 100 : 0,
  });
  
  const graphData = investmentCalculations?.pieceDataPerMonth ?
    investmentCalculations.pieceDataPerMonth.map((o, index, arr) => ({
      x: `${o.year}${`${o.month}`.padStart(2, '0')}`,
      cost: o.cost,
      price: o.price,
      profit: o.price - o.cost,
      gm: o.grossMargin,
      cumulativeCashFlow: arr.slice(0, index + 1).reduce((total, cur) => total + cur.cashFlow, 0) - (investmentCalculations?.investmentAmount ? investmentCalculations.investmentAmount : 0),
    })) :
    [];
  const breakEvenMonth = graphData.find(o => o.cumulativeCashFlow >= 0)?.x;
  
  const totalSellingPrice = fgPriceValue?.length && !isNaN(parseFloat(fgPriceValue)) ? parseFloat(fgPriceValue) + investmentCalculations.piecePriceAmortization : 0;
  const pieceCost = selectedToolingOption === 'OPTION_B' ?
    finishedGoodCalculations?.standardCostB ? finishedGoodCalculations.standardCostB + (finishedGoodCalculations.royaltyCost ? finishedGoodCalculations.royaltyCost : 0) : null :
    finishedGoodCalculations?.standardCostA ? finishedGoodCalculations.standardCostA + (finishedGoodCalculations.royaltyCost ? finishedGoodCalculations.royaltyCost : 0) : null;
  const totalPieceCost = pieceCost + investmentCalculations.pieceCostAmortization;
  
  return (
    <div style={style}>
      <div style={styles.mainContainer}>
        <h3>Inputs</h3>
        <div style={styles.inputsRow}>
          <div style={styles.inputsLabel}>
            <p style={{ margin: 0 }}>
              Volume
            </p>
          </div>
          <div style={styles.inputsBox}>
            <InputGroup>
              <Form.Control
                type="number"
                step="1"
                value={volumePercentageValue}
                onChange={e => setVolumePercentageValue(e.target.value)}
              />
              <InputGroup.Text style={styles.rightInputGroupText}>%</InputGroup.Text>
            </InputGroup>
          </div>
          <div style={styles.inputsLabel}>
            <p style={{ margin: 0 }}>
              Annual Sales Price Reduction
            </p>
          </div>
          <div style={styles.inputsBox}>
            <InputGroup>
              <Form.Control
                type="number"
                step="0.1"
                value={annualSalesPriceReductionValue}
                onChange={e => setAnnualSalesPriceReductionValue(e.target.value)}
              />
              <InputGroup.Text style={styles.rightInputGroupText}>%</InputGroup.Text>
            </InputGroup>
          </div>
        </div>
        <div style={styles.inputsRow}>
          <div style={styles.inputsLabel}>
            <p style={{ margin: 0 }}>
              Investment Year
            </p>
          </div>
          <div style={styles.inputsBox}>
            <Form.Control
              type="number"
              step="1"
              value={investmentYearValue}
              onChange={e => setInvestmentYearValue(e.target.value)}
            />
          </div>
          <div style={styles.inputsLabel}>
            <p style={{ margin: 0 }}>
              FG Price
            </p>
          </div>
          <div style={styles.inputsBox}>
            <Form.Control
              type="number"
              step="0.01"
              value={fgPriceValue}
              onChange={e => setFgPriceValue(e.target.value)}
            />
          </div>
        </div>
        <div style={styles.inputsRow}>
          <div style={styles.inputsLabel}>
            <p style={{ margin: 0 }}>
              SGA
            </p>
          </div>
          <div style={styles.inputsBox}>
            <InputGroup>
              <Form.Control
                type="number"
                step="0.1"
                value={sgaValue}
                onChange={e => setSgaValue(e.target.value)}
              />
              <InputGroup.Text style={styles.rightInputGroupText}>%</InputGroup.Text>
            </InputGroup>
          </div>
          <div style={styles.inputsLabel}>
            <p style={{ margin: 0 }}>
              Cost Variance
            </p>
          </div>
          <div style={styles.inputsBox}>
            <InputGroup>
              <Form.Control
                type="number"
                step="0.1"
                value={costVarianceValue}
                onChange={e => setCostVarianceValue(e.target.value)}
              />
              <InputGroup.Text style={styles.rightInputGroupText}>%</InputGroup.Text>
            </InputGroup>
          </div>
        </div>
        <div style={styles.tableTitleRow}>
          <h4 style={{ marginTop: 10, marginRight: 10 }}>Depreciation Lines</h4>
          <Form.Check
            type="checkbox"
            label="Edit"
            checked={editCostLines}
            onChange={() => setEditCostLines(prevState => !prevState)}
          />
        </div>
        <div style={styles.tableContainer}>
          <Table size="sm" style={{ marginBottom: 0 }}>
            <tbody>
            <tr style={{ fontWeight: 'bold' }}>
              <td>Type</td>
              <td>Description</td>
              <td>Quantity</td>
              <td>Cost</td>
              <td>Price</td>
              <td>Payment Type</td>
              <td>Depreciation (months)</td>
              <td>Total Cost</td>
              <td>Total Price</td>
            </tr>
            {costLines.map((costLine, index) => (
              <tr key={index}>
                <td>
                  <Form.Control
                    type="text"
                    value={costLine.costType}
                    disabled
                  />
                </td>
                <td>
                  <Form.Control
                    type="text"
                    value={costLine.description}
                    disabled
                  />
                </td>
                <td>
                  <Form.Control
                    type="number"
                    value={costLine.quantity ? costLine.quantity : '1'}
                    disabled
                  />
                </td>
                <td>
                  <InputGroup>
                    <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                    <Form.Control
                      type="number"
                      value={costLine.unitCost}
                      disabled
                    />
                  </InputGroup>
                </td>
                <td>
                  <InputGroup>
                    <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                    <Form.Control
                      type="number"
                      step="0.01"
                      min="0"
                      value={costLine.unitPrice}
                      onChange={e => setCostLines(prevState => {
                        const newState = cloneDeep(prevState);
                        newState[index].unitPrice = e.target.value;
                        return newState;
                      })}
                      disabled={!editCostLines}
                    />
                  </InputGroup>
                </td>
                <td>
                  <Form.Select
                    as="select"
                    value={costLine.paymentType}
                    onChange={e => setCostLines(prevState => {
                      const newState = cloneDeep(prevState);
                      newState[index].paymentType = e.target.value;
                      if(!e.target.value.startsWith('DEPRECIATE')) newState[index].depreciationMonths = 'NULL';
                      return newState;
                    })}
                    disabled={!editCostLines}
                  >
                    <option value="NULL">-- Select --</option>
                    {paymentTypeLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
                  </Form.Select>
                </td>
                <td>
                  <Form.Select
                    as="select"
                    value={costLine.depreciationMonths}
                    onChange={e => setCostLines(prevState => {
                      const newState = cloneDeep(prevState);
                      newState[index].depreciationMonths = e.target.value !== 'NULL' && !isNaN(parseInt(e.target.value, 10)) ?
                        parseInt(e.target.value, 10) : null;
                      return newState;
                    })}
                    disabled={!costLine.paymentType.startsWith('DEPRECIATE') || !editCostLines}
                  >
                    <option value="NULL">-- Select --</option>
                    {depreciationMonthsLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
                  </Form.Select>
                </td>
                <td>
                  <InputGroup>
                    <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                    <Form.Control
                      type="number"
                      value={costLine.quantity && costLine.quantity.length && !isNaN(parseInt(costLine.quantity, 10)) &&
                      costLine.unitCost && costLine.unitCost.length && !isNaN(parseFloat(costLine.unitCost)) ?
                        (parseInt(costLine.quantity, 10) * parseFloat(costLine.unitCost)).toFixed(4) : ''}
                      disabled
                    />
                  </InputGroup>
                </td>
                <td>
                  <InputGroup>
                    <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                    <Form.Control
                      type="number"
                      value={costLine.quantity && costLine.quantity.length && !isNaN(parseInt(costLine.quantity, 10)) &&
                      costLine.unitPrice && costLine.unitPrice.length && !isNaN(parseFloat(costLine.unitPrice)) ?
                        (parseInt(costLine.quantity, 10) * parseFloat(costLine.unitPrice)).toFixed(4) : ''}
                      disabled
                    />
                  </InputGroup>
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
        </div>
        <div style={styles.divider}/>
        <h3>Profitability Evaluation</h3>
        <table className="collapse-table">
          <tbody>
          <tr style={{ visibility: 'hidden' }}>
            <td width="11.11%"/>
            <td width="11.11%"/>
            <td width="11.11%"/>
            <td width="11.11%"/>
            <td width="11.11%"/>
            <td width="11.11%"/>
            <td width="11.11%"/>
            <td width="11.11%"/>
            <td width="11.11%"/>
          </tr>
          <tr>
            <th style={styles.cellRight}>Part Description</th>
            <td style={{ ...styles.cellBorder, ...styles.cellCenter }} colSpan={3}>
              {quoteData?.partDescription ? quoteData.partDescription : ''}
            </td>
            <td/>
            <td/>
            <td/>
            <th style={styles.cellRight}>SGA</th>
            <td
              style={{ ...styles.cellRight, ...styles.cellBorder }}>
              {`${sgaValue?.length && !isNaN(parseFloat(sgaValue)) ? sgaValue : '0'}%`}
            </td>
          </tr>
          <tr>
            <th style={styles.cellRight}>Customer</th>
            <td style={{ ...styles.cellBorder, ...styles.cellCenter }} colSpan={3}>
              {quoteData?.customerName ? quoteData.customerName : ''}
            </td>
            <td/>
            <td/>
            <td/>
            <th style={styles.cellRight}>Corporate Tax</th>
            <td style={{ ...styles.cellRight, ...styles.cellBorder }}>
              {`${CONSTANTS.CORPORATE_TAX_RATE * 100}%`}
            </td>
          </tr>
          <tr>
            <th style={styles.cellRight}>Model</th>
            <td style={{ ...styles.cellBorder, ...styles.cellCenter }} colSpan={3}>
              {quoteData?.programs?.length ? quoteData?.programs[0].programCode : ''}
            </td>
            <td/>
            <td/>
            <td/>
            <th style={styles.cellRight}>Cost Variance</th>
            <td style={{ ...styles.cellRight, ...styles.cellBorder }}>
              {`${costVarianceValue?.length && !isNaN(parseFloat(costVarianceValue)) ? costVarianceValue : '0'}%`}
            </td>
          </tr>
          <tr>
            <th style={styles.cellRight}>Customer Part No.</th>
            <td style={{ ...styles.cellBorder, ...styles.cellCenter }} colSpan={3}>
              {quoteData?.customerPartNumber ? quoteData.customerPartNumber : ''}
            </td>
            <td/>
            <td/>
            <td/>
            <td/>
            <td/>
          </tr>
          <tr>
            <th style={styles.cellRight}>Program Life</th>
            <td style={{ ...styles.cellBorder, ...styles.cellRight }}>
              {quoteData?.programLife ? `${quoteData.programLife} months` : ''}
            </td>
            <td/>
            <th style={styles.cellCenter}>Original</th>
            <th style={styles.cellCenter}>Piece Price Amortization</th>
            <th style={styles.cellCenter}>Total</th>
            <td/>
            <td/>
            <td/>
          </tr>
          <tr>
            <th style={styles.cellRight}>SOP Year</th>
            <td
              style={{ ...styles.cellBorder, ...styles.cellRight }}>
              {quoteData?.prodStartDate ? DateTime.fromMillis(quoteData.prodStartDate).year : ''}
            </td>
            <th style={styles.cellRight}>Selling Price</th>
            <td style={{ ...styles.cellRight, ...styles.cellBorder }}>
              {fgPriceValue?.length && !isNaN(parseFloat(fgPriceValue)) ? `$${fgPriceValue}` : ''}
            </td>
            <td style={{ ...styles.cellRight, ...styles.cellBorder }}>
              {`$${investmentCalculations.piecePriceAmortization.toFixed(4)}`}
            </td>
            <td
              style={{ ...styles.cellRight, ...styles.cellBorder }}>
              {totalSellingPrice ? `$${totalSellingPrice.toFixed(4)}` : ''}
            </td>
            <td/>
            <th style={styles.cellCenter}>Volume</th>
            <th style={styles.cellCenter}>Sales</th>
          </tr>
          <tr>
            <th style={styles.cellRight}>SOP Month</th>
            <td
              style={{ ...styles.cellBorder, ...styles.cellRight }}>
              {quoteData?.prodStartDate ? DateTime.fromMillis(quoteData.prodStartDate).month : ''}
            </td>
            <th style={styles.cellRight}>Piece Cost</th>
            <td style={{ ...styles.cellRight, ...styles.cellBorder }}>
              {pieceCost != null ? pieceCost.toFixed(4) : ''}
            </td>
            <td style={{ ...styles.cellRight, ...styles.cellBorder }}>
              {`$${investmentCalculations.pieceCostAmortization.toLocaleString()}`}
            </td>
            <td style={{ ...styles.cellRight, ...styles.cellBorder }}>
              {totalPieceCost ? `$${totalPieceCost.toFixed(4)}` : ''}
            </td>
            <th style={styles.cellRight}>Monthly</th>
            <td style={{ ...styles.cellRight, ...styles.cellBorder }}>
              {quoteData?.monthlyVolume && volumePercentageDecimal != null ?
                Math.round(quoteData.monthlyVolume * volumePercentageDecimal).toLocaleString() : ''}
            </td>
            <td style={{ ...styles.cellRight, ...styles.cellBorder }}>
              {quoteData?.monthlyVolume && volumePercentageDecimal != null && fgPriceValue?.length && !isNaN(parseFloat(fgPriceValue)) ?
                Math.round(quoteData.monthlyVolume * volumePercentageDecimal * parseFloat(fgPriceValue)).toLocaleString() : ''}
            </td>
          </tr>
          <tr>
            <th style={styles.cellRight}>Piece/Vehicle</th>
            <td style={{ ...styles.cellBorder, ...styles.cellRight }}>
              {quoteData?.estimatedPpv ? quoteData.estimatedPpv : ''}
            </td>
            <th style={styles.cellRight}>Gross Margin</th>
            <td style={{ ...styles.cellRight, ...styles.cellBorder }}>
              {fgPriceValue?.length && !isNaN(parseFloat(fgPriceValue)) && parseFloat(fgPriceValue) > 0 && pieceCost != null ?
                `${(((parseFloat(fgPriceValue) - pieceCost) / parseFloat(fgPriceValue)) * 100).toFixed(1)}%` :
                ''}
            </td>
            <td style={{ ...styles.cellRight, ...styles.cellBorder, backgroundColor: COLORS.midGray }}/>
            <td style={{ ...styles.cellRight, ...styles.cellBorder }}>
              {totalSellingPrice && totalPieceCost ? `${(((totalSellingPrice - totalPieceCost) / totalSellingPrice) * 100).toFixed(1)}%` : ''}
            </td>
            <th style={styles.cellRight}>Model Life</th>
            <td style={{ ...styles.cellRight, ...styles.cellBorder }}>
              {quoteData?.monthlyVolume && volumePercentageDecimal != null && quoteData?.programLife ?
                Math.round(quoteData.monthlyVolume * quoteData.programLife * volumePercentageDecimal).toLocaleString() : ''}
            </td>
            <td style={{ ...styles.cellRight, ...styles.cellBorder }}>
              {quoteData?.monthlyVolume && volumePercentageDecimal != null && fgPriceValue?.length && !isNaN(parseFloat(fgPriceValue)) && quoteData?.programLife ?
                Math.round(quoteData.monthlyVolume * quoteData.programLife * volumePercentageDecimal * parseFloat(fgPriceValue)).toLocaleString() : ''}
            </td>
          </tr>
          <tr>
            <th style={styles.cellRight} rowSpan={2}>Annual Sales Price Reduction</th>
            <td style={{ ...styles.cellBorder, ...styles.cellRight }} rowSpan={2}>
              {annualSalesPriceReductionValue?.length ? `${annualSalesPriceReductionValue}%` : ''}
            </td>
            <th style={styles.cellRight} colSpan={2}>Amortization Volume</th>
            <td style={{ ...styles.cellRight, ...styles.cellBorder }}>
              {investmentCalculations.amortizationVolume}
            </td>
            <td/>
            <td/>
            <td/>
            <td/>
          </tr>
          <tr>
            <th style={styles.cellRight} colSpan={2}>Amortization Period</th>
            <td style={{ ...styles.cellRight, ...styles.cellBorder }}>
              {investmentCalculations.amortizationPeriod}
            </td>
            <td/>
            <td/>
            <td/>
            <td/>
          </tr>
          <tr>
            <th style={styles.cellRight}>Investment Year</th>
            <td style={{ ...styles.cellBorder, ...styles.cellRight }}>
              {investmentYearValue}
            </td>
            <td/>
            <td/>
            <td/>
            <td/>
            <td/>
            <td/>
            <td/>
          </tr>
          <tr>
            <th style={styles.cellRight}>Investment Amount</th>
            <td style={{ ...styles.cellBorder, ...styles.cellRight }}>
              {`$${investmentCalculations.investmentAmount.toLocaleString()}`}
            </td>
            <td/>
            <td/>
            <td/>
            <td/>
            <td/>
            <td/>
            <td/>
          </tr>
          <tr>
            <th style={styles.cellRight}>Major Expenses</th>
            <td style={{ ...styles.cellBorder, ...styles.cellRight }}>
              {`$${investmentCalculations.majorExpenses.toLocaleString()}`}
            </td>
            <td/>
            <td/>
            <td/>
            <td/>
            <td/>
            <td/>
            <td/>
          </tr>
          
          </tbody>
        </table>
        {graphData?.length ?
          <>
            <div style={styles.tableContainer}>
              <h5>Cost/Price Data</h5>
              <ResponsiveContainer width="100%" height={400}>
                <LineChart
                  data={graphData}
                  height={400}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 30,
                    bottom: 10,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3"/>
                  <XAxis dataKey="x" name="month" tickFormatter={tick => `${tick.slice(4, 6)}/${tick.slice(2, 4)}`}/>
                  <YAxis/>
                  <Tooltip
                    labelFormatter={label => `${label.slice(4, 6)}/${label.slice(2, 4)}`}
                    formatter={(value, name) => [`$${value.toFixed(5)}`, name]}
                  />
                  <Legend/>
                  <Line type="monotone" dataKey="cost" stroke={COLORS.red} dot={{ fill: COLORS.red }}/>
                  <Line type="monotone" dataKey="price" stroke={COLORS.blue} dot={{ fill: COLORS.blue }}/>
                  <Line type="monotone" dataKey="profit" stroke={COLORS.green} dot={{ fill: COLORS.green }}/>
                </LineChart>
              </ResponsiveContainer>
            </div>
            <div style={styles.tableContainer}>
              <h5>Cumulative Cash Flow</h5>
              <ResponsiveContainer width="100%" height={400}>
                <LineChart
                  data={graphData}
                  height={400}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 30,
                    bottom: 10,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3"/>
                  <XAxis dataKey="x" name="month" tickFormatter={tick => `${tick.slice(4, 6)}/${tick.slice(2, 4)}`}/>
                  <YAxis/>
                  <Tooltip
                    labelFormatter={label => `${label.slice(4, 6)}/${label.slice(2, 4)}`}
                    formatter={(value, name) => [`$${value.toFixed(5)}`, name]}
                  />
                  <Legend/>
                  {breakEvenMonth ? <ReferenceLine x={breakEvenMonth} stroke="red" label="Break Even" /> : null}
                  <Line type="monotone" dataKey="cumulativeCashFlow" stroke={COLORS.green}
                        dot={{ fill: COLORS.green }}/>
                </LineChart>
              </ResponsiveContainer>
            </div>
            <div style={styles.tableContainer}>
              <h5>Gross Margin</h5>
              <ResponsiveContainer width="100%" height={400}>
                <BarChart
                  data={graphData}
                  height={400}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 30,
                    bottom: 10,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3"/>
                  <XAxis dataKey="x" name="month" tickFormatter={tick => `${tick.slice(4, 6)}/${tick.slice(2, 4)}`}/>
                  <YAxis
                    domain={[Math.min(...graphData.map(o => o.gm)) < 0 ? Math.floor(Math.min(...graphData.map(o => o.gm))) - 5 : 0, 100]}/>
                  <Tooltip
                    labelFormatter={label => `${label.slice(4, 6)}/${label.slice(2, 4)}`}
                    formatter={value => [`${value.toFixed(2)}%`, 'Gross Margin']}
                  />
                  <Legend formatter={value => value === 'gm' ? 'Gross Margin' : value}/>
                  <Bar dataKey="gm" fill={COLORS.orange}/>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </> :
          null
        }
        {investmentCalculations?.pieceDataPerYear?.length ?
          <div style={styles.tableContainer}>
            <Table size="sm" style={{ marginBottom: 0 }}>
              <thead>
              <tr>
                <th>Year</th>
                <th>0</th>
                {investmentCalculations.pieceDataPerYear.map((o, index) => <th key={o.year}>{index + 1}</th>)}
              </tr>
              </thead>
              <tbody>
              <tr>
                <th>Amortization</th>
                <td/>
                {investmentCalculations.pieceDataPerYear.map(o => <td key={o.year}>{round(o.costAmortization, 2).toLocaleString()}</td>)}
              </tr>
              <tr>
                <th>Profit After Tax</th>
                <td/>
                {investmentCalculations.pieceDataPerYear.map(o => <td key={o.year}>{round(o.profitAfterTax, 2).toLocaleString()}</td>)}
              </tr>
              <tr>
                <th>Cash Flow</th>
                <td>{round(-investmentCalculations?.investmentAmount, 2).toLocaleString()}</td>
                {investmentCalculations.pieceDataPerYear.map(o => <td key={o.year}>{round(o.cashFlow, 2).toLocaleString()}</td>)}
              </tr>
              <tr>
                <th>NPV Cash Flow</th>
                {investmentCalculations.adjustedCashFlow.map((value, index) => <td key={index}>{round(value, 2).toLocaleString()}</td>)}
              </tr>
              </tbody>
            </Table>
          </div> :
          null
        }
        <div
          style={{
            ...styles.profitIndexBox,
            backgroundColor: investmentCalculations?.internalRateReturn != null ?
              investmentCalculations?.internalRateReturn >= 0.14 ?
                COLORS.green :
                COLORS.red :
              null,
          }}
        >
          <h5 style={{ margin: 0, marginRight: 10 }}>Internal Rate of Return (>14%):</h5>
          <h5 style={{ margin: 0, marginRight: 10, fontWeight: 'bold' }}>
            {investmentCalculations?.internalRateReturn != null ? `${(investmentCalculations.internalRateReturn * 100).toFixed(1)}%` : ''}
          </h5>
        </div>
        <div
          style={{
            ...styles.profitIndexBox,
            backgroundColor: investmentCalculations?.netPresentValue != null ?
              investmentCalculations?.netPresentValue > 0 ?
                COLORS.green :
                COLORS.red :
              null,
          }}
        >
          <h5 style={{ margin: 0, marginRight: 10 }}>Net Present Value (>0):</h5>
          <h5 style={{ margin: 0, marginRight: 10, fontWeight: 'bold' }}>
            {investmentCalculations?.netPresentValue != null ? `$${Math.round(investmentCalculations.netPresentValue).toLocaleString()}` : ''}
          </h5>
        </div>
        <div
          style={{
            ...styles.profitIndexBox,
            backgroundColor: investmentCalculations?.returnOnInvestment != null ?
              investmentCalculations?.returnOnInvestment >= 0.14 ?
                COLORS.green :
                COLORS.red :
              null,
          }}
        >
          <h5 style={{ margin: 0, marginRight: 10 }}>Return on Investment (>14%):</h5>
          <h5 style={{ margin: 0, marginRight: 10, fontWeight: 'bold' }}>
            {investmentCalculations?.returnOnInvestment != null ? `${(investmentCalculations.returnOnInvestment * 100).toFixed(1)}%` : ''}
          </h5>
        </div>
        <div
          style={{
            ...styles.profitIndexBox,
            backgroundColor: investmentCalculations?.payBackPeriod != null ?
              investmentCalculations?.payBackPeriod <= 5 ?
                COLORS.green :
                COLORS.red :
              null,
          }}
        >
          <h5 style={{ margin: 0, marginRight: 10 }}>{`Pay Back Period (<5)`}:</h5>
          <h5 style={{ margin: 0, marginRight: 10, fontWeight: 'bold' }}>
            {investmentCalculations?.payBackPeriod != null ? `${investmentCalculations.payBackPeriod.toFixed(1)} years` : ''}
          </h5>
        </div>
        <div
          style={{
            ...styles.profitIndexBox,
            backgroundColor: investmentCalculations?.profitabilityIndex != null ?
              investmentCalculations?.profitabilityIndex >= 1 ?
                COLORS.green :
                COLORS.red :
              null,
          }}
        >
          <h5 style={{ margin: 0, marginRight: 10 }}>Profitability Index (>1):</h5>
          <h5 style={{ margin: 0, marginRight: 10, fontWeight: 'bold' }}>
            {investmentCalculations?.profitabilityIndex != null ? investmentCalculations.profitabilityIndex.toFixed(2) : ''}
          </h5>
        </div>
      </div>
    </div>
  );
};

const styles = {
  mainContainer: {
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
  },
  inputsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 5,
  },
  inputsLabel: {
    flexBasis: '23%',
    marginLeft: '1%',
    marginRight: '1%',
    textAlign: 'right',
  },
  inputsBox: {
    flexBasis: '23%',
    marginLeft: '1%',
    marginRight: '1%',
  },
  cellCenter: {
    textAlign: 'center',
    verticalAlign: 'center',
  },
  cellRight: {
    textAlign: 'right',
  },
  cellBorder: {
    border: '1px solid black',
  },
  divider: {
    height: 1,
    backgroundColor: COLORS.midGray,
    marginTop: 10,
    marginBottom: 10,
  },
  leftInputGroupText: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRightWidth: 0,
  },
  rightInputGroupText: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeftWidth: 0,
  },
  profitIndexBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 10,
    border: '1px solid black',
    marginTop: 10,
  },
  tableContainer: {
    padding: 10,
    borderRadius: 10,
    backgroundColor: COLORS.white,
    marginTop: 10,
  },
  tableTitleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
};

export default InvestPage;