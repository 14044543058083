import { useState, useEffect } from 'react';
import COLORS from '../styles/colors';
import { Form, InputGroup, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import * as CONFIG from '../config';
import {
  quoteDataAtom,
  appRolesAtom,
  userIdAtom,
  saveQuoteTriggerAtom,
  quoteProcessorAtom,
  packagingCostsAtom,
  rMexFreightValueAtom,
  bomDataAtom,
  initValuesAtom,
  quoteNewPartNumbersAtom,
} from '../state';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import cloneDeep from 'lodash.clonedeep';
import { mmToInch, inchToMm, gramsToPounds, getPackagingCalculations } from '../helpers/calculations';

const QuoteTabPackaging = () => {
  const quoteData = useRecoilValue(quoteDataAtom);
  const appRoles = useRecoilValue(appRolesAtom);
  const userId = useRecoilValue(userIdAtom);
  const quoteProcessor = useRecoilValue(quoteProcessorAtom);
  const saveQuoteTrigger = useRecoilValue(saveQuoteTriggerAtom);
  const quoteNewPartNumbers = useRecoilValue(quoteNewPartNumbersAtom);
  const setBomData = useSetRecoilState(bomDataAtom);
  const [rMexFreightValue, setRMexFreightValue] = useRecoilState(rMexFreightValueAtom);
  const [packagingCosts, setPackagingCosts] = useRecoilState(packagingCostsAtom);
  const [packageLengthValue, setPackageLengthValue] = useState('');
  const [packageWidthValue, setPackageWidthValue] = useState('');
  const [packageHeightValue, setPackageHeightValue] = useState('');
  const [partLengthValue, setPartLengthValue] = useState('');
  const [partWidthValue, setPartWidthValue] = useState('');
  const [partHeightValue, setPartHeightValue] = useState('');
  const [partWeightValue, setPartWeightValue] = useState('');
  const [initValues, setInitValues] = useRecoilState(initValuesAtom);
  
  useEffect(() => {
    if(saveQuoteTrigger) {
      quoteProcessor.setPackagingData({
        packagingCosts: packagingCosts.map(o => ({
          bomId: o.bomId,
          isNew: o.isNew,
          partNumber: o.partNumber,
          description: o.description,
          associatedPart: o.associatedPart,
          quantity: o.quantity && !isNaN(parseInt(o.quantity, 10)) ? parseInt(o.quantity, 10) : null,
          unitCost: o.unitCost,
        })),
        rMexFreightCost: rMexFreightValue && !isNaN(parseFloat(rMexFreightValue)) ? parseFloat(rMexFreightValue) : null,
      });
    }
  }, [saveQuoteTrigger, quoteProcessor, packagingCosts, rMexFreightValue]);
  
  useEffect(() => {
    if(initValues && quoteData) {
      setPackagingCosts(quoteData.packagingCosts ? quoteData.packagingCosts.map(o => ({
        bomId: o.bomId,
        isNew: o.isNew,
        partNumber: o.partNumber,
        description: o.description,
        associatedPart: o.associatedPart,
        quantity: o.quantity != null ? `${o.quantity}` : '',
        unitCost: o.unitCost,
      })) : []);
      setRMexFreightValue(quoteData.rMexFreightCost != null ? `${quoteData.rMexFreightCost}` : '');
      setInitValues(false);
    }
  }, [
    initValues,
    quoteData,
    setPackagingCosts,
    setRMexFreightValue,
    setInitValues,
  ]);
  
  const designAssignment = quoteData.assignments.find(o => o.types.includes('DESIGN_ESTIMATOR'));
  const designAssignedUserId = designAssignment ? designAssignment.userId : null;
  const feasibilityAssignment = quoteData.assignments.find(o => o.types.includes('FEASIBILITY'));
  const feasibilityAssignmentUserId = feasibilityAssignment ? feasibilityAssignment.userId : null;
  
  const disableAllInputs = quoteData.status !== 'SANDBOX' && (quoteData.holdUserId !== userId || quoteData.isNotActive || !['IN_PROGRESS', 'FEASIBILITY'].includes(quoteData.status) ||
    (!(appRoles.includes(CONFIG.ROLE_MAPPINGS.DESIGN_ESTIMATOR) && designAssignedUserId === userId) &&
      !(appRoles.includes(CONFIG.ROLE_MAPPINGS.FEASIBILITY) && userId === feasibilityAssignmentUserId)));
  
  const packagingCalculations = getPackagingCalculations({
    packagingCosts,
  });
  
  const packageVolume = packageLengthValue && !isNaN(parseFloat(packageLengthValue)) && packageWidthValue &&
  !isNaN(parseFloat(packageWidthValue)) && packageHeightValue && !isNaN(parseFloat(packageHeightValue)) ?
    (parseFloat(packageLengthValue) * parseFloat(packageWidthValue) * parseFloat(packageHeightValue)) : null;
  
  const partVolume = partLengthValue && !isNaN(parseFloat(partLengthValue)) && partWidthValue &&
  !isNaN(parseFloat(partWidthValue)) && partHeightValue && !isNaN(parseFloat(partHeightValue)) ?
    (mmToInch(parseFloat(partLengthValue)) * mmToInch(parseFloat(partWidthValue)) * mmToInch(parseFloat(partHeightValue))) : null;
  
  const partsPerBox = packageVolume && partVolume ? Math.ceil(packageVolume / partVolume) : null;
  
  const partWeightPerBox = partsPerBox && partWeightValue && !isNaN(parseFloat(partWeightValue)) ?
    gramsToPounds(parseFloat(partWeightValue) * partsPerBox) : null;
  
  return (
    <>
      <h5 style={{ ...styles.lineTitle, textAlign: 'left', marginTop: 20, marginBottom: 20 }}>
        <span style={{ ...styles.lineTitleText, backgroundColor: COLORS.white }}>
          Packaging Costs
        </span>
      </h5>
      <div style={styles.tabContainerRow}>
        <div style={styles.tabContainerLabel}>
          <p style={styles.noMarginText}>R-Mex Freight</p>
        </div>
        <InputGroup style={{ flexBasis: '28%' }}>
          <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
          <Form.Control
            type="number"
            step="0.01"
            min="0"
            value={rMexFreightValue}
            onChange={e => setRMexFreightValue(e.target.value)}
            disabled={disableAllInputs}
          />
        </InputGroup>
      </div>
      <Table size="sm" style={{ marginBottom: 0 }}>
        <tbody>
        <tr style={{ fontWeight: 'bold' }}>
          <td>P/N</td>
          <td>Description</td>
          <td>New</td>
          <td>Associated Part</td>
          <td>Parts / Box</td>
          <td>Oracle Cost</td>
          <td>Unit Cost</td>
          <td>Total Cost</td>
        </tr>
        {packagingCosts?.length ?
          packagingCosts.map((packObj, index) => {
            const newPartObj = quoteNewPartNumbers?.find(o => o.nodeId === packObj.bomId);
            return (
              <tr key={index}>
                <td style={{ verticalAlign: 'middle' }}>{newPartObj ? newPartObj.partNumber : packObj.partNumber}</td>
                <td style={{ verticalAlign: 'middle' }}>{packObj.description}</td>
                <td style={{ verticalAlign: 'middle' }}>{packObj.isNew ? 'YES' : 'NO'}</td>
                <td style={{ verticalAlign: 'middle' }}>{packObj.associatedPart}</td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Form.Control
                    type="number"
                    step="1"
                    min="1"
                    value={packObj.quantity}
                    onChange={e => {
                      setPackagingCosts(prevState => {
                        const newState = cloneDeep(prevState);
                        newState[index].quantity = e.target.value;
                        return newState;
                      });
                      setBomData(prevState => {
                        let newState = cloneDeep(prevState);
                        const itemIndex = newState.nodes.findIndex(o => o.id === packObj.bomId);
                        if(itemIndex < 0) return prevState;
                        newState.nodes[itemIndex].quantity = e.target.value;
                        return newState;
                      });
                    }}
                    disabled={disableAllInputs}
                  />
                </td>
                <td
                  style={{ verticalAlign: 'middle' }}>{packObj.oracleUnitCost ? `$${packObj.oracleUnitCost}` : ''}</td>
                <td style={{ verticalAlign: 'middle' }}>{`$${packObj.unitCost}`}</td>
                <td style={{ verticalAlign: 'middle' }}>
                  {packObj.quantity && packObj.quantity.length && !isNaN(parseInt(packObj.quantity, 10)) &&
                  packObj.unitCost != null && parseInt(packObj.quantity, 10) ?
                    `$${(packObj.unitCost / parseInt(packObj.quantity, 10)).toFixed(5)}` : ''}
                </td>
              </tr>
            );
          }) :
          <tr>
            <td colSpan="6">
              No packaging added to the BOM
            </td>
          </tr>
        }
        </tbody>
      </Table>
      <div style={styles.addMoldedPartButtonRow}>
        <p style={{ marginRight: 10, marginBottom: 0 }}>Total Packaging Cost</p>
        <InputGroup style={{ flexBasis: '25%' }}>
          <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
          <Form.Control
            type="text"
            value={packagingCalculations?.totalPackagingCost ? packagingCalculations.totalPackagingCost.toFixed(5) : ''}
            disabled
          />
        </InputGroup>
      </div>
      <h5 style={{ ...styles.lineTitle, textAlign: 'left', marginTop: 20, marginBottom: 20 }}>
                  <span style={{ ...styles.lineTitleText, backgroundColor: COLORS.white }}>
                    Packaging Calculator
                  </span>
      </h5>
      <div style={styles.tabContainerRow}>
        <div style={styles.tabContainerLabel}>
          <p style={styles.noMarginText}>Package Length</p>
        </div>
        <InputGroup style={{ flexBasis: '38%' }}>
          <Form.Control
            type="number"
            step="0.1"
            min="0.1"
            value={packageLengthValue}
            onChange={e => setPackageLengthValue(e.target.value)}
            disabled={disableAllInputs}
          />
          <InputGroup.Text style={styles.rightInputGroupText}>in</InputGroup.Text>
        </InputGroup>
        <div style={{ ...styles.tabContainerLabel, flexBasis: '2%' }}/>
        <InputGroup style={{ flexBasis: '38%' }}>
          <Form.Control
            type="number"
            value={packageLengthValue && !isNaN(parseFloat(packageLengthValue)) ? inchToMm(parseFloat(packageLengthValue)).toFixed(2) : ''}
            disabled
          />
          <InputGroup.Text style={styles.rightInputGroupText}>mm</InputGroup.Text>
        </InputGroup>
      </div>
      <div style={styles.tabContainerRow}>
        <div style={styles.tabContainerLabel}>
          <p style={styles.noMarginText}>Package Width</p>
        </div>
        <InputGroup style={{ flexBasis: '38%' }}>
          <Form.Control
            type="number"
            step="0.1"
            min="0.1"
            value={packageWidthValue}
            onChange={e => setPackageWidthValue(e.target.value)}
            disabled={disableAllInputs}
          />
          <InputGroup.Text style={styles.rightInputGroupText}>in</InputGroup.Text>
        </InputGroup>
        <div style={{ ...styles.tabContainerLabel, flexBasis: '2%' }}/>
        <InputGroup style={{ flexBasis: '38%' }}>
          <Form.Control
            type="number"
            value={packageWidthValue && !isNaN(parseFloat(packageWidthValue)) ? inchToMm(parseFloat(packageWidthValue)).toFixed(2) : ''}
            disabled
          />
          <InputGroup.Text style={styles.rightInputGroupText}>mm</InputGroup.Text>
        </InputGroup>
      </div>
      <div style={styles.tabContainerRow}>
        <div style={styles.tabContainerLabel}>
          <p style={styles.noMarginText}>Package Height</p>
        </div>
        <InputGroup style={{ flexBasis: '38%' }}>
          <Form.Control
            type="number"
            step="0.1"
            min="0.1"
            value={packageHeightValue}
            onChange={e => setPackageHeightValue(e.target.value)}
            disabled={disableAllInputs}
          />
          <InputGroup.Text style={styles.rightInputGroupText}>in</InputGroup.Text>
        </InputGroup>
        <div style={{ ...styles.tabContainerLabel, flexBasis: '2%' }}/>
        <InputGroup style={{ flexBasis: '38%' }}>
          <Form.Control
            type="number"
            value={packageHeightValue && !isNaN(parseFloat(packageHeightValue)) ? inchToMm(parseFloat(packageHeightValue)).toFixed(2) : ''}
            disabled
          />
          <InputGroup.Text style={styles.rightInputGroupText}>mm</InputGroup.Text>
        </InputGroup>
      </div>
      <div style={styles.tabContainerRow}>
        <div style={styles.tabContainerLabel}>
          <p style={styles.noMarginText}>Package Volume</p>
        </div>
        <InputGroup style={{ flexBasis: '78%' }}>
          <Form.Control
            type="number"
            value={packageVolume ? packageVolume.toFixed(2) : ''}
            disabled
          />
          <InputGroup.Text style={styles.rightInputGroupText}>in<sup>3</sup></InputGroup.Text>
        </InputGroup>
      </div>
      <div style={styles.tabContainerRow}>
        <div style={styles.tabContainerLabel}>
          <p style={styles.noMarginText}>Part Length</p>
        </div>
        <InputGroup style={{ flexBasis: '38%' }}>
          <Form.Control
            type="number"
            step="0.1"
            min="0.1"
            value={partLengthValue}
            onChange={e => setPartLengthValue(e.target.value)}
            disabled={disableAllInputs}
          />
          <InputGroup.Text style={styles.rightInputGroupText}>mm</InputGroup.Text>
        </InputGroup>
        <div style={{ ...styles.tabContainerLabel, flexBasis: '2%' }}/>
        <InputGroup style={{ flexBasis: '38%' }}>
          <Form.Control
            type="number"
            value={partLengthValue && !isNaN(parseFloat(partLengthValue)) ? mmToInch(parseFloat(partLengthValue)).toFixed(2) : ''}
            disabled
          />
          <InputGroup.Text style={styles.rightInputGroupText}>in</InputGroup.Text>
        </InputGroup>
      </div>
      <div style={styles.tabContainerRow}>
        <div style={styles.tabContainerLabel}>
          <p style={styles.noMarginText}>Part Width</p>
        </div>
        <InputGroup style={{ flexBasis: '38%' }}>
          <Form.Control
            type="number"
            step="0.1"
            min="0.1"
            value={partWidthValue}
            onChange={e => setPartWidthValue(e.target.value)}
            disabled={disableAllInputs}
          />
          <InputGroup.Text style={styles.rightInputGroupText}>mm</InputGroup.Text>
        </InputGroup>
        <div style={{ ...styles.tabContainerLabel, flexBasis: '2%' }}/>
        <InputGroup style={{ flexBasis: '38%' }}>
          <Form.Control
            type="number"
            value={partWidthValue && !isNaN(parseFloat(partWidthValue)) ? mmToInch(parseFloat(partWidthValue)).toFixed(2) : ''}
            disabled
          />
          <InputGroup.Text style={styles.rightInputGroupText}>in</InputGroup.Text>
        </InputGroup>
      </div>
      <div style={styles.tabContainerRow}>
        <div style={styles.tabContainerLabel}>
          <p style={styles.noMarginText}>Part Height</p>
        </div>
        <InputGroup style={{ flexBasis: '38%' }}>
          <Form.Control
            type="number"
            step="0.1"
            min="0.1"
            value={partHeightValue}
            onChange={e => setPartHeightValue(e.target.value)}
            disabled={disableAllInputs}
          />
          <InputGroup.Text style={styles.rightInputGroupText}>mm</InputGroup.Text>
        </InputGroup>
        <div style={{ ...styles.tabContainerLabel, flexBasis: '2%' }}/>
        <InputGroup style={{ flexBasis: '38%' }}>
          <Form.Control
            type="number"
            value={partHeightValue && !isNaN(parseFloat(partHeightValue)) ? mmToInch(parseFloat(partHeightValue)).toFixed(2) : ''}
            disabled
          />
          <InputGroup.Text style={styles.rightInputGroupText}>in</InputGroup.Text>
        </InputGroup>
      </div>
      <div style={styles.tabContainerRow}>
        <div style={styles.tabContainerLabel}>
          <p style={styles.noMarginText}>Part Volume</p>
        </div>
        <InputGroup style={{ flexBasis: '78%' }}>
          <Form.Control
            type="text"
            value={partVolume ? partVolume.toFixed(2) : ''}
            disabled
          />
          <InputGroup.Text style={styles.rightInputGroupText}>in<sup>3</sup></InputGroup.Text>
        </InputGroup>
      </div>
      <div style={styles.tabContainerRow}>
        <div style={styles.tabContainerLabel}>
          <p style={styles.noMarginText}>Part Weight</p>
        </div>
        <InputGroup style={{ flexBasis: '78%' }}>
          <Form.Control
            type="number"
            step="0.1"
            min="0.1"
            value={partWeightValue}
            onChange={e => setPartWeightValue(e.target.value)}
            disabled={disableAllInputs}
          />
          <InputGroup.Text style={styles.rightInputGroupText}>g</InputGroup.Text>
        </InputGroup>
      </div>
      <div style={styles.tabContainerRow}>
        <div style={styles.tabContainerLabel}>
          <p style={styles.noMarginText}>Number of Parts in Box</p>
        </div>
        <Form.Control
          type="number"
          style={{ flexBasis: '15%' }}
          value={partsPerBox ? partsPerBox : ''}
          disabled
        />
        <div style={styles.tabContainerLabel}>
          <p style={styles.noMarginText}>Parts Weight per Box</p>
        </div>
        <InputGroup style={{ flexBasis: '15%' }}>
          <Form.Control
            type="text"
            value={partWeightPerBox ? partWeightPerBox.toFixed(2) : ''}
            disabled
          />
          <InputGroup.Text style={styles.rightInputGroupText}>lbs</InputGroup.Text>
        </InputGroup>
        <p
          style={{
            ...styles.noMarginText,
            marginLeft: 20,
            visibility: partWeightPerBox <= 30 ? 'hidden' : 'visible',
            color: COLORS.red,
          }}
        >
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            style={{ marginRight: 10 }}
          />
          Box weight over 30lbs
        </p>
      </div>
    </>
  );
};

const styles = {
  tabContainerRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 7,
  },
  lineTitle: {
    textAlign: 'center',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: COLORS.midGray,
    lineHeight: '0.1em',
    marginTop: 12,
    marginBottom: 12,
    color: COLORS.darkGray,
  },
  lineTitleText: {
    backgroundColor: COLORS.offWhite,
    paddingLeft: 7,
    paddingRight: 7,
  },
  noMarginText: {
    margin: 0,
  },
  tabContainerLabel: {
    flexBasis: '22%',
    textAlign: 'right',
    paddingRight: 10,
  },
  addMoldedPartButtonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 5,
    marginBottom: 8,
  },
  leftInputGroupText: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRightWidth: 0,
  },
  rightInputGroupText: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeftWidth: 0,
  },
  deleteButton: {
    marginLeft: 5,
    cursor: 'pointer',
  },
};

export default QuoteTabPackaging;