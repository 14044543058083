import * as CONFIG from '../config';
import { storeData, deleteData } from './storage';
import { lastActivityAtom } from '../state';
import { getRecoil } from 'recoil-nexus';
import cloneDeep from 'lodash.clonedeep';
import { DateTime as DateTimeBusiness } from 'luxon-business-days';
import { DateTime } from 'luxon';
import { v4 as uuid } from 'uuid';
import COLORS from '../styles/colors';

const metaTags = [
  'dateTimeSubmitted',
  'dateTimePreFeasibilityApproved',
  'preFeasibilityApprovedUserId',
  'preFeasibilityApprovedUsername',
  'dateTimePreFeasibilityRejected',
  'preFeasibilityRejectedUserId',
  'preFeasibilityRejectedUsername',
  'designEstimationSubmittedUserId',
  'designEstimationSubmittedUsername',
  'toolingEstimationSubmittedUserId',
  'toolingEstimationSubmittedUsername',
  'manufacturingEstimationSubmittedUserId',
  'manufacturingEstimationSubmittedUsername',
  'purchasingEstimationSubmittedUserId',
  'purchasingEstimationSubmittedUsername',
  'dateTimeDesignEstimationSubmitted',
  'dateTimeToolingEstimationSubmitted',
  'dateTimeManufacturingEstimationSubmitted',
  'dateTimePurchasingEstimationSubmitted',
  'dateTimeEstimationsComplete',
  'dateTimeFeasibilitySentBack',
  'feasibilitySentBackUserId',
  'feasibilitySentBackUsername',
  'dateTimeFeasibilityRejected',
  'feasibilityRejectedUserId',
  'feasibilityRejectedUsername',
  'dateTimeFeasibilityApproved',
  'feasibilityApprovedUserId',
  'feasibilityApprovedUsername',
  'dateTimeSalesApprovalRejected',
  'salesApprovalRejectedUserId',
  'salesApprovalRejectedUsername',
  'dateTimeSalesApprovalComplete',
  'salesApprovalUserId',
  'salesApprovalUsername',
];

const metaTagsDelete = [
  'isDeleted',
  'dateTimeSubmitted',
  'dateTimePreFeasibilityApproved',
  'preFeasibilityApprovedUserId',
  'preFeasibilityApprovedUsername',
  'dateTimePreFeasibilityRejected',
  'preFeasibilityRejectedUserId',
  'preFeasibilityRejectedUsername',
  'designEstimationSubmittedUserId',
  'designEstimationSubmittedUsername',
  'toolingEstimationSubmittedUserId',
  'toolingEstimationSubmittedUsername',
  'manufacturingEstimationSubmittedUserId',
  'manufacturingEstimationSubmittedUsername',
  'purchasingEstimationSubmittedUserId',
  'purchasingEstimationSubmittedUsername',
  'dateTimeDesignEstimationSubmitted',
  'dateTimeToolingEstimationSubmitted',
  'dateTimeManufacturingEstimationSubmitted',
  'dateTimePurchasingEstimationSubmitted',
  'dateTimeEstimationsComplete',
  'dateTimeFeasibilitySentBack',
  'feasibilitySentBackUserId',
  'feasibilitySentBackUsername',
  'dateTimeFeasibilityRejected',
  'feasibilityRejectedUserId',
  'feasibilityRejectedUsername',
  'dateTimeFeasibilityApproved',
  'feasibilityApprovedUserId',
  'feasibilityApprovedUsername',
  'dateTimeSalesApprovalRejected',
  'salesApprovalRejectedUserId',
  'salesApprovalRejectedUsername',
  'feasibility',
  'feasibilityAdequateCapacity',
  'feasibilityAdequatelyDefined',
  'feasibilityEfficientMaterialHandling',
  'feasibilityEngineeringRequirementsMet',
  'feasibilityManufacturedToTolerances',
  'feasibilityNoAlternateMethods',
  'feasibilityNoUnusualCapitalCosts',
  'feasibilityNoUnusualToolingCosts',
  'spcRequired',
  'spcUsedOnSimilar',
  'awardProbability',
  'dateTimeSalesApprovalComplete',
  'salesApprovalUserId',
  'salesApprovalUsername',
  'lateReason',
  'lateComment',
];

export const getNodeColor = nodeType => {
  if(nodeType === 'Molded') return COLORS.green;
  if(nodeType === 'Assembled') return COLORS.blue;
  if(nodeType === 'Carryover') return COLORS.yellow;
  if(nodeType === 'Purchased') return COLORS.purple;
  if(nodeType === 'Packaging') return COLORS.red;
  if(nodeType === 'Material') return COLORS.brown;
  return COLORS.midGray;
};

const getMessagePrefixFromActivityType = activityType => {
  if(activityType === 'PURCHASED_COMPONENT') return 'Purchased component';
  if(activityType === 'ASSEMBLY_COST') return 'Assembly cost';
  if(activityType === 'CARRY_OVER_COMPONENT') return 'Carryover component';
  if(activityType === 'AUX_EQUIPMENT') return 'Auxiliary cost';
  if(activityType === 'TESTING_COST') return 'Testing cost';
  if(activityType === 'MOLDED_PART') return 'Molded part';
  if(activityType === 'PACKAGING_COST') return 'Packaging cost';
  return '';
};

const formatChangeValue = (key, value) => {
  if(key.toLowerCase().includes('time') && typeof value === 'number' &&
    value > 1577854800000) return DateTime.fromMillis(value).toFormat('LL/dd/yyyy, h:mm a');
  if(key.toLowerCase().includes('date') && typeof value === 'number' &&
    value > 1577854800000) return DateTime.fromMillis(value).toFormat('LL/dd/yyyy');
  return value;
};

export const formatChanges = changes => {
  return Object.keys(changes).map(key => {
    if(key.includes('|')) {
      const splits = key.split('|');
      const activityType = splits.length > 0 ? splits[0] : null;
      const index = splits.length > 1 && !isNaN(parseInt(splits[1], 10)) ? parseInt(splits[1], 10) : null;
      const attribute = splits.length > 2 ? splits[2] : null;
      if(activityType && index != null && !attribute && changes[key] == null) return {
        activityType,
        message: `${getMessagePrefixFromActivityType(activityType)} ${index + 1} removed`,
      };
      else if(activityType && index != null && attribute && changes[key]) return {
        activityType,
        message: `${getMessagePrefixFromActivityType(activityType)} ${index + 1} attribute ${attribute} changed -> ${formatChangeValue(key, changes[key])}`,
      };
      else return null;
    }
    else {
      if(changes[key] == null) return {
        activityType: 'GENERAL_CHANGE',
        message: `Attribute ${key} removed`,
      };
      else return {
        activityType: 'GENERAL_CHANGE',
        message: `Attribute ${key} changed -> ${formatChangeValue(key, changes[key])}`,
      };
    }
  }).filter(o => o != null);
};

export const diff = (object, base) => {
  const changes = {};
  Object.keys(object).forEach(key => {
    if(base[key] === undefined) return;
    if(object[key] != null && typeof object[key] === 'object') {
      if(Array.isArray(object[key])) {
        let prefix;
        if(key === 'newPurchasedParts') prefix = 'PURCHASED_COMPONENT';
        else if(key === 'assemblyCosts') prefix = 'ASSEMBLY_COST';
        else if(key === 'carryoverPurchasedParts') prefix = 'CARRY_OVER_COMPONENT';
        else if(key === 'auxCosts') prefix = 'AUX_EQUIPMENT';
        else if(key === 'testingCosts') prefix = 'TESTING_COST';
        else if(key === 'moldedParts') prefix = 'MOLDED_PART';
        else if(key === 'packagingCosts') prefix = 'PACKAGING_COST';
        if(!prefix) return;
        object[key].forEach((obj, index) => {
          if(base?.[key]?.[index] == null) {
            Object.keys(obj).forEach(objKey => {
              if(obj[objKey] != null && typeof obj[objKey] !== 'object')
                changes[`${prefix}|${index}|${objKey}`] = obj[objKey];
            });
            return;
          }
          Object.keys(obj).forEach(objKey => {
            if(obj[objKey] !== base?.[key]?.[index]?.[objKey] && typeof obj[objKey] !== 'object')
              changes[`${prefix}|${index}|${objKey}`] = obj[objKey];
          });
          base?.[key]?.[index] && Object.keys(base?.[key]?.[index]).forEach(objKey => {
            if(base?.[key]?.[index]?.[objKey] != null && obj[objKey] == null)
              changes[`${prefix}|${index}|${objKey}`] = null;
          });
        });
        base?.[key] && base?.[key]?.forEach((obj, index) => {
          if(object?.[key]?.[index] == null) changes[`${prefix}|${index}`] = null;
        });
      }
      return;
    }
    if(object[key] !== base[key] && typeof object[key] !== 'object') changes[key] = object[key];
  });
  Object.keys(base).forEach(key => {
    if(base[key] != null && object[key] == null)
      changes[key] = null;
  });
  delete changes.dateTimeLastUpdated;
  delete changes.status;
  delete changes.quoteId;
  delete changes.revision;
  delete changes.assignments;
  metaTags.forEach(tagName => delete changes[tagName]);
  return changes;
};

export const round = (num, dp) => parseFloat(num.toFixed(dp));

export const loggingEffect = name => ({ onSet }) =>
  onSet((newValue, oldValue) =>
    CONFIG.ENVIRONMENT_TYPE === 'DEVELOPMENT' && console.log(name, '->', { newValue, oldValue }));

export const saveToStorageEffect = name => ({ onSet }) =>
  onSet(newValue => {
    if(newValue != null) storeData(name, newValue);
    else deleteData(name);
  });

export const timeToLogout = timeout => {
  const now = Date.now();
  const lastActivity = getRecoil(lastActivityAtom);
  return lastActivity + timeout < now;
};

export const getStatusBadgeType = status => {
  if(status === 'AT_CUSTOMER') return 'success';
  if(status === 'FEASIBILITY') return 'success';
  if(status === 'SALES_APPROVAL') return 'success';
  if(status === 'COMPLETED') return 'success';
  if(status === 'AWARDED') return 'success';
  if(status === 'PRE_FEASIBILITY') return 'primary';
  if(status === 'NOT_FEASIBLE') return 'danger';
  if(status === 'LOST') return 'danger';
  if(status === 'IN_PROGRESS') return 'warning';
  return 'secondary';
};

export const mergeUserAssignments = userAssignments => {
  let userAssignmentsCopy = cloneDeep(userAssignments);
  const mergedUserAssignments = [];
  while(userAssignmentsCopy.length) {
    const thisUser = userAssignmentsCopy[0].userId;
    const allAssignments = userAssignmentsCopy.filter(o => o.userId === thisUser).map(o => o.types).flat();
    mergedUserAssignments.push({
      ...userAssignmentsCopy[0],
      types: allAssignments,
    });
    userAssignmentsCopy = userAssignmentsCopy.filter(o => o.userId !== thisUser);
  }
  return mergedUserAssignments;
};

export const isJsonString = str => {
  try {
    if(str == null) return false;
    JSON.parse(str);
    return true;
  }
  catch(e) {
    return false;
  }
};

export const cmp = (a, b) => (a > b) - (a < b);

export const returnQuoteToDraft = quoteData => {
  const quoteDataCopy = cloneDeep(quoteData);
  metaTagsDelete.forEach(tagName => quoteDataCopy[tagName] = null);
  return quoteDataCopy;
};

export const getLateCutoffs = () => {
  let dt = DateTimeBusiness.local();
  dt = dt.plusBusiness({ days: 3 });
  return {
    almostLateCutOff: dt.toMillis(),
    lateCutOff: Date.now(),
  };
};

export const convertOrgCode = orgCode => {
  if(orgCode === 'OUT_MX') return 'MEX';
  if(orgCode === 'OUT_US') return 'CNL';
  return orgCode;
};

export const getRateOrgCodeFromLocator = locator => {
  if(locator === 'NAM-CNL-U1') return 'CNL';
  if(locator === 'NAM-CNL-U18') return 'CRT';
  if(locator === 'NAM-CWH-U20') return 'CNL';
  if(locator === 'NAM-LVG-U05') return 'LVG';
  if(locator === 'NAM-MEX-U21') return 'MEX';
  if(locator === 'NAM-MEX-U17') return 'MEX';
  if(locator === 'NCM-NCM-U70') return 'NCM';
  if(locator === 'NAM-SLB-U40') return 'SLB';
};

export const getNameFromLovCode = (lov, code) => {
  const item = lov.find(o => o.code === code);
  if(!item || !item.name) return code;
  return item.name;
};

export const readFileData = file => new Promise((res, rej) => {
  const reader = new FileReader();
  reader.onload = () => res(reader.result);
  reader.onerror = e => rej(e);
  reader.readAsDataURL(file);
});

export const cloneQuote = (quote, userId, loggedInUser) => ({
  ...returnQuoteToDraft(quote),
  status: 'DRAFT',
  quoteId: uuid(),
  revision: 0,
  quoteNumber: null,
  createdBy: userId,
  isNotActive: null,
  createdByUsername: loggedInUser,
  assignments: [{
    userId: userId,
    username: loggedInUser,
    types: ['OWNER'],
  }],
  dateTimeLastUpdated: null,
  lastUpdatedByUserId: null,
  lastUpdatedByUsername: null,
});

export const getNodeTypeFromProductType = type => {
  if(['MOLDED_PART_ONLY', 'INSERT_MOLD_PURCHASE_PART', 'INSERT_MOLD_MOLDED_PART'].includes(type)) return 'Molded';
  if(['PURCHASE_PART_ONLY'].includes(type)) return 'Purchased';
  if(['ASSEMBLY_PURCHASE_MOLDED_PARTS_MIXED', 'ASSEMBLY_ALL_MOLDED_PARTS', 'ASSEMBLY_ALL_PURCHASE_PARTS'].includes(type)) return 'Assembled';
};

export const getAvailableNodeTypes = (productType, parentType) => {
  if(productType === 'MOLDED_PART_ONLY') {
    if(parentType === 'Molded') return ['Packaging', 'Material'];
    return [];
  }
  else if(productType === 'INSERT_MOLD_PURCHASE_PART') {
    if(parentType === 'Molded') return ['Purchased', 'Packaging', 'Material'];
    return [];
  }
  else if(productType === 'INSERT_MOLD_MOLDED_PART') {
    if(parentType === 'Molded') return ['Molded', 'Packaging', 'Material'];
    return [];
  }
  else if(productType === 'PURCHASE_PART_ONLY') {
    if(parentType === 'PurchasedBOM') return ['Purchased'];
    return [];
  }
  else if(productType === 'ASSEMBLY_PURCHASE_MOLDED_PARTS_MIXED') {
    if(parentType === 'Assembled') return ['Assembled', 'Molded', 'Purchased', 'Packaging', 'Carryover'];
    if(parentType === 'Molded') return ['Molded', 'Purchased', 'Packaging', 'Material'];
    return [];
  }
  else if(productType === 'ASSEMBLY_ALL_MOLDED_PARTS') {
    if(parentType === 'Assembled') return ['Assembled', 'Molded', 'Packaging', 'Carryover'];
    if(parentType === 'Molded') return ['Molded', 'Packaging', 'Material'];
    return [];
  }
  else if(productType === 'ASSEMBLY_ALL_PURCHASE_PARTS') {
    if(parentType === 'Assembled') return ['Assembled', 'Purchased', 'Packaging'];
    return [];
  }
  else return [];
};

export const getProgramsGrouped = programs => [...programs.reduce((map, cur) => {
  let programArr = map.get(cur.programId);
  if(!programArr) programArr = [];
  programArr.push(cur);
  map.set(cur.programId, programArr);
  return map;
}, new Map()).values()]
  .map(programArr => ({
    name: `${programArr[0].brand} ${programArr[0].model} | ${programArr[0].startOfProgram} - ${programArr[0].endOfProgram} | ${programArr[0].platform} | ${programArr[0].programCode}`,
    programId: programArr[0].programId,
    platformId: programArr[0].platformId,
    plantPrograms: programArr,
  }));

export const getPlatformsGrouped = programs => [...programs.reduce((map, cur) => {
  let platformArr = map.get(cur.platform);
  if(!platformArr) platformArr = [];
  platformArr.push(cur);
  map.set(cur.platform, platformArr);
  return map;
}, new Map()).values()]
  .map(platformArr => ({
    platform: platformArr[0].platform,
    platformId: platformArr[0].platformId,
  }));

export const getTotalQty = (initQty, initParent, bomData) => {
  let qty = initQty;
  let nextParent = initParent;
  while(nextParent) {
    if(nextParent?.quantity) qty = qty * nextParent.quantity;
    const parentId = nextParent.id;
    const nextParentId = bomData.links.find(o => o.target === parentId)?.source;
    nextParent = nextParentId ? bomData.nodes.find(o => o.id === nextParentId) : null;
  }
  return qty;
};

export const graphHasFloaters = graph => {
  for(let node of graph.nodes) {
    let nextNode = node;
    let originFound = false;
    while(nextNode) {
      if(nextNode.isOrigin) {
        originFound = true;
        break;
      }
      const parentId = nextNode.id;
      const nextNodeId = graph.links.find(o => o.target === parentId)?.source;
      nextNode = nextNodeId ? graph.nodes.find(o => o.id === nextNodeId) : null;
    }
    if(!originFound) return true;
  }
  return false;
};

export const getPartType = node => {
  if(node.type === 'Assembled') return 'PART';
  if(node.type === 'Molded') return 'PART';
  if(node.type === 'Material') return 'MATERIAL';
  if(node.type === 'Purchased') return 'PART';
  if(node.type === 'Packaging' && node.packagingType === 'BAG') return 'PACKAGING_BAG';
  if(node.type === 'Packaging' && node.packagingType === 'BOX') return 'PACKAGING_BOX';
  if(node.type === 'Packaging' && node.packagingType === 'RETURNABLE') return 'PACKAGING_RETURNABLE';
};

export const getPartSubType = node => {
  if(node.type === 'Assembled') return 'ASSEMBLED';
  if(node.type === 'Molded') return 'MOLDED';
  if(node.type === 'Material') return node.materialType;
  if(node.type === 'Purchased') return 'PURCHASED';
  if(node.type === 'Packaging') return node.packagingType;
};

export const NPV = (discountRate, cashFlow) => {
  let npv = 0, adjustedCashFlow = [];
  for(let t = 0; t < cashFlow.length; t++) {
    const acf = cashFlow[t] / Math.pow((1 + discountRate), t);
    adjustedCashFlow.push(acf);
    npv += acf;
  }
  return { npv, adjustedCashFlow };
};

export const IRR = (cashFlow, guess) => {
  guess = guess ? guess : 0.058;
  let npv;
  do {
    const result = NPV(guess, cashFlow);
    npv = result.npv;
    guess += 0.001;
  }
  while(npv > 0 && guess <= 5);
  return npv <= 0 ? guess : null;
};

export const ROI = cashFlow => {
  const initialInvestment = cashFlow[0] * -1;
  const avgPat = cashFlow.slice(1).reduce((total, cur) => total + cur, 0) / (cashFlow.length - 1);
  return avgPat / initialInvestment;
};

export const PBP = cashFlow => {
  const initialInvestment = cashFlow[0] * -1;
  const avgPat = cashFlow.slice(1).reduce((total, cur) => total + cur, 0) / (cashFlow.length - 1);
  return initialInvestment / avgPat;
};