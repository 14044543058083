import * as CONSTANTS from '../constants';
import { convertOrgCode, IRR, NPV, ROI, PBP } from './misc';

export const gramsToPounds = g => g * 0.002204623;

export const inchToMm = inches => inches * 25.4;

export const mmToInch = mm => mm / 25.4;

const getRates = config => {
  let {
    rates,
    location,
    pressType,
    pressSize,
    gateCuttingType,
    annealingType,
    shotsPerHour,
    cavitiesPerMold,
    inspectionType,
    packingType,
    toolType,
  } = config;
  location = convertOrgCode(location);
  const pph = shotsPerHour * cavitiesPerMold;
  
  // PRESS RATE
  const pressRate = location !== 'NULL' && pressType !== 'NULL' && pressSize !== 'NULL' ?
    rates.find(o => o.rateType === 'PRESS' && o.orgCode === location &&
      o.pressType === pressType && o.pressSize === pressSize) : null;
  const press = pressRate ? pressRate.rate : null;
  
  // GATE CUTTING
  const gateCuttingRate = location !== 'NULL' && gateCuttingType !== 'NULL' ?
    rates.find(o => o.rateType === 'GATE_CUTTING' && o.orgCode === location &&
      o.gateCuttingType === gateCuttingType) : null;
  const gateCutting = gateCuttingRate ? gateCuttingRate.rate : null;
  
  // ANNEALING
  const annealingRate = location !== 'NULL' && annealingType !== 'NULL' ?
    rates.find(o => o.rateType === 'ANNEALING' && o.orgCode === location &&
      o.annealingType === annealingType) : null;
  const annealing = annealingRate ? annealingRate.rate : null;
  
  // INSPECTION
  const inspectionRate = location !== 'NULL' && inspectionType !== 'NULL' ?
    rates.find(o => o.rateType === 'INSPECTION' && o.orgCode === location &&
      o.inspectionType === inspectionType) : null;
  const inspection = inspectionType === 'HIGH_CAVITATION' && pph > 0 ?
    CONSTANTS.INSPECTION_HIGH_CAVITATION_RATE / pph :
    inspectionRate ?
      inspectionRate.rate :
      null;
  
  // PACKING
  const packingRate = location !== 'NULL' && packingType !== 'NULL' ?
    rates.find(o => o.rateType === 'PACKING' && o.orgCode === location &&
      o.packingType === packingType) : null;
  const packing = inspectionType === 'HIGH_CAVITATION' && pph > 0 ?
    CONSTANTS.PACKING_HIGH_CAVITATION_RATE / pph :
    packingRate ?
      packingRate.rate :
      null;
  
  // GLASS
  const glassRate = location !== 'NULL' && pressSize !== 'NULL' ?
    rates.find(o => o.rateType === 'GLASS_COST' && o.orgCode === location && o.pressSize === pressSize) : null;
  const glass = glassRate ? glassRate.rate : null;
  
  // RECYCLED MATERIAL FACTOR
  const recycledMaterialFactorRate = location !== 'NULL' ?
    rates.find(o => o.rateType === 'AUX' && o.auxType === 'RECYCLED_MATERIAL_FACTOR' && o.orgCode === location) : null;
  const recycledMaterialFactor = recycledMaterialFactorRate ? recycledMaterialFactorRate.rate : null;
  
  // TOOL REPAIR
  const toolRepairRate = location !== 'NULL' && toolType !== 'NULL' ?
    rates.find(o => o.rateType === 'TOOL_REPAIR' && o.orgCode === location &&
      o.toolType === toolType) : null;
  const toolRepair = toolRepairRate ? toolRepairRate.rate : null;
  
  // MOLDING AUX
  const moldingAuxRate = location !== 'NULL' ?
    rates.find(o => o.rateType === 'AUX' && o.orgCode === location && o.auxType === 'MOLDING_AUX') : null;
  const moldingAux = moldingAuxRate ? moldingAuxRate.rate : null;
  
  // MOLDING YIELD
  const moldingYieldRate = location !== 'NULL' ?
    rates.find(o => o.rateType === 'AUX' && o.orgCode === location && o.auxType === 'MOLDING_YIELD') : null;
  const moldingYield = moldingYieldRate ? moldingYieldRate.rate : null;
  
  // ASSEMBLY YIELD
  const assemblyYieldRate = location !== 'NULL' ?
    rates.find(o => o.rateType === 'AUX' && o.orgCode === location && o.auxType === 'ASSEMBLY_YIELD') : null;
  const assemblyYield = assemblyYieldRate ? assemblyYieldRate.rate : null;
  
  // MOLDING ADMIN
  const moldingAdminRate = location !== 'NULL' ?
    rates.find(o => o.rateType === 'AUX' && o.orgCode === location && o.auxType === 'ADMINISTRATIVE_COEF') : null;
  const moldingAdmin = moldingAdminRate ? moldingAdminRate.rate : null;
  
  // ASSEMBLY ADMIN
  const assemblyAdminRate = location !== 'NULL' ?
    rates.find(o => o.rateType === 'AUX' && o.orgCode === location && o.auxType === 'ASSEMBLY_ADMIN') : null;
  const assemblyAdmin = assemblyAdminRate ? assemblyAdminRate.rate : null;
  
  // MOLDING TECHNICAL
  const moldingTechnicalRate = location !== 'NULL' ?
    rates.find(o => o.rateType === 'AUX' && o.orgCode === location && o.auxType === 'MOLDING_TECHNICAL') : null;
  const moldingTechnical = moldingTechnicalRate ? moldingTechnicalRate.rate : null;
  
  // ASSEMBLY TECHNICAL
  const assemblyTechnicalRate = location !== 'NULL' ?
    rates.find(o => o.rateType === 'AUX' && o.orgCode === location && o.auxType === 'ASSEMBLY_TECHNICAL') : null;
  const assemblyTechnical = assemblyTechnicalRate ? assemblyTechnicalRate.rate : null;
  
  return {
    press,
    gateCutting,
    annealing,
    inspection,
    packing,
    toolRepair,
    moldingAux,
    moldingYield,
    assemblyYield,
    assemblyAdmin,
    moldingAdmin,
    moldingTechnical,
    assemblyTechnical,
    glass,
    recycledMaterialFactor,
  };
};

export const getMoldingCalculations = config => {
  let {
    rates,
    location,
    pressType,
    pressSize,
    gateCuttingType,
    annealingType,
    shotsPerHour,
    cavitiesPerMold,
    inspectionType,
    packingType,
    glassIsUsed,
    partWeight,
    materials,
    monthlyVolume,
    regrindIsUsed,
    cavitiesPerPart,
    numOfDiffParts,
    runnerPercentage,
    toolType,
    toolCost,
    tuningTextureCost,
    boatShipmentCost,
    airShipmentCost,
    partIsOutsourced,
    outsourcedCost,
    packagingCosts,
    productType,
    purchasedPartCosts,
  } = config;
  
  const calculatedRates = getRates({
    rates,
    location,
    pressType,
    pressSize,
    gateCuttingType,
    annealingType,
    shotsPerHour,
    cavitiesPerMold,
    inspectionType,
    packingType,
    toolType,
  });
  
  const partWeightLbs = gramsToPounds(partWeight);
  const totalPartWeightLbs = partWeightLbs * cavitiesPerMold;
  
  const packagingCalculations = getPackagingCalculations({ packagingCosts });
  
  const totalPostProcessingCost = (calculatedRates?.gateCutting ? calculatedRates.gateCutting : 0) +
    (calculatedRates?.annealing ? calculatedRates.annealing : 0) +
    (calculatedRates?.packing ? calculatedRates.packing : 0) +
    (calculatedRates?.inspection ? calculatedRates.inspection : 0);
  const moldingCost = calculatedRates?.press && cavitiesPerMold && shotsPerHour ?
    calculatedRates.press / (cavitiesPerMold * shotsPerHour) : null;
  const glassCost = !glassIsUsed ?
    0 :
    shotsPerHour && cavitiesPerPart && calculatedRates?.glass ?
      calculatedRates.glass / (shotsPerHour * cavitiesPerPart) / 408 / 12 / CONSTANTS.MACHINE_EFFICIENCY :
      null;
  const insertPartCosts = productType === 'INSERT_MOLD_PURCHASE_PART' && purchasedPartCosts ?
    purchasedPartCosts.reduce((total, cur) =>
      cur.quantity && cur.partCost ? (cur.quantity * cur.partCost) + total : total, 0) :
    0;
  const totalInjectionMoldingCost = (moldingCost != null ? moldingCost : 0) + (glassCost != null ? glassCost : 0) + insertPartCosts;
  const monthlyRunHours = monthlyVolume && cavitiesPerMold && shotsPerHour ?
    (monthlyVolume * numOfDiffParts) / (cavitiesPerMold * shotsPerHour) / CONSTANTS.MACHINE_EFFICIENCY :
    null;
  
  const monthlyMaterialUsageLbs = regrindIsUsed ?
    (monthlyVolume / cavitiesPerPart) * totalPartWeightLbs :
    (monthlyVolume / cavitiesPerPart) *
    (totalPartWeightLbs + (((runnerPercentage / 100) * totalPartWeightLbs) / ((100 - runnerPercentage) / 100)));
  
  const materialPartUsageLbs = (monthlyVolume / cavitiesPerPart) * totalPartWeightLbs;
  
  const totalRawMaterialPerPound = materials?.length ?
    materials.reduce((total, mat) => total + (mat.materialCost * (mat.blendPercentage / 100)), 0) /
    (materials?.length > 1 ? 1 - CONSTANTS.RAW_MATERIAL_BLEND_OVERHEAD_FACTOR : 1) :
    0;
  
  const runnerWeightLbs = ((runnerPercentage / 100) * totalPartWeightLbs) / ((100 - runnerPercentage) / 100);
  const runnerScrapMaterialLbs = (monthlyVolume / cavitiesPerPart) * runnerWeightLbs;
  const runnerScrapCost = totalRawMaterialPerPound * runnerScrapMaterialLbs;
  
  let rawMaterialCost;
  if(regrindIsUsed) rawMaterialCost = totalRawMaterialPerPound * (partWeightLbs / (1 - CONSTANTS.RAW_MATERIAL_OVERHEAD_FACTOR));
  else {
    const runnerWeight = ((runnerPercentage / 100) * totalPartWeightLbs) / ((100 - runnerPercentage) / 100);
    const rawShotWeightLbs = (totalPartWeightLbs + runnerWeight) / cavitiesPerMold;
    rawMaterialCost = totalRawMaterialPerPound * (rawShotWeightLbs / (1 - CONSTANTS.RAW_MATERIAL_OVERHEAD_FACTOR));
  }
  
  let recycledMaterialCost;
  if(!regrindIsUsed) recycledMaterialCost = 0;
  else recycledMaterialCost = partWeightLbs * totalRawMaterialPerPound * 0.06;
  
  const grossRawMaterialCost = (rawMaterialCost ? rawMaterialCost : 0) + (recycledMaterialCost ? recycledMaterialCost : 0);
  const totalMaterialCost = grossRawMaterialCost;
  const totalProductionCost = totalMaterialCost + totalInjectionMoldingCost + totalPostProcessingCost + packagingCalculations.totalPackagingCost;
  const auxDeptCoefficient = totalProductionCost * (calculatedRates?.moldingAux ? calculatedRates.moldingAux : 0);
  const technicalCost = totalProductionCost * (calculatedRates?.moldingTechnical ? calculatedRates.moldingTechnical : 0);
  const yieldCost = totalProductionCost * (calculatedRates?.moldingYield ? calculatedRates.moldingYield : 0);
  const totalStandardCost = totalProductionCost + auxDeptCoefficient + technicalCost + yieldCost +
    (calculatedRates?.toolRepair ? calculatedRates.toolRepair : 0);
  
  // calculate tool costs
  let totalToolCostBoat = 0, totalToolCostAir = 0;
  if(toolCost != null) {
    totalToolCostBoat += toolCost;
    totalToolCostAir += toolCost;
  }
  if(tuningTextureCost != null) {
    totalToolCostBoat += tuningTextureCost;
    totalToolCostAir += tuningTextureCost;
  }
  if(boatShipmentCost != null) totalToolCostBoat += boatShipmentCost;
  if(airShipmentCost != null) totalToolCostAir += airShipmentCost;
  
  // calculate part weights
  const totalPartWeight = partWeight && cavitiesPerMold ? partWeight * cavitiesPerMold : null;
  const runnerWeight = totalPartWeight != null && runnerPercentage != null ?
    ((runnerPercentage / 100) * totalPartWeight) / ((100 - runnerPercentage) / 100) :
    null;
  const totalShotWeight = totalPartWeight != null && runnerWeight != null ? totalPartWeight + runnerWeight : null;
  const rawShotWeight = totalShotWeight && cavitiesPerMold != null && cavitiesPerMold > 0 ?
    totalShotWeight / cavitiesPerMold :
    null;
  
  return {
    rates: calculatedRates,
    totalPostProcessingCost,
    totalInjectionMoldingCost,
    monthlyRunHours,
    monthlyMaterialUsageLbs,
    materialPartUsageLbs,
    runnerScrapMaterialLbs,
    glassCost,
    moldingCost,
    runnerScrapCost,
    rawMaterialCost,
    recycledMaterialCost,
    grossRawMaterialCost,
    totalMaterialCost,
    totalProductionCost,
    auxDeptCoefficient,
    yieldCost,
    totalStandardCost,
    totalToolCostBoat,
    totalToolCostAir,
    totalShotWeight,
    rawShotWeight,
    totalPartWeight,
    runnerWeight,
    technicalCost,
    totalRawMaterialPerPound,
    outsourcedCost,
    partIsOutsourced,
    totalPackagingCost: packagingCalculations.totalPackagingCost,
    insertPartCosts,
  };
};

export const getPurchasingCalculations = config => {
  let {
    purchasedPartCosts,
    carryoverPartCosts,
  } = config;
  
  const totalPurchasedComponentsCost = purchasedPartCosts.reduce((total, cur) =>
    cur.quantity && cur.partCost ? (cur.quantity * cur.partCost) + total : total, 0);
  
  const totalCarryoverPartsCost = carryoverPartCosts.reduce((total, cur) =>
    cur.quantity && cur.partCost ? (cur.quantity * cur.partCost) + total : total, 0);
  
  return {
    totalPurchasedComponentsCost,
    totalCarryoverPartsCost,
  };
};

export const getAssemblyCalculations = config => {
  let {
    assemblyCosts,
    rates,
    location,
    inspectionType,
    packagingType,
  } = config;
  
  const totalAssemblyCost = assemblyCosts.reduce((total, line) => {
    const pph = line.pphPerPerson && !isNaN(parseFloat(line.pphPerPerson)) ?
      parseFloat(line.pphPerPerson) : null;
    if(!pph) return total;
    const rateObj = rates.find(o => o.rateType === 'ASSEMBLY' && o.orgCode === convertOrgCode(line.location) &&
      o.assemblyType === line.description);
    const rate = rateObj ? rateObj.rate : null;
    if(typeof rate !== 'number') return total;
    return total + (rate / pph);
  }, 0);
  
  const inspectionRateObj = location ?
    rates.find(o => o.rateType === 'INSPECTION' && o.orgCode === convertOrgCode(location) &&
      o.inspectionType === inspectionType) : null;
  const packingRateObj = location ?
    rates.find(o => o.rateType === 'PACKING' && o.orgCode === convertOrgCode(location) &&
      o.packingType === packagingType) : null;
  
  const inspectionRate = inspectionRateObj ? inspectionRateObj.rate : 0;
  const packingRate = packingRateObj ? packingRateObj.rate : 0;
  const totalPostProcessingCost = inspectionRate + packingRate;
  
  return {
    totalAssemblyCost,
    totalPostProcessingCost,
  };
};

export const getPackagingCalculations = config => {
  let {
    packagingCosts,
  } = config;
  
  const totalPackagingCost = packagingCosts.reduce((total, cur) =>
    cur.quantity && cur.quantity.length && !isNaN(parseInt(cur.quantity, 10)) &&
    cur.unitCost && parseInt(cur.quantity, 10) ?
      (cur.unitCost / parseInt(cur.quantity, 10)) + total : total, 0);
  
  return {
    totalPackagingCost,
  };
};

export const getAuxCalculations = config => {
  const { auxCosts } = config;
  
  let totalAuxEquipmentCost = 0;
  let totalAuxEquipmentPrice = 0;
  let totalExpenseCost = 0;
  let totalDepreciatedCost = 0;
  let totalDepreciatedPrice = 0;
  let totalCustomerPaysCost = 0;
  let totalCustomerPaysPrice = 0;
  
  auxCosts.forEach(o => {
    const subTotal = o.quantity && o.quantity.length && !isNaN(parseInt(o.quantity, 10)) &&
    o.unitCost && o.unitCost.length && !isNaN(parseFloat(o.unitCost)) ?
      (parseInt(o.quantity, 10) * parseFloat(o.unitCost)) : 0;
    const subTotalPrice = o.quantity && o.quantity.length && !isNaN(parseInt(o.quantity, 10)) &&
    o.unitPrice && o.unitPrice.length && !isNaN(parseFloat(o.unitPrice)) ?
      (parseInt(o.quantity, 10) * parseFloat(o.unitPrice)) : 0;
    totalAuxEquipmentCost += subTotal;
    totalAuxEquipmentPrice += subTotalPrice;
    if(o.paymentType === 'NIFCO') totalExpenseCost += subTotal;
    if(o.paymentType === 'DEPRECIATE') totalDepreciatedCost += subTotalPrice ? subTotalPrice : subTotal;
    if(o.paymentType === 'DEPRECIATE_PRICE') totalDepreciatedPrice += subTotalPrice ? subTotalPrice : subTotal;
    if(o.paymentType === 'CUSTOMER') {
      totalCustomerPaysCost += subTotal ? subTotal : 0;
      totalCustomerPaysPrice += subTotalPrice ? subTotalPrice : subTotal;
    }
    if(o.paymentType === 'CAP_INVESTMENT') totalExpenseCost += subTotal;
  });
  
  return {
    totalAuxEquipmentCost,
    totalExpenseCost,
    totalDepreciatedCost,
    totalDepreciatedPrice,
    totalCustomerPaysCost,
    totalCustomerPaysPrice,
    totalAuxEquipmentPrice,
  };
};

export const getInvestmentCalculations = config => {
  const {
    capitalCosts,
    auxCosts,
    testingCosts,
    monthlyVolume,
    startOfProdYear,
    startOfProdMonth,
    programLife,
    annualPriceReductionPct,
    fgPrice,
    fgCost,
    corporateTaxRate,
    royaltyPercentage,
    sga,
    costVariance,
  } = config;
  
  const capitalCalculations = getCapitalCalculations({ capitalCosts });
  const auxCalculations = getAuxCalculations({ auxCosts });
  const testingCalculations = getTestingCalculations({ testingCosts });
  
  const investmentAmount = (capitalCalculations?.totalDepreciatedPrice ? capitalCalculations.totalDepreciatedPrice : 0) +
    (capitalCalculations?.totalDepreciatedCost ? capitalCalculations.totalDepreciatedCost : 0) +
    (auxCalculations?.totalDepreciatedPrice ? auxCalculations.totalDepreciatedPrice : 0) +
    (auxCalculations?.totalDepreciatedCost ? auxCalculations.totalDepreciatedCost : 0) +
    (testingCalculations?.totalDepreciatedPrice ? testingCalculations.totalDepreciatedPrice : 0) +
    (testingCalculations?.totalDepreciatedCost ? testingCalculations.totalDepreciatedCost : 0);
  
  const initialCustomerCost = (capitalCalculations?.totalCustomerPaysCost ? capitalCalculations.totalCustomerPaysCost : 0) +
    (auxCalculations?.totalCustomerPaysCost ? auxCalculations.totalCustomerPaysCost : 0) +
    (testingCalculations?.totalCustomerPaysCost ? testingCalculations.totalCustomerPaysCost : 0);
  
  const majorExpenses = (capitalCalculations?.totalExpenseCost ? capitalCalculations.totalExpenseCost : 0) +
    (auxCalculations?.totalExpenseCost ? auxCalculations.totalExpenseCost : 0) +
    (testingCalculations?.totalExpenseCost ? testingCalculations.totalExpenseCost : 0);
  
  const initialCustomerPayment = (capitalCalculations?.totalCustomerPaysPrice ? capitalCalculations.totalCustomerPaysPrice : 0) +
    (auxCalculations?.totalCustomerPaysPrice ? auxCalculations.totalCustomerPaysPrice : 0) +
    (testingCalculations?.totalCustomerPaysPrice ? testingCalculations.totalCustomerPaysPrice : 0);
  
  const allDepPriceLines = [
    ...capitalCosts.filter(o => o.enabled && o.paymentType === 'DEPRECIATE_PRICE'),
    ...auxCosts.filter(o => o.paymentType === 'DEPRECIATE_PRICE'),
    ...testingCosts.filter(o => o.paymentType === 'DEPRECIATE_PRICE'),
  ];
  const allDepCostLines = [
    ...capitalCosts.filter(o => o.enabled && o.paymentType === 'DEPRECIATE'),
    ...auxCosts.filter(o => o.paymentType === 'DEPRECIATE'),
    ...testingCosts.filter(o => o.paymentType === 'DEPRECIATE'),
  ];
  const allDepLines = [...allDepPriceLines, ...allDepCostLines];
  
  const piecePriceAmortization = monthlyVolume ? allDepPriceLines.filter(o => (!isNaN(parseFloat(o.unitPrice)) || !isNaN(parseFloat(o.unitCost))) && !isNaN(parseInt(o.depreciationMonths, 10)))
    .reduce((total, cur) => total + ((!isNaN(parseFloat(cur.unitPrice)) ? parseFloat(cur.unitPrice) : parseFloat(cur.unitCost)) / (parseInt(cur.depreciationMonths, 10) * monthlyVolume)), 0) : 0;
  const pieceCostAmortization = monthlyVolume ? allDepCostLines.filter(o => (!isNaN(parseFloat(o.unitPrice)) || !isNaN(parseFloat(o.unitCost))) && !isNaN(parseInt(o.depreciationMonths, 10)))
    .reduce((total, cur) => total + ((!isNaN(parseFloat(cur.unitPrice)) ? parseFloat(cur.unitPrice) : parseFloat(cur.unitCost)) / (parseInt(cur.depreciationMonths, 10) * monthlyVolume)), 0) : 0;
  
  const pieceDataPerMonth = [];
  if(startOfProdYear && startOfProdMonth && programLife && fgPrice && fgCost && monthlyVolume) {
    let currentYear = startOfProdYear;
    let currentMonth = startOfProdMonth;
    for(let i = 0; i < programLife; i++) {
      const applicablePriceLines = allDepPriceLines.filter(o => (!isNaN(parseFloat(o.unitPrice)) || !isNaN(parseFloat(o.unitCost))) && !isNaN(parseInt(o.depreciationMonths, 10)) && parseInt(o.depreciationMonths, 10) > i);
      const applicableCostLines = allDepCostLines.filter(o => (!isNaN(parseFloat(o.unitPrice)) || !isNaN(parseFloat(o.unitCost))) && !isNaN(parseInt(o.depreciationMonths, 10)) && parseInt(o.depreciationMonths, 10) > i);
      const monthlyPiecePriceAmortization = applicablePriceLines
        .reduce((total, cur) => total + ((!isNaN(parseFloat(cur.unitPrice)) ? parseFloat(cur.unitPrice) : parseFloat(cur.unitCost)) / (parseInt(cur.depreciationMonths, 10) * monthlyVolume)), 0);
      const monthlyPieceCostAmortization = applicableCostLines
        .reduce((total, cur) => total + ((!isNaN(parseFloat(cur.unitPrice)) ? parseFloat(cur.unitPrice) : parseFloat(cur.unitCost)) / (parseInt(cur.depreciationMonths, 10) * monthlyVolume)), 0);
      const compoundSalesReduction = (1 - annualPriceReductionPct) ** Math.floor(i / 12);
      const price = (fgPrice + monthlyPiecePriceAmortization) * compoundSalesReduction;
      const sales = price * monthlyVolume;
      const royaltyCost = price && royaltyPercentage != null ?
        price * (royaltyPercentage / 100) : 0;
      const costVarianceCost = sales * costVariance;
      const cost = fgCost + monthlyPieceCostAmortization + royaltyCost;
      const grossProfit = (monthlyVolume * price) + (i === 0 ? initialCustomerPayment - initialCustomerCost : 0) - (monthlyVolume * cost) - costVarianceCost - (i === 0 ? majorExpenses : 0);
      const sgaCost = sales * sga;
      const operatingProfit = grossProfit - sgaCost;
      const profitAfterTax = operatingProfit * (1 - corporateTaxRate);
      const costAmortization = monthlyPieceCostAmortization * monthlyVolume;
      const cashFlow = profitAfterTax + costAmortization;
      pieceDataPerMonth.push({
        initialExpense: i === 0 ? majorExpenses : 0,
        initialCustomerProfit: i === 0 ? initialCustomerPayment - initialCustomerCost : 0,
        year: currentYear,
        month: currentMonth,
        piecePriceAmortization: monthlyPiecePriceAmortization,
        pieceCostAmortization: monthlyPieceCostAmortization,
        price,
        cost,
        grossMargin: price > 0 ? ((price - cost) / price) * 100 : 0,
        compoundSalesReduction,
        salesAmount: monthlyVolume * price,
        productionCost: (monthlyVolume * cost) + costVarianceCost,
        grossProfit,
        operatingProfit,
        profitAfterTax,
        cashFlow,
        sgaCost,
        costAmortization,
      });
      currentYear = currentMonth >= 12 ? currentYear + 1 : currentYear;
      currentMonth = currentMonth >= 12 ? 1 : currentMonth + 1;
    }
  }
  
  const pieceDataPerYear = [...pieceDataPerMonth.reduce((map, cur) => {
    const yearArr = map.get(cur.year) || [];
    yearArr.push(cur);
    map.set(cur.year, yearArr);
    return map;
  }, new Map()).values()]
    .map(yearArr => ({
      initialExpense: yearArr.reduce((total, cur) => total + cur.initialExpense, 0),
      initialCustomerProfit: yearArr.reduce((total, cur) => total + cur.initialCustomerProfit, 0),
      year: yearArr[0].year,
      avgPrice: yearArr.reduce((total, cur) => total + cur.price, 0) / yearArr.length,
      avgCost: yearArr.reduce((total, cur) => total + cur.cost, 0) / yearArr.length,
      salesAmount: yearArr.reduce((total, cur) => total + cur.salesAmount, 0),
      sgaCost: yearArr.reduce((total, cur) => total + cur.sgaCost, 0),
      productionCost: yearArr.reduce((total, cur) => total + cur.productionCost, 0),
      grossProfit: yearArr.reduce((total, cur) => total + cur.grossProfit, 0),
      operatingProfit: yearArr.reduce((total, cur) => total + cur.operatingProfit, 0),
      profitAfterTax: yearArr.reduce((total, cur) => total + cur.profitAfterTax, 0),
      cashFlow: yearArr.reduce((total, cur) => total + cur.cashFlow, 0),
      costAmortization: yearArr.reduce((total, cur) => total + cur.costAmortization, 0),
    }));
  
  const cashFlow = [investmentAmount * -1, ...pieceDataPerYear.map(o => o.cashFlow)];
  const internalRateReturn = IRR(cashFlow);
  const npvCalc = NPV(0.058, cashFlow);
  const netPresentValue = npvCalc.npv;
  const adjustedCashFlow = npvCalc.adjustedCashFlow;
  const returnOnInvestment = ROI(cashFlow);
  const payBackPeriod = PBP(cashFlow);
  
  const adjustedCashFlowTotal = adjustedCashFlow.slice(1).reduce((total, cur) => total + cur, 0);
  const profitabilityIndex = investmentAmount + majorExpenses > 0 ? adjustedCashFlowTotal / (investmentAmount + majorExpenses) : null;
  
  const firstDepMonths = allDepLines.find(o => !isNaN(parseInt(o.depreciationMonths, 10)))?.depreciationMonths;
  const amortizationVolume = monthlyVolume && firstDepMonths ?
    allDepLines.every(o => o.depreciationMonths === firstDepMonths) ?
      parseInt(firstDepMonths, 10) * monthlyVolume : 'VARIOUS' :
    '';
  const amortizationPeriod = firstDepMonths ?
    allDepLines.every(o => o.depreciationMonths === firstDepMonths) ?
      parseInt(firstDepMonths, 10) : 'VARIOUS' :
    '';
  
  return {
    initialCustomerPayment,
    initialCustomerCost,
    investmentAmount,
    majorExpenses,
    pieceCostAmortization,
    piecePriceAmortization,
    amortizationPeriod,
    amortizationVolume,
    pieceDataPerMonth,
    profitabilityIndex,
    internalRateReturn,
    netPresentValue,
    returnOnInvestment,
    payBackPeriod,
    pieceDataPerYear,
    adjustedCashFlow,
  };
};

export const getCapitalCalculations = config => {
  const { capitalCosts } = config;
  
  let totalCapitalCost = 0;
  let totalDepreciatedCost = 0;
  let totalDepreciatedPrice = 0;
  let totalCustomerPaysCost = 0;
  let totalCustomerPaysPrice = 0;
  let totalCapitalPrice = 0;
  let totalExpenseCost = 0;
  
  capitalCosts.filter(o => o.enabled).forEach(o => {
    const cost = o.unitCost && o.unitCost.length && !isNaN(parseFloat(o.unitCost)) ? parseFloat(o.unitCost) : 0;
    const price = o.unitPrice && o.unitPrice.length && !isNaN(parseFloat(o.unitPrice)) ? parseFloat(o.unitPrice) : 0;
    totalCapitalCost += cost;
    totalCapitalPrice += price;
    if(o.paymentType === 'NIFCO') totalExpenseCost += cost;
    if(o.paymentType === 'DEPRECIATE') totalDepreciatedCost += price ? price : cost;
    if(o.paymentType === 'DEPRECIATE_PRICE') totalDepreciatedPrice += price ? price : cost;
    if(o.paymentType === 'CUSTOMER') {
      totalCustomerPaysCost += cost ? cost : 0;
      totalCustomerPaysPrice += price ? price : cost;
    }
    if(o.paymentType === 'CAP_INVESTMENT') totalExpenseCost += cost;
  });
  
  return {
    totalCapitalCost,
    totalDepreciatedCost,
    totalDepreciatedPrice,
    totalCustomerPaysCost,
    totalCustomerPaysPrice,
    totalCapitalPrice,
    totalExpenseCost,
  };
};

export const getTestingCalculations = config => {
  const { testingCosts } = config;
  
  let totalTestingCost = 0;
  let totalTestingPrice = 0;
  let totalExpenseCost = 0;
  let totalDepreciatedCost = 0;
  let totalDepreciatedPrice = 0;
  let totalCustomerPaysCost = 0;
  let totalCustomerPaysPrice = 0;
  
  testingCosts.forEach(o => {
    const subTotal = o.quantity && o.quantity.length && !isNaN(parseInt(o.quantity, 10)) &&
    o.unitCost && o.unitCost.length && !isNaN(parseFloat(o.unitCost)) ?
      (parseInt(o.quantity, 10) * parseFloat(o.unitCost)) : 0;
    const subTotalPrice = o.quantity && o.quantity.length && !isNaN(parseInt(o.quantity, 10)) &&
    o.unitPrice && o.unitPrice.length && !isNaN(parseFloat(o.unitPrice)) ?
      (parseInt(o.quantity, 10) * parseFloat(o.unitPrice)) : 0;
    totalTestingCost += subTotal;
    totalTestingPrice += subTotalPrice;
    if(o.paymentType === 'NIFCO') totalExpenseCost += subTotal;
    if(o.paymentType === 'DEPRECIATE') totalDepreciatedCost += subTotalPrice ? subTotalPrice : subTotal;
    if(o.paymentType === 'DEPRECIATE_PRICE') totalDepreciatedPrice += subTotalPrice ? subTotalPrice : subTotal;
    if(o.paymentType === 'CUSTOMER') {
      totalCustomerPaysCost += subTotal ? subTotal : 0;
      totalCustomerPaysPrice += subTotalPrice ? subTotalPrice : subTotal;
    }
    if(o.paymentType === 'CAP_INVESTMENT') totalExpenseCost += subTotal;
  });
  
  return {
    totalTestingCost,
    totalExpenseCost,
    totalDepreciatedCost,
    totalDepreciatedPrice,
    totalCustomerPaysCost,
    totalCustomerPaysPrice,
    totalTestingPrice,
  };
};

export const getFinishedGoodCalculations = config => {
  let {
    rates,
    moldedParts,
    monthlyVolume,
    partType,
    purchasedPartCosts,
    carryoverPartCosts,
    assemblyCosts,
    fgLocation,
    inspectionType,
    packagingType,
    packagingCosts,
    auxCosts,
    rMexFreightCost,
    testingCosts,
    bom,
    productType,
    fgPrice,
    royaltyPercentage,
  } = config;
  
  const moldingCalculations = moldedParts.map((moldedPart, index, array) => {
    const somePartHasOptionB = array.some(o => o.hasOptionB);
    return {
      calculationsA: getMoldingCalculations({
        rates,
        location: moldedPart.toolLocationA,
        pressType: moldedPart.pressTypeA,
        pressSize: moldedPart.pressSizeA,
        gateCuttingType: moldedPart.gateCuttingA,
        annealingType: moldedPart.annealing,
        shotsPerHour: moldedPart.shotsPerHourA,
        cavitiesPerMold: moldedPart.cavitiesPerMoldA,
        inspectionType: moldedPart.inspection,
        packingType: moldedPart.packaging,
        glassIsUsed: moldedPart.glassUsed,
        partWeight: moldedPart.partWeight,
        materials: moldedPart.materials,
        monthlyVolume,
        regrindIsUsed: moldedPart.regrindUsed,
        cavitiesPerPart: moldedPart.cavitiesPerPartA,
        numOfDiffParts: moldedPart.numOfDiffPartsA,
        runnerPercentage: moldedPart.runnerPercentageA,
        regrindPercentage: moldedPart.regrindPercentage,
        partType,
        toolType: moldedPart.toolTypeA,
        toolCost: moldedPart.toolCostA,
        tuningTextureCost: moldedPart.tuningTextureCostA,
        boatShipmentCost: moldedPart.boatShipmentCostA,
        airShipmentCost: moldedPart.airShipmentCostA,
        partIsOutsourced: moldedPart.isOutsourced,
        outsourcedCost: moldedPart.outsourcedCost,
        packagingCosts: packagingCosts.filter(o => o.associatedPart === moldedPart.partDescription),
        productType,
        purchasedPartCosts,
      }),
      calculationsB: getMoldingCalculations({
        rates,
        location: moldedPart.toolLocationB ? moldedPart.toolLocationB : somePartHasOptionB ? moldedPart.toolLocationA : null,
        pressType: moldedPart.pressTypeB ? moldedPart.pressTypeB : somePartHasOptionB ? moldedPart.pressTypeA : null,
        pressSize: moldedPart.pressSizeB ? moldedPart.pressSizeB : somePartHasOptionB ? moldedPart.pressSizeA : null,
        gateCuttingType: moldedPart.gateCuttingB ? moldedPart.gateCuttingB : somePartHasOptionB ? moldedPart.gateCuttingA : null,
        annealingType: moldedPart.annealing,
        shotsPerHour: moldedPart.shotsPerHourB ? moldedPart.shotsPerHourB : somePartHasOptionB ? moldedPart.shotsPerHourA : null,
        cavitiesPerMold: moldedPart.cavitiesPerMoldB ? moldedPart.cavitiesPerMoldB : somePartHasOptionB ? moldedPart.cavitiesPerMoldA : null,
        inspectionType: moldedPart.inspection,
        packingType: moldedPart.packaging,
        glassIsUsed: moldedPart.glassUsed,
        partWeight: moldedPart.partWeight,
        materials: moldedPart.materials,
        monthlyVolume,
        regrindIsUsed: moldedPart.regrindUsed,
        cavitiesPerPart: moldedPart.cavitiesPerPartB ? moldedPart.cavitiesPerPartB : somePartHasOptionB ? moldedPart.cavitiesPerPartA : null,
        numOfDiffParts: moldedPart.numOfDiffPartsB ? moldedPart.numOfDiffPartsB : somePartHasOptionB ? moldedPart.numOfDiffPartsA : null,
        runnerPercentage: moldedPart.runnerPercentageB ? moldedPart.runnerPercentageB : somePartHasOptionB ? moldedPart.runnerPercentageA : null,
        regrindPercentage: moldedPart.regrindPercentage,
        partType,
        toolType: moldedPart.toolTypeB ? moldedPart.toolTypeB : somePartHasOptionB ? moldedPart.toolTypeA : null,
        toolCost: moldedPart.toolCostB ? moldedPart.toolCostB : somePartHasOptionB ? moldedPart.toolCostA : null,
        tuningTextureCost: moldedPart.tuningTextureCostB ? moldedPart.tuningTextureCostB : somePartHasOptionB ? moldedPart.tuningTextureCostA : null,
        boatShipmentCost: moldedPart.boatShipmentCostB ? moldedPart.boatShipmentCostB : somePartHasOptionB ? moldedPart.boatShipmentCostA : null,
        airShipmentCost: moldedPart.airShipmentCostB ? moldedPart.airShipmentCostB : somePartHasOptionB ? moldedPart.airShipmentCostA : null,
        partIsOutsourced: moldedPart.isOutsourced,
        outsourcedCost: moldedPart.outsourcedCost,
        packagingCosts: packagingCosts.filter(o => o.associatedPart === moldedPart.partDescription),
        productType,
        purchasedPartCosts,
      }),
    };
  });
  
  const purchasingCalculations = getPurchasingCalculations({
    purchasedPartCosts: productType === 'INSERT_MOLD_PURCHASE_PART' ? [] : purchasedPartCosts,
    carryoverPartCosts,
  });
  
  const assemblyCalculations = getAssemblyCalculations({
    assemblyCosts,
    rates,
    location: fgLocation,
    inspectionType,
    packagingType,
  });
  
  const packagingCalculations = getPackagingCalculations({ packagingCosts: packagingCosts.filter(o => (bom?.nodes ? bom.nodes.filter(n => n.type === 'Assembled') : []).map(n => n.name).includes(o.associatedPart)) });
  
  const totalMoldedComponentsA = moldingCalculations.map((o, index) => {
    const totalStandardCost = o?.calculationsA?.partIsOutsourced ?
      o?.calculationsA?.outsourcedCost != null ?
        o.calculationsA.outsourcedCost :
        0 :
      o?.calculationsA?.totalStandardCost != null ?
        o.calculationsA.totalStandardCost :
        0;
    return totalStandardCost * (moldedParts[index]?.quantityPerAssembly ? moldedParts[index].quantityPerAssembly : 1);
  })
    .reduce((total, cur) => total + cur, 0);
  const totalMoldedComponentsB = moldingCalculations.map((o, index) => {
    const totalStandardCost = o?.calculationsB?.partIsOutsourced ?
      o?.calculationsB?.outsourcedCost != null ?
        o.calculationsB.outsourcedCost :
        0 :
      o?.calculationsB?.totalStandardCost != null ?
        o.calculationsB.totalStandardCost :
        0;
    return totalStandardCost * (moldedParts[index]?.quantityPerAssembly ? moldedParts[index].quantityPerAssembly : 1);
  })
    .reduce((total, cur) => total + cur, 0);
  
  const totalComponentsCostA = totalMoldedComponentsA + purchasingCalculations.totalPurchasedComponentsCost + purchasingCalculations.totalCarryoverPartsCost;
  const totalComponentsCostB = totalMoldedComponentsB + purchasingCalculations.totalPurchasedComponentsCost + purchasingCalculations.totalCarryoverPartsCost;
  
  const totalOtherCost = assemblyCalculations.totalAssemblyCost + assemblyCalculations.totalPostProcessingCost +
    packagingCalculations.totalPackagingCost;
  
  // ASSEMBLY ADMIN
  const assemblyAdminRate = fgLocation !== 'NULL' ?
    rates.find(o => o.rateType === 'AUX' && o.orgCode === fgLocation && o.auxType === 'ASSEMBLY_ADMIN') : null;
  const assemblyAdmin = assemblyAdminRate ? assemblyAdminRate.rate : null;
  
  // ASSEMBLY TECHNICAL
  const assemblyTechnicalRate = fgLocation !== 'NULL' ?
    rates.find(o => o.rateType === 'AUX' && o.orgCode === fgLocation && o.auxType === 'ASSEMBLY_TECHNICAL') : null;
  const assemblyTechnical = assemblyTechnicalRate ? assemblyTechnicalRate.rate : null;
  
  // ASSEMBLY YIELD
  const assemblyYieldRate = fgLocation !== 'NULL' ?
    rates.find(o => o.rateType === 'AUX' && o.orgCode === fgLocation && o.auxType === 'ASSEMBLY_YIELD') : null;
  const assemblyYield = assemblyYieldRate ? assemblyYieldRate.rate : null;
  
  const baseFinishedGoodCostA = totalComponentsCostA + totalOtherCost;
  const baseFinishedGoodCostB = totalComponentsCostB + totalOtherCost;
  const administrativeCostA = !['MOLDED_PART_ONLY', 'INSERT_MOLD_PURCHASE_PART', 'INSERT_MOLD_MOLDED_PART'].includes(productType) && ((assemblyCosts && assemblyCosts.length) || (purchasedPartCosts && purchasedPartCosts.length)) ?
    baseFinishedGoodCostA * (assemblyAdmin ? assemblyAdmin : 0) :
    0;
  const administrativeCostB = !['MOLDED_PART_ONLY', 'INSERT_MOLD_PURCHASE_PART', 'INSERT_MOLD_MOLDED_PART'].includes(productType) && ((assemblyCosts && assemblyCosts.length) || (purchasedPartCosts && purchasedPartCosts.length)) ?
    baseFinishedGoodCostB * (assemblyAdmin ? assemblyAdmin : 0) :
    0;
  const technicalCostA = !['MOLDED_PART_ONLY', 'INSERT_MOLD_PURCHASE_PART', 'INSERT_MOLD_MOLDED_PART'].includes(productType) && ((assemblyCosts && assemblyCosts.length) || (purchasedPartCosts && purchasedPartCosts.length)) ?
    baseFinishedGoodCostA * (assemblyTechnical ? assemblyTechnical : 0) :
    0;
  const technicalCostB = !['MOLDED_PART_ONLY', 'INSERT_MOLD_PURCHASE_PART', 'INSERT_MOLD_MOLDED_PART'].includes(productType) && ((assemblyCosts && assemblyCosts.length) || (purchasedPartCosts && purchasedPartCosts.length)) ?
    baseFinishedGoodCostB * (assemblyTechnical ? assemblyTechnical : 0) :
    0;
  const yieldCostA = !['MOLDED_PART_ONLY', 'INSERT_MOLD_PURCHASE_PART', 'INSERT_MOLD_MOLDED_PART'].includes(productType) && ((assemblyCosts && assemblyCosts.length) || (purchasedPartCosts && purchasedPartCosts.length)) ?
    baseFinishedGoodCostA * (assemblyYield ? assemblyYield : 0) :
    0;
  const yieldCostB = !['MOLDED_PART_ONLY', 'INSERT_MOLD_PURCHASE_PART', 'INSERT_MOLD_MOLDED_PART'].includes(productType) && ((assemblyCosts && assemblyCosts.length) || (purchasedPartCosts && purchasedPartCosts.length)) ?
    baseFinishedGoodCostB * (assemblyYield ? assemblyYield : 0) :
    0;
  const totalAuxDepreciatedCost = auxCosts?.length && monthlyVolume && monthlyVolume > 0 ?
    auxCosts.filter(o => o.paymentType === 'DEPRECIATE' && o.depreciationMonths && o.quantity && o.quantity.length && !isNaN(parseInt(o.quantity, 10)) &&
      ((o.unitPrice && o.unitPrice.length && !isNaN(parseFloat(o.unitPrice))) || (o.unitCost && o.unitCost.length && !isNaN(parseFloat(o.unitCost)))))
      .reduce((total, o) => total + ((parseInt(o.quantity, 10) * parseFloat(o.unitPrice && o.unitPrice.length && !isNaN(parseFloat(o.unitPrice)) ? o.unitPrice : o.unitCost)) / (monthlyVolume * o.depreciationMonths)), 0) : 0;
  
  const totalTestingDepreciatedCost = testingCosts?.length && monthlyVolume && monthlyVolume > 0 ?
    testingCosts.filter(o => o.paymentType === 'DEPRECIATE' && o.depreciationMonths && o.quantity && o.quantity.length && !isNaN(parseInt(o.quantity, 10)) &&
      ((o.unitPrice && o.unitPrice.length && !isNaN(parseFloat(o.unitPrice))) || (o.unitCost && o.unitCost.length && !isNaN(parseFloat(o.unitCost)))))
      .reduce((total, o) => total + ((parseInt(o.quantity, 10) * parseFloat(o.unitCost)) / (monthlyVolume * o.depreciationMonths)), 0) : 0;
  const standardCostA = baseFinishedGoodCostA + administrativeCostA + technicalCostA + yieldCostA + rMexFreightCost;
  // const standardCostA = baseFinishedGoodCostA + administrativeCostA + technicalCostA + yieldCostA + rMexFreightCost + totalAuxDepreciatedCost + totalTestingDepreciatedCost;
  const standardCostB = baseFinishedGoodCostB + administrativeCostB + technicalCostB + yieldCostB + rMexFreightCost;
  // const standardCostB = baseFinishedGoodCostB + administrativeCostB + technicalCostB + yieldCostB + rMexFreightCost + totalAuxDepreciatedCost + totalTestingDepreciatedCost;
  
  const royaltyCost = fgPrice != null && royaltyPercentage != null ?
    fgPrice * (royaltyPercentage / 100) : null;
  
  const grossMarginPercentageA = fgPrice != null && fgPrice > 0 && royaltyCost != null && standardCostA ?
    ((fgPrice - standardCostA - royaltyCost) / fgPrice) * 100 : null;
  const grossMarginPercentageB = fgPrice != null && fgPrice > 0 && royaltyCost != null && standardCostB ?
    ((fgPrice - standardCostB - royaltyCost) / fgPrice) * 100 : null;
  
  const minimumPriceA = standardCostA && royaltyPercentage != null && (1 - 0.3 - (royaltyPercentage / 100)) > 0 ?
    standardCostA / (1 - 0.3 - (royaltyPercentage / 100)) : null;
  const minimumPriceB = standardCostB && royaltyPercentage != null && (1 - 0.3 - (royaltyPercentage / 100)) > 0 ?
    standardCostB / (1 - 0.3 - (royaltyPercentage / 100)) : null;
  
  return {
    totalMoldedComponentsA,
    totalMoldedComponentsB,
    totalPurchasedComponentsCost: purchasingCalculations.totalPurchasedComponentsCost,
    totalCarryoverPartsCost: purchasingCalculations.totalCarryoverPartsCost,
    totalComponentsCostA,
    totalComponentsCostB,
    totalAssemblyCost: assemblyCalculations.totalAssemblyCost,
    totalAssemblyPostProcessingCost: assemblyCalculations.totalPostProcessingCost,
    totalPackagingCost: packagingCalculations.totalPackagingCost,
    totalAuxDepreciatedCost,
    totalTestingDepreciatedCost,
    totalOtherCost,
    baseFinishedGoodCostA,
    baseFinishedGoodCostB,
    administrativeCostA,
    administrativeCostB,
    technicalCostA,
    technicalCostB,
    yieldCostA,
    yieldCostB,
    logisticCost: rMexFreightCost,
    standardCostA,
    standardCostB,
    royaltyCost,
    grossMarginPercentageA,
    grossMarginPercentageB,
    minimumPriceA,
    minimumPriceB,
  };
};