import { useEffect } from 'react';
import * as CONFIG from '../config'
import Login from './Login';
import LoadingOverlay from './LoadingOverlay';
import { useRecoilValue } from 'recoil';
import { loadingMessageSelector } from '../state';
import DimensionsProvider from './DimensionsProvider';

const App = () => {
  const loadingMessage = useRecoilValue(loadingMessageSelector);
  
  // set page title
  useEffect(() => {
    document.title = `NIFCO | ${CONFIG.APP_DISPLAY_NAME}`;
  }, []);
  
  return (
    <>
      <Login/>
      <DimensionsProvider/>
      {loadingMessage ? <LoadingOverlay message={loadingMessage}/> : null}
    </>
  );
};

export default App;
