import * as CONFIG from '../config';
import {
  quoteDataAtom,
  appRolesAtom,
  userIdAtom,
  saveQuoteTriggerAtom,
  quoteProcessorAtom,
  moldedPartsValueAtom,
  selectedMoldedPartIndexAtom,
  packagingCostsAtom,
  bomDataAtom,
  purchasedPartCostsAtom,
  carryoverPartCostsAtom,
  windowWidthAtom,
  quoteMissingAttributesAtom,
  initValuesAtom,
} from '../state';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faPen, faPlus, faSearch, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import COLORS from '../styles/colors';
import { useEffect, useState } from 'react';
import cloneDeep from 'lodash.clonedeep';
import {
  getNodeTypeFromProductType,
  getAvailableNodeTypes,
  getNodeColor, isJsonString, getTotalQty,
} from '../helpers/misc';
import MaterialSearchModal from './MaterialSearchModal';
import PackagingCostsSearchModal from './PackagingCostsSearchModal';
import PurchasedPartsSearchModal from './PurchasedPartsSearchModal';
import CarryoverPartsSearchModal from './CarryoverPartsSearchModal';
import ForceGraph from 'react-force-graph-3d';
import { v4 as uuid } from 'uuid';
import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js';

const QuoteTabBom = () => {
  const quoteData = useRecoilValue(quoteDataAtom);
  const appRoles = useRecoilValue(appRolesAtom);
  const userId = useRecoilValue(userIdAtom);
  const saveQuoteTrigger = useRecoilValue(saveQuoteTriggerAtom);
  const quoteProcessor = useRecoilValue(quoteProcessorAtom);
  const windowWidth = useRecoilValue(windowWidthAtom);
  const setMoldedPartsValue = useSetRecoilState(moldedPartsValueAtom);
  const selectedMoldedPartIndex = useSetRecoilState(selectedMoldedPartIndexAtom);
  const setPackagingCosts = useSetRecoilState(packagingCostsAtom);
  const setPurchasedPartCosts = useSetRecoilState(purchasedPartCostsAtom);
  const setCarryoverPartCosts = useSetRecoilState(carryoverPartCostsAtom);
  const quoteMissingAttributes = useRecoilValue(quoteMissingAttributesAtom);
  const [initValues, setInitValues] = useRecoilState(initValuesAtom);
  const [selectedPartType, setSelectedPartType] = useState('NULL');
  const [newPartNameValue, setNewPartNameValue] = useState('');
  const [newPartQuantityValue, setNewPartQuantityValue] = useState('1');
  const [isNewMaterialNumber, setIsNewMaterialNumber] = useState(false);
  const [materialNumberValue, setMaterialNumberValue] = useState(null);
  const [materialDescriptionValue, setMaterialDescriptionValue] = useState('');
  const [selectedMaterialType, setSelectedMaterialType] = useState('BASE');
  const [materialCostValue, setMaterialCostValue] = useState('');
  const [oracleMaterialCostValue, setOracleMaterialCostValue] = useState('');
  const [blendPercentageValue, setBlendPercentageValue] = useState('');
  const [showMaterialSearchModal, setShowMaterialSearchModal] = useState(false);
  const [isNewPackaging, setIsNewPackaging] = useState(false);
  const [packagingNumberValue, setPackagingNumberValue] = useState(null);
  const [packagingDescriptionValue, setPackagingDescriptionValue] = useState('');
  const [packagingCostValue, setPackagingCostValue] = useState('');
  const [packagingOracleCostValue, setPackagingOracleCostValue] = useState('');
  const [partsPerBoxValue, setPartsPerBoxValue] = useState('');
  const [showPackagingSearchModal, setShowPackagingSearchModal] = useState(false);
  const [showPurchasedPartSearchModal, setShowPurchasedPartSearchModal] = useState(false);
  const [isNewPurchasedPart, setIsNewPurchasedPart] = useState(false);
  const [purchasedPartValue, setPurchasedPartValue] = useState(null);
  const [purchasedPartDescriptionValue, setPurchasedPartDescriptionValue] = useState('');
  const [isPurchasedPartEstimate, setIsPurchasedPartEstimate] = useState(false);
  const [isPurchasedPartOutsourced, setIsPurchasedPartOutsourced] = useState(false);
  const [purchasedPartQuantityValue, setPurchasedPartQuantityValue] = useState('1');
  const [purchasedPartCostValue, setPurchasedPartCostValue] = useState('');
  const [purchasedPartOracleCostValue, setPurchasedPartOracleCostValue] = useState('');
  const [carryoverPartValue, setCarryoverPartValue] = useState(null);
  const [carryoverPartQuantityValue, setCarryoverPartQuantityValue] = useState('1');
  const [carryoverPartOracleCostValue, setCarryoverPartOracleCostValue] = useState('');
  const [carryoverPartCostValue, setCarryoverPartCostValue] = useState('');
  const [selectedNodeCostValue, setSelectedNodeCostValue] = useState('');
  const [selectedNodeBlendPercentageValue, setSelectedNodeBlendPercentageValue] = useState('');
  const [selectedNewMaterial, setSelectedNewMaterial] = useState('NULL');
  const [selectedNewPurchasedPart, setSelectedNewPurchasedPart] = useState('NULL');
  const [selectedNewPackaging, setSelectedNewPackaging] = useState('NULL');
  const [showCarryoverPartSearchModal, setShowCarryoverPartSearchModal] = useState(false);
  const [newPackagingType, setNewPackagingType] = useState('NULL');
  const [bomData, setBomData] = useRecoilState(bomDataAtom);
  const [selectedNode, setSelectedNode] = useState(null);
  
  useEffect(() => {
    if(saveQuoteTrigger) {
      quoteProcessor.setBomData({
        bom: bomData != null ? bomData : null,
      });
    }
  }, [
    saveQuoteTrigger,
    quoteProcessor,
    bomData,
  ]);
  
  useEffect(() => {
    if(initValues && quoteData) {
      setBomData(quoteData.bom != null ? quoteData.bom : null);
      setInitValues(false);
    }
  }, [initValues, quoteData, setBomData, setInitValues]);
  
  const designEstimatorAssignment = quoteData && quoteData.assignments ?
    quoteData.assignments.find(o => o.types.includes('DESIGN_ESTIMATOR')) :
    null;
  const toolingEstimatorAssignment = quoteData && quoteData.assignments ?
    quoteData.assignments.find(o => o.types.includes('TOOLING_ESTIMATOR')) :
    null;
  const purchasingEstimatorAssignment = quoteData && quoteData.assignments ?
    quoteData.assignments.find(o => o.types.includes('PURCHASING_ESTIMATOR')) :
    null;
  const feasibilityAssignment = quoteData && quoteData.assignments ?
    quoteData.assignments.find(o => o.types.includes('FEASIBILITY')) :
    null;
  const feasibilityAssignmentUserId = feasibilityAssignment ? feasibilityAssignment.userId : null;
  const designEstimatorAssignmentUserId = designEstimatorAssignment ? designEstimatorAssignment.userId : null;
  const toolingEstimatorAssignmentUserId = toolingEstimatorAssignment ? toolingEstimatorAssignment.userId : null;
  const purchasingEstimatorAssignmentUserId = purchasingEstimatorAssignment ? purchasingEstimatorAssignment.userId : null;
  
  const disableAllInputs = quoteData.status !== 'SANDBOX' && (quoteData.holdUserId !== userId ||
    quoteData.isNotActive || !['IN_PROGRESS', 'FEASIBILITY'].includes(quoteData.status) ||
    !((appRoles.includes(CONFIG.ROLE_MAPPINGS.DESIGN_ESTIMATOR) && userId === designEstimatorAssignmentUserId) ||
      (appRoles.includes(CONFIG.ROLE_MAPPINGS.FEASIBILITY) && userId === feasibilityAssignmentUserId) ||
      (appRoles.includes(CONFIG.ROLE_MAPPINGS.TOOLING_ESTIMATOR) && userId === toolingEstimatorAssignmentUserId) ||
      (appRoles.includes(CONFIG.ROLE_MAPPINGS.PURCHASING_ESTIMATOR) && userId === purchasingEstimatorAssignmentUserId)));
  
  const addPartNode = () => {
    // find parent index trail
    const nodeId = uuid();
    if(selectedPartType === 'Molded') {
      setBomData(prevState => {
        const newBomData = cloneDeep(prevState);
        newBomData.nodes.push({
          id: nodeId,
          name: newPartNameValue.trim(),
          type: selectedPartType,
          outsourced: false,
          quantity: parseInt(newPartQuantityValue, 10),
          color: getNodeColor(selectedPartType),
        });
        newBomData.links.push({
          source: selectedNode.id,
          target: nodeId,
          color: COLORS.black,
        });
        return newBomData;
      });
      // calculate actual qty
      const qty = getTotalQty(parseInt(newPartQuantityValue, 10), selectedNode, bomData);
      setMoldedPartsValue(prevState => [
        ...prevState,
        { partDescription: newPartNameValue, quantityPerAssembly: qty, bomId: nodeId },
      ]);
      setNewPartNameValue('');
      setNewPartQuantityValue('1');
    }
    else if(selectedPartType === 'Assembled') {
      setBomData(prevState => {
        const newBomData = cloneDeep(prevState);
        newBomData.nodes.push({
          id: nodeId,
          name: newPartNameValue.trim(),
          type: selectedPartType,
          quantity: parseInt(newPartQuantityValue, 10),
          color: getNodeColor(selectedPartType),
        });
        newBomData.links.push({
          source: selectedNode.id,
          target: nodeId,
          color: COLORS.black,
        });
        return newBomData;
      });
      setNewPartNameValue('');
      setNewPartQuantityValue('1');
    }
    else if(selectedPartType === 'Carryover') {
      // calculate actual qty
      const qty = getTotalQty(parseInt(carryoverPartQuantityValue, 10), selectedNode, bomData);
      setBomData(prevState => {
        const newBomData = cloneDeep(prevState);
        const existing = newBomData.nodes.find(o => o.type === 'Carryover' &&
          o.name === carryoverPartValue.partNumber);
        if(existing) {
          newBomData.links.push({
            source: selectedNode.id,
            target: existing.id,
            color: COLORS.black,
          });
        }
        else {
          newBomData.nodes.push({
            id: nodeId,
            name: carryoverPartValue.partNumber,
            type: selectedPartType,
            quantity: parseInt(carryoverPartQuantityValue, 10),
            partCost: carryoverPartCostValue,
            color: getNodeColor(selectedPartType),
          });
          newBomData.links.push({
            source: selectedNode.id,
            target: nodeId,
            color: COLORS.black,
          });
        }
        return newBomData;
      });
      setCarryoverPartCosts(prevState => {
        const newCarryoverPartCosts = cloneDeep(prevState);
        newCarryoverPartCosts.push({
          bomId: nodeId,
          partNumber: carryoverPartValue.partNumber,
          partDescription: carryoverPartValue.partDescription,
          parentName: selectedNode.name,
          orgCode: carryoverPartValue.orgCode,
          quantity: qty,
          oraclePartCost: carryoverPartValue.partCost,
          partCost: parseFloat(carryoverPartCostValue),
        });
        return newCarryoverPartCosts;
      });
      setCarryoverPartValue(null);
      setCarryoverPartQuantityValue('1');
    }
    else if(selectedPartType === 'Purchased') {
      // calculate actual qty
      const qty = getTotalQty(parseFloat(purchasedPartQuantityValue), selectedNode, bomData);
      if(selectedNewPurchasedPart && selectedNewPurchasedPart !== 'NULL' && isJsonString(selectedNewPurchasedPart)) {
        const selectedPart = JSON.parse(selectedNewPurchasedPart);
        setBomData(prevState => {
          const newBomData = cloneDeep(prevState);
          newBomData.links.push({
            source: selectedNode.id,
            target: selectedPart.id,
            color: COLORS.black,
          });
          return newBomData;
        });
        setPurchasedPartCosts(prevState => {
          const newPurchasedPartCosts = cloneDeep(prevState);
          newPurchasedPartCosts.push({
            bomId: selectedPart.id,
            partNumber: 'TBD',
            partDescription: selectedPart.name,
            parentName: selectedNode.name,
            orgCode: null,
            isOutsourced: selectedPart.isOutsourced,
            isEstimated: selectedPart.isEstimated,
            quantity: qty,
            partCost: parseFloat(selectedPart.partCost),
            oraclePartCost: selectedPart.oraclePartCost ? parseFloat(selectedPart.oraclePartCost) : null,
          });
          return newPurchasedPartCosts;
        });
        setSelectedNewPurchasedPart('NULL');
        setPurchasedPartDescriptionValue('');
        setPurchasedPartCostValue('');
        setPurchasedPartOracleCostValue('');
        setPurchasedPartQuantityValue('1');
        setIsPurchasedPartOutsourced(false);
        setIsPurchasedPartEstimate(false);
      }
      else if(isNewPurchasedPart) {
        setBomData(prevState => {
          const newBomData = cloneDeep(prevState);
          newBomData.nodes.push({
            id: nodeId,
            name: purchasedPartDescriptionValue.trim(),
            type: selectedPartType,
            partCost: purchasedPartCostValue,
            quantity: parseFloat(purchasedPartQuantityValue),
            color: getNodeColor(selectedPartType),
            isNew: true,
            isOutsourced: isPurchasedPartOutsourced,
            isEstimated: isPurchasedPartEstimate,
          });
          newBomData.links.push({
            source: selectedNode.id,
            target: nodeId,
            color: COLORS.black,
          });
          return newBomData;
        });
        setPurchasedPartCosts(prevState => {
          const newPurchasedPartCosts = cloneDeep(prevState);
          newPurchasedPartCosts.push({
            bomId: nodeId,
            partNumber: 'TBD',
            partDescription: purchasedPartDescriptionValue.trim(),
            parentName: selectedNode.name,
            orgCode: null,
            isOutsourced: isPurchasedPartOutsourced,
            isEstimated: isPurchasedPartEstimate,
            quantity: qty,
            partCost: parseFloat(purchasedPartCostValue),
          });
          return newPurchasedPartCosts;
        });
        setPurchasedPartDescriptionValue('');
        setPurchasedPartCostValue('');
        setPurchasedPartOracleCostValue('');
        setPurchasedPartQuantityValue('1');
        setIsPurchasedPartOutsourced(false);
        setIsPurchasedPartEstimate(false);
      }
      else {
        setBomData(prevState => {
          const newBomData = cloneDeep(prevState);
          const existing = newBomData.nodes.find(o => o.type === 'Purchased' &&
            o.name === purchasedPartValue.partNumber && !o.isNew);
          if(existing) {
            newBomData.links.push({
              source: selectedNode.id,
              target: existing.id,
              color: COLORS.black,
            });
          }
          else {
            newBomData.nodes.push({
              id: nodeId,
              name: purchasedPartValue.partNumber,
              type: selectedPartType,
              partCost: purchasedPartCostValue,
              oraclePartCost: purchasedPartValue.partCost,
              quantity: parseFloat(purchasedPartQuantityValue),
              color: getNodeColor(selectedPartType),
              isNew: false,
              isOutsourced: isPurchasedPartOutsourced,
              isEstimated: isPurchasedPartEstimate,
            });
            newBomData.links.push({
              source: selectedNode.id,
              target: nodeId,
              color: COLORS.black,
            });
          }
          return newBomData;
        });
        setPurchasedPartCosts(prevState => {
          const newPurchasedPartCosts = cloneDeep(prevState);
          newPurchasedPartCosts.push({
            bomId: nodeId,
            partNumber: purchasedPartValue.partNumber,
            partDescription: purchasedPartValue.partDescription,
            parentName: selectedNode.name,
            orgCode: purchasedPartValue.orgCode,
            isOutsourced: isPurchasedPartOutsourced,
            isEstimated: isPurchasedPartEstimate,
            quantity: qty,
            partCost: parseFloat(purchasedPartCostValue),
            oraclePartCost: purchasedPartValue.partCost,
          });
          return newPurchasedPartCosts;
        });
        setPurchasedPartValue(null);
        setPurchasedPartCostValue('');
        setPurchasedPartOracleCostValue('');
        setPurchasedPartQuantityValue('1');
        setIsPurchasedPartOutsourced(false);
        setIsPurchasedPartEstimate(false);
      }
    }
    else if(selectedPartType === 'Packaging') {
      if(selectedNewPackaging && selectedNewPackaging !== 'NULL' && isJsonString(selectedNewPackaging)) {
        const selectedPackaging = JSON.parse(selectedNewPackaging);
        setBomData(prevState => {
          const newBomData = cloneDeep(prevState);
          newBomData.links.push({
            source: selectedNode.id,
            target: selectedPackaging.id,
            color: COLORS.black,
          });
          return newBomData;
        });
        setPackagingCosts(prevState => {
          const newPackagingCosts = cloneDeep(prevState);
          newPackagingCosts.push({
            bomId: selectedPackaging.id,
            isNew: selectedPackaging.isNew,
            partNumber: 'TBD',
            description: selectedPackaging.name,
            associatedPart: selectedNode.name,
            quantity: partsPerBoxValue,
            unitCost: parseFloat(selectedPackaging.packagingCost),
            oracleUnitCost: selectedPackaging.oracleUnitCost ? parseFloat(selectedPackaging.oracleUnitCost) : null,
            packagingType: selectedPackaging.packagingType,
          });
          return newPackagingCosts;
        });
        setSelectedNewPackaging('NULL');
        setPackagingDescriptionValue('');
        setPackagingCostValue('');
        setPackagingOracleCostValue('');
        setPartsPerBoxValue('');
        setNewPackagingType('NULL');
      }
      else if(isNewPackaging) {
        setBomData(prevState => {
          const newBomData = cloneDeep(prevState);
          newBomData.nodes.push({
            id: nodeId,
            name: packagingDescriptionValue.trim(),
            type: selectedPartType,
            quantity: partsPerBoxValue,
            color: getNodeColor(selectedPartType),
            isNew: true,
            packagingCost: packagingCostValue,
            packagingType: newPackagingType,
          });
          newBomData.links.push({
            source: selectedNode.id,
            target: nodeId,
            color: COLORS.black,
          });
          return newBomData;
        });
        setPackagingCosts(prevState => {
          const newPackagingCosts = cloneDeep(prevState);
          newPackagingCosts.push({
            bomId: nodeId,
            isNew: true,
            partNumber: 'TBD',
            description: packagingDescriptionValue.trim(),
            associatedPart: selectedNode.name,
            quantity: partsPerBoxValue,
            unitCost: parseFloat(packagingCostValue),
            packagingType: newPackagingType,
          });
          return newPackagingCosts;
        });
        setPackagingDescriptionValue('');
        setPackagingCostValue('');
        setPackagingOracleCostValue('');
        setPartsPerBoxValue('');
        setNewPackagingType('NULL');
      }
      else {
        setBomData(prevState => {
          const newBomData = cloneDeep(prevState);
          const existing = newBomData.nodes.find(o => o.type === 'Packaging' &&
            o.name === packagingNumberValue.partNumber && !o.isNew);
          if(existing) {
            newBomData.links.push({
              source: selectedNode.id,
              target: existing.id,
              color: COLORS.black,
            });
          }
          else {
            newBomData.nodes.push({
              id: nodeId,
              name: packagingNumberValue.partNumber,
              type: selectedPartType,
              quantity: partsPerBoxValue,
              color: getNodeColor(selectedPartType),
              isNew: false,
              packagingCost: packagingCostValue,
              oraclePackagingCost: `${packagingNumberValue.partCost}`,
            });
            newBomData.links.push({
              source: selectedNode.id,
              target: nodeId,
              color: COLORS.black,
            });
          }
          return newBomData;
        });
        setPackagingCosts(prevState => {
          const newPackagingCosts = cloneDeep(prevState);
          newPackagingCosts.push({
            bomId: nodeId,
            isNew: false,
            partNumber: packagingNumberValue.partNumber,
            description: packagingNumberValue.partDescription,
            associatedPart: selectedNode.name,
            quantity: partsPerBoxValue,
            unitCost: parseFloat(packagingCostValue),
            oracleUnitCost: packagingNumberValue.partCost,
          });
          return newPackagingCosts;
        });
        setPackagingNumberValue(null);
        setPackagingCostValue('');
        setPackagingOracleCostValue('');
        setPartsPerBoxValue('');
      }
    }
    else if(selectedPartType === 'Material') {
      if(selectedNewMaterial && selectedNewMaterial !== 'NULL' && isJsonString(selectedNewMaterial)) {
        const selectedMaterial = JSON.parse(selectedNewMaterial);
        setBomData(prevState => {
          const newBomData = cloneDeep(prevState);
          newBomData.links.push({
            source: selectedNode.id,
            target: selectedMaterial.id,
            color: COLORS.black,
          });
          return newBomData;
        });
        setMoldedPartsValue(prevState => {
          const newMoldedParts = cloneDeep(prevState);
          const partIndex = newMoldedParts.findIndex(o => o.bomId === selectedNode.id);
          if(partIndex < 0) return prevState;
          if(!newMoldedParts[partIndex].materials) newMoldedParts[partIndex].materials = [];
          newMoldedParts[partIndex].materials.push({
            bomId: selectedMaterial.id,
            materialNumber: 'TBD',
            materialType: selectedMaterial.materialType,
            description: selectedMaterial.name,
            blendPercentage: parseFloat(blendPercentageValue),
            isNew: true,
            materialCost: parseFloat(selectedMaterial.materialCost),
            lastPoPrice: 'N/A',
            lastPoDate: 'N/A',
          });
          return newMoldedParts;
        });
        setSelectedNewMaterial('NULL');
        setMaterialDescriptionValue('');
        setMaterialCostValue('');
        setOracleMaterialCostValue('');
        setBlendPercentageValue('');
      }
      else if(isNewMaterialNumber) {
        setBomData(prevState => {
          const newBomData = cloneDeep(prevState);
          newBomData.nodes.push({
            id: nodeId,
            name: materialDescriptionValue.trim(),
            type: selectedPartType,
            materialType: selectedMaterialType,
            materialCost: materialCostValue,
            blendPercentage: blendPercentageValue,
            color: getNodeColor(selectedPartType),
            isNew: true,
          });
          newBomData.links.push({
            source: selectedNode.id,
            target: nodeId,
            color: COLORS.black,
          });
          return newBomData;
        });
        setMoldedPartsValue(prevState => {
          const newMoldedParts = cloneDeep(prevState);
          const partIndex = newMoldedParts.findIndex(o => o.bomId === selectedNode.id);
          if(partIndex < 0) return prevState;
          if(!newMoldedParts[partIndex].materials) newMoldedParts[partIndex].materials = [];
          newMoldedParts[partIndex].materials.push({
            bomId: nodeId,
            materialNumber: 'TBD',
            materialType: selectedMaterialType,
            description: materialDescriptionValue.trim(),
            blendPercentage: parseFloat(blendPercentageValue),
            isNew: true,
            materialCost: parseFloat(materialCostValue),
            lastPoPrice: 'N/A',
            lastPoDate: 'N/A',
          });
          return newMoldedParts;
        });
        setMaterialDescriptionValue('');
        setMaterialCostValue('');
        setOracleMaterialCostValue('');
        setBlendPercentageValue('');
      }
      else {
        const existing = bomData.nodes.find(o => o.type === 'Material' &&
          o.name === materialNumberValue.materialNumber && o.materialType === materialNumberValue.materialType && !o.isNew);
        setBomData(prevState => {
          let newBomData = cloneDeep(prevState);
          if(existing) {
            newBomData.links.push({
              source: selectedNode.id,
              target: existing.id,
              color: COLORS.black,
            });
          }
          else {
            newBomData.nodes.push({
              id: nodeId,
              name: materialNumberValue.materialNumber,
              type: selectedPartType,
              materialType: materialNumberValue.materialType,
              materialCost: materialCostValue,
              blendPercentage: blendPercentageValue,
              color: getNodeColor(selectedPartType),
              isNew: false,
            });
            newBomData.links.push({
              source: selectedNode.id,
              target: nodeId,
              color: COLORS.black,
            });
          }
          return newBomData;
        });
        setMoldedPartsValue(prevState => {
          const newMoldedParts = cloneDeep(prevState);
          const partIndex = newMoldedParts.findIndex(o => o.bomId === selectedNode.id);
          if(partIndex < 0) return prevState;
          if(!newMoldedParts[partIndex].materials) newMoldedParts[partIndex].materials = [];
          newMoldedParts[partIndex].materials.push({
            bomId: existing ? existing.id : nodeId,
            materialNumber: materialNumberValue.materialNumber,
            materialType: materialNumberValue.materialType,
            description: materialNumberValue.materialDescription,
            blendPercentage: parseFloat(blendPercentageValue),
            isNew: false,
            oracleMaterialCost: materialNumberValue.materialCost,
            materialCost: parseFloat(materialCostValue),
            lastPoPrice: materialNumberValue.lastPoPrice,
            lastPoDate: materialNumberValue.lastPoDate,
          });
          return newMoldedParts;
        });
        setMaterialNumberValue(null);
        setMaterialCostValue('');
        setOracleMaterialCostValue('');
        setBlendPercentageValue('');
      }
    }
  };
  
  const confirmRemovePartNode = () => {
    if(window.confirm(`Are you sure you want to remove this part (${selectedNode.name})?`))
      removePartNode();
  };
  
  const removePartNode = () => {
    const type = selectedNode.type;
    setBomData(prevState => {
      const newBomData = cloneDeep(prevState);
      newBomData.nodes = newBomData.nodes.filter(o => o.id !== selectedNode.id);
      newBomData.links = newBomData.links.filter(o => o.source !== selectedNode.id && o.target !== selectedNode.id);
      return newBomData;
    });
    if(type === 'Molded') {
      selectedMoldedPartIndex(0);
      setMoldedPartsValue(prevState => prevState.filter(o => o.bomId !== selectedNode.id));
    }
    else if(type === 'Carryover') {
      setCarryoverPartCosts(prevState => cloneDeep(prevState).filter(o => o.bomId !== selectedNode.id));
    }
    else if(type === 'Purchased') {
      setPurchasedPartCosts(prevState => cloneDeep(prevState).filter(o => o.bomId !== selectedNode.id));
    }
    else if(type === 'Packaging') {
      setPackagingCosts(prevState => cloneDeep(prevState).filter(o => o.bomId !== selectedNode.id));
    }
    else if(type === 'Material') {
      setMoldedPartsValue(prevState => cloneDeep(prevState)
        .map(partObj => {
          partObj.materials = partObj.materials.filter(m => m.bomId !== selectedNode.id);
          return partObj;
        }));
    }
    setSelectedNode(null);
  };
  
  const createBom = () => {
    const nodeType = getNodeTypeFromProductType(quoteData.productType);
    const initId = uuid();
    if(nodeType === 'Molded') {
      setBomData({
        nodes: [{
          id: initId,
          name: quoteData.partDescription,
          type: nodeType,
          outsourced: false,
          color: getNodeColor(nodeType),
          isOrigin: true,
          quantity: 1,
        }],
        links: [],
      });
      setMoldedPartsValue(prevState => [
        ...prevState,
        { partDescription: quoteData.partDescription, quantityPerAssembly: 1, bomId: initId },
      ]);
    }
    else if(nodeType === 'Purchased') {
      setBomData({
        nodes: [{
          id: initId,
          name: quoteData.partDescription,
          type: 'PurchasedBOM',
          isOrigin: true,
          quantity: 1,
          color: getNodeColor(nodeType),
        }],
        links: [],
      });
    }
    else if(nodeType === 'Assembled') {
      setBomData({
        nodes: [{
          id: initId,
          name: quoteData.partDescription,
          type: nodeType,
          isOrigin: true,
          quantity: 1,
          color: getNodeColor(nodeType),
        }],
        links: [],
      });
    }
  };
  
  const editPart = () => {
    if(!selectedNode) return;
    if(selectedNode.type === 'Purchased') {
      setPurchasedPartCosts(prevState => cloneDeep(prevState)
        .map(partObj => {
          if(partObj.bomId !== selectedNode.id) return partObj;
          if(!isNaN(parseFloat(selectedNodeCostValue)) && parseFloat(selectedNodeCostValue) > 0)
            partObj.partCost = parseFloat(selectedNodeCostValue);
          return partObj;
        }));
      setBomData(prevState => {
        const newBomData = cloneDeep(prevState);
        newBomData.nodes = newBomData.nodes.map(node => {
          if(node.id !== selectedNode.id) return node;
          if(!isNaN(parseFloat(selectedNodeCostValue)) && parseFloat(selectedNodeCostValue) > 0)
            node.partCost = selectedNodeCostValue;
          return node;
        });
        return newBomData;
      });
      setSelectedNode(prevState => {
        if(!isNaN(parseFloat(selectedNodeCostValue)) && parseFloat(selectedNodeCostValue) > 0)
          prevState.partCost = selectedNodeCostValue;
        return prevState;
      });
    }
    else if(selectedNode.type === 'Carryover') {
      setCarryoverPartCosts(prevState => cloneDeep(prevState)
        .map(partObj => {
          if(partObj.bomId !== selectedNode.id) return partObj;
          if(!isNaN(parseFloat(selectedNodeCostValue)) && parseFloat(selectedNodeCostValue) > 0)
            partObj.partCost = parseFloat(selectedNodeCostValue);
          return partObj;
        }));
      setBomData(prevState => {
        const newBomData = cloneDeep(prevState);
        newBomData.nodes = newBomData.nodes.map(node => {
          if(node.id !== selectedNode.id) return node;
          if(!isNaN(parseFloat(selectedNodeCostValue)) && parseFloat(selectedNodeCostValue) > 0)
            node.partCost = selectedNodeCostValue;
          return node;
        });
        return newBomData;
      });
      setSelectedNode(prevState => {
        if(!isNaN(parseFloat(selectedNodeCostValue)) && parseFloat(selectedNodeCostValue) > 0)
          prevState.partCost = selectedNodeCostValue;
        return prevState;
      });
    }
    else if(selectedNode.type === 'Material') {
      setMoldedPartsValue(prevState => cloneDeep(prevState)
        .map(partObj => {
          partObj.materials = partObj.materials.map(materialObj => {
            if(materialObj.bomId !== selectedNode.id) return materialObj;
            if(!isNaN(parseFloat(selectedNodeBlendPercentageValue)) && parseFloat(selectedNodeBlendPercentageValue) > 0)
              materialObj.blendPercentage = parseFloat(selectedNodeBlendPercentageValue);
            if(!isNaN(parseFloat(selectedNodeCostValue)) && parseFloat(selectedNodeCostValue) > 0)
              materialObj.materialCost = parseFloat(selectedNodeCostValue);
            return materialObj;
          });
          return partObj;
        }));
      setBomData(prevState => {
        const newBomData = cloneDeep(prevState);
        newBomData.nodes = newBomData.nodes.map(node => {
          if(node.id !== selectedNode.id) return node;
          if(!isNaN(parseFloat(selectedNodeBlendPercentageValue)) && parseFloat(selectedNodeBlendPercentageValue) > 0)
            node.blendPercentage = selectedNodeBlendPercentageValue;
          if(!isNaN(parseFloat(selectedNodeCostValue)) && parseFloat(selectedNodeCostValue) > 0)
            node.materialCost = selectedNodeCostValue;
          return node;
        });
        return newBomData;
      });
      setSelectedNode(prevState => {
        if(!isNaN(parseFloat(selectedNodeBlendPercentageValue)) && parseFloat(selectedNodeBlendPercentageValue) > 0)
          prevState.blendPercentage = selectedNodeBlendPercentageValue;
        if(!isNaN(parseFloat(selectedNodeCostValue)) && parseFloat(selectedNodeCostValue) > 0)
          prevState.materialCost = selectedNodeCostValue;
        return prevState;
      });
    }
    else if(selectedNode.type === 'Packaging') {
      setPackagingCosts(prevState => cloneDeep(prevState)
        .map(costObj => {
          if(costObj.bomId !== selectedNode.id) return costObj;
          if(!isNaN(parseFloat(selectedNodeCostValue)) && parseFloat(selectedNodeCostValue) > 0)
            costObj.unitCost = parseFloat(selectedNodeCostValue);
          return costObj;
        }));
      setBomData(prevState => {
        const newBomData = cloneDeep(prevState);
        newBomData.nodes = newBomData.nodes.map(node => {
          if(node.id !== selectedNode.id) return node;
          if(!isNaN(parseFloat(selectedNodeCostValue)) && parseFloat(selectedNodeCostValue) > 0)
            node.packagingCost = selectedNodeCostValue;
          return node;
        });
        return newBomData;
      });
      setSelectedNode(prevState => {
        if(!isNaN(parseFloat(selectedNodeCostValue)) && parseFloat(selectedNodeCostValue) > 0)
          prevState.packagingCost = selectedNodeCostValue;
        return prevState;
      });
    }
  };
  
  if(!bomData) return (
    <div style={styles.createBomContainer}>
      <Button
        type="button"
        variant="primary"
        onClick={createBom}
        disabled={disableAllInputs || !quoteData.partDescription || !quoteData.productType}
      >
        Create BOM
      </Button>
    </div>
  );
  
  const nodeNames = bomData?.nodes ? bomData.nodes.map(o => o.name.toLowerCase()) : [];
  
  return (
    <>
      <h5 style={styles.lineTitle}>
        <span style={styles.lineTitleText}>
          Selected Part
        </span>
      </h5>
      <div style={styles.tabContainerRow}>
        <div style={styles.tabContainerLabel}>
          <p style={styles.noMarginText}>Name</p>
        </div>
        <Form.Control
          type="text"
          style={{ flexBasis: '26%' }}
          value={selectedNode ? selectedNode.name : ''}
          disabled
        />
        <div style={{ ...styles.tabContainerLabel, flexGrow: 1 }}>
          <p style={styles.noMarginText}>Type</p>
        </div>
        <Form.Control
          type="text"
          style={{ flexBasis: '26%' }}
          value={selectedNode ? selectedNode.type : ''}
          disabled
        />
      </div>
      <div style={styles.tabContainerRow}>
        {selectedNode?.type === 'Material' ?
          <>
            <div style={styles.tabContainerLabel}>
              <p style={styles.noMarginText}>Blend Percentage</p>
            </div>
            <InputGroup style={{ flexBasis: '26%' }}>
              <Form.Control
                type="number"
                step="0.1"
                min="0"
                value={selectedNodeBlendPercentageValue}
                onChange={e => setSelectedNodeBlendPercentageValue(e.target.value)}
                disabled={disableAllInputs || !selectedNode}
              />
              <InputGroup.Text style={styles.rightInputGroupText}>%</InputGroup.Text>
            </InputGroup>
          </> :
          null
        }
        {['Purchased', 'Packaging', 'Carryover', 'Material'].includes(selectedNode?.type) ?
          <>
            <div style={{ ...styles.tabContainerLabel, flexGrow: selectedNode.type === 'Material' ? 1 : undefined }}>
              <p style={styles.noMarginText}>Cost</p>
            </div>
            <InputGroup style={{ flexBasis: '26%' }}>
              <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
              <Form.Control
                type="number"
                value={selectedNodeCostValue}
                onChange={e => setSelectedNodeCostValue(e.target.value)}
                disabled={disableAllInputs || !selectedNode || selectedNode.isOrigin}
              />
            </InputGroup>
          </> :
          null
        }
      </div>
      <div style={styles.tabContainerRow}>
        <div style={{ flex: 1 }}/>
        <Button
          type="button"
          variant="success"
          onClick={editPart}
          disabled={disableAllInputs || !selectedNode || selectedNode.isOrigin || !['Purchased', 'Packaging', 'Carryover', 'Material'].includes(selectedNode?.type) ||
            isNaN(parseFloat(selectedNodeCostValue)) || parseFloat(selectedNodeCostValue) <= 0 ||
            (selectedNode?.type === 'Purchased' && selectedNodeCostValue === selectedNode.partCost) ||
            (selectedNode?.type === 'Carryover' && selectedNodeCostValue === selectedNode.partCost) ||
            (selectedNode?.type === 'Packaging' && selectedNodeCostValue === selectedNode.packagingCost) ||
            (selectedNode?.type === 'Material' && selectedNodeCostValue === selectedNode.materialCost && selectedNodeBlendPercentageValue === selectedNode.blendPercentage) ||
            (selectedNode?.type === 'Material' && (isNaN(parseFloat(selectedNodeBlendPercentageValue)) || parseFloat(selectedNodeBlendPercentageValue) <= 0))}
          style={styles.addButton}
        >
          <FontAwesomeIcon icon={faPen} style={{ marginRight: 4 }}/>
          Edit Part
        </Button>
        <Button
          type="button"
          variant="danger"
          onClick={confirmRemovePartNode}
          disabled={disableAllInputs || !selectedNode || selectedNode.isOrigin}
          style={styles.addButton}
        >
          <FontAwesomeIcon icon={faTrashAlt} style={{ marginRight: 4 }}/>
          Remove Part
        </Button>
      </div>
      <h5 style={styles.lineTitle}>
        <span style={styles.lineTitleText}>
          Add Part
        </span>
      </h5>
      <div style={styles.tabContainerRow}>
        <div style={styles.tabContainerLabel}>
          <p style={styles.noMarginText}>Type</p>
        </div>
        <Form.Select
          as="select"
          style={{ flexBasis: '26%' }}
          value={selectedPartType}
          onChange={e => setSelectedPartType(e.target.value)}
          disabled={disableAllInputs}
        >
          <option value="NULL">-- Select Type --</option>
          {getAvailableNodeTypes(quoteData.productType, selectedNode?.type).map((value, index) => <option
            key={index}>{value}</option>)}
        </Form.Select>
        <div style={{ flex: 1 }}/>
        <Button
          type="button"
          variant="primary"
          onClick={addPartNode}
          disabled={disableAllInputs || !selectedNode || !selectedPartType || selectedPartType === 'NULL' ||
            ((selectedPartType === 'Molded' || selectedPartType === 'Assembled') && (!newPartNameValue.trim().length || nodeNames.includes(newPartNameValue.trim().toLowerCase()) || isNaN(parseInt(newPartQuantityValue, 10)) || parseInt(newPartQuantityValue, 10) <= 0)) ||
            (selectedPartType === 'Material' &&
              ((isNewMaterialNumber && (!materialDescriptionValue.trim().length || ((!selectedNewMaterial || selectedNewMaterial === 'NULL') && nodeNames.includes(materialDescriptionValue.trim().toLowerCase())) || isNaN(parseFloat(blendPercentageValue)) || parseFloat(blendPercentageValue) <= 0 || isNaN(parseFloat(materialCostValue)) || parseFloat(materialCostValue) <= 0)) ||
                (!isNewMaterialNumber && (!materialNumberValue || isNaN(parseFloat(blendPercentageValue)))))) ||
            (selectedPartType === 'Packaging' && ((isNewPackaging && (!packagingDescriptionValue.trim().length || ((!selectedNewPackaging || selectedNewPackaging === 'NULL') && nodeNames.includes(packagingDescriptionValue.trim().toLowerCase())) || isNaN(parseInt(partsPerBoxValue, 10)) || parseInt(partsPerBoxValue, 10) <= 0 || isNaN(parseFloat(packagingCostValue)) || parseFloat(packagingCostValue) <= 0)) ||
              (!isNewPackaging && (!packagingNumberValue || isNaN(parseInt(partsPerBoxValue, 10)) || parseInt(partsPerBoxValue, 10) <= 0 || isNaN(parseFloat(packagingCostValue)) || parseFloat(packagingCostValue) <= 0)))) ||
            (selectedPartType === 'Purchased' && ((isNewPurchasedPart && (!purchasedPartDescriptionValue.trim().length || ((!selectedNewPurchasedPart || selectedNewPurchasedPart === 'NULL') && nodeNames.includes(purchasedPartDescriptionValue.trim().toLowerCase())) || isNaN(parseFloat(purchasedPartQuantityValue)) || parseFloat(purchasedPartQuantityValue) <= 0 || isNaN(parseFloat(purchasedPartCostValue)) || parseFloat(purchasedPartCostValue) <= 0)) ||
              (!isNewPurchasedPart && (!purchasedPartValue || isNaN(parseFloat(purchasedPartQuantityValue)) || parseFloat(purchasedPartQuantityValue) <= 0 || isNaN(parseFloat(purchasedPartCostValue)) || parseFloat(purchasedPartCostValue) <= 0)))) ||
            (selectedPartType === 'Carryover' && (!carryoverPartValue || isNaN(parseInt(carryoverPartQuantityValue, 10)) || parseInt(carryoverPartQuantityValue, 10) <= 0 || isNaN(parseFloat(carryoverPartCostValue)) || parseFloat(carryoverPartCostValue) <= 0))}
          style={styles.addButton}
        >
          <FontAwesomeIcon icon={faPlus} style={{ marginRight: 4 }}/>
          Add Part
        </Button>
      </div>
      {
        selectedNode && (selectedPartType === 'Molded' || selectedPartType === 'Assembled') ?
          <div style={styles.tabContainerRow}>
            <div style={styles.tabContainerLabel}>
              <p style={styles.noMarginText}>Part Name</p>
            </div>
            <Form.Control
              type="text"
              style={{ flexBasis: '26%' }}
              value={newPartNameValue}
              onChange={e => setNewPartNameValue(e.target.value)}
              disabled={disableAllInputs}
            />
            <div style={{ ...styles.tabContainerLabel, flexGrow: 1 }}>
              <p style={styles.noMarginText}>Quantity</p>
            </div>
            <Form.Control
              type="number"
              step="1"
              min="1"
              style={{ flexBasis: '26%' }}
              value={newPartQuantityValue}
              onChange={e => setNewPartQuantityValue(e.target.value)}
              disabled={disableAllInputs}
            />
          </div> :
          null
      }
      {
        selectedNode && selectedPartType === 'Carryover' ?
          <>
            <div style={styles.tabContainerRow}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>P/N</p>
              </div>
              <Form.Control
                type="text"
                style={{
                  flexBasis: '26%',
                  marginRight: 10,
                }}
                value={carryoverPartValue ? carryoverPartValue.partNumber : ''}
                disabled
              />
              <div style={{ ...styles.tabContainerLabel, flexGrow: 1 }}>
                <p style={styles.noMarginText}>Part Cost</p>
              </div>
              <InputGroup style={{ flexBasis: '26%' }}>
                <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                <Form.Control
                  type="number"
                  value={carryoverPartCostValue}
                  onChange={e => setCarryoverPartCostValue(e.target.value)}
                  disabled={disableAllInputs}
                />
              </InputGroup>
            </div>
            <div style={styles.tabContainerRow}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Quantity</p>
              </div>
              <Form.Control
                type="number"
                step="1"
                min="1"
                style={{
                  flexBasis: '26%',
                  marginRight: 10,
                }}
                value={carryoverPartQuantityValue}
                onChange={e => setCarryoverPartQuantityValue(e.target.value)}
                disabled={disableAllInputs}
              />
              <div style={{ ...styles.tabContainerLabel, flexGrow: 1 }}>
                <p style={styles.noMarginText}>Oracle Part Cost</p>
              </div>
              <InputGroup style={{ flexBasis: '26%' }}>
                <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                <Form.Control
                  type="number"
                  value={carryoverPartOracleCostValue}
                  disabled
                />
              </InputGroup>
            </div>
            <div style={styles.tabContainerRow}>
              <div style={{ flex: 1 }}/>
              <Button
                type="button"
                variant="secondary"
                onClick={() => setShowCarryoverPartSearchModal(true)}
                disabled={disableAllInputs}
              >
                <FontAwesomeIcon icon={faSearch} style={{ marginRight: 4 }}/>
                Search
              </Button>
            </div>
          </> :
          null
      }
      {
        selectedNode && selectedPartType === 'Purchased' ?
          <>
            <div style={styles.tabContainerRow}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>{isNewPurchasedPart ? 'Part Description' : 'P/N'}</p>
              </div>
              {
                !isNewPurchasedPart ?
                  <Form.Control
                    type="text"
                    style={{
                      flexBasis: '26%',
                      marginRight: 10,
                    }}
                    value={purchasedPartValue ? purchasedPartValue.partNumber : ''}
                    disabled
                  /> :
                  <Form.Control
                    type="text"
                    style={{
                      flexBasis: '26%',
                      marginRight: 10,
                    }}
                    value={purchasedPartDescriptionValue}
                    onChange={e => setPurchasedPartDescriptionValue(e.target.value)}
                    disabled={disableAllInputs || (selectedNewPurchasedPart && selectedNewPurchasedPart !== 'NULL')}
                  />
              }
              <div style={{ ...styles.tabContainerLabel, flexGrow: 1 }}>
                <p style={styles.noMarginText}>Part Cost</p>
              </div>
              <InputGroup style={{ flexBasis: '26%' }}>
                <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                <Form.Control
                  type="number"
                  step="0.01"
                  min="0"
                  value={purchasedPartCostValue}
                  onChange={e => setPurchasedPartCostValue(e.target.value)}
                  disabled={disableAllInputs || (selectedNewPurchasedPart && selectedNewPurchasedPart !== 'NULL')}
                />
              </InputGroup>
            </div>
            <div style={styles.tabContainerRow}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Quantity</p>
              </div>
              <Form.Control
                type="number"
                step="1"
                min="0"
                style={{
                  flexBasis: '26%',
                  marginRight: 10,
                }}
                value={purchasedPartQuantityValue}
                onChange={e => setPurchasedPartQuantityValue(e.target.value)}
                disabled={disableAllInputs}
              />
              <div style={{ flex: 1 }}/>
              <Form.Check
                type="checkbox"
                label="Estimate"
                style={{ marginRight: 20 }}
                checked={isPurchasedPartEstimate}
                onChange={e => setIsPurchasedPartEstimate(e.target.checked)}
                disabled={disableAllInputs || (selectedNewPurchasedPart && selectedNewPurchasedPart !== 'NULL')}
              />
              <Form.Check
                type="checkbox"
                label="Outsourced"
                style={{ marginRight: 20 }}
                checked={isPurchasedPartOutsourced}
                onChange={e => setIsPurchasedPartOutsourced(e.target.checked)}
                disabled={disableAllInputs || (selectedNewPurchasedPart && selectedNewPurchasedPart !== 'NULL')}
              />
            </div>
            {!isNewPurchasedPart ?
              <div style={styles.tabContainerRow}>
                <div style={styles.tabContainerLabel}>
                  <p style={styles.noMarginText}>Oracle Part Cost</p>
                </div>
                <InputGroup style={{ flexBasis: '26%' }}>
                  <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                  <Form.Control
                    type="number"
                    value={purchasedPartOracleCostValue}
                    disabled
                  />
                </InputGroup>
              </div> :
              <div style={styles.tabContainerRow}>
                <div style={styles.tabContainerLabel}>
                  <p style={styles.noMarginText}>New Part</p>
                </div>
                <Form.Select
                  as="select"
                  style={{ flexBasis: '26%' }}
                  value={selectedNewPurchasedPart}
                  onChange={e => {
                    const newValue = isJsonString(e.target.value) ? JSON.parse(e.target.value) : null;
                    setPurchasedPartDescriptionValue(newValue ? newValue.name : '');
                    setPurchasedPartCostValue(newValue ? newValue.partCost : '');
                    setIsPurchasedPartEstimate(newValue ? newValue.isEstimated : false);
                    setIsPurchasedPartOutsourced(newValue ? newValue.isOutsourced : false);
                    setSelectedNewPurchasedPart(e.target.value);
                  }}
                  disabled={disableAllInputs}
                >
                  <option value="NULL">--- Select New Part ---</option>
                  {bomData && selectedNode ?
                    bomData.nodes
                      .filter(o => o.type === 'Purchased' && o.isNew &&
                        !bomData.links.some(l => l.source === selectedNode.id && l.target === o.id))
                      .map((o, index) => <option key={index} value={JSON.stringify(o)}>{o.name}</option>) :
                    null}
                </Form.Select>
              </div>
            }
            <div style={styles.tabContainerRow}>
              <div style={{ flex: 1 }}/>
              <Form.Check
                type="checkbox"
                label="New"
                style={{ marginRight: 10 }}
                checked={isNewPurchasedPart}
                onChange={e => {
                  setPurchasedPartValue(null);
                  setPurchasedPartDescriptionValue('');
                  setIsNewPurchasedPart(e.target.checked);
                }}
                disabled={disableAllInputs}
              />
              <Button
                type="button"
                variant="secondary"
                onClick={() => setShowPurchasedPartSearchModal(true)}
                disabled={disableAllInputs || isNewPurchasedPart}
              >
                <FontAwesomeIcon icon={faSearch} style={{ marginRight: 4 }}/>
                Search
              </Button>
            </div>
          </> :
          null
      }
      {
        selectedNode && selectedPartType === 'Packaging' ?
          <>
            <div style={styles.tabContainerRow}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>{isNewPackaging ? 'Packaging Description' : 'Packaging P/N'}</p>
              </div>
              {
                !isNewPackaging ?
                  <Form.Control
                    type="text"
                    style={{
                      flexBasis: '26%',
                      marginRight: 10,
                    }}
                    value={packagingNumberValue ? packagingNumberValue.partNumber : ''}
                    disabled
                  /> :
                  <Form.Control
                    type="text"
                    style={{
                      flexBasis: '26%',
                      marginRight: 10,
                    }}
                    value={packagingDescriptionValue}
                    onChange={e => setPackagingDescriptionValue(e.target.value)}
                    disabled={disableAllInputs || (selectedNewPackaging && selectedNewPackaging !== 'NULL')}
                  />
              }
              <div style={{ ...styles.tabContainerLabel, flexGrow: 1 }}>
                <p style={styles.noMarginText}>Parts / Box</p>
              </div>
              <Form.Control
                type="number"
                step="1"
                min="1"
                style={{ flexBasis: '26%' }}
                value={partsPerBoxValue}
                onChange={e => setPartsPerBoxValue(e.target.value)}
                disabled={disableAllInputs}
              />
            </div>
            <div style={styles.tabContainerRow}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Unit Cost</p>
              </div>
              <InputGroup style={{ flexBasis: '26%' }}>
                <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                <Form.Control
                  type="number"
                  step="0.01"
                  min="0"
                  value={packagingCostValue}
                  onChange={e => setPackagingCostValue(e.target.value)}
                  disabled={disableAllInputs || (selectedNewPackaging && selectedNewPackaging !== 'NULL')}
                />
              </InputGroup>
              {!isNewPackaging ?
                <>
                  <div style={{ ...styles.tabContainerLabel, flexGrow: 1 }}>
                    <p style={styles.noMarginText}>Oracle Unit Cost</p>
                  </div>
                  <InputGroup style={{ flexBasis: '26%' }}>
                    <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                    <Form.Control
                      type="number"
                      step="0.01"
                      min="0"
                      value={packagingOracleCostValue}
                      onChange={e => setPackagingOracleCostValue(e.target.value)}
                      disabled
                    />
                  </InputGroup>
                </> :
                <>
                  <div style={{ ...styles.tabContainerLabel, flexGrow: 1 }}>
                    <p style={styles.noMarginText}>Packaging Type</p>
                  </div>
                  <Form.Select
                    as="select"
                    style={{ flexBasis: '26%' }}
                    value={newPackagingType}
                    onChange={e => setNewPackagingType(e.target.value)}
                    disabled={disableAllInputs || (selectedNewPackaging && selectedNewPackaging !== 'NULL')}
                  >
                    <option value="NULL">--- Select Packaging Type ---</option>
                    <option value="BOX">Box</option>
                    <option value="BAG">Bag</option>
                    <option value="RETURNABLE">Returnable</option>
                  </Form.Select>
                </>
              }
            </div>
            {isNewPackaging ?
              <div style={styles.tabContainerRow}>
                <div style={styles.tabContainerLabel}>
                  <p style={styles.noMarginText}>New Packaging</p>
                </div>
                <Form.Select
                  as="select"
                  style={{ flexBasis: '26%' }}
                  value={selectedNewPackaging}
                  onChange={e => {
                    const newValue = isJsonString(e.target.value) ? JSON.parse(e.target.value) : null;
                    setPackagingDescriptionValue(newValue ? newValue.name : '');
                    setPackagingCostValue(newValue ? newValue.packagingCost : '');
                    setSelectedNewPackaging(e.target.value);
                    setNewPackagingType(newValue ? newValue.packagingType : 'NULL');
                  }}
                  disabled={disableAllInputs}
                >
                  <option value="NULL">--- Select New Packaging ---</option>
                  {bomData && selectedNode ?
                    bomData.nodes
                      .filter(o => o.type === 'Packaging' && o.isNew &&
                        !bomData.links.some(l => l.source === selectedNode.id && l.target === o.id))
                      .map((o, index) => <option key={index} value={JSON.stringify(o)}>{o.name}</option>) :
                    null}
                </Form.Select>
              </div> :
              null
            }
            <div style={styles.tabContainerRow}>
              <div style={{ flex: 1 }}/>
              <Form.Check
                type="checkbox"
                label="New"
                style={{ marginRight: 10 }}
                checked={isNewPackaging}
                onChange={e => {
                  setPackagingNumberValue(null);
                  setPackagingDescriptionValue('');
                  setPackagingCostValue('');
                  setPackagingOracleCostValue('');
                  setIsNewPackaging(e.target.checked);
                }}
                disabled={disableAllInputs}
              />
              <Button
                type="button"
                variant="secondary"
                onClick={() => setShowPackagingSearchModal(true)}
                disabled={disableAllInputs || isNewPackaging}
              >
                <FontAwesomeIcon icon={faSearch} style={{ marginRight: 4 }}/>
                Search
              </Button>
            </div>
          </> :
          null
      }
      {
        selectedNode && selectedPartType === 'Material' ?
          <>
            <div style={styles.tabContainerRow}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>{isNewMaterialNumber ? 'Material Description' : 'Material P/N'}</p>
              </div>
              {
                !isNewMaterialNumber ?
                  <Form.Control
                    type="text"
                    style={{
                      flexBasis: '26%',
                      marginRight: 10,
                    }}
                    value={materialNumberValue ? materialNumberValue.materialNumber : ''}
                    disabled
                  /> :
                  <Form.Control
                    type="text"
                    style={{
                      flexBasis: '26%',
                      marginRight: 10,
                    }}
                    value={materialDescriptionValue}
                    onChange={e => setMaterialDescriptionValue(e.target.value)}
                    disabled={disableAllInputs || (selectedNewMaterial != null && selectedNewMaterial !== 'NULL')}
                  />
              }
              <div style={{ ...styles.tabContainerLabel, flexGrow: 1 }}>
                <p style={styles.noMarginText}>Blend Percentage</p>
              </div>
              <InputGroup style={{ flexBasis: '26%' }}>
                <Form.Control
                  type="number"
                  step="0.1"
                  min="0"
                  value={blendPercentageValue}
                  onChange={e => setBlendPercentageValue(e.target.value)}
                  disabled={disableAllInputs}
                />
                <InputGroup.Text style={styles.rightInputGroupText}>%</InputGroup.Text>
              </InputGroup>
            </div>
            <div style={styles.tabContainerRow}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Material Type</p>
              </div>
              <Form.Select
                as="select"
                style={{ flexBasis: '26%' }}
                value={selectedMaterialType}
                onChange={e => setSelectedMaterialType(e.target.value)}
                disabled={disableAllInputs || !isNewMaterialNumber || (selectedNewMaterial != null && selectedNewMaterial !== 'NULL')}
              >
                <option value="BASE">BASE</option>
                <option value="CONCENTRATE">CONCENTRATE</option>
                <option value="PREBLEND">PREBLEND</option>
              </Form.Select>
              <div style={{ ...styles.tabContainerLabel, flexGrow: 1 }}>
                <p style={styles.noMarginText}>Material Cost</p>
              </div>
              <InputGroup style={{ flexBasis: '26%' }}>
                <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                <Form.Control
                  type="number"
                  step="0.01"
                  min="0"
                  value={materialCostValue}
                  onChange={e => setMaterialCostValue(e.target.value)}
                  disabled={disableAllInputs || (selectedNewMaterial != null && selectedNewMaterial !== 'NULL')}
                />
                <InputGroup.Text style={styles.rightInputGroupText}>/lb</InputGroup.Text>
              </InputGroup>
            </div>
            {!isNewMaterialNumber ?
              <div style={styles.tabContainerRow}>
                <div style={styles.tabContainerLabel}>
                  <p style={styles.noMarginText}>Oracle Material Cost</p>
                </div>
                <InputGroup style={{ flexBasis: '26%' }}>
                  <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                  <Form.Control
                    type="number"
                    value={oracleMaterialCostValue}
                    disabled
                  />
                  <InputGroup.Text style={styles.rightInputGroupText}>/lb</InputGroup.Text>
                </InputGroup>
              </div> :
              <div style={styles.tabContainerRow}>
                <div style={styles.tabContainerLabel}>
                  <p style={styles.noMarginText}>New Material</p>
                </div>
                <Form.Select
                  as="select"
                  style={{ flexBasis: '26%' }}
                  value={selectedNewMaterial}
                  onChange={e => {
                    const newValue = isJsonString(e.target.value) ? JSON.parse(e.target.value) : null;
                    setMaterialDescriptionValue(newValue ? newValue.name : '');
                    setSelectedMaterialType(newValue ? newValue.materialType : 'BASE');
                    setMaterialCostValue(newValue ? newValue.materialCost : '');
                    setSelectedNewMaterial(e.target.value);
                  }}
                  disabled={disableAllInputs}
                >
                  <option value="NULL">--- Select New Material ---</option>
                  {bomData && selectedNode ?
                    bomData.nodes
                      .filter(o => o.type === 'Material' && o.isNew &&
                        !bomData.links.some(l => l.source === selectedNode.id && l.target === o.id))
                      .map((o, index) => <option key={index} value={JSON.stringify(o)}>{o.name}</option>) :
                    null}
                </Form.Select>
              </div>
            }
            <div style={styles.tabContainerRow}>
              <div style={{ flex: 1 }}/>
              <Form.Check
                type="checkbox"
                label="New"
                style={{ marginRight: 10 }}
                checked={isNewMaterialNumber}
                onChange={e => {
                  setMaterialNumberValue(null);
                  setMaterialDescriptionValue('');
                  setIsNewMaterialNumber(e.target.checked);
                }}
                disabled={disableAllInputs}
              />
              <Button
                type="button"
                variant="secondary"
                onClick={() => setShowMaterialSearchModal(true)}
                disabled={disableAllInputs || isNewMaterialNumber}
              >
                <FontAwesomeIcon icon={faSearch} style={{ marginRight: 4 }}/>
                Search
              </Button>
            </div>
          </> :
          null
      }
      <h5 style={styles.lineTitle}>
        <span style={styles.lineTitleText}>
          BOM
        </span>
      </h5>
      <div style={styles.treeKey}>
        <div style={styles.treeKeyItem}>
          <FontAwesomeIcon icon={faCircle} style={{ marginRight: 4, color: getNodeColor('Assembled'), opacity: 0.75 }}/>
          <p style={{ margin: 0, marginRight: 15 }}>Assembled</p>
        </div>
        <div style={styles.treeKeyItem}>
          <FontAwesomeIcon icon={faCircle} style={{ marginRight: 4, color: getNodeColor('Molded'), opacity: 0.75 }}/>
          <p style={{ margin: 0, marginRight: 15 }}>Molded</p>
        </div>
        <div style={styles.treeKeyItem}>
          <FontAwesomeIcon icon={faCircle} style={{ marginRight: 4, color: getNodeColor('Carryover'), opacity: 0.75 }}/>
          <p style={{ margin: 0, marginRight: 15 }}>Carryover</p>
        </div>
        <div style={styles.treeKeyItem}>
          <FontAwesomeIcon icon={faCircle} style={{ marginRight: 4, color: getNodeColor('Purchased'), opacity: 0.75 }}/>
          <p style={{ margin: 0, marginRight: 15 }}>Purchased</p>
        </div>
        <div style={styles.treeKeyItem}>
          <FontAwesomeIcon icon={faCircle} style={{ marginRight: 4, color: getNodeColor('Packaging'), opacity: 0.75 }}/>
          <p style={{ margin: 0, marginRight: 15 }}>Packaging</p>
        </div>
        <div style={styles.treeKeyItem}>
          <FontAwesomeIcon icon={faCircle} style={{ marginRight: 4, color: getNodeColor('Material'), opacity: 0.75 }}/>
          <p style={{ margin: 0, marginRight: 15 }}>Material</p>
        </div>
      </div>
      <div
        style={{
          ...styles.treeContainer,
          border: `1px solid ${quoteMissingAttributes.includes('BOM|bomData') ? COLORS.red : COLORS.lightGray}`,
        }}
      >
        <ForceGraph
          graphData={cloneDeep(bomData)}
          nodeAutoColorBy="type"
          backgroundColor="#FFFFFF"
          width={(windowWidth * 0.75) - 50}
          height={400}
          nodeOpacity={0.75}
          extraRenderers={[new CSS2DRenderer()]}
          nodeThreeObject={node => {
            const nodeEl = document.createElement('div');
            nodeEl.textContent = node.name;
            nodeEl.style.color = selectedNode?.id === node.id ? COLORS.orange : '#FFFFFF';
            nodeEl.className = 'node-label';
            if(showMaterialSearchModal || showCarryoverPartSearchModal || showPurchasedPartSearchModal || showPackagingSearchModal)
              nodeEl.style.visibility = 'hidden';
            return new CSS2DObject(nodeEl);
          }}
          nodeThreeObjectExtend={true}
          onNodeClick={node => {
            if(selectedNode?.id !== node.id) {
              setSelectedNode(node);
              setSelectedPartType('NULL');
              setSelectedNodeCostValue(node.partCost || node.packagingCost || node.materialCost || '');
              if(node.type === 'Material') setSelectedNodeBlendPercentageValue(node.blendPercentage || '');
            }
          }}
          nodeLabel={() => ''}
        />
      </div>
      
      <MaterialSearchModal
        isOpen={showMaterialSearchModal}
        hide={() => setShowMaterialSearchModal(false)}
        onSubmit={materialObj => {
          setMaterialNumberValue(materialObj);
          setSelectedMaterialType(materialObj.materialType);
          if(materialObj.materialCost != null) {
            setMaterialCostValue(`${materialObj.materialCost}`);
            setOracleMaterialCostValue(`${materialObj.materialCost}`);
          }
        }}
      />
      <PackagingCostsSearchModal
        isOpen={showPackagingSearchModal}
        hide={() => setShowPackagingSearchModal(false)}
        onSubmit={packObj => {
          setPackagingNumberValue(packObj);
          setPackagingCostValue(`${packObj.partCost}`);
          setPackagingOracleCostValue(`${packObj.partCost}`);
        }}
      />
      <PurchasedPartsSearchModal
        isOpen={showPurchasedPartSearchModal}
        hide={() => setShowPurchasedPartSearchModal(false)}
        onSubmit={partObj => {
          setPurchasedPartValue(partObj);
          setPurchasedPartCostValue(`${partObj.partCost}`);
          setPurchasedPartOracleCostValue(`${partObj.partCost}`);
        }}
      />
      <CarryoverPartsSearchModal
        isOpen={showCarryoverPartSearchModal}
        hide={() => setShowCarryoverPartSearchModal(false)}
        onSubmit={partObj => {
          setCarryoverPartValue(partObj);
          setCarryoverPartCostValue(`${partObj.partCost}`);
          setCarryoverPartOracleCostValue(`${partObj.partCost}`);
        }}
      />
    </>
  );
};

const styles = {
  controlsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  treeContainer: {
    borderRadius: 5,
    height: 410,
    padding: 5,
    marginBottom: 10,
    marginTop: 10,
  },
  tabContainerRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 7,
  },
  tabContainerLabel: {
    flexBasis: '22%',
    textAlign: 'right',
    paddingRight: 10,
  },
  noMarginText: {
    margin: 0,
  },
  controlsText: {
    margin: 0,
    marginRight: 10,
    textAlign: 'right',
  },
  controlsTextContainer: {
    minWidth: 100,
  },
  addButton: {
    marginLeft: 10,
  },
  lineTitle: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: COLORS.midGray,
    lineHeight: '0.1em',
    color: COLORS.darkGray,
    textAlign: 'left',
    marginTop: 20,
    marginBottom: 20,
  },
  lineTitleText: {
    backgroundColor: COLORS.white,
    paddingLeft: 7,
    paddingRight: 7,
  },
  createBomContainer: {
    marginBottom: 10,
  },
  treeKey: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  treeKeyItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
};

export default QuoteTabBom;