import { useState } from 'react';
import { Button, Form, Spinner, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { getMaterials } from '../helpers/api';
import { accessTokenAtom, userIdAtom, loggedInUserAtom } from '../state';
import { useRecoilValue } from 'recoil';
import { round } from '../helpers/misc';
import Logger from '../helpers/Logger';

const logger = new Logger();

const MaterialSearchModal = props => {
  const { isOpen, hide, onSubmit } = props;
  const [materialNumberSearchValue, setMaterialNumberSearchValue] = useState('');
  const [materialDescriptionSearchValue, setMaterialDescriptionSearchValue] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);
  const accessToken = useRecoilValue(accessTokenAtom);
  const userId = useRecoilValue(userIdAtom);
  const loggedInUser = useRecoilValue(loggedInUserAtom);
  
  const searchMaterial = async () => {
    try {
      setIsSearching(true);
      const materials = await getMaterials(accessToken, materialNumberSearchValue, materialDescriptionSearchValue);
      setSearchResults(materials);
      setHasSearched(true);
    }
    catch(e) {
      console.error('MaterialSearchModal.searchMaterial error ->', e);
      logger.log(accessToken, `MaterialSearchModal.searchMaterial error -> ${JSON.stringify(e)}`, userId, loggedInUser);
      setSearchResults([]);
      setHasSearched(true);
    }
    finally {
      setIsSearching(false);
    }
  };
  
  return (
    <Modal
      isOpen={isOpen}
      style={styles.materialModal}
      ariaHideApp={false}
    >
      <div style={styles.materialModalHeaderRow}>
        <h4 style={{ margin: 0 }}>Search Material</h4>
        <FontAwesomeIcon
          icon={faTimes}
          size="lg"
          onClick={() => {
            setMaterialNumberSearchValue('');
            setMaterialDescriptionSearchValue('');
            setSearchResults([]);
            setHasSearched(false);
            hide();
          }}
          style={styles.closeTimes}
        />
      </div>
      <div style={styles.materialModalHeaderRow}>
        <div style={styles.materialModalSearchGroup}>
          <Form.Control
            type="text"
            placeholder="Number"
            value={materialNumberSearchValue}
            onChange={e => setMaterialNumberSearchValue(e.target.value)}
            style={{ marginRight: 10 }}
          />
          <Form.Control
            type="text"
            placeholder="Description"
            value={materialDescriptionSearchValue}
            onChange={e => setMaterialDescriptionSearchValue(e.target.value)}
            style={{ marginRight: 10 }}
          />
          <Button
            type="button"
            variant="primary"
            onClick={searchMaterial}
            disabled={isSearching ||
              (!materialNumberSearchValue.trim().length && !materialDescriptionSearchValue.trim().length)}
          >
              <span style={styles.materialModalSearchButton}>
                <FontAwesomeIcon icon={faSearch} style={{ marginRight: 4 }}/>
                Search
              </span>
          </Button>
        </div>
        {
          hasSearched ?
            <h5 style={styles.headingResultsText}>
              {searchResults.length === 100 ?
                'Found over 100 matching materials' :
                `Found ${searchResults.length} matching materials`}
            </h5> :
            null
        }
      </div>
      <div style={styles.materialModalContent}>
        {
          isSearching ?
            <div style={styles.spinnerContainer}>
              <Spinner animation="border"/>
              <p style={styles.spinnerText}>Searching...</p>
            </div> :
            searchResults.length ?
              <Table striped>
                <thead>
                <tr>
                  <th>Org</th>
                  <th>Number</th>
                  <th>Description</th>
                  <th>Cost</th>
                  <th>Last PO Price</th>
                  <th>Last PO Date</th>
                </tr>
                </thead>
                <tbody>
                {searchResults.map((row, index) => (
                  <tr
                    key={index}
                    style={styles.clickableRow}
                    onClick={() => {
                      onSubmit(row);
                      setMaterialNumberSearchValue('');
                      setMaterialDescriptionSearchValue('');
                      setSearchResults([]);
                      setHasSearched(false);
                      hide();
                    }}
                  >
                    <td>{row.orgCode}</td>
                    <td>{row.materialNumber}</td>
                    <td>{row.materialDescription}</td>
                    <td>{row.materialCost != null ? round(row.materialCost, 5) : 'N/A'}</td>
                    <td>{row.lastPoPrice != null ? round(row.lastPoPrice, 5) : 'N/A'}</td>
                    <td>{row.lastPoDate != null ? row.lastPoDate : 'N/A'}</td>
                  </tr>
                ))}
                </tbody>
              </Table> :
              null
        }
      </div>
    </Modal>
  );
};

const styles = {
  materialModal: {
    content: {
      top: 25,
      bottom: 25,
      left: 25,
      right: 25,
      borderRadius: 5,
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'flex-start',
    },
  },
  materialModalContent: {
    flex: 1,
    overflowY: 'auto',
  },
  materialModalHeaderRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: 10,
    borderBottom: 'solid 1px #CCCCCC',
  },
  materialModalSearchGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  materialModalSearchButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  closeTimes: {
    cursor: 'pointer',
  },
  spinnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 30,
  },
  spinnerText: {
    margin: 0,
    marginTop: 10,
    fontSize: 20,
  },
  headingResultsText: {
    margin: 0,
    alignSelf: 'center',
  },
  clickableRow: {
    cursor: 'pointer',
  },
};

export default MaterialSearchModal;