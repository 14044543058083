import { Spinner } from 'react-bootstrap';
import COLORS from '../styles/colors';

const LoadingOverlay = ({ message }) => {
  return (
    <div style={styles.overlay}>
      <Spinner animation="border" />
      <p style={styles.message}>{message}</p>
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 1000,
    opacity: 0.8,
    backgroundColor: COLORS.black,
    color: COLORS.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  message: {
    marginTop: '10px',
    fontSize: '30px',
  },
};

export default LoadingOverlay;
