import { Table } from 'react-bootstrap';
import COLORS from '../styles/colors';
import { useState } from 'react';
import { getFinishedGoodCalculations } from '../helpers/calculations';
import {
  moldedPartsValueAtom,
  purchasedPartCostsAtom,
  carryoverPartCostsAtom,
  currentRatesAtom,
  quoteDataAtom,
  assemblyCostsAtom,
  fgLocationSelectAtom,
  inspectionSelectAtom,
  packagingSelectAtom,
  packagingCostsAtom,
  auxCostsAtom,
  rMexFreightValueAtom,
  testingCostsAtom,
  ihsProgramDataAtom,
} from '../state';
import { useRecoilValue } from 'recoil';
import { getRateOrgCodeFromLocator, cmp } from '../helpers/misc';
import { DateTime } from 'luxon';

const QuoteTabFinishGood = () => {
  const [ihsDataVisible, setIhsDataVisible] = useState(false);
  const [finishedGoodsAssemblyCostsVisible, setFinishedGoodsAssemblyCostsVisible] = useState(false);
  const [finishedGoodsOtherCostsVisible, setFinishedGoodsOtherCostsVisible] = useState(false);
  const [finishedGoodsTotalCostsVisible, setFinishedGoodsTotalCostsVisible] = useState(false);
  const moldedPartsValue = useRecoilValue(moldedPartsValueAtom);
  const purchasedPartCosts = useRecoilValue(purchasedPartCostsAtom);
  const carryoverPartCosts = useRecoilValue(carryoverPartCostsAtom);
  const currentRates = useRecoilValue(currentRatesAtom);
  const quoteData = useRecoilValue(quoteDataAtom);
  const assemblyCosts = useRecoilValue(assemblyCostsAtom);
  const fgLocationSelect = useRecoilValue(fgLocationSelectAtom);
  const inspectionSelect = useRecoilValue(inspectionSelectAtom);
  const packagingSelect = useRecoilValue(packagingSelectAtom);
  const packagingCosts = useRecoilValue(packagingCostsAtom);
  const auxCosts = useRecoilValue(auxCostsAtom);
  const rMexFreightValue = useRecoilValue(rMexFreightValueAtom);
  const testingCosts = useRecoilValue(testingCostsAtom);
  const ihsProgramData = useRecoilValue(ihsProgramDataAtom);
  
  // manipulate IHS data
  const ihsDataYearsAll = ihsProgramData?.years?.filter(o => quoteData?.programs?.some(p => p.programPlantId === o.programPlantId));
  const ihsDataMonthsAll = ihsProgramData?.months?.filter(o => quoteData?.programs?.some(p => p.programPlantId === o.programPlantId));
  const ihsDataYears = ihsDataYearsAll?.reduce((arr, cur) => {
    for(let obj of cur.data) {
      const yearIndex = arr.findIndex(o => o.year === obj.year);
      if(yearIndex < 0) arr.push({ ...obj });
      else arr[yearIndex].value += obj.value;
    }
    return arr;
  }, []);
  const ihsDataMonths = ihsDataMonthsAll?.reduce((arr, cur) => {
    for(let obj of cur.data) {
      const monthYearIndex = arr.findIndex(o => o.monthYear === obj.monthYear);
      if(monthYearIndex < 0) arr.push({ ...obj });
      else arr[monthYearIndex].value += obj.value;
    }
    return arr;
  }, []);
  const now = DateTime.now();
  const currentYear = now.year;
  const currentMonth = now.month;
  const actualYears = ihsDataYears ? ihsDataYears.filter(o => o.year < currentYear).map(o => ({
    ...o,
    value: Math.round(o.value / 12),
  })).sort((a, b) => cmp(a.year, b.year)) : [];
  const futureYears = ihsDataYears ? ihsDataYears.filter(o => o.year > currentYear).map(o => ({
    ...o,
    value: Math.round(o.value / 12),
  })).sort((a, b) => cmp(a.year, b.year)) : [];
  const actualMonths = ihsDataMonths ? ihsDataMonths.filter(o => o.year === currentYear && o.month < currentMonth) : [];
  const actualMonthsTotal = actualMonths.length ? Math.round(actualMonths.reduce((total, cur) => total + cur.value, 0) / actualMonths.length) : 0;
  const futureMonths = ihsDataMonths ? ihsDataMonths.filter(o => o.year === currentYear && o.month >= currentMonth) : [];
  const futureMonthsTotal = futureMonths.length ? Math.round(futureMonths.reduce((total, cur) => total + cur.value, 0) / futureMonths.length) : 0;
  
  // TODO: handle sub assembly aux, yield, technical costs. Handle packaging costs assigned to sub assembly
  
  const calculations = getFinishedGoodCalculations({
    rates: currentRates,
    moldedParts: moldedPartsValue,
    monthlyVolume: quoteData.monthlyVolume,
    partType: quoteData.partType,
    purchasedPartCosts: purchasedPartCosts,
    carryoverPartCosts: carryoverPartCosts,
    assemblyCosts,
    fgLocation: fgLocationSelect !== 'NULL' ? getRateOrgCodeFromLocator(fgLocationSelect) : null,
    inspectionType: inspectionSelect,
    packagingType: packagingSelect,
    packagingCosts,
    auxCosts,
    rMexFreightCost: rMexFreightValue && !isNaN(parseFloat(rMexFreightValue)) ? parseFloat(rMexFreightValue) : 0,
    testingCosts,
    bom: quoteData.bom,
    productType: quoteData.productType,
  });
  
  const somePartHasOptionB = moldedPartsValue.some(o => o.hasOptionB);
  
  return (
    <>
      <div style={styles.closingRow}>
        <div
          style={styles.closingRowHeader}
          onClick={() => setIhsDataVisible(prev => !prev)}
        >
          <h5 style={styles.noMarginText}>IHS Data</h5>
        </div>
        {
          ihsDataVisible ?
            <div style={styles.closingRowContent}>
              {ihsDataYears && ihsDataMonths ?
                <>
                  <Table
                    striped
                    variant="light"
                    size="sm"
                  >
                    <tbody>
                    <tr>
                      <th
                        rowSpan="2"
                        style={{
                          textAlign: 'center',
                          verticalAlign: 'middle',
                        }}
                      >
                        Avg Monthly (Actual)
                      </th>
                      {actualYears.map(obj => <th key={obj.year}>{obj.year}</th>)}
                      <th>{currentYear}</th>
                    </tr>
                    <tr>
                      {actualYears.map(obj => <td key={obj.year}>{obj.value}</td>)}
                      <td>{actualMonthsTotal}</td>
                    </tr>
                    </tbody>
                  </Table>
                  <Table
                    striped
                    variant="light"
                    size="sm"
                    style={{ margin: 0 }}
                  >
                    <tbody>
                    <tr>
                      <th
                        rowSpan="2"
                        style={{
                          textAlign: 'center',
                          verticalAlign: 'middle',
                        }}
                      >
                        Avg Monthly (Future)
                      </th>
                      <th>{currentYear}</th>
                      {futureYears.map(obj => <th key={obj.year}>{obj.year}</th>)}
                    </tr>
                    <tr>
                      <td>{futureMonthsTotal}</td>
                      {futureYears.map(obj => <td key={obj.year}>{obj.value}</td>)}
                    </tr>
                    </tbody>
                  </Table>
                </> :
                <p style={{ margin: 0 }}>No IHS data...</p>
              }
            </div> :
            null
        }
      </div>
      <div style={styles.closingRow}>
        <div
          style={styles.closingRowHeader}
          onClick={() => setFinishedGoodsAssemblyCostsVisible(prev => !prev)}
        >
          <h5 style={styles.noMarginText}>Cost Of Parts That Make Up Assembly</h5>
        </div>
        {
          finishedGoodsAssemblyCostsVisible ?
            <div style={{ ...styles.closingRowContent, flexDirection: 'row' }}>
              <div style={{ flex: 1 }}>
                <h5>Option A</h5>
                <Table size="sm" style={{ marginBottom: 0 }}>
                  <tbody>
                  <tr>
                    <td style={styles.noCellBorder}>Molded Components</td>
                    <td style={styles.noCellBorder}>
                      {calculations?.totalMoldedComponentsA ? `$${calculations.totalMoldedComponentsA.toFixed(5)}` : ''}
                    </td>
                  </tr>
                  <tr>
                    <td style={styles.noCellBorder}>Carryover Nifco Parts</td>
                    <td style={styles.noCellBorder}>
                      {calculations?.totalCarryoverPartsCost ? `$${calculations.totalCarryoverPartsCost.toFixed(5)}` : ''}
                    </td>
                  </tr>
                  <tr>
                    <td style={styles.noCellBorder}>New Purchased, Passthrough, and Outsourced Parts</td>
                    <td style={styles.noCellBorder}>
                      {calculations?.totalPurchasedComponentsCost ? `$${calculations.totalPurchasedComponentsCost.toFixed(5)}` : ''}
                    </td>
                  </tr>
                  <tr>
                    <td style={styles.noCellBorder}>Total Components Cost</td>
                    <td style={styles.noCellBorder}>
                      {calculations?.totalComponentsCostA ? `$${calculations.totalComponentsCostA.toFixed(5)}` : ''}
                    </td>
                  </tr>
                  </tbody>
                </Table>
              </div>
              <div style={{ flex: 1 }}>
                {somePartHasOptionB ?
                  <>
                    <h5>Option B</h5>
                    <Table size="sm" style={{ marginBottom: 0 }}>
                      <tbody>
                      <tr>
                        <td style={styles.noCellBorder}>Molded Components</td>
                        <td style={styles.noCellBorder}>
                          {calculations?.totalMoldedComponentsB ? `$${calculations.totalMoldedComponentsB.toFixed(5)}` : ''}
                        </td>
                      </tr>
                      <tr>
                        <td style={styles.noCellBorder}>Carryover Nifco Parts</td>
                        <td style={styles.noCellBorder}>
                          {calculations?.totalCarryoverPartsCost ? `$${calculations.totalCarryoverPartsCost.toFixed(5)}` : ''}
                        </td>
                      </tr>
                      <tr>
                        <td style={styles.noCellBorder}>New Purchased, Passthrough, and Outsourced Parts</td>
                        <td style={styles.noCellBorder}>
                          {calculations?.totalPurchasedComponentsCost ? `$${calculations.totalPurchasedComponentsCost.toFixed(5)}` : ''}
                        </td>
                      </tr>
                      <tr>
                        <td style={styles.noCellBorder}>Total Components Cost</td>
                        <td style={styles.noCellBorder}>
                          {calculations?.totalComponentsCostB ? `$${calculations.totalComponentsCostB.toFixed(5)}` : ''}
                        </td>
                      </tr>
                      </tbody>
                    </Table>
                  </> :
                  null
                }
              </div>
            </div> :
            null
        }
      </div>
      <div style={styles.closingRow}>
        <div
          style={styles.closingRowHeader}
          onClick={() => setFinishedGoodsOtherCostsVisible(prev => !prev)}
        >
          <h5 style={styles.noMarginText}>Other Costs</h5>
        </div>
        {
          finishedGoodsOtherCostsVisible ?
            <div style={{ ...styles.closingRowContent, flexDirection: 'row' }}>
              <div style={{ flex: 1 }}>
                <h5>Option A</h5>
                <Table size="sm" style={{ marginBottom: 0 }}>
                  <tbody>
                  <tr>
                    <td style={styles.noCellBorder}>Total Assembly</td>
                    <td style={styles.noCellBorder}>
                      {calculations?.totalAssemblyCost ? `$${calculations.totalAssemblyCost.toFixed(5)}` : ''}
                    </td>
                  </tr>
                  <tr>
                    <td style={styles.noCellBorder}>Total Assembly Post Processing</td>
                    <td style={styles.noCellBorder}>
                      {calculations?.totalAssemblyPostProcessingCost ? `$${calculations.totalAssemblyPostProcessingCost.toFixed(5)}` : ''}
                    </td>
                  </tr>
                  <tr>
                    <td style={styles.noCellBorder}>Total Packaging</td>
                    <td style={styles.noCellBorder}>
                      {calculations?.totalPackagingCost ? `$${calculations.totalPackagingCost.toFixed(5)}` : ''}
                    </td>
                  </tr>
                  <tr>
                    <td style={styles.noCellBorder}>Total Other Cost</td>
                    <td style={styles.noCellBorder}>
                      {calculations?.totalOtherCost ? `$${calculations.totalOtherCost.toFixed(5)}` : ''}
                    </td>
                  </tr>
                  </tbody>
                </Table>
              </div>
              <div style={{ flex: 1 }}/>
            </div> :
            null
        }
      </div>
      <div style={styles.closingRow}>
        <div
          style={styles.closingRowHeader}
          onClick={() => setFinishedGoodsTotalCostsVisible(prev => !prev)}
        >
          <h5 style={styles.noMarginText}>Total Finished Good Cost</h5>
        </div>
        {
          finishedGoodsTotalCostsVisible ?
            <div style={{ ...styles.closingRowContent, flexDirection: 'row' }}>
              <div style={{ flex: 1 }}>
                <h5>Option A</h5>
                <Table size="sm" style={{ marginBottom: 0 }}>
                  <tbody>
                  <tr>
                    <td style={styles.noCellBorder}>Base Finish Good Cost</td>
                    <td style={styles.noCellBorder}>
                      {calculations?.baseFinishedGoodCostA ? `$${calculations.baseFinishedGoodCostA.toFixed(5)}` : ''}
                    </td>
                  </tr>
                  <tr>
                    <td style={styles.noCellBorder}>Administrative Cost</td>
                    <td style={styles.noCellBorder}>
                      {calculations?.administrativeCostA ? `$${calculations.administrativeCostA.toFixed(5)}` : ''}
                    </td>
                  </tr>
                  <tr>
                    <td style={styles.noCellBorder}>Technical Cost</td>
                    <td style={styles.noCellBorder}>
                      {calculations?.technicalCostA ? `$${calculations.technicalCostA.toFixed(5)}` : ''}
                    </td>
                  </tr>
                  <tr>
                    <td style={styles.noCellBorder}>Yield</td>
                    <td style={styles.noCellBorder}>
                      {calculations?.yieldCostA ? `$${calculations.yieldCostA.toFixed(5)}` : ''}
                    </td>
                  </tr>
                  <tr>
                    <td style={styles.noCellBorder}>Logistic Cost (R-Mex Freight)</td>
                    <td style={styles.noCellBorder}>
                      {calculations?.logisticCost ? `$${calculations.logisticCost.toFixed(5)}` : ''}
                    </td>
                  </tr>
                  <tr>
                    <td style={styles.noCellBorder}>Testing Cost (Depreciated)</td>
                    <td style={styles.noCellBorder}>
                      {calculations?.totalTestingDepreciatedCost ? `$${calculations.totalTestingDepreciatedCost.toFixed(5)}` : ''}
                    </td>
                  </tr>
                  <tr>
                    <td style={styles.noCellBorder}>Auxiliary Cost (Depreciated)</td>
                    <td style={styles.noCellBorder}>
                      {calculations?.totalAuxDepreciatedCost ? `$${calculations.totalAuxDepreciatedCost.toFixed(5)}` : ''}
                    </td>
                  </tr>
                  <tr>
                    <td style={styles.noCellBorder}>Standard Cost</td>
                    <td style={styles.noCellBorder}>
                      {calculations?.standardCostA ? `$${calculations.standardCostA.toFixed(5)}` : ''}
                    </td>
                  </tr>
                  </tbody>
                </Table>
              </div>
              <div style={{ flex: 1 }}>
                {somePartHasOptionB ?
                  <>
                    <h5>Option B</h5>
                    <Table size="sm" style={{ marginBottom: 0 }}>
                      <tbody>
                      <tr>
                        <td style={styles.noCellBorder}>Base Finish Good Cost</td>
                        <td style={styles.noCellBorder}>
                          {calculations?.baseFinishedGoodCostB ? `$${calculations.baseFinishedGoodCostB.toFixed(5)}` : ''}
                        </td>
                      </tr>
                      <tr>
                        <td style={styles.noCellBorder}>Administrative Cost</td>
                        <td style={styles.noCellBorder}>
                          {calculations?.administrativeCostB ? `$${calculations.administrativeCostB.toFixed(5)}` : ''}
                        </td>
                      </tr>
                      <tr>
                        <td style={styles.noCellBorder}>Technical Cost</td>
                        <td style={styles.noCellBorder}>
                          {calculations?.technicalCostB ? `$${calculations.technicalCostB.toFixed(5)}` : ''}
                        </td>
                      </tr>
                      <tr>
                        <td style={styles.noCellBorder}>Yield</td>
                        <td style={styles.noCellBorder}>
                          {calculations?.yieldCostB ? `$${calculations.yieldCostB.toFixed(5)}` : ''}
                        </td>
                      </tr>
                      <tr>
                        <td style={styles.noCellBorder}>Logistic Cost (R-Mex Freight)</td>
                        <td style={styles.noCellBorder}>
                          {calculations?.logisticCost ? `$${calculations.logisticCost.toFixed(5)}` : ''}
                        </td>
                      </tr>
                      <tr>
                        <td style={styles.noCellBorder}>Testing Cost (Depreciated)</td>
                        <td style={styles.noCellBorder}>
                          {calculations?.totalTestingDepreciatedCost ? `$${calculations.totalTestingDepreciatedCost.toFixed(5)}` : ''}
                        </td>
                      </tr>
                      <tr>
                        <td style={styles.noCellBorder}>Auxiliary Cost (Depreciated)</td>
                        <td style={styles.noCellBorder}>
                          {calculations?.totalAuxDepreciatedCost ? `$${calculations.totalAuxDepreciatedCost.toFixed(5)}` : ''}
                        </td>
                      </tr>
                      <tr>
                        <td style={styles.noCellBorder}>Standard Cost</td>
                        <td style={styles.noCellBorder}>
                          {calculations?.standardCostB ? `$${calculations.standardCostB.toFixed(5)}` : ''}
                        </td>
                      </tr>
                      </tbody>
                    </Table>
                  </> :
                  null
                }
              </div>
            </div> :
            null
        }
      </div>
    </>
  );
};

const styles = {
  noMarginText: {
    margin: 0,
  },
  closingRow: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    borderRadius: 3,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: COLORS.midGray,
    marginBottom: 7,
  },
  closingRowHeader: {
    backgroundColor: COLORS.midGray,
    color: COLORS.darkGray,
    cursor: 'pointer',
    padding: 7,
  },
  closingRowContent: {
    padding: 7,
    display: 'flex',
    flexDirection: 'column',
  },
  noCellBorder: {
    borderTopWidth: 0,
  },
};

export default QuoteTabFinishGood;