const colors = {
  paleOrange: '#F4A971',
  orange: '#EE7500',
  yellow: '#FFC008',
  green: '#28A745',
  blue: '#3D7BFF',
  red: '#DC3445',
  offWhite: '#F8F9FA',
  white: '#FFFFFF',
  offBlack: '#181818',
  black: '#000000',
  lightGray: '#B3B3B3',
  whiteGray: '#DEE2E6',
  midGray: '#909090',
  darkGray: '#363636',
  paleYellow: '#FAFAD2',
  paleGreen: '#D4EDDA',
  paleRed: '#F9D7DA',
  purple: '#712CF9',
  teal: '#46A2B8',
  brown: '#422906',
};

export default colors;