import COLORS from './colors';

const styles = {
  errorInput: {
    borderWidth: 1,
    borderColor: COLORS.red,
    borderStyle: 'solid',
  },
};

export default styles;