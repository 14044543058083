import axios from 'axios';
import * as CONFIG from '../config';

const BASE_URL = `https://cd8ayajsq7.execute-api.us-west-2.amazonaws.com/${CONFIG.ENVIRONMENT_TYPE === 'PRODUCTION' ? 'prod' : 'dev'}`;

export const login = async (username, password) => {
  const response = await axios.post(
    '/login',
    { userType: CONFIG.AUTH_DEFAULT_USER_TYPE, username, password },
    {
      headers: { 'x-api-key': CONFIG.APP_API_KEY },
      baseURL: BASE_URL,
    },
  );
  return response.status === 200 ? response.data : null;
};

export const refreshLogin = async (userId, refreshToken) => {
  const response = await axios.post(
    '/login',
    { userType: CONFIG.AUTH_DEFAULT_USER_TYPE, userId, refreshToken },
    {
      headers: { 'x-api-key': CONFIG.APP_API_KEY },
      baseURL: BASE_URL,
    },
  );
  return response.status === 200 ? response.data : null;
};

export const getAppRoles = async accessToken => {
  const response = await axios.get(
    '/roles',
    {
      headers: {
        'x-api-key': CONFIG.APP_API_KEY,
        Authorization: accessToken,
      },
      baseURL: BASE_URL,
    },
  );
  return response.status === 200 ? response?.data?.roles : null;
};

export const getInsightLov = async (accessToken, listId) => {
  const response = await axios.get(
    `/insight/lov/${listId}`,
    {
      headers: {
        'x-api-key': CONFIG.APP_API_KEY,
        Authorization: accessToken,
      },
      baseURL: BASE_URL,
    },
  );
  return response.status === 200 ? response?.data?.lov : null;
};

export const getInsightPrograms = async accessToken => {
  const response = await axios.get(
    `/insight/programs`,
    {
      headers: {
        'x-api-key': CONFIG.APP_API_KEY,
        Authorization: accessToken,
      },
      baseURL: BASE_URL,
    },
  );
  if(response.status !== 200) return;
  const data = response?.data?.body ? JSON.parse(response.data.body) : response.data;
  return data.programs;
};

export const updateQuote = async (accessToken, quoteId, revision, quoteObj, userId, username) => {
  const response = await axios.put(
    `/insight/quotes/${quoteId}_${revision}`,
    {
      ...quoteObj,
      holdUserId: null,
      holdUsername: null,
      holdTime: null,
      lastUpdatedByUserId: userId,
      lastUpdatedByUsername: username,
    },
    {
      headers: {
        'x-api-key': CONFIG.APP_API_KEY,
        Authorization: accessToken,
      },
      baseURL: BASE_URL,
    },
  );
  return response.status === 200 ? response.data : null;
};

export const updateSandboxQuote = async (accessToken, quoteId, revision, quoteObj, userId, username) => {
  const response = await axios.put(
    `/insight/quotes/${quoteId}_${revision}/sandbox`,
    {
      ...quoteObj,
      holdUserId: null,
      holdUsername: null,
      holdTime: null,
      lastUpdatedByUserId: userId,
      lastUpdatedByUsername: username,
    },
    {
      headers: {
        'x-api-key': CONFIG.APP_API_KEY,
        Authorization: accessToken,
      },
      baseURL: BASE_URL,
    },
  );
  return response.status === 200 ? response.data : null;
};

export const releaseQuoteHold = async (accessToken, quoteId, revision, quoteObj, userId, username) => {
  const response = await axios.put(
    `/insight/quotes/${quoteId}_${revision}`,
    {
      quoteNumber: quoteObj.quoteNumber,
      createdTime: quoteObj.createdTime,
      holdUserId: null,
      holdUsername: null,
      holdTime: null,
      lastUpdatedByUserId: userId,
      lastUpdatedByUsername: username,
    },
    {
      headers: {
        'x-api-key': CONFIG.APP_API_KEY,
        Authorization: accessToken,
      },
      baseURL: BASE_URL,
    },
  );
  return response.status === 200 ? response.data : null;
};

export const requestQuoteHold = async (accessToken, quoteId, revision, userId, username) => {
  const response = await axios.put(
    `/insight/quotes/${quoteId}_${revision}/request-hold`,
    { userId, username },
    {
      headers: {
        'x-api-key': CONFIG.APP_API_KEY,
        Authorization: accessToken,
      },
      baseURL: BASE_URL,
    },
  );
  return response.status === 200 ? response.data : null;
};

export const getQuoteById = async (accessToken, quoteId, revision) => {
  const response = await axios.get(
    `/insight/quotes/${quoteId}_${revision}`,
    {
      headers: {
        'x-api-key': CONFIG.APP_API_KEY,
        Authorization: accessToken,
      },
      baseURL: BASE_URL,
    },
  );
  if(response.status !== 200) return;
  const data = response?.data?.body ? JSON.parse(response.data.body) : response.data;
  return data.quote;
};

export const getQuoteByIdOnly = async (accessToken, quoteId) => {
  const response = await axios.get(
    `/insight/quotes/${quoteId}`,
    {
      headers: {
        'x-api-key': CONFIG.APP_API_KEY,
        Authorization: accessToken,
      },
      baseURL: BASE_URL,
    },
  );
  if(response.status !== 200) return;
  const data = response?.data?.body ? JSON.parse(response.data.body) : response.data;
  return data.quote;
};

export const getSandboxQuoteById = async (accessToken, quoteId, revision, userId) => {
  const response = await axios.get(
    `/insight/quotes/${quoteId}_${revision}/sandbox`,
    {
      headers: {
        'x-api-key': CONFIG.APP_API_KEY,
        Authorization: accessToken,
      },
      params: { userId },
      baseURL: BASE_URL,
    },
  );
  if(response.status !== 200) return;
  const data = response?.data?.body ? JSON.parse(response.data.body) : response.data;
  return data.quote;
};

export const assignQuote = async (accessToken, quoteId, revision, userData) => {
  const response = await axios.put(
    `/insight/quotes/${quoteId}_${revision}/assignments`,
    userData,
    {
      headers: {
        'x-api-key': CONFIG.APP_API_KEY,
        Authorization: accessToken,
      },
      baseURL: BASE_URL,
    },
  );
  return response.status === 200 ? response.data : null;
};

export const getOwnedCreatedQuotes = (accessToken, userId) => searchQuotes(accessToken, {
  searchType: 'DASHBOARD',
  owner: userId,
  assignment: userId,
});

export const getQuotesByAccountManager = (accessToken, userId, oaSearchType) => searchQuotes(accessToken, {
  searchType: 'ACCOUNT_MANAGER',
  owner: userId,
  oaSearchType,
});

export const getQuotesByCostEstimator = (accessToken, userId, oaSearchType) => searchQuotes(accessToken, {
  searchType: 'COST_ESTIMATOR',
  assignment: userId,
  oaSearchType,
});

export const getAllOpenQuotes = accessToken => searchQuotes(accessToken, { searchType: 'ALL_OPEN' });

export const getQuotesByQuoteNumber = (accessToken, quoteNumber) => searchQuotes(accessToken, {
  searchType: 'QUOTE_NUMBER',
  quoteNumber,
});

export const getQuotesByQuoteNumberRevision = (accessToken, quoteNumber, revision) => searchQuotes(accessToken, {
  searchType: 'QUOTE_NUMBER',
  quoteNumber,
  revision,
});

export const getQuotesByStatus = (accessToken, quoteStatus) => searchQuotes(accessToken, {
  searchType: 'QUOTE_STATUS',
  quoteStatus,
});

export const getQuotesByDepartment = (accessToken, department) => searchQuotes(accessToken, {
  searchType: 'DEPARTMENT',
  department,
});

export const getQuotesByCustomerPart = (accessToken, customerPart) => searchQuotes(accessToken, {
  searchType: 'CUSTOMER_PART',
  customerPart,
});

export const getQuotesByCustomerName = async (accessToken, customerName) => {
  let items = [], startKey = null;
  while(true) {
    const params = {
      searchType: 'CUSTOMER_NAME',
      customerName,
    };
    if(startKey) params.nextKey = startKey;
    const response = await axios.get(
      `/insight/quotes`,
      {
        headers: {
          'x-api-key': CONFIG.APP_API_KEY,
          Authorization: accessToken,
        },
        baseURL: BASE_URL,
        params,
      },
    );
    if(response.status !== 200) return;
    const data = response?.data?.body ? JSON.parse(response.data.body) : response.data;
    if(data.quotes?.length) items.push(...data.quotes);
    if(data.nextToken) startKey = data.nextToken;
    else break;
  }
  return items;
};

export const getQuotesByCustomerNamePart = (accessToken, customerName, customerPart) => searchQuotes(accessToken, {
  searchType: 'CUSTOMER_NAME',
  customerName,
  customerPart,
});

export const getQuotesByPartDescription = (accessToken, partDescription) => searchQuotes(accessToken, {
  searchType: 'PART_DESCRIPTION',
  partDescription,
});

const searchQuotes = async (accessToken, params) => {
  if(!params.searchType) {
    console.error('No searchType in request!');
    return [];
  }
  const response = await axios.get(
    `/insight/quotes`,
    {
      headers: {
        'x-api-key': CONFIG.APP_API_KEY,
        Authorization: accessToken,
      },
      baseURL: BASE_URL,
      params,
    },
  );
  if(response.status !== 200) return;
  const data = response?.data?.body ? JSON.parse(response.data.body) : response.data;
  return data.quotes;
};

export const getQuoteAssignments = async (accessToken, quoteId, revision) => {
  const response = await axios.get(
    `/insight/quotes/${quoteId}_${revision}/assignments`,
    {
      headers: {
        'x-api-key': CONFIG.APP_API_KEY,
        Authorization: accessToken,
      },
      baseURL: BASE_URL,
    },
  );
  if(response.status !== 200) return;
  const data = response?.data?.body ? JSON.parse(response.data.body) : response.data;
  return data.assignments;
};

export const createQuoteComment = async (accessToken, quoteId, revision, commentType, comment, username) => {
  const response = await axios.post(
    `/insight/quotes/${quoteId}_${revision}/comments`,
    { commentType, comment, username },
    {
      headers: {
        'x-api-key': CONFIG.APP_API_KEY,
        Authorization: accessToken,
      },
      baseURL: BASE_URL,
    },
  );
  return response.status === 200 ? response?.data?.quoteComment : null;
};

export const createQuoteActivityLog = async (accessToken, quoteId, revision, activityType, message, username) => {
  const response = await axios.post(
    `/insight/quotes/${quoteId}_${revision}/activity-logs`,
    { activityType, message, username },
    {
      headers: {
        'x-api-key': CONFIG.APP_API_KEY,
        Authorization: accessToken,
      },
      baseURL: BASE_URL,
    },
  );
  return response.status === 200 ? response?.data?.quoteActivity : null;
};

export const getQuoteComments = async (accessToken, quoteId) => {
  let items = [], startToken = null;
  while(true) {
    const params = {};
    if(startToken) params.nextToken = startToken;
    const response = await axios.get(
      `/insight/quotes/${quoteId}/comments`,
      {
        headers: {
          'x-api-key': CONFIG.APP_API_KEY,
          Authorization: accessToken,
        },
        baseURL: BASE_URL,
        params,
      },
    );
    if(response.status !== 200) return;
    const data = response?.data?.body ? JSON.parse(response.data.body) : response.data;
    items.push(...(data.comments ? data.comments : []));
    if(data.nextToken) startToken = data.nextToken;
    else break;
  }
  return items;
};

export const getQuoteActivityLogs = async (accessToken, quoteId) => {
  let items = [], startToken = null;
  while(true) {
    const params = {};
    if(startToken) params.nextToken = startToken;
    const response = await axios.get(
      `/insight/quotes/${quoteId}/activity-logs`,
      {
        headers: {
          'x-api-key': CONFIG.APP_API_KEY,
          Authorization: accessToken,
        },
        baseURL: BASE_URL,
        params,
      },
    );
    if(response.status !== 200) return;
    const data = response?.data?.body ? JSON.parse(response.data.body) : response.data;
    items.push(...(data.activityLogs ? data.activityLogs : []));
    if(data.nextToken) startToken = data.nextToken;
    else break;
  }
  return items;
};

export const getUserWithRoleLov = async (accessToken, roleName) => {
  const response = await axios.get(
    `/insight/users`,
    {
      headers: {
        'x-api-key': CONFIG.APP_API_KEY,
        Authorization: accessToken,
      },
      baseURL: BASE_URL,
      params: { role: roleName },
    },
  );
  if(response.status !== 200) return;
  const data = response?.data?.body ? JSON.parse(response.data.body) : response.data;
  return data.users;
};

export const getRates = async (accessToken, refTime) => {
  const response = await axios.get(
    `/insight/rates`,
    {
      headers: {
        'x-api-key': CONFIG.APP_API_KEY,
        Authorization: accessToken,
      },
      baseURL: BASE_URL,
      params: { time: refTime },
    },
  );
  if(response.status !== 200) return;
  const data = response?.data?.body ? JSON.parse(response.data.body) : response.data;
  return data.rates;
};

export const getIsQuoteCurrentRevision = async (accessToken, quoteId, revision) => {
  const response = await axios.get(
    `/insight/quotes/${quoteId}_${revision}/is-current`,
    {
      headers: {
        'x-api-key': CONFIG.APP_API_KEY,
        Authorization: accessToken,
      },
      baseURL: BASE_URL,
    },
  );
  if(response.status !== 200) return;
  const data = response?.data?.body ? JSON.parse(response.data.body) : response.data;
  return data.isCurrent;
};

export const getMaterials = async (accessToken, materialNumber, materialDescription) => {
  if((!materialNumber || !materialNumber.trim().length) && (!materialDescription || !materialDescription.trim().length))
    return [];
  const params = {};
  if(materialNumber && materialNumber.trim().length) params.number = materialNumber.trim();
  if(materialDescription && materialDescription.trim().length) params.description = materialDescription.trim();
  const response = await axios.get(
    `/insight/materials`,
    {
      headers: {
        'x-api-key': CONFIG.APP_API_KEY,
        Authorization: accessToken,
      },
      baseURL: BASE_URL,
      params,
    },
  );
  if(response.status !== 200) return;
  const data = response?.data?.body ? JSON.parse(response.data.body) : response.data;
  return data.materials;
};

export const getPurchasedParts = async (accessToken, partNumber, partDescription, orgCode, partType) => {
  if((!partNumber || !partNumber.trim().length) && (!partDescription || !partDescription.trim().length) &&
    (!orgCode || !orgCode.trim().length) && (!partType || !partType.trim().length))
    return [];
  const params = {};
  if(partNumber && partNumber.trim().length) params.number = partNumber.trim();
  if(partDescription && partDescription.trim().length) params.description = partDescription.trim();
  if(orgCode && orgCode.trim().length) params.org = orgCode.trim();
  if(partType && partType.trim().length) params.type = partType.trim();
  const response = await axios.get(
    `/insight/purchased-parts`,
    {
      headers: {
        'x-api-key': CONFIG.APP_API_KEY,
        Authorization: accessToken,
      },
      baseURL: BASE_URL,
      params,
    },
  );
  if(response.status !== 200) return;
  const data = response?.data?.body ? JSON.parse(response.data.body) : response.data;
  return data.purchasedParts;
};

export const getCarryoverParts = async (accessToken, partNumber, partDescription, orgCode) => {
  if((!partNumber || !partNumber.trim().length) && (!partDescription || !partDescription.trim().length) &&
    (!orgCode || !orgCode.trim().length))
    return [];
  const params = {};
  if(partNumber && partNumber.trim().length) params.number = partNumber.trim();
  if(partDescription && partDescription.trim().length) params.description = partDescription.trim();
  if(orgCode && orgCode.trim().length) params.org = orgCode.trim();
  const response = await axios.get(
    `/insight/carryover-parts`,
    {
      headers: {
        'x-api-key': CONFIG.APP_API_KEY,
        Authorization: accessToken,
      },
      baseURL: BASE_URL,
      params,
    },
  );
  if(response.status !== 200) return;
  const data = response?.data?.body ? JSON.parse(response.data.body) : response.data;
  return data.carryoverParts;
};

export const getPackagingCosts = async (accessToken, partNumber, partDescription) => {
  if((!partNumber || !partNumber.trim().length) && (!partDescription || !partDescription.trim().length))
    return [];
  const params = {};
  if(partNumber && partNumber.trim().length) params.number = partNumber.trim().toUpperCase();
  if(partDescription && partDescription.trim().length) params.description = partDescription.trim();
  const response = await axios.get(
    `/insight/packaging-costs`,
    {
      headers: {
        'x-api-key': CONFIG.APP_API_KEY,
        Authorization: accessToken,
      },
      baseURL: BASE_URL,
      params,
    },
  );
  if(response.status !== 200) return;
  const data = response?.data?.body ? JSON.parse(response.data.body) : response.data;
  return data.packagingCosts;
};

export const uploadAttachedDocument = async (url, fileData, fileType) => {
  const response = await axios.put(url, fileData, { headers: { 'Content-Type': fileType } });
  return response.status === 200 ? response.data : null;
};

export const createAttachmentRecord = async (accessToken, quoteId, revision, filename, fileType, description, userId, username, parentRevision) => {
  const response = await axios.post(
    `/insight/quotes/${quoteId}_${revision}/attachments`,
    { filename, fileType, description, userId, username, parentRevision },
    {
      headers: {
        'x-api-key': CONFIG.APP_API_KEY,
        Authorization: accessToken,
      },
      baseURL: BASE_URL,
    },
  );
  if(response.status !== 200) return;
  const data = response?.data?.body ? JSON.parse(response.data.body) : response.data;
  return data.quoteAttachment;
};

export const getUploadUrl = async (accessToken, quoteId, revision, filename, type) => {
  const response = await axios.get(
    `/insight/quotes/${quoteId}_${revision}/attachments/url`,
    {
      headers: {
        'x-api-key': CONFIG.APP_API_KEY,
        Authorization: accessToken,
      },
      params: {
        action: 'PUT',
        filename,
        type,
      },
      baseURL: BASE_URL,
    },
  );
  if(response.status !== 200) return;
  const data = response?.data?.body ? JSON.parse(response.data.body) : response.data;
  return data.url;
};

export const getDownloadUrl = async (accessToken, quoteId, revision, filename) => {
  const response = await axios.get(
    `/insight/quotes/${quoteId}_${revision}/attachments/url`,
    {
      headers: {
        'x-api-key': CONFIG.APP_API_KEY,
        Authorization: accessToken,
      },
      params: {
        action: 'GET',
        filename,
      },
      baseURL: BASE_URL,
    },
  );
  if(response.status !== 200) return;
  const data = response?.data?.body ? JSON.parse(response.data.body) : response.data;
  return data.url;
};

export const getAttachmentRecords = async (accessToken, quoteId, revision) => {
  const response = await axios.get(
    `/insight/quotes/${quoteId}_${revision}/attachments`,
    {
      headers: {
        'x-api-key': CONFIG.APP_API_KEY,
        Authorization: accessToken,
      },
      baseURL: BASE_URL,
    },
  );
  if(response.status !== 200) return;
  const data = response?.data?.body ? JSON.parse(response.data.body) : response.data;
  return data.attachments;
};

export const deleteAttachment = async (accessToken, quoteId, revision, filename) => {
  const response = await axios.delete(
    `/insight/quotes/${quoteId}_${revision}/attachments/${filename}`,
    {
      headers: {
        'x-api-key': CONFIG.APP_API_KEY,
        Authorization: accessToken,
      },
      baseURL: BASE_URL,
    },
  );
  if(response.status !== 200) return;
  return response?.data?.body ? JSON.parse(response.data.body) : response.data;
};

export const deleteSandboxQuote = async (accessToken, quoteId, revision, userId) => {
  const response = await axios.delete(
    `/insight/quotes/${quoteId}_${revision}/sandbox`,
    {
      headers: {
        'x-api-key': CONFIG.APP_API_KEY,
        Authorization: accessToken,
      },
      params: { userId },
      baseURL: BASE_URL,
    },
  );
  if(response.status !== 200) return;
  return response?.data?.body ? JSON.parse(response.data.body) : response.data;
};

export const getAllIhsProgramData = async (accessToken, type) => {
  const response = await axios.get(
    `/product-manager/programs/ihs`,
    {
      headers: {
        'x-api-key': CONFIG.APP_API_KEY,
        Authorization: accessToken,
      },
      baseURL: BASE_URL,
      params: { type },
    },
  );
  if(response.status !== 200) return;
  const data = response?.data?.body ? JSON.parse(response.data.body) : response.data;
  return data?.data;
};

export const deleteQuoteComment = async (accessToken, quoteId, revision, commentTime) => {
  const response = await axios.delete(
    `/insight/quotes/${quoteId}_${revision}/comments/${commentTime}`,
    {
      headers: {
        'x-api-key': CONFIG.APP_API_KEY,
        Authorization: accessToken,
      },
      baseURL: BASE_URL,
    },
  );
  if(response.status !== 200) throw new Error(`deleteComment failed with HTTP code ${response.status}`);
};

export const getQuoteNewPartNumbers = async (accessToken, quoteId, revision) => {
  const response = await axios.get(
    `/insight/quotes/${quoteId}_${revision}/new-part-numbers`,
    {
      headers: {
        'x-api-key': CONFIG.APP_API_KEY,
        Authorization: accessToken,
      },
      baseURL: BASE_URL,
    },
  );
  if(response.status !== 200) return;
  const data = response?.data?.body ? JSON.parse(response.data.body) : response.data;
  return data.newParts;
};

export const assignQuoteNewPartNumbers = async (accessToken, quoteId, revision, newParts) => {
  const response = await axios.post(
    `/insight/quotes/${quoteId}_${revision}/new-part-numbers`,
    { newParts },
    {
      headers: {
        'x-api-key': CONFIG.APP_API_KEY,
        Authorization: accessToken,
      },
      baseURL: BASE_URL,
    },
  );
  if(response.status !== 200) return;
  const data = response?.data?.body ? JSON.parse(response.data.body) : response.data;
  return data.newParts;
};