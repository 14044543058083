import { useState, useEffect } from 'react';
import COLORS from '../styles/colors';
import { Form, Button } from 'react-bootstrap';
import logo from '../assets/nifco_logo.png';
import * as CONFIG from '../config';
import {
  loadingMessageSelector,
  accessTokenAtom,
  refreshTokenAtom,
  refreshExpiryAtom,
  accessExpiryAtom,
  userIdAtom,
  loggedInUserAtom,
  quoteProcessorAtom,
  quoteDataAtom,
  currentViewSelector,
  showPrintPageAtom,
} from '../state';
import { getDataString, getDataInt, deleteData } from '../helpers/storage';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { login, refreshLogin } from '../helpers/api';
import Main from './Main';
import { timeToLogout } from '../helpers/misc';
import QuoteProcessor from '../helpers/QuoteProcessor';

const quoteProcessorInstance = new QuoteProcessor();

const Login = () => {
  const [loginError, setLoginError] = useState(null);
  const [usernameValue, setUsernameValue] = useState('');
  const [passwordValue, setPasswordValue] = useState('');
  const [loadingMessage, setLoadingMessage] = useRecoilState(loadingMessageSelector);
  const [quoteProcessor, setQuoteProcessor] = useRecoilState(quoteProcessorAtom);
  const setUserId = useSetRecoilState(userIdAtom);
  const [accessToken, setAccessToken] = useRecoilState(accessTokenAtom);
  const setRefreshToken = useSetRecoilState(refreshTokenAtom);
  const setAccessExpiry = useSetRecoilState(accessExpiryAtom);
  const setRefreshExpiry = useSetRecoilState(refreshExpiryAtom);
  const setLoggedInUser = useSetRecoilState(loggedInUserAtom);
  const setQuoteData = useSetRecoilState(quoteDataAtom);
  const setCurrentView = useSetRecoilState(currentViewSelector);
  const setShowPrintPage = useSetRecoilState(showPrintPageAtom);
  
  useEffect(() => {
    setQuoteProcessor(quoteProcessorInstance);
  }, [setQuoteProcessor]);
  
  // check for local stored credentials
  useEffect(() => {
    const now = Math.floor(Date.now() / 1000);
    const userIdLocal = getDataString('userId'),
      accessTokenLocal = getDataString('accessToken'),
      refreshTokenLocal = getDataString('refreshToken'),
      accessExpiryLocal = getDataInt('accessExpiry'),
      refreshExpiryLocal = getDataInt('refreshExpiry'),
      loggedInUserLocal = getDataString('loggedInUser');
    if(!userIdLocal || !accessTokenLocal || !refreshTokenLocal || !accessExpiryLocal || !refreshExpiryLocal
      || !loggedInUserLocal || (accessExpiryLocal < now && refreshExpiryLocal < now)) {
      deleteData('userId');
      deleteData('accessToken');
      deleteData('refreshToken');
      deleteData('accessExpiry');
      deleteData('refreshExpiry');
      deleteData('loggedInUser');
    }
    else if(accessExpiryLocal < now && refreshExpiryLocal > now) {
      const refresh = async () => {
        try {
          setLoadingMessage('Initializing');
          const authData = await refreshLogin(userIdLocal, refreshTokenLocal);
          if(!authData || !authData.userId || !authData.accessToken || !authData.refreshToken ||
            !authData.accessExpiry || !authData.refreshExpiry || !authData.user) {
            deleteData('userId');
            deleteData('accessToken');
            deleteData('refreshToken');
            deleteData('accessExpiry');
            deleteData('refreshExpiry');
            deleteData('loggedInUser');
            return;
          }
          setUserId(authData.userId);
          setAccessToken(authData.accessToken);
          setRefreshToken(authData.refreshToken);
          setAccessExpiry(authData.accessExpiry);
          setRefreshExpiry(authData.refreshExpiry);
          setLoggedInUser(authData.user);
        }
        finally {
          setLoadingMessage(null);
        }
      };
      refresh();
    }
    else {
      setUserId(userIdLocal);
      setAccessToken(accessTokenLocal);
      setRefreshToken(refreshTokenLocal);
      setAccessExpiry(accessExpiryLocal);
      setRefreshExpiry(refreshExpiryLocal);
      setLoggedInUser(loggedInUserLocal);
    }
  }, [
    setUserId,
    setAccessToken,
    setRefreshToken,
    setAccessExpiry,
    setRefreshExpiry,
    setLoadingMessage,
    setLoggedInUser,
  ]);
  
  // credential checker interval
  useEffect(() => {
    const clearAuth = () => {
      quoteProcessor.clearAllData();
      setQuoteData(null);
      setShowPrintPage(false);
      setCurrentView('SEARCH_QUOTES');
      setUserId(null);
      setAccessToken(null);
      setRefreshToken(null);
      setAccessExpiry(null);
      setRefreshExpiry(null);
      setLoggedInUser(null);
    };
    const checkerInterval = setInterval(() => {
      const now = Math.floor(Date.now() / 1000);
      const userIdLocal = getDataString('userId'),
        accessTokenLocal = getDataString('accessToken'),
        refreshTokenLocal = getDataString('refreshToken'),
        accessExpiryLocal = getDataInt('accessExpiry'),
        refreshExpiryLocal = getDataInt('refreshExpiry'),
        loggedInUserLocal = getDataString('loggedInUser');
      if(userIdLocal && refreshTokenLocal && accessExpiryLocal && refreshExpiryLocal && accessTokenLocal
        && loggedInUserLocal) {
        if(accessExpiryLocal < now) {
          if(refreshExpiryLocal > now && !CONFIG.DISABLE_RE_AUTHENTICATION) {
            refreshLogin(userIdLocal, refreshTokenLocal)
              .then(authData => {
                if(!authData || !authData.userId || !authData.accessToken || !authData.refreshToken ||
                  !authData.accessExpiry || !authData.refreshExpiry || !authData.user) {
                  clearAuth();
                  return;
                }
                setUserId(authData.userId);
                setAccessToken(authData.accessToken);
                setRefreshToken(authData.refreshToken);
                setAccessExpiry(authData.accessExpiry);
                setRefreshExpiry(authData.refreshExpiry);
                setLoggedInUser(authData.user);
              })
              .catch(e => {
                console.error('Could not background refresh API credentials ->', e);
                clearAuth();
              });
          }
          else clearAuth();
        }
      }
      else {
        clearAuth();
      }
    }, 30000);
    return () => clearInterval(checkerInterval);
  }, [
    setUserId,
    setAccessToken,
    setRefreshToken,
    setAccessExpiry,
    setRefreshExpiry,
    setLoggedInUser,
    quoteProcessor,
    setQuoteData,
    setCurrentView,
    setShowPrintPage,
  ]);
  
  // no activity checker interval
  useEffect(() => {
    let checkerInterval;
    if(CONFIG.NO_ACTIVITY_TIMEOUT) {
      checkerInterval = setInterval(() => {
        if(timeToLogout(CONFIG.NO_ACTIVITY_TIMEOUT)) {
          quoteProcessor.clearAllData();
          setQuoteData(null);
          setShowPrintPage(false);
          setCurrentView('SEARCH_QUOTES');
          setUserId(null);
          setAccessToken(null);
          setRefreshToken(null);
          setAccessExpiry(null);
          setRefreshExpiry(null);
          setLoggedInUser(null);
        }
      }, 10000);
    }
    if(checkerInterval) return () => clearInterval(checkerInterval);
  }, [
    setUserId,
    setAccessToken,
    setRefreshToken,
    setAccessExpiry,
    setRefreshExpiry,
    setLoggedInUser,
    quoteProcessor,
    setQuoteData,
    setCurrentView,
    setShowPrintPage,
  ]);
  
  const authenticate = async event => {
    event.preventDefault();
    setLoginError(false);
    try {
      setLoadingMessage('Authenticating');
      const authData = await login(usernameValue, passwordValue);
      if(!authData || !authData.userId || !authData.accessToken || !authData.refreshToken || !authData.accessExpiry ||
        !authData.refreshExpiry || !authData.user) {
        setLoginError(true);
        return;
      }
      setUserId(authData.userId);
      setAccessToken(authData.accessToken);
      setRefreshToken(authData.refreshToken);
      setAccessExpiry(authData.accessExpiry);
      setRefreshExpiry(authData.refreshExpiry);
      setLoggedInUser(authData.user);
      setUsernameValue('');
      setPasswordValue('');
    }
    catch(e) {
      setLoginError(true);
      console.error('login error ->', e);
    }
    finally {
      setLoadingMessage(null);
    }
  };
  
  if(!accessToken) return (
    <div style={styles.mainContainer}>
      <img
        src={logo}
        alt="NIFCO"
        width="284"
        height="132"
        style={styles.logo}
      />
      <p style={styles.appTitle}>{`${CONFIG.APP_DISPLAY_NAME} v${process.env.REACT_APP_VERSION.replace('-', '')}`}</p>
      <Form style={styles.form}>
        <Form.Group>
          <Form.Control
            type="text"
            style={{
              ...styles.textInput,
              border: loginError ? `1px ${COLORS.red} solid` : null,
            }}
            placeholder="Enter username"
            value={usernameValue}
            onChange={e => setUsernameValue(e.target.value)}
            disabled={loadingMessage != null}
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            type="password"
            style={{
              ...styles.textInput,
              border: loginError ? `1px ${COLORS.red} solid` : null,
            }}
            placeholder="Enter password"
            value={passwordValue}
            onChange={e => setPasswordValue(e.target.value)}
            disabled={loadingMessage != null}
          />
        </Form.Group>
        <Button
          variant="warning"
          type="submit"
          onClick={authenticate}
          disabled={loadingMessage != null}
          style={styles.button}
          className="button-orange"
        >
          LOGIN
        </Button>
      </Form>
    </div>
  );
  else return <Main/>;
};

const styles = {
  mainContainer: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '100px 10px 0 10px',
    margin: 0,
    backgroundColor: COLORS.offWhite,
    color: COLORS.offBlack,
  },
  appTitle: {
    fontStyle: 'italic',
    fontWeight: 'bold',
    fontSize: 20,
    marginBottom: 30,
  },
  logo: {
    marginBottom: 15,
  },
  form: {
    width: 280,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
  },
  redBorder: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: COLORS.red,
  },
  textInput: {
    marginBottom: 15,
  },
  button: {
    fontWeight: 'bold',
  },
};

export default Login;