import axios from 'axios';
import * as CONFIG from '../config';

const BASE_URL = `https://cd8ayajsq7.execute-api.us-west-2.amazonaws.com/${CONFIG.ENVIRONMENT_TYPE === 'PRODUCTION' ? 'prod' : 'dev'}`;

export default class Logger {
  #stream = null;
  #seq = null;
  
  constructor() {
    const savedStream = localStorage.getItem(`LOG_STREAM|${CONFIG.APP_API_KEY}`);
    const savedSequenceNum = localStorage.getItem(`LOG_STREAM_SEQ|${CONFIG.APP_API_KEY}`);
    if(savedStream && savedSequenceNum) {
      this.#stream = savedStream;
      this.#seq = savedSequenceNum;
    }
  }
  
  log(accessToken, message, userId, userDisplayName) {
    axios.post(
      `/logs/${CONFIG.APP_API_KEY}`,
      { stream: this.#stream, seq: this.#seq, message, userId, userDisplayName },
      {
        headers: {
          'x-api-key': CONFIG.APP_API_KEY,
          Authorization: accessToken,
        },
        baseURL: BASE_URL,
      },
    ).then(response => {
      if(response.status !== 200) throw new Error('postLog error -> log not posted');
      else {
        const data = response?.data?.body ? JSON.parse(response.data.body) : response.data;
        if(data && data.stream && data.nextSequenceToken) {
          this.#stream = data.stream;
          this.#seq = data.nextSequenceToken;
          localStorage.setItem(`LOG_STREAM|${CONFIG.APP_API_KEY}`, data.stream);
          localStorage.setItem(`LOG_STREAM_SEQ|${CONFIG.APP_API_KEY}`, data.nextSequenceToken);
        }
        if(CONFIG.ENVIRONMENT_TYPE !== 'PRODUCTION') console.log(`Logger.log -> message posted at ${data.timestamp}`);
      }
    }).catch(e => {
      console.error('Log posting error ->', e);
      localStorage.removeItem(`LOG_STREAM|${CONFIG.APP_API_KEY}`);
      localStorage.removeItem(`LOG_STREAM_SEQ|${CONFIG.APP_API_KEY}`);
      this.#stream = null;
      this.#seq = null;
    });
  }
}

