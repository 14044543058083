import ToolbarButton from './ToolbarButton';
import nifcoLogo from '../assets/nifco_logo_no_tag.png';
import * as CONFIG from '../config';
import {
  windowWidthAtom,
  currentViewSelector,
  loggedInUserAtom,
  userIdAtom,
  accessTokenAtom,
  refreshTokenAtom,
  accessExpiryAtom,
  refreshExpiryAtom,
  appRolesAtom,
  quoteDataAtom,
  loadingMessageSelector,
  saveQuoteTriggerAtom,
  quoteProcessorAtom,
  isSubmittingQuoteAtom,
  quoteMissingAttributesAtom,
  isPreFeasibilityApprovingQuoteAtom,
  isPreFeasibilityRejectingQuoteAtom,
  isCreatingRevisionQuoteAtom,
  isCreatingSandboxQuoteAtom,
  isSubmittingEstimationAtom,
  isSendingQuoteBackToEstimationAtom,
  isSigningOffFeasibilityAtom,
  isSendingBackToFeasibilityAtom,
  feasibilitySelectAtom,
  isApprovingSalesCheckAtom,
  quoteStatusSelectAtom,
  previousViewAtom,
  isInheritingAttachmentsAtom,
  quoteAttachmentsAtom,
  showPrintPageAtom,
  showInvestPageAtom,
  isReassigningQuoteAtom,
  isPromotingQuoteAtom,
  isTakingBackPurchasingEstimationAtom,
  isTakingBackToolingEstimationAtom,
  isTakingBackManufacturingEstimationAtom,
  isTakingBackDesignEstimationAtom,
  initValuesAtom,
} from '../state';
import { useRecoilValue, useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import {
  faEye,
  faSearch,
  faPlusSquare,
  faSignOutAlt,
  faSave,
  faTimesCircle,
  faPrint,
  faEdit,
  faChartLine,
} from '@fortawesome/free-solid-svg-icons';
import COLORS from '../styles/colors';
import { v4 as uuid } from 'uuid';
import { useAlert } from 'react-alert';
import Logger from '../helpers/Logger';
import { createAttachmentRecord, requestQuoteHold, releaseQuoteHold } from '../helpers/api';
import { useEffect, useState } from 'react';
import WebSocketClient from '../helpers/WebSocketClient';
import { DateTime } from 'luxon';
import Modal from 'react-modal';
import { Button } from 'react-bootstrap';

const logger = new Logger();

const Toolbar = () => {
  const windowWidth = useRecoilValue(windowWidthAtom);
  const loggedInUser = useRecoilValue(loggedInUserAtom);
  const [isCreatingRevisionQuote, setIsCreatingRevisionQuote] = useRecoilState(isCreatingRevisionQuoteAtom);
  const [isCreatingSandboxQuote, setIsCreatingSandboxQuote] = useRecoilState(isCreatingSandboxQuoteAtom);
  const [currentView, setCurrentView] = useRecoilState(currentViewSelector);
  const [userId, setUserId] = useRecoilState(userIdAtom);
  const [accessToken, setAccessToken] = useRecoilState(accessTokenAtom);
  const quoteProcessor = useRecoilValue(quoteProcessorAtom);
  const setRefreshToken = useSetRecoilState(refreshTokenAtom);
  const setAccessExpiry = useSetRecoilState(accessExpiryAtom);
  const setRefreshExpiry = useSetRecoilState(refreshExpiryAtom);
  const setLoggedInUser = useSetRecoilState(loggedInUserAtom);
  const [quoteData, setQuoteData] = useRecoilState(quoteDataAtom);
  const [isSubmittingQuote, setIsSubmittingQuote] = useRecoilState(isSubmittingQuoteAtom);
  const [isReassigningQuote, setIsReassigningQuote] = useRecoilState(isReassigningQuoteAtom);
  const [isPreFeasibilityApprovingQuote, setIsPreFeasibilityApprovingQuote] = useRecoilState(isPreFeasibilityApprovingQuoteAtom);
  const [isPreFeasibilityRejectingQuote, setIsPreFeasibilityRejectingQuote] = useRecoilState(isPreFeasibilityRejectingQuoteAtom);
  const [isSendingQuoteBackToEstimation, setIsSendingQuoteBackToEstimation] = useRecoilState(isSendingQuoteBackToEstimationAtom);
  const [isSubmittingEstimation, setIsSubmittingEstimation] = useRecoilState(isSubmittingEstimationAtom);
  const [isSigningOffFeasibility, setIsSigningOffFeasibility] = useRecoilState(isSigningOffFeasibilityAtom);
  const [isSendingBackToFeasibility, setIsSendingBackToFeasibility] = useRecoilState(isSendingBackToFeasibilityAtom);
  const [isApprovingSalesCheck, setIsApprovingSalesCheck] = useRecoilState(isApprovingSalesCheckAtom);
  const [isInheritingAttachments, setIsInheritingAttachments] = useRecoilState(isInheritingAttachmentsAtom);
  const [isPromotingQuote, setIsPromotingQuote] = useRecoilState(isPromotingQuoteAtom);
  const [showPrintPage, setShowPrintPage] = useRecoilState(showPrintPageAtom);
  const [showInvestPage, setShowInvestPage] = useRecoilState(showInvestPageAtom);
  const resetAppRoles = useResetRecoilState(appRolesAtom);
  const appRoles = useRecoilValue(appRolesAtom);
  const [previousView, setPreviousView] = useRecoilState(previousViewAtom);
  const feasibilitySelect = useRecoilValue(feasibilitySelectAtom);
  const quoteAttachments = useRecoilValue(quoteAttachmentsAtom);
  const [quoteStatusSelect, setQuoteStatusSelect] = useRecoilState(quoteStatusSelectAtom);
  const setLoadingMessage = useSetRecoilState(loadingMessageSelector);
  const setSaveQuoteTrigger = useSetRecoilState(saveQuoteTriggerAtom);
  const setQuoteMissingAttributes = useSetRecoilState(quoteMissingAttributesAtom);
  const setInitValues = useSetRecoilState(initValuesAtom);
  const [editHoldWarningData, setEditHoldWarningData] = useState(null);
  const [isTakingBackDesignEstimation, setIsTakingBackDesignEstimation] = useRecoilState(isTakingBackDesignEstimationAtom);
  const [isTakingBackToolingEstimation, setIsTakingBackToolingEstimation] = useRecoilState(isTakingBackToolingEstimationAtom);
  const [isTakingBackManufacturingEstimation, setIsTakingBackManufacturingEstimation] = useRecoilState(isTakingBackManufacturingEstimationAtom);
  const [isTakingBackPurchasingEstimation, setIsTakingBackPurchasingEstimation] = useRecoilState(isTakingBackPurchasingEstimationAtom);
  const alert = useAlert();
  
  useEffect(() => {
    let ws;
    if(quoteData && quoteData.quoteNumber) {
      // init websocket client
      ws = new WebSocketClient(CONFIG.NIFCO_WS_API_URL, CONFIG.APP_API_KEY);
      ws.on('message', async (topic, payload) => {
        // console.log(`Received message on ${topic} ->`, payload);
        const release = async () => {
          try {
            setLoadingMessage('Releasing hold');
            await releaseQuoteHold(accessToken, quoteData.quoteId, quoteData.revision, quoteData, userId, loggedInUser);
          }
          catch(e) {
            console.error('Release hold error ->', e);
          }
          finally {
            setLoadingMessage(null);
          }
          quoteProcessor.clearAllData();
          setEditHoldWarningData(null);
          setShowPrintPage(false);
          setShowInvestPage(false);
          setQuoteMissingAttributes([]);
          setCurrentView(previousView);
          setQuoteData(null);
          if(ws) {
            ws.unsubscribe(`insight/quote-hold/${quoteData.quoteId}_${quoteData.revision}/warning`);
            ws.unsubscribe(`insight/quote-hold/${quoteData.quoteId}_${quoteData.revision}/kicked`);
            ws.terminate();
            ws = null;
          }
        };
        
        // handle edit hold warnings
        if(topic === `insight/quote-hold/${quoteData.quoteId}_${quoteData.revision}/warning`) {
          if(payload.holdUserId === userId) setEditHoldWarningData(payload);
        }
        // handle edit hold kicks
        else if(topic === `insight/quote-hold/${quoteData.quoteId}_${quoteData.revision}/kicked`) {
          if(payload.holdUserId === userId) await release();
        }
      });
      
      ws.on('open', () => {
        if(ws) {
          ws.subscribe(`insight/quote-hold/${quoteData.quoteId}_${quoteData.revision}/warning`);
          ws.subscribe(`insight/quote-hold/${quoteData.quoteId}_${quoteData.revision}/kicked`);
        }
      });
      
      ws.on('error', e => console.error('WS error ->', e));
    }
    
    return () => {
      if(ws) {
        ws.unsubscribe(`insight/quote-hold/${quoteData.quoteId}_${quoteData.revision}/warning`);
        ws.unsubscribe(`insight/quote-hold/${quoteData.quoteId}_${quoteData.revision}/kicked`);
        ws.terminate();
        ws = null;
      }
    };
  }, [
    quoteData,
    accessToken,
    loggedInUser,
    previousView,
    quoteProcessor,
    setCurrentView,
    setLoadingMessage,
    setQuoteData,
    setQuoteMissingAttributes,
    setShowPrintPage,
    setShowInvestPage,
    userId,
  ]);
  
  const feasibilityAssignment = quoteData && quoteData.assignments ?
    quoteData.assignments.find(o => o.types.includes('FEASIBILITY')) :
    null;
  const designEstimatorAssignment = quoteData && quoteData.assignments ?
    quoteData.assignments.find(o => o.types.includes('DESIGN_ESTIMATOR')) :
    null;
  const toolingEstimatorAssignment = quoteData && quoteData.assignments ?
    quoteData.assignments.find(o => o.types.includes('TOOLING_ESTIMATOR')) :
    null;
  const manufacturingEstimatorAssignment = quoteData && quoteData.assignments ?
    quoteData.assignments.find(o => o.types.includes('MANUFACTURING_ESTIMATOR')) :
    null;
  const purchasingEstimatorAssignment = quoteData && quoteData.assignments ?
    quoteData.assignments.find(o => o.types.includes('PURCHASING_ESTIMATOR')) :
    null;
  const feasibilityAssignmentUserId = feasibilityAssignment ? feasibilityAssignment.userId : null;
  const designEstimatorAssignmentUserId = designEstimatorAssignment ? designEstimatorAssignment.userId : null;
  const toolingEstimatorAssignmentUserId = toolingEstimatorAssignment ? toolingEstimatorAssignment.userId : null;
  const manufacturingEstimatorAssignmentUserId = manufacturingEstimatorAssignment ? manufacturingEstimatorAssignment.userId : null;
  const purchasingEstimatorAssignmentUserId = purchasingEstimatorAssignment ? purchasingEstimatorAssignment.userId : null;
  
  const submittedDesignEstimatorUserId = quoteData?.designEstimationSubmittedUserId;
  const submittedToolingEstimatorUserId = quoteData?.toolingEstimationSubmittedUserId;
  const submittedManufacturingEstimatorUserId = quoteData?.manufacturingEstimationSubmittedUserId;
  const submittedPurchasingEstimatorUserId = quoteData?.purchasingEstimationSubmittedUserId;
  
  const handleLogoutClick = () => {
    if(window.confirm('Are you sure you want to logout?')) {
      quoteProcessor.clearAllData();
      setShowPrintPage(false);
      setShowInvestPage(false);
      setQuoteData(null);
      setCurrentView('SEARCH_QUOTES');
      setUserId(null);
      setAccessToken(null);
      setRefreshToken(null);
      setAccessExpiry(null);
      setRefreshExpiry(null);
      setLoggedInUser(null);
      setLoggedInUser(null);
      resetAppRoles();
    }
  };
  
  const saveQuote = async () => {
    try {
      if(!quoteProcessor || !quoteData) return;
      logger.log(accessToken, `User clicked save quote (${quoteData.quoteId} rev ${quoteData.revision})`, userId, loggedInUser);
      setSaveQuoteTrigger(true);
      // handle quote submissions
      if(quoteData.status === 'SANDBOX') {
        if(isPromotingQuote) {
          setLoadingMessage('Creating revision');
          try {
            await quoteProcessor.processQuote(accessToken, userId, loggedInUser, quoteData, quoteProcessor.ACTIONS.PROMOTE_SANDBOX);
          }
          catch(e) {
            if(e === 'QUOTE_ON_HOLD') {
              alert.error('Quote on hold');
            }
            else {
              console.error('Quote save promote error ->', e);
              alert.error('Quote save error');
              logger.log(accessToken, `Quote save promote error (${quoteData.quoteId} rev ${quoteData.revision}) -> ${JSON.stringify(e)}`, userId, loggedInUser);
            }
            return;
          }
          alert.success('Revision created');
          logger.log(accessToken, `Sandbox quote promoted to revision successfully (${quoteData.quoteId} rev ${quoteData.revision})`, userId, loggedInUser);
          setIsPromotingQuote(false);
          setCurrentView(previousView);
          setShowPrintPage(false);
          setShowInvestPage(false);
          setQuoteData(null);
        }
        else {
          setLoadingMessage('Saving quote');
          const updatedQuoteData = await quoteProcessor.processQuote(accessToken, userId, loggedInUser, quoteData, quoteProcessor.ACTIONS.UPDATE_SANDBOX);
          alert.success('Quote saved');
          logger.log(accessToken, `Sandbox quote was saved successfully (${quoteData.quoteId} rev ${quoteData.revision})`, userId, loggedInUser);
          setInitValues(true);
          setQuoteData(updatedQuoteData);
        }
      }
      else {
        if(isReassigningQuote) {
          setLoadingMessage('Reassigning quote');
          const updatedQuoteData = await quoteProcessor.processQuote(accessToken, userId, loggedInUser, quoteData, quoteProcessor.ACTIONS.REASSIGN);
          if(updatedQuoteData.missingAttributes) {
            setQuoteMissingAttributes(updatedQuoteData.missingAttributes);
            alert.error('Missing data');
            return;
          }
          alert.success('Quote reassigned');
          logger.log(accessToken, `Quote was reassigned (${quoteData.quoteId} rev ${quoteData.revision})`, userId, loggedInUser);
          setIsReassigningQuote(false);
          setQuoteMissingAttributes([]);
          setCurrentView(previousView);
          setShowPrintPage(false);
          setShowInvestPage(false);
          setQuoteData(null);
        }
        else if(isTakingBackDesignEstimation || isTakingBackToolingEstimation || isTakingBackManufacturingEstimation || isTakingBackPurchasingEstimation) {
          setLoadingMessage('Taking back estimation');
          const updatedQuoteData = await quoteProcessor.processQuote(accessToken, userId, loggedInUser, quoteData, quoteProcessor.ACTIONS.TAKE_BACK_ESTIMATION);
          if(updatedQuoteData.missingAttributes) {
            setQuoteMissingAttributes(updatedQuoteData.missingAttributes);
            alert.error('Missing data');
            return;
          }
          alert.success('Estimation taken back');
          logger.log(accessToken, `Quote estimation was taken back (${quoteData.quoteId} rev ${quoteData.revision})`, userId, loggedInUser);
          setIsTakingBackDesignEstimation(false);
          setIsTakingBackToolingEstimation(false);
          setIsTakingBackManufacturingEstimation(false);
          setIsTakingBackPurchasingEstimation(false);
          setInitValues(true);
          setQuoteData(updatedQuoteData);
        }
        else if(isSubmittingQuote) {
          setLoadingMessage('Submitting quote');
          const updatedQuoteData = await quoteProcessor.processQuote(accessToken, userId, loggedInUser, quoteData, quoteProcessor.ACTIONS.SUBMIT);
          if(updatedQuoteData.missingAttributes) {
            setQuoteMissingAttributes(updatedQuoteData.missingAttributes);
            alert.error('Missing data');
            return;
          }
          alert.success('Quote submitted');
          logger.log(accessToken, `Quote was submitted successfully (${quoteData.quoteId} rev ${quoteData.revision})`, userId, loggedInUser);
          setIsSubmittingQuote(false);
          setQuoteMissingAttributes([]);
          setCurrentView(previousView);
          setShowPrintPage(false);
          setShowInvestPage(false);
          setQuoteData(null);
        }
        // handle quote feasibility approvals
        else if(isPreFeasibilityApprovingQuote) {
          setLoadingMessage('Approving quote');
          const updatedQuoteData = await quoteProcessor.processQuote(accessToken, userId, loggedInUser, quoteData, quoteProcessor.ACTIONS.APPROVE_PRE_FEASIBILITY);
          if(updatedQuoteData.missingAttributes) {
            setQuoteMissingAttributes(updatedQuoteData.missingAttributes);
            alert.error('Missing data');
            return;
          }
          alert.success('Quote approved');
          logger.log(accessToken, `Quote feasibility was approved successfully (${quoteData.quoteId} rev ${quoteData.revision})`, userId, loggedInUser);
          setIsPreFeasibilityApprovingQuote(false);
          setQuoteMissingAttributes([]);
          setCurrentView(previousView);
          setShowPrintPage(false);
          setShowInvestPage(false);
          setQuoteData(null);
        }
        // handle quote feasibility rejections
        else if(isPreFeasibilityRejectingQuote) {
          setLoadingMessage('Rejecting quote');
          const updatedQuoteData = await quoteProcessor.processQuote(accessToken, userId, loggedInUser, quoteData, quoteProcessor.ACTIONS.REJECT_PRE_FEASIBILITY);
          if(updatedQuoteData.missingAttributes) {
            setQuoteMissingAttributes(updatedQuoteData.missingAttributes);
            alert.error('Missing data');
            return;
          }
          alert.success('Quote rejected');
          logger.log(accessToken, `Quote feasibility was rejected successfully (${quoteData.quoteId} rev ${quoteData.revision})`, userId, loggedInUser);
          setIsPreFeasibilityRejectingQuote(false);
          setQuoteMissingAttributes([]);
          setCurrentView(previousView);
          setShowPrintPage(false);
          setShowInvestPage(false);
          setQuoteData(null);
        }
        // handle create revisions
        else if(isCreatingRevisionQuote) {
          setLoadingMessage('Creating revision');
          await quoteProcessor.processQuote(accessToken, userId, loggedInUser, quoteData, quoteProcessor.ACTIONS.CREATE_REVISION);
          if(isInheritingAttachments) {
            try {
              await Promise.all(quoteAttachments.map(attachment => createAttachmentRecord(
                accessToken,
                attachment.quoteId,
                quoteData.revision + 1,
                attachment.filename,
                attachment.fileType,
                attachment.description,
                userId,
                loggedInUser,
                attachment.revision,
              )));
            }
            catch(e) {
              console.error('Toolbar.createAttachmentRecord loop error ->', e);
              logger.log(accessToken, `Toolbar.createAttachmentRecord error -> ${JSON.stringify(e)}`, userId, loggedInUser);
              alert.error('Error copying attachments');
            }
          }
          alert.success('Revision created');
          logger.log(accessToken, `Quote revision was created successfully (${quoteData.quoteId} rev ${quoteData.revision})`, userId, loggedInUser);
          setIsCreatingRevisionQuote(false);
          setIsInheritingAttachments(false);
          setCurrentView(previousView);
          setShowPrintPage(false);
          setShowInvestPage(false);
          setQuoteData(null);
        }
        // handle create sandbox quote
        else if(isCreatingSandboxQuote) {
          setLoadingMessage('Creating sandbox quote');
          await quoteProcessor.processQuote(accessToken, userId, loggedInUser, quoteData, quoteProcessor.ACTIONS.CREATE_SANDBOX);
          alert.success('Sandbox quote created');
          logger.log(accessToken, `Sandbox quote was created successfully (${quoteData.quoteId} rev ${quoteData.revision})`, userId, loggedInUser);
          setIsCreatingSandboxQuote(false);
          setCurrentView(previousView);
          setShowPrintPage(false);
          setShowInvestPage(false);
          setQuoteData(null);
        }
        // handle quote estimation submissions
        else if(isSubmittingEstimation) {
          setLoadingMessage('Submitting estimation');
          const updatedQuoteData = await quoteProcessor.processQuote(accessToken, userId, loggedInUser, quoteData, quoteProcessor.ACTIONS.SUBMIT_ESTIMATION);
          if(updatedQuoteData.missingAttributes) {
            setQuoteMissingAttributes(updatedQuoteData.missingAttributes);
            alert.error('Missing data');
            return;
          }
          alert.success('Estimation submitted');
          logger.log(accessToken, `Quote estimation was submitted successfully (${quoteData.quoteId} rev ${quoteData.revision})`, userId, loggedInUser);
          setIsSubmittingEstimation(false);
          setQuoteMissingAttributes([]);
          setCurrentView(previousView);
          setShowPrintPage(false);
          setShowInvestPage(false);
          setQuoteData(null);
        }
        // handle sending back to estimators
        else if(isSendingQuoteBackToEstimation) {
          setLoadingMessage('Sending back');
          const updatedQuoteData = await quoteProcessor.processQuote(accessToken, userId, loggedInUser, quoteData, quoteProcessor.ACTIONS.SEND_BACK_FEASIBILITY);
          if(updatedQuoteData.missingAttributes) {
            setQuoteMissingAttributes(updatedQuoteData.missingAttributes);
            alert.error('Missing data');
            return;
          }
          alert.success('Sent back');
          logger.log(accessToken, `Quote was sent back successfully (${quoteData.quoteId} rev ${quoteData.revision})`, userId, loggedInUser);
          setIsSendingQuoteBackToEstimation(false);
          setQuoteMissingAttributes([]);
          setCurrentView(previousView);
          setShowPrintPage(false);
          setShowInvestPage(false);
          setQuoteData(null);
        }
        // handle reject feasibility
        else if(isSigningOffFeasibility && feasibilitySelect === 'NOT_FEASIBLE') {
          setLoadingMessage('Rejecting feasibility');
          const updatedQuoteData = await quoteProcessor.processQuote(accessToken, userId, loggedInUser, quoteData, quoteProcessor.ACTIONS.REJECT_FEASIBILITY);
          if(updatedQuoteData.missingAttributes) {
            setQuoteMissingAttributes(updatedQuoteData.missingAttributes);
            alert.error('Missing data');
            return;
          }
          alert.success('Rejected quote');
          logger.log(accessToken, `Quote feasibility was rejected successfully (${quoteData.quoteId} rev ${quoteData.revision})`, userId, loggedInUser);
          setIsSigningOffFeasibility(false);
          setQuoteMissingAttributes([]);
          setCurrentView(previousView);
          setShowPrintPage(false);
          setShowInvestPage(false);
          setQuoteData(null);
        }
        // handle signing off feasibility
        else if(isSigningOffFeasibility && feasibilitySelect !== 'NOT_FEASIBLE') {
          setLoadingMessage('Signing off');
          const updatedQuoteData = await quoteProcessor.processQuote(accessToken, userId, loggedInUser, quoteData, quoteProcessor.ACTIONS.SIGN_OFF_FEASIBILITY);
          if(updatedQuoteData.missingAttributes) {
            setQuoteMissingAttributes(updatedQuoteData.missingAttributes);
            alert.error('Missing data');
            return;
          }
          alert.success('Signed off');
          logger.log(accessToken, `Quote was signed off successfully (${quoteData.quoteId} rev ${quoteData.revision})`, userId, loggedInUser);
          setIsSigningOffFeasibility(false);
          setQuoteMissingAttributes([]);
          setCurrentView(previousView);
          setShowPrintPage(false);
          setShowInvestPage(false);
          setQuoteData(null);
        }
        // handle sending back to feasibility
        else if(isSendingBackToFeasibility) {
          setLoadingMessage('Sending back');
          const updatedQuoteData = await quoteProcessor.processQuote(accessToken, userId, loggedInUser, quoteData, quoteProcessor.ACTIONS.SEND_BACK_SALES_APPROVAL);
          if(updatedQuoteData.missingAttributes) {
            setQuoteMissingAttributes(updatedQuoteData.missingAttributes);
            alert.error('Missing data');
            return;
          }
          alert.success('Sent back');
          logger.log(accessToken, `Quote was sent back successfully (${quoteData.quoteId} rev ${quoteData.revision})`, userId, loggedInUser);
          setIsSendingBackToFeasibility(false);
          setQuoteMissingAttributes([]);
          setCurrentView(previousView);
          setShowPrintPage(false);
          setShowInvestPage(false);
          setQuoteData(null);
        }
        // handle sales check approval
        else if(isApprovingSalesCheck) {
          setLoadingMessage('Approving');
          const updatedQuoteData = await quoteProcessor.processQuote(accessToken, userId, loggedInUser, quoteData, quoteProcessor.ACTIONS.SALES_APPROVAL);
          if(updatedQuoteData.missingAttributes) {
            setQuoteMissingAttributes(updatedQuoteData.missingAttributes);
            alert.error('Missing data');
            return;
          }
          alert.success('Quote approved');
          logger.log(accessToken, `Quote was successfully approved (${quoteData.quoteId} rev ${quoteData.revision})`, userId, loggedInUser);
          setIsApprovingSalesCheck(false);
          setQuoteMissingAttributes([]);
          setCurrentView(previousView);
          setShowPrintPage(false);
          setShowInvestPage(false);
          setQuoteData(null);
        }
        // handle completed quote updates
        else if(['COMPLETED', 'AT_CUSTOMER', 'AWARDED', 'LOST'].includes(quoteData.status) && quoteStatusSelect && quoteStatusSelect !== 'NULL' && quoteStatusSelect !== quoteData.status) {
          setLoadingMessage('Saving quote');
          const updatedQuoteData = await quoteProcessor.processQuote(accessToken, userId, loggedInUser, quoteData, quoteProcessor.ACTIONS.SAVE_COMPLETE);
          if(updatedQuoteData.missingAttributes) {
            setQuoteMissingAttributes(updatedQuoteData.missingAttributes);
            alert.error('Missing data');
            return;
          }
          alert.success('Quote saved');
          logger.log(accessToken, `Quote was successfully saved (${quoteData.quoteId} rev ${quoteData.revision})`, userId, loggedInUser);
          setQuoteStatusSelect('NULL');
          setQuoteMissingAttributes([]);
          setCurrentView(previousView);
          setShowPrintPage(false);
          setShowInvestPage(false);
          setQuoteData(null);
        }
        // handle quote creations
        else if(quoteData.quoteNumber == null) {
          setLoadingMessage('Creating quote');
          const updatedQuoteData = await quoteProcessor.processQuote(accessToken, userId, loggedInUser, quoteData, quoteProcessor.ACTIONS.CREATE);
          alert.success('Quote created');
          logger.log(accessToken, `Quote was created successfully (${quoteData.quoteId} rev ${quoteData.revision})`, userId, loggedInUser);
          setInitValues(true);
          setQuoteData(updatedQuoteData);
          setCurrentView('EDIT_QUOTE');
          setPreviousView(previousView);
        }
        // handle regular quote updates
        else {
          setLoadingMessage('Saving quote');
          const updatedQuoteData = await quoteProcessor.processQuote(accessToken, userId, loggedInUser, quoteData, quoteProcessor.ACTIONS.UPDATE);
          alert.success('Quote saved');
          logger.log(accessToken, `Quote was saved successfully (${quoteData.quoteId} rev ${quoteData.revision})`, userId, loggedInUser);
          setInitValues(true);
          setQuoteData(updatedQuoteData);
        }
      }
    }
    catch(e) {
      console.error('Quote save error ->', e);
      alert.error('Quote save error');
      logger.log(accessToken, `Quote save error (${quoteData.quoteId} rev ${quoteData.revision}) -> ${JSON.stringify(e)}`, userId, loggedInUser);
    }
    finally {
      setSaveQuoteTrigger(false);
      setLoadingMessage(null);
    }
  };
  
  const requestEditHold = async () => {
    try {
      logger.log(accessToken, `User clicked edit quote (${quoteData.quoteId} rev ${quoteData.revision})`, userId, loggedInUser);
      setLoadingMessage('Requesting hold');
      const updatedQuoteData = await requestQuoteHold(accessToken, quoteData.quoteId, quoteData.revision, userId, loggedInUser);
      if(!updatedQuoteData) {
        alert.error('Edit hold error');
        return;
      }
      alert.success('Edit mode enabled');
      setInitValues(true);
      setQuoteData(prevState => ({
        ...prevState,
        ...updatedQuoteData,
      }));
    }
    catch(e) {
      console.error('Request hold error ->', e);
      alert.error('Request hold error');
      logger.log(accessToken, `Request hold error (${quoteData.quoteId} rev ${quoteData.revision}) -> ${JSON.stringify(e)}`, userId, loggedInUser);
    }
    finally {
      setLoadingMessage(null);
    }
  };
  
  const handleCloseClick = async (confirmed = false) => {
    if(confirmed || window.confirm('Are you sure you want to close the quote? You will lose any unsaved work.')) {
      if(quoteData.holdUserId === userId) {
        try {
          logger.log(accessToken, `User clicked close to release quote (${quoteData.quoteId} rev ${quoteData.revision})`, userId, loggedInUser);
          setLoadingMessage('Releasing hold');
          const result = await releaseQuoteHold(accessToken, quoteData.quoteId, quoteData.revision, quoteData, userId, loggedInUser);
          if(!result) {
            alert.error('Quote was not released');
            return;
          }
          alert.success('Edit mode disabled');
          setInitValues(true);
          setQuoteData(prevState => ({
            ...prevState,
            holdUserId: undefined,
            holdUsername: undefined,
            holdTime: undefined,
          }));
        }
        catch(e) {
          console.error('Release hold error ->', e);
          alert.error('Release hold error');
          logger.log(accessToken, `Release hold error (${quoteData.quoteId} rev ${quoteData.revision}) -> ${JSON.stringify(e)}`, userId, loggedInUser);
          return;
        }
        finally {
          setLoadingMessage(null);
        }
      }
      document.title = `NIFCO | ${CONFIG.APP_DISPLAY_NAME}`;
      quoteProcessor.clearAllData();
      setEditHoldWarningData(null);
      setShowPrintPage(false);
      setShowInvestPage(false);
      setQuoteMissingAttributes([]);
      setCurrentView(previousView);
      setQuoteData(null);
    }
  };
  
  const extendEditHold = async () => {
    if(editHoldWarningData && Date.now() < editHoldWarningData.expiryTime) {
      try {
        setLoadingMessage('Extending hold');
        const updatedQuoteData = await requestQuoteHold(accessToken, quoteData.quoteId, quoteData.revision, userId, loggedInUser);
        if(!updatedQuoteData) handleCloseClick(true);
        setQuoteData(prevState => ({
          ...prevState,
          holdUserId: updatedQuoteData.holdUserId,
          holdUsername: updatedQuoteData.holdUsername,
          holdTime: updatedQuoteData.holdTime,
        }));
        setEditHoldWarningData(null);
      }
      catch(e) {
        console.error('Request hold error ->', e);
      }
      finally {
        setLoadingMessage(null);
      }
    }
    else handleCloseClick(true);
  };
  
  return (
    <>
      <div style={styles.toolbar} className="toolbar">
        <div style={styles.brandContainer}>
          {
            !windowWidth || windowWidth > 400 ?
              <img src={nifcoLogo} height="30" alt=""/>
              :
              null
          }
          {
            !windowWidth || windowWidth > 550 ?
              <h3 style={styles.brandText}>{CONFIG.APP_DISPLAY_NAME}</h3>
              :
              null
          }
        </div>
        <div style={styles.buttonGroup}>
          {
            currentView === 'EDIT_QUOTE' && quoteData && ['COMPLETED', 'AT_CUSTOMER', 'AWARDED', 'LOST', 'SALES_APPROVAL'].includes(quoteData.status) ?
              <>
                {/* INVEST BUTTON */}
                <ToolbarButton
                  icon={faChartLine}
                  label={showInvestPage ? 'CLOSE INVEST' : 'INVEST'}
                  onClick={() => setShowInvestPage(prevState => !prevState)}
                  disabled={showPrintPage}
                />
                
                {/* PRINT BUTTON */}
                <ToolbarButton
                  icon={faPrint}
                  label={showPrintPage ? 'CLOSE PRINT' : 'PRINT'}
                  onClick={() => setShowPrintPage(prevState => !prevState)}
                  disabled={showInvestPage}
                />
              </> :
              null
          }
          {
            currentView === 'EDIT_QUOTE' || currentView === 'CREATE_QUOTE' ?
              <>
                {/* EDIT/SAVE BUTTON */}
                {quoteData && (!quoteData.holdUserId || quoteData.holdUserId !== userId) && quoteData.quoteNumber != null && quoteData.status !== 'SANDBOX' ?
                  <ToolbarButton
                    icon={faEdit}
                    label="EDIT"
                    onClick={requestEditHold}
                    disabled={quoteData.holdUserId || showPrintPage || showInvestPage || quoteData.isNotActive ||
                      ((!appRoles.includes(CONFIG.ROLE_MAPPINGS.REASSIGN_QUOTE) &&
                          ![
                            feasibilityAssignmentUserId,
                            designEstimatorAssignmentUserId,
                            toolingEstimatorAssignmentUserId,
                            purchasingEstimatorAssignmentUserId,
                            manufacturingEstimatorAssignmentUserId,
                            quoteData.createdBy,
                          ].includes(userId)) &&
                        !([
                          submittedDesignEstimatorUserId,
                          submittedToolingEstimatorUserId,
                          submittedManufacturingEstimatorUserId,
                          submittedPurchasingEstimatorUserId,
                        ].includes(userId) && quoteData.status === 'IN_PROGRESS'))}
                  /> :
                  <ToolbarButton
                    icon={faSave}
                    label="SAVE"
                    onClick={saveQuote}
                    disabled={!quoteData || showPrintPage || showInvestPage || quoteData.isNotActive ||
                      (quoteData.status === 'NOT_FEASIBLE' && !isCreatingRevisionQuote) ||
                      ((!appRoles.includes(CONFIG.ROLE_MAPPINGS.REASSIGN_QUOTE) &&
                          ![
                            feasibilityAssignmentUserId,
                            designEstimatorAssignmentUserId,
                            toolingEstimatorAssignmentUserId,
                            purchasingEstimatorAssignmentUserId,
                            manufacturingEstimatorAssignmentUserId,
                            quoteData.createdBy,
                          ].includes(userId)) &&
                        !([
                            submittedDesignEstimatorUserId,
                            submittedToolingEstimatorUserId,
                            submittedManufacturingEstimatorUserId,
                            submittedPurchasingEstimatorUserId,
                          ].includes(userId) && quoteData.status === 'IN_PROGRESS' &&
                          (isTakingBackDesignEstimation || isTakingBackToolingEstimation ||
                            isTakingBackManufacturingEstimation || isTakingBackPurchasingEstimation)))}
                  />
                }
                
                {/* CLOSE BUTTON */}
                <ToolbarButton
                  icon={faTimesCircle}
                  label="CLOSE"
                  onClick={() => handleCloseClick()}
                />
              </> :
              <>
                {/* SEARCH QUOTES BUTTON */}
                <ToolbarButton
                  icon={faSearch}
                  label="SEARCH"
                  onClick={() => setCurrentView('SEARCH_QUOTES')}
                  active={currentView === 'SEARCH_QUOTES'}
                />
                
                {/* VIEW QUOTES BUTTON */}
                <ToolbarButton
                  icon={faEye}
                  label="DASHBOARD"
                  onClick={() => setCurrentView('QUOTE_DASHBOARD')}
                  active={currentView === 'QUOTE_DASHBOARD'}
                />
                
                {/* CREATE QUOTES BUTTON */}
                {
                  appRoles && appRoles.includes(CONFIG.ROLE_MAPPINGS.CREATE_QUOTE) ?
                    <ToolbarButton
                      icon={faPlusSquare}
                      label="CREATE"
                      onClick={() => {
                        setShowPrintPage(false);
                        setShowInvestPage(false);
                        setInitValues(true);
                        setQuoteData({
                          status: 'DRAFT',
                          quoteId: uuid(),
                          revision: 0,
                          createdBy: userId,
                          createdByUsername: loggedInUser,
                          assignments: [{
                            userId: userId,
                            username: loggedInUser,
                            types: ['OWNER'],
                          }],
                        });
                        setCurrentView('CREATE_QUOTE');
                      }}
                      active={currentView === 'CREATE_QUOTE'}
                    /> :
                    null
                }
              </>
          }
          
          {/* DIVIDER */}
          <div style={styles.divider}/>
          
          {/* LOGOUT BUTTON */}
          <ToolbarButton
            icon={faSignOutAlt}
            label={loggedInUser ?
              loggedInUser.length > 8 ?
                `${loggedInUser.slice(0, 4).toUpperCase()}...${loggedInUser.slice(loggedInUser.length - 4).toUpperCase()}` :
                loggedInUser.toUpperCase() :
              'LOGOUT'}
            onClick={handleLogoutClick}
          />
        </div>
      </div>
      
      <Modal
        isOpen={editHoldWarningData != null}
        style={styles.warningModal}
        ariaHideApp={false}
      >
        <h5>Edit Hold Expiring</h5>
        {editHoldWarningData ?
          <p>{`Quote: ${quoteData.quoteNumber} rev ${quoteData.revision} - Your edit hold expires at ${DateTime.fromMillis(editHoldWarningData.expiryTime).toFormat('HH:mm:ss')}, do you want to extend your edit hold? If you do not extend then any unsaved progress will be lost.`}</p> :
          null
        }
        <div style={styles.buttonRow}>
          <Button
            variant="secondary"
            style={{ marginRight: 5 }}
            onClick={() => handleCloseClick(true)}
          >
            Exit Edit Mode
          </Button>
          <Button
            variant="primary"
            onClick={extendEditHold}
          >
            Extend
          </Button>
        </div>
      </Modal>
    </>
  );
};

const styles = {
  toolbar: {
    minHeight: 51,
    height: 51,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: COLORS.offWhite,
    color: COLORS.darkGray,
    borderBottomColor: COLORS.midGray,
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
  },
  brandContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  brandText: {
    padding: 0,
    margin: '0 0 0 10px',
    fontSize: '1.5em',
    fontWeight: 'bold',
    position: 'relative',
    top: 4,
  },
  divider: {
    alignSelf: 'stretch',
    width: 2,
    marginLeft: 20,
    backgroundColor: COLORS.midGray,
  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  warningModal: {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: 5,
      padding: 10,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'flex-start',
    },
  },
};

export default Toolbar;