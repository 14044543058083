import Toolbar from './Toolbar';
import {
  accessTokenAtom,
  currentViewSelector,
  appRolesAtom,
  productTypeLovAtom,
  partTypeLovAtom,
  partGlobalCategoryLovAtom,
  ppapTimingLovAtom,
  toolTypeLovAtom,
  designResponsibilityLovAtom,
  depreciationMonthsLovAtom,
  businessTypeLovAtom,
  salesExpectationLovAtom,
  annealingLovAtom,
  derivedWeightLovAtom,
  inspectionLovAtom,
  packagingLovAtom,
  ejectorSystemLovAtom,
  gateStyleLovAtom,
  gateCuttingLovAtom,
  toolingToolTypeLovAtom,
  locationLovAtom,
  pressSizeLovAtom,
  pressTypeLovAtom,
  toolDescLovAtom,
  quoteStatusLovAtom,
  activityTypeLovAtom,
  commentTypeLovAtom,
  fgLocationLovAtom,
  cancelReasonLovAtom,
  customersLovAtom,
  feasibilityUsersLovAtom,
  designEstimatorUsersLovAtom,
  toolingEstimatorUsersLovAtom,
  manufacturingEstimatorUsersLovAtom,
  purchasingEstimatorUsersLovAtom,
  assemblyTypesLovAtom,
  paymentTypeLovAtom,
  reasonLostLovAtom,
  awardProbabilityLovAtom,
  salesManagerUsersLovAtom,
  cachedRatesAtom,
  userIdAtom,
  loggedInUserAtom,
  programsLovAtom,
  partTypeIhsLovAtom,
  complexityIhsLovAtom,
  ihsProgramDataAtom,
  lateReasonLovAtom,
} from '../state';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import Dashboard from './Dashboard';
import Search from './Search';
import Quote from './Quote';
import { useEffect } from 'react';
import {
  getAppRoles,
  getInsightLov,
  getUserWithRoleLov,
  getRates,
  getInsightPrograms,
  getAllIhsProgramData,
} from '../helpers/api';
import LoadingOverlay from './LoadingOverlay';
import COLORS from '../styles/colors';
import { getDataJson, getDataInt, storeData, getCachedData, cacheData } from '../helpers/storage';
import Logger from '../helpers/Logger';
import { cmp } from '../helpers/misc';

const logger = new Logger();

const Main = ({ logout }) => {
  const [currentView, setCurrentView] = useRecoilState(currentViewSelector);
  const [appRoles, setAppRoles] = useRecoilState(appRolesAtom);
  const accessToken = useRecoilValue(accessTokenAtom);
  const setPartTypeIhsLov = useSetRecoilState(partTypeIhsLovAtom);
  const setComplexityIhsLov = useSetRecoilState(complexityIhsLovAtom);
  const setProductTypeLov = useSetRecoilState(productTypeLovAtom);
  const setPartTypeLov = useSetRecoilState(partTypeLovAtom);
  const setPartGlobalCategoryLov = useSetRecoilState(partGlobalCategoryLovAtom);
  const setPpapTimingLov = useSetRecoilState(ppapTimingLovAtom);
  const setToolTypeLov = useSetRecoilState(toolTypeLovAtom);
  const setDesignResponsibilityLov = useSetRecoilState(designResponsibilityLovAtom);
  const setDepreciationMonthsLov = useSetRecoilState(depreciationMonthsLovAtom);
  const setBusinessTypeLov = useSetRecoilState(businessTypeLovAtom);
  const setSalesExpectationLov = useSetRecoilState(salesExpectationLovAtom);
  const setAnnealingLov = useSetRecoilState(annealingLovAtom);
  const setDerivedWeightLov = useSetRecoilState(derivedWeightLovAtom);
  const setInspectionLov = useSetRecoilState(inspectionLovAtom);
  const setPackagingLov = useSetRecoilState(packagingLovAtom);
  const setEjectorSystemLov = useSetRecoilState(ejectorSystemLovAtom);
  const setGateStyleLov = useSetRecoilState(gateStyleLovAtom);
  const setGateCuttingLov = useSetRecoilState(gateCuttingLovAtom);
  const setToolingToolTypeLov = useSetRecoilState(toolingToolTypeLovAtom);
  const setToolDescLov = useSetRecoilState(toolDescLovAtom);
  const setLocationLov = useSetRecoilState(locationLovAtom);
  const setPressTypeLov = useSetRecoilState(pressTypeLovAtom);
  const setPressSizeLov = useSetRecoilState(pressSizeLovAtom);
  const setQuoteStatusLov = useSetRecoilState(quoteStatusLovAtom);
  const setActivityTypeLov = useSetRecoilState(activityTypeLovAtom);
  const setCommentTypeLov = useSetRecoilState(commentTypeLovAtom);
  const setProgramsLov = useSetRecoilState(programsLovAtom);
  const setFgLocationLov = useSetRecoilState(fgLocationLovAtom);
  const setCancelReasonLov = useSetRecoilState(cancelReasonLovAtom);
  const setLateReasonLov = useSetRecoilState(lateReasonLovAtom);
  const setCustomersLov = useSetRecoilState(customersLovAtom);
  const setFeasibilityUsersLov = useSetRecoilState(feasibilityUsersLovAtom);
  const setDesignEstimatorUsersLov = useSetRecoilState(designEstimatorUsersLovAtom);
  const setToolingEstimatorUsersLov = useSetRecoilState(toolingEstimatorUsersLovAtom);
  const setManufacturingEstimatorUsersLov = useSetRecoilState(manufacturingEstimatorUsersLovAtom);
  const setPurchasingEstimatorUsersLov = useSetRecoilState(purchasingEstimatorUsersLovAtom);
  const setAssemblyTypesLov = useSetRecoilState(assemblyTypesLovAtom);
  const setPaymentTypeLov = useSetRecoilState(paymentTypeLovAtom);
  const setReasonLostLov = useSetRecoilState(reasonLostLovAtom);
  const setAwardProbabilityLov = useSetRecoilState(awardProbabilityLovAtom);
  const setSalesManagerUsersLov = useSetRecoilState(salesManagerUsersLovAtom);
  const setCachedRates = useSetRecoilState(cachedRatesAtom);
  const setIhsProgramData = useSetRecoilState(ihsProgramDataAtom);
  const userId = useRecoilValue(userIdAtom);
  const loggedInUser = useRecoilValue(loggedInUserAtom);
  
  useEffect(() => {
    if(accessToken) getAppRoles(accessToken)
      .then(roles => {
        if(roles && roles.length) setAppRoles(roles);
      })
      .catch(e => {
        console.error('Main.getAppRoles error ->', e);
        logger.log(accessToken, `Main.getAppRoles error -> ${JSON.stringify(e)}`, userId, loggedInUser);
      });
  }, [accessToken, setAppRoles, setCurrentView, userId, loggedInUser]);
  
  useEffect(() => {
    const lovExpiry = getDataInt('lovExpiryTime');
    const updateLov = async (listId, setFunc, stateName) => {
      let lov;
      if(lovExpiry && lovExpiry > Date.now()) lov = getDataJson(stateName);
      if(!lov) lov = await getInsightLov(accessToken, listId);
      if(lov) {
        setFunc(lov);
        storeData('lovExpiryTime', Date.now() + 3600000);
      }
    };
    const updateProgramsLov = async () => {
      let lov;
      if(lovExpiry && lovExpiry > Date.now()) lov = getDataJson('programsLov');
      if(!lov) lov = (await getInsightPrograms(accessToken)).map(o => ({
        ...o,
        name: `${o.manufacturer}${o.brand !== o.manufacturer ? ` (${o.brand})` : ''} ${o.model} | ${o.startOfProgram} - ${o.endOfProgram} | ${o.platform} | ${o.programCode} ${o.productionPlant}`,
      })).sort((a, b) => cmp(a.manufacturer, b.manufacturer) || cmp(a.model, b.model) || cmp(a.programCode, b.programCode) || cmp(a.productionPlant, b.productionPlant));
      if(lov) {
        setProgramsLov(lov);
        storeData('lovExpiryTime', Date.now() + 3600000);
      }
    };
    const updateIhsProgramData = async () => {
      let lov;
      if(lovExpiry && lovExpiry > Date.now()) lov = await getCachedData('ihsProgramData');
      if(!lov) {
        const ihsData = await Promise.all([
          getAllIhsProgramData(accessToken, 'YEARS'),
          getAllIhsProgramData(accessToken, 'MONTHS'),
          getAllIhsProgramData(accessToken, 'QUARTERS'),
        ]);
        lov = {
          years: ihsData?.[0] ? ihsData[0] : [],
          months: ihsData?.[1] ? ihsData[1] : [],
          quarters: ihsData?.[2] ? ihsData[2] : [],
        };
      }
      if(lov) {
        setIhsProgramData(lov);
        await cacheData('ihsProgramData', lov);
        storeData('lovExpiryTime', Date.now() + 3600000);
      }
    };
    const updateUsersLov = async (roleName, setFunc, stateName) => {
      let lov;
      if(lovExpiry && lovExpiry > Date.now()) lov = getDataJson(stateName);
      if(!lov) lov = await getUserWithRoleLov(accessToken, roleName);
      if(lov) {
        setFunc(lov.sort((a, b) => cmp(a.name, b.name)));
        storeData('lovExpiryTime', Date.now() + 3600000);
      }
    };
    const updateCachedRates = async () => {
      let rates = getDataJson('cachedRates');
      if(!rates) {
        const currentRates = await getRates(accessToken, Date.now());
        rates = [{
          startTime: Math.max(...currentRates.map(r => r.rateStartTime)),
          endTime: 32503680000000,
          rates: currentRates,
        }];
      }
      if(rates) setCachedRates(rates);
    };
    if(!accessToken) return;
    updateLov('PART_TYPE_IHS', setPartTypeIhsLov, 'partTypeIhsLov');
    updateLov('COMPLEXITY_IHS', setComplexityIhsLov, 'complexityIhsLov');
    updateLov('PRODUCT_TYPE', setProductTypeLov, 'productTypeLov');
    updateLov('PART_TYPE', setPartTypeLov, 'partTypeLov');
    updateLov('PART_GLOBAL_CATEGORY', setPartGlobalCategoryLov, 'partGlobalCategoryLov');
    updateLov('PPAP_TIMING', setPpapTimingLov, 'ppapTimingLov');
    updateLov('LH_RH_TOOL', setToolTypeLov, 'toolTypeLov');
    updateLov('DESIGN_RESPONSIBILITY', setDesignResponsibilityLov, 'designResponsibilityLov');
    updateLov('DEPRECIATION_MONTHS', setDepreciationMonthsLov, 'depreciationMonthsLov');
    updateLov('BUSINESS_TYPE', setBusinessTypeLov, 'businessTypeLov');
    updateLov('SALES_EXPECTATION', setSalesExpectationLov, 'salesExpectationLov');
    updateLov('ANNEALING', setAnnealingLov, 'annealingLov');
    updateLov('DERIVED_WEIGHT', setDerivedWeightLov, 'derivedWeightLov');
    updateLov('INSPECTION', setInspectionLov, 'inspectionLov');
    updateLov('ASSEMBLY_TYPES', setAssemblyTypesLov, 'assemblyTypesLov');
    updateLov('PACKAGING', setPackagingLov, 'packagingLov');
    updateLov('EJECTOR_SYSTEMS', setEjectorSystemLov, 'ejectorSystemLov');
    updateLov('GATE_STYLE', setGateStyleLov, 'gateStyleLov');
    updateLov('GATE_CUTTING', setGateCuttingLov, 'gateCuttingLov');
    updateLov('TOOL_TYPE', setToolingToolTypeLov, 'toolingToolTypeLov');
    updateLov('TOOL_DESCRIPTION', setToolDescLov, 'toolDescLov');
    updateLov('LOCATIONS', setLocationLov, 'locationLov');
    updateLov('PRESS_TYPE', setPressTypeLov, 'pressTypeLov');
    updateLov('PRESS_SIZE', setPressSizeLov, 'pressSizeLov');
    updateLov('QUOTE_STATUS', setQuoteStatusLov, 'quoteStatusLov');
    updateLov('ACTIVITY_TYPE', setActivityTypeLov, 'activityTypeLov');
    updateLov('COMMENT_TYPE', setCommentTypeLov, 'commentTypeLov');
    updateLov('FG_LOCATION', setFgLocationLov, 'fgLocationLov');
    updateLov('CANCEL_REASON', setCancelReasonLov, 'cancelReasonLov');
    updateLov('REASON_LATE', setLateReasonLov, 'lateReasonLov');
    updateLov('CUSTOMERS', setCustomersLov, 'customersLov');
    updateLov('PAYMENT_TYPE', setPaymentTypeLov, 'paymentTypeLov');
    updateLov('REASON_LOST', setReasonLostLov, 'reasonLostLov');
    updateLov('PROBABILITY', setAwardProbabilityLov, 'awardProbabilityLov');
    updateUsersLov('Feasibility', setFeasibilityUsersLov, 'feasibilityUsersLov');
    updateUsersLov('Design Estimator', setDesignEstimatorUsersLov, 'designEstimatorUsersLov');
    updateUsersLov('Tooling Estimator', setToolingEstimatorUsersLov, 'toolingEstimatorUsersLov');
    updateUsersLov('Manufacturing Estimator', setManufacturingEstimatorUsersLov, 'manufacturingEstimatorUsersLov');
    updateUsersLov('Purchasing Estimator', setPurchasingEstimatorUsersLov, 'purchasingEstimatorUsersLov');
    updateUsersLov('Create Quote', setSalesManagerUsersLov, 'salesManagerUsersLov');
    updateProgramsLov();
    updateIhsProgramData();
    updateCachedRates();
  }, [
    accessToken,
    setProductTypeLov,
    setPartTypeLov,
    setPartGlobalCategoryLov,
    setPpapTimingLov,
    setToolTypeLov,
    setDesignResponsibilityLov,
    setFgLocationLov,
    setCancelReasonLov,
    setLateReasonLov,
    setCustomersLov,
    setDepreciationMonthsLov,
    setBusinessTypeLov,
    setSalesExpectationLov,
    setAnnealingLov,
    setDerivedWeightLov,
    setInspectionLov,
    setPackagingLov,
    setEjectorSystemLov,
    setGateStyleLov,
    setGateCuttingLov,
    setToolingToolTypeLov,
    setToolDescLov,
    setLocationLov,
    setPressTypeLov,
    setPressSizeLov,
    setQuoteStatusLov,
    setActivityTypeLov,
    setCommentTypeLov,
    setDesignEstimatorUsersLov,
    setToolingEstimatorUsersLov,
    setManufacturingEstimatorUsersLov,
    setPurchasingEstimatorUsersLov,
    setCachedRates,
    setFeasibilityUsersLov,
    setAssemblyTypesLov,
    setPaymentTypeLov,
    setReasonLostLov,
    setAwardProbabilityLov,
    setSalesManagerUsersLov,
    setProgramsLov,
    setComplexityIhsLov,
    setPartTypeIhsLov,
    setIhsProgramData,
  ]);
  
  let render = null;
  if(currentView === 'QUOTE_DASHBOARD') render = <Dashboard/>;
  else if(currentView === 'SEARCH_QUOTES') render = <Search/>;
  else if(currentView === 'CREATE_QUOTE') render = <Quote/>;
  else if(currentView === 'EDIT_QUOTE') render = <Quote/>;
  
  return (
    <div style={styles.mainContainer}>
      <Toolbar logout={logout}/>
      {!appRoles ? <LoadingOverlay message="Loading"/> : render}
    </div>
  );
};

const styles = {
  mainContainer: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    margin: 0,
    backgroundColor: COLORS.offWhite,
    color: COLORS.offBlack,
  },
};

export default Main;
