import { atom, selector } from 'recoil';
import { loggingEffect, saveToStorageEffect } from './helpers/misc';
import cloneDeep from 'lodash.clonedeep';

const loadingMessageStackAtom = atom({
  key: 'loadingMessageStack',
  default: [],
  effects_UNSTABLE: [loggingEffect('loadingMessageStack')],
});

export const loadingMessageSelector = selector({
  key: 'loadingMessage',
  get: ({ get }) => {
    const messageStack = get(loadingMessageStackAtom);
    return messageStack.length ? messageStack[0] : null;
  },
  set: ({ get, set }, newValue) => {
    const messageStack = cloneDeep(get(loadingMessageStackAtom));
    if(newValue != null) messageStack.push(newValue);
    else messageStack.shift();
    set(loadingMessageStackAtom, messageStack);
  },
});

export const userIdAtom = atom({
  key: 'userId',
  default: null,
  effects_UNSTABLE: [
    loggingEffect('userId'),
    saveToStorageEffect('userId'),
  ],
});

export const accessTokenAtom = atom({
  key: 'accessToken',
  default: null,
  effects_UNSTABLE: [
    loggingEffect('accessToken'),
    saveToStorageEffect('accessToken'),
  ],
});

export const refreshTokenAtom = atom({
  key: 'refreshToken',
  default: null,
  effects_UNSTABLE: [
    loggingEffect('refreshToken'),
    saveToStorageEffect('refreshToken'),
  ],
});

export const accessExpiryAtom = atom({
  key: 'accessExpiry',
  default: null,
  effects_UNSTABLE: [
    loggingEffect('accessExpiry'),
    saveToStorageEffect('accessExpiry'),
  ],
});

export const refreshExpiryAtom = atom({
  key: 'refreshExpiry',
  default: null,
  effects_UNSTABLE: [
    loggingEffect('refreshExpiry'),
    saveToStorageEffect('refreshExpiry'),
  ],
});

export const loggedInUserAtom = atom({
  key: 'loggedInUser',
  default: null,
  effects_UNSTABLE: [
    loggingEffect('loggedInUser'),
    saveToStorageEffect('loggedInUser'),
  ],
});

export const windowWidthAtom = atom({
  key: 'windowWidth',
  default: 0,
  effects_UNSTABLE: [loggingEffect('windowWidth')],
});

export const windowHeightAtom = atom({
  key: 'windowHeight',
  default: 0,
  effects_UNSTABLE: [loggingEffect('windowHeight')],
});

export const previousViewAtom = atom({
  key: 'previousView',
  default: 'SEARCH_QUOTES',
  effects_UNSTABLE: [loggingEffect('previousView')],
});

const currentViewStoreAtom = atom({
  key: 'currentViewStore',
  default: 'SEARCH_QUOTES',
  effects_UNSTABLE: [loggingEffect('currentViewStore')],
});

export const currentViewSelector = selector({
  key: 'currentView',
  get: ({ get }) => get(currentViewStoreAtom),
  set: ({ get, set }, newValue) => {
    const oldValue = get(currentViewStoreAtom);
    set(previousViewAtom, oldValue);
    set(currentViewStoreAtom, newValue);
  },
});

export const lastActivityAtom = atom({
  key: 'lastActivity',
  default: null,
  effects_UNSTABLE: [loggingEffect('lastActivity')],
});

export const appRolesAtom = atom({
  key: 'appRoles',
  default: null,
  effects_UNSTABLE: [loggingEffect('appRoles')],
});

export const selectedDashboardQuoteAtom = atom({
  key: 'selectedDashboardQuote',
  default: null,
  effects_UNSTABLE: [loggingEffect('selectedDashboardQuote')],
});

export const partTypeIhsLovAtom = atom({
  key: 'partTypeIhsLov',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('partTypeIhsLov'),
    saveToStorageEffect('partTypeIhsLov'),
  ],
});

export const complexityIhsLovAtom = atom({
  key: 'complexityIhsLov',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('complexityIhsLov'),
    saveToStorageEffect('complexityIhsLov'),
  ],
});

export const productTypeLovAtom = atom({
  key: 'productTypeLov',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('productTypeLov'),
    saveToStorageEffect('productTypeLov'),
  ],
});

export const partTypeLovAtom = atom({
  key: 'partTypeLov',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('partTypeLov'),
    saveToStorageEffect('partTypeLov'),
  ],
});

export const partGlobalCategoryLovAtom = atom({
  key: 'partGlobalCategoryLov',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('partGlobalCategoryLov'),
    saveToStorageEffect('partGlobalCategoryLov'),
  ],
});

export const ppapTimingLovAtom = atom({
  key: 'ppapTimingLov',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('ppapTimingLov'),
    saveToStorageEffect('ppapTimingLov'),
  ],
});

export const toolTypeLovAtom = atom({
  key: 'toolTypeLov',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('toolTypeLov'),
    saveToStorageEffect('toolTypeLov'),
  ],
});

export const designResponsibilityLovAtom = atom({
  key: 'designResponsibilityLov',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('designResponsibilityLov'),
    saveToStorageEffect('designResponsibilityLov'),
  ],
});

export const depreciationMonthsLovAtom = atom({
  key: 'depreciationMonthsLov',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('depreciationMonthsLov'),
    saveToStorageEffect('depreciationMonthsLov'),
  ],
});

export const businessTypeLovAtom = atom({
  key: 'businessTypeLov',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('businessTypeLov'),
    saveToStorageEffect('businessTypeLov'),
  ],
});

export const salesExpectationLovAtom = atom({
  key: 'salesExpectationLov',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('salesExpectationLov'),
    saveToStorageEffect('salesExpectationLov'),
  ],
});

export const annealingLovAtom = atom({
  key: 'annealingLov',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('annealingLov'),
    saveToStorageEffect('annealingLov'),
  ],
});

export const derivedWeightLovAtom = atom({
  key: 'derivedWeightLov',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('derivedWeightLov'),
    saveToStorageEffect('derivedWeightLov'),
  ],
});

export const inspectionLovAtom = atom({
  key: 'inspectionLov',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('inspectionLov'),
    saveToStorageEffect('inspectionLov'),
  ],
});

export const packagingLovAtom = atom({
  key: 'packagingLov',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('packagingLov'),
    saveToStorageEffect('packagingLov'),
  ],
});

export const ejectorSystemLovAtom = atom({
  key: 'ejectorSystemLov',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('ejectorSystemLov'),
    saveToStorageEffect('ejectorSystemLov'),
  ],
});

export const ihsProgramDataAtom = atom({
  key: 'ihsProgramData',
  default: { years: [], months: [] },
  effects_UNSTABLE: [loggingEffect('ihsProgramData')],
});

export const gateStyleLovAtom = atom({
  key: 'gateStyleLov',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('gateStyleLov'),
    saveToStorageEffect('gateStyleLov'),
  ],
});

export const gateCuttingLovAtom = atom({
  key: 'gateCuttingLov',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('gateCuttingLov'),
    saveToStorageEffect('gateCuttingLov'),
  ],
});

export const toolingToolTypeLovAtom = atom({
  key: 'toolingToolTypeLov',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('toolingToolTypeLov'),
    saveToStorageEffect('toolingToolTypeLov'),
  ],
});

export const toolDescLovAtom = atom({
  key: 'toolDescLov',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('toolDescLov'),
    saveToStorageEffect('toolDescLov'),
  ],
});

export const locationLovAtom = atom({
  key: 'locationLov',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('locationLov'),
    saveToStorageEffect('locationLov'),
  ],
});

export const pressTypeLovAtom = atom({
  key: 'pressTypeLov',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('pressTypeLov'),
    saveToStorageEffect('pressTypeLov'),
  ],
});

export const pressSizeLovAtom = atom({
  key: 'pressSizeLov',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('pressSizeLov'),
    saveToStorageEffect('pressSizeLov'),
  ],
});

export const fgLocationLovAtom = atom({
  key: 'fgLocationLov',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('fgLocationLov'),
    saveToStorageEffect('fgLocationLov'),
  ],
});

export const cancelReasonLovAtom = atom({
  key: 'cancelReasonLov',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('cancelReasonLov'),
    saveToStorageEffect('cancelReasonLov'),
  ],
});

export const lateReasonLovAtom = atom({
  key: 'lateReasonLov',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('lateReasonLov'),
    saveToStorageEffect('lateReasonLov'),
  ],
});

export const customersLovAtom = atom({
  key: 'customersLov',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('customersLov'),
    saveToStorageEffect('customersLov'),
  ],
});

export const quoteStatusLovAtom = atom({
  key: 'quoteStatusLov',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('quoteStatusLov'),
    saveToStorageEffect('quoteStatusLov'),
  ],
});

export const assemblyTypesLovAtom = atom({
  key: 'assemblyTypesLov',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('assemblyTypesLov'),
    saveToStorageEffect('assemblyTypesLov'),
  ],
});

export const activityTypeLovAtom = atom({
  key: 'activityTypeLov',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('activityTypeLov'),
    saveToStorageEffect('activityTypeLov'),
  ],
});

export const commentTypeLovAtom = atom({
  key: 'commentTypeLov',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('commentTypeLov'),
    saveToStorageEffect('commentTypeLov'),
  ],
});

export const quoteDataAtom = atom({
  key: 'quoteData',
  default: null,
  effects_UNSTABLE: [loggingEffect('quoteData')],
});

export const programsLovAtom = atom({
  key: 'programsLov',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('programsLov'),
    saveToStorageEffect('programsLov'),
  ],
});

export const paymentTypeLovAtom = atom({
  key: 'paymentTypeLov',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('paymentTypeLov'),
    saveToStorageEffect('paymentTypeLov'),
  ],
});

export const reasonLostLovAtom = atom({
  key: 'reasonLostLov',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('reasonLostLov'),
    saveToStorageEffect('reasonLostLov'),
  ],
});

export const awardProbabilityLovAtom = atom({
  key: 'awardProbabilityLov',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('awardProbabilityLov'),
    saveToStorageEffect('awardProbabilityLov'),
  ],
});

export const saveQuoteTriggerAtom = atom({
  key: 'saveQuoteTrigger',
  default: false,
  effects_UNSTABLE: [loggingEffect('saveQuoteTrigger')],
});

export const quoteProcessorAtom = atom({
  key: 'quoteProcessor',
  default: null,
  dangerouslyAllowMutability: true,
  effects_UNSTABLE: [loggingEffect('quoteProcessor')],
});

export const quoteCommentsAtom = atom({
  key: 'quoteComments',
  default: [],
  effects_UNSTABLE: [loggingEffect('quoteComments')],
});

export const quoteNewPartNumbersAtom = atom({
  key: 'quoteNewPartNumbers',
  default: [],
  effects_UNSTABLE: [loggingEffect('quoteNewPartNumbers')],
});

export const quoteActivitiesAtom = atom({
  key: 'quoteActivities',
  default: [],
  effects_UNSTABLE: [loggingEffect('quoteActivities')],
});

export const isSubmittingQuoteAtom = atom({
  key: 'isSubmittingQuote',
  default: false,
  effects_UNSTABLE: [loggingEffect('isSubmittingQuote')],
});

export const isReassigningQuoteAtom = atom({
  key: 'isReassigningQuote',
  default: false,
  effects_UNSTABLE: [loggingEffect('isReassigningQuote')],
});

export const isApprovingSalesCheckAtom = atom({
  key: 'isApprovingSalesCheck',
  default: false,
  effects_UNSTABLE: [loggingEffect('isApprovingSalesCheck')],
});

export const isSendingBackToFeasibilityAtom = atom({
  key: 'isSendingBackToFeasibility',
  default: false,
  effects_UNSTABLE: [loggingEffect('isSendingBackToFeasibility')],
});

export const isSigningOffFeasibilityAtom = atom({
  key: 'isSigningOffFeasibility',
  default: false,
  effects_UNSTABLE: [loggingEffect('isSigningOffFeasibility')],
});

export const isSendingQuoteBackToEstimationAtom = atom({
  key: 'isSendingQuoteBackToEstimation',
  default: false,
  effects_UNSTABLE: [loggingEffect('isSendingQuoteBackToEstimation')],
});

export const isSendingQuoteBackToDesignEstimatorAtom = atom({
  key: 'isSendingQuoteBackToDesignEstimator',
  default: false,
  effects_UNSTABLE: [loggingEffect('isSendingQuoteBackToDesignEstimator')],
});

export const isSendingQuoteBackToToolingEstimatorAtom = atom({
  key: 'isSendingQuoteBackToToolingEstimator',
  default: false,
  effects_UNSTABLE: [loggingEffect('isSendingQuoteBackToToolingEstimator')],
});

export const isSendingQuoteBackToManufacturingEstimatorAtom = atom({
  key: 'isSendingQuoteBackToManufacturingEstimator',
  default: false,
  effects_UNSTABLE: [loggingEffect('isSendingQuoteBackToManufacturingEstimator')],
});

export const isSendingQuoteBackToPurchasingEstimatorAtom = atom({
  key: 'isSendingQuoteBackToPurchasingEstimator',
  default: false,
  effects_UNSTABLE: [loggingEffect('isSendingQuoteBackToPurchasingEstimator')],
});

export const isSubmittingEstimationAtom = atom({
  key: 'isSubmittingEstimation',
  default: false,
  effects_UNSTABLE: [loggingEffect('isSubmittingEstimation')],
});

export const quoteMissingAttributesAtom = atom({
  key: 'quoteMissingAttributes',
  default: [],
  effects_UNSTABLE: [loggingEffect('quoteMissingAttributes')],
});

export const feasibilityUsersLovAtom = atom({
  key: 'feasibilityUsersLovAtom',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('feasibilityUsersLovAtom'),
    saveToStorageEffect('feasibilityUsersLovAtom'),
  ],
});

export const isPreFeasibilityApprovingQuoteAtom = atom({
  key: 'isPreFeasibilityApprovingQuote',
  default: false,
  effects_UNSTABLE: [loggingEffect('isPreFeasibilityApprovingQuote')],
});

export const isPreFeasibilityRejectingQuoteAtom = atom({
  key: 'isPreFeasibilityRejectingQuote',
  default: false,
  effects_UNSTABLE: [loggingEffect('isPreFeasibilityRejectingQuote')],
});

export const designEstimatorUsersLovAtom = atom({
  key: 'designEstimatorUsersLov',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('designEstimatorUsersLov'),
    saveToStorageEffect('designEstimatorUsersLov'),
  ],
});

export const salesManagerUsersLovAtom = atom({
  key: 'salesManagerUsersLov',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('salesManagerUsersLov'),
    saveToStorageEffect('salesManagerUsersLov'),
  ],
});

export const toolingEstimatorUsersLovAtom = atom({
  key: 'toolingEstimatorUsersLov',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('toolingEstimatorUsersLov'),
    saveToStorageEffect('toolingEstimatorUsersLov'),
  ],
});

export const manufacturingEstimatorUsersLovAtom = atom({
  key: 'manufacturingEstimatorUsersLov',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('manufacturingEstimatorUsersLov'),
    saveToStorageEffect('manufacturingEstimatorUsersLov'),
  ],
});

export const purchasingEstimatorUsersLovAtom = atom({
  key: 'purchasingEstimatorUsersLov',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('purchasingEstimatorUsersLov'),
    saveToStorageEffect('purchasingEstimatorUsersLov'),
  ],
});

export const dashboardQuotesAtom = atom({
  key: 'dashboardQuotes',
  default: [],
  effects_UNSTABLE: [loggingEffect('dashboardQuotes')],
});

export const generalProductTypeSelectAtom = atom({
  key: 'generalProductTypeSelectAtom',
  default: 'NULL',
  effects_UNSTABLE: [loggingEffect('generalProductTypeSelectAtom')],
});

export const isCreatingRevisionQuoteAtom = atom({
  key: 'isCreatingRevisionQuote',
  default: false,
  effects_UNSTABLE: [loggingEffect('isCreatingRevisionQuote')],
});

export const isCreatingSandboxQuoteAtom = atom({
  key: 'isCreatingSandboxQuote',
  default: false,
  effects_UNSTABLE: [loggingEffect('isCreatingSandboxQuote')],
});

export const isPromotingQuoteAtom = atom({
  key: 'isPromotingQuote',
  default: false,
  effects_UNSTABLE: [loggingEffect('isPromotingQuote')],
});

export const cachedRatesAtom = atom({
  key: 'cachedRates',
  default: [],
  effects_UNSTABLE: [
    loggingEffect('cachedRates'),
    saveToStorageEffect('cachedRates'),
  ],
});

export const currentRatesAtom = atom({
  key: 'currentRates',
  default: [],
  effects_UNSTABLE: [loggingEffect('currentRates')],
});

export const fgLocationSelectAtom = atom({
  key: 'fgLocationSelect',
  default: 'NULL',
  effects_UNSTABLE: [loggingEffect('fgLocationSelect')],
});

export const moldedPartsValueAtom = atom({
  key: 'moldedPartsValue',
  default: [],
  effects_UNSTABLE: [loggingEffect('moldedPartsValue')],
});

export const purchasedPartCostsAtom = atom({
  key: 'purchasedPartCosts',
  default: [],
  effects_UNSTABLE: [loggingEffect('purchasedPartCosts')],
});

export const carryoverPartCostsAtom = atom({
  key: 'carryoverPartCosts',
  default: [],
  effects_UNSTABLE: [loggingEffect('carryoverPartCosts')],
});

export const assemblyCostsAtom = atom({
  key: 'assemblyCosts',
  default: [],
  effects_UNSTABLE: [loggingEffect('assemblyCosts')],
});

export const packagingCostsAtom = atom({
  key: 'packagingCosts',
  default: [],
  effects_UNSTABLE: [loggingEffect('packagingCosts')],
});

export const auxCostsAtom = atom({
  key: 'auxCosts',
  default: [],
  effects_UNSTABLE: [loggingEffect('auxCosts')],
});

export const testingCostsAtom = atom({
  key: 'testingCosts',
  default: [],
  effects_UNSTABLE: [loggingEffect('testingCosts')],
});

export const capitalCostsAtom = atom({
  key: 'capitalCosts',
  default: [],
  effects_UNSTABLE: [loggingEffect('capitalCosts')],
});

export const inspectionSelectAtom = atom({
  key: 'inspectionSelect',
  default: 'NULL',
  effects_UNSTABLE: [loggingEffect('inspectionSelect')],
});

export const packagingSelectAtom = atom({
  key: 'packagingSelect',
  default: 'NULL',
  effects_UNSTABLE: [loggingEffect('packagingSelect')],
});

export const rMexFreightValueAtom = atom({
  key: 'rMexFreightValue',
  default: '',
  effects_UNSTABLE: [loggingEffect('rMexFreightValue')],
});

export const feasibilitySelectAtom = atom({
  key: 'feasibilitySelect',
  default: 'NULL',
  effects_UNSTABLE: [loggingEffect('feasibilitySelect')],
});

export const quoteStatusSelectAtom = atom({
  key: 'quoteStatusSelect',
  default: 'NULL',
  effects_UNSTABLE: [loggingEffect('quoteStatusSelect')],
});

export const quoteAttachmentsAtom = atom({
  key: 'quoteAttachments',
  default: [],
  effects_UNSTABLE: [loggingEffect('quoteAttachments')],
});

export const isInheritingAttachmentsAtom = atom({
  key: 'isInheritingAttachments',
  default: false,
  effects_UNSTABLE: [loggingEffect('isInheritingAttachments')],
});

export const showPrintPageAtom = atom({
  key: 'showPrintPage',
  default: false,
  effects_UNSTABLE: [loggingEffect('showPrintPage')],
});

export const showInvestPageAtom = atom({
  key: 'showInvestPage',
  default: false,
  effects_UNSTABLE: [loggingEffect('showInvestPage')],
});

export const selectedMoldedPartIndexAtom = atom({
  key: 'selectedMoldedPartIndex',
  default: 0,
  effects_UNSTABLE: [loggingEffect('selectedMoldedPartIndex')],
});

export const bomDataAtom = atom({
  key: 'bomData',
  default: null,
  effects_UNSTABLE: [loggingEffect('bomData')],
});

export const createMaterialBlendsAtom = atom({
  key: 'createMaterialBlends',
  default: true,
  effects_UNSTABLE: [loggingEffect('createMaterialBlends')],
});

export const isTakingBackDesignEstimationAtom = atom({
  key: 'isTakingBackDesignEstimation',
  default: false,
  effects_UNSTABLE: [loggingEffect('isTakingBackDesignEstimation')],
});

export const isTakingBackToolingEstimationAtom = atom({
  key: 'isTakingBackToolingEstimation',
  default: false,
  effects_UNSTABLE: [loggingEffect('isTakingBackToolingEstimation')],
});

export const isTakingBackManufacturingEstimationAtom = atom({
  key: 'isTakingBackManufacturingEstimation',
  default: false,
  effects_UNSTABLE: [loggingEffect('isTakingBackManufacturingEstimation')],
});

export const isTakingBackPurchasingEstimationAtom = atom({
  key: 'isTakingBackPurchasingEstimation',
  default: false,
  effects_UNSTABLE: [loggingEffect('isTakingBackPurchasingEstimation')],
});

export const initValuesAtom = atom({
  key: 'initValues',
  default: true,
  effects_UNSTABLE: [loggingEffect('initValues')],
});