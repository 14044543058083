import { useState } from 'react';
import { Form, Table } from 'react-bootstrap';
import COLORS from '../styles/colors';
import { useRecoilValue } from 'recoil';
import { activityTypeLovAtom, quoteActivitiesAtom } from '../state';
import { DateTime } from 'luxon';

const ActivityLogSection = () => {
  const [activityLogVisible, setActivityLogVisible] = useState(false);
  const [activityTypeSelect, setActivityTypeSelect] = useState('NULL');
  const activityTypeLov = useRecoilValue(activityTypeLovAtom);
  const quoteActivities = useRecoilValue(quoteActivitiesAtom);
  
  const filteredActivities = quoteActivities.filter(activity =>
    activityTypeSelect === 'NULL' || activityTypeSelect === activity.activityType);
  
  return (
    <>
      <div style={styles.closingRow}>
        <div
          style={styles.closingRowHeader}
          onClick={() => setActivityLogVisible(prev => !prev)}
        >
          <h5 style={styles.noMarginText}>Activity Log</h5>
        </div>
        {
          activityLogVisible ?
            <div style={styles.closingRowContent}>
              <div style={styles.logFilterRow}>
                <p style={styles.logFilterText}>Filter</p>
                <Form.Select
                  as="select"
                  size="sm"
                  value={activityTypeSelect}
                  onChange={e => setActivityTypeSelect(e.target.value)}
                >
                  <option value="NULL">-- All --</option>
                  {activityTypeLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
                </Form.Select>
              </div>
              <div style={styles.scrollingTableContainer}>
                <Table size="sm" striped bordered style={{ marginBottom: 0 }}>
                  <thead>
                  <tr>
                    <th style={{ ...styles.stickyHeader, width: '7%' }}>
                      <div style={styles.fauxTopBorder}/>
                      <p style={styles.stickyHeaderText}>Rev</p>
                      <div style={styles.fauxBottomBorder}/>
                    </th>
                    <th style={{ ...styles.stickyHeader, width: '15%' }}>
                      <div style={styles.fauxTopBorder}/>
                      <p style={styles.stickyHeaderText}>Type</p>
                      <div style={styles.fauxBottomBorder}/>
                    </th>
                    <th style={{ ...styles.stickyHeader, width: '15%' }}>
                      <div style={styles.fauxTopBorder}/>
                      <p style={styles.stickyHeaderText}>Username</p>
                      <div style={styles.fauxBottomBorder}/>
                    </th>
                    <th style={{ ...styles.stickyHeader, width: '20%' }}>
                      <div style={styles.fauxTopBorder}/>
                      <p style={styles.stickyHeaderText}>Time</p>
                      <div style={styles.fauxBottomBorder}/>
                    </th>
                    <th style={{ ...styles.stickyHeader, width: '43%' }}>
                      <div style={styles.fauxTopBorder}/>
                      <p style={styles.stickyHeaderText}>Message</p>
                      <div style={styles.fauxBottomBorder}/>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  {filteredActivities.map((activity, index) => {
                    const activityType = activityTypeLov.find(o => o.code === activity.activityType);
                    return (
                      <tr key={index}>
                        <td>{activity.revision}</td>
                        <td>{activityType ? activityType.name : activity.activityType}</td>
                        <td>{activity.username}</td>
                        <td>{DateTime.fromMillis(activity.activityTime).toFormat('ccc LLL d yyyy, h:mm a')}</td>
                        <td>{activity.message}</td>
                      </tr>
                    );
                  })}
                  </tbody>
                </Table>
              </div>
            </div> :
            null
        }
      </div>
    </>
  );
};

const styles = {
  closingRow: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    borderRadius: 3,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: COLORS.midGray,
    marginBottom: 0,
  },
  closingRowHeader: {
    backgroundColor: COLORS.midGray,
    color: COLORS.darkGray,
    cursor: 'pointer',
    padding: 7,
  },
  closingRowContent: {
    padding: 7,
    display: 'flex',
    flexDirection: 'column',
  },
  logFilterRow: {
    alignSelf: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 8,
  },
  logFilterText: {
    marginBottom: 0,
    marginRight: 8,
    fontWeight: 'bold',
  },
  noMarginText: {
    margin: 0,
  },
  stickyHeader: {
    position: 'sticky',
    top: 0,
    backgroundColor: COLORS.white,
    padding: 0,
  },
  scrollingTableContainer: {
    maxHeight: 350,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  fauxTopBorder: {
    width: '100%',
    height: 1,
    backgroundColor: COLORS.whiteGray,
    position: 'relative',
    top: -1,
  },
  fauxBottomBorder: {
    width: '100%',
    height: 1,
    backgroundColor: COLORS.whiteGray,
    position: 'relative',
    bottom: -1,
  },
  stickyHeaderText: {
    margin: 5,
  },
};

export default ActivityLogSection;