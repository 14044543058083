import { useEffect } from 'react';
import COLORS from '../styles/colors';
import { Button, Form, InputGroup, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import {
  quoteDataAtom,
  appRolesAtom,
  userIdAtom,
  paymentTypeLovAtom,
  saveQuoteTriggerAtom,
  quoteProcessorAtom,
  auxCostsAtom,
  initValuesAtom,
  depreciationMonthsLovAtom,
} from '../state';
import { useRecoilValue, useRecoilState } from 'recoil';
import * as CONFIG from '../config';
import cloneDeep from 'lodash.clonedeep';
import { getAuxCalculations } from '../helpers/calculations';


const QuoteTabAuxiliary = () => {
  const quoteData = useRecoilValue(quoteDataAtom);
  const appRoles = useRecoilValue(appRolesAtom);
  const userId = useRecoilValue(userIdAtom);
  const depreciationMonthsLov = useRecoilValue(depreciationMonthsLovAtom);
  const paymentTypeLov = useRecoilValue(paymentTypeLovAtom);
  const quoteProcessor = useRecoilValue(quoteProcessorAtom);
  const saveQuoteTrigger = useRecoilValue(saveQuoteTriggerAtom);
  const [auxCosts, setAuxCosts] = useRecoilState(auxCostsAtom);
  const [initValues, setInitValues] = useRecoilState(initValuesAtom);
  
  useEffect(() => {
    if(saveQuoteTrigger) {
      quoteProcessor.setAuxData({
        auxCosts: auxCosts.map(o => ({
          description: o.description,
          paymentType: o.paymentType,
          quantity: o.quantity && !isNaN(parseInt(o.quantity, 10)) ? parseInt(o.quantity, 10) : null,
          unitCost: o.unitCost && !isNaN(parseFloat(o.unitCost)) ? parseFloat(o.unitCost) : null,
          depreciationMonths: o.depreciationMonths !== 'NULL' ? o.depreciationMonths : null,
          unitPrice: o.unitPrice && !isNaN(parseFloat(o.unitPrice)) ? parseFloat(o.unitPrice) : null,
        })),
      });
    }
  }, [saveQuoteTrigger, quoteProcessor, auxCosts]);
  
  useEffect(() => {
    if(initValues && quoteData) {
      setAuxCosts(quoteData.auxCosts ? quoteData.auxCosts.map(o => ({
        description: o.description,
        paymentType: o.paymentType,
        quantity: o.quantity != null ? `${o.quantity}` : '',
        unitCost: o.unitCost != null ? `${o.unitCost}` : '',
        unitPrice: o.unitPrice != null ? `${o.unitPrice}` : '',
        depreciationMonths: o.depreciationMonths != null ? o.depreciationMonths : 'NULL',
      })) : []);
      setInitValues(false);
    }
  }, [initValues, quoteData, setAuxCosts, setInitValues]);
  
  const manufacturingAssignment = quoteData.assignments.find(o => o.types.includes('MANUFACTURING_ESTIMATOR'));
  const manufacturingAssignedUserId = manufacturingAssignment ? manufacturingAssignment.userId : null;
  const designAssignment = quoteData.assignments.find(o => o.types.includes('DESIGN_ESTIMATOR'));
  const designAssignedUserId = designAssignment ? designAssignment.userId : null;
  const toolingAssignment = quoteData.assignments.find(o => o.types.includes('TOOLING_ESTIMATOR'));
  const toolingAssignedUserId = toolingAssignment ? toolingAssignment.userId : null;
  const purchasingAssignment = quoteData.assignments.find(o => o.types.includes('PURCHASING_ESTIMATOR'));
  const purchasingAssignedUserId = purchasingAssignment ? purchasingAssignment.userId : null;
  const feasibilityAssignment = quoteData.assignments.find(o => o.types.includes('FEASIBILITY'));
  const feasibilityAssignmentUserId = feasibilityAssignment ? feasibilityAssignment.userId : null;
  
  const disableAllInputs = quoteData.status !== 'SANDBOX' && (quoteData.holdUserId !== userId || quoteData.isNotActive || !['IN_PROGRESS', 'FEASIBILITY'].includes(quoteData.status) ||
    (!(appRoles.includes(CONFIG.ROLE_MAPPINGS.DESIGN_ESTIMATOR) && userId === designAssignedUserId) &&
      !(appRoles.includes(CONFIG.ROLE_MAPPINGS.MANUFACTURING_ESTIMATOR) && userId === manufacturingAssignedUserId) &&
      !(appRoles.includes(CONFIG.ROLE_MAPPINGS.TOOLING_ESTIMATOR) && userId === toolingAssignedUserId) &&
      !(appRoles.includes(CONFIG.ROLE_MAPPINGS.PURCHASING_ESTIMATOR) && userId === purchasingAssignedUserId) &&
      !(appRoles.includes(CONFIG.ROLE_MAPPINGS.FEASIBILITY) && userId === feasibilityAssignmentUserId)));
  
  const confirmRemoveAuxCost = index => {
    if(window.confirm('Are you sure you want to remove this auxiliary cost?'))
      setAuxCosts(prevState => {
        const newState = cloneDeep(prevState);
        newState.splice(index, 1);
        return newState;
      });
  };
  
  const auxCalculations = getAuxCalculations({ auxCosts });
  
  return (
    <>
      <h5 style={{ ...styles.lineTitle, textAlign: 'left', marginTop: 20, marginBottom: 20 }}>
        <span style={{ ...styles.lineTitleText, backgroundColor: COLORS.white }}>
          Auxiliary Equipment Costs
        </span>
      </h5>
      <Table size="sm" style={{ marginBottom: 0 }}>
        <tbody>
        <tr style={{ fontWeight: 'bold' }}>
          <td>Description</td>
          <td>Quantity</td>
          <td>Cost</td>
          <td>Price</td>
          <td>Payment Type</td>
          <td>Depreciation (months)</td>
          <td>Total Cost</td>
          <td>Total Price</td>
          <td/>
        </tr>
        {auxCosts.map((auxCost, index) => (
          <tr key={index}>
            <td>
              <Form.Control
                type="text"
                value={auxCost.description}
                onChange={e => setAuxCosts(prevState => {
                  const newState = cloneDeep(prevState);
                  newState[index].description = e.target.value;
                  return newState;
                })}
                disabled={disableAllInputs}
              />
            </td>
            <td>
              <Form.Control
                type="number"
                step="1"
                min="1"
                value={auxCost.quantity}
                onChange={e => setAuxCosts(prevState => {
                  const newState = cloneDeep(prevState);
                  newState[index].quantity = e.target.value;
                  return newState;
                })}
                disabled={disableAllInputs}
              />
            </td>
            <td>
              <InputGroup>
                <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                <Form.Control
                  type="number"
                  step="0.01"
                  min="0"
                  value={auxCost.unitCost}
                  onChange={e => setAuxCosts(prevState => {
                    const newState = cloneDeep(prevState);
                    newState[index].unitCost = e.target.value;
                    return newState;
                  })}
                  disabled={disableAllInputs}
                />
              </InputGroup>
            </td>
            <td>
              <InputGroup>
                <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                <Form.Control
                  type="number"
                  step="0.01"
                  min="0"
                  value={auxCost.unitPrice}
                  onChange={e => setAuxCosts(prevState => {
                    const newState = cloneDeep(prevState);
                    newState[index].unitPrice = e.target.value;
                    return newState;
                  })}
                  disabled={disableAllInputs &&
                    !(['SALES_APPROVAL', 'COMPLETED'].includes(quoteData.status) &&
                      appRoles.includes(CONFIG.ROLE_MAPPINGS.CREATE_QUOTE) && userId === quoteData.createdBy && quoteData.holdUserId === userId)}
                />
              </InputGroup>
            </td>
            <td>
              <Form.Select
                as="select"
                value={auxCost.paymentType}
                onChange={e => setAuxCosts(prevState => {
                  const newState = cloneDeep(prevState);
                  newState[index].paymentType = e.target.value;
                  if(!e.target.value.startsWith('DEPRECIATE')) newState[index].depreciationMonths = 'NULL';
                  return newState;
                })}
                disabled={disableAllInputs &&
                  !(['SALES_APPROVAL', 'COMPLETED'].includes(quoteData.status) &&
                    appRoles.includes(CONFIG.ROLE_MAPPINGS.CREATE_QUOTE) && userId === quoteData.createdBy && quoteData.holdUserId === userId)}
              >
                <option value="NULL">-- Select --</option>
                {paymentTypeLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
              </Form.Select>
            </td>
            <td>
              <Form.Select
                as="select"
                value={auxCost.depreciationMonths}
                onChange={e => setAuxCosts(prevState => {
                  const newState = cloneDeep(prevState);
                  newState[index].depreciationMonths = e.target.value !== 'NULL' && !isNaN(parseInt(e.target.value, 10)) ?
                    parseInt(e.target.value, 10) : null;
                  return newState;
                })}
                disabled={!auxCost.paymentType.startsWith('DEPRECIATE') || (disableAllInputs &&
                  !(['SALES_APPROVAL', 'COMPLETED'].includes(quoteData.status) &&
                    appRoles.includes(CONFIG.ROLE_MAPPINGS.CREATE_QUOTE) && userId === quoteData.createdBy && quoteData.holdUserId === userId))}
              >
                <option value="NULL">-- Select --</option>
                {depreciationMonthsLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
              </Form.Select>
            </td>
            <td>
              <InputGroup>
                <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                <Form.Control
                  type="number"
                  value={auxCost.quantity && auxCost.quantity.length && !isNaN(parseInt(auxCost.quantity, 10)) &&
                  auxCost.unitCost && auxCost.unitCost.length && !isNaN(parseFloat(auxCost.unitCost)) ?
                    (parseInt(auxCost.quantity, 10) * parseFloat(auxCost.unitCost)).toFixed(5) : ''}
                  disabled
                />
              </InputGroup>
            </td>
            <td>
              <InputGroup>
                <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                <Form.Control
                  type="number"
                  value={auxCost.quantity && auxCost.quantity.length && !isNaN(parseInt(auxCost.quantity, 10)) &&
                  auxCost.unitPrice && auxCost.unitPrice.length && !isNaN(parseFloat(auxCost.unitPrice)) ?
                    (parseInt(auxCost.quantity, 10) * parseFloat(auxCost.unitPrice)).toFixed(5) : ''}
                  disabled
                />
              </InputGroup>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              {
                !disableAllInputs ?
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    className="delete-button"
                    style={styles.deleteButton}
                    onClick={() => confirmRemoveAuxCost(index)}
                  /> :
                  null
              }
            </td>
          </tr>
        ))}
        </tbody>
      </Table>
      <div style={styles.addMoldedPartButtonRow}>
        <Button
          type="button"
          variant="primary"
          onClick={() => setAuxCosts(prevState => {
            const newState = cloneDeep(prevState);
            newState.push({
              description: '',
              quantity: '',
              unitCost: '',
              paymentType: 'NULL',
              depreciationMonths: 'NULL',
            });
            return newState;
          })}
          disabled={disableAllInputs}
        >
          <FontAwesomeIcon icon={faPlus} style={{ marginRight: 4 }}/>
          Add Aux Equipment
        </Button>
      </div>
      <div style={styles.addMoldedPartButtonRow}>
        <p style={{ marginRight: 10, marginBottom: 0 }}>Total Auxiliary Equipment Cost</p>
        <InputGroup style={{ flexBasis: '25%' }}>
          <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
          <Form.Control
            type="number"
            value={auxCalculations?.totalAuxEquipmentCost ? auxCalculations.totalAuxEquipmentCost.toFixed(5) : ''}
            disabled
          />
        </InputGroup>
      </div>
      <div style={styles.addMoldedPartButtonRow}>
        <p style={{ marginRight: 10, marginBottom: 0 }}>Total Auxiliary Equipment Price</p>
        <InputGroup style={{ flexBasis: '25%' }}>
          <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
          <Form.Control
            type="number"
            value={auxCalculations?.totalAuxEquipmentPrice ? auxCalculations.totalAuxEquipmentPrice.toFixed(5) : ''}
            disabled
          />
        </InputGroup>
      </div>
      <div style={styles.addMoldedPartButtonRow}>
        <p style={{ marginRight: 10, marginBottom: 0 }}>Total Depreciated Into Cost</p>
        <InputGroup style={{ flexBasis: '25%' }}>
          <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
          <Form.Control
            type="number"
            value={auxCalculations?.totalDepreciatedCost ? auxCalculations.totalDepreciatedCost.toFixed(5) : ''}
            disabled
          />
        </InputGroup>
      </div>
      <div style={styles.addMoldedPartButtonRow}>
        <p style={{ marginRight: 10, marginBottom: 0 }}>Total Depreciated Into Price</p>
        <InputGroup style={{ flexBasis: '25%' }}>
          <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
          <Form.Control
            type="number"
            value={auxCalculations?.totalDepreciatedPrice ? auxCalculations.totalDepreciatedPrice.toFixed(5) : ''}
            disabled
          />
        </InputGroup>
      </div>
      <div style={styles.addMoldedPartButtonRow}>
        <p style={{ marginRight: 10, marginBottom: 0 }}>Total Expense</p>
        <InputGroup style={{ flexBasis: '25%' }}>
          <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
          <Form.Control
            type="number"
            value={auxCalculations?.totalExpenseCost ? auxCalculations.totalExpenseCost.toFixed(5) : ''}
            disabled
          />
        </InputGroup>
      </div>
      <div style={styles.addMoldedPartButtonRow}>
        <p style={{ marginRight: 10, marginBottom: 0 }}>Total Customer Pays</p>
        <InputGroup style={{ flexBasis: '25%' }}>
          <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
          <Form.Control
            type="number"
            value={auxCalculations?.totalCustomerPaysCost ? auxCalculations.totalCustomerPaysCost.toFixed(5) : ''}
            disabled
          />
        </InputGroup>
      </div>
    </>
  );
};

const styles = {
  lineTitle: {
    textAlign: 'center',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: COLORS.midGray,
    lineHeight: '0.1em',
    marginTop: 12,
    marginBottom: 12,
    color: COLORS.darkGray,
  },
  lineTitleText: {
    backgroundColor: COLORS.offWhite,
    paddingLeft: 7,
    paddingRight: 7,
  },
  addMoldedPartButtonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 5,
    marginBottom: 8,
  },
  leftInputGroupText: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRightWidth: 0,
  },
  deleteButton: {
    marginLeft: 5,
    cursor: 'pointer',
  },
};

export default QuoteTabAuxiliary;