import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import COLORS from '../styles/colors';


const ToolbarButton = ({ icon, label, onClick=null, disabled, active }) => {
  return (
    <div
      style={{
        ...styles.buttonContainer,
        cursor: disabled ? 'not-allowed' :
          active ? 'default' :
            'pointer',
        color: disabled ? COLORS.darkGray :
          active ? COLORS.orange :
            COLORS.midGray,
      }}
      className={!disabled ? "toolbar-button" : null}
      onClick={!disabled && !active ? onClick : null}
    >
      <FontAwesomeIcon
        icon={icon}
        size="lg"
      />
      <p style={styles.label}>{label}</p>
    </div>
  );
};

const styles = {
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 20,
  },
  label: {
    padding: 0,
    margin: 0,
    fontWeight: 'bold',
    fontSize: '0.8em',
    textAlign: 'center',
    userSelect: 'none',
  },
};

export default ToolbarButton;