import { useState, useEffect } from 'react';
import { Tabs, Tab, Badge, Form, Button, InputGroup } from 'react-bootstrap';
import CommentsSection from './CommentsSection';
import ActivityLogSection from './ActivityLogSection';
import COLORS from '../styles/colors';
import QuoteTabGeneral from './QuoteTabGeneral';
import QuoteTabMolded from './QuoteTabMolded';
import QuoteTabAssembled from './QuoteTabAssembled';
import QuoteTabPurchased from './QuoteTabPurchased';
import QuoteTabPackaging from './QuoteTabPackaging';
import QuoteTabAuxiliary from './QuoteTabAuxiliary';
import QuoteTabTesting from './QuoteTabTesting';
import QuoteTabFinishGood from './QuoteTabFinishGood';
import QuoteTabFeasibility from './QuoteTabFeasibility';
import QuoteTabBom from './QuoteTabBom';
import QuoteTabCapital from './QuoteTabCapital';
import InvestPage from './InvestPage';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import {
  quoteDataAtom,
  accessTokenAtom,
  quoteCommentsAtom,
  quoteActivitiesAtom,
  quoteProcessorAtom,
  saveQuoteTriggerAtom,
  fgLocationLovAtom,
  cancelReasonLovAtom,
  userIdAtom,
  loggedInUserAtom,
  loadingMessageSelector,
  currentViewSelector,
  isSubmittingQuoteAtom,
  isSubmittingEstimationAtom,
  quoteMissingAttributesAtom,
  quoteStatusLovAtom,
  isPreFeasibilityApprovingQuoteAtom,
  designEstimatorUsersLovAtom,
  toolingEstimatorUsersLovAtom,
  isPreFeasibilityRejectingQuoteAtom,
  generalProductTypeSelectAtom,
  productTypeLovAtom,
  manufacturingEstimatorUsersLovAtom,
  purchasingEstimatorUsersLovAtom,
  isCreatingRevisionQuoteAtom,
  appRolesAtom,
  feasibilityUsersLovAtom,
  fgLocationSelectAtom,
  isSendingQuoteBackToEstimationAtom,
  isSendingQuoteBackToDesignEstimatorAtom,
  isSendingQuoteBackToToolingEstimatorAtom,
  isSendingQuoteBackToManufacturingEstimatorAtom,
  isSendingQuoteBackToPurchasingEstimatorAtom,
  isSigningOffFeasibilityAtom,
  isApprovingSalesCheckAtom,
  isSendingBackToFeasibilityAtom,
  salesManagerUsersLovAtom,
  reasonLostLovAtom,
  currentRatesAtom,
  awardProbabilityLovAtom,
  quoteStatusSelectAtom,
  previousViewAtom,
  cachedRatesAtom,
  quoteAttachmentsAtom,
  isInheritingAttachmentsAtom,
  showPrintPageAtom,
  isReassigningQuoteAtom,
  isCreatingSandboxQuoteAtom,
  isPromotingQuoteAtom,
  dashboardQuotesAtom,
  ihsProgramDataAtom,
  isTakingBackPurchasingEstimationAtom,
  isTakingBackToolingEstimationAtom,
  isTakingBackManufacturingEstimationAtom,
  isTakingBackDesignEstimationAtom,
  lateReasonLovAtom,
  initValuesAtom,
  capitalCostsAtom,
  showInvestPageAtom,
  quoteNewPartNumbersAtom,
} from '../state';
import {
  getQuoteComments,
  getQuoteActivityLogs,
  getRates,
  getAttachmentRecords,
  getQuoteNewPartNumbers,
} from '../helpers/api';
import { DateTime } from 'luxon';
import Logger from '../helpers/Logger';
import { useAlert } from 'react-alert';
import GLOBAL_STYLES from '../styles';
import { cmp, getRateOrgCodeFromLocator, getStatusBadgeType } from '../helpers/misc';
import * as CONFIG from '../config';
import { DateTime as DateTimeBusiness } from 'luxon-business-days';
import { getFinishedGoodCalculations, getCapitalCalculations } from '../helpers/calculations';
import cloneDeep from 'lodash.clonedeep';
import PrintPage from './PrintPage';
import QuoteTabNewParts from './QuoteTabNewParts';

const logger = new Logger();

const Quote = () => {
  const [isCancellingQuote, setIsCancellingQuote] = useState(false);
  const [isDeletingQuote, setIsDeletingQuote] = useState(false);
  const [isDeletingSandboxQuote, setIsDeletingSandboxQuote] = useState(false);
  const [isTakingBackDesignEstimation, setIsTakingBackDesignEstimation] = useRecoilState(isTakingBackDesignEstimationAtom);
  const [isTakingBackToolingEstimation, setIsTakingBackToolingEstimation] = useRecoilState(isTakingBackToolingEstimationAtom);
  const [isTakingBackManufacturingEstimation, setIsTakingBackManufacturingEstimation] = useRecoilState(isTakingBackManufacturingEstimationAtom);
  const [isTakingBackPurchasingEstimation, setIsTakingBackPurchasingEstimation] = useRecoilState(isTakingBackPurchasingEstimationAtom);
  const [isSubmittingQuote, setIsSubmittingQuote] = useRecoilState(isSubmittingQuoteAtom);
  const [isSubmittingEstimation, setIsSubmittingEstimation] = useRecoilState(isSubmittingEstimationAtom);
  const [isPreFeasibilityApprovingQuote, setIsPreFeasibilityApprovingQuote] = useRecoilState(isPreFeasibilityApprovingQuoteAtom);
  const [isPreFeasibilityRejectingQuote, setIsPreFeasibilityRejectingQuote] = useRecoilState(isPreFeasibilityRejectingQuoteAtom);
  const [isCreatingRevisionQuote, setIsCreatingRevisionQuote] = useRecoilState(isCreatingRevisionQuoteAtom);
  const [isCreatingSandboxQuote, setIsCreatingSandboxQuote] = useRecoilState(isCreatingSandboxQuoteAtom);
  const [isSendingQuoteBackToEstimation, setIsSendingQuoteBackToEstimation] = useRecoilState(isSendingQuoteBackToEstimationAtom);
  const [isSendingQuoteBackToDesignEstimator, setIsSendingQuoteBackToDesignEstimator] = useRecoilState(isSendingQuoteBackToDesignEstimatorAtom);
  const [isSendingQuoteBackToToolingEstimator, setIsSendingQuoteBackToToolingEstimator] = useRecoilState(isSendingQuoteBackToToolingEstimatorAtom);
  const [isSendingQuoteBackToManufacturingEstimator, setIsSendingQuoteBackToManufacturingEstimator] = useRecoilState(isSendingQuoteBackToManufacturingEstimatorAtom);
  const [isSendingQuoteBackToPurchasingEstimator, setIsSendingQuoteBackToPurchasingEstimator] = useRecoilState(isSendingQuoteBackToPurchasingEstimatorAtom);
  const [isSigningOffFeasibility, setIsSigningOffFeasibility] = useRecoilState(isSigningOffFeasibilityAtom);
  const [isApprovingSalesCheck, setIsApprovingSalesCheck] = useRecoilState(isApprovingSalesCheckAtom);
  const [isSendingBackToFeasibility, setIsSendingBackToFeasibility] = useRecoilState(isSendingBackToFeasibilityAtom);
  const [isPromotingQuote, setIsPromotingQuote] = useRecoilState(isPromotingQuoteAtom);
  const [isInheritingAttachments, setIsInheritingAttachments] = useRecoilState(isInheritingAttachmentsAtom);
  const [fgLocationSelect, setFgLocationSelect] = useRecoilState(fgLocationSelectAtom);
  const [feasibilityAssigneeSelect, setFeasibilityAssigneeSelect] = useState('NULL');
  const [isReassigningQuote, setIsReassigningQuote] = useRecoilState(isReassigningQuoteAtom);
  const [reassignOwnerSelect, setReassignOwnerSelect] = useState('NULL');
  const [reassignFeasibilitySelect, setReassignFeasibilitySelect] = useState('NULL');
  const [reassignDesignEstimatorSelect, setReassignDesignEstimatorSelect] = useState('NULL');
  const [reassignToolingEstimatorSelect, setReassignToolingEstimatorSelect] = useState('NULL');
  const [reassignManufacturingEstimatorSelect, setReassignManufacturingEstimatorSelect] = useState('NULL');
  const [reassignPurchasingEstimatorSelect, setReassignPurchasingEstimatorSelect] = useState('NULL');
  const [designEstimatorAssigneeSelect, setDesignEstimatorAssigneeSelect] = useState('NULL');
  const [toolingEstimatorAssigneeSelect, setToolingEstimatorAssigneeSelect] = useState('NULL');
  const [manufacturingEstimatorAssigneeSelect, setManufacturingEstimatorAssigneeSelect] = useState('NULL');
  const [purchasingEstimatorAssigneeSelect, setPurchasingEstimatorAssigneeSelect] = useState('NULL');
  const [rejectionPreFeasibilityCommentValue, setRejectionPreFeasibilityCommentValue] = useState('');
  const [dueDateValue, setDueDateValue] = useState('');
  const [rfqRequestValue, setRfqRequestValue] = useState('');
  const [quoteIssuedValue, setQuoteIssuedValue] = useState('');
  const [dateOfNoticeValue, setDateOfNoticeValue] = useState('');
  const [prodStartDateValue, setProdStartDateValue] = useState('');
  const [cancelCommentValue, setCancelCommentValue] = useState('');
  const [lateCommentValue, setLateCommentValue] = useState('');
  const [lostReasonCommentValue, setLostReasonCommentValue] = useState('');
  const [sendBackCommentValue, setSendBackCommentValue] = useState('');
  const [sendBackFeasibilityCommentValue, setSendBackFeasibilityCommentValue] = useState('');
  const [cancelReasonValue, setCancelReasonValue] = useState('NULL');
  const [lateReasonValue, setLateReasonValue] = useState('NULL');
  const [quoteStatusSelect, setQuoteStatusSelect] = useRecoilState(quoteStatusSelectAtom);
  const [toolingOptionSelect, setToolingOptionSelect] = useState('OPTION_A');
  const [lostReasonValue, setLostReasonValue] = useState('NULL');
  const [awardProbabilitySelect, setAwardProbabilitySelect] = useState('NULL');
  const [fgPriceValue, setFgPriceValue] = useState('');
  const [royaltyPercentageValue, setRoyaltyPercentageValue] = useState('');
  const [initValues, setInitValues] = useRecoilState(initValuesAtom);
  const [quoteData, setQuoteData] = useRecoilState(quoteDataAtom);
  const accessToken = useRecoilValue(accessTokenAtom);
  const setQuoteComments = useSetRecoilState(quoteCommentsAtom);
  const [quoteNewPartNumbers, setQuoteNewPartNumbers] = useRecoilState(quoteNewPartNumbersAtom);
  const setQuoteActivities = useSetRecoilState(quoteActivitiesAtom);
  const quoteProcessor = useRecoilValue(quoteProcessorAtom);
  const [saveQuoteTrigger, setSaveQuoteTrigger] = useRecoilState(saveQuoteTriggerAtom);
  const fgLocationLov = useRecoilValue(fgLocationLovAtom);
  const cancelReasonLov = useRecoilValue(cancelReasonLovAtom);
  const lateReasonLov = useRecoilValue(lateReasonLovAtom);
  const userId = useRecoilValue(userIdAtom);
  const loggedInUser = useRecoilValue(loggedInUserAtom);
  const feasibilityUsersLov = useRecoilValue(feasibilityUsersLovAtom);
  const quoteMissingAttributes = useRecoilValue(quoteMissingAttributesAtom);
  const quoteStatusLov = useRecoilValue(quoteStatusLovAtom);
  const designEstimatorUsersLov = useRecoilValue(designEstimatorUsersLovAtom);
  const toolingEstimatorUsersLov = useRecoilValue(toolingEstimatorUsersLovAtom);
  const manufacturingEstimatorUsersLov = useRecoilValue(manufacturingEstimatorUsersLovAtom);
  const purchasingEstimatorUsersLov = useRecoilValue(purchasingEstimatorUsersLovAtom);
  const salesManagerUsersLov = useRecoilValue(salesManagerUsersLovAtom);
  const generalProductTypeSelect = useRecoilValue(generalProductTypeSelectAtom);
  const productTypeLov = useRecoilValue(productTypeLovAtom);
  const appRoles = useRecoilValue(appRolesAtom);
  const reasonLostLov = useRecoilValue(reasonLostLovAtom);
  const awardProbabilityLov = useRecoilValue(awardProbabilityLovAtom);
  const [showPrintPage, setShowPrintPage] = useRecoilState(showPrintPageAtom);
  const [currentRates, setCurrentRates] = useRecoilState(currentRatesAtom);
  const previousView = useRecoilValue(previousViewAtom);
  const dashboardQuotes = useRecoilValue(dashboardQuotesAtom);
  const ihsProgramData = useRecoilValue(ihsProgramDataAtom);
  const capitalCosts = useRecoilValue(capitalCostsAtom);
  const [cachedRates, setCachedRates] = useRecoilState(cachedRatesAtom);
  const setLoadingMessage = useSetRecoilState(loadingMessageSelector);
  const setQuoteAttachments = useSetRecoilState(quoteAttachmentsAtom);
  const [currentView, setCurrentView] = useRecoilState(currentViewSelector);
  const showInvestPage = useRecoilValue(showInvestPageAtom);
  const alert = useAlert();
  
  // set page title
  useEffect(() => {
    const quoteExt = quoteData ? ` | QUOTE ${quoteData.quoteNumber}` : '';
    document.title = `NIFCO | ${CONFIG.APP_DISPLAY_NAME}${quoteExt}`;
  }, [quoteData]);
  
  useEffect(() => {
    if(quoteData && quoteData.quoteNumber) {
      const updateQuoteAuxData = async () => {
        try {
          setLoadingMessage('Loading quote data');
          const auxData = await Promise.all([
            getQuoteComments(accessToken, quoteData.quoteId),
            getQuoteActivityLogs(accessToken, quoteData.quoteId),
            getAttachmentRecords(accessToken, quoteData.quoteId, quoteData.revision),
            getQuoteNewPartNumbers(accessToken, quoteData.quoteId, quoteData.revision),
          ]);
          setQuoteComments(auxData[0]);
          setQuoteActivities(auxData[1]);
          setQuoteAttachments(auxData[2].sort((a, b) => cmp(a.createdTime, b.createdTime)));
          setQuoteNewPartNumbers(auxData[3]);
        }
        catch(e) {
          console.error('Quote.updateQuoteAuxData error ->', e);
        }
        finally {
          setLoadingMessage(null);
        }
      };
      updateQuoteAuxData();
    }
  }, [accessToken, quoteData, setQuoteComments, setQuoteActivities, setQuoteAttachments, setLoadingMessage, setQuoteNewPartNumbers]);
  
  useEffect(() => {
    if(quoteData && quoteData.quoteNumber) {
      const getQuoteRates = async refTime => {
        let rates = cachedRates.find(o => o.startTime <= refTime && o.endTime > refTime);
        rates = rates ? rates.rates : null;
        if(!rates) {
          rates = await getRates(accessToken, refTime);
          if(!rates || !rates.length) return;
          setCachedRates(prevState => {
            const newCachedRates = cloneDeep(prevState);
            const rateEndTime = Math.min(...rates.map(r => r.rateEndTime));
            newCachedRates.push({
              startTime: Math.max(...rates.map(r => r.rateStartTime)),
              endTime: rateEndTime ? rateEndTime : 32503680000000,
              rates,
            });
            return newCachedRates;
          });
        }
        return rates;
      };
      getQuoteRates(quoteData.createdTime)
        .then(rates => {
          if(rates) setCurrentRates(rates);
          else getQuoteRates(Date.now())
            .then(nowRates => setCurrentRates(nowRates));
        });
    }
  }, [accessToken, quoteData, setCachedRates, cachedRates, setCurrentRates]);
  
  useEffect(() => {
    if(saveQuoteTrigger) {
      quoteProcessor.setUserRoles(appRoles);
      quoteProcessor.setGlobalData({
        dueDate: quoteData.createdBy === userId && dueDateValue && dueDateValue.length ?
          DateTime.fromISO(dueDateValue).toMillis() :
          quoteData.dueDate ?
            quoteData.dueDate :
            null,
        prodStartDate: prodStartDateValue && prodStartDateValue.length ? DateTime.fromISO(prodStartDateValue).toMillis() : null,
        fgLocation: fgLocationSelect !== 'NULL' ? fgLocationSelect : null,
        lateReason: lateReasonValue !== 'NULL' ? lateReasonLov.find(o => o.code === lateReasonValue) : null,
        lateComment: lateCommentValue.trim().length ? lateCommentValue.trim() : null,
      });
      quoteProcessor.setCompleteData({
        rfqRequestDate: rfqRequestValue && rfqRequestValue.length ? DateTime.fromISO(rfqRequestValue).toMillis() : null,
        quoteIssuedDate: quoteIssuedValue && quoteIssuedValue.length ? DateTime.fromISO(quoteIssuedValue).toMillis() : null,
        dateOfNotice: dateOfNoticeValue && dateOfNoticeValue.length ? DateTime.fromISO(dateOfNoticeValue).toMillis() : null,
        fgPrice: fgPriceValue && !isNaN(parseFloat(fgPriceValue)) ? parseFloat(fgPriceValue) : null,
        royaltyPercentage: royaltyPercentageValue && !isNaN(parseFloat(royaltyPercentageValue)) ? parseFloat(royaltyPercentageValue) : null,
        awardProbability: awardProbabilitySelect !== 'NULL' ? awardProbabilitySelect : null,
        quoteStatus: quoteStatusSelect !== 'NULL' ? quoteStatusSelect : null,
        lostReason: lostReasonValue !== 'NULL' ? lostReasonValue : null,
        selectedToolingOption: toolingOptionSelect,
      });
      quoteProcessor.setRejectPreFeasibilityComments(rejectionPreFeasibilityCommentValue.trim().length ? rejectionPreFeasibilityCommentValue.trim() : null);
      quoteProcessor.setRejectFeasibilityComments(sendBackCommentValue.trim().length ? sendBackCommentValue.trim() : null);
      quoteProcessor.setRejectSalesCheckComments(sendBackFeasibilityCommentValue.trim().length ? sendBackFeasibilityCommentValue.trim() : null);
      quoteProcessor.setLostReasonComments(lostReasonCommentValue.trim().length ? lostReasonCommentValue.trim() : null);
      quoteProcessor.setProductType(productTypeLov.find(o => o.code === generalProductTypeSelect));
      quoteProcessor.setAssignees({
        feasibilityAssignee: feasibilityAssigneeSelect !== 'NULL' ? feasibilityUsersLov.find(o => o.code === feasibilityAssigneeSelect) : null,
        designEstimatorAssignee: designEstimatorAssigneeSelect !== 'NULL' ? designEstimatorUsersLov.find(o => o.code === designEstimatorAssigneeSelect) : null,
        toolingEstimatorAssignee: toolingEstimatorAssigneeSelect !== 'NULL' ? toolingEstimatorUsersLov.find(o => o.code === toolingEstimatorAssigneeSelect) : null,
        manufacturingEstimatorAssignee: manufacturingEstimatorAssigneeSelect !== 'NULL' ? manufacturingEstimatorUsersLov.find(o => o.code === manufacturingEstimatorAssigneeSelect) : null,
        purchasingEstimatorAssignee: purchasingEstimatorAssigneeSelect !== 'NULL' ? purchasingEstimatorUsersLov.find(o => o.code === purchasingEstimatorAssigneeSelect) : null,
        sendBackDesignEstimatorAssignee: isSendingQuoteBackToDesignEstimator ? {
          code: quoteData.designEstimationSubmittedUserId,
          name: quoteData.designEstimationSubmittedUsername,
        } : null,
        sendBackToolingEstimatorAssignee: isSendingQuoteBackToToolingEstimator ? {
          code: quoteData.toolingEstimationSubmittedUserId,
          name: quoteData.toolingEstimationSubmittedUsername,
        } : null,
        sendBackManufacturingEstimatorAssignee: isSendingQuoteBackToManufacturingEstimator ? {
          code: quoteData.manufacturingEstimationSubmittedUserId,
          name: quoteData.manufacturingEstimationSubmittedUsername,
        } : null,
        sendBackPurchasingEstimatorAssignee: isSendingQuoteBackToPurchasingEstimator ? {
          code: quoteData.purchasingEstimationSubmittedUserId,
          name: quoteData.purchasingEstimationSubmittedUsername,
        } : null,
      });
      quoteProcessor.setReassignees({
        ownerAssignee: reassignOwnerSelect !== 'NULL' ? salesManagerUsersLov.find(o => o.code === reassignOwnerSelect) : null,
        feasibilityAssignee: reassignFeasibilitySelect !== 'NULL' ? designEstimatorUsersLov.find(o => o.code === reassignFeasibilitySelect) : null,
        designEstimatorAssignee: reassignDesignEstimatorSelect !== 'NULL' ? designEstimatorUsersLov.find(o => o.code === reassignDesignEstimatorSelect) : null,
        toolingEstimatorAssignee: reassignToolingEstimatorSelect !== 'NULL' ? toolingEstimatorUsersLov.find(o => o.code === reassignToolingEstimatorSelect) : null,
        manufacturingEstimatorAssignee: reassignManufacturingEstimatorSelect !== 'NULL' ? manufacturingEstimatorUsersLov.find(o => o.code === reassignManufacturingEstimatorSelect) : null,
        purchasingEstimatorAssignee: reassignPurchasingEstimatorSelect !== 'NULL' ? purchasingEstimatorUsersLov.find(o => o.code === reassignPurchasingEstimatorSelect) : null,
      });
      quoteProcessor.setCancellationData({
        reason: cancelReasonValue !== 'NULL' ? cancelReasonLov.find(o => o.code === cancelReasonValue) : null,
        comment: cancelCommentValue,
      });
      quoteProcessor.setTakeBackData({
        isTakingBackDesignEstimation,
        isTakingBackToolingEstimation,
        isTakingBackManufacturingEstimation,
        isTakingBackPurchasingEstimation,
      });
    }
  }, [
    lateReasonLov,
    lateReasonValue,
    lateCommentValue,
    awardProbabilitySelect,
    dateOfNoticeValue,
    fgPriceValue,
    royaltyPercentageValue,
    lostReasonValue,
    lostReasonCommentValue,
    quoteIssuedValue,
    quoteStatusSelect,
    rfqRequestValue,
    saveQuoteTrigger,
    quoteProcessor,
    dueDateValue,
    prodStartDateValue,
    fgLocationSelect,
    productTypeLov,
    generalProductTypeSelect,
    designEstimatorAssigneeSelect,
    designEstimatorUsersLov,
    toolingEstimatorAssigneeSelect,
    toolingEstimatorUsersLov,
    manufacturingEstimatorAssigneeSelect,
    manufacturingEstimatorUsersLov,
    purchasingEstimatorAssigneeSelect,
    purchasingEstimatorUsersLov,
    salesManagerUsersLov,
    rejectionPreFeasibilityCommentValue,
    sendBackCommentValue,
    appRoles,
    cancelCommentValue,
    cancelReasonValue,
    cancelReasonLov,
    feasibilityAssigneeSelect,
    feasibilityUsersLov,
    isSendingQuoteBackToDesignEstimator,
    isSendingQuoteBackToToolingEstimator,
    isSendingQuoteBackToManufacturingEstimator,
    isSendingQuoteBackToPurchasingEstimator,
    quoteData,
    sendBackFeasibilityCommentValue,
    toolingOptionSelect,
    reassignOwnerSelect,
    reassignFeasibilitySelect,
    reassignDesignEstimatorSelect,
    reassignToolingEstimatorSelect,
    reassignManufacturingEstimatorSelect,
    reassignPurchasingEstimatorSelect,
    isTakingBackDesignEstimation,
    isTakingBackToolingEstimation,
    isTakingBackManufacturingEstimation,
    isTakingBackPurchasingEstimation,
    userId,
  ]);
  
  useEffect(() => {
    if(initValues && quoteData) {
      setDueDateValue(quoteData.dueDate != null ? DateTime.fromMillis(quoteData.dueDate).toISODate() : '');
      setProdStartDateValue(quoteData.prodStartDate != null ? DateTime.fromMillis(quoteData.prodStartDate).toISODate() : '');
      setFgLocationSelect(quoteData.fgLocation ? quoteData.fgLocation : 'NULL');
      setRfqRequestValue(quoteData.rfqRequestDate != null ? DateTime.fromMillis(quoteData.rfqRequestDate).toISODate() : '');
      setQuoteIssuedValue(quoteData.quoteIssuedDate != null ? DateTime.fromMillis(quoteData.quoteIssuedDate).toISODate() : '');
      setDateOfNoticeValue(quoteData.dateOfNotice != null ? DateTime.fromMillis(quoteData.dateOfNotice).toISODate() : '');
      setFgPriceValue(quoteData.fgPrice ? `${quoteData.fgPrice}` : '');
      setRoyaltyPercentageValue(quoteData.royaltyPercentage ? `${quoteData.royaltyPercentage}` : '');
      setAwardProbabilitySelect(quoteData.awardProbability ? quoteData.awardProbability : 'NULL');
      setToolingOptionSelect(quoteData.selectedToolingOption ? quoteData.selectedToolingOption : 'OPTION_A');
      setInitValues(false);
      setIsSubmittingQuote(false);
      setIsReassigningQuote(false);
      setIsSubmittingEstimation(false);
      setIsPreFeasibilityApprovingQuote(false);
      setIsPreFeasibilityRejectingQuote(false);
      setLateReasonValue(quoteData.lateReason != null ? quoteData.lateReason.code : 'NULL');
      setLateCommentValue(quoteData.lateComment != null ? quoteData.lateComment : '');
    }
  }, [
    quoteData,
    initValues,
    setIsSubmittingQuote,
    setIsSubmittingEstimation,
    setIsPreFeasibilityApprovingQuote,
    setIsPreFeasibilityRejectingQuote,
    setFgLocationSelect,
    setIsReassigningQuote,
    setInitValues,
  ]);
  
  // get pieces per vehicle
  const ihsDataYearsAll = ihsProgramData?.years?.filter(o => quoteData?.programs?.some(p => p.programPlantId === o.programPlantId));
  const ihsDataMonthsAll = ihsProgramData?.months?.filter(o => quoteData?.programs?.some(p => p.programPlantId === o.programPlantId));
  const ihsDataYears = ihsDataYearsAll?.reduce((arr, cur) => {
    for(let obj of cur.data) {
      const yearIndex = arr.findIndex(o => o.year === obj.year);
      if(yearIndex < 0) arr.push({ ...obj });
      else arr[yearIndex].value += obj.value;
    }
    return arr;
  }, []);
  const ihsDataMonths = ihsDataMonthsAll?.reduce((arr, cur) => {
    for(let obj of cur.data) {
      const monthYearIndex = arr.findIndex(o => o.monthYear === obj.monthYear);
      if(monthYearIndex < 0) arr.push({ ...obj });
      else arr[monthYearIndex].value += obj.value;
    }
    return arr;
  }, []);
  const now = DateTime.now();
  const currentYear = now.year;
  const currentMonth = now.month;
  const actualYears = ihsDataYears ? ihsDataYears.filter(o => o.year < currentYear && o.value > 0).sort((a, b) => cmp(a.year, b.year)) : [];
  const actualMonths = ihsDataMonths ? ihsDataMonths.filter(o => o.year === currentYear && o.month < currentMonth && o.value > 0) : [];
  const avgPartsPerMonthActual = actualYears.length + actualMonths.length > 0 ?
    Math.round((actualYears.reduce((total, cur) => total + cur.value, 0) + actualMonths.reduce((total, cur) => total + cur.value, 0)) / ((actualYears.length * 12) + actualMonths.length))
    : 0;
  const futureYears = ihsDataYears ? ihsDataYears.filter(o => o.year > currentYear && o.value > 0).sort((a, b) => cmp(a.year, b.year)) : [];
  const futureMonths = ihsDataMonths ? ihsDataMonths.filter(o => o.year === currentYear && o.month >= currentMonth && o.value > 0) : [];
  const avgPartsPerMonthFuture = futureYears.length + futureMonths.length > 0 ?
    Math.round((futureYears.reduce((total, cur) => total + cur.value, 0) + futureMonths.reduce((total, cur) => total + cur.value, 0)) / ((futureYears.length * 12) + futureMonths.length))
    : 0;
  
  const confirmDeleteQuote = () => {
    if(window.confirm(`Are you sure you want to delete this quote (${quoteData.quoteNumber})? This is irreversible!`))
      deleteQuote();
  };
  
  const deleteQuote = async () => {
    try {
      if(!quoteProcessor || !quoteData) return;
      logger.log(accessToken, `User clicked delete quote (${quoteData.quoteId} rev ${quoteData.revision})`, userId, loggedInUser);
      setLoadingMessage('Deleting quote');
      setSaveQuoteTrigger(true);
      await quoteProcessor.processQuote(accessToken, userId, loggedInUser, quoteData, quoteProcessor.ACTIONS.DELETE);
      alert.success('Quote deleted');
      logger.log(accessToken, `Quote was deleted successfully (${quoteData.quoteId} rev ${quoteData.revision})`, userId, loggedInUser);
      setCurrentView(previousView);
      setQuoteData(null);
      setShowPrintPage(false);
    }
    catch(e) {
      console.error('Quote.deleteQuote error ->', e);
      alert.error('Quote delete error');
      logger.log(accessToken, `Quote delete error (${quoteData.quoteId} rev ${quoteData.revision}) -> ${JSON.stringify(e)}`, userId, loggedInUser);
    }
    finally {
      setSaveQuoteTrigger(false);
      setLoadingMessage(null);
    }
  };
  
  const confirmDeleteSandboxQuote = () => {
    if(window.confirm(`Are you sure you want to delete this sandbox quote (${quoteData.quoteNumber})? This is irreversible!`))
      deleteSandboxQuote();
  };
  
  const deleteSandboxQuote = async () => {
    try {
      if(!quoteProcessor || !quoteData) return;
      logger.log(accessToken, `User clicked delete sandbox quote (${quoteData.quoteId} rev ${quoteData.revision})`, userId, loggedInUser);
      setLoadingMessage('Deleting quote');
      setSaveQuoteTrigger(true);
      await quoteProcessor.processQuote(accessToken, userId, loggedInUser, quoteData, quoteProcessor.ACTIONS.DELETE_SANDBOX);
      alert.success('Quote deleted');
      logger.log(accessToken, `Sandbox quote was deleted successfully (${quoteData.quoteId} rev ${quoteData.revision})`, userId, loggedInUser);
      setCurrentView(previousView);
      setQuoteData(null);
      setShowPrintPage(false);
    }
    catch(e) {
      console.error('Quote.deleteSandboxQuote error ->', e);
      alert.error('Quote delete error');
      logger.log(accessToken, `Sandbox quote delete error (${quoteData.quoteId} rev ${quoteData.revision}) -> ${JSON.stringify(e)}`, userId, loggedInUser);
    }
    finally {
      setSaveQuoteTrigger(false);
      setLoadingMessage(null);
    }
  };
  
  const confirmCancelQuote = () => {
    if(window.confirm(`Are you sure you want to cancel this quote (${quoteData.quoteNumber})? This is irreversible!`))
      cancelQuote();
  };
  
  const cancelQuote = async () => {
    try {
      if(!quoteProcessor || !quoteData) return;
      logger.log(accessToken, `User clicked cancel quote (${quoteData.quoteId} rev ${quoteData.revision})`, userId, loggedInUser);
      setLoadingMessage('Cancelling quote');
      setSaveQuoteTrigger(true);
      await quoteProcessor.processQuote(accessToken, userId, loggedInUser, quoteData, quoteProcessor.ACTIONS.CANCEL);
      alert.success('Quote cancelled');
      logger.log(accessToken, `Quote was cancelled successfully (${quoteData.quoteId} rev ${quoteData.revision})`, userId, loggedInUser);
      setCurrentView(previousView);
      setQuoteData(null);
      setShowPrintPage(false);
    }
    catch(e) {
      console.error('Quote.cancelQuote error ->', e);
      alert.error('Quote cancel error');
      logger.log(accessToken, `Quote cancel error (${quoteData.quoteId} rev ${quoteData.revision}) -> ${JSON.stringify(e)}`, userId, loggedInUser);
    }
    finally {
      setSaveQuoteTrigger(false);
      setLoadingMessage(null);
    }
  };
  
  const quoteDisplayOption = quoteStatusLov && quoteStatusLov.length ?
    quoteStatusLov.find(o => o.code === quoteData.status) : null;
  const feasibilityAssignment = quoteData && quoteData.assignments ?
    quoteData.assignments.find(o => o.types.includes('FEASIBILITY')) :
    null;
  const designEstimatorAssignment = quoteData && quoteData.assignments ?
    quoteData.assignments.find(o => o.types.includes('DESIGN_ESTIMATOR')) :
    null;
  const toolingEstimatorAssignment = quoteData && quoteData.assignments ?
    quoteData.assignments.find(o => o.types.includes('TOOLING_ESTIMATOR')) :
    null;
  const manufacturingEstimatorAssignment = quoteData && quoteData.assignments ?
    quoteData.assignments.find(o => o.types.includes('MANUFACTURING_ESTIMATOR')) :
    null;
  const purchasingEstimatorAssignment = quoteData && quoteData.assignments ?
    quoteData.assignments.find(o => o.types.includes('PURCHASING_ESTIMATOR')) :
    null;
  const feasibilityAssignmentUserId = feasibilityAssignment ? feasibilityAssignment.userId : null;
  const designEstimatorAssignmentUserId = designEstimatorAssignment ? designEstimatorAssignment.userId : null;
  const toolingEstimatorAssignmentUserId = toolingEstimatorAssignment ? toolingEstimatorAssignment.userId : null;
  const manufacturingEstimatorAssignmentUserId = manufacturingEstimatorAssignment ? manufacturingEstimatorAssignment.userId : null;
  const purchasingEstimatorAssignmentUserId = purchasingEstimatorAssignment ? purchasingEstimatorAssignment.userId : null;
  
  const submittedDesignEstimatorUserId = quoteData?.designEstimationSubmittedUserId;
  const submittedToolingEstimatorUserId = quoteData?.toolingEstimationSubmittedUserId;
  const submittedManufacturingEstimatorUserId = quoteData?.manufacturingEstimationSubmittedUserId;
  const submittedPurchasingEstimatorUserId = quoteData?.purchasingEstimationSubmittedUserId;
  
  const selectedProductTypeObj = productTypeLov.find(o => o.code === generalProductTypeSelect);
  
  const displayFeasibilityTab = (quoteData.status === 'FEASIBILITY' && ((appRoles.includes(CONFIG.ROLE_MAPPINGS.FEASIBILITY) &&
      feasibilityAssignmentUserId === userId) || quoteData.createdBy === userId)) ||
    ['SALES_APPROVAL', 'COMPLETED', 'AT_CUSTOMER', 'AWARDED', 'LOST'].includes(quoteData.status);
  
  const capitalCalculations = getCapitalCalculations({ capitalCosts });
  
  const calculations = getFinishedGoodCalculations({
    rates: currentRates,
    moldedParts: quoteData.moldedParts ? quoteData.moldedParts : [],
    monthlyVolume: quoteData.monthlyVolume,
    partType: quoteData.partType,
    purchasedPartCosts: quoteData.newPurchasedParts ? quoteData.newPurchasedParts : [],
    carryoverPartCosts: quoteData.carryoverPurchasedParts ? quoteData.carryoverPurchasedParts : [],
    assemblyCosts: quoteData.assemblyCosts ? quoteData.assemblyCosts.map(o => ({
      description: o.assemblyType,
      location: o.location,
      pphPerPerson: o.pphPerPerson,
    })) : [],
    fgLocation: quoteData.fgLocation ? getRateOrgCodeFromLocator(quoteData.fgLocation) : null,
    inspectionType: quoteData.assemblyInspection,
    packagingType: quoteData.assemblyPackaging,
    packagingCosts: quoteData.packagingCosts ? quoteData.packagingCosts.map(o => ({
      ...o,
      quantity: `${o.quantity}`,
    })) : [],
    auxCosts: quoteData.auxCosts ? quoteData.auxCosts.map(o => ({
      ...o,
      quantity: `${o.quantity}`,
      unitCost: `${o.unitCost}`,
    })) : [],
    rMexFreightCost: quoteData.rMexFreightCost ? quoteData.rMexFreightCost : 0,
    testingCosts: quoteData.testingCosts ? quoteData.testingCosts : [],
    bom: quoteData.bom,
    productType: quoteData.productType,
    fgPrice: fgPriceValue && !isNaN(parseFloat(fgPriceValue)) ? parseFloat(fgPriceValue) : null,
    royaltyPercentage: royaltyPercentageValue && !isNaN(parseFloat(royaltyPercentageValue)) ? parseFloat(royaltyPercentageValue) : null,
  });
  
  const disableAllInputs = quoteData.status !== 'SANDBOX' && ((quoteData.holdUserId !== userId && currentView !== 'CREATE_QUOTE') || quoteData.isNotActive ||
    (![
        feasibilityAssignmentUserId,
        designEstimatorAssignmentUserId,
        toolingEstimatorAssignmentUserId,
        purchasingEstimatorAssignmentUserId,
        manufacturingEstimatorAssignmentUserId,
        quoteData.createdBy,
      ].includes(userId) &&
      (![
        submittedDesignEstimatorUserId,
        submittedToolingEstimatorUserId,
        submittedManufacturingEstimatorUserId,
        submittedPurchasingEstimatorUserId,
      ].includes(userId) && quoteData.status === 'IN_PROGRESS')));
  
  const quoteIsLate = quoteData?.dueDate ? quoteData.dueDate < DateTime.now().set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  }).toMillis() : false;
  
  const fgNewPartObj = quoteNewPartNumbers?.find(o => o.partName === quoteData?.partDescription);
  
  if(quoteData == null) return null;
  return (
    <>
      <PrintPage quoteData={quoteData} style={{ display: showPrintPage ? undefined : 'none' }}/>
      <InvestPage quoteData={quoteData} style={{ display: showInvestPage ? undefined : 'none' }}/>
      <div style={{ ...styles.mainQuoteContainer, display: showPrintPage || showInvestPage ? 'none' : undefined }}
           id="quote">
        <div style={styles.mainQuoteRow}>
          <div style={styles.mainQuoteLeftCol}>
            {
              !['DRAFT', 'SANDBOX', 'PRE_FEASIBILITY'].includes(quoteData.status) ?
                <div style={styles.quoteSummaryBox}>
                  <table style={{ width: '100%' }}>
                    <tbody>
                    <tr>
                      <td style={styles.boldCell}>Manufacture Type:</td>
                      <td>{selectedProductTypeObj ? selectedProductTypeObj.name : 'UNKNOWN'}</td>
                      <td>Purchase Component - <span
                        style={styles.boldCell}>{`${selectedProductTypeObj && selectedProductTypeObj.purchased ? 'Yes' : 'No'}`}</span>
                      </td>
                      <td>Requires Assembly - <span
                        style={styles.boldCell}>{`${selectedProductTypeObj && selectedProductTypeObj.assembled ? 'Yes' : 'No'}`}</span>
                      </td>
                    </tr>
                    <tr>
                      <td style={styles.boldCell}>Customer:</td>
                      <td colSpan="3">{quoteData.customerName}</td>
                    </tr>
                    <tr>
                      <td style={styles.boldCell}>Customer Part Number:</td>
                      <td colSpan="3">{quoteData.customerPartNumber}</td>
                    </tr>
                    <tr>
                      <td style={styles.boldCell}>Part Description:</td>
                      <td
                        colSpan="3">{`${quoteData.partDescription}${fgNewPartObj ? ` (${fgNewPartObj.partNumber})` : ''}`}</td>
                    </tr>
                    <tr>
                      <td style={styles.boldCell}>Monthly Volume:</td>
                      <td colSpan="3">{quoteData.monthlyVolume.toLocaleString()}</td>
                    </tr>
                    <tr>
                      <td style={styles.boldCell}>Monthly Car Volume:</td>
                      <td
                        colSpan="3">{quoteData.monthlyCarVolume ? quoteData.monthlyCarVolume.toLocaleString() : ''}</td>
                    </tr>
                    <tr>
                      <td style={styles.boldCell}>Pieces Per Vehicle (Actual):</td>
                      <td
                        colSpan="3">{quoteData.monthlyVolume && avgPartsPerMonthActual ? (quoteData.monthlyVolume / avgPartsPerMonthActual).toFixed(2) : ''}</td>
                    </tr>
                    <tr>
                      <td style={styles.boldCell}>Pieces Per Vehicle (Future):</td>
                      <td
                        colSpan="3">{quoteData.monthlyVolume && avgPartsPerMonthFuture ? (quoteData.monthlyVolume / avgPartsPerMonthFuture).toFixed(2) : ''}</td>
                    </tr>
                    <tr>
                      <td style={styles.boldCell}>Target Price:</td>
                      <td
                        colSpan="3">{quoteData.targetPrice ? `$${quoteData.targetPrice.toLocaleString()}` : 'N/A'}</td>
                    </tr>
                    <tr>
                      <td style={styles.boldCell}>Finish Good Price:</td>
                      <td colSpan="3">{quoteData.fgPrice ? `$${quoteData.fgPrice.toLocaleString()}` : 'N/A'}</td>
                    </tr>
                    </tbody>
                  </table>
                </div> :
                null
            }
            <Tabs defaultActiveKey="GENERAL">
              <Tab eventKey="GENERAL" title="General">
                <div style={styles.tabContainer}>
                  <QuoteTabGeneral/>
                  <CommentsSection/>
                  <ActivityLogSection/>
                </div>
              </Tab>
              <Tab
                eventKey="BOM"
                title="BOM"
                disabled={quoteData.status === 'DRAFT' || quoteData.status === 'PRE_FEASIBILITY' ||
                  quoteData.status === 'NOT_FEASIBLE'}
              >
                <div style={styles.tabContainer}>
                  <QuoteTabBom/>
                  <CommentsSection/>
                  <ActivityLogSection/>
                </div>
              </Tab>
              <Tab
                eventKey="MOLDED"
                title="Molded"
                disabled={quoteData.status === 'DRAFT' || quoteData.status === 'PRE_FEASIBILITY' ||
                  quoteData.status === 'NOT_FEASIBLE'}
              >
                <div style={styles.tabContainer}>
                  <QuoteTabMolded/>
                  <CommentsSection/>
                  <ActivityLogSection/>
                </div>
              </Tab>
              <Tab
                eventKey="ASSEMBLED"
                title="Assembled"
                disabled={quoteData.status === 'DRAFT' || quoteData.status === 'PRE_FEASIBILITY' ||
                  quoteData.status === 'NOT_FEASIBLE'}
              >
                <div style={styles.tabContainer}>
                  <QuoteTabAssembled/>
                  <CommentsSection/>
                  <ActivityLogSection/>
                </div>
              </Tab>
              <Tab
                eventKey="PURCHASED"
                title="Purchased"
                disabled={quoteData.status === 'DRAFT' || quoteData.status === 'PRE_FEASIBILITY' ||
                  quoteData.status === 'NOT_FEASIBLE'}
              >
                <div style={styles.tabContainer}>
                  <QuoteTabPurchased/>
                  <CommentsSection/>
                  <ActivityLogSection/>
                </div>
              </Tab>
              <Tab
                eventKey="PACKAGING"
                title="Packaging"
                disabled={quoteData.status === 'DRAFT' || quoteData.status === 'PRE_FEASIBILITY' ||
                  quoteData.status === 'NOT_FEASIBLE'}
              >
                <div style={styles.tabContainer}>
                  <QuoteTabPackaging/>
                  <CommentsSection/>
                  <ActivityLogSection/>
                </div>
              </Tab>
              <Tab
                eventKey="AUXILIARY"
                title="Auxiliary"
                disabled={quoteData.status === 'DRAFT' || quoteData.status === 'PRE_FEASIBILITY' ||
                  quoteData.status === 'NOT_FEASIBLE'}
              >
                <div style={styles.tabContainer}>
                  <QuoteTabAuxiliary/>
                  <CommentsSection/>
                  <ActivityLogSection/>
                </div>
              </Tab>
              <Tab
                eventKey="TESTING"
                title="Testing"
                disabled={quoteData.status === 'DRAFT' || quoteData.status === 'PRE_FEASIBILITY' ||
                  quoteData.status === 'NOT_FEASIBLE'}
              >
                <div style={styles.tabContainer}>
                  <QuoteTabTesting/>
                  <CommentsSection/>
                  <ActivityLogSection/>
                </div>
              </Tab>
              <Tab
                eventKey="CAPITAL"
                title="Capital"
                disabled={quoteData.status === 'DRAFT' || quoteData.status === 'PRE_FEASIBILITY' ||
                  quoteData.status === 'NOT_FEASIBLE'}
              >
                <div style={styles.tabContainer}>
                  <QuoteTabCapital/>
                  <CommentsSection/>
                  <ActivityLogSection/>
                </div>
              </Tab>
              <Tab
                eventKey="FINISH_GOOD"
                title="Finish Good"
              >
                <div style={styles.tabContainer}>
                  <QuoteTabFinishGood/>
                  <CommentsSection/>
                  <ActivityLogSection/>
                </div>
              </Tab>
              {
                displayFeasibilityTab ?
                  <Tab
                    eventKey="FEASIBILITY"
                    title="Feasibility"
                    disabled={quoteData.status === 'DRAFT' || quoteData.status === 'PRE_FEASIBILITY' ||
                      quoteData.status === 'NOT_FEASIBLE'}
                  >
                    <div style={styles.tabContainer}>
                      <QuoteTabFeasibility/>
                      <CommentsSection/>
                      <ActivityLogSection/>
                    </div>
                  </Tab> :
                  null
              }
              {
                ['COMPLETED', 'AT_CUSTOMER', 'AWARDED'].includes(quoteData.status) ?
                  <Tab
                    eventKey="NEW_PARTS"
                    title="New Parts"
                  >
                    <div style={styles.tabContainer}>
                      <QuoteTabNewParts/>
                      <CommentsSection/>
                      <ActivityLogSection/>
                    </div>
                  </Tab> :
                  null
              }
            </Tabs>
          </div>
          {!displayFeasibilityTab ? <QuoteTabFeasibility hide="true"/> : null}
          <div style={styles.sideBar}>
            {
              quoteData.quoteNumber ?
                <>
                  <h5 style={styles.lineTitle}>
                    <span style={styles.lineTitleText}>Identifiers</span>
                  </h5>
                  <div style={styles.sideBarContainer}>
                    <div style={styles.sideBarRow}>
                      <div style={styles.sideBarTitleCol}>
                        <p style={styles.noMarginText}>Quote #</p>
                      </div>
                      <div style={styles.sideBarCol}>
                        <p style={{ ...styles.noMarginText, fontWeight: 'bold' }}>{quoteData.quoteNumber}</p>
                      </div>
                    </div>
                    <div style={styles.sideBarRow}>
                      <div style={styles.sideBarTitleCol}>
                        <p style={styles.noMarginText}>Revision #</p>
                      </div>
                      <div style={styles.sideBarCol}>
                        <p style={{ ...styles.noMarginText, fontWeight: 'bold' }}>{quoteData.revision}</p>
                      </div>
                    </div>
                  </div>
                </> :
                null
            }
            <h5 style={styles.lineTitle}>
              <span style={styles.lineTitleText}>Status</span>
            </h5>
            <div style={styles.statusContainer}>
              <h2
                style={{
                  ...styles.noMarginText,
                  textTransform: 'uppercase',
                }}
              >
                <Badge bg={getStatusBadgeType(quoteData.status)}>
                  {quoteDisplayOption ? quoteDisplayOption.name : quoteData.status}
                </Badge>
              </h2>
              {
                quoteData.status === 'LOST' && quoteData.lostReason != null && reasonLostLov.find(o => o.code === quoteData.lostReason) ?
                  <h5
                    style={{
                      ...styles.noMarginText,
                      marginTop: 18,
                    }}
                  >
                    {`Reason: ${reasonLostLov.find(o => o.code === quoteData.lostReason).name}`}
                  </h5> :
                  null
              }
              {
                quoteData.awardProbability != null && awardProbabilityLov.find(o => o.code === quoteData.awardProbability) ?
                  <h5
                    style={{
                      ...styles.noMarginText,
                      marginTop: 18,
                    }}
                  >
                    {`Probability: ${awardProbabilityLov.find(o => o.code === quoteData.awardProbability).name}`}
                  </h5> :
                  null
              }
            </div>
            <h5 style={styles.lineTitle}>
              <span style={styles.lineTitleText}>People</span>
            </h5>
            <div style={styles.sideBarContainer}>
              <div style={styles.sideBarRow}>
                <div style={styles.sideBarTitleCol}>
                  <p style={styles.noMarginText}>Requester</p>
                </div>
                <div style={styles.sideBarCol}>
                  <p style={{ ...styles.noMarginText, fontWeight: 'bold' }}>{quoteData.createdByUsername}</p>
                </div>
              </div>
              {
                quoteData.holdUsername ?
                  <div style={styles.sideBarRow}>
                    <div style={styles.sideBarTitleCol}>
                      <p style={styles.noMarginText}>Editing</p>
                    </div>
                    <div style={styles.sideBarCol}>
                      <p style={{ ...styles.noMarginText, fontWeight: 'bold' }}>
                        {`${quoteData.holdUsername} (${DateTime.fromMillis(quoteData.holdTime).toFormat('LL/dd HH:mm')})`}
                      </p>
                    </div>
                  </div> :
                  null
              }
              {
                quoteData.status === 'PRE_FEASIBILITY' ?
                  <>
                    <div style={styles.sideBarRow}>
                      <div style={styles.sideBarTitleCol}>
                        <p style={styles.noMarginText}>Feasibility</p>
                      </div>
                      <div style={styles.sideBarCol}>
                        <p style={{ ...styles.noMarginText, fontWeight: 'bold' }}>
                          {feasibilityAssignment ? feasibilityAssignment.username : 'UNKNOWN'}
                          {` - Pending`}
                        </p>
                      </div>
                    </div>
                  </> :
                  null
              }
              {
                quoteData.status === 'FEASIBILITY' ?
                  <>
                    <div style={styles.sideBarRow}>
                      <div style={styles.sideBarTitleCol}>
                        <p style={styles.noMarginText}>Feasibility</p>
                      </div>
                      <div style={styles.sideBarCol}>
                        <p style={{ ...styles.noMarginText, fontWeight: 'bold' }}>
                          {feasibilityAssignment ? feasibilityAssignment.username : 'UNKNOWN'}
                          {` - Pending`}
                        </p>
                      </div>
                    </div>
                  </> :
                  null
              }
              {
                quoteData.status === 'IN_PROGRESS' ?
                  <>
                    <div style={styles.sideBarRow}>
                      <div style={styles.sideBarTitleCol}>
                        <p style={styles.noMarginText}>Design</p>
                      </div>
                      <div style={styles.sideBarCol}>
                        <p style={{ ...styles.noMarginText, fontWeight: 'bold' }}>
                          {quoteData.designEstimationSubmittedUsername ?
                            quoteData.designEstimationSubmittedUsername :
                            designEstimatorAssignment ? designEstimatorAssignment.username : 'UNKNOWN'}
                          {` - `}
                          {quoteData.designEstimationSubmittedUserId ? 'Complete' : 'Pending'}
                        </p>
                      </div>
                    </div>
                    <div style={styles.sideBarRow}>
                      <div style={styles.sideBarTitleCol}>
                        <p style={styles.noMarginText}>Tooling</p>
                      </div>
                      <div style={styles.sideBarCol}>
                        <p style={{ ...styles.noMarginText, fontWeight: 'bold' }}>
                          {quoteData.toolingEstimationSubmittedUsername ?
                            quoteData.toolingEstimationSubmittedUsername :
                            toolingEstimatorAssignment ? toolingEstimatorAssignment.username : 'UNKNOWN'}
                          {` - `}
                          {quoteData.toolingEstimationSubmittedUserId ? 'Complete' : 'Pending'}
                        </p>
                      </div>
                    </div>
                    {
                      selectedProductTypeObj && selectedProductTypeObj.assembled ?
                        <div style={styles.sideBarRow}>
                          <div style={styles.sideBarTitleCol}>
                            <p style={styles.noMarginText}>Manufacturing</p>
                          </div>
                          <div style={styles.sideBarCol}>
                            <p style={{ ...styles.noMarginText, fontWeight: 'bold' }}>
                              {quoteData.manufacturingEstimationSubmittedUsername ?
                                quoteData.manufacturingEstimationSubmittedUsername :
                                manufacturingEstimatorAssignment ? manufacturingEstimatorAssignment.username : 'UNKNOWN'}
                              {` - `}
                              {quoteData.manufacturingEstimationSubmittedUserId ? 'Complete' : 'Pending'}
                            </p>
                          </div>
                        </div> :
                        null
                    }
                    {
                      selectedProductTypeObj && selectedProductTypeObj.purchased ?
                        <div style={styles.sideBarRow}>
                          <div style={styles.sideBarTitleCol}>
                            <p style={styles.noMarginText}>Purchasing</p>
                          </div>
                          <div style={styles.sideBarCol}>
                            <p style={{ ...styles.noMarginText, fontWeight: 'bold' }}>
                              {quoteData.purchasingEstimationSubmittedUsername ?
                                quoteData.purchasingEstimationSubmittedUsername :
                                purchasingEstimatorAssignment ? purchasingEstimatorAssignment.username : 'UNKNOWN'}
                              {` - `}
                              {quoteData.purchasingEstimationSubmittedUserId ? 'Complete' : 'Pending'}
                            </p>
                          </div>
                        </div> :
                        null
                    }
                  </> :
                  null
              }
              {
                quoteData.status === 'SALES_APPROVAL' ?
                  <>
                    <div style={styles.sideBarRow}>
                      <div style={styles.sideBarTitleCol}>
                        <p style={styles.noMarginText}>Sales Approval</p>
                      </div>
                      <div style={styles.sideBarCol}>
                        <p style={{ ...styles.noMarginText, fontWeight: 'bold' }}>
                          {quoteData.createdByUsername ? quoteData.createdByUsername : 'UNKNOWN'}
                          {` - Pending`}
                        </p>
                      </div>
                    </div>
                  </> :
                  null
              }
              <div style={styles.sideBarRow}>
                <div
                  style={{
                    ...styles.sideBarTitleCol,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <p style={styles.noMarginText}>FG Location</p>
                </div>
                <div style={styles.sideBarCol}>
                  <Form>
                    <Form.Select
                      as="select"
                      value={fgLocationSelect}
                      onChange={e => setFgLocationSelect(e.target.value)}
                      style={quoteMissingAttributes.includes('GLOBAL|fgLocation') ? GLOBAL_STYLES.errorInput : null}
                      disabled={disableAllInputs ||
                        ['NOT_FEASIBLE', 'SALES_APPROVAL', 'FEASIBILITY', 'COMPLETED', 'AT_CUSTOMER', 'AWARDED', 'LOST']
                          .includes(quoteData.status)}
                    >
                      <option value="NULL">-- Select Location --</option>
                      {fgLocationLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
                    </Form.Select>
                  </Form>
                </div>
              </div>
            </div>
            {
              isSubmittingQuote ?
                <>
                  <h5 style={styles.lineTitle}>
                    <span style={styles.lineTitleText}>Assignments</span>
                  </h5>
                  <div style={styles.sideBarContainer}>
                    <div style={styles.sideBarRow}>
                      <div
                        style={{
                          ...styles.sideBarTitleCol,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <p style={styles.noMarginText}>Feasibility</p>
                      </div>
                      <div style={styles.sideBarCol}>
                        <Form>
                          <Form.Select
                            as="select"
                            value={feasibilityAssigneeSelect}
                            onChange={e => setFeasibilityAssigneeSelect(e.target.value)}
                            style={quoteMissingAttributes.includes('GLOBAL|feasibilityAssignee') ? GLOBAL_STYLES.errorInput : null}
                            disabled={disableAllInputs}
                          >
                            <option value="NULL">-- Select Assignee --</option>
                            {feasibilityUsersLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
                          </Form.Select>
                        </Form>
                      </div>
                    </div>
                  </div>
                </> :
                null
            }
            {
              isPreFeasibilityApprovingQuote && appRoles.includes(CONFIG.ROLE_MAPPINGS.FEASIBILITY) &&
              feasibilityAssignmentUserId === userId ?
                <>
                  <h5 style={styles.lineTitle}>
                    <span style={styles.lineTitleText}>Assignments</span>
                  </h5>
                  <div style={styles.sideBarContainer}>
                    <div style={styles.sideBarRow}>
                      <div
                        style={{
                          ...styles.sideBarTitleCol,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <p style={styles.noMarginText}>Design</p>
                      </div>
                      <div style={styles.sideBarCol}>
                        <Form>
                          <Form.Select
                            as="select"
                            value={designEstimatorAssigneeSelect}
                            onChange={e => setDesignEstimatorAssigneeSelect(e.target.value)}
                            style={quoteMissingAttributes.includes('GLOBAL|designEstimatorAssignee') ? GLOBAL_STYLES.errorInput : null}
                            disabled={disableAllInputs}
                          >
                            <option value="NULL">-- Select Assignee --</option>
                            {designEstimatorUsersLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
                          </Form.Select>
                        </Form>
                      </div>
                    </div>
                    <div style={styles.sideBarRow}>
                      <div
                        style={{
                          ...styles.sideBarTitleCol,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <p style={styles.noMarginText}>Tooling</p>
                      </div>
                      <div style={styles.sideBarCol}>
                        <Form>
                          <Form.Select
                            as="select"
                            value={toolingEstimatorAssigneeSelect}
                            onChange={e => setToolingEstimatorAssigneeSelect(e.target.value)}
                            style={quoteMissingAttributes.includes('GLOBAL|toolingEstimatorAssignee') ? GLOBAL_STYLES.errorInput : null}
                            disabled={disableAllInputs}
                          >
                            <option value="NULL">-- Select Assignee --</option>
                            {toolingEstimatorUsersLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
                          </Form.Select>
                        </Form>
                      </div>
                    </div>
                    {
                      selectedProductTypeObj && selectedProductTypeObj.assembled ?
                        <div style={styles.sideBarRow}>
                          <div
                            style={{
                              ...styles.sideBarTitleCol,
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <p style={styles.noMarginText}>Manufacturing</p>
                          </div>
                          <div style={styles.sideBarCol}>
                            <Form>
                              <Form.Select
                                as="select"
                                value={manufacturingEstimatorAssigneeSelect}
                                onChange={e => setManufacturingEstimatorAssigneeSelect(e.target.value)}
                                style={quoteMissingAttributes.includes('GLOBAL|manufacturingEstimatorAssignee') ? GLOBAL_STYLES.errorInput : null}
                                disabled={disableAllInputs}
                              >
                                <option value="NULL">-- Select Assignee --</option>
                                {manufacturingEstimatorUsersLov.map(o => <option key={o.code}
                                                                                 value={o.code}>{o.name}</option>)}
                              </Form.Select>
                            </Form>
                          </div>
                        </div> :
                        null
                    }
                    {
                      selectedProductTypeObj && selectedProductTypeObj.purchased ?
                        <div style={styles.sideBarRow}>
                          <div
                            style={{
                              ...styles.sideBarTitleCol,
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <p style={styles.noMarginText}>Purchasing</p>
                          </div>
                          <div style={styles.sideBarCol}>
                            <Form>
                              <Form.Select
                                as="select"
                                value={purchasingEstimatorAssigneeSelect}
                                onChange={e => setPurchasingEstimatorAssigneeSelect(e.target.value)}
                                style={quoteMissingAttributes.includes('GLOBAL|purchasingEstimatorAssignee') ? GLOBAL_STYLES.errorInput : null}
                                disabled={disableAllInputs}
                              >
                                <option value="NULL">-- Select Assignee --</option>
                                {purchasingEstimatorUsersLov.map(o => <option key={o.code}
                                                                              value={o.code}>{o.name}</option>)}
                              </Form.Select>
                            </Form>
                          </div>
                        </div> :
                        null
                    }
                  </div>
                </> :
                null
            }
            {
              appRoles.includes(CONFIG.ROLE_MAPPINGS.REASSIGN_QUOTE) && quoteData.status !== 'SANDBOX' ?
                <>
                  <h5 style={styles.lineTitle}>
                    <span style={styles.lineTitleText}>Reassign</span>
                  </h5>
                  <div style={styles.sideBarContainer}>
                    <Form>
                      <Form.Check
                        type="checkbox"
                        label="Reassign This Quote"
                        checked={isReassigningQuote}
                        onChange={e => {
                          if(e.target.checked) {
                            setIsSubmittingQuote(false);
                            setIsSubmittingEstimation(false);
                            setIsPreFeasibilityApprovingQuote(false);
                            setIsPreFeasibilityRejectingQuote(false);
                            setIsCreatingRevisionQuote(false);
                            setIsCreatingSandboxQuote(false);
                            setIsInheritingAttachments(false);
                            setIsSendingQuoteBackToEstimation(false);
                            setIsSendingQuoteBackToDesignEstimator(false);
                            setIsSendingQuoteBackToToolingEstimator(false);
                            setIsSendingQuoteBackToManufacturingEstimator(false);
                            setIsSendingQuoteBackToPurchasingEstimator(false);
                            setIsApprovingSalesCheck(false);
                            setIsSendingBackToFeasibility(false);
                            setIsCancellingQuote(false);
                            setIsDeletingQuote(false);
                            setIsTakingBackToolingEstimation(false);
                            setIsTakingBackToolingEstimation(false);
                            setIsTakingBackManufacturingEstimation(false);
                            setIsTakingBackPurchasingEstimation(false);
                          }
                          setIsReassigningQuote(e.target.checked);
                        }}
                        disabled={(quoteData.holdUserId !== userId && currentView !== 'CREATE_QUOTE') || quoteData.isNotActive || isCancellingQuote || isDeletingQuote}
                      />
                    </Form>
                    {
                      isReassigningQuote ?
                        <>
                          <div style={styles.sideBarRow}>
                            <div
                              style={{
                                ...styles.sideBarTitleCol,
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <p style={styles.noMarginText}>Owner/Creator</p>
                            </div>
                            <div style={styles.sideBarCol}>
                              <Form>
                                <Form.Select
                                  as="select"
                                  value={reassignOwnerSelect}
                                  onChange={e => setReassignOwnerSelect(e.target.value)}
                                  style={quoteMissingAttributes.includes('GLOBAL|reassignOwnerAssignee') ? GLOBAL_STYLES.errorInput : null}
                                >
                                  <option value="NULL">-- Select Assignee --</option>
                                  {salesManagerUsersLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
                                </Form.Select>
                              </Form>
                            </div>
                          </div>
                          {
                            quoteData.assignments.some(o => o.types.includes('FEASIBILITY')) ?
                              <div style={styles.sideBarRow}>
                                <div
                                  style={{
                                    ...styles.sideBarTitleCol,
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <p style={styles.noMarginText}>Feasibility</p>
                                </div>
                                <div style={styles.sideBarCol}>
                                  <Form>
                                    <Form.Select
                                      as="select"
                                      value={reassignFeasibilitySelect}
                                      onChange={e => setReassignFeasibilitySelect(e.target.value)}
                                      style={quoteMissingAttributes.includes('GLOBAL|reassignFeasibilityAssignee') ? GLOBAL_STYLES.errorInput : null}
                                    >
                                      <option value="NULL">-- Select Assignee --</option>
                                      {designEstimatorUsersLov.map(o => <option key={o.code}
                                                                                value={o.code}>{o.name}</option>)}
                                    </Form.Select>
                                  </Form>
                                </div>
                              </div> :
                              null
                          }
                          {
                            quoteData.assignments.some(o => o.types.includes('DESIGN_ESTIMATOR')) ?
                              <div style={styles.sideBarRow}>
                                <div
                                  style={{
                                    ...styles.sideBarTitleCol,
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <p style={styles.noMarginText}>Design Estimator</p>
                                </div>
                                <div style={styles.sideBarCol}>
                                  <Form>
                                    <Form.Select
                                      as="select"
                                      value={reassignDesignEstimatorSelect}
                                      onChange={e => setReassignDesignEstimatorSelect(e.target.value)}
                                      style={quoteMissingAttributes.includes('GLOBAL|reassignDesignEstimatorAssignee') ? GLOBAL_STYLES.errorInput : null}
                                    >
                                      <option value="NULL">-- Select Assignee --</option>
                                      {designEstimatorUsersLov.map(o => <option key={o.code}
                                                                                value={o.code}>{o.name}</option>)}
                                    </Form.Select>
                                  </Form>
                                </div>
                              </div> :
                              null
                          }
                          {
                            quoteData.assignments.some(o => o.types.includes('TOOLING_ESTIMATOR')) ?
                              <div style={styles.sideBarRow}>
                                <div
                                  style={{
                                    ...styles.sideBarTitleCol,
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <p style={styles.noMarginText}>Tooling Estimator</p>
                                </div>
                                <div style={styles.sideBarCol}>
                                  <Form>
                                    <Form.Select
                                      as="select"
                                      value={reassignToolingEstimatorSelect}
                                      onChange={e => setReassignToolingEstimatorSelect(e.target.value)}
                                      style={quoteMissingAttributes.includes('GLOBAL|reassignToolingEstimatorAssignee') ? GLOBAL_STYLES.errorInput : null}
                                    >
                                      <option value="NULL">-- Select Assignee --</option>
                                      {toolingEstimatorUsersLov.map(o => <option key={o.code}
                                                                                 value={o.code}>{o.name}</option>)}
                                    </Form.Select>
                                  </Form>
                                </div>
                              </div> :
                              null
                          }
                          {
                            quoteData.assignments.some(o => o.types.includes('MANUFACTURING_ESTIMATOR')) ?
                              <div style={styles.sideBarRow}>
                                <div
                                  style={{
                                    ...styles.sideBarTitleCol,
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <p style={styles.noMarginText}>Manufacturing Estimator</p>
                                </div>
                                <div style={styles.sideBarCol}>
                                  <Form>
                                    <Form.Select
                                      as="select"
                                      value={reassignManufacturingEstimatorSelect}
                                      onChange={e => setReassignManufacturingEstimatorSelect(e.target.value)}
                                      style={quoteMissingAttributes.includes('GLOBAL|reassignManufacturingEstimatorAssignee') ? GLOBAL_STYLES.errorInput : null}
                                    >
                                      <option value="NULL">-- Select Assignee --</option>
                                      {manufacturingEstimatorUsersLov.map(o => <option key={o.code}
                                                                                       value={o.code}>{o.name}</option>)}
                                    </Form.Select>
                                  </Form>
                                </div>
                              </div> :
                              null
                          }
                          {
                            quoteData.assignments.some(o => o.types.includes('PURCHASING_ESTIMATOR')) ?
                              <div style={styles.sideBarRow}>
                                <div
                                  style={{
                                    ...styles.sideBarTitleCol,
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <p style={styles.noMarginText}>Purchasing Estimator</p>
                                </div>
                                <div style={styles.sideBarCol}>
                                  <Form>
                                    <Form.Select
                                      as="select"
                                      value={reassignPurchasingEstimatorSelect}
                                      onChange={e => setReassignPurchasingEstimatorSelect(e.target.value)}
                                      style={quoteMissingAttributes.includes('GLOBAL|reassignPurchasingEstimatorAssignee') ? GLOBAL_STYLES.errorInput : null}
                                    >
                                      <option value="NULL">-- Select Assignee --</option>
                                      {purchasingEstimatorUsersLov.map(o => <option key={o.code}
                                                                                    value={o.code}>{o.name}</option>)}
                                    </Form.Select>
                                  </Form>
                                </div>
                              </div> :
                              null
                          }
                        </> :
                        null
                    }
                  </div>
                </> :
                null
            }
            <h5 style={styles.lineTitle}>
              <span style={styles.lineTitleText}>Dates</span>
            </h5>
            <div style={styles.sideBarContainer}>
              {
                !['DRAFT', 'SANDBOX'].includes(quoteData.status) && quoteData.dateTimeSubmitted ?
                  <div style={styles.sideBarRow}>
                    <div
                      style={{
                        ...styles.sideBarTitleCol,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <p style={styles.noMarginText}>Submitted</p>
                    </div>
                    <div style={styles.sideBarCol}>
                      <p style={{ ...styles.noMarginText, fontWeight: 'bold' }}>
                        {DateTime.fromMillis(quoteData.dateTimeSubmitted).toFormat('LL/dd/y HH:mm:ss')}
                      </p>
                    </div>
                  </div> :
                  null
              }
              <div style={styles.sideBarRow}>
                <div
                  style={{
                    ...styles.sideBarTitleCol,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <p style={styles.noMarginText}>Due</p>
                </div>
                <div style={styles.sideBarCol}>
                  <Form>
                    <Form.Control
                      type="date"
                      min={DateTimeBusiness.now().plusBusiness({ days: 1 }).toFormat('y-MM-dd')}
                      value={dueDateValue}
                      onChange={e => setDueDateValue(e.target.value)}
                      style={quoteMissingAttributes.includes('GLOBAL|dueDate') ? GLOBAL_STYLES.errorInput : null}
                      disabled={disableAllInputs || ['NOT_FEASIBLE', 'COMPLETED', 'AT_CUSTOMER', 'AWARDED', 'LOST'].includes(quoteData.status) ||
                        quoteData.createdBy !== userId}
                    />
                  </Form>
                </div>
              </div>
              <div style={styles.sideBarRow}>
                <div
                  style={{
                    ...styles.sideBarTitleCol,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <p style={styles.noMarginText}>Estimation Due</p>
                </div>
                <div style={styles.sideBarCol}>
                  <Form>
                    <Form.Control
                      type="date"
                      value={dueDateValue && dueDateValue.length ? DateTimeBusiness.fromISO(dueDateValue).minusBusiness({ days: 1 }).toISODate() : ''}
                      disabled
                    />
                  </Form>
                </div>
              </div>
              <div style={styles.sideBarRow}>
                <div
                  style={{
                    ...styles.sideBarTitleCol,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <p style={styles.noMarginText}>Start of Production</p>
                </div>
                <div style={styles.sideBarCol}>
                  <Form>
                    <Form.Control
                      type="date"
                      value={prodStartDateValue}
                      onChange={e => setProdStartDateValue(e.target.value)}
                      disabled={disableAllInputs || !['DRAFT', 'SANDBOX'].includes(quoteData.status)}
                    />
                  </Form>
                </div>
              </div>
              {
                quoteData.dateTimeLastUpdated ?
                  <div style={styles.sideBarRow}>
                    <div
                      style={{
                        ...styles.sideBarTitleCol,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <p style={styles.noMarginText}>Last Updated</p>
                    </div>
                    <div style={styles.sideBarCol}>
                      <p style={{ ...styles.noMarginText, fontWeight: 'bold' }}>
                        {DateTime.fromMillis(quoteData.dateTimeLastUpdated).toFormat('LL/dd/y HH:mm:ss')}
                      </p>
                    </div>
                  </div> :
                  null
              }
            </div>
            {
              ['COMPLETED', 'AT_CUSTOMER', 'AWARDED', 'LOST'].includes(quoteData.status) ?
                <>
                  <h5 style={styles.lineTitle}>
                    <span style={styles.lineTitleText}>Quote Log Info</span>
                  </h5>
                  <div style={styles.sideBarContainer}>
                    <div style={styles.sideBarRow}>
                      <div
                        style={{
                          ...styles.sideBarTitleCol,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <p style={styles.noMarginText}>RFQ Request</p>
                      </div>
                      <div style={styles.sideBarCol}>
                        <Form>
                          <Form.Control
                            type="date"
                            value={rfqRequestValue}
                            onChange={e => setRfqRequestValue(e.target.value)}
                            disabled={disableAllInputs || isCancellingQuote || isDeletingQuote || quoteData.createdBy !== userId}
                          />
                        </Form>
                      </div>
                    </div>
                    <div style={styles.sideBarRow}>
                      <div
                        style={{
                          ...styles.sideBarTitleCol,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <p style={styles.noMarginText}>Quote Issued</p>
                      </div>
                      <div style={styles.sideBarCol}>
                        <Form>
                          <Form.Control
                            type="date"
                            value={quoteIssuedValue}
                            onChange={e => setQuoteIssuedValue(e.target.value)}
                            disabled={disableAllInputs || isCancellingQuote || isDeletingQuote || quoteData.createdBy !== userId}
                          />
                        </Form>
                      </div>
                    </div>
                    <div style={styles.sideBarRow}>
                      <div
                        style={{
                          ...styles.sideBarTitleCol,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <p style={styles.noMarginText}>Date of Notice</p>
                      </div>
                      <div style={styles.sideBarCol}>
                        <Form>
                          <Form.Control
                            type="date"
                            value={dateOfNoticeValue}
                            onChange={e => setDateOfNoticeValue(e.target.value)}
                            disabled={disableAllInputs || isCancellingQuote || isDeletingQuote || quoteData.createdBy !== userId}
                          />
                        </Form>
                      </div>
                    </div>
                  </div>
                </> :
                null
            }
            {
              ['COMPLETED', 'AT_CUSTOMER'].includes(quoteData.status) ?
                <>
                  <h5 style={styles.lineTitle}>
                    <span style={styles.lineTitleText}>Quote Option</span>
                  </h5>
                  <div style={styles.sideBarContainer}>
                    <div style={styles.sideBarRow}>
                      <div
                        style={{
                          ...styles.sideBarTitleCol,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <p style={styles.noMarginText}>Tooling Option</p>
                      </div>
                      <div style={styles.sideBarCol}>
                        <Form>
                          <Form.Select
                            as="select"
                            value={toolingOptionSelect}
                            onChange={e => setToolingOptionSelect(e.target.value)}
                            disabled={disableAllInputs || isCancellingQuote || isDeletingQuote || !quoteData?.moldedParts?.some(o => o.toolLocationB != null)}
                          >
                            <option value="OPTION_A">Option A</option>
                            <option value="OPTION_B">Option B</option>
                          </Form.Select>
                        </Form>
                      </div>
                    </div>
                    <div style={styles.sideBarRow}>
                      <div
                        style={{
                          ...styles.sideBarTitleCol,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <p style={styles.noMarginText}>FG Cost</p>
                      </div>
                      <div style={styles.sideBarCol}>
                        <Form>
                          <InputGroup>
                            <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                            <Form.Control
                              type="number"
                              value={toolingOptionSelect === 'OPTION_A' ?
                                calculations.standardCostA.toFixed(5) :
                                toolingOptionSelect === 'OPTION_B' ?
                                  calculations.standardCostB.toFixed(5) :
                                  ''}
                              disabled
                            />
                          </InputGroup>
                        </Form>
                      </div>
                    </div>
                    <div style={styles.sideBarRow}>
                      <div
                        style={{
                          ...styles.sideBarTitleCol,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <p style={styles.noMarginText}>FG Price</p>
                      </div>
                      <div style={styles.sideBarCol}>
                        <Form>
                          <InputGroup
                            style={quoteMissingAttributes.includes('GLOBAL|fgPrice') ? GLOBAL_STYLES.errorInput : null}>
                            <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                            <Form.Control
                              type="number"
                              step="0.01"
                              min="0"
                              value={fgPriceValue}
                              onChange={e => setFgPriceValue(e.target.value)}
                              disabled={disableAllInputs || isCancellingQuote || isDeletingQuote || quoteData.createdBy !== userId}
                            />
                          </InputGroup>
                        </Form>
                      </div>
                    </div>
                    <div style={styles.sideBarRow}>
                      <div
                        style={{
                          ...styles.sideBarTitleCol,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <p style={styles.noMarginText}>Royalty %</p>
                      </div>
                      <div style={styles.sideBarCol}>
                        <Form>
                          <InputGroup
                            style={quoteMissingAttributes.includes('GLOBAL|royaltyPercentage') ? GLOBAL_STYLES.errorInput : null}>
                            <Form.Control
                              type="number"
                              step="1"
                              min="0"
                              value={royaltyPercentageValue}
                              onChange={e => setRoyaltyPercentageValue(e.target.value)}
                              disabled={disableAllInputs || isCancellingQuote || isDeletingQuote || quoteData.createdBy !== userId}
                            />
                            <InputGroup.Text style={styles.rightInputGroupText}>%</InputGroup.Text>
                          </InputGroup>
                        </Form>
                      </div>
                    </div>
                    <div style={styles.sideBarRow}>
                      <div
                        style={{
                          ...styles.sideBarTitleCol,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <p style={styles.noMarginText}>Royalty</p>
                      </div>
                      <div style={styles.sideBarCol}>
                        <Form>
                          <InputGroup>
                            <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                            <Form.Control
                              type="number"
                              disabled
                              value={calculations?.royaltyCost != null ? calculations.royaltyCost.toFixed(5) : ''}
                            />
                          </InputGroup>
                        </Form>
                      </div>
                    </div>
                    <div style={styles.sideBarRow}>
                      <div
                        style={{
                          ...styles.sideBarTitleCol,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <p style={styles.noMarginText}>Gross Margin</p>
                      </div>
                      <div style={styles.sideBarCol}>
                        <Form>
                          <InputGroup>
                            <Form.Control
                              type="number"
                              disabled
                              value={toolingOptionSelect === 'OPTION_A' && calculations?.grossMarginPercentageA != null ?
                                calculations.grossMarginPercentageA.toFixed(1) :
                                toolingOptionSelect === 'OPTION_B' && calculations?.grossMarginPercentageB != null ?
                                  calculations.grossMarginPercentageB.toFixed(1) :
                                  ''}
                            />
                            <InputGroup.Text style={styles.rightInputGroupText}>%</InputGroup.Text>
                          </InputGroup>
                        </Form>
                      </div>
                    </div>
                    <div style={styles.sideBarRow}>
                      <div
                        style={{
                          ...styles.sideBarTitleCol,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <p style={styles.noMarginText}>Minimum Price</p>
                      </div>
                      <div style={styles.sideBarCol}>
                        <Form>
                          <InputGroup>
                            <Form.Control
                              type="number"
                              disabled
                              value={toolingOptionSelect === 'OPTION_A' && calculations?.minimumPriceA != null ?
                                calculations.minimumPriceA.toFixed(5) :
                                toolingOptionSelect === 'OPTION_B' && calculations?.minimumPriceB != null ?
                                  calculations.minimumPriceB.toFixed(5) :
                                  ''}
                            />
                            <InputGroup.Text style={styles.rightInputGroupText}>%</InputGroup.Text>
                          </InputGroup>
                        </Form>
                      </div>
                    </div>
                    <div style={styles.sideBarRow}>
                      <div
                        style={{
                          ...styles.sideBarTitleCol,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <p style={styles.noMarginText}>Capital Cost</p>
                      </div>
                      <div style={styles.sideBarCol}>
                        <Form>
                          <InputGroup>
                            <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                            <Form.Control
                              type="number"
                              value={capitalCalculations?.totalCapitalCost ? capitalCalculations.totalCapitalCost : 0}
                              disabled
                            />
                          </InputGroup>
                        </Form>
                      </div>
                    </div>
                    <div style={styles.sideBarRow}>
                      <div
                        style={{
                          ...styles.sideBarTitleCol,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <p style={styles.noMarginText}>Capital Price</p>
                      </div>
                      <div style={styles.sideBarCol}>
                        <Form>
                          <InputGroup>
                            <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                            <Form.Control
                              type="number"
                              value={capitalCalculations?.totalCapitalPrice ? capitalCalculations.totalCapitalPrice : 0}
                              disabled
                            />
                          </InputGroup>
                        </Form>
                      </div>
                    </div>
                  </div>
                </> :
                null
            }
            <h5 style={styles.lineTitle}>
              <span style={styles.lineTitleText}>Actions</span>
            </h5>
            <div style={styles.sideBarContainer}>
              {
                quoteData.status === 'DRAFT' ?
                  <Form>
                    <Form.Check
                      type="checkbox"
                      label="Submit This Quote"
                      checked={isSubmittingQuote}
                      onChange={e => {
                        if(e.target.checked) setIsReassigningQuote(false);
                        setIsSubmittingQuote(e.target.checked);
                      }}
                      disabled={disableAllInputs || isCancellingQuote || isDeletingQuote}
                    />
                  </Form> :
                  null
              }
              {quoteData.holdUserId === userId && submittedDesignEstimatorUserId === userId && quoteData.status === 'IN_PROGRESS' ?
                <Form>
                  <Form.Check
                    type="checkbox"
                    label="Take Back Design Estimation"
                    checked={isTakingBackDesignEstimation}
                    onChange={e => {
                      if(e.target.checked) {
                        setIsReassigningQuote(false);
                        setIsSubmittingEstimation(false);
                      }
                      setIsTakingBackDesignEstimation(e.target.checked);
                    }}
                    disabled={disableAllInputs || isCancellingQuote || isDeletingQuote}
                  />
                </Form> :
                null
              }
              {quoteData.holdUserId === userId && submittedToolingEstimatorUserId === userId && quoteData.status === 'IN_PROGRESS' ?
                <Form>
                  <Form.Check
                    type="checkbox"
                    label="Take Back Tooling Estimation"
                    checked={isTakingBackToolingEstimation}
                    onChange={e => {
                      if(e.target.checked) {
                        setIsReassigningQuote(false);
                        setIsSubmittingEstimation(false);
                      }
                      setIsTakingBackToolingEstimation(e.target.checked);
                    }}
                    disabled={disableAllInputs || isCancellingQuote || isDeletingQuote}
                  />
                </Form> :
                null
              }
              {quoteData.holdUserId === userId && submittedManufacturingEstimatorUserId === userId && quoteData.status === 'IN_PROGRESS' ?
                <Form>
                  <Form.Check
                    type="checkbox"
                    label="Take Back Manufacturing Estimation"
                    checked={isTakingBackManufacturingEstimation}
                    onChange={e => {
                      if(e.target.checked) {
                        setIsReassigningQuote(false);
                        setIsSubmittingEstimation(false);
                      }
                      setIsTakingBackManufacturingEstimation(e.target.checked);
                    }}
                    disabled={disableAllInputs || isCancellingQuote || isDeletingQuote}
                  />
                </Form> :
                null
              }
              {quoteData.holdUserId === userId && submittedPurchasingEstimatorUserId === userId && quoteData.status === 'IN_PROGRESS' ?
                <Form>
                  <Form.Check
                    type="checkbox"
                    label="Take Back Purchasing Estimation"
                    checked={isTakingBackPurchasingEstimation}
                    onChange={e => {
                      if(e.target.checked) {
                        setIsReassigningQuote(false);
                        setIsSubmittingEstimation(false);
                      }
                      setIsTakingBackPurchasingEstimation(e.target.checked);
                    }}
                    disabled={disableAllInputs || isCancellingQuote || isDeletingQuote}
                  />
                </Form> :
                null
              }
              {
                quoteData.status === 'IN_PROGRESS' &&
                ((appRoles.includes(CONFIG.ROLE_MAPPINGS.DESIGN_ESTIMATOR) && designEstimatorAssignmentUserId === userId) ||
                  (appRoles.includes(CONFIG.ROLE_MAPPINGS.TOOLING_ESTIMATOR) && toolingEstimatorAssignmentUserId === userId) ||
                  (appRoles.includes(CONFIG.ROLE_MAPPINGS.MANUFACTURING_ESTIMATOR) && manufacturingEstimatorAssignmentUserId === userId) ||
                  (appRoles.includes(CONFIG.ROLE_MAPPINGS.PURCHASING_ESTIMATOR) && purchasingEstimatorAssignmentUserId === userId)) ?
                  <Form>
                    <Form.Check
                      type="checkbox"
                      label="Submit Estimation"
                      checked={isSubmittingEstimation}
                      onChange={e => {
                        if(e.target.checked) {
                          setIsReassigningQuote(false);
                          setIsTakingBackToolingEstimation(false);
                          setIsTakingBackToolingEstimation(false);
                          setIsTakingBackManufacturingEstimation(false);
                          setIsTakingBackPurchasingEstimation(false);
                        }
                        setIsSubmittingEstimation(e.target.checked);
                      }}
                      disabled={disableAllInputs || isCancellingQuote || isDeletingQuote}
                    />
                  </Form> :
                  null
              }
              {
                quoteData.status === 'SANDBOX' ?
                  <Form>
                    <Form.Check
                      type="checkbox"
                      label="Promote To Revision"
                      checked={isPromotingQuote}
                      onChange={e => setIsPromotingQuote(e.target.checked)}
                      disabled={isDeletingSandboxQuote}
                    />
                  </Form> :
                  null
              }
              {
                quoteData.status === 'PRE_FEASIBILITY' && appRoles.includes(CONFIG.ROLE_MAPPINGS.FEASIBILITY) &&
                feasibilityAssignmentUserId === userId ?
                  <>
                    <Form>
                      <Form.Check
                        type="checkbox"
                        label="Approve Pre-Feasibility"
                        checked={isPreFeasibilityApprovingQuote}
                        onChange={e => {
                          if(e.target.checked) {
                            setIsPreFeasibilityRejectingQuote(false);
                            setIsReassigningQuote(false);
                          }
                          setIsPreFeasibilityApprovingQuote(e.target.checked);
                        }}
                        disabled={disableAllInputs || isCancellingQuote || isDeletingQuote}
                      />
                    </Form>
                    <Form>
                      <Form.Check
                        type="checkbox"
                        label="Reject Pre-Feasibility"
                        checked={isPreFeasibilityRejectingQuote}
                        onChange={e => {
                          if(e.target.checked) {
                            setIsPreFeasibilityApprovingQuote(false);
                            setIsReassigningQuote(false);
                          }
                          setIsPreFeasibilityRejectingQuote(e.target.checked);
                        }}
                        disabled={disableAllInputs || isCancellingQuote || isDeletingQuote}
                      />
                    </Form>
                    {
                      isPreFeasibilityRejectingQuote ?
                        <>
                          <Form.Group>
                            <Form.Label>Rejection Comments (Required)</Form.Label>
                            <Form.Control
                              value={rejectionPreFeasibilityCommentValue}
                              onChange={e => setRejectionPreFeasibilityCommentValue(e.target.value)}
                              style={quoteMissingAttributes.includes('GLOBAL|rejectionPreFeasibilityComment') ? GLOBAL_STYLES.errorInput : null}
                              as="textarea"
                              rows={3}
                              disabled={disableAllInputs}
                            />
                          </Form.Group>
                        </> :
                        null
                    }
                  </> :
                  null
              }
              {
                quoteData.status === 'FEASIBILITY' && appRoles.includes(CONFIG.ROLE_MAPPINGS.FEASIBILITY) &&
                feasibilityAssignmentUserId === userId ?
                  <>
                    <Form>
                      <Form.Check
                        type="checkbox"
                        label="Sign Off"
                        checked={isSigningOffFeasibility}
                        onChange={e => {
                          setIsSigningOffFeasibility(e.target.checked);
                          if(e.target.checked) {
                            setIsReassigningQuote(false);
                            setIsSendingQuoteBackToEstimation(false);
                            setIsSendingQuoteBackToDesignEstimator(false);
                            setIsSendingQuoteBackToToolingEstimator(false);
                            setIsSendingQuoteBackToManufacturingEstimator(false);
                            setIsSendingQuoteBackToPurchasingEstimator(false);
                            setSendBackCommentValue('');
                          }
                        }}
                        disabled={disableAllInputs || isCancellingQuote || isDeletingQuote}
                      />
                      {isSigningOffFeasibility && quoteIsLate ?
                        <>
                          <Form.Group>
                            <Form.Label>Late Reason (Required)</Form.Label>
                            <Form.Select
                              as="select"
                              value={lateReasonValue}
                              onChange={e => setLateReasonValue(e.target.value)}
                              disabled={disableAllInputs}
                              style={quoteMissingAttributes.includes('GLOBAL|lateReason') ? GLOBAL_STYLES.errorInput : null}
                            >
                              <option value="NULL">-- Select Late Reason --</option>
                              {lateReasonLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
                            </Form.Select>
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Late Comment (Required)</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              value={lateCommentValue}
                              onChange={e => setLateCommentValue(e.target.value)}
                              disabled={disableAllInputs}
                              style={quoteMissingAttributes.includes('GLOBAL|lateComment') ? GLOBAL_STYLES.errorInput : null}
                            />
                          </Form.Group>
                        </> :
                        null
                      }
                      <Form.Check
                        type="checkbox"
                        label="Send Back"
                        checked={isSendingQuoteBackToEstimation}
                        onChange={e => {
                          setIsSendingQuoteBackToEstimation(e.target.checked);
                          if(!e.target.checked) {
                            setIsReassigningQuote(false);
                            setIsSendingQuoteBackToDesignEstimator(false);
                            setIsSendingQuoteBackToToolingEstimator(false);
                            setIsSendingQuoteBackToManufacturingEstimator(false);
                            setIsSendingQuoteBackToPurchasingEstimator(false);
                            setSendBackCommentValue('');
                          }
                        }}
                        disabled={disableAllInputs || isCancellingQuote || isDeletingQuote}
                      />
                      {
                        isSendingQuoteBackToEstimation ?
                          <>
                            <Form.Check
                              type="checkbox"
                              label={`Design Estimator: ${quoteData.designEstimationSubmittedUsername}`}
                              checked={isSendingQuoteBackToDesignEstimator}
                              onChange={e => {
                                if(e.target.checked) setIsReassigningQuote(false);
                                setIsSendingQuoteBackToDesignEstimator(e.target.checked);
                              }}
                              disabled={disableAllInputs || isCancellingQuote || isDeletingQuote}
                              style={{
                                ...(quoteMissingAttributes.includes('GLOBAL|sendBackAssignees') ? GLOBAL_STYLES.errorInput : {}),
                                marginLeft: 25,
                              }}
                            />
                            <Form.Check
                              type="checkbox"
                              label={`Tooling Estimator: ${quoteData.toolingEstimationSubmittedUsername}`}
                              checked={isSendingQuoteBackToToolingEstimator}
                              onChange={e => {
                                if(e.target.checked) setIsReassigningQuote(false);
                                setIsSendingQuoteBackToToolingEstimator(e.target.checked);
                              }}
                              disabled={disableAllInputs || isCancellingQuote || isDeletingQuote}
                              style={{
                                ...(quoteMissingAttributes.includes('GLOBAL|sendBackAssignees') ? GLOBAL_STYLES.errorInput : {}),
                                marginLeft: 25,
                              }}
                            />
                            {
                              quoteData.manufacturingEstimationSubmittedUsername ?
                                <Form.Check
                                  type="checkbox"
                                  label={`Manufacturing Estimator: ${quoteData.manufacturingEstimationSubmittedUsername}`}
                                  checked={isSendingQuoteBackToManufacturingEstimator}
                                  onChange={e => {
                                    if(e.target.checked) setIsReassigningQuote(false);
                                    setIsSendingQuoteBackToManufacturingEstimator(e.target.checked);
                                  }}
                                  disabled={disableAllInputs || isCancellingQuote || isDeletingQuote}
                                  style={{
                                    ...(quoteMissingAttributes.includes('GLOBAL|sendBackAssignees') ? GLOBAL_STYLES.errorInput : {}),
                                    marginLeft: 25,
                                  }}
                                /> :
                                null
                            }
                            {
                              quoteData.purchasingEstimationSubmittedUsername ?
                                <Form.Check
                                  type="checkbox"
                                  label={`Purchasing Estimator: ${quoteData.purchasingEstimationSubmittedUsername}`}
                                  checked={isSendingQuoteBackToPurchasingEstimator}
                                  onChange={e => {
                                    if(e.target.checked) setIsReassigningQuote(false);
                                    setIsSendingQuoteBackToPurchasingEstimator(e.target.checked);
                                  }}
                                  disabled={disableAllInputs || isCancellingQuote || isDeletingQuote}
                                  style={{
                                    ...(quoteMissingAttributes.includes('GLOBAL|sendBackAssignees') ? GLOBAL_STYLES.errorInput : {}),
                                    marginLeft: 25,
                                  }}
                                /> :
                                null
                            }
                            <Form.Group>
                              <Form.Label>Comment (Required)</Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={3}
                                style={{
                                  ...(quoteMissingAttributes.includes('GLOBAL|sendBackComment') ? GLOBAL_STYLES.errorInput : {}),
                                  marginBottom: 10,
                                }}
                                value={sendBackCommentValue}
                                onChange={e => setSendBackCommentValue(e.target.value)}
                                disabled={disableAllInputs}
                              />
                            </Form.Group>
                          </> :
                          null
                      }
                    </Form>
                  </> :
                  null
              }
              {
                quoteData.status === 'SALES_APPROVAL' && quoteData.createdBy === userId ?
                  <>
                    <Form>
                      <Form.Check
                        type="checkbox"
                        label="Approve"
                        checked={isApprovingSalesCheck}
                        onChange={e => {
                          setIsApprovingSalesCheck(e.target.checked);
                          if(e.target.checked) {
                            setIsReassigningQuote(false);
                            setIsSendingBackToFeasibility(false);
                            setSendBackFeasibilityCommentValue('');
                          }
                        }}
                        disabled={disableAllInputs || isCancellingQuote || isDeletingQuote}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Send Back"
                        checked={isSendingBackToFeasibility}
                        onChange={e => {
                          setIsSendingBackToFeasibility(e.target.checked);
                          if(e.target.checked) {
                            setIsReassigningQuote(false);
                            setIsApprovingSalesCheck(false);
                          }
                        }}
                        disabled={disableAllInputs || isCancellingQuote || isDeletingQuote}
                      />
                      {
                        isSendingBackToFeasibility ?
                          <Form.Group>
                            <Form.Label>Comment (Required)</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              style={{
                                ...(quoteMissingAttributes.includes('GLOBAL|sendBackFeasibilityComment') ? GLOBAL_STYLES.errorInput : {}),
                                marginBottom: 10,
                              }}
                              value={sendBackFeasibilityCommentValue}
                              onChange={e => setSendBackFeasibilityCommentValue(e.target.value)}
                              disabled={disableAllInputs}
                            />
                          </Form.Group> :
                          null
                      }
                    </Form>
                  </> :
                  null
              }
              {
                ['COMPLETED', 'AT_CUSTOMER'].includes(quoteData.status) ?
                  <div style={styles.sideBarRow}>
                    <div
                      style={{
                        ...styles.sideBarTitleCol,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <p style={styles.noMarginText}>Award Probability</p>
                    </div>
                    <div style={styles.sideBarCol}>
                      <Form>
                        <Form.Select
                          as="select"
                          value={awardProbabilitySelect}
                          style={quoteMissingAttributes.includes('GLOBAL|awardProbability') ? GLOBAL_STYLES.errorInput : null}
                          onChange={e => setAwardProbabilitySelect(e.target.value)}
                          disabled={disableAllInputs || isCancellingQuote || isDeletingQuote || quoteData.createdBy !== userId}
                        >
                          <option value="NULL">-- Select Probability --</option>
                          {awardProbabilityLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
                        </Form.Select>
                      </Form>
                    </div>
                  </div> :
                  null
              }
              {
                ['COMPLETED', 'AT_CUSTOMER', 'AWARDED', 'LOST'].includes(quoteData.status) ?
                  <>
                    <div style={styles.sideBarRow}>
                      <div
                        style={{
                          ...styles.sideBarTitleCol,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <p style={styles.noMarginText}>Quote Status</p>
                      </div>
                      <div style={styles.sideBarCol}>
                        <Form>
                          <Form.Select
                            as="select"
                            value={quoteStatusSelect}
                            onChange={e => setQuoteStatusSelect(e.target.value)}
                            disabled={disableAllInputs || isCancellingQuote || isDeletingQuote || quoteData.createdBy !== userId}
                          >
                            <option value="NULL">-- Select Status --</option>
                            {[
                              { code: 'AT_CUSTOMER', name: 'At Customer' },
                              { code: 'AWARDED', name: 'Awarded' },
                              { code: 'LOST', name: 'Lost' },
                            ].filter(o => o.code !== quoteData.status)
                              .map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
                          </Form.Select>
                        </Form>
                      </div>
                    </div>
                    {
                      quoteStatusSelect === 'LOST' ?
                        <>
                          <Form.Group>
                            <Form.Label>Lost Reason (Required)</Form.Label>
                            <Form.Select
                              as="select"
                              style={quoteMissingAttributes.includes('GLOBAL|lostReason') ? GLOBAL_STYLES.errorInput : null}
                              value={lostReasonValue}
                              onChange={e => setLostReasonValue(e.target.value)}
                              disabled={disableAllInputs}
                            >
                              <option value="NULL">-- Select Lost Reason --</option>
                              {reasonLostLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
                            </Form.Select>
                          </Form.Group>
                          <Form.Group style={{ marginBottom: 5 }}>
                            <Form.Label>Comment (Required)</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              style={quoteMissingAttributes.includes('GLOBAL|lostReasonComment') ? GLOBAL_STYLES.errorInput : null}
                              value={lostReasonCommentValue}
                              onChange={e => setLostReasonCommentValue(e.target.value)}
                              disabled={disableAllInputs}
                            />
                          </Form.Group>
                        </> :
                        null
                    }
                  </> :
                  null
              }
              {
                ['COMPLETED', 'AT_CUSTOMER', 'AWARDED', 'LOST'].includes(quoteData.status) &&
                !dashboardQuotes.some(q => q.quoteId === quoteData.quoteId && q.status === 'SANDBOX') ?
                  <Form>
                    <Form.Check
                      type="checkbox"
                      label="Create Sandbox Quote"
                      checked={isCreatingSandboxQuote}
                      onChange={e => {
                        if(e.target.checked) setIsReassigningQuote(false);
                        setIsCreatingRevisionQuote(false);
                        setIsCreatingSandboxQuote(e.target.checked);
                      }}
                      disabled={disableAllInputs || isCancellingQuote || isDeletingQuote}
                    />
                  </Form> :
                  null
              }
              {
                ['NOT_FEASIBLE', 'COMPLETED', 'AT_CUSTOMER', 'AWARDED', 'LOST'].includes(quoteData.status) ?
                  <>
                    <Form>
                      <Form.Check
                        type="checkbox"
                        label="Create Revision"
                        checked={isCreatingRevisionQuote}
                        onChange={e => {
                          if(e.target.checked) setIsReassigningQuote(false);
                          setIsCreatingSandboxQuote(false);
                          setIsCreatingRevisionQuote(e.target.checked);
                        }}
                        disabled={disableAllInputs || isCancellingQuote || isDeletingQuote}
                      />
                    </Form>
                    {
                      isCreatingRevisionQuote ?
                        <Form.Check
                          type="checkbox"
                          label="Inherit Attachments"
                          checked={isInheritingAttachments}
                          onChange={e => setIsInheritingAttachments(e.target.checked)}
                          disabled={disableAllInputs || isCancellingQuote || isDeletingQuote || !isCreatingRevisionQuote}
                          style={{ marginLeft: 25 }}
                        /> :
                        null
                    }
                  </> :
                  null
              }
              {
                !['DRAFT', 'SANDBOX'].includes(quoteData.status) ?
                  <Form>
                    <Form.Group>
                      <Form.Check
                        type="checkbox"
                        label="Cancel This Quote"
                        checked={isCancellingQuote}
                        onChange={e => {
                          setIsSubmittingQuote(false);
                          setIsSubmittingEstimation(false);
                          setIsPreFeasibilityApprovingQuote(false);
                          setIsPreFeasibilityRejectingQuote(false);
                          setIsCreatingRevisionQuote(false);
                          setIsCreatingSandboxQuote(false);
                          setIsInheritingAttachments(false);
                          setIsSendingQuoteBackToEstimation(false);
                          setIsSendingQuoteBackToDesignEstimator(false);
                          setIsSendingQuoteBackToToolingEstimator(false);
                          setIsSendingQuoteBackToManufacturingEstimator(false);
                          setIsSendingQuoteBackToPurchasingEstimator(false);
                          setIsApprovingSalesCheck(false);
                          setIsSendingBackToFeasibility(false);
                          setIsReassigningQuote(false);
                          setIsTakingBackToolingEstimation(false);
                          setIsTakingBackToolingEstimation(false);
                          setIsTakingBackManufacturingEstimation(false);
                          setIsTakingBackPurchasingEstimation(false);
                          setIsCancellingQuote(e.target.checked);
                        }}
                        disabled={disableAllInputs || isDeletingQuote || !appRoles.includes(CONFIG.ROLE_MAPPINGS.CREATE_QUOTE)}
                      />
                    </Form.Group>
                    {
                      isCancellingQuote ?
                        <>
                          <Form.Group>
                            <Form.Label>Cancel Reason (Required)</Form.Label>
                            <Form.Select
                              as="select"
                              value={cancelReasonValue}
                              onChange={e => setCancelReasonValue(e.target.value)}
                              disabled={disableAllInputs}
                            >
                              <option value="NULL">-- Select Cancel Reason --</option>
                              {cancelReasonLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
                            </Form.Select>
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Cancel Comment (Required)</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              value={cancelCommentValue}
                              onChange={e => setCancelCommentValue(e.target.value)}
                              disabled={disableAllInputs}
                            />
                          </Form.Group>
                          <Form.Group style={styles.cancelDeleteGroup}>
                            <Form.Label style={{ color: COLORS.red }}>
                              Canceling this revision is irreversible!
                            </Form.Label>
                            <Button
                              type="button"
                              variant="danger"
                              onClick={confirmCancelQuote}
                              disabled={disableAllInputs || cancelReasonValue === 'NULL' || !cancelCommentValue.trim().length}
                            >
                              Cancel
                            </Button>
                          </Form.Group>
                        </> :
                        null
                    }
                  </Form> :
                  null
              }
              {
                quoteData.status !== 'SANDBOX' ?
                  <Form>
                    <Form.Check
                      type="checkbox"
                      label="Delete This Quote"
                      checked={isDeletingQuote}
                      onChange={e => {
                        setIsSubmittingQuote(false);
                        setIsSubmittingEstimation(false);
                        setIsPreFeasibilityApprovingQuote(false);
                        setIsPreFeasibilityRejectingQuote(false);
                        setIsCreatingRevisionQuote(false);
                        setIsCreatingSandboxQuote(false);
                        setIsInheritingAttachments(false);
                        setIsSendingQuoteBackToEstimation(false);
                        setIsSendingQuoteBackToDesignEstimator(false);
                        setIsSendingQuoteBackToToolingEstimator(false);
                        setIsSendingQuoteBackToManufacturingEstimator(false);
                        setIsSendingQuoteBackToPurchasingEstimator(false);
                        setIsApprovingSalesCheck(false);
                        setIsSendingBackToFeasibility(false);
                        setIsReassigningQuote(false);
                        setIsTakingBackToolingEstimation(false);
                        setIsTakingBackToolingEstimation(false);
                        setIsTakingBackManufacturingEstimation(false);
                        setIsTakingBackPurchasingEstimation(false);
                        setIsDeletingQuote(e.target.checked);
                      }}
                      disabled={disableAllInputs || isCancellingQuote || !appRoles.includes(CONFIG.ROLE_MAPPINGS.CREATE_QUOTE)}
                    />
                    {
                      isDeletingQuote ?
                        <Form.Group style={styles.cancelDeleteGroup}>
                          <Form.Label style={{ color: COLORS.red }}>
                            Deleting this revision is irreversible!
                          </Form.Label>
                          <Button
                            type="button"
                            variant="danger"
                            onClick={confirmDeleteQuote}
                            disabled={disableAllInputs}
                          >
                            Delete
                          </Button>
                        </Form.Group>
                        :
                        null
                    }
                  </Form> :
                  <Form>
                    <Form.Check
                      type="checkbox"
                      label="Delete This Quote"
                      checked={isDeletingSandboxQuote}
                      onChange={e => {
                        setIsPromotingQuote(false);
                        setIsDeletingSandboxQuote(e.target.checked);
                      }}
                      disabled={!appRoles.includes(CONFIG.ROLE_MAPPINGS.CREATE_QUOTE)}
                    />
                    {
                      isDeletingSandboxQuote ?
                        <Form.Group style={styles.cancelDeleteGroup}>
                          <Form.Label style={{ color: COLORS.red }}>
                            Deleting this revision is irreversible!
                          </Form.Label>
                          <Button
                            type="button"
                            variant="danger"
                            onClick={confirmDeleteSandboxQuote}
                          >
                            Delete
                          </Button>
                        </Form.Group>
                        :
                        null
                    }
                  </Form>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  mainQuoteContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  },
  mainQuoteRow: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  },
  mainQuoteLeftCol: {
    padding: 10,
    flexBasis: '75%',
  },
  quoteSummaryBox: {
    backgroundColor: COLORS.white,
    borderColor: COLORS.whiteGray,
    borderStyle: 'solid',
    borderWidth: 1,
    padding: 10,
    borderRadius: 5,
    marginBottom: 10,
  },
  sideBar: {
    padding: 0,
    paddingTop: 10,
    paddingBottom: 10,
    borderLeftWidth: 1,
    borderLeftStyle: 'solid',
    borderLeftColor: COLORS.midGray,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    flexBasis: '25%',
  },
  tabContainer: {
    padding: 10,
    backgroundColor: COLORS.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    borderWidth: 1,
    borderColor: COLORS.whiteGray,
    borderStyle: 'solid',
    borderTopWidth: 0,
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
  },
  lineTitle: {
    textAlign: 'center',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: COLORS.midGray,
    lineHeight: '0.1em',
    marginTop: 12,
    marginBottom: 12,
    color: COLORS.darkGray,
  },
  lineTitleText: {
    backgroundColor: COLORS.offWhite,
    paddingLeft: 7,
    paddingRight: 7,
  },
  sideBarContainer: {
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    marginBottom: 7,
  },
  sideBarRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    marginBottom: 4,
  },
  sideBarTitleCol: {
    flexBasis: '42%',
  },
  sideBarCol: {
    flexBasis: '58%',
  },
  noMarginText: {
    margin: 0,
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    marginBottom: 7,
  },
  cancelDeleteGroup: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  boldCell: {
    fontWeight: 'bold',
  },
  leftInputGroupText: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRightWidth: 0,
  },
  rightInputGroupText: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeftWidth: 0,
  },
};

export default Quote;