import { useEffect } from 'react';
import COLORS from '../styles/colors';
import { Form, InputGroup, Table } from 'react-bootstrap';
import {
  quoteDataAtom,
  quoteProcessorAtom,
  saveQuoteTriggerAtom,
  purchasedPartCostsAtom,
  carryoverPartCostsAtom,
  initValuesAtom,
  quoteNewPartNumbersAtom,
} from '../state';
import { useRecoilValue, useRecoilState } from 'recoil';
import { getPurchasingCalculations } from '../helpers/calculations';


const QuoteTabPurchased = () => {
  const quoteData = useRecoilValue(quoteDataAtom);
  const quoteProcessor = useRecoilValue(quoteProcessorAtom);
  const saveQuoteTrigger = useRecoilValue(saveQuoteTriggerAtom);
  const quoteNewPartNumbers = useRecoilValue(quoteNewPartNumbersAtom);
  const [purchasedPartCosts, setPurchasedPartCosts] = useRecoilState(purchasedPartCostsAtom);
  const [carryoverPartCosts, setCarryoverPartCosts] = useRecoilState(carryoverPartCostsAtom);
  const [initValues, setInitValues] = useRecoilState(initValuesAtom);
  
  useEffect(() => {
    if(saveQuoteTrigger) {
      quoteProcessor.setPurchasingData({
        newPurchasedParts: purchasedPartCosts.map(o => ({
          bomId: o.bomId,
          partNumber: o.partNumber,
          partDescription: o.partDescription,
          orgCode: o.orgCode,
          isEstimated: o.isEstimated,
          isOutsourced: o.isOutsourced,
          quantity: o.quantity,
          partCost: o.partCost,
        })),
        carryoverPurchasedParts: carryoverPartCosts.map(o => ({
          bomId: o.bomId,
          partNumber: o.partNumber,
          partDescription: o.partDescription,
          orgCode: o.orgCode,
          quantity: o.quantity,
          partCost: o.partCost,
        })),
      });
    }
  }, [saveQuoteTrigger, quoteProcessor, purchasedPartCosts, carryoverPartCosts]);
  
  useEffect(() => {
    if(initValues && quoteData) {
      setPurchasedPartCosts(quoteData.newPurchasedParts ? quoteData.newPurchasedParts : []);
      setCarryoverPartCosts(quoteData.carryoverPurchasedParts ? quoteData.carryoverPurchasedParts : []);
      setInitValues(false);
    }
  }, [
    initValues,
    quoteData,
    setPurchasedPartCosts,
    setCarryoverPartCosts,
    setInitValues,
  ]);
  
  const calculations = getPurchasingCalculations({
    purchasedPartCosts,
    carryoverPartCosts,
  });
  
  return (
    <>
      <h5 style={{ ...styles.lineTitle, textAlign: 'left', marginTop: 20, marginBottom: 20 }}>
        <span style={{ ...styles.lineTitleText, backgroundColor: COLORS.white }}>
          New Purchased, Passthrough, and Outsourced Parts
        </span>
      </h5>
      <Table size="sm" style={{ marginBottom: 0 }}>
        <tbody>
        <tr style={{ fontWeight: 'bold' }}>
          <td>P/N</td>
          <td>Name</td>
          <td>Associated Part</td>
          <td>Estimate</td>
          <td>Outsourced</td>
          <td>Qty</td>
          <td>Oracle Cost</td>
          <td>Unit Cost</td>
          <td>Total Cost</td>
        </tr>
        {purchasedPartCosts?.length ?
          purchasedPartCosts.map((partObj, index) => {
            const newPartObj = quoteNewPartNumbers?.find(o => o.nodeId === partObj.bomId);
            return (
              <tr key={index}>
                <td style={{ verticalAlign: 'middle' }}>{newPartObj ? newPartObj.partNumber : partObj.partNumber}</td>
                <td style={{ verticalAlign: 'middle' }}>{partObj.partDescription}</td>
                <td style={{ verticalAlign: 'middle' }}>{partObj.parentName}</td>
                <td style={{ verticalAlign: 'middle' }}>{partObj.isEstimated ? 'YES' : 'NO'}</td>
                <td style={{ verticalAlign: 'middle' }}>{partObj.isOutsourced ? 'YES' : 'NO'}</td>
                <td style={{ verticalAlign: 'middle' }}>{partObj.quantity}</td>
                <td
                  style={{ verticalAlign: 'middle' }}>{partObj.oraclePartCost ? `$${partObj.oraclePartCost}` : ''}</td>
                <td style={{ verticalAlign: 'middle' }}>{`$${partObj.partCost}`}</td>
                <td style={{ verticalAlign: 'middle' }}>
                  {partObj.quantity && partObj.partCost ? `$${(partObj.quantity * partObj.partCost).toFixed(5)}` : ''}
                </td>
              </tr>
            );
          }) :
          <tr>
            <td colSpan="7">
              No purchased parts added to the BOM
            </td>
          </tr>
        }
        </tbody>
      </Table>
      <div style={styles.addMoldedPartButtonRow}>
        <p style={{ marginRight: 10, marginBottom: 0 }}>Total Purchased Components Cost</p>
        <InputGroup style={{ flexBasis: '25%' }}>
          <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
          <Form.Control
            type="number"
            value={calculations.totalPurchasedComponentsCost ? calculations.totalPurchasedComponentsCost.toFixed(5) : ''}
            disabled
          />
        </InputGroup>
      </div>
      <h5 style={{ ...styles.lineTitle, textAlign: 'left', marginTop: 20, marginBottom: 20 }}>
                  <span style={{ ...styles.lineTitleText, backgroundColor: COLORS.white }}>
                    Carryover NIFCO Parts
                  </span>
      </h5>
      <Table size="sm" style={{ marginBottom: 0 }}>
        <tbody>
        <tr style={{ fontWeight: 'bold' }}>
          <td>P/N</td>
          <td>Name</td>
          <td>Associated Part</td>
          <td>Location</td>
          <td>Qty</td>
          <td>Oracle Cost</td>
          <td>Unit Cost</td>
          <td>Total Cost</td>
        </tr>
        {carryoverPartCosts?.length ?
          carryoverPartCosts.map((partObj, index) => (
            <tr key={index}>
              <td style={{ verticalAlign: 'middle' }}>{partObj.partNumber}</td>
              <td style={{ verticalAlign: 'middle' }}>{partObj.partDescription}</td>
              <td style={{ verticalAlign: 'middle' }}>{partObj.parentName}</td>
              <td style={{ verticalAlign: 'middle' }}>{partObj.orgCode}</td>
              <td style={{ verticalAlign: 'middle' }}>{partObj.quantity}</td>
              <td style={{ verticalAlign: 'middle' }}>{partObj.oraclePartCost ? `$${partObj.oraclePartCost}` : ''}</td>
              <td style={{ verticalAlign: 'middle' }}>{`$${partObj.partCost}`}</td>
              <td style={{ verticalAlign: 'middle' }}>
                {partObj.quantity && partObj.partCost ? `$${(partObj.quantity * partObj.partCost).toFixed(5)}` : ''}
              </td>
            </tr>
          )) :
          <tr>
            <td colSpan="6">
              No carryover parts added to the BOM
            </td>
          </tr>
        }
        </tbody>
      </Table>
      <div style={styles.addMoldedPartButtonRow}>
        <p style={{ marginRight: 10, marginBottom: 0 }}>Total Carryover Parts Cost</p>
        <InputGroup style={{ flexBasis: '25%' }}>
          <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
          <Form.Control
            type="text"
            value={calculations.totalCarryoverPartsCost ? calculations.totalCarryoverPartsCost.toFixed(5) : ''}
            disabled
          />
        </InputGroup>
      </div>
    </>
  );
};

const styles = {
  lineTitle: {
    textAlign: 'center',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: COLORS.midGray,
    lineHeight: '0.1em',
    marginTop: 12,
    marginBottom: 12,
    color: COLORS.darkGray,
  },
  lineTitleText: {
    backgroundColor: COLORS.offWhite,
    paddingLeft: 7,
    paddingRight: 7,
  },
  addMoldedPartButtonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 5,
    marginBottom: 8,
  },
  leftInputGroupText: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRightWidth: 0,
  },
  deleteButton: {
    marginLeft: 5,
    cursor: 'pointer',
  },
};

export default QuoteTabPurchased;