import { useEffect } from 'react';
import COLORS from '../styles/colors';
import { Form, InputGroup, Table } from 'react-bootstrap';
import {
  quoteDataAtom,
  appRolesAtom,
  userIdAtom,
  paymentTypeLovAtom,
  saveQuoteTriggerAtom,
  quoteProcessorAtom,
  initValuesAtom,
  depreciationMonthsLovAtom,
  capitalCostsAtom,
} from '../state';
import { useRecoilValue, useRecoilState } from 'recoil';
import * as CONFIG from '../config';
import cloneDeep from 'lodash.clonedeep';
import { getCapitalCalculations } from '../helpers/calculations';

const QuoteTabCapital = () => {
  const quoteData = useRecoilValue(quoteDataAtom);
  const appRoles = useRecoilValue(appRolesAtom);
  const userId = useRecoilValue(userIdAtom);
  const depreciationMonthsLov = useRecoilValue(depreciationMonthsLovAtom);
  const paymentTypeLov = useRecoilValue(paymentTypeLovAtom);
  const quoteProcessor = useRecoilValue(quoteProcessorAtom);
  const saveQuoteTrigger = useRecoilValue(saveQuoteTriggerAtom);
  const [initValues, setInitValues] = useRecoilState(initValuesAtom);
  const [capitalCosts, setCapitalCosts] = useRecoilState(capitalCostsAtom);
  
  useEffect(() => {
    if(saveQuoteTrigger) {
      quoteProcessor.setCapitalCostData({
        capitalCosts: capitalCosts.map(o => ({
          description: o.description,
          paymentType: o.paymentType !== 'NULL' ? o.paymentType : null,
          enabled: o.enabled,
          unitCost: o.unitCost && !isNaN(parseFloat(o.unitCost)) ? parseFloat(o.unitCost) : null,
          unitPrice: o.unitPrice && !isNaN(parseFloat(o.unitPrice)) ? parseFloat(o.unitPrice) : null,
          depreciationMonths: o.depreciationMonths !== 'NULL' ? o.depreciationMonths : null,
        })),
      });
    }
  }, [saveQuoteTrigger, quoteProcessor, capitalCosts]);
  
  useEffect(() => {
    if(initValues && quoteData) {
      const lines = quoteData?.moldedParts ? quoteData.moldedParts.map(moldedPart => [
        {
          description: `Tool Cost A (${moldedPart.partDescription})`,
          unitCost: `${moldedPart.toolCostA}`,
          unitPrice: quoteData?.capitalCosts?.find(o => o.description === `Tool Cost A (${moldedPart.partDescription})`)?.unitPrice ?
            `${quoteData.capitalCosts.find(o => o.description === `Tool Cost A (${moldedPart.partDescription})`).unitPrice}` :
            '',
          enabled: quoteData?.capitalCosts?.find(o => o.description === `Tool Cost A (${moldedPart.partDescription})`) ?
            quoteData.capitalCosts.find(o => o.description === `Tool Cost A (${moldedPart.partDescription})`).enabled :
            false,
          paymentType: quoteData?.capitalCosts?.find(o => o.description === `Tool Cost A (${moldedPart.partDescription})`) ?
            quoteData.capitalCosts.find(o => o.description === `Tool Cost A (${moldedPart.partDescription})`).paymentType :
            'CUSTOMER',
          depreciationMonths: quoteData?.capitalCosts?.find(o => o.description === `Tool Cost A (${moldedPart.partDescription})`)?.depreciationMonths ?
            quoteData.capitalCosts.find(o => o.description === `Tool Cost A (${moldedPart.partDescription})`).depreciationMonths :
            'NULL',
        },
        {
          description: `Tool Tuning & Texture A (${moldedPart.partDescription})`,
          unitCost: `${moldedPart.tuningTextureCostA}`,
          unitPrice: quoteData?.capitalCosts?.find(o => o.description === `Tool Tuning & Texture A (${moldedPart.partDescription})`)?.unitPrice ?
            `${quoteData.capitalCosts.find(o => o.description === `Tool Tuning & Texture A (${moldedPart.partDescription})`).unitPrice}` :
            '',
          enabled: quoteData?.capitalCosts?.find(o => o.description === `Tool Tuning & Texture A (${moldedPart.partDescription})`) ?
            quoteData.capitalCosts.find(o => o.description === `Tool Tuning & Texture A (${moldedPart.partDescription})`).enabled :
            false,
          paymentType: quoteData?.capitalCosts?.find(o => o.description === `Tool Tuning & Texture A (${moldedPart.partDescription})`) ?
            quoteData.capitalCosts.find(o => o.description === `Tool Tuning & Texture A (${moldedPart.partDescription})`).paymentType :
            'CUSTOMER',
          depreciationMonths: quoteData?.capitalCosts?.find(o => o.description === `Tool Tuning & Texture A (${moldedPart.partDescription})`)?.depreciationMonths ?
            quoteData.capitalCosts.find(o => o.description === `Tool Tuning & Texture A (${moldedPart.partDescription})`).depreciationMonths :
            'NULL',
        },
        {
          description: `Tool Boat Shipment A (${moldedPart.partDescription})`,
          unitCost: `${moldedPart.boatShipmentCostA}`,
          unitPrice: quoteData?.capitalCosts?.find(o => o.description === `Tool Boat Shipment A (${moldedPart.partDescription})`)?.unitPrice ?
            `${quoteData.capitalCosts.find(o => o.description === `Tool Boat Shipment A (${moldedPart.partDescription})`).unitPrice}` :
            '',
          enabled: quoteData?.capitalCosts?.find(o => o.description === `Tool Boat Shipment A (${moldedPart.partDescription})`) ?
            quoteData.capitalCosts.find(o => o.description === `Tool Boat Shipment A (${moldedPart.partDescription})`).enabled :
            false,
          paymentType: quoteData?.capitalCosts?.find(o => o.description === `Tool Boat Shipment A (${moldedPart.partDescription})`) ?
            quoteData.capitalCosts.find(o => o.description === `Tool Boat Shipment A (${moldedPart.partDescription})`).paymentType :
            'CUSTOMER',
          depreciationMonths: quoteData?.capitalCosts?.find(o => o.description === `Tool Boat Shipment A (${moldedPart.partDescription})`)?.depreciationMonths ?
            quoteData.capitalCosts.find(o => o.description === `Tool Boat Shipment A (${moldedPart.partDescription})`).depreciationMonths :
            'NULL',
        },
        {
          description: `Tool Air Shipment A (${moldedPart.partDescription})`,
          unitCost: `${moldedPart.airShipmentCostA}`,
          unitPrice: quoteData?.capitalCosts?.find(o => o.description === `Tool Air Shipment A (${moldedPart.partDescription})`)?.unitPrice ?
            `${quoteData.capitalCosts.find(o => o.description === `Tool Air Shipment A (${moldedPart.partDescription})`).unitPrice}` :
            '',
          enabled: quoteData?.capitalCosts?.find(o => o.description === `Tool Air Shipment A (${moldedPart.partDescription})`) ?
            quoteData.capitalCosts.find(o => o.description === `Tool Air Shipment A (${moldedPart.partDescription})`).enabled :
            false,
          paymentType: quoteData?.capitalCosts?.find(o => o.description === `Tool Air Shipment A (${moldedPart.partDescription})`) ?
            quoteData.capitalCosts.find(o => o.description === `Tool Air Shipment A (${moldedPart.partDescription})`).paymentType :
            'CUSTOMER',
          depreciationMonths: quoteData?.capitalCosts?.find(o => o.description === `Tool Air Shipment A (${moldedPart.partDescription})`)?.depreciationMonths ?
            quoteData.capitalCosts.find(o => o.description === `Tool Air Shipment A (${moldedPart.partDescription})`).depreciationMonths :
            'NULL',
        },
        ...(moldedPart.hasOptionB ?
          [
            {
              description: `Tool Cost B (${moldedPart.partDescription})`,
              unitCost: `${moldedPart.toolCostB}`,
              unitPrice: quoteData?.capitalCosts?.find(o => o.description === `Tool Cost B (${moldedPart.partDescription})`)?.unitPrice ?
                `${quoteData.capitalCosts.find(o => o.description === `Tool Cost B (${moldedPart.partDescription})`).unitPrice}` :
                '',
              enabled: quoteData?.capitalCosts?.find(o => o.description === `Tool Cost B (${moldedPart.partDescription})`) ?
                quoteData.capitalCosts.find(o => o.description === `Tool Cost B (${moldedPart.partDescription})`).enabled :
                false,
              paymentType: quoteData?.capitalCosts?.find(o => o.description === `Tool Cost B (${moldedPart.partDescription})`) ?
                quoteData.capitalCosts.find(o => o.description === `Tool Cost B (${moldedPart.partDescription})`).paymentType :
                'CUSTOMER',
              depreciationMonths: quoteData?.capitalCosts?.find(o => o.description === `Tool Cost B (${moldedPart.partDescription})`)?.depreciationMonths ?
                quoteData.capitalCosts.find(o => o.description === `Tool Cost B (${moldedPart.partDescription})`).depreciationMonths :
                'NULL',
            },
            {
              description: `Tool Tuning & Texture B (${moldedPart.partDescription})`,
              unitCost: `${moldedPart.tuningTextureCostB}`,
              unitPrice: quoteData?.capitalCosts?.find(o => o.description === `Tool Tuning & Texture B (${moldedPart.partDescription})`)?.unitPrice ?
                `${quoteData.capitalCosts.find(o => o.description === `Tool Tuning & Texture B (${moldedPart.partDescription})`).unitPrice}` :
                '',
              enabled: quoteData?.capitalCosts?.find(o => o.description === `Tool Tuning & Texture B (${moldedPart.partDescription})`) ?
                quoteData.capitalCosts.find(o => o.description === `Tool Tuning & Texture B (${moldedPart.partDescription})`).enabled :
                false,
              paymentType: quoteData?.capitalCosts?.find(o => o.description === `Tool Tuning & Texture B (${moldedPart.partDescription})`) ?
                quoteData.capitalCosts.find(o => o.description === `Tool Tuning & Texture B (${moldedPart.partDescription})`).paymentType :
                'CUSTOMER',
              depreciationMonths: quoteData?.capitalCosts?.find(o => o.description === `Tool Tuning & Texture B (${moldedPart.partDescription})`)?.depreciationMonths ?
                quoteData.capitalCosts.find(o => o.description === `Tool Tuning & Texture B (${moldedPart.partDescription})`).depreciationMonths :
                'NULL',
            },
            {
              description: `Tool Boat Shipment B (${moldedPart.partDescription})`,
              unitCost: `${moldedPart.boatShipmentCostB}`,
              unitPrice: quoteData?.capitalCosts?.find(o => o.description === `Tool Boat Shipment B (${moldedPart.partDescription})`)?.unitPrice ?
                `${quoteData.capitalCosts.find(o => o.description === `Tool Boat Shipment B (${moldedPart.partDescription})`).unitPrice}` :
                '',
              enabled: quoteData?.capitalCosts?.find(o => o.description === `Tool Boat Shipment B (${moldedPart.partDescription})`) ?
                quoteData.capitalCosts.find(o => o.description === `Tool Boat Shipment B (${moldedPart.partDescription})`).enabled :
                false,
              paymentType: quoteData?.capitalCosts?.find(o => o.description === `Tool Boat Shipment B (${moldedPart.partDescription})`) ?
                quoteData.capitalCosts.find(o => o.description === `Tool Boat Shipment B (${moldedPart.partDescription})`).paymentType :
                'CUSTOMER',
              depreciationMonths: quoteData?.capitalCosts?.find(o => o.description === `Tool Boat Shipment B (${moldedPart.partDescription})`)?.depreciationMonths ?
                quoteData.capitalCosts.find(o => o.description === `Tool Boat Shipment B (${moldedPart.partDescription})`).depreciationMonths :
                'NULL',
            },
            {
              description: `Tool Air Shipment B (${moldedPart.partDescription})`,
              unitCost: `${moldedPart.airShipmentCostB}`,
              unitPrice: quoteData?.capitalCosts?.find(o => o.description === `Tool Air Shipment B (${moldedPart.partDescription})`)?.unitPrice ?
                `${quoteData.capitalCosts.find(o => o.description === `Tool Air Shipment B (${moldedPart.partDescription})`).unitPrice}` :
                '',
              enabled: quoteData?.capitalCosts?.find(o => o.description === `Tool Air Shipment B (${moldedPart.partDescription})`) ?
                quoteData.capitalCosts.find(o => o.description === `Tool Air Shipment B (${moldedPart.partDescription})`).enabled :
                false,
              paymentType: quoteData?.capitalCosts?.find(o => o.description === `Tool Air Shipment B (${moldedPart.partDescription})`) ?
                quoteData.capitalCosts.find(o => o.description === `Tool Air Shipment B (${moldedPart.partDescription})`).paymentType :
                'CUSTOMER',
              depreciationMonths: quoteData?.capitalCosts?.find(o => o.description === `Tool Air Shipment B (${moldedPart.partDescription})`)?.depreciationMonths ?
                quoteData.capitalCosts.find(o => o.description === `Tool Air Shipment B (${moldedPart.partDescription})`).depreciationMonths :
                'NULL',
            },
          ] : []),
      ]).flat() : [];
      setCapitalCosts(lines);
      setInitValues(false);
    }
  }, [initValues, quoteData, setInitValues, setCapitalCosts]);
  
  const manufacturingAssignment = quoteData.assignments.find(o => o.types.includes('MANUFACTURING_ESTIMATOR'));
  const manufacturingAssignedUserId = manufacturingAssignment ? manufacturingAssignment.userId : null;
  const designAssignment = quoteData.assignments.find(o => o.types.includes('DESIGN_ESTIMATOR'));
  const designAssignedUserId = designAssignment ? designAssignment.userId : null;
  const toolingAssignment = quoteData.assignments.find(o => o.types.includes('TOOLING_ESTIMATOR'));
  const toolingAssignedUserId = toolingAssignment ? toolingAssignment.userId : null;
  const purchasingAssignment = quoteData.assignments.find(o => o.types.includes('PURCHASING_ESTIMATOR'));
  const purchasingAssignedUserId = purchasingAssignment ? purchasingAssignment.userId : null;
  const feasibilityAssignment = quoteData.assignments.find(o => o.types.includes('FEASIBILITY'));
  const feasibilityAssignmentUserId = feasibilityAssignment ? feasibilityAssignment.userId : null;
  
  const disableAllInputs = quoteData.status !== 'SANDBOX' &&
    (quoteData.holdUserId !== userId || quoteData.isNotActive || !['IN_PROGRESS', 'FEASIBILITY', 'SALES_APPROVAL', 'COMPLETED'].includes(quoteData.status) ||
    (!(appRoles.includes(CONFIG.ROLE_MAPPINGS.DESIGN_ESTIMATOR) && userId === designAssignedUserId) &&
      !(appRoles.includes(CONFIG.ROLE_MAPPINGS.MANUFACTURING_ESTIMATOR) && userId === manufacturingAssignedUserId) &&
      !(appRoles.includes(CONFIG.ROLE_MAPPINGS.TOOLING_ESTIMATOR) && userId === toolingAssignedUserId) &&
      !(appRoles.includes(CONFIG.ROLE_MAPPINGS.PURCHASING_ESTIMATOR) && userId === purchasingAssignedUserId) &&
      !(appRoles.includes(CONFIG.ROLE_MAPPINGS.FEASIBILITY) && userId === feasibilityAssignmentUserId) &&
      !(appRoles.includes(CONFIG.ROLE_MAPPINGS.CREATE_QUOTE) && userId === quoteData.createdBy)));
  
  const capitalCalculations = getCapitalCalculations({ capitalCosts });
  
  return (
    <>
      <h5 style={{ ...styles.lineTitle, textAlign: 'left', marginTop: 20, marginBottom: 20 }}>
        <span style={{ ...styles.lineTitleText, backgroundColor: COLORS.white }}>
          Capital Costs
        </span>
      </h5>
      <Table size="sm" style={{ marginBottom: 0 }}>
        <tbody>
        <tr style={{ fontWeight: 'bold' }}>
          <td>Enabled</td>
          <td width="35%">Description</td>
          <td>Payment Type</td>
          <td>Depreciation (months)</td>
          <td>Cost</td>
          <td>Price</td>
        </tr>
        {capitalCosts.length ? capitalCosts.map((line, index) => (
            <tr key={line.description}>
              <td style={{ textAlign: 'center' }}>
                <Form.Check
                  type="checkbox"
                  label=""
                  checked={line.enabled}
                  onChange={e => setCapitalCosts(prevState => {
                    const newState = cloneDeep(prevState);
                    newState[index].enabled = e.target.checked;
                    return newState;
                  })}
                  disabled={disableAllInputs}
                />
              </td>
              <td>
                <Form.Control
                  type="text"
                  value={line.description}
                  disabled
                />
              </td>
              <td>
                <Form.Select
                  as="select"
                  value={line.paymentType}
                  onChange={e => setCapitalCosts(prevState => {
                    const newState = cloneDeep(prevState);
                    newState[index].paymentType = e.target.value;
                    if(!e.target.value.startsWith('DEPRECIATE')) newState[index].depreciationMonths = 'NULL';
                    return newState;
                  })}
                  disabled={!line.enabled || disableAllInputs}
                >
                  <option value="NULL">-- Select --</option>
                  {paymentTypeLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
                </Form.Select>
              </td>
              <td>
                <Form.Select
                  as="select"
                  value={line.depreciationMonths}
                  onChange={e => setCapitalCosts(prevState => {
                    const newState = cloneDeep(prevState);
                    newState[index].depreciationMonths = e.target.value !== 'NULL' && !isNaN(parseInt(e.target.value, 10)) ?
                      parseInt(e.target.value, 10) : null;
                    return newState;
                  })}
                  disabled={!line.enabled || !line.paymentType.startsWith('DEPRECIATE') || disableAllInputs}
                >
                  <option value="NULL">-- Select --</option>
                  {depreciationMonthsLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
                </Form.Select>
              </td>
              <td>
                <InputGroup>
                  <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                  <Form.Control
                    type="number"
                    value={line.unitCost}
                    disabled
                  />
                </InputGroup>
              </td>
              <td>
                <InputGroup>
                  <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                  <Form.Control
                    type="number"
                    value={line.unitPrice}
                    onChange={e => setCapitalCosts(prevState => {
                      const newState = cloneDeep(prevState);
                      newState[index].unitPrice = e.target.value;
                      return newState;
                    })}
                    disabled={!line.enabled || disableAllInputs}
                  />
                </InputGroup>
              </td>
            </tr>
          )) :
          null}
        </tbody>
      </Table>
      <div style={styles.addMoldedPartButtonRow}>
        <p style={{ marginRight: 10, marginBottom: 0 }}>Total Capital Cost</p>
        <InputGroup style={{ flexBasis: '25%' }}>
          <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
          <Form.Control
            type="number"
            value={capitalCalculations?.totalCapitalCost ? capitalCalculations.totalCapitalCost.toFixed(5) : ''}
            disabled
          />
        </InputGroup>
      </div>
      <div style={styles.addMoldedPartButtonRow}>
        <p style={{ marginRight: 10, marginBottom: 0 }}>Total Capital Price</p>
        <InputGroup style={{ flexBasis: '25%' }}>
          <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
          <Form.Control
            type="number"
            value={capitalCalculations?.totalCapitalPrice ? capitalCalculations.totalCapitalPrice.toFixed(5) : ''}
            disabled
          />
        </InputGroup>
      </div>
      <div style={styles.addMoldedPartButtonRow}>
        <p style={{ marginRight: 10, marginBottom: 0 }}>Total Depreciated Into Cost</p>
        <InputGroup style={{ flexBasis: '25%' }}>
          <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
          <Form.Control
            type="number"
            value={capitalCalculations?.totalDepreciatedCost ? capitalCalculations.totalDepreciatedCost.toFixed(5) : ''}
            disabled
          />
        </InputGroup>
      </div>
      <div style={styles.addMoldedPartButtonRow}>
        <p style={{ marginRight: 10, marginBottom: 0 }}>Total Depreciated Into Price</p>
        <InputGroup style={{ flexBasis: '25%' }}>
          <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
          <Form.Control
            type="number"
            value={capitalCalculations?.totalDepreciatedPrice ? capitalCalculations.totalDepreciatedPrice.toFixed(5) : ''}
            disabled
          />
        </InputGroup>
      </div>
      <div style={styles.addMoldedPartButtonRow}>
        <p style={{ marginRight: 10, marginBottom: 0 }}>Total Expense Cost</p>
        <InputGroup style={{ flexBasis: '25%' }}>
          <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
          <Form.Control
            type="number"
            value={capitalCalculations?.totalExpenseCost ? capitalCalculations.totalExpenseCost.toFixed(5) : ''}
            disabled
          />
        </InputGroup>
      </div>
      <div style={styles.addMoldedPartButtonRow}>
        <p style={{ marginRight: 10, marginBottom: 0 }}>Total Customer Pays Cost</p>
        <InputGroup style={{ flexBasis: '25%' }}>
          <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
          <Form.Control
            type="number"
            value={capitalCalculations?.totalCustomerPaysCost ? capitalCalculations.totalCustomerPaysCost.toFixed(5) : ''}
            disabled
          />
        </InputGroup>
      </div>
    </>
  );
};

const styles = {
  lineTitle: {
    textAlign: 'center',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: COLORS.midGray,
    lineHeight: '0.1em',
    marginTop: 12,
    marginBottom: 12,
    color: COLORS.darkGray,
  },
  lineTitleText: {
    backgroundColor: COLORS.offWhite,
    paddingLeft: 7,
    paddingRight: 7,
  },
  addMoldedPartButtonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 5,
    marginBottom: 8,
  },
  leftInputGroupText: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRightWidth: 0,
  },
};

export default QuoteTabCapital;