import EventEmitter from 'events';

const isJsonString = str => {
  try {
    if(str == null) return false;
    JSON.parse(str);
    return true;
  }
  catch(e) {
    return false;
  }
};

class WebSocketClient extends EventEmitter {
  ws = null;
  isOpen = false;
  isTerminating = false;
  
  constructor(endpoint, apiKey) {
    super();
    this.connect(endpoint, apiKey);
  }
  
  connect(endpoint, apiKey) {
    this.ws = new WebSocket(`${endpoint}?apiKey=${apiKey}`);
    this.ws.onopen = () => {
      // console.log('Connected to websocket');
      this.isOpen = true;
      this.emit('open');
    };
    this.ws.onerror = e => this.emit('error', e.message);
    this.ws.onmessage = e => {
      const payload = isJsonString(e.data) ? JSON.parse(e.data) : e.data;
      this.handleMessage(payload.topic, payload.payload);
    };
    this.ws.onclose = () => {
      // console.log('Disconnected from websocket');
      this.isOpen = false;
      this.emit('close');
      if(!this.isTerminating) setTimeout(() => this.connect(endpoint, apiKey), 100);
    };
  }
  
  handleMessage(topic, payload) {
    if(isJsonString(payload)) payload = JSON.parse(payload);
    this.emit('message', topic, payload);
  }
  
  subscribe(topic) {
    if(!this.ws || !this.isOpen) return;
    this.ws.send(JSON.stringify({ action: 'subscribe', topic }));
  }
  
  unsubscribe(topic) {
    if(!this.ws || !this.isOpen) return;
    this.ws.send(JSON.stringify({ action: 'unsubscribe', topic }));
  }
  
  terminate() {
    if(!this.ws || !this.isOpen) return;
    this.isTerminating = true;
    this.ws.close();
  }
}

export default WebSocketClient;