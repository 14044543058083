import {
  appRolesAtom,
  quoteDataAtom,
  userIdAtom,
  accessTokenAtom,
  quoteNewPartNumbersAtom,
  loadingMessageSelector,
} from '../state';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { v4 as uuid } from 'uuid';
import { cmp, getPartType, getPartSubType } from '../helpers/misc';
import COLORS from '../styles/colors';
import { Button, Form, InputGroup, Table } from 'react-bootstrap';
import cloneDeep from 'lodash.clonedeep';
import { useEffect, useState } from 'react';
import * as CONFIG from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { assignQuoteNewPartNumbers } from '../helpers/api';
import { useAlert } from 'react-alert';

const QuoteTabNewParts = () => {
  const quoteData = useRecoilValue(quoteDataAtom);
  const userId = useRecoilValue(userIdAtom);
  const appRoles = useRecoilValue(appRolesAtom);
  const accessToken = useRecoilValue(accessTokenAtom);
  const setLoadingMessage = useSetRecoilState(loadingMessageSelector);
  const [newParts, setNewParts] = useState([]);
  const [quoteNewPartNumbers, setQuoteNewPartNumbers] = useRecoilState(quoteNewPartNumbersAtom);
  const alert = useAlert();
  
  useEffect(() => {
    const newPartBomNodes = quoteData?.bom?.nodes?.filter(node =>
      (node.type === 'Assembled') ||
      (node.type === 'Molded') ||
      (node.type === 'Purchased' && node.isNew) ||
      (node.type === 'Packaging' && node.isNew) ||
      (node.type === 'Material' && node.isNew),
    )?.map(node => {
      const newPartNumberObj = quoteNewPartNumbers.find(o => o.nodeId === node.id);
      return {
        ...node,
        materialCost: node.materialCost ? parseFloat(node.materialCost) : undefined,
        packagingCost: node.packagingCost ? parseFloat(node.packagingCost) : undefined,
        enabled: true,
        partNumber: newPartNumberObj ? newPartNumberObj.partNumber : '',
      };
    });
    const newPhantomBlends = quoteData?.moldedParts?.filter(o => o.createMaterialBlends && o?.materials?.length > 1)?.map(o => {
      const name = o.materials.map(m => `${m.description} (${m.blendPercentage}%)`).join(' + ');
      const newPartNumberObj = quoteNewPartNumbers.find(node => node.partName === name);
      return {
        type: 'Material',
        materialType: 'BLEND',
        isNew: true,
        id: newPartNumberObj ? newPartNumberObj.nodeId : uuid(),
        materialCost: o.materials.reduce((total, cur) => total + (cur.materialCost * (cur.blendPercentage / 100)), 0),
        name,
        enabled: true,
        partNumber: newPartNumberObj ? newPartNumberObj.partNumber : '',
      };
    });
    setNewParts([
      ...newPartBomNodes,
      ...newPhantomBlends,
    ].sort((a, b) => cmp(a.type, b.type) || cmp(a.name, b.name)));
  }, [quoteData, quoteNewPartNumbers]);
  
  const disableAllInputs = quoteData.status !== 'SANDBOX' &&
    (quoteData.holdUserId !== userId || quoteData.isNotActive || !['AWARDED'].includes(quoteData.status) ||
      (!(appRoles.includes(CONFIG.ROLE_MAPPINGS.CREATE_QUOTE) && userId === quoteData.createdBy)));
  
  const assignNewPartNumbers = async () => {
    try {
      setLoadingMessage('Assigning part numbers');
      const newPartsRecords = newParts.filter(o => o.enabled && !quoteNewPartNumbers.some(node => node.nodeId === o.id))
        .map(o => ({
          id: o.id,
          name: o.name,
          partType: getPartType(o),
          isOutsourced: o.isOutsourced,
          isEstimated: o.isEstimated,
          partSubType: getPartSubType(o),
          blendPercentage: o.blendPercentage,
          partCost: o.materialCost ? o.materialCost : o.packagingCost ? o.packagingCost : undefined,
        }));
      const quoteNewParts = await assignQuoteNewPartNumbers(
        accessToken,
        quoteData.quoteId,
        quoteData.revision,
        newPartsRecords,
      );
      setQuoteNewPartNumbers(quoteNewParts ? quoteNewParts : []);
      alert.success('Part numbers assigned');
    }
    catch(e) {
      console.error('assignNewPartNumbers error ->', e);
      alert.error('Part numbers not assigned');
    }
    finally {
      setLoadingMessage(null);
    }
  };
  
  return (
    <>
      <h5 style={{ ...styles.lineTitle, textAlign: 'left', marginTop: 20, marginBottom: 20 }}>
        <span style={{ ...styles.lineTitleText, backgroundColor: COLORS.white }}>
          New Parts
        </span>
      </h5>
      <Table size="sm" style={{ marginBottom: 0 }}>
        <tbody>
        <tr style={{ fontWeight: 'bold' }}>
          <td>Enabled</td>
          <td width="35%">Description</td>
          <td>Type</td>
          <td>Cost</td>
          <td>Part Number</td>
        </tr>
        {newParts?.length ? newParts.map((part, index) => (
            <tr key={part.id}>
              <td style={{ textAlign: 'center' }}>
                <Form.Check
                  type="checkbox"
                  label=""
                  checked={part.enabled}
                  onChange={e => setNewParts(prevState => {
                    const newState = cloneDeep(prevState);
                    newState[index].enabled = e.target.checked;
                    return newState;
                  })}
                  disabled={disableAllInputs || quoteNewPartNumbers.some(o => o.nodeId === part.id)}
                />
              </td>
              <td>
                <Form.Control
                  type="text"
                  value={part.name}
                  disabled
                />
              </td>
              <td>
                <Form.Control
                  type="text"
                  value={`${part.type}${part.materialType ? ` (${part.materialType})` : ''}${part.packagingType ? ` (${part.packagingType})` : ''}`}
                  disabled
                />
              </td>
              <td>
                <InputGroup>
                  <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                  <Form.Control
                    type="text"
                    value={part.materialCost ? `${part.materialCost}` : part.packagingCost ? `${part.packagingCost}` : part.partCost ? `${part.partCost}` : ''}
                    disabled
                  />
                </InputGroup>
              </td>
              <td>
                <Form.Control
                  type="text"
                  value={part.partNumber}
                  disabled
                />
              </td>
            </tr>
          )) :
          null}
        </tbody>
      </Table>
      <div style={styles.addMoldedPartButtonRow}>
        <Button
          type="button"
          variant="primary"
          disabled={disableAllInputs || !newParts?.filter(o => o.enabled && !quoteNewPartNumbers.some(node => node.nodeId === o.id))?.length}
          onClick={assignNewPartNumbers}
        >
          <FontAwesomeIcon icon={faPlus} style={{ marginRight: 4 }}/>
          Assign Part Numbers
        </Button>
      </div>
    </>
  );
};

const styles = {
  lineTitle: {
    textAlign: 'center',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: COLORS.midGray,
    lineHeight: '0.1em',
    marginTop: 12,
    marginBottom: 12,
    color: COLORS.darkGray,
  },
  lineTitleText: {
    backgroundColor: COLORS.offWhite,
    paddingLeft: 7,
    paddingRight: 7,
  },
  addMoldedPartButtonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 5,
    marginBottom: 8,
  },
  leftInputGroupText: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRightWidth: 0,
  },
};

export default QuoteTabNewParts;