import { useEffect } from 'react';
import COLORS from '../styles/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Button, Form, InputGroup, Table } from 'react-bootstrap';
import * as CONFIG from '../config';
import {
  quoteDataAtom,
  appRolesAtom,
  userIdAtom,
  assemblyTypesLovAtom,
  locationLovAtom,
  inspectionLovAtom,
  packagingLovAtom,
  currentRatesAtom,
  fgLocationSelectAtom,
  moldedPartsValueAtom,
  saveQuoteTriggerAtom,
  quoteProcessorAtom,
  assemblyCostsAtom,
  inspectionSelectAtom,
  packagingSelectAtom,
  packagingCostsAtom,
  initValuesAtom,
} from '../state';
import { useRecoilState, useRecoilValue } from 'recoil';
import cloneDeep from 'lodash.clonedeep';
import { convertOrgCode, getRateOrgCodeFromLocator } from '../helpers/misc';
import * as CONSTANTS from '../constants';
import { getMoldingCalculations, getAssemblyCalculations } from '../helpers/calculations';


const QuoteTabAssembled = () => {
  const quoteData = useRecoilValue(quoteDataAtom);
  const appRoles = useRecoilValue(appRolesAtom);
  const userId = useRecoilValue(userIdAtom);
  const assemblyTypesLov = useRecoilValue(assemblyTypesLovAtom);
  const locationLov = useRecoilValue(locationLovAtom);
  const inspectionLov = useRecoilValue(inspectionLovAtom);
  const packagingLov = useRecoilValue(packagingLovAtom);
  const currentRates = useRecoilValue(currentRatesAtom);
  const moldedPartsValue = useRecoilValue(moldedPartsValueAtom);
  const fgLocationSelect = useRecoilValue(fgLocationSelectAtom);
  const saveQuoteTrigger = useRecoilValue(saveQuoteTriggerAtom);
  const quoteProcessor = useRecoilValue(quoteProcessorAtom);
  const packagingCosts = useRecoilValue(packagingCostsAtom);
  const [assemblyCosts, setAssemblyCosts] = useRecoilState(assemblyCostsAtom);
  const [inspectionSelect, setInspectionSelect] = useRecoilState(inspectionSelectAtom);
  const [packagingSelect, setPackagingSelect] = useRecoilState(packagingSelectAtom);
  const [initValues, setInitValues] = useRecoilState(initValuesAtom);
  
  useEffect(() => {
    if(saveQuoteTrigger) {
      quoteProcessor.setAssemblyData({
        assemblyCosts: assemblyCosts.map(o => ({
          location: o.location !== 'NULL' ? o.location : null,
          assemblyType: o.description !== 'NULL' ? o.description : null,
          pphPerPerson: o.pphPerPerson && !isNaN(parseFloat(o.pphPerPerson)) ? parseFloat(o.pphPerPerson) : null,
        })),
        assemblyInspection: inspectionSelect !== 'NULL' ? inspectionSelect : null,
        assemblyPackaging: packagingSelect !== 'NULL' ? packagingSelect : null,
      });
    }
  }, [saveQuoteTrigger, quoteProcessor, assemblyCosts, inspectionSelect, packagingSelect]);
  
  useEffect(() => {
    if(initValues && quoteData) {
      setAssemblyCosts(quoteData.assemblyCosts ? quoteData.assemblyCosts.map(o => ({
        location: o.location ? o.location : 'NULL',
        description: o.assemblyType ? o.assemblyType : 'NULL',
        pphPerPerson: o.pphPerPerson,
      })) : []);
      setInspectionSelect(quoteData.assemblyInspection ? quoteData.assemblyInspection : 'NULL');
      setPackagingSelect(quoteData.assemblyPackaging ? quoteData.assemblyPackaging : 'NULL');
      setInitValues(false);
    }
  }, [
    initValues,
    quoteData,
    setAssemblyCosts,
    setInspectionSelect,
    setPackagingSelect,
    setInitValues,
  ]);
  
  const manufacturingAssignment = quoteData.assignments.find(o => o.types.includes('MANUFACTURING_ESTIMATOR'));
  const manufacturingAssignedUserId = manufacturingAssignment ? manufacturingAssignment.userId : null;
  const designAssignment = quoteData.assignments.find(o => o.types.includes('DESIGN_ESTIMATOR'));
  const designAssignedUserId = designAssignment ? designAssignment.userId : null;
  const feasibilityAssignment = quoteData.assignments.find(o => o.types.includes('FEASIBILITY'));
  const feasibilityAssignmentUserId = feasibilityAssignment ? feasibilityAssignment.userId : null;
  
  const disableAllInputs = quoteData.status !== 'SANDBOX' && (quoteData.holdUserId !== userId || quoteData.isNotActive || !['IN_PROGRESS', 'FEASIBILITY'].includes(quoteData.status) ||
    (!(appRoles.includes(CONFIG.ROLE_MAPPINGS.DESIGN_ESTIMATOR) && userId === designAssignedUserId) &&
      !(appRoles.includes(CONFIG.ROLE_MAPPINGS.MANUFACTURING_ESTIMATOR) && userId === manufacturingAssignedUserId) &&
      !(appRoles.includes(CONFIG.ROLE_MAPPINGS.FEASIBILITY) && userId === feasibilityAssignmentUserId)));
  
  const confirmRemoveLine = index => {
    if(window.confirm('Are you sure you want to remove this assembly cost?'))
      setAssemblyCosts(prevState => {
        const newState = cloneDeep(prevState);
        newState.splice(index, 1);
        return newState;
      });
  };
  
  const getAssemblyRate = (orgCode, assemblyType) => {
    const rateObj = currentRates.find(o => o.rateType === 'ASSEMBLY' && o.orgCode === convertOrgCode(orgCode) &&
      o.assemblyType === assemblyType);
    return rateObj ? rateObj.rate : '';
  };
  
  const getLineTotal = (orgCode, assemblyType, pphPerPerson) => {
    const pph = pphPerPerson && !isNaN(parseFloat(pphPerPerson)) ?
      parseFloat(pphPerPerson) : null;
    if(!pph) return '';
    const rate = getAssemblyRate(orgCode, assemblyType);
    if(typeof rate !== 'number') return '';
    return (rate / pph).toFixed(5);
  };
  
  const formatNumberString = (num, dp) => typeof num === 'number' ? num.toFixed(dp) : num;
  
  const assemblyCalculations = getAssemblyCalculations({
    assemblyCosts,
    rates: currentRates,
    location: fgLocationSelect !== 'NULL' ? getRateOrgCodeFromLocator(fgLocationSelect) : null,
    inspectionType: inspectionSelect,
    packagingType: packagingSelect,
  });
  
  const moldingCalculations = moldedPartsValue ? moldedPartsValue.map((partObj, index, array) => {
    const somePartHasOptionB = array.some(o => o.hasOptionB);
    return {
      calculationsA: getMoldingCalculations({
        rates: currentRates,
        location: partObj.toolLocationA,
        pressType: partObj.pressTypeA,
        pressSize: partObj.pressSizeA,
        gateCuttingType: partObj.gateCuttingA,
        annealingType: partObj.annealing,
        shotsPerHour: partObj.shotsPerHourA,
        cavitiesPerMold: partObj.cavitiesPerMoldA,
        numOfDiffParts: partObj.numOfDiffPartsA,
        inspectionType: partObj.inspection,
        packingType: partObj.packaging,
        glassIsUsed: partObj.glassUsed,
        partWeight: partObj.partWeight,
        materials: partObj.materials,
        monthlyVolume: quoteData.monthlyVolume,
        regrindIsUsed: partObj.regrindUsed,
        cavitiesPerPart: partObj.cavitiesPerPartA,
        runnerPercentage: partObj.runnerPercentageA,
        regrindPercentage: partObj.regrindPercentage,
        partType: quoteData.partType,
        toolType: partObj.toolTypeA,
        toolCost: partObj.toolCostA,
        tuningTextureCost: partObj.tuningTextureCostA,
        boatShipmentCost: partObj.boatShipmentCostA,
        airShipmentCost: partObj.airShipmentCostA,
        partIsOutsourced: partObj.isOutsourced,
        outsourcedCost: partObj.outsourcedCost,
        packagingCosts: packagingCosts.filter(o => o.associatedPart === partObj.partDescription),
        productType: quoteData.productType,
        purchasedPartCosts: quoteData.newPurchasedParts,
      }),
      calculationsB: getMoldingCalculations({
        rates: currentRates,
        location: partObj.toolLocationB ? partObj.toolLocationB : somePartHasOptionB ? partObj.toolLocationA : null,
        pressType: partObj.pressTypeB ? partObj.pressTypeB : somePartHasOptionB ? partObj.pressTypeA : null,
        pressSize: partObj.pressSizeB ? partObj.pressSizeB : somePartHasOptionB ? partObj.pressSizeA : null,
        gateCuttingType: partObj.gateCuttingB ? partObj.gateCuttingB : somePartHasOptionB ? partObj.gateCuttingA : null,
        annealingType: partObj.annealing,
        shotsPerHour: partObj.shotsPerHourB ? partObj.shotsPerHourB : somePartHasOptionB ? partObj.shotsPerHourA : null,
        cavitiesPerMold: partObj.cavitiesPerMoldB ? partObj.cavitiesPerMoldB : somePartHasOptionB ? partObj.cavitiesPerMoldA : null,
        numOfDiffParts: partObj.numOfDiffPartsB ? partObj.numOfDiffPartsB : somePartHasOptionB ? partObj.numOfDiffPartsA : null,
        inspectionType: partObj.inspection,
        packingType: partObj.packaging,
        glassIsUsed: partObj.glassUsed,
        partWeight: partObj.partWeight,
        materials: partObj.materials,
        monthlyVolume: quoteData.monthlyVolume,
        regrindIsUsed: partObj.regrindUsed,
        cavitiesPerPart: partObj.cavitiesPerPartB ? partObj.cavitiesPerPartB : somePartHasOptionB ? partObj.cavitiesPerPartA : null,
        runnerPercentage: partObj.runnerPercentageB ? partObj.runnerPercentageB : somePartHasOptionB ? partObj.runnerPercentageA : null,
        regrindPercentage: partObj.regrindPercentage,
        partType: quoteData.partType,
        toolType: partObj.toolTypeB ? partObj.toolTypeB : somePartHasOptionB ? partObj.toolTypeA : null,
        toolCost: partObj.toolCostB ? partObj.toolCostB : somePartHasOptionB ? partObj.toolCostA : null,
        tuningTextureCost: partObj.tuningTextureCostB ? partObj.tuningTextureCostB : somePartHasOptionB ? partObj.tuningTextureCostA : null,
        boatShipmentCost: partObj.boatShipmentCostB ? partObj.boatShipmentCostB : somePartHasOptionB ? partObj.boatShipmentCostA : null,
        airShipmentCost: partObj.airShipmentCostB ? partObj.airShipmentCostB : somePartHasOptionB ? partObj.airShipmentCostA : null,
        partIsOutsourced: partObj.isOutsourced,
        outsourcedCost: partObj.outsourcedCost,
        packagingCosts: packagingCosts.filter(o => o.associatedPart === partObj.partDescription),
        productType: quoteData.productType,
        purchasedPartCosts: quoteData.newPurchasedParts,
      }),
    };
  }) : [];
  
  const totalMoldedComponentCostA = moldingCalculations.reduce((total, cur, index) =>
    cur?.calculationsA?.totalStandardCost && moldedPartsValue[index]?.quantityPerAssembly &&
    typeof moldedPartsValue[index].quantityPerAssembly === 'number' ?
      total + (cur.calculationsA.totalStandardCost * moldedPartsValue[index].quantityPerAssembly) : total, 0);
  
  const totalMoldedComponentCostB = moldingCalculations.reduce((total, cur, index) =>
    cur?.calculationsB?.totalStandardCost && moldedPartsValue[index]?.quantityPerAssembly &&
    typeof moldedPartsValue[index].quantityPerAssembly === 'number' ?
      total + (cur.calculationsB.totalStandardCost * moldedPartsValue[index].quantityPerAssembly) : total, 0);
  
  return (
    <>
      <h5 style={{ ...styles.lineTitle, textAlign: 'left', marginTop: 20, marginBottom: 20 }}>
        <span style={{ ...styles.lineTitleText, backgroundColor: COLORS.white }}>
          Assembly Costs at 85% Efficiency
        </span>
      </h5>
      <Table size="sm" style={{ marginBottom: 0 }}>
        <tbody>
        <tr style={{ fontWeight: 'bold' }}>
          <td>Location</td>
          <td>Description</td>
          <td>Cost/hr/Person</td>
          <td>Parts/hr/Person</td>
          <td>Total</td>
          <td/>
        </tr>
        {assemblyCosts.map((line, index) => (
          <tr key={index}>
            <td>
              <Form.Select
                as="select"
                style={{ flexBasis: '78%' }}
                value={line.location}
                onChange={e => setAssemblyCosts(prevState => {
                  const newState = cloneDeep(prevState);
                  newState[index].location = e.target.value;
                  return newState;
                })}
                disabled={disableAllInputs}
              >
                <option value="NULL">-- Select Location --</option>
                {locationLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
              </Form.Select>
            </td>
            <td>
              <Form.Select
                as="select"
                value={line.description}
                onChange={e => setAssemblyCosts(prevState => {
                  const newState = cloneDeep(prevState);
                  newState[index].description = e.target.value;
                  return newState;
                })}
                disabled={disableAllInputs}
              >
                <option value="NULL">-- Select --</option>
                {assemblyTypesLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
              </Form.Select>
            </td>
            <td>
              <InputGroup>
                <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                <Form.Control
                  type="number"
                  value={getAssemblyRate(line.location, line.description)}
                  disabled
                />
              </InputGroup>
            </td>
            <td>
              <Form.Control
                type="number"
                step="1"
                min="1"
                value={line.pphPerPerson}
                onChange={e => setAssemblyCosts(prevState => {
                  const newState = cloneDeep(prevState);
                  newState[index].pphPerPerson = e.target.value;
                  return newState;
                })}
                disabled={disableAllInputs}
              />
            </td>
            <td>
              <InputGroup>
                <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                <Form.Control
                  type="number"
                  value={formatNumberString(getLineTotal(line.location, line.description, line.pphPerPerson))}
                  disabled
                />
              </InputGroup>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              {
                !disableAllInputs ?
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    className="delete-button"
                    style={styles.deleteButton}
                    onClick={() => confirmRemoveLine(index)}
                  /> :
                  null
              }
            </td>
          </tr>
        ))}
        </tbody>
      </Table>
      <div style={styles.addMoldedPartButtonRow}>
        <Button
          type="button"
          variant="primary"
          onClick={() => setAssemblyCosts(prevState => {
            const newState = cloneDeep(prevState);
            newState.push({
              location: 'NULL',
              description: 'NULL',
              pphPerPerson: '',
            });
            return newState;
          })}
          disabled={disableAllInputs}
        >
          <FontAwesomeIcon icon={faPlus} style={{ marginRight: 4 }}/>
          Add Assembly Cost
        </Button>
      </div>
      <div style={styles.addMoldedPartButtonRow}>
        <p style={{ marginRight: 10, marginBottom: 0 }}>Total Assembly Cost</p>
        <InputGroup style={{ flexBasis: '25%' }}>
          <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
          <Form.Control
            type="number"
            value={assemblyCalculations?.totalAssemblyCost ? assemblyCalculations.totalAssemblyCost.toFixed(5) : ''}
            disabled
          />
        </InputGroup>
      </div>
      <h5 style={{ ...styles.lineTitle, textAlign: 'left', marginTop: 20, marginBottom: 20 }}>
                  <span style={{ ...styles.lineTitleText, backgroundColor: COLORS.white }}>
                    Assembly Post Processing Costs
                  </span>
      </h5>
      <div style={styles.tabContainerRow}>
        <div style={styles.tabContainerLabel}>
          <p style={styles.noMarginText}>Inspection</p>
        </div>
        <Form.Select
          as="select"
          style={{ flexBasis: '78%' }}
          value={inspectionSelect}
          onChange={e => setInspectionSelect(e.target.value)}
          disabled={disableAllInputs}
        >
          <option value="NULL">-- Select --</option>
          {inspectionLov.filter(o =>
            (quoteData.partType === 'CRITICAL' && o.partType === 'CRITICAL') || (quoteData.partType !== 'CRITICAL' && o.partType === 'NON_CRITICAL'))
            .map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
          {quoteData.monthlyVolume && quoteData.monthlyVolume >= CONSTANTS.HIGH_MONTHLY_VOLUME ?
            <option value="HIGH_CAVITATION">5 - High cavitation</option> : null}
        </Form.Select>
        <div style={{ ...styles.tabContainerLabel, flexGrow: 1 }}>
          <p style={styles.noMarginText}>Measuring & Packaging</p>
        </div>
        <Form.Select
          as="select"
          style={{ flexBasis: '26%', marginRight: 10 }}
          value={packagingSelect}
          onChange={e => setPackagingSelect(e.target.value)}
          disabled={disableAllInputs}
        >
          <option value="NULL">-- Select --</option>
          {packagingLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
        </Form.Select>
      </div>
      <div style={styles.addMoldedPartButtonRow}>
        <p style={{ marginRight: 10, marginBottom: 0 }}>Total Assembly Post Processing Cost</p>
        <InputGroup style={{ flexBasis: '25%' }}>
          <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
          <Form.Control
            type="text"
            value={assemblyCalculations?.totalPostProcessingCost ? assemblyCalculations.totalPostProcessingCost.toFixed(5) : ''}
            disabled
          />
        </InputGroup>
      </div>
      
      <h5 style={{ ...styles.lineTitle, textAlign: 'left', marginTop: 20, marginBottom: 20 }}>
                  <span style={{ ...styles.lineTitleText, backgroundColor: COLORS.white }}>
                    Molded Components
                  </span>
      </h5>
      <Table size="sm" style={{ marginBottom: 15 }}>
        <tbody>
        <tr style={{ fontWeight: 'bold' }}>
          <td/>
          <td/>
          <td colSpan="4" style={{ textAlign: 'center' }}>Option A</td>
          <td colSpan="4" style={{ textAlign: 'center' }}>Option B</td>
        </tr>
        <tr style={{ fontWeight: 'bold' }}>
          <td>Component</td>
          <td>Quantity</td>
          <td>Cavities</td>
          <td>Unit Cost</td>
          <td>Total Cost</td>
          <td>Location</td>
          <td>Cavities</td>
          <td>Unit Cost</td>
          <td>Total Cost</td>
          <td>Location</td>
        </tr>
        {moldedPartsValue?.length ?
          moldedPartsValue.map((partObj, index, array) => {
            const somePartHasOptionB = array.some(o => o.hasOptionB);
            return (
              <tr key={index}>
                <td style={{ verticalAlign: 'middle' }}>{partObj.partDescription}</td>
                <td style={{ verticalAlign: 'middle' }}>{partObj.quantityPerAssembly}</td>
                <td style={{ verticalAlign: 'middle' }}>{partObj.cavitiesPerPartA}</td>
                <td style={{ verticalAlign: 'middle' }}>
                  {moldingCalculations[index]?.calculationsA?.totalStandardCost ?
                    moldingCalculations[index].calculationsA.totalStandardCost.toFixed(5) : ''}
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  {moldingCalculations[index]?.calculationsA?.totalStandardCost && partObj.quantityPerAssembly &&
                  typeof partObj.quantityPerAssembly === 'number' ?
                    (moldingCalculations[index].calculationsA.totalStandardCost * partObj.quantityPerAssembly).toFixed(5) : ''}
                </td>
                <td style={{ verticalAlign: 'middle' }}>{partObj.toolLocationA}</td>
                <td
                  style={{ verticalAlign: 'middle' }}>{partObj.cavitiesPerPartB ? partObj.cavitiesPerPartB : somePartHasOptionB ? partObj.cavitiesPerPartA : ''}</td>
                <td style={{ verticalAlign: 'middle' }}>
                  {moldingCalculations[index]?.calculationsB?.totalStandardCost && somePartHasOptionB ?
                    moldingCalculations[index].calculationsB.totalStandardCost.toFixed(5) : ''}
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  {moldingCalculations[index]?.calculationsB?.totalStandardCost && partObj.quantityPerAssembly &&
                  typeof partObj.quantityPerAssembly === 'number' && somePartHasOptionB ?
                    (moldingCalculations[index].calculationsB.totalStandardCost * partObj.quantityPerAssembly).toFixed(5) : ''}
                </td>
                <td
                  style={{ verticalAlign: 'middle' }}>{partObj.toolLocationB ? partObj.toolLocationB : somePartHasOptionB ? partObj.toolLocationA : ''}</td>
              </tr>
            );
          }) :
          <tr>
            <td colSpan="10">
              No molded parts added to the BOM
            </td>
          </tr>
        }
        <tr>
          <td colSpan="2" style={{ verticalAlign: 'middle' }}>
            Total Molded Component Cost
          </td>
          <td colSpan="4" style={{ verticalAlign: 'middle' }}>
            <InputGroup>
              <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
              <Form.Control
                type="text"
                value={totalMoldedComponentCostA ? totalMoldedComponentCostA.toFixed(5) : ''}
                disabled
              />
            </InputGroup>
          </td>
          <td colSpan="4" style={{ verticalAlign: 'middle' }}>
            <InputGroup>
              <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
              <Form.Control
                type="text"
                value={totalMoldedComponentCostB && moldedPartsValue.some(o => o.hasOptionB) ? totalMoldedComponentCostB.toFixed(5) : ''}
                disabled
              />
            </InputGroup>
          </td>
        </tr>
        </tbody>
      </Table>
    </>
  );
};

const styles = {
  tabContainerRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 7,
  },
  lineTitle: {
    textAlign: 'center',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: COLORS.midGray,
    lineHeight: '0.1em',
    marginTop: 12,
    marginBottom: 12,
    color: COLORS.darkGray,
  },
  lineTitleText: {
    backgroundColor: COLORS.offWhite,
    paddingLeft: 7,
    paddingRight: 7,
  },
  noMarginText: {
    margin: 0,
  },
  tabContainerLabel: {
    flexBasis: '22%',
    textAlign: 'right',
    paddingRight: 10,
  },
  addMoldedPartButtonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 5,
    marginBottom: 8,
  },
  leftInputGroupText: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRightWidth: 0,
  },
  deleteButton: {
    marginLeft: 5,
    cursor: 'pointer',
  },
};

export default QuoteTabAssembled;