import { isJsonString } from './misc';
import { STORAGE_KEY_PREFIX } from '../config';
import localforage from 'localforage';

export const storeData = (key, data) => localStorage.setItem(
  `${STORAGE_KEY_PREFIX}${key}`,
  typeof data === 'object' ? JSON.stringify(data) : data,
);
export const deleteData = key => localStorage.removeItem(`${STORAGE_KEY_PREFIX}${key}`);
export const getDataString = key => localStorage.getItem(`${STORAGE_KEY_PREFIX}${key}`);
export const getDataInt = key => {
  const strData = localStorage.getItem(`${STORAGE_KEY_PREFIX}${key}`);
  const intData = strData != null ? parseInt(strData, 10) : null;
  return intData != null && !isNaN(intData) ? intData : null;
};
export const getDataFloat = key => {
  const strData = localStorage.getItem(`${STORAGE_KEY_PREFIX}${key}`);
  const floatData = strData != null ? parseFloat(strData) : null;
  return floatData != null && !isNaN(floatData) ? floatData : null;
};
export const getDataJson = key => {
  const strData = localStorage.getItem(`${STORAGE_KEY_PREFIX}${key}`);
  return isJsonString(strData) ? JSON.parse(strData) : null;
};

export const cacheData = (key, data) => localforage.setItem(`${STORAGE_KEY_PREFIX}${key}`, data);
export const getCachedData = key => localforage.getItem(`${STORAGE_KEY_PREFIX}${key}`);