import { useEffect } from 'react';
import { windowWidthAtom, windowHeightAtom } from '../state';
import { useSetRecoilState } from 'recoil';

const DimensionsProvider = () => {
  const setWindowWidth = useSetRecoilState(windowWidthAtom);
  const setWindowHeight = useSetRecoilState(windowHeightAtom);
  
  useEffect(() => {
    const updateDimensions = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, [setWindowWidth, setWindowHeight]);
  
  return null;
};

export default DimensionsProvider;