import { useEffect, useState } from 'react';
import COLORS from '../styles/colors';
import { Form, Table } from 'react-bootstrap';
import * as CONFIG from '../config';
import {
  quoteDataAtom,
  appRolesAtom,
  userIdAtom,
  quoteProcessorAtom,
  saveQuoteTriggerAtom,
  quoteMissingAttributesAtom,
  feasibilitySelectAtom,
  initValuesAtom,
} from '../state';
import { useRecoilValue, useRecoilState } from 'recoil';
import GLOBAL_STYLES from '../styles';

const QuoteTabFeasibility = ({ hide }) => {
  const quoteData = useRecoilValue(quoteDataAtom);
  const appRoles = useRecoilValue(appRolesAtom);
  const userId = useRecoilValue(userIdAtom);
  const quoteMissingAttributes = useRecoilValue(quoteMissingAttributesAtom);
  const [feasibilityAdequatelyDefined, setFeasibilityAdequatelyDefined] = useState(null);
  const [feasibilityAdequatelyDefinedComment, setFeasibilityAdequatelyDefinedComment] = useState('');
  const [feasibilityEngineeringRequirementsMet, setFeasibilityEngineeringRequirementsMet] = useState(null);
  const [feasibilityEngineeringRequirementsMetComment, setFeasibilityEngineeringRequirementsMetComment] = useState('');
  const [feasibilityManufacturedToTolerances, setFeasibilityManufacturedToTolerances] = useState(null);
  const [feasibilityManufacturedToTolerancesComment, setFeasibilityManufacturedToTolerancesComment] = useState('');
  const [feasibilityAdequateCapacity, setFeasibilityAdequateCapacity] = useState(null);
  const [feasibilityAdequateCapacityComment, setFeasibilityAdequateCapacityComment] = useState('');
  const [feasibilityEfficientMaterialHandling, setFeasibilityEfficientMaterialHandling] = useState(null);
  const [feasibilityEfficientMaterialHandlingComment, setFeasibilityEfficientMaterialHandlingComment] = useState('');
  const [feasibilityNoUnusualCapitalCosts, setFeasibilityNoUnusualCapitalCosts] = useState(null);
  const [feasibilityNoUnusualCapitalCostsComment, setFeasibilityNoUnusualCapitalCostsComment] = useState('');
  const [feasibilityNoUnusualToolingCosts, setFeasibilityNoUnusualToolingCosts] = useState(null);
  const [feasibilityNoUnusualToolingCostsComment, setFeasibilityNoUnusualToolingCostsComment] = useState('');
  const [feasibilityNoAlternateMethods, setFeasibilityNoAlternateMethods] = useState(null);
  const [feasibilityNoAlternateMethodsComment, setFeasibilityNoAlternateMethodsComment] = useState('');
  const [spcRequired, setSpcRequired] = useState(null);
  const [spcUsedOnSimilar, setSpcUsedOnSimilar] = useState(null);
  const [feasibilitySelect, setFeasibilitySelect] = useRecoilState(feasibilitySelectAtom);
  const [feasibilityComments, setFeasibilityComments] = useState('');
  const quoteProcessor = useRecoilValue(quoteProcessorAtom);
  const saveQuoteTrigger = useRecoilValue(saveQuoteTriggerAtom);
  const [initValues, setInitValues] = useRecoilState(initValuesAtom);
  
  useEffect(() => {
    if(saveQuoteTrigger) {
      quoteProcessor.setFeasibilityData({
        feasibilityAdequatelyDefined,
        feasibilityEngineeringRequirementsMet,
        feasibilityManufacturedToTolerances,
        feasibilityAdequateCapacity,
        feasibilityEfficientMaterialHandling,
        feasibilityNoUnusualCapitalCosts,
        feasibilityNoUnusualToolingCosts,
        feasibilityNoAlternateMethods,
        feasibilityAdequatelyDefinedComment: feasibilityAdequatelyDefinedComment.trim().length ? feasibilityAdequatelyDefinedComment.trim() : null,
        feasibilityEngineeringRequirementsMetComment: feasibilityEngineeringRequirementsMetComment.trim().length ? feasibilityEngineeringRequirementsMetComment.trim() : null,
        feasibilityManufacturedToTolerancesComment: feasibilityManufacturedToTolerancesComment.trim().length ? feasibilityManufacturedToTolerancesComment.trim() : null,
        feasibilityAdequateCapacityComment: feasibilityAdequateCapacityComment.trim().length ? feasibilityAdequateCapacityComment.trim() : null,
        feasibilityEfficientMaterialHandlingComment: feasibilityEfficientMaterialHandlingComment.trim().length ? feasibilityEfficientMaterialHandlingComment.trim() : null,
        feasibilityNoUnusualCapitalCostsComment: feasibilityNoUnusualCapitalCostsComment.trim().length ? feasibilityNoUnusualCapitalCostsComment.trim() : null,
        feasibilityNoUnusualToolingCostsComment: feasibilityNoUnusualToolingCostsComment.trim().length ? feasibilityNoUnusualToolingCostsComment.trim() : null,
        feasibilityNoAlternateMethodsComment: feasibilityNoAlternateMethodsComment.trim().length ? feasibilityNoAlternateMethodsComment.trim() : null,
        spcRequired,
        spcUsedOnSimilar,
        feasibility: feasibilitySelect !== 'NULL' ? feasibilitySelect : null,
      });
      quoteProcessor.setFeasibilityComments(feasibilityComments.trim().length ? feasibilityComments.trim() : null);
    }
  }, [
    saveQuoteTrigger,
    quoteProcessor,
    feasibilityAdequatelyDefined,
    feasibilityEngineeringRequirementsMet,
    feasibilityManufacturedToTolerances,
    feasibilityAdequateCapacity,
    feasibilityEfficientMaterialHandling,
    feasibilityNoUnusualCapitalCosts,
    feasibilityNoUnusualToolingCosts,
    feasibilityNoAlternateMethods,
    feasibilityAdequatelyDefinedComment,
    feasibilityEngineeringRequirementsMetComment,
    feasibilityManufacturedToTolerancesComment,
    feasibilityAdequateCapacityComment,
    feasibilityEfficientMaterialHandlingComment,
    feasibilityNoUnusualCapitalCostsComment,
    feasibilityNoUnusualToolingCostsComment,
    feasibilityNoAlternateMethodsComment,
    spcRequired,
    spcUsedOnSimilar,
    feasibilitySelect,
    feasibilityComments,
  ]);
  
  useEffect(() => {
    if(initValues && quoteData) {
      setFeasibilityAdequatelyDefined(quoteData.feasibilityAdequatelyDefined != null ? quoteData.feasibilityAdequatelyDefined : null);
      setFeasibilityEngineeringRequirementsMet(quoteData.feasibilityEngineeringRequirementsMet != null ? quoteData.feasibilityEngineeringRequirementsMet : null);
      setFeasibilityManufacturedToTolerances(quoteData.feasibilityManufacturedToTolerances != null ? quoteData.feasibilityManufacturedToTolerances : null);
      setFeasibilityAdequateCapacity(quoteData.feasibilityAdequateCapacity != null ? quoteData.feasibilityAdequateCapacity : null);
      setFeasibilityEfficientMaterialHandling(quoteData.feasibilityEfficientMaterialHandling != null ? quoteData.feasibilityEfficientMaterialHandling : null);
      setFeasibilityNoUnusualCapitalCosts(quoteData.feasibilityNoUnusualCapitalCosts != null ? quoteData.feasibilityNoUnusualCapitalCosts : null);
      setFeasibilityNoUnusualToolingCosts(quoteData.feasibilityNoUnusualToolingCosts != null ? quoteData.feasibilityNoUnusualToolingCosts : null);
      setFeasibilityNoAlternateMethods(quoteData.feasibilityNoAlternateMethods != null ? quoteData.feasibilityNoAlternateMethods : null);
      setFeasibilityAdequatelyDefinedComment(quoteData.feasibilityAdequatelyDefinedComment ? quoteData.feasibilityAdequatelyDefinedComment : '');
      setFeasibilityEngineeringRequirementsMetComment(quoteData.feasibilityEngineeringRequirementsMetComment ? quoteData.feasibilityEngineeringRequirementsMetComment : '');
      setFeasibilityManufacturedToTolerancesComment(quoteData.feasibilityManufacturedToTolerancesComment ? quoteData.feasibilityManufacturedToTolerancesComment: '');
      setFeasibilityAdequateCapacityComment(quoteData.feasibilityAdequateCapacityComment ? quoteData.feasibilityAdequateCapacityComment : '');
      setFeasibilityEfficientMaterialHandlingComment(quoteData.feasibilityEfficientMaterialHandlingComment ? quoteData.feasibilityEfficientMaterialHandlingComment : '');
      setFeasibilityNoUnusualCapitalCostsComment(quoteData.feasibilityNoUnusualCapitalCostsComment ? quoteData.feasibilityNoUnusualCapitalCostsComment : '');
      setFeasibilityNoUnusualToolingCostsComment(quoteData.feasibilityNoUnusualToolingCostsComment ? quoteData.feasibilityNoUnusualToolingCostsComment : '');
      setFeasibilityNoAlternateMethodsComment(quoteData.feasibilityNoAlternateMethodsComment ? quoteData.feasibilityNoAlternateMethodsComment : '');
      setSpcRequired(quoteData.spcRequired != null ? quoteData.spcRequired : null);
      setSpcUsedOnSimilar(quoteData.spcUsedOnSimilar != null ? quoteData.spcUsedOnSimilar : null);
      setFeasibilitySelect(quoteData.feasibility ? quoteData.feasibility : 'NULL');
      setInitValues(false);
    }
  }, [initValues, quoteData, setFeasibilitySelect, setInitValues]);
  
  const feasibilityAssignment = quoteData.assignments.find(o => o.types.includes('FEASIBILITY'));
  const feasibilityAssignmentUserId = feasibilityAssignment ? feasibilityAssignment.userId : null;
  
  const disableAllInputs = quoteData.holdUserId !== userId || quoteData.isNotActive || quoteData.status !== 'FEASIBILITY' ||
    !(appRoles.includes(CONFIG.ROLE_MAPPINGS.FEASIBILITY) && userId === feasibilityAssignmentUserId);
  
  if(hide) return null;
  return (
    <>
      <h5 style={{ ...styles.lineTitle, textAlign: 'left', marginTop: 20, marginBottom: 20 }}>
        <span style={{ ...styles.lineTitleText, backgroundColor: COLORS.white }}>
          Team Feasibility Commitment
        </span>
      </h5>
      <p>
        We have considered the following questions which are not intended to be all-inclusive in performing a
        feasibility evaluation. The drawings and/or specifications provided have been used as a basis for
        analyzing the ability to meet all specified requirements. All "NO" answers are supported with
        comments identifying any concerns and/or proposed changes to enable us to meet the specified documents.
      </p>
      <Table size="sm" style={{ marginBottom: 0 }}>
        <tbody>
        <tr style={{ fontWeight: 'bold' }}>
          <td>Feasible?</td>
          <td/>
        </tr>
        <tr>
          <td>
            <Form.Select
              as="select"
              value={feasibilityAdequatelyDefined == null ? 'NULL' : feasibilityAdequatelyDefined ? 'YES' : 'NO'}
              onChange={e => setFeasibilityAdequatelyDefined(e.target.value === 'NULL' ? null : e.target.value === 'YES')}
              disabled={disableAllInputs}
            >
              <option value="NULL">-</option>
              <option value="YES">Yes</option>
              <option value="NO">No</option>
            </Form.Select>
          </td>
          <td>
            <p>
              This product can be adequately defined, in terms of application requirements, to enable
              feasibility evaluation.
            </p>
            <p>If no, please state why:</p>
            <Form.Control
              type="text"
              style={quoteMissingAttributes.includes('FEASIBILITY|feasibilityAdequatelyDefinedComment') ? GLOBAL_STYLES.errorInput : null}
              value={feasibilityAdequatelyDefinedComment}
              onChange={e => setFeasibilityAdequatelyDefinedComment(e.target.value)}
              disabled={disableAllInputs || feasibilityAdequatelyDefined == null || feasibilityAdequatelyDefined}
            />
          </td>
        </tr>
        <tr>
          <td>
            <Form.Select
              as="select"
              value={feasibilityEngineeringRequirementsMet == null ? 'NULL' : feasibilityEngineeringRequirementsMet ? 'YES' : 'NO'}
              onChange={e => setFeasibilityEngineeringRequirementsMet(e.target.value === 'NULL' ? null : e.target.value === 'YES')}
              disabled={disableAllInputs}
            >
              <option value="NULL">-</option>
              <option value="YES">Yes</option>
              <option value="NO">No</option>
            </Form.Select>
          </td>
          <td>
            <p>
              Engineering performance requirements can be met as written.
            </p>
            <p>If no, please state why:</p>
            <Form.Control
              type="text"
              style={quoteMissingAttributes.includes('FEASIBILITY|feasibilityEngineeringRequirementsMetComment') ? GLOBAL_STYLES.errorInput : null}
              value={feasibilityEngineeringRequirementsMetComment}
              onChange={e => setFeasibilityEngineeringRequirementsMetComment(e.target.value)}
              disabled={disableAllInputs || feasibilityEngineeringRequirementsMet == null || feasibilityEngineeringRequirementsMet}
            />
          </td>
        </tr>
        <tr>
          <td>
            <Form.Select
              as="select"
              value={feasibilityManufacturedToTolerances == null ? 'NULL' : feasibilityManufacturedToTolerances ? 'YES' : 'NO'}
              onChange={e => setFeasibilityManufacturedToTolerances(e.target.value === 'NULL' ? null : e.target.value === 'YES')}
              disabled={disableAllInputs}
            >
              <option value="NULL">-</option>
              <option value="YES">Yes</option>
              <option value="NO">No</option>
            </Form.Select>
          </td>
          <td>
            <p>
              This product can be manufactured to tolerances specified on the drawing.
            </p>
            <p>If no, please state why:</p>
            <Form.Control
              type="text"
              style={quoteMissingAttributes.includes('FEASIBILITY|feasibilityManufacturedToTolerancesComment') ? GLOBAL_STYLES.errorInput : null}
              value={feasibilityManufacturedToTolerancesComment}
              onChange={e => setFeasibilityManufacturedToTolerancesComment(e.target.value)}
              disabled={disableAllInputs || feasibilityManufacturedToTolerances == null || feasibilityManufacturedToTolerances}
            />
          </td>
        </tr>
        <tr>
          <td>
            <Form.Select
              as="select"
              value={feasibilityAdequateCapacity == null ? 'NULL' : feasibilityAdequateCapacity ? 'YES' : 'NO'}
              onChange={e => setFeasibilityAdequateCapacity(e.target.value === 'NULL' ? null : e.target.value === 'YES')}
              disabled={disableAllInputs}
            >
              <option value="NULL">-</option>
              <option value="YES">Yes</option>
              <option value="NO">No</option>
            </Form.Select>
          </td>
          <td>
            <p>
              There is adequate capacity to produce product.
            </p>
            <p>If no, please state why:</p>
            <Form.Control
              type="text"
              style={quoteMissingAttributes.includes('FEASIBILITY|feasibilityAdequateCapacityComment') ? GLOBAL_STYLES.errorInput : null}
              value={feasibilityAdequateCapacityComment}
              onChange={e => setFeasibilityAdequateCapacityComment(e.target.value)}
              disabled={disableAllInputs || feasibilityAdequateCapacity == null || feasibilityAdequateCapacity}
            />
          </td>
        </tr>
        <tr>
          <td>
            <Form.Select
              as="select"
              value={feasibilityEfficientMaterialHandling == null ? 'NULL' : feasibilityEfficientMaterialHandling ? 'YES' : 'NO'}
              onChange={e => setFeasibilityEfficientMaterialHandling(e.target.value === 'NULL' ? null : e.target.value === 'YES')}
              disabled={disableAllInputs}
            >
              <option value="NULL">-</option>
              <option value="YES">Yes</option>
              <option value="NO">No</option>
            </Form.Select>
          </td>
          <td>
            <p>
              The design allows for the use of efficient material handling techniques.
            </p>
            <p>If no, please state why:</p>
            <Form.Control
              type="text"
              style={quoteMissingAttributes.includes('FEASIBILITY|feasibilityEfficientMaterialHandlingComment') ? GLOBAL_STYLES.errorInput : null}
              value={feasibilityEfficientMaterialHandlingComment}
              onChange={e => setFeasibilityEfficientMaterialHandlingComment(e.target.value)}
              disabled={disableAllInputs || feasibilityEfficientMaterialHandling == null || feasibilityEfficientMaterialHandling}
            />
          </td>
        </tr>
        <tr>
          <td>
            <Form.Select
              as="select"
              value={feasibilityNoUnusualCapitalCosts == null ? 'NULL' : feasibilityNoUnusualCapitalCosts ? 'YES' : 'NO'}
              onChange={e => setFeasibilityNoUnusualCapitalCosts(e.target.value === 'NULL' ? null : e.target.value === 'YES')}
              disabled={disableAllInputs}
            >
              <option value="NULL">-</option>
              <option value="YES">Yes</option>
              <option value="NO">No</option>
            </Form.Select>
          </td>
          <td>
            <p>
              The product can be manufactured without incurring any unusual capital equipment costs.
            </p>
            <p>If no, please state why:</p>
            <Form.Control
              type="text"
              style={quoteMissingAttributes.includes('FEASIBILITY|feasibilityNoUnusualCapitalCostsComment') ? GLOBAL_STYLES.errorInput : null}
              value={feasibilityNoUnusualCapitalCostsComment}
              onChange={e => setFeasibilityNoUnusualCapitalCostsComment(e.target.value)}
              disabled={disableAllInputs || feasibilityNoUnusualCapitalCosts == null || feasibilityNoUnusualCapitalCosts}
            />
          </td>
        </tr>
        <tr>
          <td>
            <Form.Select
              as="select"
              value={feasibilityNoUnusualToolingCosts == null ? 'NULL' : feasibilityNoUnusualToolingCosts ? 'YES' : 'NO'}
              onChange={e => setFeasibilityNoUnusualToolingCosts(e.target.value === 'NULL' ? null : e.target.value === 'YES')}
              disabled={disableAllInputs}
            >
              <option value="NULL">-</option>
              <option value="YES">Yes</option>
              <option value="NO">No</option>
            </Form.Select>
          </td>
          <td>
            <p>
              The product can be manufactured without incurring any unusual tooling costs.
            </p>
            <p>If no, please state why:</p>
            <Form.Control
              type="text"
              style={quoteMissingAttributes.includes('FEASIBILITY|feasibilityNoUnusualToolingCostsComment') ? GLOBAL_STYLES.errorInput : null}
              value={feasibilityNoUnusualToolingCostsComment}
              onChange={e => setFeasibilityNoUnusualToolingCostsComment(e.target.value)}
              disabled={disableAllInputs || feasibilityNoUnusualToolingCosts == null || feasibilityNoUnusualToolingCosts}
            />
          </td>
        </tr>
        <tr>
          <td>
            <Form.Select
              as="select"
              value={feasibilityNoAlternateMethods == null ? 'NULL' : feasibilityNoAlternateMethods ? 'YES' : 'NO'}
              onChange={e => setFeasibilityNoAlternateMethods(e.target.value === 'NULL' ? null : e.target.value === 'YES')}
              disabled={disableAllInputs}
            >
              <option value="NULL">-</option>
              <option value="YES">Yes</option>
              <option value="NO">No</option>
            </Form.Select>
          </td>
          <td>
            <p>
              The product can be manufactured without any alternative manufacturing methods.
            </p>
            <p>If no, please state why:</p>
            <Form.Control
              type="text"
              style={quoteMissingAttributes.includes('FEASIBILITY|feasibilityNoAlternateMethodsComment') ? GLOBAL_STYLES.errorInput : null}
              value={feasibilityNoAlternateMethodsComment}
              onChange={e => setFeasibilityNoAlternateMethodsComment(e.target.value)}
              disabled={disableAllInputs || feasibilityNoAlternateMethods == null || feasibilityNoAlternateMethods}
            />
          </td>
        </tr>
        <tr>
          <td>
            <Form.Select
              as="select"
              value={spcRequired == null ? 'NULL' : spcRequired ? 'YES' : 'NO'}
              onChange={e => setSpcRequired(e.target.value === 'NULL' ? null : e.target.value === 'YES')}
              disabled={disableAllInputs}
            >
              <option value="NULL">-</option>
              <option value="YES">Yes</option>
              <option value="NO">No</option>
            </Form.Select>
          </td>
          <td>
            <p>
              Statistical Process Control (SPC) is required on this product.
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <Form.Select
              as="select"
              value={spcUsedOnSimilar == null ? 'NULL' : spcUsedOnSimilar ? 'YES' : 'NO'}
              onChange={e => setSpcUsedOnSimilar(e.target.value === 'NULL' ? null : e.target.value === 'YES')}
              disabled={disableAllInputs}
            >
              <option value="NULL">-</option>
              <option value="YES">Yes</option>
              <option value="NO">No</option>
            </Form.Select>
          </td>
          <td>
            <p>
              Statistical Process Control (SPC) is currently being used on similar products.
            </p>
          </td>
        </tr>
        <tr>
          <td>Feasibility</td>
          <td>
            <Form.Select
              as="select"
              style={quoteMissingAttributes.includes('FEASIBILITY|feasibility') ? GLOBAL_STYLES.errorInput : null}
              value={feasibilitySelect}
              onChange={e => setFeasibilitySelect(e.target.value)}
              disabled={disableAllInputs}
            >
              <option value="NULL">-- Select --</option>
              <option value="FEASIBLE">Feasible - This product can be produced as specified with no revisions</option>
              <option value="FEASIBLE_CHANGES">Feasible - The changes recommended are listed below</option>
              <option value="NOT_FEASIBLE">Not Feasible - A design revision is required to produce the product within the specified requirements</option>
            </Form.Select>
          </td>
        </tr>
        </tbody>
      </Table>
      <p style={{ marginBottom: 5, marginTop: 10 }}>Comments</p>
      <Form.Control
        as="textarea"
        rows={3}
        value={feasibilityComments}
        onChange={e => setFeasibilityComments(e.target.value)}
        size="sm"
        style={{
          ...(quoteMissingAttributes.includes('FEASIBILITY|feasibilityComments') ? GLOBAL_STYLES.errorInput : {}),
          marginBottom: 10,
        }}
        disabled={disableAllInputs}
      />
    </>
  );
};

const styles = {
  lineTitle: {
    textAlign: 'center',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: COLORS.midGray,
    lineHeight: '0.1em',
    marginTop: 12,
    marginBottom: 12,
    color: COLORS.darkGray,
  },
  lineTitleText: {
    backgroundColor: COLORS.offWhite,
    paddingLeft: 7,
    paddingRight: 7,
  },
};

export default QuoteTabFeasibility;