export const ENVIRONMENT_TYPE = 'PRODUCTION';
export const APP_API_KEY = 'WlURcTHZYp4eUPij7blrd9zsRTpugSvG7KSzw0HW';
export const APP_DISPLAY_NAME = 'INSIGHT';
export const AUTH_DEFAULT_USER_TYPE = 'AD';
export const NO_ACTIVITY_TIMEOUT = 0;
export const DISABLE_RE_AUTHENTICATION = false;
export const STORAGE_KEY_PREFIX = 'com.nifconet.INSIGHT.';
export const NIFCO_WS_API_URL = `wss://OdJdixu5tO2Fp2HsuHrvW7ZsFxyVB3bG7aS3U1nv@fnte5m8tfe.execute-api.us-west-2.amazonaws.com/prod`
export const DASHBOARD_PADDING = 5;
export const ROLE_MAPPINGS = {
  FEASIBILITY: '9f12020e-c886-473d-b482-6e4dd729ba32',
  DESIGN_ESTIMATOR: '8552b649-5398-47c7-b8e0-8dbff177da2c',
  TOOLING_ESTIMATOR: '6a69b412-bd19-4efa-a444-f3ef90d83877',
  MANUFACTURING_ESTIMATOR: '3380a02f-0807-47c9-9628-e87a291a9422',
  PURCHASING_ESTIMATOR: '05151003-0833-4833-801c-b208797ccfd8',
  CREATE_QUOTE: 'f242e043-2381-406a-8c18-7bf39bfb724c',
  REASSIGN_QUOTE: 'edbb3528-7fe2-4b17-bd69-245737bb3150',
};