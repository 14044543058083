import nifcoLogo from '../assets/nifco_logo_no_tag.png';
import {
  productTypeLovAtom,
  quoteStatusLovAtom,
  partTypeLovAtom,
  partGlobalCategoryLovAtom,
  complexityIhsLovAtom,
  toolTypeLovAtom,
  designResponsibilityLovAtom,
  derivedWeightLovAtom,
  annealingLovAtom,
  inspectionLovAtom,
  packagingLovAtom,
  ejectorSystemLovAtom,
  currentRatesAtom,
  gateStyleLovAtom,
  gateCuttingLovAtom,
  toolingToolTypeLovAtom,
  toolDescLovAtom,
  locationLovAtom,
  pressTypeLovAtom,
  pressSizeLovAtom,
  assemblyTypesLovAtom,
  paymentTypeLovAtom,
  partTypeIhsLovAtom,
  packagingCostsAtom,
  quoteCommentsAtom,
  commentTypeLovAtom,
} from '../state';
import { useRecoilValue } from 'recoil';
import { DateTime } from 'luxon';
import { Fragment } from 'react';
import {
  getAssemblyCalculations, getAuxCalculations, getFinishedGoodCalculations,
  getMoldingCalculations, getPackagingCalculations,
  getPurchasingCalculations, getTestingCalculations,
  gramsToPounds,
} from '../helpers/calculations';
import { convertOrgCode, getRateOrgCodeFromLocator, round } from '../helpers/misc';

const PrintPage = ({ quoteData, style }) => {
  const productTypeLov = useRecoilValue(productTypeLovAtom);
  const quoteStatusLov = useRecoilValue(quoteStatusLovAtom);
  const partTypeLov = useRecoilValue(partTypeLovAtom);
  const partTypeIhsLov = useRecoilValue(partTypeIhsLovAtom);
  const partGlobalCategoryLov = useRecoilValue(partGlobalCategoryLovAtom);
  const complexityIhsLov = useRecoilValue(complexityIhsLovAtom);
  const toolTypeLov = useRecoilValue(toolTypeLovAtom);
  const designResponsibilityLov = useRecoilValue(designResponsibilityLovAtom);
  const derivedWeightLov = useRecoilValue(derivedWeightLovAtom);
  const annealingLov = useRecoilValue(annealingLovAtom);
  const inspectionLov = useRecoilValue(inspectionLovAtom);
  const packagingLov = useRecoilValue(packagingLovAtom);
  const ejectorSystemLov = useRecoilValue(ejectorSystemLovAtom);
  const currentRates = useRecoilValue(currentRatesAtom);
  const gateStyleLov = useRecoilValue(gateStyleLovAtom);
  const gateCuttingLov = useRecoilValue(gateCuttingLovAtom);
  const toolingToolTypeLov = useRecoilValue(toolingToolTypeLovAtom);
  const toolDescLov = useRecoilValue(toolDescLovAtom);
  const locationLov = useRecoilValue(locationLovAtom);
  const pressTypeLov = useRecoilValue(pressTypeLovAtom);
  const pressSizeLov = useRecoilValue(pressSizeLovAtom);
  const assemblyTypesLov = useRecoilValue(assemblyTypesLovAtom);
  const paymentTypeLov = useRecoilValue(paymentTypeLovAtom);
  const packagingCosts = useRecoilValue(packagingCostsAtom);
  const quoteComments = useRecoilValue(quoteCommentsAtom);
  const commentTypeLov = useRecoilValue(commentTypeLovAtom);
  
  const selectedProductTypeObj = productTypeLov.find(o => o.code === quoteData.productType);
  const selectedQuoteStatusObj = quoteStatusLov.find(o => o.code === quoteData.status);
  const selectedPartTypeObj = partTypeLov.find(o => o.code === quoteData.partType);
  const selectedPartTypeIhsObj = partTypeIhsLov.find(o => o.code === quoteData.productTypeIhs);
  const selectedPartGlobalCategoryObj = partGlobalCategoryLov.find(o => o.code === quoteData.partCategory);
  const selectedComplexityObj = complexityIhsLov.find(o => o.code === quoteData.complexity);
  const selectedToolTypeObj = toolTypeLov.find(o => o.code === quoteData.toolType);
  const selectedDesignResponsibilityObj = designResponsibilityLov.find(o => o.code === quoteData.designResponsibility);
  const assemblyCalculations = getAssemblyCalculations({
    assemblyCosts: quoteData?.assemblyCosts ? quoteData.assemblyCosts.map(line => ({
      location: line.location,
      description: line.assemblyType,
      pphPerPerson: line.pphPerPerson,
    })) : [],
    rates: currentRates,
    location: quoteData.fgLocation !== 'NULL' ? getRateOrgCodeFromLocator(quoteData.fgLocation) : null,
    inspectionType: quoteData.assemblyInspection,
    packagingType: quoteData.assemblyPackaging,
  });
  const selectedAssemblyInspectionObj = inspectionLov.find(o => o.code === quoteData.assemblyInspection);
  const selectedAssemblyPackagingObj = packagingLov.find(o => o.code === quoteData.assemblyPackaging);
  const moldingCalculations = quoteData?.moldedParts ? quoteData.moldedParts.map((partObj, index, array) => {
    const somePartHasOptionB = array.some(o => o.hasOptionB);
    const calculationsA = getMoldingCalculations({
      rates: currentRates,
      location: partObj.toolLocationA,
      pressType: partObj.pressTypeA,
      pressSize: partObj.pressSizeA,
      gateCuttingType: partObj.gateCuttingA,
      annealingType: partObj.annealing,
      shotsPerHour: partObj.shotsPerHourA,
      cavitiesPerMold: partObj.cavitiesPerMoldA,
      numOfDiffParts: partObj.numOfDiffPartsA,
      inspectionType: partObj.inspection,
      packingType: partObj.packaging,
      glassIsUsed: partObj.glassUsed,
      partWeight: partObj.partWeight,
      materials: partObj.materials,
      monthlyVolume: quoteData.monthlyVolume,
      regrindIsUsed: partObj.regrindUsed,
      cavitiesPerPart: partObj.cavitiesPerPartA,
      runnerPercentage: partObj.runnerPercentageA,
      regrindPercentage: partObj.regrindPercentage,
      partType: quoteData.partType,
      toolType: partObj.toolTypeA,
      toolCost: partObj.toolCostA,
      tuningTextureCost: partObj.tuningTextureCostA,
      boatShipmentCost: partObj.boatShipmentCostA,
      airShipmentCost: partObj.airShipmentCostA,
      partIsOutsourced: partObj.isOutsourced,
      outsourcedCost: partObj.outsourcedCost,
      packagingCosts: packagingCosts.filter(o => o.associatedPart === partObj.partDescription),
      productType: quoteData.productType,
      purchasedPartCosts: quoteData.newPurchasedParts,
    });
    const calculationsB = getMoldingCalculations({
      rates: currentRates,
      location: partObj.toolLocationB ? partObj.toolLocationB : somePartHasOptionB ? partObj.toolLocationA : null,
      pressType: partObj.pressTypeB ? partObj.pressTypeB : somePartHasOptionB ? partObj.pressTypeA : null,
      pressSize: partObj.pressSizeB ? partObj.pressSizeB : somePartHasOptionB ? partObj.pressSizeA : null,
      gateCuttingType: partObj.gateCuttingB ? partObj.gateCuttingB : somePartHasOptionB ? partObj.gateCuttingA : null,
      annealingType: partObj.annealing,
      shotsPerHour: partObj.shotsPerHourB ? partObj.shotsPerHourB : somePartHasOptionB ? partObj.shotsPerHourA : null,
      cavitiesPerMold: partObj.cavitiesPerMoldB ? partObj.cavitiesPerMoldB : somePartHasOptionB ? partObj.cavitiesPerMoldA : null,
      numOfDiffParts: partObj.numOfDiffPartsB ? partObj.numOfDiffPartsB : somePartHasOptionB ? partObj.numOfDiffPartsA : null,
      inspectionType: partObj.inspection,
      packingType: partObj.packaging,
      glassIsUsed: partObj.glassUsed,
      partWeight: partObj.partWeight,
      materials: partObj.materials,
      monthlyVolume: quoteData.monthlyVolume,
      regrindIsUsed: partObj.regrindUsed,
      cavitiesPerPart: partObj.cavitiesPerPartB ? partObj.cavitiesPerPartB : somePartHasOptionB ? partObj.cavitiesPerPartA : null,
      runnerPercentage: partObj.runnerPercentageB ? partObj.runnerPercentageB : somePartHasOptionB ? partObj.runnerPercentageA : null,
      regrindPercentage: partObj.regrindPercentage,
      partType: quoteData.partType,
      toolType: partObj.toolTypeB ? partObj.toolTypeB : somePartHasOptionB ? partObj.toolTypeA : null,
      toolCost: partObj.toolCostB ? partObj.toolCostB : somePartHasOptionB ? partObj.toolCostA : null,
      tuningTextureCost: partObj.tuningTextureCostB ? partObj.tuningTextureCostB : somePartHasOptionB ? partObj.tuningTextureCostA : null,
      boatShipmentCost: partObj.boatShipmentCostB ? partObj.boatShipmentCostB : somePartHasOptionB ? partObj.boatShipmentCostA : null,
      airShipmentCost: partObj.airShipmentCostB ? partObj.airShipmentCostB : somePartHasOptionB ? partObj.airShipmentCostA : null,
      partIsOutsourced: partObj.isOutsourced,
      outsourcedCost: partObj.outsourcedCost,
      packagingCosts: packagingCosts.filter(o => o.associatedPart === partObj.partDescription),
      productType: quoteData.productType,
      purchasedPartCosts: quoteData.newPurchasedParts,
    });
    return { calculationsA, calculationsB };
  }) : [];
  const totalMoldedComponentCostA = moldingCalculations.reduce((total, cur, index) =>
    cur?.calculationsA?.totalStandardCost && quoteData.moldedParts[index]?.quantityPerAssembly &&
    typeof quoteData.moldedParts[index].quantityPerAssembly === 'number' ?
      total + (cur.calculationsA.totalStandardCost * quoteData.moldedParts[index].quantityPerAssembly) : total, 0);
  const totalMoldedComponentCostB = moldingCalculations.reduce((total, cur, index) =>
    cur?.calculationsB?.totalStandardCost && quoteData.moldedParts[index]?.quantityPerAssembly &&
    typeof quoteData.moldedParts[index].quantityPerAssembly === 'number' ?
      total + (cur.calculationsB.totalStandardCost * quoteData.moldedParts[index].quantityPerAssembly) : total, 0);
  const purchasingCalculations = getPurchasingCalculations({
    purchasedPartCosts: quoteData?.newPurchasedParts ? quoteData.newPurchasedParts.map(o => ({
      quantity: o.quantity,
      partCost: o.partCost,
    })) : [],
    carryoverPartCosts: quoteData?.carryoverPurchasedParts ? quoteData.carryoverPurchasedParts.map(o => ({
      quantity: o.quantity,
      partCost: o.partCost,
    })) : [],
  });
  const packagingCalculations = getPackagingCalculations({
    packagingCosts: quoteData?.packagingCosts ? quoteData.packagingCosts.map(o => ({
      quantity: `${o.quantity}`,
      unitCost: o.unitCost,
    })) : [],
  });
  const auxCalculations = getAuxCalculations({
    auxCosts: quoteData?.auxCosts ? quoteData.auxCosts.map(o => ({
      quantity: `${o.quantity}`,
      unitCost: `${o.unitCost}`,
      paymentType: o.paymentType,
    })) : [],
  });
  const testingCalculations = getTestingCalculations({
    testingCosts: quoteData?.testingCosts ? quoteData.testingCosts.map(o => ({
      quantity: `${o.quantity}`,
      unitCost: `${o.unitCost}`,
      paymentType: o.paymentType,
    })) : [],
  });
  const finishedGoodCalculations = getFinishedGoodCalculations({
    rates: currentRates,
    moldedParts: quoteData?.moldedParts ? quoteData.moldedParts : [],
    monthlyVolume: quoteData.monthlyVolume,
    partType: quoteData.partType,
    purchasedPartCosts: quoteData?.newPurchasedParts ? quoteData.newPurchasedParts : [],
    carryoverPartCosts: quoteData?.carryoverPurchasedParts ? quoteData.carryoverPurchasedParts.map(o => ({
      quantity: o.quantity,
      partCost: o.partCost,
    })) : [],
    assemblyCosts: quoteData?.assemblyCosts ? quoteData.assemblyCosts.map(line => ({
      location: line.location,
      description: line.assemblyType,
      pphPerPerson: line.pphPerPerson,
    })) : [],
    fgLocation: quoteData.fgLocation !== 'NULL' ? getRateOrgCodeFromLocator(quoteData.fgLocation) : null,
    inspectionType: quoteData.assemblyInspection,
    packagingType: quoteData.assemblyPackaging,
    packagingCosts: quoteData?.packagingCosts ? quoteData.packagingCosts.map(o => ({
      associatedPart: o.associatedPart,
      quantity: `${o.quantity}`,
      unitCost: o.unitCost,
    })) : [],
    auxCosts: quoteData?.auxCosts ? quoteData.auxCosts.map(o => ({
      quantity: `${o.quantity}`,
      unitCost: `${o.unitCost}`,
      paymentType: o.paymentType,
      depreciationMonths: o.depreciationMonths,
    })) : [],
    rMexFreightCost: quoteData.rMexFreightCost ? quoteData.rMexFreightCost : 0,
    testingCosts: quoteData?.testingCosts ? quoteData.testingCosts.map(o => ({
      quantity: `${o.quantity}`,
      unitCost: `${o.unitCost}`,
      paymentType: o.paymentType,
    })) : [],
    bom: quoteData.bom,
    productType: quoteData.productType,
  });
  
  const PageHeader = props => (
    <div {...props}>
      <div style={styles.headerContainer}>
        <img src={nifcoLogo} height="42" alt=""/>
        <table id="print-table" style={styles.headerTable}>
          <tbody>
          <tr>
            <td style={styles.centerCell}>{quoteData.quoteNumber}</td>
            <td style={styles.centerCell}>{quoteData.createdByUsername}</td>
            <td style={styles.centerCell}>{quoteData.customerName}</td>
          </tr>
          <tr>
            <td style={styles.centerCell}>{quoteData.revision}</td>
            <td style={styles.centerCell}>{selectedQuoteStatusObj ? selectedQuoteStatusObj.name : ''}</td>
            <td style={styles.centerCell}>{`${quoteData.customerPartNumber} - ${quoteData.partDescription}`}</td>
          </tr>
          </tbody>
        </table>
        <p style={styles.headerText}>{new Date().toLocaleString()}</p>
      </div>
      <div style={styles.hr}/>
    </div>
  );
  
  return (
    <div style={{ ...style, ...styles.mainPrintContainer }}>
      <PageHeader/>
      <h5 style={styles.heading}>Summary</h5>
      <table id="print-table" style={styles.table}>
        <tbody>
        <tr>
          <th>Manufacture Type</th>
          <td>{selectedProductTypeObj ? selectedProductTypeObj.name : ''}</td>
          <th>Purchased Components</th>
          <td>{selectedProductTypeObj?.purchased ? 'Yes' : 'No'}</td>
        </tr>
        <tr>
          <th>Requires Assembly</th>
          <td>{selectedProductTypeObj?.assembled ? 'Yes' : 'No'}</td>
          <th>Customer</th>
          <td>{quoteData.customerName}</td>
        </tr>
        <tr>
          <th>Customer Part #</th>
          <td>{quoteData.customerPartNumber}</td>
          <th>Part Description</th>
          <td>{quoteData.partDescription}</td>
        </tr>
        <tr>
          <th>Monthly Volume</th>
          <td>{quoteData?.monthlyVolume?.toLocaleString()}</td>
          <th>Target Price</th>
          <td>{quoteData.targetPrice ? `$${quoteData.targetPrice.toLocaleString()}` : 'N/A'}</td>
        </tr>
        <tr>
          <th>FG Location</th>
          <td>{quoteData.fgLocation}</td>
          <th>Submitted Date</th>
          <td>{quoteData.dateTimeSubmitted ? DateTime.fromMillis(quoteData.dateTimeSubmitted).toFormat('MM/dd/yyyy') : ''}</td>
        </tr>
        <tr>
          <th>Due Date</th>
          <td>{quoteData.dueDate ? DateTime.fromMillis(quoteData.dueDate).toFormat('MM/dd/yyyy') : ''}</td>
          <th>Start of Production Date</th>
          <td>{quoteData.prodStartDate ? DateTime.fromMillis(quoteData.prodStartDate).toFormat('MM/dd/yyyy') : ''}</td>
        </tr>
        </tbody>
      </table>
      <div style={styles.hr}/>
      <h5 style={styles.heading}>General Information</h5>
      <table id="print-table" style={styles.table}>
        <tbody>
        <tr>
          <th>Car Program</th>
          <td>{quoteData?.programs?.length ? quoteData.programs[0].programCode : ''}</td>
          <th>Product Type</th>
          <td>{selectedPartTypeIhsObj ? selectedPartTypeIhsObj.name : ''}</td>
        </tr>
        <tr>
          <th>Part Type</th>
          <td>{selectedPartTypeObj ? selectedPartTypeObj.name : ''}</td>
          <th>Category</th>
          <td>{selectedPartGlobalCategoryObj ? selectedPartGlobalCategoryObj.name : ''}</td>
        </tr>
        <tr>
          <th>Complexity</th>
          <td>{selectedComplexityObj ? selectedComplexityObj.name : ''}</td>
          <th>Est. PPAP Timing (Weeks)</th>
          <td>{quoteData.ppapTiming}</td>
        </tr>
        <tr>
          <th>Tool Type</th>
          <td>{selectedToolTypeObj ? selectedToolTypeObj.name : ''}</td>
          <th>Drawing Rev.</th>
          <td>{quoteData.drawingRevLevel}</td>
        </tr>
        <tr>
          <th>Material Type</th>
          <td>{quoteData.materialType}</td>
          <th>General Color</th>
          <td>{quoteData.generalColor}</td>
        </tr>
        <tr>
          <th>Program Life (Months)</th>
          <td>{quoteData.programLife}</td>
          <th>Design Responsibility</th>
          <td>{selectedDesignResponsibilityObj ? selectedDesignResponsibilityObj.name : ''}</td>
        </tr>
        </tbody>
      </table>
      <div style={styles.hr}/>
      <h5 style={styles.heading}>Work List</h5>
      <table id="print-table" style={styles.table}>
        <thead>
        <tr>
          <th>Step</th>
          <th>User</th>
          <th>Time Submitted</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>Pre-feasibility</td>
          <td>{quoteData.preFeasibilityApprovedUsername}</td>
          <td>{quoteData.dateTimePreFeasibilityApproved ? DateTime.fromMillis(quoteData.dateTimePreFeasibilityApproved).toFormat('MM/dd/yyyy HH:mm') : ''}</td>
        </tr>
        <tr>
          <td>Design Estimation</td>
          <td>{quoteData.designEstimationSubmittedUsername}</td>
          <td>{quoteData.dateTimeDesignEstimationSubmitted ? DateTime.fromMillis(quoteData.dateTimeDesignEstimationSubmitted).toFormat('MM/dd/yyyy HH:mm') : ''}</td>
        </tr>
        <tr>
          <td>Tooling Estimation</td>
          <td>{quoteData.toolingEstimationSubmittedUsername}</td>
          <td>{quoteData.dateTimeToolingEstimationSubmitted ? DateTime.fromMillis(quoteData.dateTimeToolingEstimationSubmitted).toFormat('MM/dd/yyyy HH:mm') : ''}</td>
        </tr>
        {
          quoteData.manufacturingEstimationSubmittedUsername ?
            <tr>
              <td>Manufacturing Estimation</td>
              <td>{quoteData.manufacturingEstimationSubmittedUsername}</td>
              <td>{quoteData.dateTimeManufacturingEstimationSubmitted ? DateTime.fromMillis(quoteData.dateTimeManufacturingEstimationSubmitted).toFormat('MM/dd/yyyy HH:mm') : ''}</td>
            </tr> :
            null
        }
        {
          quoteData.purchasingEstimationSubmittedUsername ?
            <tr>
              <td>Purchasing Estimation</td>
              <td>{quoteData.purchasingEstimationSubmittedUsername}</td>
              <td>{quoteData.dateTimePurchasingEstimationSubmitted ? DateTime.fromMillis(quoteData.dateTimePurchasingEstimationSubmitted).toFormat('MM/dd/yyyy HH:mm') : ''}</td>
            </tr> :
            null
        }
        <tr>
          <td>Feasibility</td>
          <td>{quoteData.feasibilityApprovedUsername}</td>
          <td>{quoteData.dateTimeFeasibilityApproved ? DateTime.fromMillis(quoteData.dateTimeFeasibilityApproved).toFormat('MM/dd/yyyy HH:mm') : ''}</td>
        </tr>
        <tr>
          <td>Sales Approval</td>
          <td>{quoteData.salesApprovalUsername}</td>
          <td>{quoteData.dateTimeSalesApprovalComplete ? DateTime.fromMillis(quoteData.dateTimeSalesApprovalComplete).toFormat('MM/dd/yyyy HH:mm') : ''}</td>
        </tr>
        </tbody>
      </table>
      <div style={styles.hr}/>
      <h5 style={styles.heading}>Special Instructions</h5>
      <p style={styles.pageText}>{quoteData.specialInstructions ? quoteData.specialInstructions : ''}</p>
      <div style={styles.hr}/>
      <h5 style={styles.heading}>Comments</h5>
      <table id="print-table" style={styles.table}>
        <thead>
        <tr>
          <th>Rev</th>
          <th>Type</th>
          <th>User</th>
          <th>Time</th>
          <th>Comment</th>
        </tr>
        </thead>
        <tbody>
        {quoteComments?.map((comment, index) => {
          const commentType = commentTypeLov.find(o => o.code === comment.commentType);
          return (
            <tr
              key={index}
              style={{ textDecoration: comment.deleted ? 'line-through' : null }}
            >
              <td>{comment.revision}</td>
              <td>{commentType ? commentType.name : comment.commentType}</td>
              <td>{comment.username}</td>
              <td>{DateTime.fromMillis(comment.commentTime).toFormat('ccc LLL d yyyy, h:mm a')}</td>
              <td>{comment.comment}</td>
            </tr>
          );
        })}
        </tbody>
      </table>
      <div style={styles.hr}/>
      {quoteData?.moldedParts?.map((partObj, index) => {
        const selectedDerivedWeightObj = derivedWeightLov.find(o => o.code === partObj.partWeightDerived);
        const selectedAnnealingObj = annealingLov.find(o => o.code === partObj.annealing);
        const selectedInspectionObj = inspectionLov.find(o => o.code === partObj.inspection);
        const selectedPackagingObj = packagingLov.find(o => o.code === partObj.packaging);
        const selectedEjectorSystemObj = ejectorSystemLov.find(o => o.code === partObj.ejectorSystem);
        const selectedGateStyleObjA = gateStyleLov.find(o => o.code === partObj.gateStyleA);
        const selectedGateStyleObjB = gateStyleLov.find(o => o.code === partObj.gateStyleB);
        const selectedGateCuttingObjA = gateCuttingLov.find(o => o.code === partObj.gateCuttingA);
        const selectedGateCuttingObjB = gateCuttingLov.find(o => o.code === partObj.gateCuttingB);
        const selectedToolTypeObjA = toolingToolTypeLov.find(o => o.code === partObj.toolTypeA);
        const selectedToolTypeObjB = toolingToolTypeLov.find(o => o.code === partObj.toolTypeB);
        const selectedToolDescObjA = toolDescLov.find(o => o.code === partObj.toolDescA);
        const selectedToolDescObjB = toolDescLov.find(o => o.code === partObj.toolDescB);
        const selectedToolLocationObjA = locationLov.find(o => o.code === partObj.toolLocationA);
        const selectedToolLocationObjB = locationLov.find(o => o.code === partObj.toolLocationB);
        const selectedPressTypeObjA = pressTypeLov.find(o => o.code === partObj.pressTypeA);
        const selectedPressTypeObjB = pressTypeLov.find(o => o.code === partObj.pressTypeB);
        const selectedPressSizeObjA = pressSizeLov.find(o => o.code === partObj.pressSizeA);
        const selectedPressSizeObjB = pressSizeLov.find(o => o.code === partObj.pressSizeB);
        const { calculationsA, calculationsB } = moldingCalculations[index];
        
        return (
          <Fragment key={index}>
            <PageHeader className="page-break page-header"/>
            <h5 style={styles.heading}>{`Molded Part - ${partObj.partDescription} (INPUTS)`}</h5>
            <div style={styles.hr}/>
            <h5 style={styles.heading}>Part Comments</h5>
            <table id="print-table" style={styles.table}>
              <thead>
              <tr>
                <th>User</th>
                <th>Time</th>
                <th>Comment</th>
              </tr>
              </thead>
              <tbody>
              {partObj?.partComments?.length ?
                partObj.partComments.map((comment, index) => (
                  <tr key={index}>
                    <td>{comment.username}</td>
                    <td>{DateTime.fromMillis(comment.time).toFormat('ccc LLL d yyyy, h:mm a')}</td>
                    <td>{comment.comment}</td>
                  </tr>
                )) :
                <tr>
                  <td colSpan="3">No part comments...</td>
                </tr>
              }
              </tbody>
            </table>
            <div style={styles.hr}/>
            <h5 style={styles.heading}>Material Info</h5>
            <table id="print-table" style={styles.table}>
              <tbody>
              <tr>
                <th>Regrind</th>
                <td>{partObj.regrindUsed ? 'Yes' : 'No'}</td>
                <th>Regrind %</th>
                <td>{partObj.regrindPercentage}</td>
              </tr>
              <tr>
                <th>Glass</th>
                <td>{partObj.glassUsed ? 'Yes' : 'No'}</td>
                <th>Total Raw Material / lb</th>
                <td>{calculationsA.totalRawMaterialPerPound}</td>
              </tr>
              <tr>
                <th>Part Weight Derived</th>
                <td>{selectedDerivedWeightObj ? selectedDerivedWeightObj.name : ''}</td>
                <th>Estimated Volume of Parts</th>
                <td>{quoteData.programLife && quoteData.monthlyVolume ? (quoteData.programLife * quoteData.monthlyVolume).toLocaleString() : ''}</td>
              </tr>
              <tr>
                <th>Part Weight (g)</th>
                <td>{partObj.partWeight ? round(partObj.partWeight, 2) : ''}</td>
                <th>Part Weight (lb)</th>
                <td>{partObj.partWeight ? round(gramsToPounds(partObj.partWeight), 5) : ''}</td>
              </tr>
              </tbody>
            </table>
            <table id="print-table" style={{ ...styles.table, marginTop: 10 }}>
              <thead>
              <tr>
                <th>P/N</th>
                <th>Type</th>
                <th>Blend Percentage</th>
                <th>Description</th>
                <th>New</th>
              </tr>
              </thead>
              <tbody>
              {partObj.materials ? partObj.materials.map((mat, index) => (
                <tr key={index}>
                  <td>{mat.materialNumber}</td>
                  <td>{mat.materialType}</td>
                  <td>{mat.blendPercentage}</td>
                  <td>{mat.description}</td>
                  <td>{mat.isNew ? 'YES' : 'NO'}</td>
                </tr>
              )) : null}
              </tbody>
            </table>
            <div style={styles.hr}/>
            <h5 style={styles.heading}>Post Processing</h5>
            <table id="print-table" style={styles.table}>
              <tbody>
              <tr>
                <th>Annealing</th>
                <td>{selectedAnnealingObj ? selectedAnnealingObj.name : ''}</td>
              </tr>
              <tr>
                <th>Inspection</th>
                <td>{partObj.inspection === 'HIGH_CAVITATION' ? '5 - High Cavitation' : selectedInspectionObj ? selectedInspectionObj.name : ''}</td>
              </tr>
              <tr>
                <th>Measuring & Packaging</th>
                <td>{partObj.packaging === 'HIGH_CAVITATION' ? '5 - High Cavitation' : selectedPackagingObj ? selectedPackagingObj.name : ''}</td>
              </tr>
              </tbody>
            </table>
            <div style={styles.hr}/>
            <h5 style={styles.heading}>Tooling</h5>
            <table id="print-table" style={styles.table}>
              <tbody>
              <tr>
                <th>Insert Molded</th>
                <td>{partObj.insertMolded ? 'Yes' : 'No'}</td>
              </tr>
              <tr>
                <th>No. of Side Actions</th>
                <td>{partObj.sideActions}</td>
              </tr>
              <tr>
                <th>Ejector System</th>
                <td>{selectedEjectorSystemObj ? selectedEjectorSystemObj.name : ''}</td>
              </tr>
              </tbody>
            </table>
            <div style={styles.hr}/>
            <h5 style={styles.heading}>Molding</h5>
            <table id="print-table" style={styles.table}>
              <tbody>
              <tr>
                <th/>
                <th>Option A</th>
                <th>Option B</th>
              </tr>
              <tr>
                <th>Cavities Per Mold</th>
                <td>{partObj.cavitiesPerMoldA}</td>
                <td>{partObj.hasOptionB ? partObj.cavitiesPerMoldB : null}</td>
              </tr>
              <tr>
                <th>Cavities Per Part</th>
                <td>{partObj.cavitiesPerPartA}</td>
                <td>{partObj.hasOptionB ? partObj.cavitiesPerPartB : null}</td>
              </tr>
              <tr>
                <th>No. of Different Parts</th>
                <td>{partObj.numOfDiffPartsA}</td>
                <td>{partObj.hasOptionB ? partObj.numOfDiffPartsB : null}</td>
              </tr>
              <tr>
                <th>Total Part Weight (g)</th>
                <td>{calculationsA?.totalPartWeight ? round(calculationsA.totalPartWeight, 2) : ''}</td>
                <td>{partObj.hasOptionB && calculationsB?.totalPartWeight ? round(calculationsB.totalPartWeight, 2) : ''}</td>
              </tr>
              <tr>
                <th>Runner Percentage</th>
                <td>{partObj.runnerPercentageA}</td>
                <td>{partObj.hasOptionB ? partObj.runnerPercentageB : null}</td>
              </tr>
              <tr>
                <th>Runner (g)</th>
                <td>{calculationsA?.runnerWeight ? round(calculationsA.runnerWeight, 2) : ''}</td>
                <td>{partObj.hasOptionB && calculationsB?.runnerWeight ? round(calculationsB.runnerWeight, 2) : ''}</td>
              </tr>
              <tr>
                <th>Gate Style</th>
                <td>{selectedGateStyleObjA ? selectedGateStyleObjA.name : ''}</td>
                <td>{partObj.hasOptionB && selectedGateStyleObjB ? selectedGateStyleObjB.name : ''}</td>
              </tr>
              <tr>
                <th>Gate Cutting</th>
                <td>{selectedGateCuttingObjA ? selectedGateCuttingObjA.name : ''}</td>
                <td>{partObj.hasOptionB && selectedGateCuttingObjB ? selectedGateCuttingObjB.name : ''}</td>
              </tr>
              <tr>
                <th>Tool Type</th>
                <td>{selectedToolTypeObjA ? selectedToolTypeObjA.name : ''}</td>
                <td>{partObj.hasOptionB && selectedToolTypeObjB ? selectedToolTypeObjB.name : ''}</td>
              </tr>
              <tr>
                <th>Tool Cost</th>
                <td>{partObj.toolCostA}</td>
                <td>{partObj.hasOptionB ? partObj.toolCostB : null}</td>
              </tr>
              <tr>
                <th>Tuning & Texture</th>
                <td>{partObj.tuningTextureCostA}</td>
                <td>{partObj.hasOptionB ? partObj.tuningTextureCostB : null}</td>
              </tr>
              <tr>
                <th>Boat Shipment</th>
                <td>{partObj.boatShipmentCostA}</td>
                <td>{partObj.hasOptionB ? partObj.boatShipmentCostB : null}</td>
              </tr>
              <tr>
                <th>Total Tool Cost (Boat)</th>
                <td>{calculationsA?.totalToolCostBoat ? calculationsA.totalToolCostBoat : ''}</td>
                <td>{partObj.hasOptionB && calculationsB?.totalToolCostBoat ? calculationsB.totalToolCostBoat : ''}</td>
              </tr>
              <tr>
                <th>Air Shipment (Expedite)</th>
                <td>{partObj.airShipmentCostA}</td>
                <td>{partObj.hasOptionB ? partObj.airShipmentCostB : null}</td>
              </tr>
              <tr>
                <th>Total Tool Cost (Air)</th>
                <td>{calculationsA?.totalToolCostAir ? calculationsA.totalToolCostAir : ''}</td>
                <td>{partObj.hasOptionB && calculationsB?.totalToolCostAir ? calculationsB.totalToolCostAir : ''}</td>
              </tr>
              <tr>
                <th>Lead Time</th>
                <td>{partObj.leadTimeWeeksA}</td>
                <td>{partObj.hasOptionB ? partObj.leadTimeWeeksB : null}</td>
              </tr>
              <tr>
                <th>Tool Description</th>
                <td>{selectedToolDescObjA ? selectedToolDescObjA.name : ''}</td>
                <td>{partObj.hasOptionB && selectedToolDescObjB ? selectedToolDescObjB.name : ''}</td>
              </tr>
              <tr>
                <th>Press Location</th>
                <td>{selectedToolLocationObjA ? selectedToolLocationObjA.name : ''}</td>
                <td>{partObj.hasOptionB && selectedToolLocationObjB ? selectedToolLocationObjB.name : ''}</td>
              </tr>
              <tr>
                <th>Press Type</th>
                <td>{selectedPressTypeObjA ? selectedPressTypeObjA.name : ''}</td>
                <td>{partObj.hasOptionB && selectedPressTypeObjB ? selectedPressTypeObjB.name : ''}</td>
              </tr>
              <tr>
                <th>Shots per Hour</th>
                <td>{partObj.shotsPerHourA}</td>
                <td>{partObj.hasOptionB ? partObj.shotsPerHourB : null}</td>
              </tr>
              <tr>
                <th>Cycle Time</th>
                <td>{partObj.shotsPerHourA ? round(3600 / partObj.shotsPerHourA, 2) : ''}</td>
                <td>{partObj.hasOptionB && partObj.shotsPerHourB ? round(3600 / partObj.shotsPerHourB, 2) : ''}</td>
              </tr>
              <tr>
                <th>Press Size</th>
                <td>{selectedPressSizeObjA ? selectedPressSizeObjA.name : ''}</td>
                <td>{partObj.hasOptionB && selectedPressSizeObjB ? selectedPressSizeObjB.name : ''}</td>
              </tr>
              <tr>
                <th>Press Rate</th>
                <td>{calculationsA?.rates?.press ? calculationsA.rates.press : ''}</td>
                <td>{partObj.hasOptionB && calculationsB?.rates?.press ? calculationsB.rates.press : ''}</td>
              </tr>
              </tbody>
            </table>
            <div style={styles.hr}/>
            <PageHeader className="page-break page-header"/>
            <h5 style={styles.heading}>{`Molded Part - ${partObj.partDescription} (OUTPUTS)`}</h5>
            <div style={styles.hr}/>
            <table id="print-table" style={styles.table}>
              <thead>
              <tr>
                <th/>
                <th style={{ width: '30%' }}>Option A</th>
                <th style={{ width: '30%' }}>Option B</th>
              </tr>
              </thead>
            </table>
            <div style={styles.hr}/>
            <h5 style={styles.heading}>Material Cost Breakdown</h5>
            <table id="print-table" style={styles.table}>
              <tbody>
              <tr>
                <th>Press Location</th>
                <td style={{ width: '30%' }}>{selectedToolLocationObjA ? selectedToolLocationObjA.name : ''}</td>
                <td style={{ width: '30%' }}>{selectedToolLocationObjB ? selectedToolLocationObjB.name : ''}</td>
              </tr>
              <tr>
                <th>Raw Material</th>
                <td>{calculationsA?.rawMaterialCost ? round(calculationsA.rawMaterialCost, 5) : ''}</td>
                <td>{partObj.hasOptionB && calculationsB?.rawMaterialCost ? round(calculationsB.rawMaterialCost, 5) : ''}</td>
              </tr>
              <tr>
                <th>Recycled Material</th>
                <td>{calculationsA?.recycledMaterialCost ? round(calculationsA.recycledMaterialCost, 5) : ''}</td>
                <td>{partObj.hasOptionB && calculationsB?.recycledMaterialCost ? round(calculationsB.recycledMaterialCost, 5) : ''}</td>
              </tr>
              <tr>
                <th>Gross Raw Material</th>
                <td>{calculationsA?.grossRawMaterialCost ? round(calculationsA.grossRawMaterialCost, 5) : ''}</td>
                <td>{partObj.hasOptionB && calculationsB?.grossRawMaterialCost ? round(calculationsB.grossRawMaterialCost, 5) : ''}</td>
              </tr>
              <tr>
                <th>Total Material Costs</th>
                <td>{calculationsA?.totalMaterialCost ? round(calculationsA.totalMaterialCost, 5) : ''}</td>
                <td>{partObj.hasOptionB && calculationsB?.totalMaterialCost ? round(calculationsB.totalMaterialCost, 5) : ''}</td>
              </tr>
              </tbody>
            </table>
            <div style={styles.hr}/>
            <h5 style={styles.heading}>Molded Cost Breakdown</h5>
            <table id="print-table" style={styles.table}>
              <tbody>
              <tr>
                <th>No. of Cavities</th>
                <td style={{ width: '30%' }}>{partObj.cavitiesPerMoldA}</td>
                <td style={{ width: '30%' }}>{partObj.hasOptionB && partObj.cavitiesPerMoldB}</td>
              </tr>
              <tr>
                <th>Molding Costs</th>
                <td>{calculationsA?.moldingCost ? round(calculationsA.moldingCost, 5) : ''}</td>
                <td>{partObj.hasOptionB && calculationsB?.moldingCost ? round(calculationsB.moldingCost, 5) : ''}</td>
              </tr>
              <tr>
                <th>Glass</th>
                <td>{calculationsA?.glassCost ? round(calculationsA.glassCost, 5) : ''}</td>
                <td>{partObj.hasOptionB && calculationsB?.glassCost ? round(calculationsB.glassCost, 5) : ''}</td>
              </tr>
              <tr>
                <th>Total Injection Molding Costs</th>
                <td>{calculationsA?.totalInjectionMoldingCost ? round(calculationsA.totalInjectionMoldingCost, 5) : ''}</td>
                <td>{partObj.hasOptionB && calculationsB?.totalInjectionMoldingCost ? round(calculationsB.totalInjectionMoldingCost, 5) : ''}</td>
              </tr>
              <tr>
                <th>Monthly Run (hours)</th>
                <td>{calculationsA?.monthlyRunHours ? round(calculationsA.monthlyRunHours, 1) : ''}</td>
                <td>{partObj.hasOptionB && calculationsB?.monthlyRunHours ? round(calculationsB.monthlyRunHours, 1) : ''}</td>
              </tr>
              <tr>
                <th>Monthly Material Usage</th>
                <td>{calculationsA?.monthlyMaterialUsageLbs ? round(calculationsA.monthlyMaterialUsageLbs, 2) : ''}</td>
                <td>{partObj.hasOptionB && calculationsB?.monthlyMaterialUsageLbs ? round(calculationsB.monthlyMaterialUsageLbs, 2) : ''}</td>
              </tr>
              </tbody>
            </table>
            <div style={styles.hr}/>
            <h5 style={styles.heading}>Post Processing Cost Breakdown</h5>
            <table id="print-table" style={styles.table}>
              <tbody>
              <tr>
                <th>Gate Cutting</th>
                <td
                  style={{ width: '30%' }}>{calculationsA?.rates?.gateCutting ? round(calculationsA.rates.gateCutting, 5) : ''}</td>
                <td
                  style={{ width: '30%' }}>{partObj.hasOptionB && calculationsB?.rates?.gateCutting ? round(calculationsB.rates.gateCutting, 5) : ''}</td>
              </tr>
              <tr>
                <th>Annealing</th>
                <td>{calculationsA?.rates?.annealing ? round(calculationsA.rates.annealing, 5) : ''}</td>
                <td>{partObj.hasOptionB && calculationsB?.rates?.annealing ? round(calculationsB.rates.annealing, 5) : ''}</td>
              </tr>
              <tr>
                <th>Inspection</th>
                <td>{calculationsA?.rates?.inspection ? round(calculationsA.rates.inspection, 5) : ''}</td>
                <td>{partObj.hasOptionB && calculationsB?.rates?.inspection ? round(calculationsB.rates.inspection, 5) : ''}</td>
              </tr>
              <tr>
                <th>Measuring & Packaging</th>
                <td>{calculationsA?.rates?.packing ? round(calculationsA.rates.packing, 5) : ''}</td>
                <td>{partObj.hasOptionB && calculationsB?.rates?.packing ? round(calculationsB.rates.packing, 5) : ''}</td>
              </tr>
              <tr>
                <th>Total Post Processing Costs</th>
                <td>{calculationsA?.totalPostProcessingCost ? round(calculationsA.totalPostProcessingCost, 5) : ''}</td>
                <td>{partObj.hasOptionB && calculationsB?.totalPostProcessingCost ? round(calculationsB.totalPostProcessingCost, 5) : ''}</td>
              </tr>
              </tbody>
            </table>
            <div style={styles.hr}/>
            <h5 style={styles.heading}>Component Standard Cost</h5>
            <table id="print-table" style={styles.table}>
              <tbody>
              <tr>
                <th>Total Production Cost</th>
                <td
                  style={{ width: '30%' }}>{calculationsA?.totalProductionCost ? round(calculationsA.totalProductionCost, 5) : ''}</td>
                <td
                  style={{ width: '30%' }}>{partObj.hasOptionB && calculationsB?.totalProductionCost ? round(calculationsB.totalProductionCost, 5) : ''}</td>
              </tr>
              <tr>
                <th>Aux Dept. Coefficient</th>
                <td>{calculationsA?.auxDeptCoefficient ? round(calculationsA.auxDeptCoefficient, 5) : ''}</td>
                <td>{partObj.hasOptionB && calculationsB?.auxDeptCoefficient ? round(calculationsB.auxDeptCoefficient, 5) : ''}</td>
              </tr>
              <tr>
                <th>Technical Cost</th>
                <td>{calculationsA?.technicalCost ? round(calculationsA.technicalCost, 5) : ''}</td>
                <td>{partObj.hasOptionB && calculationsB?.technicalCost ? round(calculationsB.technicalCost, 5) : ''}</td>
              </tr>
              <tr>
                <th>Yield Cost</th>
                <td>{calculationsA?.yieldCost ? round(calculationsA.yieldCost, 5) : ''}</td>
                <td>{partObj.hasOptionB && calculationsB?.yieldCost ? round(calculationsB.yieldCost, 5) : ''}</td>
              </tr>
              <tr>
                <th>Mold Repair</th>
                <td>{calculationsA?.rates?.toolRepair ? round(calculationsA.rates.toolRepair, 5) : ''}</td>
                <td>{partObj.hasOptionB && calculationsB?.rates?.toolRepair ? round(calculationsB.rates.toolRepair, 5) : ''}</td>
              </tr>
              </tbody>
            </table>
            <div style={styles.hr}/>
            <table id="print-table" style={styles.table}>
              <tbody>
              <tr>
                <th>Total Standard Cost</th>
                <td
                  style={{ width: '30%' }}>{calculationsA?.totalStandardCost ? round(calculationsA.totalStandardCost, 5) : ''}</td>
                <td
                  style={{ width: '30%' }}>{partObj.hasOptionB && calculationsB?.totalStandardCost ? round(calculationsB.totalStandardCost, 5) : ''}</td>
              </tr>
              </tbody>
            </table>
            <div style={styles.hr}/>
          </Fragment>
        );
      })}
      <PageHeader className="page-break page-header"/>
      <h5 style={styles.heading}>Assembled</h5>
      <div style={styles.hr}/>
      <h5 style={styles.heading}>Assembly Cost at 85% Efficiency</h5>
      {quoteData?.assemblyCosts?.length ?
        <table id="print-table" style={styles.table}>
          <thead>
          <tr>
            <th>Location</th>
            <th>Description</th>
            <th>Cost/hr/Person</th>
            <th>Parts/hr/Person</th>
            <th>Total</th>
          </tr>
          </thead>
          <tbody>
          {quoteData.assemblyCosts.map((row, index) => {
            const selectedLocationObj = locationLov.find(o => o.code === row.location);
            const selectedAssemblyType = assemblyTypesLov.find(o => o.code === row.assemblyType);
            const rateObj = currentRates.find(o => o.rateType === 'ASSEMBLY' && o.orgCode === convertOrgCode(row.location) &&
              o.assemblyType === row.assemblyType);
            const pph = row.pphPerPerson && !isNaN(parseFloat(row.pphPerPerson)) ?
              parseFloat(row.pphPerPerson) : null;
            const lineTotal = pph && rateObj?.rate ? round(rateObj.rate / pph, 5) : null;
            return (
              <tr key={index}>
                <td>{selectedLocationObj ? selectedLocationObj.name : ''}</td>
                <td>{selectedAssemblyType ? selectedAssemblyType.name : ''}</td>
                <td>{rateObj ? rateObj.rate : ''}</td>
                <td>{row.pphPerPerson}</td>
                <td>{lineTotal}</td>
              </tr>
            );
          })}
          </tbody>
        </table> :
        <p style={styles.pageText}>No Assembly Costs</p>
      }
      <p
        style={styles.pageTextTotal}>{`Total Assembly Cost: ${assemblyCalculations?.totalAssemblyCost ? round(assemblyCalculations.totalAssemblyCost, 5) : 'N/A'}`}</p>
      <div style={styles.hr}/>
      <h5 style={styles.heading}>Assembly Post Processing Costs</h5>
      <table id="print-table" style={styles.table}>
        <tbody>
        <tr>
          <th>Inspection</th>
          <td>{quoteData.assemblyInspection === 'HIGH_CAVITATION' ? '5 - High Cavitation' : selectedAssemblyInspectionObj ? selectedAssemblyInspectionObj.name : ''}</td>
          <th>Measuring & Packaging</th>
          <td>{quoteData.assemblyPackaging === 'HIGH_CAVITATION' ? '5 - High Cavitation' : selectedAssemblyPackagingObj ? selectedAssemblyPackagingObj.name : ''}</td>
        </tr>
        </tbody>
      </table>
      <p
        style={styles.pageTextTotal}>{`Total Assembly Post Processing Cost: ${assemblyCalculations?.totalPostProcessingCost ? round(assemblyCalculations.totalPostProcessingCost, 5) : 'N/A'}`}</p>
      <div style={styles.hr}/>
      <h5 style={styles.heading}>Molded Components</h5>
      <table id="print-table" style={styles.table}>
        <thead>
        <tr>
          <th/>
          <th colSpan="5" style={{ textAlign: 'center' }}>Option A</th>
          <th colSpan="5" style={{ textAlign: 'center' }}>Option B</th>
        </tr>
        <tr>
          <th>Component</th>
          <th>Qty</th>
          <th>Cavities</th>
          <th>Unit Cost</th>
          <th>Total Cost</th>
          <th>Location</th>
          <th>Qty</th>
          <th>Cavities</th>
          <th>Unit Cost</th>
          <th>Total Cost</th>
          <th>Location</th>
        </tr>
        </thead>
        <tbody>
        {quoteData?.moldedParts?.map((partObj, index, array) => {
          const somePartHasOptionB = array.some(o => o.hasOptionB);
          const { calculationsA, calculationsB } = moldingCalculations[index];
          const selectedToolLocationObjA = locationLov.find(o => o.code === partObj.toolLocationA);
          const selectedToolLocationObjB = locationLov.find(o => o.code === partObj.toolLocationB);
          return (
            <tr key={index}>
              <td>{partObj.partDescription}</td>
              <td>{partObj.quantityPerAssembly}</td>
              <td>{partObj.cavitiesPerPartA}</td>
              <td>{calculationsA?.totalStandardCost ? round(calculationsA.totalStandardCost, 5) : ''}</td>
              <td>{calculationsA?.totalStandardCost && partObj.quantityPerAssembly ? round(calculationsA.totalStandardCost * partObj.quantityPerAssembly, 5) : ''}</td>
              <td>{selectedToolLocationObjA ? selectedToolLocationObjA.name : ''}</td>
              <td>{somePartHasOptionB ? partObj.quantityPerAssembly : ''}</td>
              <td>{partObj.hasOptionB ? partObj.cavitiesPerPartB : somePartHasOptionB ? partObj.cavitiesPerPartA : ''}</td>
              <td>{somePartHasOptionB && calculationsB?.totalStandardCost ? round(calculationsB.totalStandardCost, 5) : ''}</td>
              <td>{somePartHasOptionB && calculationsB?.totalStandardCost && partObj.quantityPerAssembly ? round(calculationsB.totalStandardCost * partObj.quantityPerAssembly, 5) : ''}</td>
              <td>{partObj.hasOptionB && selectedToolLocationObjB ? selectedToolLocationObjB.name : somePartHasOptionB && selectedToolLocationObjA ? selectedToolLocationObjA.name : ''}</td>
            </tr>
          );
        })}
        <tr>
          <th>Total</th>
          <th/>
          <th/>
          <th/>
          <th>{totalMoldedComponentCostA ? round(totalMoldedComponentCostA, 5) : ''}</th>
          <th/>
          <th/>
          <th/>
          <th/>
          <td>{quoteData?.moldedParts?.some(o => o.hasOptionB) && totalMoldedComponentCostB ? round(totalMoldedComponentCostB, 5) : ''}</td>
          <th/>
        </tr>
        </tbody>
      </table>
      <div style={styles.hr}/>
      <h5 style={styles.heading}>Purchased</h5>
      <div style={styles.hr}/>
      <h5 style={styles.heading}>New Purchased, Passthrough, and Outsourced Parts</h5>
      {quoteData?.newPurchasedParts?.length ?
        <table id="print-table" style={styles.table}>
          <thead>
          <tr>
            <th>Part Name</th>
            <th>Estimate</th>
            <th>Outsourced</th>
            <th>Qty</th>
            <th>Unit Cost</th>
            <th>Total Cost</th>
          </tr>
          </thead>
          <tbody>
          {quoteData.newPurchasedParts.map((partObj, index) => {
            return (
              <tr key={index}>
                <td>{partObj.partDescription}</td>
                <td>{partObj.isEstimated ? 'Yes' : 'No'}</td>
                <td>{partObj.isOutsourced ? 'Yes' : 'No'}</td>
                <td>{partObj.quantity}</td>
                <td>{partObj.partCost}</td>
                <td>{partObj.quantity && partObj.partCost ? round(partObj.quantity * partObj.partCost, 5) : ''}</td>
              </tr>
            );
          })}
          </tbody>
        </table> :
        <p style={styles.pageText}>No Purchased Parts</p>
      }
      <p
        style={styles.pageTextTotal}>{`Total Purchased Components Cost: ${purchasingCalculations?.totalPurchasedComponentsCost ? round(purchasingCalculations.totalPurchasedComponentsCost, 5) : 'N/A'}`}</p>
      <div style={styles.hr}/>
      <h5 style={styles.heading}>Carryover NIFCO Parts</h5>
      {quoteData?.carryoverPurchasedParts?.length ?
        <table id="print-table" style={styles.table}>
          <thead>
          <tr>
            <th>P/N</th>
            <th>Part Name</th>
            <th>Location</th>
            <th>Qty</th>
            <th>Unit Cost</th>
            <th>Total Cost</th>
          </tr>
          </thead>
          <tbody>
          {quoteData.carryoverPurchasedParts.map((partObj, index) => {
            const selectedLocationObj = locationLov.find(o => o.code === partObj.orgCode);
            return (
              <tr key={index}>
                <td>{partObj.partNumber}</td>
                <td>{partObj.partDescription}</td>
                <td>{selectedLocationObj ? selectedLocationObj.name : ''}</td>
                <td>{partObj.quantity}</td>
                <td>{partObj.partCost}</td>
                <td>{partObj.quantity && partObj.partCost ? round(partObj.quantity * partObj.partCost, 5) : ''}</td>
              </tr>
            );
          })}
          </tbody>
        </table> :
        <p style={styles.pageText}>No Carryover Parts</p>
      }
      <p
        style={styles.pageTextTotal}>{`Total Carryover Parts Cost: ${purchasingCalculations?.totalCarryoverPartsCost ? round(purchasingCalculations.totalCarryoverPartsCost, 5) : 'N/A'}`}</p>
      <div style={styles.hr}/>
      <h5 style={styles.heading}>Packaging</h5>
      <div style={styles.hr}/>
      <h5 style={styles.heading}>Packaging Costs</h5>
      {quoteData?.packagingCosts?.length ?
        <table id="print-table" style={styles.table}>
          <thead>
          <tr>
            <th>Package Name</th>
            <th>Associated Part</th>
            <th>Qty</th>
            <th>Unit Cost</th>
            <th>Total Cost</th>
          </tr>
          </thead>
          <tbody>
          {quoteData.packagingCosts.map((partObj, index) => {
            const part = partObj.associatedPart ? partObj.associatedPart === 'FINISHED_GOOD' ? 'FG' : partObj.associatedPart.split('|').pop() : null;
            return (
              <tr key={index}>
                <td>{partObj.description}</td>
                <td>{part}</td>
                <td>{partObj.quantity}</td>
                <td>{partObj.unitCost}</td>
                <td>{partObj.quantity && partObj.unitCost ? round(partObj.unitCost / partObj.quantity, 5) : ''}</td>
              </tr>
            );
          })}
          </tbody>
        </table> :
        <p style={styles.pageText}>No Packaging</p>
      }
      <p style={styles.pageTextTotal}>
        {`Logistics (R-Mex Freight) Cost: ${quoteData.rMexFreightCost ? round(quoteData.rMexFreightCost, 5) : 'N/A'}`}
      </p>
      <p style={styles.pageTextTotal}>
        {`Total Packaging Cost: ${packagingCalculations?.totalPackagingCost ? round(packagingCalculations.totalPackagingCost, 5) : 'N/A'}`}
      </p>
      <div style={styles.hr}/>
      <PageHeader className="page-break page-header"/>
      <h5 style={styles.heading}>Auxiliary</h5>
      {quoteData?.auxCosts?.length ?
        <table id="print-table" style={styles.table}>
          <thead>
          <tr>
            <th>Description</th>
            <th>Quantity</th>
            <th>Cost</th>
            <th>Payment Type</th>
            <th>Total Cost</th>
          </tr>
          </thead>
          <tbody>
          {quoteData.auxCosts.map((partObj, index) => {
            const selectedPaymentTypeObj = paymentTypeLov.find(o => o.code === partObj.paymentType);
            return (
              <tr key={index}>
                <td>{partObj.description}</td>
                <td>{partObj.quantity}</td>
                <td>{partObj.unitCost}</td>
                <td>{selectedPaymentTypeObj ? selectedPaymentTypeObj.name : ''}</td>
                <td>{partObj.quantity && partObj.unitCost ? round(partObj.unitCost * partObj.quantity, 5) : ''}</td>
              </tr>
            );
          })}
          </tbody>
        </table> :
        <p style={styles.pageText}>No Auxiliary Costs</p>
      }
      <p style={styles.pageTextTotal}>
        {`Total Auxiliary Equipment Cost: ${auxCalculations?.totalAuxEquipmentCost ? round(auxCalculations.totalAuxEquipmentCost, 2) : 'N/A'}`}
      </p>
      <p style={styles.pageTextTotal}>
        {`Total Expense Cost: ${auxCalculations?.totalExpenseCost ? round(auxCalculations.totalExpenseCost, 2) : 'N/A'}`}
      </p>
      <p style={styles.pageTextTotal}>
        {`Total Depreciated Cost: ${auxCalculations?.totalDepreciatedCost ? round(auxCalculations.totalDepreciatedCost, 2) : 'N/A'}`}
      </p>
      <p style={styles.pageTextTotal}>
        {`Total Customer Pays Cost: ${auxCalculations?.totalCustomerPaysCost ? round(auxCalculations.totalCustomerPaysCost, 2) : 'N/A'}`}
      </p>
      <div style={styles.hr}/>
      <h5 style={styles.heading}>Testing</h5>
      {quoteData?.testingCosts?.length ?
        <table id="print-table" style={styles.table}>
          <thead>
          <tr>
            <th>Test</th>
            <th>Quantity</th>
            <th>Cost</th>
            <th>Payment Type</th>
            <th>Total Cost</th>
          </tr>
          </thead>
          <tbody>
          {quoteData.testingCosts.map((partObj, index) => {
            const selectedPaymentTypeObj = paymentTypeLov.find(o => o.code === partObj.paymentType);
            return (
              <tr key={index}>
                <td>{partObj.description}</td>
                <td>{partObj.quantity}</td>
                <td>{partObj.unitCost}</td>
                <td>{selectedPaymentTypeObj ? selectedPaymentTypeObj.name : ''}</td>
                <td>{partObj.quantity && partObj.unitCost ? round(partObj.unitCost / partObj.quantity, 5) : ''}</td>
              </tr>
            );
          })}
          </tbody>
        </table> :
        <p style={styles.pageText}>No Testing Costs</p>
      }
      <p style={styles.pageTextTotal}>
        {`Total Testing Cost: ${testingCalculations?.totalTestingCost ? round(testingCalculations.totalTestingCost, 2) : 'N/A'}`}
      </p>
      <p style={styles.pageTextTotal}>
        {`Total Expense Cost: ${testingCalculations?.totalExpenseCost ? round(testingCalculations.totalExpenseCost, 2) : 'N/A'}`}
      </p>
      <p style={styles.pageTextTotal}>
        {`Total Depreciated Cost: ${testingCalculations?.totalDepreciatedCost ? round(testingCalculations.totalDepreciatedCost, 2) : 'N/A'}`}
      </p>
      <p style={styles.pageTextTotal}>
        {`Total Customer Pays Cost: ${testingCalculations?.totalCustomerPaysCost ? round(testingCalculations.totalCustomerPaysCost, 2) : 'N/A'}`}
      </p>
      <div style={styles.hr}/>
      <PageHeader className="page-break page-header"/>
      <h5 style={styles.heading}>Finish Good Calculations</h5>
      <table id="print-table" style={styles.table}>
        <thead>
        <tr>
          <th/>
          <th style={{ width: '30%' }}>Option A</th>
          <th style={{ width: '30%' }}>Option B</th>
        </tr>
        </thead>
      </table>
      <div style={styles.hr}/>
      <h5 style={styles.heading}>Cost of Parts</h5>
      <table id="print-table" style={styles.table}>
        <tbody>
        <tr>
          <th>Molded Components</th>
          <td style={{ width: '30%' }}>
            {finishedGoodCalculations?.totalMoldedComponentsA ? round(finishedGoodCalculations.totalMoldedComponentsA, 5) : ''}
          </td>
          <td style={{ width: '30%' }}>
            {quoteData?.moldedParts?.some(o => o.hasOptionB) && finishedGoodCalculations?.totalMoldedComponentsB ? round(finishedGoodCalculations.totalMoldedComponentsB, 5) : ''}
          </td>
        </tr>
        <tr>
          <th>Carryover NIFCO Parts</th>
          <td>{finishedGoodCalculations?.totalCarryoverPartsCost ? round(finishedGoodCalculations.totalCarryoverPartsCost, 5) : ''}</td>
          <td>{quoteData?.moldedParts?.some(o => o.hasOptionB) && finishedGoodCalculations?.totalCarryoverPartsCost ? round(finishedGoodCalculations.totalCarryoverPartsCost, 5) : ''}</td>
        </tr>
        <tr>
          <th>New Purchased, Passthrough, Outsourced Parts</th>
          <td>{finishedGoodCalculations?.totalPurchasedComponentsCost ? round(finishedGoodCalculations.totalPurchasedComponentsCost, 5) : ''}</td>
          <td>{quoteData?.moldedParts?.some(o => o.hasOptionB) && finishedGoodCalculations?.totalPurchasedComponentsCost ? round(finishedGoodCalculations.totalPurchasedComponentsCost, 5) : ''}</td>
        </tr>
        <tr>
          <th>Total Components Cost</th>
          <td>{finishedGoodCalculations?.totalComponentsCostA ? round(finishedGoodCalculations.totalComponentsCostA, 5) : ''}</td>
          <td>{quoteData?.moldedParts?.some(o => o.hasOptionB) && finishedGoodCalculations?.totalComponentsCostB ? round(finishedGoodCalculations.totalComponentsCostB, 5) : ''}</td>
        </tr>
        </tbody>
      </table>
      <div style={styles.hr}/>
      <h5 style={styles.heading}>Other Costs</h5>
      <table id="print-table" style={styles.table}>
        <tbody>
        <tr>
          <th>Total Assembly</th>
          <td style={{ width: '30%' }}>
            {finishedGoodCalculations?.totalAssemblyCost ? round(finishedGoodCalculations.totalAssemblyCost, 5) : ''}
          </td>
          <td style={{ width: '30%' }}>
            {quoteData?.moldedParts?.some(o => o.hasOptionB) && finishedGoodCalculations?.totalAssemblyCost ? round(finishedGoodCalculations.totalAssemblyCost, 5) : ''}
          </td>
        </tr>
        <tr>
          <th>Total Assembly Post Processing</th>
          <td>{finishedGoodCalculations?.totalAssemblyPostProcessingCost ? round(finishedGoodCalculations.totalAssemblyPostProcessingCost, 5) : ''}</td>
          <td>{quoteData?.moldedParts?.some(o => o.hasOptionB) && finishedGoodCalculations?.totalAssemblyPostProcessingCost ? round(finishedGoodCalculations.totalAssemblyPostProcessingCost, 5) : ''}</td>
        </tr>
        <tr>
          <th>Total Packaging</th>
          <td>{finishedGoodCalculations?.totalPackagingCost ? round(finishedGoodCalculations.totalPackagingCost, 5) : ''}</td>
          <td>{quoteData?.moldedParts?.some(o => o.hasOptionB) && finishedGoodCalculations?.totalPackagingCost ? round(finishedGoodCalculations.totalPackagingCost, 5) : ''}</td>
        </tr>
        <tr>
          <th>Testing Cost (Depreciated)</th>
          <td>{finishedGoodCalculations?.totalTestingDepreciatedCost ? round(finishedGoodCalculations.totalTestingDepreciatedCost, 5) : ''}</td>
          <td>{quoteData?.moldedParts?.some(o => o.hasOptionB) && finishedGoodCalculations?.totalTestingDepreciatedCost ? round(finishedGoodCalculations.totalTestingDepreciatedCost, 5) : ''}</td>
        </tr>
        <tr>
          <th>Auxiliary Cost (Depreciated)</th>
          <td>{finishedGoodCalculations?.totalAuxDepreciatedCost ? round(finishedGoodCalculations.totalAuxDepreciatedCost, 5) : ''}</td>
          <td>{quoteData?.moldedParts?.some(o => o.hasOptionB) && finishedGoodCalculations?.totalAuxDepreciatedCost ? round(finishedGoodCalculations.totalAuxDepreciatedCost, 5) : ''}</td>
        </tr>
        <tr>
          <th>Total Other Cost</th>
          <td>{finishedGoodCalculations?.totalOtherCost ? round(finishedGoodCalculations.totalOtherCost, 5) : ''}</td>
          <td>{quoteData?.moldedParts?.some(o => o.hasOptionB) && finishedGoodCalculations?.totalOtherCost ? round(finishedGoodCalculations.totalOtherCost, 5) : ''}</td>
        </tr>
        </tbody>
      </table>
      <div style={styles.hr}/>
      <h5 style={styles.heading}>Total Finished Good Cost</h5>
      <table id="print-table" style={styles.table}>
        <tbody>
        <tr>
          <th>Base Finish Good Cost</th>
          <td style={{ width: '30%' }}>
            {finishedGoodCalculations?.baseFinishedGoodCostA ? round(finishedGoodCalculations.baseFinishedGoodCostA, 5) : ''}
          </td>
          <td style={{ width: '30%' }}>
            {quoteData?.moldedParts?.some(o => o.hasOptionB) && finishedGoodCalculations?.baseFinishedGoodCostB ? round(finishedGoodCalculations.baseFinishedGoodCostB, 5) : ''}
          </td>
        </tr>
        <tr>
          <th>Administrative Cost</th>
          <td>{finishedGoodCalculations?.administrativeCostA ? round(finishedGoodCalculations.administrativeCostA, 5) : ''}</td>
          <td>{quoteData?.moldedParts?.some(o => o.hasOptionB) && finishedGoodCalculations?.administrativeCostB ? round(finishedGoodCalculations.administrativeCostB, 5) : ''}</td>
        </tr>
        <tr>
          <th>Technical Cost</th>
          <td>{finishedGoodCalculations?.technicalCostA ? round(finishedGoodCalculations.technicalCostA, 5) : ''}</td>
          <td>{quoteData?.moldedParts?.some(o => o.hasOptionB) && finishedGoodCalculations?.technicalCostB ? round(finishedGoodCalculations.technicalCostB, 5) : ''}</td>
        </tr>
        <tr>
          <th>Yield</th>
          <td>{finishedGoodCalculations?.yieldCostA ? round(finishedGoodCalculations.yieldCostA, 5) : ''}</td>
          <td>{quoteData?.moldedParts?.some(o => o.hasOptionB) && finishedGoodCalculations?.yieldCostB ? round(finishedGoodCalculations.yieldCostB, 5) : ''}</td>
        </tr>
        <tr>
          <th>Logistic Cost (R-Mex Freight)</th>
          <td>{finishedGoodCalculations?.logisticCost ? round(finishedGoodCalculations.logisticCost, 5) : ''}</td>
          <td>{quoteData?.moldedParts?.some(o => o.hasOptionB) && finishedGoodCalculations?.logisticCost ? round(finishedGoodCalculations.logisticCost, 5) : ''}</td>
        </tr>
        </tbody>
      </table>
      <div style={styles.hr}/>
      <table id="print-table" style={styles.table}>
        <tbody>
        <tr>
          <th>Total Standard Cost</th>
          <th style={{ width: '30%' }}>
            {finishedGoodCalculations?.standardCostA ? round(finishedGoodCalculations.standardCostA, 5) : ''}
          </th>
          <th style={{ width: '30%' }}>
            {quoteData?.moldedParts?.some(o => o.hasOptionB) && finishedGoodCalculations?.standardCostB ? round(finishedGoodCalculations.standardCostB, 5) : ''}
          </th>
        </tr>
        </tbody>
      </table>
      <div style={styles.hr}/>
    </div>
  );
};

const styles = {
  mainPrintContainer: {
    padding: 5,
    fontSize: 12,
  },
  table: {
    width: '100%',
  },
  headerTable: {
    flex: 1,
    marginLeft: 15,
    marginRight: 15,
  },
  centerCell: {
    textAlign: 'center',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  heading: {
    fontSize: 15,
    fontWeight: 'bold',
  },
  pageText: {
    margin: 0,
  },
  pageTextTotal: {
    margin: 0,
    marginTop: 5,
    textAlign: 'right',
    fontWeight: 'bold',
  },
  headerText: {
    margin: 0,
    textAlign: 'center',
  },
  hr: {
    borderTopColor: '#000000',
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    marginTop: 5,
    marginBottom: 5,
  },
};

export default PrintPage;