import COLORS from '../styles/colors';
import { Button, Form, InputGroup, Tab, Table, Tabs } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  derivedWeightLovAtom,
  annealingLovAtom,
  ejectorSystemLovAtom,
  gateStyleLovAtom,
  gateCuttingLovAtom,
  toolingToolTypeLovAtom,
  toolDescLovAtom,
  locationLovAtom,
  pressTypeLovAtom,
  pressSizeLovAtom,
  inspectionLovAtom,
  packagingLovAtom,
  packagingCostsAtom,
  quoteDataAtom,
  appRolesAtom,
  userIdAtom,
  loggedInUserAtom,
  currentRatesAtom,
  saveQuoteTriggerAtom,
  quoteProcessorAtom,
  quoteMissingAttributesAtom,
  moldedPartsValueAtom,
  selectedMoldedPartIndexAtom,
  bomDataAtom,
  createMaterialBlendsAtom,
  initValuesAtom,
  quoteNewPartNumbersAtom,
} from '../state';
import * as CONFIG from '../config';
import cloneDeep from 'lodash.clonedeep';
import { DateTime } from 'luxon';
import { convertOrgCode, round } from '../helpers/misc';
import {
  gramsToPounds,
  getMoldingCalculations,
} from '../helpers/calculations';
import * as CONSTANTS from '../constants';
import GLOBAL_STYLES from '../styles';
import { faExchangeAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const QuoteTabMolded = () => {
  const [totalStdCostsVisible, setTotalStdCostsVisible] = useState(false);
  const [materialCostsVisible, setMaterialCostsVisible] = useState(false);
  const [injectionMoldingCostsVisible, setInjectionMoldingCostsVisible] = useState(false);
  const [postProcessingCostsVisible, setPostProcessingCostsVisible] = useState(false);
  const [outsourcedCostsVisible, setOutsourcedCostsVisible] = useState(false);
  const [annealingSelect, setAnnealingSelect] = useState('NULL');
  const [derivedWeightSelect, setDerivedWeightSelect] = useState('NULL');
  const [inspectionSelect, setInspectionSelect] = useState('NULL');
  const [packagingSelect, setPackagingSelect] = useState('NULL');
  const [ejectorSystemSelect, setEjectorSystemSelect] = useState('NULL');
  const [gateStyleSelectA, setGateStyleSelectA] = useState('NULL');
  const [gateCuttingSelectA, setGateCuttingSelectA] = useState('NULL');
  const [toolingToolTypeSelectA, setToolingToolTypeSelectA] = useState('NULL');
  const [toolDescSelectA, setToolDescSelectA] = useState('NULL');
  const [locationSelectA, setLocationSelectA] = useState('NULL');
  const [pressTypeSelectA, setPressTypeSelectA] = useState('NULL');
  const [pressSizeSelectA, setPressSizeSelectA] = useState('NULL');
  const [gateStyleSelectB, setGateStyleSelectB] = useState('NULL');
  const [gateCuttingSelectB, setGateCuttingSelectB] = useState('NULL');
  const [toolingToolTypeSelectB, setToolingToolTypeSelectB] = useState('NULL');
  const [toolDescSelectB, setToolDescSelectB] = useState('NULL');
  const [locationSelectB, setLocationSelectB] = useState('NULL');
  const [pressTypeSelectB, setPressTypeSelectB] = useState('NULL');
  const [pressSizeSelectB, setPressSizeSelectB] = useState('NULL');
  const [partWeightValue, setPartWeightValue] = useState('');
  const [regrindPercentageValue, setRegrindPercentageValue] = useState('');
  const [outsourcedCostValue, setOutsourcedCostValue] = useState('30');
  const [newPartCommentValue, setNewPartCommentValue] = useState('');
  const [regrindIsUsed, setRegrindIsUsed] = useState(false);
  const [partIsOutsourced, setPartIsOutsourced] = useState(false);
  const [glassIsUsed, setGlassIsUsed] = useState(false);
  const [createMaterialBlends, setCreateMaterialBlends] = useRecoilState(createMaterialBlendsAtom);
  const annealingLov = useRecoilValue(annealingLovAtom);
  const packagingCosts = useRecoilValue(packagingCostsAtom);
  const ejectorSystemLov = useRecoilValue(ejectorSystemLovAtom);
  const gateStyleLov = useRecoilValue(gateStyleLovAtom);
  const gateCuttingLov = useRecoilValue(gateCuttingLovAtom);
  const toolingToolTypeLov = useRecoilValue(toolingToolTypeLovAtom);
  const toolDescLov = useRecoilValue(toolDescLovAtom);
  const locationLov = useRecoilValue(locationLovAtom);
  const pressTypeLov = useRecoilValue(pressTypeLovAtom);
  const pressSizeLov = useRecoilValue(pressSizeLovAtom);
  const derivedWeightLov = useRecoilValue(derivedWeightLovAtom);
  const inspectionLov = useRecoilValue(inspectionLovAtom);
  const packagingLov = useRecoilValue(packagingLovAtom);
  const quoteData = useRecoilValue(quoteDataAtom);
  const appRoles = useRecoilValue(appRolesAtom);
  const quoteNewPartNumbers = useRecoilValue(quoteNewPartNumbersAtom);
  const quoteMissingAttributes = useRecoilValue(quoteMissingAttributesAtom);
  const [moldedPartsValue, setMoldedPartsValue] = useRecoilState(moldedPartsValueAtom);
  const [selectedMoldedPartIndex, setSelectedMoldedPartIndex] = useRecoilState(selectedMoldedPartIndexAtom);
  const [initValues, setInitValues] = useRecoilState(initValuesAtom);
  const userId = useRecoilValue(userIdAtom);
  const loggedInUser = useRecoilValue(loggedInUserAtom);
  const setBomData = useSetRecoilState(bomDataAtom);
  const [insertMoldedSelect, setInsertMoldedSelect] = useState('NO');
  const [sideActionsValue, setSideActionsValue] = useState('');
  const [cavitiesPerMoldA, setCavitiesPerMoldA] = useState('');
  const [cavitiesPerPartA, setCavitiesPerPartA] = useState('');
  const [numOfDiffPartsA, setNumOfDiffPartsA] = useState('');
  const [runnerPercentageA, setRunnerPercentageA] = useState('');
  const [runnerWeightA, setRunnerWeightA] = useState('');
  const [editingRunnerPercentageA, setEditingRunnerPercentageA] = useState(true);
  const [cycleTimeA, setCycleTimeA] = useState('');
  const [toolCostA, setToolCostA] = useState('');
  const [tuningTextureCostA, setTuningTextureCostA] = useState('');
  const [boatShipmentCostA, setBoatShipmentCostA] = useState('');
  const [airShipmentCostA, setAirShipmentCostA] = useState('');
  const [leadTimeWeeksA, setLeadTimeWeeksA] = useState('');
  const [shotsPerHourA, setShotsPerHourA] = useState('');
  const [cavitiesPerMoldB, setCavitiesPerMoldB] = useState('');
  const [cavitiesPerPartB, setCavitiesPerPartB] = useState('');
  const [numOfDiffPartsB, setNumOfDiffPartsB] = useState('');
  const [runnerPercentageB, setRunnerPercentageB] = useState('');
  const [runnerWeightB, setRunnerWeightB] = useState('');
  const [editingRunnerPercentageB, setEditingRunnerPercentageB] = useState(true);
  const [cycleTimeB, setCycleTimeB] = useState('');
  const [toolCostB, setToolCostB] = useState('');
  const [tuningTextureCostB, setTuningTextureCostB] = useState('');
  const [boatShipmentCostB, setBoatShipmentCostB] = useState('');
  const [airShipmentCostB, setAirShipmentCostB] = useState('');
  const [leadTimeWeeksB, setLeadTimeWeeksB] = useState('');
  const [shotsPerHourB, setShotsPerHourB] = useState('');
  const [hasOptionB, setHasOptionB] = useState(false);
  const currentRates = useRecoilValue(currentRatesAtom);
  const saveQuoteTrigger = useRecoilValue(saveQuoteTriggerAtom);
  const quoteProcessor = useRecoilValue(quoteProcessorAtom);
  
  useEffect(() => {
    if(saveQuoteTrigger) {
      const newMoldedPartsValue = cloneDeep(moldedPartsValue);
      if(newMoldedPartsValue && newMoldedPartsValue.length && selectedMoldedPartIndex != null) {
        newMoldedPartsValue[selectedMoldedPartIndex].partWeightDerived = derivedWeightSelect;
        newMoldedPartsValue[selectedMoldedPartIndex].partWeight = partWeightValue;
        newMoldedPartsValue[selectedMoldedPartIndex].regrindPercentage = regrindPercentageValue;
        newMoldedPartsValue[selectedMoldedPartIndex].outsourcedCost = outsourcedCostValue;
        newMoldedPartsValue[selectedMoldedPartIndex].regrindUsed = regrindIsUsed;
        newMoldedPartsValue[selectedMoldedPartIndex].isOutsourced = partIsOutsourced;
        newMoldedPartsValue[selectedMoldedPartIndex].glassUsed = glassIsUsed;
        newMoldedPartsValue[selectedMoldedPartIndex].createMaterialBlends = createMaterialBlends;
        newMoldedPartsValue[selectedMoldedPartIndex].annealing = annealingSelect;
        newMoldedPartsValue[selectedMoldedPartIndex].inspection = inspectionSelect;
        newMoldedPartsValue[selectedMoldedPartIndex].packaging = packagingSelect;
        newMoldedPartsValue[selectedMoldedPartIndex].insertMolded = insertMoldedSelect;
        newMoldedPartsValue[selectedMoldedPartIndex].sideActions = sideActionsValue;
        newMoldedPartsValue[selectedMoldedPartIndex].ejectorSystem = ejectorSystemSelect;
        newMoldedPartsValue[selectedMoldedPartIndex].cavitiesPerMoldA = cavitiesPerMoldA;
        newMoldedPartsValue[selectedMoldedPartIndex].cavitiesPerPartA = cavitiesPerPartA;
        newMoldedPartsValue[selectedMoldedPartIndex].numOfDiffPartsA = numOfDiffPartsA;
        newMoldedPartsValue[selectedMoldedPartIndex].runnerPercentageA = runnerPercentageA;
        newMoldedPartsValue[selectedMoldedPartIndex].gateStyleA = gateStyleSelectA;
        newMoldedPartsValue[selectedMoldedPartIndex].gateCuttingA = gateCuttingSelectA;
        newMoldedPartsValue[selectedMoldedPartIndex].toolTypeA = toolingToolTypeSelectA;
        newMoldedPartsValue[selectedMoldedPartIndex].toolCostA = toolCostA;
        newMoldedPartsValue[selectedMoldedPartIndex].tuningTextureCostA = tuningTextureCostA;
        newMoldedPartsValue[selectedMoldedPartIndex].boatShipmentCostA = boatShipmentCostA;
        newMoldedPartsValue[selectedMoldedPartIndex].airShipmentCostA = airShipmentCostA;
        newMoldedPartsValue[selectedMoldedPartIndex].leadTimeWeeksA = leadTimeWeeksA;
        newMoldedPartsValue[selectedMoldedPartIndex].toolDescA = toolDescSelectA;
        newMoldedPartsValue[selectedMoldedPartIndex].toolLocationA = locationSelectA;
        newMoldedPartsValue[selectedMoldedPartIndex].pressTypeA = pressTypeSelectA;
        newMoldedPartsValue[selectedMoldedPartIndex].shotsPerHourA = shotsPerHourA;
        newMoldedPartsValue[selectedMoldedPartIndex].pressSizeA = pressSizeSelectA;
        newMoldedPartsValue[selectedMoldedPartIndex].cavitiesPerMoldB = hasOptionB ? cavitiesPerMoldB : null;
        newMoldedPartsValue[selectedMoldedPartIndex].cavitiesPerPartB = hasOptionB ? cavitiesPerPartB : null;
        newMoldedPartsValue[selectedMoldedPartIndex].numOfDiffPartsB = hasOptionB ? numOfDiffPartsB : null;
        newMoldedPartsValue[selectedMoldedPartIndex].runnerPercentageB = hasOptionB ? runnerPercentageB : null;
        newMoldedPartsValue[selectedMoldedPartIndex].gateStyleB = hasOptionB ? gateStyleSelectB : null;
        newMoldedPartsValue[selectedMoldedPartIndex].gateCuttingB = hasOptionB ? gateCuttingSelectB : null;
        newMoldedPartsValue[selectedMoldedPartIndex].toolTypeB = hasOptionB ? toolingToolTypeSelectB : null;
        newMoldedPartsValue[selectedMoldedPartIndex].toolCostB = hasOptionB ? toolCostB : null;
        newMoldedPartsValue[selectedMoldedPartIndex].tuningTextureCostB = hasOptionB ? tuningTextureCostB : null;
        newMoldedPartsValue[selectedMoldedPartIndex].boatShipmentCostB = hasOptionB ? boatShipmentCostB : null;
        newMoldedPartsValue[selectedMoldedPartIndex].airShipmentCostB = hasOptionB ? airShipmentCostB : null;
        newMoldedPartsValue[selectedMoldedPartIndex].leadTimeWeeksB = hasOptionB ? leadTimeWeeksB : null;
        newMoldedPartsValue[selectedMoldedPartIndex].toolDescB = hasOptionB ? toolDescSelectB : null;
        newMoldedPartsValue[selectedMoldedPartIndex].toolLocationB = hasOptionB ? locationSelectB : null;
        newMoldedPartsValue[selectedMoldedPartIndex].pressTypeB = hasOptionB ? pressTypeSelectB : null;
        newMoldedPartsValue[selectedMoldedPartIndex].shotsPerHourB = hasOptionB ? shotsPerHourB : null;
        newMoldedPartsValue[selectedMoldedPartIndex].pressSizeB = hasOptionB ? pressSizeSelectB : null;
        newMoldedPartsValue[selectedMoldedPartIndex].hasOptionB = hasOptionB;
      }
      quoteProcessor.setMoldedData(newMoldedPartsValue.map(partObj => ({
        airShipmentCostA: partObj.airShipmentCostA && !isNaN(parseFloat(partObj.airShipmentCostA)) ? parseFloat(partObj.airShipmentCostA) : null,
        airShipmentCostB: partObj.airShipmentCostB && !isNaN(parseFloat(partObj.airShipmentCostB)) ? parseFloat(partObj.airShipmentCostB) : null,
        annealing: partObj.annealing && partObj.annealing !== 'NULL' ? partObj.annealing : null,
        boatShipmentCostA: partObj.boatShipmentCostA && !isNaN(parseFloat(partObj.boatShipmentCostA)) ? parseFloat(partObj.boatShipmentCostA) : null,
        boatShipmentCostB: partObj.boatShipmentCostB && !isNaN(parseFloat(partObj.boatShipmentCostB)) ? parseFloat(partObj.boatShipmentCostB) : null,
        cavitiesPerMoldA: partObj.cavitiesPerMoldA && !isNaN(parseFloat(partObj.cavitiesPerMoldA)) ? parseFloat(partObj.cavitiesPerMoldA) : null,
        cavitiesPerMoldB: partObj.cavitiesPerMoldB && !isNaN(parseFloat(partObj.cavitiesPerMoldB)) ? parseFloat(partObj.cavitiesPerMoldB) : null,
        cavitiesPerPartA: partObj.cavitiesPerPartA && !isNaN(parseFloat(partObj.cavitiesPerPartA)) ? parseFloat(partObj.cavitiesPerPartA) : null,
        cavitiesPerPartB: partObj.cavitiesPerPartB && !isNaN(parseFloat(partObj.cavitiesPerPartB)) ? parseFloat(partObj.cavitiesPerPartB) : null,
        materials: partObj.materials ? partObj.materials : [],
        ejectorSystem: partObj.ejectorSystem && partObj.ejectorSystem !== 'NULL' ? partObj.ejectorSystem : null,
        gateCuttingA: partObj.gateCuttingA && partObj.gateCuttingA !== 'NULL' ? partObj.gateCuttingA : null,
        gateCuttingB: partObj.gateCuttingB && partObj.gateCuttingB !== 'NULL' ? partObj.gateCuttingB : null,
        gateStyleA: partObj.gateStyleA && partObj.gateStyleA !== 'NULL' ? partObj.gateStyleA : null,
        gateStyleB: partObj.gateStyleB && partObj.gateStyleB !== 'NULL' ? partObj.gateStyleB : null,
        glassUsed: partObj.glassUsed != null ? partObj.glassUsed : null,
        createMaterialBlends: partObj.createMaterialBlends != null ? partObj.createMaterialBlends : true,
        insertMolded: partObj.insertMolded && partObj.insertMolded !== 'NULL' ? partObj.insertMolded === 'YES' : null,
        inspection: partObj.inspection && partObj.inspection !== 'NULL' ? partObj.inspection : null,
        leadTimeWeeksA: partObj.leadTimeWeeksA && !isNaN(parseFloat(partObj.leadTimeWeeksA)) ? parseFloat(partObj.leadTimeWeeksA) : null,
        leadTimeWeeksB: partObj.leadTimeWeeksB && !isNaN(parseFloat(partObj.leadTimeWeeksB)) ? parseFloat(partObj.leadTimeWeeksB) : null,
        numOfDiffPartsA: partObj.numOfDiffPartsA && !isNaN(parseFloat(partObj.numOfDiffPartsA)) ? parseFloat(partObj.numOfDiffPartsA) : null,
        numOfDiffPartsB: partObj.numOfDiffPartsB && !isNaN(parseFloat(partObj.numOfDiffPartsB)) ? parseFloat(partObj.numOfDiffPartsB) : null,
        packaging: partObj.packaging && partObj.packaging !== 'NULL' ? partObj.packaging : null,
        partDescription: partObj.partDescription && partObj.partDescription.trim().length ? partObj.partDescription.trim() : null,
        partWeight: partObj.partWeight && !isNaN(parseFloat(partObj.partWeight)) ? parseFloat(partObj.partWeight) : null,
        partWeightDerived: partObj.partWeightDerived && partObj.partWeightDerived !== 'NULL' ? partObj.partWeightDerived : null,
        pressSizeA: partObj.pressSizeA && partObj.pressSizeA !== 'NULL' ? partObj.pressSizeA : null,
        pressSizeB: partObj.pressSizeB && partObj.pressSizeB !== 'NULL' ? partObj.pressSizeB : null,
        pressTypeA: partObj.pressTypeA && partObj.pressTypeA !== 'NULL' ? partObj.pressTypeA : null,
        pressTypeB: partObj.pressTypeB && partObj.pressTypeB !== 'NULL' ? partObj.pressTypeB : null,
        regrindPercentage: partObj.regrindPercentage && !isNaN(parseFloat(partObj.regrindPercentage)) ? parseFloat(partObj.regrindPercentage) : null,
        regrindUsed: partObj.regrindUsed != null ? partObj.regrindUsed : null,
        isOutsourced: partObj.isOutsourced != null ? partObj.isOutsourced : null,
        outsourcedCost: partObj.outsourcedCost && !isNaN(parseFloat(partObj.outsourcedCost)) ? parseFloat(partObj.outsourcedCost) : null,
        runnerPercentageA: partObj.runnerPercentageA != null && !isNaN(parseFloat(partObj.runnerPercentageA)) ? parseFloat(partObj.runnerPercentageA) : null,
        runnerPercentageB: partObj.runnerPercentageB != null && !isNaN(parseFloat(partObj.runnerPercentageB)) ? parseFloat(partObj.runnerPercentageB) : null,
        shotsPerHourA: partObj.shotsPerHourA && !isNaN(parseFloat(partObj.shotsPerHourA)) ? parseFloat(partObj.shotsPerHourA) : null,
        shotsPerHourB: partObj.shotsPerHourB && !isNaN(parseFloat(partObj.shotsPerHourB)) ? parseFloat(partObj.shotsPerHourB) : null,
        sideActions: partObj.sideActions && !isNaN(parseFloat(partObj.sideActions)) ? parseFloat(partObj.sideActions) : null,
        toolCostA: partObj.toolCostA && !isNaN(parseFloat(partObj.toolCostA)) ? parseFloat(partObj.toolCostA) : null,
        toolCostB: partObj.toolCostB && !isNaN(parseFloat(partObj.toolCostB)) ? parseFloat(partObj.toolCostB) : null,
        toolDescA: partObj.toolDescA && partObj.toolDescA !== 'NULL' ? partObj.toolDescA : null,
        toolDescB: partObj.toolDescB && partObj.toolDescB !== 'NULL' ? partObj.toolDescB : null,
        toolLocationA: partObj.toolLocationA && partObj.toolLocationA !== 'NULL' ? partObj.toolLocationA : null,
        toolLocationB: partObj.toolLocationB && partObj.toolLocationB !== 'NULL' ? partObj.toolLocationB : null,
        toolTypeA: partObj.toolTypeA && partObj.toolTypeA !== 'NULL' ? partObj.toolTypeA : null,
        toolTypeB: partObj.toolTypeB && partObj.toolTypeB !== 'NULL' ? partObj.toolTypeB : null,
        tuningTextureCostA: partObj.tuningTextureCostA && !isNaN(parseFloat(partObj.tuningTextureCostA)) ? parseFloat(partObj.tuningTextureCostA) : null,
        tuningTextureCostB: partObj.tuningTextureCostB && !isNaN(parseFloat(partObj.tuningTextureCostB)) ? parseFloat(partObj.tuningTextureCostB) : null,
        hasOptionB: partObj.hasOptionB != null ? partObj.hasOptionB : null,
        quantityPerAssembly: partObj.quantityPerAssembly && typeof partObj.quantityPerAssembly === 'number' ? partObj.quantityPerAssembly : 1,
        bomId: partObj.bomId ? partObj.bomId : null,
        partComments: partObj.partComments ? partObj.partComments : null,
      })));
    }
  }, [
    saveQuoteTrigger,
    quoteProcessor,
    moldedPartsValue,
    airShipmentCostA,
    airShipmentCostB,
    annealingSelect,
    boatShipmentCostA,
    boatShipmentCostB,
    cavitiesPerMoldA,
    cavitiesPerMoldB,
    cavitiesPerPartA,
    cavitiesPerPartB,
    derivedWeightSelect,
    ejectorSystemSelect,
    gateCuttingSelectA,
    gateCuttingSelectB,
    gateStyleSelectA,
    gateStyleSelectB,
    glassIsUsed,
    createMaterialBlends,
    insertMoldedSelect,
    inspectionSelect,
    leadTimeWeeksA,
    leadTimeWeeksB,
    locationSelectA,
    locationSelectB,
    numOfDiffPartsA,
    numOfDiffPartsB,
    packagingSelect,
    partWeightValue,
    pressSizeSelectA,
    pressSizeSelectB,
    pressTypeSelectA,
    pressTypeSelectB,
    regrindIsUsed,
    partIsOutsourced,
    regrindPercentageValue,
    outsourcedCostValue,
    runnerPercentageA,
    runnerPercentageB,
    selectedMoldedPartIndex,
    shotsPerHourA,
    shotsPerHourB,
    sideActionsValue,
    toolCostA,
    toolCostB,
    toolDescSelectA,
    toolDescSelectB,
    toolingToolTypeSelectA,
    toolingToolTypeSelectB,
    tuningTextureCostA,
    tuningTextureCostB,
    hasOptionB,
  ]);
  
  useEffect(() => {
    if(initValues && quoteData) {
      setMoldedPartsValue(quoteData.moldedParts && quoteData.moldedParts.length ? quoteData.moldedParts : []);
      setSelectedMoldedPartIndex(0);
      setInitValues(false);
    }
  }, [initValues, quoteData, setMoldedPartsValue, setSelectedMoldedPartIndex, setInitValues]);
  
  useEffect(() => {
    if(selectedMoldedPartIndex != null && moldedPartsValue && moldedPartsValue.length) {
      const selectedMoldedPartObj = moldedPartsValue[selectedMoldedPartIndex];
      setDerivedWeightSelect(selectedMoldedPartObj.partWeightDerived ? selectedMoldedPartObj.partWeightDerived : 'NULL');
      setPartWeightValue(selectedMoldedPartObj.partWeight ? `${selectedMoldedPartObj.partWeight}` : '');
      setRegrindPercentageValue(selectedMoldedPartObj.regrindPercentage ? selectedMoldedPartObj.regrindPercentage : '30');
      setOutsourcedCostValue(selectedMoldedPartObj.outsourcedCost ? `${selectedMoldedPartObj.outsourcedCost}` : '');
      setRegrindIsUsed(selectedMoldedPartObj.regrindUsed ? selectedMoldedPartObj.regrindUsed : false);
      setPartIsOutsourced(selectedMoldedPartObj.isOutsourced ? selectedMoldedPartObj.isOutsourced : false);
      setGlassIsUsed(selectedMoldedPartObj.glassUsed ? selectedMoldedPartObj.glassUsed : false);
      setAnnealingSelect(selectedMoldedPartObj.annealing ? selectedMoldedPartObj.annealing : 'NULL');
      setInspectionSelect(selectedMoldedPartObj.inspection ? selectedMoldedPartObj.inspection : 'NULL');
      setPackagingSelect(selectedMoldedPartObj.packaging ? selectedMoldedPartObj.packaging : 'NULL');
      setInsertMoldedSelect(selectedMoldedPartObj.insertMolded === true || selectedMoldedPartObj.insertMolded === 'YES' ? 'YES' : 'NO');
      setSideActionsValue(selectedMoldedPartObj.sideActions ? selectedMoldedPartObj.sideActions : '');
      setEjectorSystemSelect(selectedMoldedPartObj.ejectorSystem ? selectedMoldedPartObj.ejectorSystem : 'NULL');
      setCavitiesPerMoldA(selectedMoldedPartObj.cavitiesPerMoldA ? selectedMoldedPartObj.cavitiesPerMoldA : '');
      setCavitiesPerPartA(selectedMoldedPartObj.cavitiesPerPartA ? selectedMoldedPartObj.cavitiesPerPartA : '');
      setNumOfDiffPartsA(selectedMoldedPartObj.numOfDiffPartsA ? selectedMoldedPartObj.numOfDiffPartsA : '');
      setRunnerPercentageA(selectedMoldedPartObj.runnerPercentageA != null ? selectedMoldedPartObj.runnerPercentageA : '30');
      setGateStyleSelectA(selectedMoldedPartObj.gateStyleA ? selectedMoldedPartObj.gateStyleA : 'NULL');
      setGateCuttingSelectA(selectedMoldedPartObj.gateCuttingA ? selectedMoldedPartObj.gateCuttingA : 'NULL');
      setToolingToolTypeSelectA(selectedMoldedPartObj.toolTypeA ? selectedMoldedPartObj.toolTypeA : 'NULL');
      setToolCostA(selectedMoldedPartObj.toolCostA ? selectedMoldedPartObj.toolCostA : '');
      setTuningTextureCostA(selectedMoldedPartObj.tuningTextureCostA ? selectedMoldedPartObj.tuningTextureCostA : '');
      setBoatShipmentCostA(selectedMoldedPartObj.boatShipmentCostA ? selectedMoldedPartObj.boatShipmentCostA : '');
      setAirShipmentCostA(selectedMoldedPartObj.airShipmentCostA ? selectedMoldedPartObj.airShipmentCostA : '');
      setLeadTimeWeeksA(selectedMoldedPartObj.leadTimeWeeksA ? selectedMoldedPartObj.leadTimeWeeksA : '');
      setToolDescSelectA(selectedMoldedPartObj.toolDescA ? selectedMoldedPartObj.toolDescA : 'NULL');
      setLocationSelectA(selectedMoldedPartObj.toolLocationA ? selectedMoldedPartObj.toolLocationA : 'NULL');
      setPressTypeSelectA(selectedMoldedPartObj.pressTypeA ? selectedMoldedPartObj.pressTypeA : 'NULL');
      setShotsPerHourA(selectedMoldedPartObj.shotsPerHourA ? selectedMoldedPartObj.shotsPerHourA : '');
      setCycleTimeA(selectedMoldedPartObj.shotsPerHourA && !isNaN(parseFloat(selectedMoldedPartObj.shotsPerHourA)) ?
        parseFloat(selectedMoldedPartObj.shotsPerHourA) ?
          `${round(3600 / parseFloat(selectedMoldedPartObj.shotsPerHourA), 2)}` :
          '0' :
        '',
      );
      setPressSizeSelectA(selectedMoldedPartObj.pressSizeA ? selectedMoldedPartObj.pressSizeA : 'NULL');
      setCavitiesPerMoldB(selectedMoldedPartObj.hasOptionB && selectedMoldedPartObj.cavitiesPerMoldB ? selectedMoldedPartObj.cavitiesPerMoldB : '');
      setCavitiesPerPartB(selectedMoldedPartObj.hasOptionB && selectedMoldedPartObj.cavitiesPerPartB ? selectedMoldedPartObj.cavitiesPerPartB : '');
      setNumOfDiffPartsB(selectedMoldedPartObj.hasOptionB && selectedMoldedPartObj.numOfDiffPartsB ? selectedMoldedPartObj.numOfDiffPartsB : '');
      setRunnerPercentageB(selectedMoldedPartObj.hasOptionB && selectedMoldedPartObj.runnerPercentageB != null ? selectedMoldedPartObj.runnerPercentageB : '30');
      setGateStyleSelectB(selectedMoldedPartObj.hasOptionB && selectedMoldedPartObj.gateStyleB ? selectedMoldedPartObj.gateStyleB : 'NULL');
      setGateCuttingSelectB(selectedMoldedPartObj.hasOptionB && selectedMoldedPartObj.gateCuttingB ? selectedMoldedPartObj.gateCuttingB : 'NULL');
      setToolingToolTypeSelectB(selectedMoldedPartObj.hasOptionB && selectedMoldedPartObj.toolTypeB ? selectedMoldedPartObj.toolTypeB : 'NULL');
      setToolCostB(selectedMoldedPartObj.hasOptionB && selectedMoldedPartObj.toolCostB ? selectedMoldedPartObj.toolCostB : '');
      setTuningTextureCostB(selectedMoldedPartObj.hasOptionB && selectedMoldedPartObj.tuningTextureCostB ? selectedMoldedPartObj.tuningTextureCostB : '');
      setBoatShipmentCostB(selectedMoldedPartObj.hasOptionB && selectedMoldedPartObj.boatShipmentCostB ? selectedMoldedPartObj.boatShipmentCostB : '');
      setAirShipmentCostB(selectedMoldedPartObj.hasOptionB && selectedMoldedPartObj.airShipmentCostB ? selectedMoldedPartObj.airShipmentCostB : '');
      setLeadTimeWeeksB(selectedMoldedPartObj.hasOptionB && selectedMoldedPartObj.leadTimeWeeksB ? selectedMoldedPartObj.leadTimeWeeksB : '');
      setToolDescSelectB(selectedMoldedPartObj.hasOptionB && selectedMoldedPartObj.toolDescB ? selectedMoldedPartObj.toolDescB : 'NULL');
      setLocationSelectB(selectedMoldedPartObj.hasOptionB && selectedMoldedPartObj.toolLocationB ? selectedMoldedPartObj.toolLocationB : 'NULL');
      setPressTypeSelectB(selectedMoldedPartObj.hasOptionB && selectedMoldedPartObj.pressTypeB ? selectedMoldedPartObj.pressTypeB : 'NULL');
      setShotsPerHourB(selectedMoldedPartObj.hasOptionB && selectedMoldedPartObj.shotsPerHourB ? selectedMoldedPartObj.shotsPerHourB : '');
      setCycleTimeB(selectedMoldedPartObj.shotsPerHourB && !isNaN(parseFloat(selectedMoldedPartObj.shotsPerHourB)) ?
        parseFloat(selectedMoldedPartObj.shotsPerHourB) ?
          `${round(3600 / parseFloat(selectedMoldedPartObj.shotsPerHourB), 2)}` :
          '0' :
        '',
      );
      setPressSizeSelectB(selectedMoldedPartObj.hasOptionB && selectedMoldedPartObj.pressSizeB ? selectedMoldedPartObj.pressSizeB : 'NULL');
      setHasOptionB(selectedMoldedPartObj.hasOptionB ? selectedMoldedPartObj.hasOptionB : false);
    }
  }, [selectedMoldedPartIndex, moldedPartsValue]);
  
  const toolingAssignment = quoteData.assignments.find(o => o.types.includes('TOOLING_ESTIMATOR'));
  const toolingAssignedUserId = toolingAssignment ? toolingAssignment.userId : null;
  const designAssignment = quoteData.assignments.find(o => o.types.includes('DESIGN_ESTIMATOR'));
  const designAssignedUserId = designAssignment ? designAssignment.userId : null;
  const feasibilityAssignment = quoteData.assignments.find(o => o.types.includes('FEASIBILITY'));
  const feasibilityAssignmentUserId = feasibilityAssignment ? feasibilityAssignment.userId : null;
  
  const disableAllInputs = quoteData.status !== 'SANDBOX' && (quoteData.holdUserId !== userId || quoteData.isNotActive || !['IN_PROGRESS', 'FEASIBILITY'].includes(quoteData.status) ||
    (!(appRoles.includes(CONFIG.ROLE_MAPPINGS.DESIGN_ESTIMATOR) && userId === designAssignedUserId) &&
      !(appRoles.includes(CONFIG.ROLE_MAPPINGS.TOOLING_ESTIMATOR) && userId === toolingAssignedUserId) &&
      !(appRoles.includes(CONFIG.ROLE_MAPPINGS.FEASIBILITY) && userId === feasibilityAssignmentUserId)));
  
  const disableDesignInputs = quoteData.status !== 'SANDBOX' && (quoteData.holdUserId !== userId || !['IN_PROGRESS', 'FEASIBILITY'].includes(quoteData.status) ||
    (!(appRoles.includes(CONFIG.ROLE_MAPPINGS.DESIGN_ESTIMATOR) && userId === designAssignedUserId) &&
      !(appRoles.includes(CONFIG.ROLE_MAPPINGS.FEASIBILITY) && userId === feasibilityAssignmentUserId) &&
      !(appRoles.includes(CONFIG.ROLE_MAPPINGS.TOOLING_ESTIMATOR) && userId === toolingAssignedUserId)));
  
  const noMoldedPartSelected = !moldedPartsValue || !moldedPartsValue.length || selectedMoldedPartIndex == null ||
    selectedMoldedPartIndex >= moldedPartsValue.length;
  
  const updateMoldedPartsValue = newHasOptionB => {
    const thisHasOptionB = newHasOptionB != null ? newHasOptionB : hasOptionB;
    setMoldedPartsValue(prevState => {
      if(!prevState.length) return prevState;
      const newMoldedPartsValue = cloneDeep(prevState);
      newMoldedPartsValue[selectedMoldedPartIndex].partWeightDerived = derivedWeightSelect;
      newMoldedPartsValue[selectedMoldedPartIndex].partWeight = partWeightValue;
      newMoldedPartsValue[selectedMoldedPartIndex].regrindPercentage = regrindPercentageValue;
      newMoldedPartsValue[selectedMoldedPartIndex].outsourcedCost = outsourcedCostValue;
      newMoldedPartsValue[selectedMoldedPartIndex].regrindUsed = regrindIsUsed;
      newMoldedPartsValue[selectedMoldedPartIndex].isOutsourced = partIsOutsourced;
      newMoldedPartsValue[selectedMoldedPartIndex].glassUsed = glassIsUsed;
      newMoldedPartsValue[selectedMoldedPartIndex].createMaterialBlends = createMaterialBlends;
      newMoldedPartsValue[selectedMoldedPartIndex].annealing = annealingSelect;
      newMoldedPartsValue[selectedMoldedPartIndex].inspection = inspectionSelect;
      newMoldedPartsValue[selectedMoldedPartIndex].packaging = packagingSelect;
      newMoldedPartsValue[selectedMoldedPartIndex].insertMolded = insertMoldedSelect;
      newMoldedPartsValue[selectedMoldedPartIndex].sideActions = sideActionsValue;
      newMoldedPartsValue[selectedMoldedPartIndex].ejectorSystem = ejectorSystemSelect;
      newMoldedPartsValue[selectedMoldedPartIndex].cavitiesPerMoldA = cavitiesPerMoldA;
      newMoldedPartsValue[selectedMoldedPartIndex].cavitiesPerPartA = cavitiesPerPartA;
      newMoldedPartsValue[selectedMoldedPartIndex].numOfDiffPartsA = numOfDiffPartsA;
      newMoldedPartsValue[selectedMoldedPartIndex].runnerPercentageA = runnerPercentageA;
      newMoldedPartsValue[selectedMoldedPartIndex].gateStyleA = gateStyleSelectA;
      newMoldedPartsValue[selectedMoldedPartIndex].gateCuttingA = gateCuttingSelectA;
      newMoldedPartsValue[selectedMoldedPartIndex].toolTypeA = toolingToolTypeSelectA;
      newMoldedPartsValue[selectedMoldedPartIndex].toolCostA = toolCostA;
      newMoldedPartsValue[selectedMoldedPartIndex].tuningTextureCostA = tuningTextureCostA;
      newMoldedPartsValue[selectedMoldedPartIndex].boatShipmentCostA = boatShipmentCostA;
      newMoldedPartsValue[selectedMoldedPartIndex].airShipmentCostA = airShipmentCostA;
      newMoldedPartsValue[selectedMoldedPartIndex].leadTimeWeeksA = leadTimeWeeksA;
      newMoldedPartsValue[selectedMoldedPartIndex].toolDescA = toolDescSelectA;
      newMoldedPartsValue[selectedMoldedPartIndex].toolLocationA = locationSelectA;
      newMoldedPartsValue[selectedMoldedPartIndex].pressTypeA = pressTypeSelectA;
      newMoldedPartsValue[selectedMoldedPartIndex].shotsPerHourA = shotsPerHourA;
      newMoldedPartsValue[selectedMoldedPartIndex].pressSizeA = pressSizeSelectA;
      newMoldedPartsValue[selectedMoldedPartIndex].cavitiesPerMoldB = thisHasOptionB ? cavitiesPerMoldB : null;
      newMoldedPartsValue[selectedMoldedPartIndex].cavitiesPerPartB = thisHasOptionB ? cavitiesPerPartB : null;
      newMoldedPartsValue[selectedMoldedPartIndex].numOfDiffPartsB = thisHasOptionB ? numOfDiffPartsB : null;
      newMoldedPartsValue[selectedMoldedPartIndex].runnerPercentageB = thisHasOptionB ? runnerPercentageB : null;
      newMoldedPartsValue[selectedMoldedPartIndex].gateStyleB = thisHasOptionB ? gateStyleSelectB : null;
      newMoldedPartsValue[selectedMoldedPartIndex].gateCuttingB = thisHasOptionB ? gateCuttingSelectB : null;
      newMoldedPartsValue[selectedMoldedPartIndex].toolTypeB = thisHasOptionB ? toolingToolTypeSelectB : null;
      newMoldedPartsValue[selectedMoldedPartIndex].toolCostB = thisHasOptionB ? toolCostB : null;
      newMoldedPartsValue[selectedMoldedPartIndex].tuningTextureCostB = thisHasOptionB ? tuningTextureCostB : null;
      newMoldedPartsValue[selectedMoldedPartIndex].boatShipmentCostB = thisHasOptionB ? boatShipmentCostB : null;
      newMoldedPartsValue[selectedMoldedPartIndex].airShipmentCostB = thisHasOptionB ? airShipmentCostB : null;
      newMoldedPartsValue[selectedMoldedPartIndex].leadTimeWeeksB = thisHasOptionB ? leadTimeWeeksB : null;
      newMoldedPartsValue[selectedMoldedPartIndex].toolDescB = thisHasOptionB ? toolDescSelectB : null;
      newMoldedPartsValue[selectedMoldedPartIndex].toolLocationB = thisHasOptionB ? locationSelectB : null;
      newMoldedPartsValue[selectedMoldedPartIndex].pressTypeB = thisHasOptionB ? pressTypeSelectB : null;
      newMoldedPartsValue[selectedMoldedPartIndex].shotsPerHourB = thisHasOptionB ? shotsPerHourB : null;
      newMoldedPartsValue[selectedMoldedPartIndex].pressSizeB = thisHasOptionB ? pressSizeSelectB : null;
      newMoldedPartsValue[selectedMoldedPartIndex].hasOptionB = thisHasOptionB;
      return newMoldedPartsValue;
    });
  };
  
  const addPartComment = () => {
    updateMoldedPartsValue();
    setMoldedPartsValue(prevState => {
      const newMoldedPartsValue = cloneDeep(prevState);
      let partComments = newMoldedPartsValue[selectedMoldedPartIndex].partComments;
      if(!partComments) partComments = [];
      partComments.push({
        userId,
        username: loggedInUser,
        time: Date.now(),
        comment: newPartCommentValue.trim(),
      });
      newMoldedPartsValue[selectedMoldedPartIndex].partComments = partComments;
      return newMoldedPartsValue;
    });
    setNewPartCommentValue('');
  };
  
  const deletePartComment = time => {
    updateMoldedPartsValue();
    setMoldedPartsValue(prevState => {
      const newMoldedPartsValue = cloneDeep(prevState);
      const commentIndex = newMoldedPartsValue?.[selectedMoldedPartIndex]?.partComments.findIndex(o => o.userId === userId && o.time === time);
      if(commentIndex < 0) return newMoldedPartsValue;
      newMoldedPartsValue[selectedMoldedPartIndex].partComments[commentIndex].deleted = 'X';
      return newMoldedPartsValue;
    });
  };
  
  // convert text number fields to numbers
  const numToolCostA = !isNaN(parseFloat(toolCostA)) ? parseFloat(toolCostA) : null;
  const numToolCostB = !isNaN(parseFloat(toolCostB)) ? parseFloat(toolCostB) : null;
  const numTuningTextureCostA = !isNaN(parseFloat(tuningTextureCostA)) ? parseFloat(tuningTextureCostA) : null;
  const numTuningTextureCostB = !isNaN(parseFloat(tuningTextureCostB)) ? parseFloat(tuningTextureCostB) : null;
  const numBoatShipmentCostA = !isNaN(parseFloat(boatShipmentCostA)) ? parseFloat(boatShipmentCostA) : null;
  const numBoatShipmentCostB = !isNaN(parseFloat(boatShipmentCostB)) ? parseFloat(boatShipmentCostB) : null;
  const numAirShipmentCostA = !isNaN(parseFloat(airShipmentCostA)) ? parseFloat(airShipmentCostA) : null;
  const numAirShipmentCostB = !isNaN(parseFloat(airShipmentCostB)) ? parseFloat(airShipmentCostB) : null;
  const numPartWeightValue = !isNaN(parseFloat(partWeightValue)) ? parseFloat(partWeightValue) : null;
  const numCavitiesPerMoldA = !isNaN(parseFloat(cavitiesPerMoldA)) ? parseFloat(cavitiesPerMoldA) : null;
  const numCavitiesPerMoldB = !isNaN(parseFloat(cavitiesPerMoldB)) ? parseFloat(cavitiesPerMoldB) : null;
  const numRunnerPercentageA = !isNaN(parseFloat(runnerPercentageA)) ? parseFloat(runnerPercentageA) : null;
  const numRunnerPercentageB = !isNaN(parseFloat(runnerPercentageB)) ? parseFloat(runnerPercentageB) : null;
  const numRunnerWeightA = !isNaN(parseFloat(runnerWeightA)) ? parseFloat(runnerWeightA) : null;
  const numRunnerWeightB = !isNaN(parseFloat(runnerWeightB)) ? parseFloat(runnerWeightB) : null;
  const numShotsPerHourA = !isNaN(parseFloat(shotsPerHourA)) ? parseFloat(shotsPerHourA) : null;
  const numShotsPerHourB = !isNaN(parseFloat(shotsPerHourB)) ? parseFloat(shotsPerHourB) : null;
  const numRegrindPercentageValue = !isNaN(parseFloat(regrindPercentageValue)) ? parseFloat(regrindPercentageValue) : null;
  const numOutsourcedCostValue = !isNaN(parseFloat(outsourcedCostValue)) ? parseFloat(outsourcedCostValue) : null;
  const numCavitiesPerPartA = !isNaN(parseFloat(cavitiesPerPartA)) ? parseFloat(cavitiesPerPartA) : null;
  const numCavitiesPerPartB = !isNaN(parseFloat(cavitiesPerPartB)) ? parseFloat(cavitiesPerPartB) : null;
  
  const pressSizeRateOptionsA = currentRates.filter(o => o.rateType === 'PRESS' &&
    o.orgCode === convertOrgCode(locationSelectA) && o.pressType === pressTypeSelectA).map(o => o.pressSize);
  const pressSizeRateOptionsB = currentRates.filter(o => o.rateType === 'PRESS' &&
    o.orgCode === convertOrgCode(locationSelectB) && o.pressType === pressTypeSelectB).map(o => o.pressSize);
  const pressSizeOptionsA = pressSizeLov.filter(o => pressSizeRateOptionsA.includes(o.code) && o.type === pressTypeSelectA);
  const pressSizeOptionsB = pressSizeLov.filter(o => pressSizeRateOptionsB.includes(o.code) && o.type === pressTypeSelectB);
  
  const calculationsA = getMoldingCalculations({
    rates: currentRates,
    location: locationSelectA,
    pressType: pressTypeSelectA,
    pressSize: pressSizeSelectA,
    gateCuttingType: gateCuttingSelectA,
    annealingType: annealingSelect,
    shotsPerHour: numShotsPerHourA,
    cavitiesPerMold: numCavitiesPerMoldA,
    numOfDiffParts: numOfDiffPartsA,
    inspectionType: inspectionSelect,
    packingType: packagingSelect,
    glassIsUsed,
    partWeight: numPartWeightValue,
    materials: moldedPartsValue?.[selectedMoldedPartIndex]?.materials,
    monthlyVolume: quoteData.monthlyVolume,
    regrindIsUsed,
    partIsOutsourced,
    cavitiesPerPart: numCavitiesPerPartA,
    runnerPercentage: numRunnerPercentageA,
    regrindPercentage: numRegrindPercentageValue,
    outsourcedCost: numOutsourcedCostValue,
    partType: quoteData.partType,
    toolType: toolingToolTypeSelectA,
    toolCost: numToolCostA,
    tuningTextureCost: numTuningTextureCostA,
    boatShipmentCost: numBoatShipmentCostA,
    airShipmentCost: numAirShipmentCostA,
    packagingCosts: packagingCosts.filter(o => o.associatedPart === moldedPartsValue?.[selectedMoldedPartIndex]?.partDescription),
    productType: quoteData.productType,
    purchasedPartCosts: quoteData.newPurchasedParts,
  });
  
  const somePartHasOptionB = moldedPartsValue.some(o => o.hasOptionB);
  const calculationsB = getMoldingCalculations({
    rates: currentRates,
    location: locationSelectB ? locationSelectB : somePartHasOptionB ? locationSelectA : null,
    pressType: pressTypeSelectB ? pressTypeSelectB : somePartHasOptionB ? pressTypeSelectA : null,
    pressSize: pressSizeSelectB ? pressSizeSelectB : somePartHasOptionB ? pressSizeSelectA : null,
    gateCuttingType: gateCuttingSelectB ? gateCuttingSelectB : somePartHasOptionB ? gateCuttingSelectA : null,
    annealingType: annealingSelect,
    shotsPerHour: numShotsPerHourB ? numShotsPerHourB : somePartHasOptionB ? numShotsPerHourA : null,
    cavitiesPerMold: numCavitiesPerMoldB ? numCavitiesPerMoldB : somePartHasOptionB ? numCavitiesPerMoldA : null,
    numOfDiffParts: numOfDiffPartsB ? numOfDiffPartsB : somePartHasOptionB ? numOfDiffPartsA : null,
    inspectionType: inspectionSelect,
    packingType: packagingSelect,
    glassIsUsed,
    createMaterialBlends,
    partWeight: numPartWeightValue,
    materials: moldedPartsValue?.[selectedMoldedPartIndex]?.materials,
    monthlyVolume: quoteData.monthlyVolume,
    regrindIsUsed,
    partIsOutsourced,
    cavitiesPerPart: numCavitiesPerPartB ? numCavitiesPerPartB : somePartHasOptionB ? numCavitiesPerPartA : null,
    runnerPercentage: numRunnerPercentageB ? numRunnerPercentageB : somePartHasOptionB ? numRunnerPercentageA : null,
    regrindPercentage: numRegrindPercentageValue,
    outsourcedCost: numOutsourcedCostValue,
    partType: quoteData.partType,
    toolType: toolingToolTypeSelectB ? toolingToolTypeSelectB : somePartHasOptionB ? toolingToolTypeSelectA : null,
    toolCost: numToolCostB ? numToolCostB : somePartHasOptionB ? numToolCostA : null,
    tuningTextureCost: numTuningTextureCostB ? numTuningTextureCostB : somePartHasOptionB ? numTuningTextureCostA : null,
    boatShipmentCost: numBoatShipmentCostB ? numBoatShipmentCostB : somePartHasOptionB ? numBoatShipmentCostA : null,
    airShipmentCost: numAirShipmentCostB ? numAirShipmentCostB : somePartHasOptionB ? numAirShipmentCostA : null,
    packagingCosts: packagingCosts.filter(o => o.associatedPart === moldedPartsValue?.[selectedMoldedPartIndex]?.partDescription),
    productType: quoteData.productType,
    purchasedPartCosts: quoteData.newPurchasedParts,
  });
  
  const InjectionMoldingCostsTable = ({ location, cavitiesPerMold, calculations }) => (
    <Table size="sm" style={{ marginBottom: 0 }}>
      <tbody>
      <tr>
        <td style={styles.noCellBorder}>Press Location</td>
        <td style={styles.noCellBorder}>
          {location !== 'NULL' ? locationLov.find(o => o.code === location)?.name : ''}
        </td>
      </tr>
      <tr>
        <td style={styles.noCellBorder}>No of Cavities</td>
        <td style={styles.noCellBorder}>{cavitiesPerMold}</td>
      </tr>
      <tr>
        <td style={styles.noCellBorder}>Molding Costs</td>
        <td style={styles.noCellBorder}>
          {calculations?.moldingCost ? `$${calculations.moldingCost.toFixed(5)}` : ''}
        </td>
      </tr>
      <tr>
        <td style={styles.noCellBorder}>Glass</td>
        <td style={styles.noCellBorder}>
          {calculations?.glassCost ? `$${calculations.glassCost.toFixed(5)}` : ''}
        </td>
      </tr>
      <tr>
        <td style={styles.noCellBorder}>Insert Component Cost</td>
        <td style={styles.noCellBorder}>
          {calculations?.insertPartCosts ? `$${calculations.insertPartCosts.toFixed(5)}` : ''}
        </td>
      </tr>
      <tr>
        <td style={styles.noCellBorder}>Total Injection Molding Cost</td>
        <td style={styles.noCellBorder}>
          {calculations?.totalInjectionMoldingCost ? `$${calculations.totalInjectionMoldingCost.toFixed(5)}` : ''}
        </td>
      </tr>
      <tr>
        <td style={styles.noCellBorder}>Monthly Run Hours @ 85% Efficient</td>
        <td style={styles.noCellBorder}>
          {calculations?.monthlyRunHours ? calculations.monthlyRunHours.toFixed(5) : ''}
        </td>
      </tr>
      <tr>
        <td style={styles.noCellBorder}>Monthly Material Usage</td>
        <td style={styles.noCellBorder}>
          {calculations?.monthlyMaterialUsageLbs ? calculations.monthlyMaterialUsageLbs.toFixed(5) : ''}
        </td>
      </tr>
      {
        !regrindIsUsed ?
          <>
            <tr>
              <td style={{ ...styles.noCellBorder, paddingLeft: 15 }}>Part Usage (lbs)</td>
              <td style={styles.noCellBorder}>
                {calculations?.materialPartUsageLbs ? calculations.materialPartUsageLbs.toFixed(5) : ''}
              </td>
            </tr>
            <tr>
              <td style={{ ...styles.noCellBorder, paddingLeft: 15 }}>Runner Scrap (lbs)</td>
              <td style={styles.noCellBorder}>
                {calculations?.runnerScrapMaterialLbs ? calculations.runnerScrapMaterialLbs.toFixed(5) : ''}
              </td>
            </tr>
            <tr>
              <td style={{ ...styles.noCellBorder, paddingLeft: 15 }}>Runner Scrap Cost</td>
              <td style={styles.noCellBorder}>
                {calculations?.runnerScrapCost ? `$${calculations.runnerScrapCost.toFixed(5)}` : ''}
              </td>
            </tr>
          </> :
          null
      }
      </tbody>
    </Table>
  );
  
  const PostProcessingCostsTable = ({ location, calculations }) => (
    <Table size="sm" style={{ marginBottom: 0 }}>
      <tbody>
      <tr>
        <td style={styles.noCellBorder}>Press Location</td>
        <td style={styles.noCellBorder}>
          {location !== 'NULL' ? locationLov.find(o => o.code === location)?.name : ''}
        </td>
      </tr>
      <tr>
        <td style={styles.noCellBorder}>Gate Cutting</td>
        <td style={styles.noCellBorder}>
          {calculations?.rates?.gateCutting ? `$${calculations.rates.gateCutting.toFixed(5)}` : ''}
        </td>
      </tr>
      <tr>
        <td style={styles.noCellBorder}>Annealing</td>
        <td style={styles.noCellBorder}>
          {calculations?.rates?.annealing ? `$${calculations.rates.annealing.toFixed(5)}` : ''}
        </td>
      </tr>
      <tr>
        <td style={styles.noCellBorder}>Inspection</td>
        <td style={styles.noCellBorder}>
          {calculations?.rates?.inspection ? `$${calculations.rates.inspection.toFixed(5)}` : ''}
        </td>
      </tr>
      <tr>
        <td style={styles.noCellBorder}>Measuring & Packing</td>
        <td style={styles.noCellBorder}>
          {calculations?.rates?.packing ? `$${calculations.rates.packing.toFixed(5)}` : ''}
        </td>
      </tr>
      <tr>
        <td style={styles.noCellBorder}>Total Post Processing</td>
        <td style={styles.noCellBorder}>
          {calculations?.totalPostProcessingCost ? `$${calculations.totalPostProcessingCost.toFixed(5)}` : ''}
        </td>
      </tr>
      </tbody>
    </Table>
  );
  
  const TotalStandardCostsTable = ({ location, calculations }) => (
    <Table size="sm" style={{ marginBottom: 0 }}>
      <tbody>
      <tr>
        <td style={styles.noCellBorder}>Press Location</td>
        <td style={styles.noCellBorder}>
          {location !== 'NULL' ? locationLov.find(o => o.code === location)?.name : ''}
        </td>
      </tr>
      <tr>
        <td style={styles.noCellBorder}>Total Material</td>
        <td style={styles.noCellBorder}>
          {calculations?.totalMaterialCost ? `$${calculations.totalMaterialCost.toFixed(5)}` : ''}
        </td>
      </tr>
      <tr>
        <td style={styles.noCellBorder}>Total Injection Molding</td>
        <td style={styles.noCellBorder}>
          {calculations?.totalInjectionMoldingCost ? `$${calculations.totalInjectionMoldingCost.toFixed(5)}` : ''}
        </td>
      </tr>
      <tr>
        <td style={styles.noCellBorder}>Total Post Processing</td>
        <td style={styles.noCellBorder}>
          {calculations?.totalPostProcessingCost ? `$${calculations.totalPostProcessingCost.toFixed(5)}` : ''}
        </td>
      </tr>
      <tr>
        <td style={styles.noCellBorder}>Total Packaging</td>
        <td style={styles.noCellBorder}>
          {calculations?.totalPackagingCost ? `$${calculations.totalPackagingCost.toFixed(5)}` : ''}
        </td>
      </tr>
      <tr>
        <td style={styles.noCellBorder}/>
        <td style={styles.noCellBorder}/>
      </tr>
      <tr>
        <td style={styles.noCellBorder}>Total Production Cost</td>
        <td style={styles.noCellBorder}>
          {calculations?.totalProductionCost ? `$${calculations.totalProductionCost.toFixed(5)}` : ''}
        </td>
      </tr>
      <tr>
        <td style={styles.noCellBorder}>Auxiliary Dept. Coefficient</td>
        <td style={styles.noCellBorder}>
          {calculations?.auxDeptCoefficient ? `$${calculations.auxDeptCoefficient.toFixed(5)}` : ''}
        </td>
      </tr>
      <tr>
        <td style={styles.noCellBorder}>Technical Cost</td>
        <td style={styles.noCellBorder}>
          {calculations?.technicalCost ? `$${calculations.technicalCost.toFixed(5)}` : ''}
        </td>
      </tr>
      <tr>
        <td style={styles.noCellBorder}>Yield Cost</td>
        <td style={styles.noCellBorder}>
          {calculations?.yieldCost ? `$${calculations.yieldCost.toFixed(5)}` : ''}
        </td>
      </tr>
      <tr>
        <td style={styles.noCellBorder}>Mold Repair</td>
        <td style={styles.noCellBorder}>
          {calculations?.rates?.toolRepair ? `$${calculations.rates.toolRepair.toFixed(5)}` : ''}
        </td>
      </tr>
      <tr>
        <td style={styles.noCellBorder}/>
        <td style={styles.noCellBorder}/>
      </tr>
      <tr>
        <td style={styles.noCellBorder}>Total Standard Cost</td>
        <td style={styles.noCellBorder}>
          {calculations?.totalStandardCost ? `$${calculations.totalStandardCost.toFixed(5)}` : ''}
        </td>
      </tr>
      </tbody>
    </Table>
  );
  
  return (
    <div style={styles.mainTabContainer} onMouseLeave={() => updateMoldedPartsValue()}>
      {
        moldedPartsValue && moldedPartsValue.length ?
          moldedPartsValue.map((part, index) => {
            const newPartObj = quoteNewPartNumbers?.find(o => o.nodeId === part.bomId);
            return (
              <div
                key={index}
                style={{
                  ...styles.moldedPartCell,
                  backgroundColor: index === selectedMoldedPartIndex ?
                    COLORS.paleGreen : null,
                }}
                onClick={() => {
                  updateMoldedPartsValue();
                  setSelectedMoldedPartIndex(index);
                  if(moldedPartsValue[index].runnerPercentageA != null && !isNaN(moldedPartsValue[index].runnerPercentageA)) {
                    const totalPartWeightA = moldedPartsValue[index].cavitiesPerMoldA && moldedPartsValue[index].partWeight ?
                      moldedPartsValue[index].partWeight * moldedPartsValue[index].cavitiesPerMoldA : null;
                    if(!totalPartWeightA) {
                      setRunnerWeightA('');
                      return;
                    }
                    const tempRunnerWeightA = round((totalPartWeightA * moldedPartsValue[index].runnerPercentageA) / (100 - moldedPartsValue[index].runnerPercentageA), 1);
                    setRunnerWeightA(`${tempRunnerWeightA}`);
                  }
                  else setRunnerWeightA('');
                  if(moldedPartsValue[index].runnerPercentageB != null && !isNaN(moldedPartsValue[index].runnerPercentageB)) {
                    const totalPartWeightB = moldedPartsValue[index].cavitiesPerMoldB && moldedPartsValue[index].partWeight ?
                      moldedPartsValue[index].partWeight * moldedPartsValue[index].cavitiesPerMoldB : null;
                    if(!totalPartWeightB) {
                      setRunnerWeightB('');
                      return;
                    }
                    const tempRunnerWeightB = round((totalPartWeightB * moldedPartsValue[index].runnerPercentageB) / (100 - moldedPartsValue[index].runnerPercentageB), 1);
                    setRunnerWeightB(`${tempRunnerWeightB}`);
                  }
                  else setRunnerWeightB('');
                }}
              >
                <h5
                  style={{
                    ...styles.moldedPartCellText,
                    color: quoteMissingAttributes.some(str => str.startsWith(`MOLDED|${index}`)) ?
                      COLORS.red : null,
                  }}
                >
                  {`${part.partDescription ? part.partDescription : 'Unnamed part'}${newPartObj ? ` (${newPartObj.partNumber})` : ''}`}
                </h5>
              </div>
            );
          }) :
          <div
            style={{
              ...styles.moldedPartCell,
              cursor: 'default',
            }}
          >
            <h5
              style={{
                ...styles.moldedPartCellText,
                backgroundColor: quoteMissingAttributes.includes('MOLDED|moldedParts') ?
                  COLORS.red : null,
              }}
            >
              No molded parts...
            </h5>
          </div>
      }
      <div style={{ marginBottom: 8 }}/>
      <Tabs
        defaultActiveKey="DESIGN"
        onSelect={() => updateMoldedPartsValue()}
      >
        <Tab eventKey="DESIGN" title="Design">
          <div style={{ ...styles.tabContainer, marginBottom: 8 }}>
            <h5 style={{ ...styles.lineTitle, textAlign: 'left', marginTop: 20, marginBottom: 20 }}>
              <span style={{ ...styles.lineTitleText, backgroundColor: COLORS.white }}>
                Material
              </span>
            </h5>
            <div style={styles.tabContainerRow}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Create Phantom Blends</p>
              </div>
              <Form.Check
                type="checkbox"
                checked={createMaterialBlends}
                onChange={e => setCreateMaterialBlends(e.target.checked)}
                label=""
                disabled={noMoldedPartSelected || disableAllInputs || disableDesignInputs}
              />
            </div>
            <Table size="sm" style={{ marginBottom: 15 }}>
              <tbody>
              <tr style={{ fontWeight: 'bold' }}>
                <td>Part Number</td>
                <td>Type</td>
                <td>Blend Percentage</td>
                <td>Description</td>
                <td>New</td>
                <td>Oracle Cost</td>
                <td>Cost</td>
                <td>Last PO Price</td>
                <td>Last PO Date</td>
              </tr>
              {moldedPartsValue.length && moldedPartsValue?.[selectedMoldedPartIndex]?.materials?.length ?
                moldedPartsValue[selectedMoldedPartIndex].materials.map((matObj, index) => {
                  const newMaterialObj = quoteNewPartNumbers?.find(o => o.nodeId === matObj.bomId);
                  return (
                    <tr
                      key={index}
                      style={{ color: quoteMissingAttributes.includes(`MOLDED|${selectedMoldedPartIndex}|materials`) ? COLORS.red : null }}
                    >
                      <td
                        style={{ verticalAlign: 'middle' }}>{newMaterialObj ? newMaterialObj.partNumber : matObj.materialNumber}</td>
                      <td style={{ verticalAlign: 'middle' }}>{matObj.materialType}</td>
                      <td style={{ verticalAlign: 'middle' }}>{matObj.blendPercentage}</td>
                      <td style={{ verticalAlign: 'middle' }}>{matObj.description}</td>
                      <td style={{ verticalAlign: 'middle' }}>{matObj.isNew ? 'YES' : 'NO'}</td>
                      <td
                        style={{ verticalAlign: 'middle' }}>{matObj.oracleMaterialCost != null ? `$${matObj.oracleMaterialCost}` : ''}</td>
                      <td
                        style={{ verticalAlign: 'middle' }}>{matObj.materialCost != null ? `$${matObj.materialCost}` : ''}</td>
                      <td style={{ verticalAlign: 'middle' }}>{matObj.lastPoPrice}</td>
                      <td style={{ verticalAlign: 'middle' }}>{matObj.lastPoDate}</td>
                    </tr>
                  );
                }) :
                <tr
                  style={{ color: quoteMissingAttributes.includes(`MOLDED|${selectedMoldedPartIndex}|materials`) ? COLORS.red : null }}
                >
                  <td colSpan="7">
                    No materials added to the BOM
                  </td>
                </tr>
              }
              </tbody>
            </Table>
            <h5 style={{ ...styles.lineTitle, textAlign: 'left', marginTop: 20, marginBottom: 20 }}>
                        <span style={{ ...styles.lineTitleText, backgroundColor: COLORS.white }}>
                          Part Costing Information
                        </span>
            </h5>
            <div style={styles.tabContainerRow}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Part Weight Derived</p>
              </div>
              <Form.Select
                as="select"
                style={{
                  ...(quoteMissingAttributes.includes(`MOLDED|${selectedMoldedPartIndex}|partWeightDerived`) ? GLOBAL_STYLES.errorInput : {}),
                  flexBasis: '78%',
                }}
                value={derivedWeightSelect}
                onChange={e => setDerivedWeightSelect(e.target.value)}
                disabled={noMoldedPartSelected || disableAllInputs || disableDesignInputs}
              >
                <option value="NULL">-- Select --</option>
                {derivedWeightLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
              </Form.Select>
            </div>
            <div style={styles.tabContainerRow}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Part Weight</p>
              </div>
              <InputGroup
                style={{
                  ...(quoteMissingAttributes.includes(`MOLDED|${selectedMoldedPartIndex}|partWeight`) ? GLOBAL_STYLES.errorInput : {}),
                  flexBasis: '35%',
                  marginRight: 10,
                }}
              >
                <Form.Control
                  type="number"
                  step="0.01"
                  min="0"
                  value={partWeightValue}
                  onChange={e => {
                    setPartWeightValue(e.target.value);
                    const partWeightValueFloat = parseFloat(e.target.value);
                    if(isNaN(partWeightValueFloat)) return;
                    const totalPartWeightA = numCavitiesPerMoldA ? partWeightValueFloat * numCavitiesPerMoldA : null;
                    const totalPartWeightB = numCavitiesPerMoldB ? partWeightValueFloat * numCavitiesPerMoldB : null;
                    if(totalPartWeightA) {
                      if(!editingRunnerPercentageA) {
                        if(numRunnerWeightA) {
                          const tempRunnerPercentageA = Math.round(100 / ((totalPartWeightA / numRunnerWeightA) + 1));
                          setRunnerPercentageA(`${tempRunnerPercentageA}`);
                        }
                      }
                      else {
                        if(numRunnerPercentageA) {
                          const tempRunnerWeightA = round((totalPartWeightA * numRunnerPercentageA) / (100 - numRunnerPercentageA), 1);
                          setRunnerWeightA(`${tempRunnerWeightA}`);
                        }
                      }
                    }
                    if(totalPartWeightB) {
                      if(!editingRunnerPercentageB) {
                        if(numRunnerWeightB) {
                          const tempRunnerPercentageB = Math.round(100 / ((totalPartWeightB / numRunnerWeightB) + 1));
                          setRunnerPercentageB(`${tempRunnerPercentageB}`);
                        }
                      }
                      else {
                        if(numRunnerPercentageB) {
                          const tempRunnerWeightB = round((totalPartWeightB * numRunnerPercentageB) / (100 - numRunnerPercentageB), 1);
                          setRunnerWeightB(`${tempRunnerWeightB}`);
                        }
                      }
                    }
                  }}
                  disabled={noMoldedPartSelected || disableAllInputs || disableDesignInputs}
                />
                <InputGroup.Text style={styles.rightInputGroupText}>g</InputGroup.Text>
              </InputGroup>
              <InputGroup style={{ flexBasis: '35%' }}>
                <Form.Control
                  type="number"
                  disabled
                  value={partWeightValue && partWeightValue.length && numPartWeightValue != null ?
                    gramsToPounds(numPartWeightValue).toFixed(5) : ''}
                />
                <InputGroup.Text style={styles.rightInputGroupText}>lbs</InputGroup.Text>
              </InputGroup>
            </div>
            <div style={styles.tabContainerRow}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Regrind</p>
              </div>
              <InputGroup style={{ flexBasis: '35%', marginRight: 10 }}>
                <Form.Control
                  type="number"
                  step="1"
                  min="0"
                  disabled={!regrindIsUsed || noMoldedPartSelected || disableAllInputs || disableDesignInputs}
                  value={regrindPercentageValue}
                  onChange={e => setRegrindPercentageValue(e.target.value)}
                />
                <InputGroup.Text style={styles.rightInputGroupText}>%</InputGroup.Text>
              </InputGroup>
              <Form.Check
                type="checkbox"
                label="Enable"
                checked={regrindIsUsed}
                onChange={e => {
                  setRegrindPercentageValue('30');
                  setRegrindIsUsed(e.target.checked);
                }}
                disabled={noMoldedPartSelected || disableAllInputs || disableDesignInputs}
              />
            </div>
            <div style={styles.tabContainerRow}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Glass Used</p>
              </div>
              <Form.Check
                type="checkbox"
                checked={glassIsUsed}
                onChange={e => setGlassIsUsed(e.target.checked)}
                label=""
                disabled={noMoldedPartSelected || disableAllInputs || disableDesignInputs}
              />
            </div>
            <div style={styles.tabContainerRow}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Est. Volume of Parts</p>
              </div>
              <Form.Control
                type="number"
                style={{ flexBasis: '35%' }}
                disabled
                value={quoteData.programLife && quoteData.monthlyVolume ?
                  `${quoteData.programLife * quoteData.monthlyVolume}` : ''}
              />
            </div>
            <div style={styles.tabContainerRow}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Annealing</p>
              </div>
              <Form.Select
                as="select"
                style={{
                  ...(quoteMissingAttributes.includes(`MOLDED|${selectedMoldedPartIndex}|annealing`) ? GLOBAL_STYLES.errorInput : {}),
                  flexBasis: '78%',
                }}
                value={annealingSelect}
                onChange={e => setAnnealingSelect(e.target.value)}
                disabled={noMoldedPartSelected || disableAllInputs || disableDesignInputs}
              >
                <option value="NULL">-- Select --</option>
                {annealingLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
              </Form.Select>
            </div>
            <div style={styles.tabContainerRow}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Inspection</p>
              </div>
              <Form.Select
                as="select"
                style={{
                  ...(quoteMissingAttributes.includes(`MOLDED|${selectedMoldedPartIndex}|inspection`) ? GLOBAL_STYLES.errorInput : {}),
                  flexBasis: '78%',
                }}
                value={inspectionSelect}
                onChange={e => setInspectionSelect(e.target.value)}
                disabled={noMoldedPartSelected || disableAllInputs || disableDesignInputs}
              >
                <option value="NULL">-- Select --</option>
                {inspectionLov.filter(o => o.partSubType === 'BOTH').map(o => <option key={o.code}
                                                                                      value={o.code}>{o.name}</option>)}
                {quoteData.monthlyVolume && quoteData.monthlyVolume >= CONSTANTS.HIGH_MONTHLY_VOLUME ?
                  <option value="HIGH_CAVITATION">5 - High cavitation</option> : null}
              </Form.Select>
            </div>
            <div style={styles.tabContainerRow}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Measuring & Packaging</p>
              </div>
              <Form.Select
                as="select"
                style={{
                  ...(quoteMissingAttributes.includes(`MOLDED|${selectedMoldedPartIndex}|packaging`) ? GLOBAL_STYLES.errorInput : {}),
                  flexBasis: '78%',
                }}
                value={packagingSelect}
                onChange={e => setPackagingSelect(e.target.value)}
                disabled={noMoldedPartSelected || disableAllInputs || disableDesignInputs}
              >
                <option value="NULL">-- Select --</option>
                {packagingLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
                {quoteData.monthlyVolume && quoteData.monthlyVolume >= CONSTANTS.HIGH_MONTHLY_VOLUME ?
                  <option value="HIGH_CAVITATION">5 - High cavitation</option> : null}
              </Form.Select>
            </div>
            <div style={styles.tabContainerRow}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Outsourced Cost</p>
              </div>
              <InputGroup
                style={{
                  ...(quoteMissingAttributes.includes(`MOLDED|${selectedMoldedPartIndex}|outsourcedCost`) ? GLOBAL_STYLES.errorInput : {}),
                  flexBasis: '35%',
                  marginRight: 10,
                }}
              >
                <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                <Form.Control
                  type="number"
                  step="0.01"
                  min="0"
                  disabled={!partIsOutsourced || noMoldedPartSelected || disableAllInputs || disableDesignInputs}
                  value={outsourcedCostValue}
                  onChange={e => setOutsourcedCostValue(e.target.value)}
                />
              </InputGroup>
              <Form.Check
                type="checkbox"
                label="Enable"
                checked={partIsOutsourced}
                onChange={e => {
                  setPartIsOutsourced(e.target.checked);
                  setBomData(prevState => {
                    let newState = cloneDeep(prevState);
                    const itemIndex = newState.nodes.findIndex(o => o.id === moldedPartsValue[selectedMoldedPartIndex].bomId);
                    if(itemIndex < 0) return prevState;
                    newState.nodes[itemIndex].outsourced = e.target.checked;
                    return newState;
                  });
                }}
                disabled={noMoldedPartSelected || disableAllInputs || disableDesignInputs}
              />
            </div>
          </div>
        </Tab>
        <Tab eventKey="TOOLING" title="Tooling">
          <div style={{ ...styles.tabContainer, marginBottom: 8 }}>
            <div style={styles.tabContainerRow}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Insert Molded</p>
              </div>
              <Form.Select
                as="select"
                style={{ flexBasis: '78%' }}
                value={insertMoldedSelect}
                onChange={e => setInsertMoldedSelect(e.target.value)}
                disabled={noMoldedPartSelected || disableAllInputs}
              >
                <option value="NO">No</option>
                <option value="YES">Yes</option>
              </Form.Select>
            </div>
            <div style={styles.tabContainerRow}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Num of Side Actions</p>
              </div>
              <Form.Control
                type="number"
                step="1"
                min="0"
                style={{ flexBasis: '78%' }}
                value={sideActionsValue}
                onChange={e => setSideActionsValue(e.target.value)}
                disabled={noMoldedPartSelected || disableAllInputs}
              />
            </div>
            <div style={styles.tabContainerRow}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Ejector System</p>
              </div>
              <Form.Select
                as="select"
                style={{ flexBasis: '78%' }}
                value={ejectorSystemSelect}
                onChange={e => setEjectorSystemSelect(e.target.value)}
                disabled={noMoldedPartSelected || disableAllInputs}
              >
                <option value="NULL">-- Select --</option>
                {ejectorSystemLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
              </Form.Select>
            </div>
            <div style={{ ...styles.tabContainerRowSpaced, marginTop: 10 }}>
              <div style={styles.tabContainerLabel}/>
              <div style={{ flexBasis: '38%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <p style={{ ...styles.noMarginText, fontWeight: 'bold' }}>Option A</p>
              </div>
              <div style={{
                flexBasis: '38%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
                <p style={{ ...styles.noMarginText, fontWeight: 'bold' }}>Option B</p>
                <Button
                  size="sm"
                  style={{ marginLeft: 10 }}
                  type="button"
                  variant="primary"
                  disabled={disableAllInputs}
                  onClick={() => {
                    setHasOptionB(prevState => !prevState);
                    updateMoldedPartsValue(!hasOptionB);
                  }}
                >
                  {hasOptionB ? 'Remove Option B' : 'Add Option B'}
                </Button>
              </div>
            </div>
            <div style={styles.tabContainerRowSpaced}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Cavities per Mold</p>
              </div>
              <Form.Control
                tabIndex={1}
                type="number"
                step="1"
                min="1"
                style={{
                  ...(quoteMissingAttributes.includes(`MOLDED|${selectedMoldedPartIndex}|cavitiesPerMoldA`) ? GLOBAL_STYLES.errorInput : {}),
                  flexBasis: '38%',
                }}
                value={cavitiesPerMoldA}
                onChange={e => {
                  setCavitiesPerMoldA(e.target.value);
                  const cavitiesPerMoldAInt = parseInt(e.target.value, 10);
                  if(isNaN(cavitiesPerMoldAInt)) return;
                  const totalPartWeightA = numPartWeightValue ? numPartWeightValue * cavitiesPerMoldAInt : null;
                  if(!totalPartWeightA) return;
                  if(!editingRunnerPercentageA) {
                    if(numRunnerWeightA) {
                      const tempRunnerPercentageA = Math.round(100 / ((totalPartWeightA / numRunnerWeightA) + 1));
                      setRunnerPercentageA(`${tempRunnerPercentageA}`);
                    }
                  }
                  else {
                    if(numRunnerPercentageA) {
                      const tempRunnerWeightA = round((totalPartWeightA * numRunnerPercentageA) / (100 - numRunnerPercentageA), 1);
                      setRunnerWeightA(`${tempRunnerWeightA}`);
                    }
                  }
                }}
                disabled={noMoldedPartSelected || disableAllInputs}
              />
              <Form.Control
                tabIndex={101}
                type="number"
                step="1"
                min="1"
                style={{
                  ...(quoteMissingAttributes.includes(`MOLDED|${selectedMoldedPartIndex}|cavitiesPerMoldB`) ? GLOBAL_STYLES.errorInput : {}),
                  flexBasis: '38%',
                }}
                value={cavitiesPerMoldB}
                onChange={e => {
                  setCavitiesPerMoldB(e.target.value);
                  const cavitiesPerMoldBInt = parseInt(e.target.value, 10);
                  if(isNaN(cavitiesPerMoldBInt)) return;
                  const totalPartWeightB = numPartWeightValue ? numPartWeightValue * cavitiesPerMoldBInt : null;
                  if(!totalPartWeightB) return;
                  if(!editingRunnerPercentageB) {
                    if(numRunnerWeightB) {
                      const tempRunnerPercentageB = Math.round(100 / ((totalPartWeightB / numRunnerWeightB) + 1));
                      setRunnerPercentageB(`${tempRunnerPercentageB}`);
                    }
                  }
                  else {
                    if(numRunnerPercentageB) {
                      const tempRunnerWeightB = round((totalPartWeightB * numRunnerPercentageB) / (100 - numRunnerPercentageB), 1);
                      setRunnerWeightB(`${tempRunnerWeightB}`);
                    }
                  }
                }}
                disabled={noMoldedPartSelected || disableAllInputs || !hasOptionB}
              />
            </div>
            <div style={styles.tabContainerRowSpaced}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Cavities per Part</p>
              </div>
              <Form.Control
                tabIndex={2}
                type="number"
                step="1"
                min="1"
                style={{
                  ...(quoteMissingAttributes.includes(`MOLDED|${selectedMoldedPartIndex}|cavitiesPerPartA`) ? GLOBAL_STYLES.errorInput : {}),
                  flexBasis: '38%',
                }}
                value={cavitiesPerPartA}
                onChange={e => setCavitiesPerPartA(e.target.value)}
                disabled={noMoldedPartSelected || disableAllInputs}
              />
              <Form.Control
                tabIndex={102}
                type="number"
                step="1"
                min="1"
                style={{
                  ...(quoteMissingAttributes.includes(`MOLDED|${selectedMoldedPartIndex}|cavitiesPerPartB`) ? GLOBAL_STYLES.errorInput : {}),
                  flexBasis: '38%',
                }}
                value={cavitiesPerPartB}
                onChange={e => setCavitiesPerPartB(e.target.value)}
                disabled={noMoldedPartSelected || disableAllInputs || !hasOptionB}
              />
            </div>
            <div style={styles.tabContainerRowSpaced}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Num of different parts</p>
              </div>
              <Form.Control
                tabIndex={3}
                type="number"
                step="1"
                min="1"
                style={{
                  ...(quoteMissingAttributes.includes(`MOLDED|${selectedMoldedPartIndex}|numOfDiffPartsA`) ? GLOBAL_STYLES.errorInput : {}),
                  flexBasis: '38%',
                }}
                value={numOfDiffPartsA}
                onChange={e => setNumOfDiffPartsA(e.target.value)}
                disabled={noMoldedPartSelected || disableAllInputs}
              />
              <Form.Control
                tabIndex={103}
                type="number"
                step="1"
                min="1"
                style={{
                  ...(quoteMissingAttributes.includes(`MOLDED|${selectedMoldedPartIndex}|numOfDiffPartsB`) ? GLOBAL_STYLES.errorInput : {}),
                  flexBasis: '38%',
                }}
                value={numOfDiffPartsB}
                onChange={e => setNumOfDiffPartsB(e.target.value)}
                disabled={noMoldedPartSelected || disableAllInputs || !hasOptionB}
              />
            </div>
            <div style={styles.tabContainerRowSpaced}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Total Part Weight</p>
              </div>
              <InputGroup style={{ flexBasis: '38%' }}>
                <Form.Control
                  type="number"
                  disabled
                  value={calculationsA?.totalPartWeight != null ? calculationsA.totalPartWeight.toFixed(2) : ''}
                />
                <InputGroup.Text style={styles.rightInputGroupText}>g</InputGroup.Text>
              </InputGroup>
              <InputGroup style={{ flexBasis: '38%' }}>
                <Form.Control
                  type="number"
                  disabled
                  value={calculationsB?.totalPartWeight != null ? calculationsB.totalPartWeight.toFixed(2) : ''}
                />
                <InputGroup.Text style={styles.rightInputGroupText}>g</InputGroup.Text>
              </InputGroup>
            </div>
            <div style={styles.tabContainerRowSpaced}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Runner Percent</p>
              </div>
              <div style={styles.tabDualContainer}>
                <InputGroup style={{ flexBasis: '45%' }}>
                  <Form.Control
                    tabIndex={4}
                    type="number"
                    step="1"
                    min="0"
                    value={runnerPercentageA}
                    onChange={e => {
                      setRunnerPercentageA(e.target.value);
                      const runnerPercentageAFloat = parseFloat(e.target.value);
                      if(isNaN(runnerPercentageAFloat)) return;
                      const totalPartWeightA = numCavitiesPerMoldA && numPartWeightValue ? numPartWeightValue * numCavitiesPerMoldA : null;
                      if(!totalPartWeightA) return;
                      const tempRunnerWeightA = round((totalPartWeightA * runnerPercentageAFloat) / (100 - runnerPercentageAFloat), 1);
                      setRunnerWeightA(`${tempRunnerWeightA}`);
                    }}
                    disabled={noMoldedPartSelected || disableAllInputs || !editingRunnerPercentageA}
                  />
                  <InputGroup.Text style={styles.rightInputGroupText}>%</InputGroup.Text>
                </InputGroup>
                <FontAwesomeIcon
                  icon={faExchangeAlt}
                  style={{ cursor: 'pointer', color: COLORS.orange }}
                  onClick={() => setEditingRunnerPercentageA(prevState => !prevState)}
                />
                <InputGroup style={{ flexBasis: '45%' }}>
                  <Form.Control
                    tabIndex={5}
                    type="number"
                    step="0.1"
                    min="0"
                    value={runnerWeightA}
                    onChange={e => {
                      setRunnerWeightA(e.target.value);
                      const runnerWeightAFloat = parseFloat(e.target.value);
                      if(isNaN(runnerWeightAFloat)) return;
                      const totalPartWeightA = numCavitiesPerMoldA && numPartWeightValue ? numPartWeightValue * numCavitiesPerMoldA : null;
                      if(!totalPartWeightA) return;
                      const tempRunnerPercentageA = Math.round(100 / ((totalPartWeightA / runnerWeightAFloat) + 1));
                      setRunnerPercentageA(`${tempRunnerPercentageA}`);
                    }}
                    disabled={noMoldedPartSelected || disableAllInputs || editingRunnerPercentageA}
                  />
                  <InputGroup.Text style={styles.rightInputGroupText}>g</InputGroup.Text>
                </InputGroup>
              </div>
              <div style={styles.tabDualContainer}>
                <InputGroup style={{ flexBasis: '45%' }}>
                  <Form.Control
                    tabIndex={104}
                    type="number"
                    step="1"
                    min="0"
                    value={runnerPercentageB}
                    onChange={e => {
                      setRunnerPercentageB(e.target.value);
                      const runnerPercentageBFloat = parseFloat(e.target.value);
                      if(isNaN(runnerPercentageBFloat)) return;
                      const totalPartWeightB = numCavitiesPerMoldB && numPartWeightValue ? numPartWeightValue * numCavitiesPerMoldB : null;
                      if(!totalPartWeightB) return;
                      const tempRunnerWeightB = round((totalPartWeightB * runnerPercentageBFloat) / (100 - runnerPercentageBFloat), 1);
                      setRunnerWeightB(`${tempRunnerWeightB}`);
                    }}
                    disabled={noMoldedPartSelected || disableAllInputs || !hasOptionB || !editingRunnerPercentageB}
                  />
                  <InputGroup.Text style={styles.rightInputGroupText}>%</InputGroup.Text>
                </InputGroup>
                <FontAwesomeIcon
                  icon={faExchangeAlt}
                  style={{ cursor: 'pointer', color: COLORS.orange }}
                  onClick={() => setEditingRunnerPercentageB(prevState => !prevState)}
                />
                <InputGroup style={{ flexBasis: '45%' }}>
                  <Form.Control
                    tabIndex={105}
                    type="number"
                    step="0.1"
                    min="0"
                    value={runnerWeightB}
                    onChange={e => {
                      setRunnerWeightB(e.target.value);
                      const runnerWeightBFloat = parseFloat(e.target.value);
                      if(isNaN(runnerWeightBFloat)) return;
                      const totalPartWeightB = numCavitiesPerMoldB && numPartWeightValue ? numPartWeightValue * numCavitiesPerMoldB : null;
                      if(!totalPartWeightB) return;
                      const tempRunnerPercentageB = Math.round(100 / ((totalPartWeightB / runnerWeightBFloat) + 1));
                      setRunnerPercentageB(`${tempRunnerPercentageB}`);
                    }}
                    disabled={noMoldedPartSelected || disableAllInputs || !hasOptionB || editingRunnerPercentageB}
                  />
                  <InputGroup.Text style={styles.rightInputGroupText}>g</InputGroup.Text>
                </InputGroup>
              </div>
            </div>
            <div style={styles.tabContainerRowSpaced}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Total Press Shot wt</p>
              </div>
              <div style={styles.tabDualContainer}>
                <InputGroup style={{ flexBasis: '49%' }}>
                  <Form.Control
                    type="number"
                    disabled
                    value={calculationsA?.totalShotWeight != null ? calculationsA.totalShotWeight.toFixed(2) : ''}
                  />
                  <InputGroup.Text style={styles.rightInputGroupText}>g</InputGroup.Text>
                </InputGroup>
                <InputGroup style={{ flexBasis: '49%' }}>
                  <Form.Control
                    type="number"
                    disabled
                    value={calculationsA?.totalShotWeight != null ? gramsToPounds(calculationsA.totalShotWeight).toFixed(2) : ''}
                  />
                  <InputGroup.Text style={styles.rightInputGroupText}>lb</InputGroup.Text>
                </InputGroup>
              </div>
              <div style={styles.tabDualContainer}>
                <InputGroup style={{ flexBasis: '49%' }}>
                  <Form.Control
                    type="number"
                    disabled
                    value={calculationsB?.totalShotWeight != null ? calculationsB.totalShotWeight.toFixed(2) : ''}
                  />
                  <InputGroup.Text style={styles.rightInputGroupText}>g</InputGroup.Text>
                </InputGroup>
                <InputGroup style={{ flexBasis: '49%' }}>
                  <Form.Control
                    type="number"
                    disabled
                    value={calculationsB?.totalShotWeight != null ? gramsToPounds(calculationsB.totalShotWeight).toFixed(2) : ''}
                  />
                  <InputGroup.Text style={styles.rightInputGroupText}>lb</InputGroup.Text>
                </InputGroup>
              </div>
            </div>
            <div style={styles.tabContainerRowSpaced}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Raw Material Shot wt</p>
              </div>
              <div style={styles.tabDualContainer}>
                <InputGroup style={{ flexBasis: '49%' }}>
                  <Form.Control
                    type="number"
                    disabled
                    value={calculationsA?.rawShotWeight ? calculationsA.rawShotWeight.toFixed(2) : ''}
                  />
                  <InputGroup.Text style={styles.rightInputGroupText}>g</InputGroup.Text>
                </InputGroup>
                <InputGroup style={{ flexBasis: '49%' }}>
                  <Form.Control
                    type="number"
                    disabled
                    value={calculationsA?.rawShotWeight != null ? gramsToPounds(calculationsA.rawShotWeight).toFixed(2) : ''}
                  />
                  <InputGroup.Text style={styles.rightInputGroupText}>lb</InputGroup.Text>
                </InputGroup>
              </div>
              <div style={styles.tabDualContainer}>
                <InputGroup style={{ flexBasis: '49%' }}>
                  <Form.Control
                    type="number"
                    disabled
                    value={calculationsB?.rawShotWeight != null ? calculationsB.rawShotWeight.toFixed(2) : ''}
                  />
                  <InputGroup.Text style={styles.rightInputGroupText}>g</InputGroup.Text>
                </InputGroup>
                <InputGroup style={{ flexBasis: '49%' }}>
                  <Form.Control
                    type="number"
                    disabled
                    value={calculationsB?.rawShotWeight != null ? gramsToPounds(calculationsB.rawShotWeight).toFixed(2) : ''}
                  />
                  <InputGroup.Text style={styles.rightInputGroupText}>lb</InputGroup.Text>
                </InputGroup>
              </div>
            </div>
            <div style={styles.tabContainerRowSpaced}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Gate Style</p>
              </div>
              <Form.Select
                tabIndex={6}
                as="select"
                style={{
                  ...(quoteMissingAttributes.includes(`MOLDED|${selectedMoldedPartIndex}|gateStyleA`) ? GLOBAL_STYLES.errorInput : {}),
                  flexBasis: '38%',
                }}
                value={gateStyleSelectA}
                onChange={e => setGateStyleSelectA(e.target.value)}
                disabled={noMoldedPartSelected || disableAllInputs}
              >
                <option value="NULL">-- Select Gate Style --</option>
                {gateStyleLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
              </Form.Select>
              <Form.Select
                tabIndex={106}
                as="select"
                style={{
                  ...(quoteMissingAttributes.includes(`MOLDED|${selectedMoldedPartIndex}|gateStyleB`) ? GLOBAL_STYLES.errorInput : {}),
                  flexBasis: '38%',
                }}
                value={gateStyleSelectB}
                onChange={e => setGateStyleSelectB(e.target.value)}
                disabled={noMoldedPartSelected || disableAllInputs || !hasOptionB}
              >
                <option value="NULL">-- Select Gate Style --</option>
                {gateStyleLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
              </Form.Select>
            </div>
            <div style={styles.tabContainerRowSpaced}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Gate Cutting</p>
              </div>
              <Form.Select
                tabIndex={7}
                as="select"
                style={{
                  ...(quoteMissingAttributes.includes(`MOLDED|${selectedMoldedPartIndex}|gateCuttingA`) ? GLOBAL_STYLES.errorInput : {}),
                  flexBasis: '38%',
                }}
                value={gateCuttingSelectA}
                onChange={e => setGateCuttingSelectA(e.target.value)}
                disabled={noMoldedPartSelected || disableAllInputs}
              >
                <option value="NULL">-- Select Gate Cutting --</option>
                {gateCuttingLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
              </Form.Select>
              <Form.Select
                tabIndex={107}
                as="select"
                style={{
                  ...(quoteMissingAttributes.includes(`MOLDED|${selectedMoldedPartIndex}|gateCuttingB`) ? GLOBAL_STYLES.errorInput : {}),
                  flexBasis: '38%',
                }}
                value={gateCuttingSelectB}
                onChange={e => setGateCuttingSelectB(e.target.value)}
                disabled={noMoldedPartSelected || disableAllInputs || !hasOptionB}
              >
                <option value="NULL">-- Select Gate Cutting --</option>
                {gateCuttingLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
              </Form.Select>
            </div>
            <div style={styles.tabContainerRowSpaced}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Tool Type</p>
              </div>
              <Form.Select
                tabIndex={8}
                as="select"
                style={{ flexBasis: '38%' }}
                value={toolingToolTypeSelectA}
                onChange={e => setToolingToolTypeSelectA(e.target.value)}
                disabled={noMoldedPartSelected || disableAllInputs}
              >
                <option value="NULL">-- Select Tool Type --</option>
                {toolingToolTypeLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
                {quoteData.monthlyVolume && quoteData.monthlyVolume >= CONSTANTS.HIGH_MONTHLY_VOLUME ?
                  <option value="HIGH_CAVITATION">High cavitation</option> : null}
              </Form.Select>
              <Form.Select
                tabIndex={108}
                as="select"
                style={{ flexBasis: '38%' }}
                value={toolingToolTypeSelectB}
                onChange={e => setToolingToolTypeSelectB(e.target.value)}
                disabled={noMoldedPartSelected || disableAllInputs || !hasOptionB}
              >
                <option value="NULL">-- Select Tool Type --</option>
                {toolingToolTypeLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
                {quoteData.monthlyVolume && quoteData.monthlyVolume >= CONSTANTS.HIGH_MONTHLY_VOLUME ?
                  <option value="HIGH_CAVITATION">High cavitation</option> : null}
              </Form.Select>
            </div>
            <div style={styles.tabContainerRowSpaced}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Tool Cost</p>
              </div>
              <InputGroup
                style={{
                  ...(quoteMissingAttributes.includes(`MOLDED|${selectedMoldedPartIndex}|toolCostA`) ? GLOBAL_STYLES.errorInput : {}),
                  flexBasis: '38%',
                }}
              >
                <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                <Form.Control
                  tabIndex={9}
                  type="number"
                  step="0.01"
                  min="0"
                  value={toolCostA}
                  onChange={e => setToolCostA(e.target.value)}
                  disabled={noMoldedPartSelected || disableAllInputs}
                />
              </InputGroup>
              <InputGroup
                style={{
                  ...(quoteMissingAttributes.includes(`MOLDED|${selectedMoldedPartIndex}|toolCostB`) ? GLOBAL_STYLES.errorInput : {}),
                  flexBasis: '38%',
                }}
              >
                <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                <Form.Control
                  tabIndex={109}
                  type="number"
                  step="0.01"
                  min="0"
                  value={toolCostB}
                  onChange={e => setToolCostB(e.target.value)}
                  disabled={noMoldedPartSelected || disableAllInputs || !hasOptionB}
                />
              </InputGroup>
            </div>
            <div style={styles.tabContainerRowSpaced}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Tuning & Texture</p>
              </div>
              <InputGroup style={{ flexBasis: '38%' }}>
                <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                <Form.Control
                  tabIndex={10}
                  type="number"
                  step="0.01"
                  min="0"
                  value={tuningTextureCostA}
                  onChange={e => setTuningTextureCostA(e.target.value)}
                  disabled={noMoldedPartSelected || disableAllInputs}
                />
              </InputGroup>
              <InputGroup style={{ flexBasis: '38%' }}>
                <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                <Form.Control
                  tabIndex={110}
                  type="number"
                  step="0.01"
                  min="0"
                  value={tuningTextureCostB}
                  onChange={e => setTuningTextureCostB(e.target.value)}
                  disabled={noMoldedPartSelected || disableAllInputs || !hasOptionB}
                />
              </InputGroup>
            </div>
            <div style={styles.tabContainerRowSpaced}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Boat Shipment</p>
              </div>
              <InputGroup style={{ flexBasis: '38%' }}>
                <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                <Form.Control
                  tabIndex={11}
                  type="number"
                  step="0.01"
                  min="0"
                  value={boatShipmentCostA}
                  onChange={e => setBoatShipmentCostA(e.target.value)}
                  disabled={noMoldedPartSelected || disableAllInputs}
                />
              </InputGroup>
              <InputGroup style={{ flexBasis: '38%' }}>
                <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                <Form.Control
                  tabIndex={111}
                  type="number"
                  step="0.01"
                  min="0"
                  value={boatShipmentCostB}
                  onChange={e => setBoatShipmentCostB(e.target.value)}
                  disabled={noMoldedPartSelected || disableAllInputs || !hasOptionB}
                />
              </InputGroup>
            </div>
            <div style={styles.tabContainerRowSpaced}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Total Tool Cost (Boat)</p>
              </div>
              <InputGroup style={{ flexBasis: '38%' }}>
                <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                <Form.Control
                  type="number"
                  disabled
                  value={calculationsA?.totalToolCostBoat ? calculationsA.totalToolCostBoat : ''}
                />
              </InputGroup>
              <InputGroup style={{ flexBasis: '38%' }}>
                <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                <Form.Control
                  type="number"
                  disabled
                  value={calculationsB?.totalToolCostBoat ? calculationsB.totalToolCostBoat : ''}
                />
              </InputGroup>
            </div>
            <div style={styles.tabContainerRowSpaced}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Air Shipment (Expedite)</p>
              </div>
              <InputGroup style={{ flexBasis: '38%' }}>
                <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                <Form.Control
                  tabIndex={12}
                  type="number"
                  step="0.01"
                  min="0"
                  value={airShipmentCostA}
                  onChange={e => setAirShipmentCostA(e.target.value)}
                  disabled={noMoldedPartSelected || disableAllInputs}
                />
              </InputGroup>
              <InputGroup style={{ flexBasis: '38%' }}>
                <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                <Form.Control
                  tabIndex={112}
                  type="number"
                  step="0.01"
                  min="0"
                  value={airShipmentCostB}
                  onChange={e => setAirShipmentCostB(e.target.value)}
                  disabled={noMoldedPartSelected || disableAllInputs || !hasOptionB}
                />
              </InputGroup>
            </div>
            <div style={styles.tabContainerRowSpaced}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Total Tool Cost (Air)</p>
              </div>
              <InputGroup style={{ flexBasis: '38%' }}>
                <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                <Form.Control
                  type="number"
                  disabled
                  value={calculationsA?.totalToolCostAir ? calculationsA.totalToolCostAir : ''}
                />
              </InputGroup>
              <InputGroup style={{ flexBasis: '38%' }}>
                <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                <Form.Control
                  type="number"
                  disabled
                  value={calculationsB?.totalToolCostAir ? calculationsB.totalToolCostAir : ''}
                />
              </InputGroup>
            </div>
            <div style={styles.tabContainerRowSpaced}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Lead Time (KO to sample)</p>
              </div>
              <InputGroup
                style={{
                  ...(quoteMissingAttributes.includes(`MOLDED|${selectedMoldedPartIndex}|leadTimeWeeksA`) ? GLOBAL_STYLES.errorInput : {}),
                  flexBasis: '38%',
                }}
              >
                <Form.Control
                  tabIndex={13}
                  type="number"
                  step="1"
                  min="0"
                  value={leadTimeWeeksA}
                  onChange={e => setLeadTimeWeeksA(e.target.value)}
                  disabled={noMoldedPartSelected || disableAllInputs}
                />
                <InputGroup.Text style={styles.rightInputGroupText}>weeks</InputGroup.Text>
              </InputGroup>
              <InputGroup
                style={{
                  ...(quoteMissingAttributes.includes(`MOLDED|${selectedMoldedPartIndex}|leadTimeWeeksB`) ? GLOBAL_STYLES.errorInput : {}),
                  flexBasis: '38%',
                }}
              >
                <Form.Control
                  tabIndex={113}
                  type="number"
                  step="1"
                  min="0"
                  value={leadTimeWeeksB}
                  onChange={e => setLeadTimeWeeksB(e.target.value)}
                  disabled={noMoldedPartSelected || disableAllInputs || !hasOptionB}
                />
                <InputGroup.Text style={styles.rightInputGroupText}>weeks</InputGroup.Text>
              </InputGroup>
            </div>
            <div style={styles.tabContainerRowSpaced}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Tool Description</p>
              </div>
              <Form.Select
                tabIndex={14}
                as="select"
                style={{
                  ...(quoteMissingAttributes.includes(`MOLDED|${selectedMoldedPartIndex}|toolDescA`) ? GLOBAL_STYLES.errorInput : {}),
                  flexBasis: '38%',
                }}
                value={toolDescSelectA}
                onChange={e => setToolDescSelectA(e.target.value)}
                disabled={noMoldedPartSelected || disableAllInputs}
              >
                <option value="NULL">-- Select Tool Description --</option>
                {toolDescLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
              </Form.Select>
              <Form.Select
                tabIndex={114}
                as="select"
                style={{
                  ...(quoteMissingAttributes.includes(`MOLDED|${selectedMoldedPartIndex}|toolDescB`) ? GLOBAL_STYLES.errorInput : {}),
                  flexBasis: '38%',
                }}
                value={toolDescSelectB}
                onChange={e => setToolDescSelectB(e.target.value)}
                disabled={noMoldedPartSelected || disableAllInputs || !hasOptionB}
              >
                <option value="NULL">-- Select Tool Description --</option>
                {toolDescLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
              </Form.Select>
            </div>
            <div style={styles.tabContainerRowSpaced}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Press Location</p>
              </div>
              <Form.Select
                tabIndex={15}
                as="select"
                style={{
                  ...(quoteMissingAttributes.includes(`MOLDED|${selectedMoldedPartIndex}|toolLocationA`) ? GLOBAL_STYLES.errorInput : {}),
                  flexBasis: '38%',
                }}
                value={locationSelectA}
                onChange={e => {
                  setPressSizeSelectA('NULL');
                  setPressTypeSelectA('NULL');
                  setLocationSelectA(e.target.value);
                }}
                disabled={noMoldedPartSelected || disableAllInputs}
              >
                <option value="NULL">-- Select Press Location --</option>
                {locationLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
              </Form.Select>
              <Form.Select
                tabIndex={115}
                as="select"
                style={{
                  ...(quoteMissingAttributes.includes(`MOLDED|${selectedMoldedPartIndex}|toolLocationB`) ? GLOBAL_STYLES.errorInput : {}),
                  flexBasis: '38%',
                }}
                value={locationSelectB}
                onChange={e => {
                  setPressSizeSelectB('NULL');
                  setPressTypeSelectB('NULL');
                  setLocationSelectB(e.target.value);
                }}
                disabled={noMoldedPartSelected || disableAllInputs || !hasOptionB}
              >
                <option value="NULL">-- Select Press Location --</option>
                {locationLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
              </Form.Select>
            </div>
            <div style={styles.tabContainerRowSpaced}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Press Type</p>
              </div>
              <Form.Select
                tabIndex={16}
                as="select"
                style={{ flexBasis: '38%' }}
                value={pressTypeSelectA}
                onChange={e => {
                  setPressSizeSelectB('NULL');
                  setPressTypeSelectA(e.target.value);
                }}
                disabled={noMoldedPartSelected || disableAllInputs}
              >
                <option value="NULL">-- Select Press Type --</option>
                {pressTypeLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
              </Form.Select>
              <Form.Select
                tabIndex={116}
                as="select"
                style={{ flexBasis: '38%' }}
                value={pressTypeSelectB}
                onChange={e => {
                  setPressSizeSelectB('NULL');
                  setPressTypeSelectB(e.target.value);
                }}
                disabled={noMoldedPartSelected || disableAllInputs || !hasOptionB}
              >
                <option value="NULL">-- Select Press Type --</option>
                {pressTypeLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
              </Form.Select>
            </div>
            <div style={styles.tabContainerRowSpaced}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Shots per Hour</p>
              </div>
              <div style={styles.tabDualContainer}>
                <Form.Control
                  tabIndex={17}
                  type="number"
                  step="1"
                  min="1"
                  style={{
                    ...(quoteMissingAttributes.includes(`MOLDED|${selectedMoldedPartIndex}|shotsPerHourA`) ? GLOBAL_STYLES.errorInput : {}),
                    flexBasis: '39%',
                  }}
                  value={shotsPerHourA}
                  onChange={e => {
                    setShotsPerHourA(e.target.value);
                    const shotsPerHourAFloat = parseFloat(e.target.value);
                    if(isNaN(shotsPerHourAFloat)) return;
                    const tempCycleTimeA = shotsPerHourAFloat ? round(3600 / shotsPerHourAFloat, 2) : 0;
                    setCycleTimeA(`${tempCycleTimeA}`);
                  }}
                  disabled={noMoldedPartSelected || disableAllInputs}
                />
                <InputGroup style={{ flexBasis: '59%' }}>
                  <InputGroup.Text style={styles.leftInputGroupText}>Cycle Time</InputGroup.Text>
                  <Form.Control
                    tabIndex={18}
                    type="number"
                    step="0.1"
                    min="0"
                    value={cycleTimeA}
                    onChange={e => {
                      setCycleTimeA(e.target.value);
                      const cycleTimeAFloat = parseFloat(e.target.value);
                      if(isNaN(cycleTimeAFloat)) return;
                      const tempShotsPerHourA = cycleTimeAFloat ? round(3600 / cycleTimeAFloat, 1) : 0;
                      setShotsPerHourA(`${tempShotsPerHourA}`);
                    }}
                    disabled={noMoldedPartSelected || disableAllInputs}
                  />
                  <InputGroup.Text style={styles.rightInputGroupText}>s</InputGroup.Text>
                </InputGroup>
              </div>
              <div style={styles.tabDualContainer}>
                <Form.Control
                  tabIndex={117}
                  type="number"
                  step="1"
                  min="1"
                  style={{
                    ...(quoteMissingAttributes.includes(`MOLDED|${selectedMoldedPartIndex}|shotsPerHourB`) ? GLOBAL_STYLES.errorInput : {}),
                    flexBasis: '39%',
                  }}
                  value={shotsPerHourB}
                  onChange={e => {
                    setShotsPerHourB(e.target.value);
                    const shotsPerHourBFloat = parseFloat(e.target.value);
                    if(isNaN(shotsPerHourBFloat)) return;
                    const tempCycleTimeB = shotsPerHourBFloat ? round(3600 / shotsPerHourBFloat, 2) : 0;
                    setCycleTimeB(`${tempCycleTimeB}`);
                  }}
                  disabled={noMoldedPartSelected || disableAllInputs || !hasOptionB}
                />
                <InputGroup style={{ flexBasis: '59%' }}>
                  <InputGroup.Text style={styles.leftInputGroupText}>Cycle Time</InputGroup.Text>
                  <Form.Control
                    tabIndex={118}
                    type="number"
                    step="0.1"
                    min="0"
                    value={cycleTimeB}
                    onChange={e => {
                      setCycleTimeB(e.target.value);
                      const cycleTimeBFloat = parseFloat(e.target.value);
                      if(isNaN(cycleTimeBFloat)) return;
                      const tempShotsPerHourB = cycleTimeBFloat ? round(3600 / cycleTimeBFloat, 1) : 0;
                      setShotsPerHourB(`${tempShotsPerHourB}`);
                    }}
                    disabled={noMoldedPartSelected || disableAllInputs || !hasOptionB}
                  />
                  <InputGroup.Text style={styles.rightInputGroupText}>s</InputGroup.Text>
                </InputGroup>
              </div>
            </div>
            <div style={styles.tabContainerRowSpaced}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Press Size</p>
              </div>
              <Form.Select
                tabIndex={19}
                as="select"
                style={{
                  ...(quoteMissingAttributes.includes(`MOLDED|${selectedMoldedPartIndex}|pressSizeA`) ? GLOBAL_STYLES.errorInput : {}),
                  flexBasis: '38%',
                }}
                value={pressSizeSelectA}
                onChange={e => setPressSizeSelectA(e.target.value)}
                disabled={noMoldedPartSelected || disableAllInputs}
              >
                <option value="NULL">-- Select Press Size --</option>
                {pressSizeOptionsA.map((o, index) => <option key={index} value={o.code}>{o.name}</option>)}
              </Form.Select>
              <Form.Select
                tabIndex={119}
                as="select"
                style={{
                  ...(quoteMissingAttributes.includes(`MOLDED|${selectedMoldedPartIndex}|pressSizeB`) ? GLOBAL_STYLES.errorInput : {}),
                  flexBasis: '38%',
                }}
                value={pressSizeSelectB}
                onChange={e => setPressSizeSelectB(e.target.value)}
                disabled={noMoldedPartSelected || disableAllInputs || !hasOptionB}
              >
                <option value="NULL">-- Select Press Size --</option>
                {pressSizeOptionsB.map((o, index) => <option key={index} value={o.code}>{o.name}</option>)}
              </Form.Select>
            </div>
            <div style={styles.tabContainerRowSpaced}>
              <div style={styles.tabContainerLabel}>
                <p style={styles.noMarginText}>Press Rate</p>
              </div>
              <InputGroup style={{ flexBasis: '38%' }}>
                <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                <Form.Control
                  type="number"
                  disabled
                  value={calculationsA?.rates?.press ? calculationsA.rates.press : ''}
                />
              </InputGroup>
              <InputGroup style={{ flexBasis: '38%' }}>
                <InputGroup.Text style={styles.leftInputGroupText}>$</InputGroup.Text>
                <Form.Control
                  type="number"
                  disabled
                  value={calculationsB?.rates?.press ? calculationsB.rates.press : ''}
                />
              </InputGroup>
            </div>
          </div>
        </Tab>
        <Tab eventKey="CALCULATIONS" title="Calculations">
          <div style={{ ...styles.tabContainer, marginBottom: 8 }}>
            {calculationsA?.partIsOutsourced ?
              <div style={styles.closingRow}>
                <div
                  style={styles.closingRowHeader}
                  onClick={() => setOutsourcedCostsVisible(prev => !prev)}
                >
                  <h5 style={styles.noMarginText}>Outsourced Costs</h5>
                </div>
                {
                  outsourcedCostsVisible ?
                    <div style={{ ...styles.closingRowContent, flexDirection: 'row' }}>
                      <div style={{ flex: 1 }}>
                        <Table size="sm" style={{ marginBottom: 0 }}>
                          <tbody>
                          <tr>
                            <td style={styles.noCellBorder}>Total Standard Cost</td>
                            <td style={styles.noCellBorder}>
                              {calculationsA?.outsourcedCost ? `$${calculationsA.outsourcedCost.toFixed(5)}` : ''}
                            </td>
                          </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div> :
                    null
                }
              </div> :
              null
            }
            <div style={styles.closingRow}>
              <div
                style={styles.closingRowHeader}
                onClick={() => setTotalStdCostsVisible(prev => !prev)}
              >
                <h5 style={styles.noMarginText}>Total Standard Costs</h5>
              </div>
              {
                totalStdCostsVisible ?
                  <div style={{ ...styles.closingRowContent, flexDirection: 'row' }}>
                    <div style={{ flex: 1 }}>
                      <h5>Option A</h5>
                      <TotalStandardCostsTable
                        location={locationSelectA}
                        calculations={calculationsA}
                      />
                    </div>
                    {hasOptionB ?
                      <div style={{ flex: 1 }}>
                        <h5>Option B</h5>
                        <TotalStandardCostsTable
                          location={locationSelectB}
                          calculations={calculationsB}
                        />
                      </div> :
                      null
                    }
                  </div> :
                  null
              }
            </div>
            <div style={styles.closingRow}>
              <div
                style={styles.closingRowHeader}
                onClick={() => setMaterialCostsVisible(prev => !prev)}
              >
                <h5 style={styles.noMarginText}>Material Costs</h5>
              </div>
              {
                materialCostsVisible ?
                  <div style={{ ...styles.closingRowContent, flexDirection: 'row' }}>
                    <div style={{ flex: 1 }}>
                      <Table size="sm" style={{ marginBottom: 0 }}>
                        <tbody>
                        <tr>
                          <td style={styles.noCellBorder}>Total Raw Material per Lb</td>
                          <td style={styles.noCellBorder}>
                            {calculationsA?.totalRawMaterialPerPound ? `$${calculationsA.totalRawMaterialPerPound.toFixed(5)}` : ''}
                          </td>
                        </tr>
                        <tr>
                          <td style={styles.noCellBorder}>Raw Material</td>
                          <td style={styles.noCellBorder}>
                            {calculationsA?.rawMaterialCost ? `$${calculationsA.rawMaterialCost.toFixed(5)}` : ''}
                          </td>
                        </tr>
                        <tr>
                          <td style={styles.noCellBorder}>Recycled Material</td>
                          <td style={styles.noCellBorder}>
                            {calculationsA?.recycledMaterialCost ? `$${calculationsA.recycledMaterialCost.toFixed(5)}` : ''}
                          </td>
                        </tr>
                        <tr>
                          <td style={styles.noCellBorder}>Gross Raw Material</td>
                          <td style={styles.noCellBorder}>
                            {calculationsA?.grossRawMaterialCost ? `$${calculationsA.grossRawMaterialCost.toFixed(5)}` : ''}
                          </td>
                        </tr>
                        <tr>
                          <td style={styles.noCellBorder}>Total Material Costs</td>
                          <td style={styles.noCellBorder}>
                            {calculationsA?.totalMaterialCost ? `$${calculationsA.totalMaterialCost.toFixed(5)}` : ''}
                          </td>
                        </tr>
                        </tbody>
                      </Table>
                    </div>
                    <div style={{ flex: 1 }}/>
                  </div> :
                  null
              }
            </div>
            <div style={styles.closingRow}>
              <div
                style={styles.closingRowHeader}
                onClick={() => setInjectionMoldingCostsVisible(prev => !prev)}
              >
                <h5 style={styles.noMarginText}>Injection Molding Costs</h5>
              </div>
              {
                injectionMoldingCostsVisible ?
                  <div style={{ ...styles.closingRowContent, flexDirection: 'row' }}>
                    <div style={{ flex: 1 }}>
                      <h5>Option A</h5>
                      <InjectionMoldingCostsTable
                        location={locationSelectA}
                        cavitiesPerMold={cavitiesPerMoldA}
                        calculations={calculationsA}
                      />
                    </div>
                    {hasOptionB ?
                      <div style={{ flex: 1 }}>
                        <h5>Option B</h5>
                        <InjectionMoldingCostsTable
                          location={locationSelectB}
                          cavitiesPerMold={cavitiesPerMoldB}
                          calculations={calculationsB}
                        />
                      </div> :
                      null
                    }
                  </div> :
                  null
              }
            </div>
            <div style={{ ...styles.closingRow, marginBottom: 0 }}>
              <div
                style={styles.closingRowHeader}
                onClick={() => setPostProcessingCostsVisible(prev => !prev)}
              >
                <h5 style={styles.noMarginText}>Post Processing Costs</h5>
              </div>
              {
                postProcessingCostsVisible ?
                  <div style={{ ...styles.closingRowContent, flexDirection: 'row' }}>
                    <div style={{ flex: 1 }}>
                      <h5>Option A</h5>
                      <PostProcessingCostsTable
                        location={locationSelectA}
                        calculations={calculationsA}
                      />
                    </div>
                    {hasOptionB ?
                      <div style={{ flex: 1 }}>
                        <h5>Option B</h5>
                        <PostProcessingCostsTable
                          location={locationSelectB}
                          calculations={calculationsB}
                        />
                      </div> :
                      null
                    }
                  </div> :
                  null
              }
            </div>
          </div>
        </Tab>
      </Tabs>
      {
        !noMoldedPartSelected ?
          <div style={styles.partCommentSection}>
            <div style={styles.commentInputRow}>
              <p style={styles.noMarginText}>Part Comments</p>
              <Form.Control
                as="textarea"
                rows={3}
                value={newPartCommentValue}
                onChange={e => setNewPartCommentValue(e.target.value)}
                size="sm"
                style={{ flexBasis: '67%' }}
                disabled={disableAllInputs}
              />
              <Button
                type="button"
                variant="primary"
                size="sm"
                disabled={disableAllInputs || !newPartCommentValue.trim().length}
                onClick={addPartComment}
              >
                Add
              </Button>
            </div>
            <div style={styles.hr}/>
            <div style={styles.scrollingTableContainer}>
              <Table size="sm" striped bordered style={{ marginBottom: 0 }}>
                <thead>
                <tr>
                  <th style={{ ...styles.stickyHeader, width: '20%' }}>
                    <div style={styles.fauxTopBorder}/>
                    <p style={styles.stickyHeaderText}>Username</p>
                    <div style={styles.fauxBottomBorder}/>
                  </th>
                  <th style={{ ...styles.stickyHeader, width: '25%' }}>
                    <div style={styles.fauxTopBorder}/>
                    <p style={styles.stickyHeaderText}>Time</p>
                    <div style={styles.fauxBottomBorder}/>
                  </th>
                  <th style={{ ...styles.stickyHeader, width: '50%' }}>
                    <div style={styles.fauxTopBorder}/>
                    <p style={styles.stickyHeaderText}>Comment</p>
                    <div style={styles.fauxBottomBorder}/>
                  </th>
                  <th style={{ ...styles.stickyHeader, width: '5%' }}>
                    <div style={styles.fauxTopBorder}/>
                    <p style={styles.stickyHeaderText}>Delete</p>
                    <div style={styles.fauxBottomBorder}/>
                  </th>
                </tr>
                </thead>
                <tbody>
                {moldedPartsValue[selectedMoldedPartIndex].partComments && moldedPartsValue[selectedMoldedPartIndex].partComments.length ?
                  moldedPartsValue[selectedMoldedPartIndex].partComments.map((commentObj, index) => (
                    <tr key={index}>
                      <td style={commentObj.deleted ? styles.deletedComment : null}>{commentObj.username}</td>
                      <td
                        style={commentObj.deleted ? styles.deletedComment : null}>{DateTime.fromMillis(commentObj.time).toFormat('ccc LLL d yyyy, h:mm a')}</td>
                      <td style={commentObj.deleted ? styles.deletedComment : null}>{commentObj.comment}</td>
                      <td style={{ textAlign: 'center' }}>
                        {!commentObj.deleted && commentObj.userId === userId ?
                          <FontAwesomeIcon
                            icon={faTrashAlt}
                            style={{
                              cursor: quoteData.holdUserId !== userId ? 'not-allowed' : 'pointer',
                              color: quoteData.holdUserId !== userId ? COLORS.midGray : COLORS.red,
                            }}
                            onClick={quoteData.holdUserId !== userId ? null : () => deletePartComment(commentObj.time)}
                          /> :
                          null
                        }
                      </td>
                    </tr>
                  )) :
                  <tr>
                    <td colSpan="4">No part comments...</td>
                  </tr>
                }
                </tbody>
              </Table>
            </div>
          </div> :
          null
      }
    </div>
  );
};

const styles = {
  mainTabContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  },
  tabContainer: {
    padding: 10,
    backgroundColor: COLORS.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    borderWidth: 1,
    borderColor: COLORS.whiteGray,
    borderStyle: 'solid',
    borderTopWidth: 0,
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
  },
  tabContainerRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 7,
  },
  tabContainerRowSpaced: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 7,
  },
  tabDualContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexBasis: '38%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  lineTitle: {
    textAlign: 'center',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: COLORS.midGray,
    lineHeight: '0.1em',
    marginTop: 12,
    marginBottom: 12,
    color: COLORS.darkGray,
  },
  lineTitleText: {
    backgroundColor: COLORS.offWhite,
    paddingLeft: 7,
    paddingRight: 7,
  },
  noMarginText: {
    margin: 0,
  },
  tabContainerLabel: {
    flexBasis: '22%',
    textAlign: 'right',
    paddingRight: 10,
  },
  closingRow: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    borderRadius: 3,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: COLORS.midGray,
    marginBottom: 7,
  },
  closingRowHeader: {
    backgroundColor: COLORS.midGray,
    color: COLORS.darkGray,
    cursor: 'pointer',
    padding: 7,
  },
  closingRowContent: {
    padding: 7,
    display: 'flex',
    flexDirection: 'column',
  },
  moldedPartCell: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    padding: 7,
    borderRadius: 3,
  },
  moldedPartCellText: {
    margin: 0,
  },
  leftInputGroupText: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRightWidth: 0,
  },
  rightInputGroupText: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeftWidth: 0,
  },
  partCommentSection: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 7,
  },
  commentInputRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  hr: {
    height: 1,
    backgroundColor: COLORS.midGray,
    marginBottom: 8,
    marginTop: 8,
  },
  stickyHeader: {
    position: 'sticky',
    top: 0,
    backgroundColor: COLORS.white,
    padding: 0,
  },
  scrollingTableContainer: {
    maxHeight: 350,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  fauxTopBorder: {
    width: '100%',
    height: 1,
    backgroundColor: COLORS.whiteGray,
    position: 'relative',
    top: -1,
  },
  fauxBottomBorder: {
    width: '100%',
    height: 1,
    backgroundColor: COLORS.whiteGray,
    position: 'relative',
    bottom: -1,
  },
  stickyHeaderText: {
    margin: 5,
  },
  noCellBorder: {
    borderTopWidth: 0,
  },
  deleteButton: {
    marginLeft: 5,
    cursor: 'pointer',
  },
  deletedComment: {
    textDecoration: 'line-through',
  },
};

export default QuoteTabMolded;