import { useState } from 'react';
import { Button, Form, Table } from 'react-bootstrap';
import COLORS from '../styles/colors';
import {
  commentTypeLovAtom,
  loadingMessageSelector,
  accessTokenAtom,
  quoteDataAtom,
  loggedInUserAtom,
  quoteCommentsAtom,
  userIdAtom,
} from '../state';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import { createQuoteComment, deleteQuoteComment } from '../helpers/api';
import { DateTime } from 'luxon';
import Logger from '../helpers/Logger';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAlert } from 'react-alert';
import cloneDeep from 'lodash.clonedeep';

const logger = new Logger();

const CommentsSection = () => {
  const [commentsVisible, setCommentsVisible] = useState(false);
  const [commentTypeSelect, setCommentTypeSelect] = useState('NULL');
  const [commentTypeFilterSelect, setCommentTypeFilterSelect] = useState('NULL');
  const [newCommentValue, setNewCommentValue] = useState('');
  const [quoteComments, setQuoteComments] = useRecoilState(quoteCommentsAtom);
  const commentTypeLov = useRecoilValue(commentTypeLovAtom);
  const accessToken = useRecoilValue(accessTokenAtom);
  const quoteData = useRecoilValue(quoteDataAtom);
  const loggedInUser = useRecoilValue(loggedInUserAtom);
  const userId = useRecoilValue(userIdAtom);
  const setLoadingMessage = useSetRecoilState(loadingMessageSelector);
  const alert = useAlert();
  
  const createComment = async () => {
    try {
      setLoadingMessage('Inserting comment');
      const newComment = await createQuoteComment(
        accessToken,
        quoteData.quoteId,
        quoteData.revision,
        commentTypeSelect,
        newCommentValue,
        loggedInUser,
      );
      setCommentTypeSelect('NULL');
      setNewCommentValue('');
      setQuoteComments(prevState => [...prevState, newComment]);
    }
    catch(e) {
      console.error('CommentsSection.createComment error ->', e);
      logger.log(accessToken, `CommentsSection.createComment error -> ${JSON.stringify(e)}`, userId, loggedInUser);
    }
    finally {
      setLoadingMessage(null);
    }
  };
  
  const confirmStrikeComment = async (quoteId, revision, commentTime) => {
    if(window.confirm('Are you sure you want to strike-through your comment?'))
      strikeComment(quoteId, revision, commentTime);
  };
  
  const strikeComment = async (quoteId, revision, commentTime) => {
    try {
      setLoadingMessage('Striking comment');
      await deleteQuoteComment(accessToken, quoteId, revision, commentTime);
      setQuoteComments(prevState => {
        const newQuoteComments = cloneDeep(prevState);
        const commentIndex = newQuoteComments.findIndex(o => o.quoteId === quoteId && o.revision === revision && o.commentTime === commentTime);
        if(commentIndex < 0) return newQuoteComments;
        newQuoteComments[commentIndex].deleted = 'X';
        return newQuoteComments;
      });
    }
    catch(e) {
      console.error('CommentsSection.strikeComment error ->', e);
      logger.log(accessToken, `CommentsSection.strikeComment error -> ${JSON.stringify(e)}`, userId, loggedInUser);
      alert.error('Could not strike comment');
    }
    finally {
      setLoadingMessage(null);
    }
  };
  
  const filteredComments = quoteComments.filter(comment =>
    commentTypeFilterSelect === 'NULL' || commentTypeFilterSelect === comment.commentType);
  
  return (
    <div style={styles.closingRow}>
      <div
        style={styles.closingRowHeader}
        onClick={() => setCommentsVisible(prev => !prev)}
      >
        <h5 style={styles.noMarginText}>Comments</h5>
      </div>
      {
        commentsVisible ?
          <div style={styles.closingRowContent}>
            <div style={styles.commentInputRow}>
              <Form.Select
                as="select"
                style={{ flexBasis: '23%' }}
                size="sm"
                value={commentTypeSelect}
                onChange={e => setCommentTypeSelect(e.target.value)}
              >
                <option value="NULL">-- Select a Type --</option>
                {commentTypeLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
              </Form.Select>
              <Form.Control
                as="textarea"
                rows={3}
                size="sm"
                style={{ flexBasis: '67%' }}
                value={newCommentValue}
                disabled={quoteData.holdUserId !== userId}
                onChange={e => setNewCommentValue(e.target.value)}
              />
              <Button
                type="button"
                variant="primary"
                size="sm"
                disabled={quoteData.holdUserId !== userId || commentTypeSelect === 'NULL' || !newCommentValue.trim().length}
                onClick={createComment}
              >
                Add
              </Button>
            </div>
            <div style={styles.hr}/>
            <div style={styles.commentFilterRow}>
              <p style={styles.commentFilterText}>Filter</p>
              <Form.Select
                as="select"
                size="sm"
                value={commentTypeFilterSelect}
                onChange={e => setCommentTypeFilterSelect(e.target.value)}
              >
                <option value="NULL">-- All --</option>
                {commentTypeLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
              </Form.Select>
            </div>
            <div style={styles.scrollingTableContainer}>
              <Table size="sm" striped bordered style={{ marginBottom: 0 }}>
                <thead>
                <tr>
                  <th style={{ ...styles.stickyHeader, width: '7%' }}>
                    <div style={styles.fauxTopBorder}/>
                    <p style={styles.stickyHeaderText}>Rev</p>
                    <div style={styles.fauxBottomBorder}/>
                  </th>
                  <th style={{ ...styles.stickyHeader, width: '15%' }}>
                    <div style={styles.fauxTopBorder}/>
                    <p style={styles.stickyHeaderText}>Type</p>
                    <div style={styles.fauxBottomBorder}/>
                  </th>
                  <th style={{ ...styles.stickyHeader, width: '15%' }}>
                    <div style={styles.fauxTopBorder}/>
                    <p style={styles.stickyHeaderText}>Username</p>
                    <div style={styles.fauxBottomBorder}/>
                  </th>
                  <th style={{ ...styles.stickyHeader, width: '20%' }}>
                    <div style={styles.fauxTopBorder}/>
                    <p style={styles.stickyHeaderText}>Time</p>
                    <div style={styles.fauxBottomBorder}/>
                  </th>
                  <th style={{ ...styles.stickyHeader, width: '38%' }}>
                    <div style={styles.fauxTopBorder}/>
                    <p style={styles.stickyHeaderText}>Comment</p>
                    <div style={styles.fauxBottomBorder}/>
                  </th>
                  <th style={{ ...styles.stickyHeader, width: '5%' }}>
                    <div style={styles.fauxTopBorder}/>
                    <p style={styles.stickyHeaderText}>Delete</p>
                    <div style={styles.fauxBottomBorder}/>
                  </th>
                </tr>
                </thead>
                <tbody>
                {filteredComments.map((comment, index) => {
                  const commentType = commentTypeLov.find(o => o.code === comment.commentType);
                  return (
                    <tr key={index}>
                      <td style={comment.deleted ? styles.deletedComment : null}>{comment.revision}</td>
                      <td
                        style={comment.deleted ? styles.deletedComment : null}>{commentType ? commentType.name : comment.commentType}</td>
                      <td style={comment.deleted ? styles.deletedComment : null}>{comment.username}</td>
                      <td
                        style={comment.deleted ? styles.deletedComment : null}>{DateTime.fromMillis(comment.commentTime).toFormat('ccc LLL d yyyy, h:mm a')}</td>
                      <td style={comment.deleted ? styles.deletedComment : null}>{comment.comment}</td>
                      <td style={{ textAlign: 'center' }}>
                        {!comment.deleted && comment.username === loggedInUser ?
                          <FontAwesomeIcon
                            icon={faTrashAlt}
                            style={{
                              cursor: quoteData.holdUserId !== userId ? 'not-allowed' : 'pointer',
                              color: quoteData.holdUserId !== userId ? COLORS.midGray : COLORS.red,
                            }}
                            onClick={quoteData.holdUserId !== userId ? null : () => confirmStrikeComment(comment.quoteId, comment.revision, comment.commentTime)}
                          /> :
                          null
                        }
                      </td>
                    </tr>
                  );
                })}
                </tbody>
              </Table>
            </div>
          </div> :
          null
      }
    </div>
  );
};

const styles = {
  closingRow: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    borderRadius: 3,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: COLORS.midGray,
    marginBottom: 7,
  },
  closingRowHeader: {
    backgroundColor: COLORS.midGray,
    color: COLORS.darkGray,
    cursor: 'pointer',
    padding: 7,
  },
  closingRowContent: {
    padding: 7,
    display: 'flex',
    flexDirection: 'column',
  },
  commentInputRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  hr: {
    height: 1,
    backgroundColor: COLORS.midGray,
    marginBottom: 8,
    marginTop: 8,
  },
  commentFilterRow: {
    alignSelf: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 8,
  },
  commentFilterText: {
    marginBottom: 0,
    marginRight: 8,
    fontWeight: 'bold',
  },
  noMarginText: {
    margin: 0,
  },
  stickyHeader: {
    position: 'sticky',
    top: 0,
    backgroundColor: COLORS.white,
    padding: 0,
  },
  scrollingTableContainer: {
    maxHeight: 350,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  fauxTopBorder: {
    width: '100%',
    height: 1,
    backgroundColor: COLORS.whiteGray,
    position: 'relative',
    top: -1,
  },
  fauxBottomBorder: {
    width: '100%',
    height: 1,
    backgroundColor: COLORS.whiteGray,
    position: 'relative',
    bottom: -1,
  },
  stickyHeaderText: {
    margin: 5,
  },
  deletedComment: {
    textDecoration: 'line-through',
  },
};

export default CommentsSection;