import { Fragment, useState, useEffect } from 'react';
import {
  windowWidthAtom,
  selectedDashboardQuoteAtom,
  quoteStatusLovAtom,
  accessTokenAtom,
  userIdAtom,
  loadingMessageSelector,
  loggedInUserAtom,
  awardProbabilityLovAtom,
  dashboardQuotesAtom,
} from '../state';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import DashboardQuote from './DashboardQuote';
import { Form, Table } from 'react-bootstrap';
import COLORS from '../styles/colors';
import { getOwnedCreatedQuotes, getQuoteAssignments } from '../helpers/api';
import { DateTime } from 'luxon';
import { getLateCutoffs } from '../helpers/misc';
import Logger from '../helpers/Logger';
import * as CONFIG from '../config';

const logger = new Logger();

const Dashboard = () => {
  const windowWidth = useRecoilValue(windowWidthAtom);
  const selectedDashboardQuote = useRecoilValue(selectedDashboardQuoteAtom);
  const quoteStatusLov = useRecoilValue(quoteStatusLovAtom);
  const [quoteStatusFilterSelect, setQuoteStatusFilterSelect] = useState('NULL');
  const [quoteDueDateFilterSelect, setQuoteDueDateFilterSelect] = useState('NULL');
  const [removeLostFilter, setRemoveLostFilter] = useState(false);
  const [removeNotFeasibleFilter, setRemoveNotFeasibleFilter] = useState(false);
  const [removeSandboxFilter, setRemoveSandboxFilter] = useState(false);
  const accessToken = useRecoilValue(accessTokenAtom);
  const userId = useRecoilValue(userIdAtom);
  const loggedInUser = useRecoilValue(loggedInUserAtom);
  const awardProbabilityLov = useRecoilValue(awardProbabilityLovAtom);
  const setLoadingMessage = useSetRecoilState(loadingMessageSelector);
  const [dashboardQuotes, setDashboardQuotes] = useRecoilState(dashboardQuotesAtom);
  
  const lateCutoffs = getLateCutoffs();
  
  const filteredQuotes = dashboardQuotes
    .filter(quote => quoteStatusFilterSelect === 'NULL' || quoteStatusFilterSelect === quote.status)
    .filter(quote => quoteDueDateFilterSelect === 'NULL' ||
      (quoteDueDateFilterSelect === 'ALMOST_LATE' && quote.dueDate < lateCutoffs.almostLateCutOff && quote.dueDate >= lateCutoffs.lateCutOff) ||
      (quoteDueDateFilterSelect === 'LATE' && quote.dueDate < lateCutoffs.lateCutOff))
    .filter(quote => !removeLostFilter || (removeLostFilter && quote.status !== 'LOST'))
    .filter(quote => !removeNotFeasibleFilter || (removeNotFeasibleFilter && quote.status !== 'NOT_FEASIBLE'))
    .filter(quote => !removeSandboxFilter || (removeSandboxFilter && quote.status !== 'SANDBOX'));
  
  const assignedQuotes = filteredQuotes.filter(quote => quote.assignments.some(o => o.userId === userId) &&
    !['AWARDED', 'AT_CUSTOMER'].includes(quote.status));
  const myOpenQuotes = filteredQuotes.filter(quote => !quote.assignments.some(o => o.userId === userId) &&
    quote.createdBy === userId && !['AWARDED', 'AT_CUSTOMER'].includes(quote.status));
  const atCustomerQuotes = filteredQuotes.filter(quote => quote.createdBy === userId && quote.status === 'AT_CUSTOMER');
  const awardedQuotes = filteredQuotes.filter(quote => quote.createdBy === userId && quote.status === 'AWARDED');
  const awardProbabilityObj = selectedDashboardQuote?.awardProbability ? awardProbabilityLov.find(o => o.code === selectedDashboardQuote.awardProbability) : null;
  
  useEffect(() => {
    const updateDashboardQuotes = async () => {
      try {
        setLoadingMessage('Loading quotes');
        let quotes = await getOwnedCreatedQuotes(accessToken, userId);
        quotes = await Promise.all(quotes.map(async quote => {
          const assignments = quote.status === 'SANDBOX' ?
            [{
              userId,
              username: loggedInUser,
              assignmentTypes: ['OWNER'],
            }] :
            await getQuoteAssignments(accessToken, quote.quoteId, quote.revision);
          return {
            ...quote,
            assignments: assignments.map(o => ({ userId: o.userId, username: o.username, types: o.assignmentTypes })),
          };
        }));
        setDashboardQuotes(quotes);
      }
      catch(e) {
        console.error('Dashboard.updateDashboardQuotes error ->', e);
        logger.log(accessToken, `Dashboard.updateDashboardQuotes error -> ${JSON.stringify(e)}`, userId, loggedInUser);
      }
      finally {
        setLoadingMessage(null);
      }
    };
    updateDashboardQuotes();
  }, [accessToken, userId, setLoadingMessage, loggedInUser, setDashboardQuotes]);
  
  // calculate col sizing
  const windowBase = windowWidth - 18 - (CONFIG.DASHBOARD_PADDING * 2);
  let colWidth = windowBase / 5;
  let controlWidth = windowBase / 5;
  if(windowWidth < 1200) {
    colWidth = windowBase / 3;
    controlWidth = windowBase / 3;
  }
  if(windowWidth < 992) {
    colWidth = windowBase / 2;
    controlWidth = windowBase + (2 * CONFIG.DASHBOARD_PADDING);
  }
  if(windowWidth < 768) {
    colWidth = windowBase;
    controlWidth = windowBase;
  }
  
  const quoteControls = (
    <div
      style={{
        ...styles.colContainer,
        width: controlWidth,
      }}
    >
      <div
        style={{
          ...styles.controlsColumn,
          borderBottomLeftRadius: !selectedDashboardQuote ? 5 : 0,
          borderBottomRightRadius: !selectedDashboardQuote ? 5 : 0,
        }}
      >
        <div style={styles.quoteColumnHeading}>
          <h5 style={styles.quoteColumnHeadingText}>
            Quote Filter
          </h5>
        </div>
        <Form style={styles.controlsForm}>
          <Form.Group>
            <Form.Label>Status</Form.Label>
            <Form.Select
              as="select"
              value={quoteStatusFilterSelect}
              onChange={e => setQuoteStatusFilterSelect(e.target.value)}
            >
              <option value="NULL">All Quotes</option>
              {quoteStatusLov.map(o => <option key={o.code} value={o.code}>{o.name}</option>)}
            </Form.Select>
          </Form.Group>
          <Form.Group>
            <Form.Label>Due Date</Form.Label>
            <Form.Select
              as="select"
              value={quoteDueDateFilterSelect}
              onChange={e => setQuoteDueDateFilterSelect(e.target.value)}
            >
              <option value="NULL">All</option>
              <option value="ALMOST_LATE">Almost Late</option>
              <option value="LATE">Late</option>
            </Form.Select>
          </Form.Group>
          <Form.Group>
            <Form.Label>Remove</Form.Label>
            <Form.Check
              type="checkbox"
              label="Lost"
              checked={removeLostFilter}
              onChange={e => setRemoveLostFilter(e.target.checked)}
            />
            <Form.Check
              type="checkbox"
              label="Not Feasible"
              checked={removeNotFeasibleFilter}
              onChange={e => setRemoveNotFeasibleFilter(e.target.checked)}
            />
            <Form.Check
              type="checkbox"
              label="Sandbox"
              checked={removeSandboxFilter}
              onChange={e => setRemoveSandboxFilter(e.target.checked)}
            />
          </Form.Group>
        </Form>
        {
          selectedDashboardQuote ?
            <>
              <div style={{ ...styles.quoteColumnHeading, borderRadius: 0 }}>
                <h5 style={styles.quoteColumnHeadingText}>
                  Quick View
                </h5>
              </div>
              <Table size="sm" striped bordered style={styles.quickTable}>
                <tbody>
                <tr>
                  <td style={styles.tableHeading}>Quote Number:</td>
                  <td>{selectedDashboardQuote.quoteNumber != null ? selectedDashboardQuote.quoteNumber : ''}</td>
                </tr>
                <tr>
                  <td style={styles.tableHeading}>Revision Number:</td>
                  <td>{selectedDashboardQuote.revision != null ? selectedDashboardQuote.revision : ''}</td>
                </tr>
                <tr>
                  <td style={styles.tableHeading}>Account Manager:</td>
                  <td>{selectedDashboardQuote.createdByUsername ? selectedDashboardQuote.createdByUsername : ''}</td>
                </tr>
                <tr>
                  <td style={styles.tableHeading}>Due Date:</td>
                  <td>{selectedDashboardQuote.dueDate != null ? DateTime.fromMillis(selectedDashboardQuote.dueDate).toISODate() : ''}</td>
                </tr>
                <tr>
                  <td style={styles.tableHeading}>Assigned to:</td>
                  <td>{selectedDashboardQuote.assignments && selectedDashboardQuote.assignments.length ?
                    selectedDashboardQuote.assignments.map(o => o.username).join(', ') : ''}</td>
                </tr>
                <tr>
                  <td style={styles.tableHeading}>Customer:</td>
                  <td>{selectedDashboardQuote.customerName ? selectedDashboardQuote.customerName : ''}</td>
                </tr>
                <tr>
                  <td style={styles.tableHeading}>Customer P/N:</td>
                  <td>{selectedDashboardQuote.customerPartNumber ? selectedDashboardQuote.customerPartNumber : ''}</td>
                </tr>
                <tr>
                  <td style={styles.tableHeading}>Part Desc:</td>
                  <td>{selectedDashboardQuote.partDescription ? selectedDashboardQuote.partDescription : ''}</td>
                </tr>
                <tr>
                  <td style={styles.tableHeading}>Monthly Volume:</td>
                  <td>{selectedDashboardQuote.monthlyVolume ? selectedDashboardQuote.monthlyVolume.toLocaleString() : ''}</td>
                </tr>
                <tr>
                  <td style={styles.tableHeading}>Probability:</td>
                  <td>{awardProbabilityObj ? awardProbabilityObj.name : ''}</td>
                </tr>
                </tbody>
              </Table>
            </> :
            null
        }
      </div>
    </div>
  );
  
  const hiddenColumn = (
    <div
      style={{
        ...styles.colContainer,
        width: colWidth,
        visibility: 'hidden',
      }}
    />
  );
  
  const quoteColumns = [
    // ASSIGNED TO ME
    (
      <div
        style={{
          ...styles.colContainer,
          width: colWidth,
        }}
      >
        <div style={styles.quoteColumn}>
          <div style={styles.quoteColumnHeading}>
            <h5 style={styles.quoteColumnHeadingText}>
              Assigned to Me
            </h5>
          </div>
          <div style={styles.quoteList}>
            {assignedQuotes.map((quote, index) => <DashboardQuote
              key={index}
              quote={quote}
            />)}
          </div>
        </div>
      </div>
    ),
    // MY OPEN QUOTES
    (
      <div
        style={{
          ...styles.colContainer,
          width: colWidth,
        }}
      >
        <div style={styles.quoteColumn}>
          <div style={styles.quoteColumnHeading}>
            <h5 style={styles.quoteColumnHeadingText}>
              My Open Quotes
            </h5>
          </div>
          <div style={styles.quoteList}>
            {myOpenQuotes.map((quote, index) => <DashboardQuote
              key={index}
              quote={quote}
            />)}
          </div>
        </div>
      </div>
    ),
    // AT CUSTOMER
    (
      <div
        style={{
          ...styles.colContainer,
          width: colWidth,
        }}
      >
        <div style={styles.quoteColumn}>
          <div style={styles.quoteColumnHeading}>
            <h5 style={styles.quoteColumnHeadingText}>
              At Customer
            </h5>
          </div>
          <div style={styles.quoteList}>
            {atCustomerQuotes.map((quote, index) => <DashboardQuote
              key={index}
              quote={quote}
            />)}
          </div>
        </div>
      </div>
    ),
    // AWARDED
    (
      <div
        style={{
          ...styles.colContainer,
          width: colWidth,
        }}
      >
        <div style={styles.quoteColumn}>
          <div style={styles.quoteColumnHeading}>
            <h5 style={styles.quoteColumnHeadingText}>
              Awarded
            </h5>
          </div>
          <div style={styles.quoteList}>
            {awardedQuotes.map((quote, index) => <DashboardQuote
              key={index}
              quote={quote}
            />)}
          </div>
        </div>
      </div>
    ),
  ];
  
  // add quote controls component to list at correct index
  if(windowWidth < 992) quoteColumns.push(quoteControls);
  else if(windowWidth < 1200) {
    quoteColumns.splice(2, 0, quoteControls);
    quoteColumns.push(hiddenColumn);
  }
  else quoteColumns.push(quoteControls);
  
  return (
    <div
      style={{
        ...styles.dashboardContainer,
        justifyContent: windowWidth >= 768 ? 'space-around' : 'center',
      }}
    >
      {quoteColumns.map((comp, index) => <Fragment key={index}>{comp}</Fragment>)}
    </div>
  );
};

const styles = {
  dashboardContainer: {
    padding: CONFIG.DASHBOARD_PADDING,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  },
  colContainer: {
    padding: CONFIG.DASHBOARD_PADDING,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
  },
  quoteColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    borderStyle: 'solid',
    borderColor: COLORS.midGray,
    borderWidth: 1,
    borderRadius: 5,
  },
  quoteColumnHeading: {
    backgroundColor: COLORS.lightGray,
    padding: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  quoteColumnHeadingText: {
    margin: 0,
  },
  quoteList: {
    display: 'flex',
    flexDirection: 'column',
    padding: 4,
    paddingBottom: 0,
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  },
  controlsColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    borderStyle: 'solid',
    borderColor: COLORS.midGray,
    borderWidth: 1,
    backgroundColor: COLORS.white,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  controlsForm: {
    display: 'flex',
    flexDirection: 'column',
    padding: 8,
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  },
  tableHeading: {
    fontStyle: 'italic',
    fontWeight: 'bold',
  },
  quickTable: {
    fontSize: '0.85em',
    margin: 0,
  },
};

export default Dashboard;